import { useContext,useState,useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";
import { GET_CAMPAIGN_BOND } from '../../../Querys/querys';
import ObjectivesContext,{ Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import { 
  Dialog,
  DialogTitle,
  DialogContent, 
  Grid, 
  TextField } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { formatter } from "../../../helpers/formatoMoneda";
import styles from '../Objectives.module.css';

const InfoBondColaborator = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [bond, setBond] = useState('');  
  const [qualFinal, setQualFinal] = useState(false);  
  const [getCampaignBond] = useLazyQuery(GET_CAMPAIGN_BOND, { 
    onCompleted({ GET_CAMPAIGN_BOND }) {   	
      if(GET_CAMPAIGN_BOND.length>0){        
        setBond(formatter(Number(GET_CAMPAIGN_BOND[0].bond))  )         
        setQualFinal(true)       
      }
    },
  });   
  
  useEffect(() => {   
    const typeBond=["Monto Fijo","Porcentaje de ingreso"]
    if(state?.campaign?.typeBond === typeBond[0]) {
      if (state?.qualification && state?.campaign?.minQualification) {
        if(state?.qualification >= state?.campaign?.minQualification){
          setQualFinal(true)              
          const bonoObtein=(Number(state?.campaign?.descriptionBond) * (state?.qualification/100))
          setBond(formatter(bonoObtein))          
        }  else{
          setQualFinal(false)
          setBond('')
        }
      }
    }
    if(state?.campaign?.typeBond === typeBond[1]) {
       if (state?.qualification && state?.campaign?.minQualification) {
         if(state?.qualification >= state?.campaign?.minQualification){         
           getCampaignBond({ 
            variables: {
                idCollaborator: parseInt(state?.collaboratorId as any, 10),
                idCampaign:parseInt(state?.campaignId as any, 10),
                bond:Number(state?.campaign?.descriptionBond)
              }
           }) 
        }  else{
          setQualFinal(false)
          setBond('')
        }
      }
    }
  }, [state?.qualification, state?.campaign?.minQualification, setQualFinal, state, getCampaignBond, bond]);


  const cancelButton =async () => {    
    dispatch({ type: Types.CANCEL_BOND_MODAL });           
  }

  return (
  <Dialog
    aria-labelledby="customized-dialog-title"
    open={state.bondModal}
    fullWidth={true}      
  >
       <DialogTitle id="alert-dialog-title" >
          <IconButton aria-label="close" 
                  onClick={(()=> { cancelButton() } )}
                  sx={{position: 'absolute', right: 8, top: 8,color: (theme) => theme.palette.grey[500], }}
          >
          <CloseIcon />
        </IconButton> 
          <div className={styles.containerTitlesKpisBonds}> 
            <div className={styles.titlesKpisBonds1}>Campaña KPI's</div>
            <div className={styles.titlesKpisBonds2}>{state?.campaign?.nameCampaign} </div>
            <div className={styles.titlesKpisBonds3}>{state?.campaign?.campaignBond !== 'false' ?'Con bono':'Sin bono'} </div>
          </div>
          
     </DialogTitle>       
    <DialogContent>
      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <TextField
            name="qualificationGoal"
            defaultValue={state?.campaign?.minQualification}
            label="Calificación Meta"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
        <Grid xs item>
          <TextField
            name="qualificationObtained"
            defaultValue={ state?.qualification?.toFixed(2)}
           label="Calificación  Obtenida"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
      </Grid>
    </DialogContent>

    { 
    (state?.campaign?.campaignBond !==  'false' && qualFinal) &&
    <DialogContent>
      <Grid direction="row" container spacing={2}>
        <Grid xs item>
          <TextField
            name="bond"
            defaultValue={bond}
            label="Bono"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>   
        <Grid xs item>
          
        </Grid> 
      </Grid>
    </DialogContent>
    }
    <DialogContent 
     className={styles.containerbtnsModalBonds}
    >
      <div>
         <button
           onClick={cancelButton}
           className={styles.evidenceCancelButton}
            >Cerrar
        </button>                   
      </div>       
   </DialogContent>
     
      
  </Dialog>  
  )
}

export default InfoBondColaborator
