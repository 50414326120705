import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import styles from "../../src/components/Payroll/PayrollStyles.module.css";
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_MONTHLYISR,
  GET_MONTHLYISRCOLLABORATOR,
  UPDATE_MONTHLYISR_STATE,
  UPDATE_MONTHLYISR_COLLABORATOR_DELETE,
  GET_MONTHLYISRCOLLABORATOR_EXCELL_CALCULO,
  GET_ALL_MONTHLYISR
} from "..//Querys/querys";
import { formatter } from "../helpers/formatoMoneda";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import { SuccessfulAlert } from '../alerts/successAlerts';
import { CreateExcelFileMonthlyISR } from "../components/Payroll/Modals/ExcelPayroll";

export const InitMonthlyISR = () => {
  const { id, tab } = useParams<any>();
  const [filtrado, setFiltrado] = useState([]);
  const [isCheck, setIsCheck] =  useState<any[]>([])

  const history = useHistory();
  
  const {
    loading: payrollCollaboratorLoading,
    data: resultPayrollCollaborator
  } = useQuery(GET_MONTHLYISRCOLLABORATOR, {
    variables: { getPayrollcollaboratorId: id },
  });

  const resultPayrollCollaboratorExcell = useQuery(GET_MONTHLYISRCOLLABORATOR_EXCELL_CALCULO, {
    variables: { getPayrollcollaboratorExcellCalculoId: id },
  });

  const { loading, data } = useQuery(GET_MONTHLYISR, {
    variables: { getPayrollId: id },
  });
  const PayrollData = data?.GET_MONTHLYISR;

  const [updateCollaboratorStatus] = useMutation(UPDATE_MONTHLYISR_COLLABORATOR_DELETE, {
    refetchQueries: [
      { query: GET_MONTHLYISRCOLLABORATOR, variables:{getPayrollcollaboratorId: id}},
      { query: GET_MONTHLYISR, variables:{getPayrollId: id}},
      { query: GET_ALL_MONTHLYISR },
      { query: GET_MONTHLYISRCOLLABORATOR_EXCELL_CALCULO, variables:{getPayrollcollaboratorExcellCalculoId: id}}
    ],
  });

  const allPayrollCollaborator = resultPayrollCollaborator?.GET_MONTHLYISRCOLLABORATOR;

  const allPayrollCollaboratorExcell = resultPayrollCollaboratorExcell.data?.GET_MONTHLYISRCOLLABORATOR_EXCELL_CALCULO;

  useEffect(() => {
    setFiltrado(allPayrollCollaborator);
  }, [allPayrollCollaborator]);


  const datosFiltrados = (e: any) => {
    if (e !== "") {
      let expresion = new RegExp(`${e}.*`, "i");
      const nuevoFiltrado = filtrado.filter((lis: any) =>
        expresion.test(lis.colaborator)
      );
      setFiltrado(nuevoFiltrado);
    } else {
      setFiltrado(allPayrollCollaborator);
    }
  };

  const [updatePayroll] = useMutation(UPDATE_MONTHLYISR_STATE);

  const handleChange = () => {
  };

  const updateState = (id: any) => {
    if(!PayrollData?.dateFinish ){
      updatePayroll({
        variables: {
          input: {
            id: id,
            statusProgress: "Terminar",
          },
        },
      });
    } 
  };

  const handleSelectAll = (e:any) =>{

    if(!e.target.checked){
      setIsCheck([])
    }

    let filtradoSeleccionado = (allPayrollCollaborator.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (!lis.UUID || lis.UUID === "") && (lis.dispersionStatus !== "LIQUIDADO")
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)

  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }

  }

  const handleOnExport = () => {
    console.log(allPayrollCollaboratorExcell,'Reporte')
    CreateExcelFileMonthlyISR(allPayrollCollaboratorExcell);
  };

  const eliminarColaborador = async () =>{
    if(isCheck.length > 0){
      for (const payrollDelete of isCheck){
        await updateCollaboratorStatus({
          variables: {
            id: String(payrollDelete)
          },
        })
      }
    }
    SuccessfulAlert({text: "Registro eliminado con éxito"})
    setIsCheck([])

  }  

 

  return (
    <>
      <Box mt={3} ml={5} className="Title">
        Calculo ISR
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={7}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            <CustomTab label="Pre-Nómina" value={1} />
            <CustomTab label="Finiquito / Liquidación" value={2} /> 
            <CustomTab label="Eventuales" value={3} />
            <CustomTab label="Históricas" value={4} />
            <CustomTab label="Calculadora" value={5} />
            <CustomTab label="Reportes" value={6} />
            <CustomTab label="ISR Mensual" value={7} />
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>
            </div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
          </div>
        </div>
        <div className={styles.pc_contenedorBotones}>
          <button
            className={styles.pc_botonDispersar}
            onClick={() => {
              updateState(id);
              history.push(`/monthlyISRTerminar/${id}/${tab}`);
            }}
          >
            <div>Terminar</div>
            <div className={styles.pc_iconoDispersar}></div>
          </button>
        </div>
      </div>
      <div className={styles.pc_contenedorPrincipal}>
        <div className={styles.pc_tituloContenedorPrincipal}>
          <div className={styles.pc_contenedorNombre}>
            <div>
              <span className={styles.pc_tituloPrincipal}>{tab}</span>
            </div>
            {loading ? null : (
              <div>
                <span className={styles.pc_tituloPeriodo}>
                  Periódo: &nbsp;{getDateDay(PayrollData?.init_date)}
                </span>
                &nbsp;-&nbsp;
                <span className={styles.pc_tituloPeriodo}>
                  {getDateDay(PayrollData?.end_date)}
                </span>
                &nbsp;
                <span className={styles.pc_tituloPeriodo}>
                  {getDateYear(PayrollData?.end_date)}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.pc_contenedorBuscadorDescargar}>
          <div className={styles.pc_contenedorBuscador}>
            <input
              type="text"
              placeholder="Buscar al colaborador por nombre"
              className={styles.pc_inputBuscador}
              onChange={(e) => datosFiltrados(e.target.value)}
            ></input>
            <div className={styles.pc_iconoBuscador}></div>
          </div>
          <button
            className={styles.pc_botonDescargar}
            onClick={() => handleOnExport()}
          >
            <div className={styles.pc_iconoDescargar}></div>
            <div>Descargar reporte</div>
          </button>
        </div>
        <div
        style={{
          marginBottom: "20px",
        }}
        > {
            (PayrollData?.dateFinish) ? null
            :  (isCheck.length > 0)?
                <button className={styles.pc_botonEliminar}
                onClick={() => eliminarColaborador()}
                >
                    Eliminar
                  </button>
              :
                <button className={styles.pc_botonEliminarInactivo}>
                    Eliminar
                </button>
          }
        </div>
        <div className={styles.pc_contenedorTabla}>
          <table className={styles.pc_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={styles.pc_columnaTitulos}>
                <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id="check"
                          type="checkbox"
                          onChange={(e) => handleSelectAll(e)}
                        ></input>
                        <label htmlFor="check"> Colaborador </label>
                </div>
              </td>
              <td className={styles.pc_columnaTitulos}>Proyecto/Área</td>
              <td className={styles.pc_columnaTitulos}>Base Gravable</td>
              <td className={styles.pc_columnaTitulos}>Limite Inferior</td>
              <td className={styles.pc_columnaTitulos}>Excedente</td>
              <td className={styles.pc_columnaTitulos}>Tasa</td>
              <td className={styles.pc_columnaTitulos}>
              ISR Marginal
              </td>
              <td className={styles.pc_columnaTitulos}>Cuota fija</td>
              <td className={styles.pc_columnaTitulos}>ISR Causado</td>
              <td className={styles.pc_columnaTitulos}>SUPE Causado</td>
              <td className={styles.pc_columnaTitulos}>ISR acreditado</td>
              <td className={styles.pc_columnaTitulos}>SUPE acreditado</td>
              <td className={styles.pc_columnaTitulos}>
              ISR
              </td>
              <td className={styles.pc_columnaTitulos}>SUPE</td>
              <td className={styles.pc_columnaTitulos}>Total Diferencia</td>
            </thead>
            <tbody>
              {!payrollCollaboratorLoading && filtrado?.map((collaborator: any, index: any) => (
                <>
                  <TableRow key={index} collaborator={collaborator} handleClick={handleClick} isCheck={isCheck} formatter={formatter}></TableRow>
                </>
              ))}
            </tbody>
          </table>
        </div>
        {
          payrollCollaboratorLoading && (
            <div className={styles.loader_container}>
              <div className={styles.loading} />
            </div>
          )
        }
        <div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado}>
              <div
                className={styles.pd_contenedorRegreso}
                onClick={() => history.push(`/payroll`)}
              >
                <div className={styles.pd_botonRegreso}></div>
                <div>Regresar a "Calculo ISR"</div>
              </div>
              <button
                className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function TableRow({collaborator, isCheck, handleClick, formatter }:{collaborator:any, isCheck:any, handleClick:any, formatter: any  }){

  return (
    <>
      <tr>
        <td className={styles.pc_columnaDatos}>
          <div id="colaboladores" className={styles.checkboxitem}>
            <input
              id={collaborator.id}
              key={collaborator.id}
              type="checkbox"
              checked ={isCheck.includes(collaborator.id)}
              onChange={(e) => handleClick(e)}
              value={collaborator.id}
              disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
            ></input>
            <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
          </div>
        </td>
        <td className={styles.pc_columnaDatos}>
          {collaborator.area}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.baseGravable)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.LimiteInferior)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.Excedente)}
        </td>
        <td className={styles.pc_columnaDatos}>
        {collaborator.Tasa}%
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.ISRMarginal)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.CuotaFija)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.ISRCausado)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.SUPECausado)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.ISRAcreditado)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.SUPEAcreditado)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.ISR)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.SUPE)}
        </td>
        <td className={styles.pc_columnaDatos}>
          {formatter(collaborator.TotalDiferencia)}
        </td>
      </tr>
    </>
  )
}

export default InitMonthlyISR;
