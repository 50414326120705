/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { useEffect, useState, useContext, useCallback } from 'react';
import { debounce } from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { PayrollModal } from '../../../interfaces/TabPayroll.interfaces';
import { useForm } from '../../../hooks/useForm';
import { TabPayrollContext } from '../../../context/PayrollContext/TabPayrollContext';
import '../Payroll.css'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays,format,getDate,
         parseISO,differenceInCalendarDays,
         getDaysInMonth,getMonth,isLeapYear,
         subMonths,getWeekOfMonth   } from 'date-fns';
// import { es } from 'date-fns/locale';
import { DateRange } from 'react-date-range'
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_PAYROLL_GROUP, CREATE_PAYROLL, GET_ALL_PAYROLL, GET_ALL_PAYROLL_ACTIVE } from "../../../Querys/querys";
import styles from '../PayrollStyles.module.css'
import PayrollProcessContext, { Types } from '../../../context/PayrollProcess/PayrollProcessContext';
import { createPrenominaModal } from '../../../context/PayrollProcess/Actions';
//import { SuccessfulAlert } from '../../../alerts/successAlerts';
import { ErrorAlert } from "../../../alerts/errorAlert";

const CreaPayroll = (props: any) => {
  const { state, dispatch } = useContext(PayrollProcessContext)
  const [estadoInicial, setEstadoInicial] = useState<any[]>([])
  const [seleccionadas, setSeleccionadas] = useState<any[]>([])
  const [showCalendario, setShowCalendario] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [stateCalendario, setStateCalendario] = useState([
    {
      startDate: subMonths(new Date(), 0),
      endDate: addDays(new Date(), 0),
      key: "selection"
    }]
  );
  const [tipoNomina, setTipoNomina] = useState("");
  const [agregaIncidencias, setAgregaIncidencias] = useState(false);
  const resultPayrollGroup = useQuery(GET_ALL_PAYROLL_GROUP);
  const allPayrollGroup = resultPayrollGroup.data?.GET_ALL_PAYROLL_GROUP;
  const [createNewPayroll, { data: datosMutacion, loading: loadingMutation }] = useMutation(CREATE_PAYROLL, {
    refetchQueries: [
      { query: GET_ALL_PAYROLL },
      { query: GET_ALL_PAYROLL_ACTIVE }

    ],
  });


  const guardarInformacion = async () => {
    if (!disabledButton) {
      setDisabledButton(true);

      if (formulario.PaymentFrecuency === "") {
        ErrorAlert({ text: "Frecuencia de pago es requerida." });
        setDisabledButton(false);
        return;
      }

      if (formulario.PayrollType === "") {
        ErrorAlert({ text: "Tipo de nómina es requerida." });
        setDisabledButton(false);
        return;
      }

      let nuevoEstadoIncial = estadoInicial.filter((lis) => lis?.payroll_period === formulario.PaymentFrecuency)
      nuevoEstadoIncial = nuevoEstadoIncial.map((lis) => ({ ...lis, uuid: uuidv4() }))
      const inProgressPayroll = nuevoEstadoIncial.map((lis) => ({
        uuid: lis.uuid,
        id: lis.uuid,
        group_name: lis.group_name,
        frecuency_payment: lis.payroll_period,
        Company: null,
        status: "Activo",
        statusProgress: "Creación",
        inProgress: true,
        error: null,
        init_date: stateCalendario[0].startDate.toISOString(),
        end_date: stateCalendario[0].endDate.toISOString(),
        deleted: false,
      }))
      setEstadoInicial(nuevoEstadoIncial)

      dispatch({
        type: Types.ADD_IN_PROGRESS_PAYROLL,
        payload: inProgressPayroll
      })
      dispatch({
        type: Types.SET_IS_NEW_PAYROLL_CREATED,
        payload: true
      })


      cerrarModal();

      for await (const lis of nuevoEstadoIncial) {
        await createNewPayroll({
          variables: {
            input: {
              uuid: lis.uuid,
              group_name: lis.group_name,
              frecuency_payment: formulario.PaymentFrecuency,
              payroll_type: formulario.PayrollType,
              init_date: stateCalendario[0].startDate,
              end_date: stateCalendario[0].endDate,
              id_group_payroll: parseInt(lis.id),
              Incidencias: agregaIncidencias ? 1 : 0
            },
          },
        });
      }
    }

  }

  const toggleCalendar = () => {
    if (!PaymentFrecuency) {
     // alert("Debe seleccionar la frecuencia de pago.");
     ErrorAlert({text:'Debe seleccionar la frecuencia de pago.'});
      return;
    }

    // setShowCalendario(!showCalendario);
    if (showCalendario === false) {
      setShowCalendario(true);
    }
  }

  const eliminaSeleccionada = (objeto: any) => {
    setSeleccionadas([...seleccionadas, { id: objeto.id, group_name: objeto.group_name, payroll_period: objeto.payroll_period }])
    const nuevoSeleccionadas = estadoInicial.filter((lis) => lis?.id !== objeto.id)
    setEstadoInicial(nuevoSeleccionadas)
  }

  const agregaSeleccionadas = (objeto: any) => {
    const agregaSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.id === objeto.id)
    agregaSeleccionadas.map((lis: any) => {
      setEstadoInicial([...estadoInicial, lis])
    })
    const eliminaDisponibles = seleccionadas.filter((lis: any) => lis?.id !== objeto.id)
    setSeleccionadas(eliminaDisponibles)
  }

  const eliminaTodas = () => {
    const nuevoSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.payroll_period === formulario.PaymentFrecuency)
    setSeleccionadas(nuevoSeleccionadas)
    const nuevoEstadoInicial = allPayrollGroup.filter((lis: any) => lis?.payroll_period !== formulario.PaymentFrecuency)
    setEstadoInicial(nuevoEstadoInicial)
  }

  const agregaTodas = () => {
    const nuevoSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.payroll_period !== formulario.PaymentFrecuency)
    setSeleccionadas(nuevoSeleccionadas)
    const nuevoEstadoInicial = allPayrollGroup.filter((lis: any) => lis?.payroll_period === formulario.PaymentFrecuency)
    setEstadoInicial(nuevoEstadoInicial)
  }


  useEffect(() => {
    initData()
  }, [allPayrollGroup]);

  const initData = async () => {
    setEstadoInicial(allPayrollGroup)
  };


  const {
    PayrollType,
    InitDate,
    EndDate,
    PaymentFrecuency,
    PayrollGroups,
    onChange, formulario, reset, setForm
  } = useForm<PayrollModal>({
    PayrollType: "",
    InitDate: "",
    EndDate: "",
    PaymentFrecuency: "",
    PayrollGroups: ""
  });

  useEffect(() => {
    setTipoNomina(PayrollType)
    setAgregaIncidencias(false)
  },[PayrollType])


  const { addPayrollOpen, setAddPayrollOpen, PayrollDispatch, } = useContext(TabPayrollContext);

  const handleClose = () => {
    // Clean modal
    reset();
    // Close modal
    setAddPayrollOpen();
  }

  const cerrarModal = () => {
    setDisabledButton(false);
    createPrenominaModal({ id: '', createPrenomina: false }, dispatch)
    setStateCalendario([
      {
        startDate: subMonths(new Date(), 0),
        endDate: addDays(new Date(), 0),
        key: "selection"
      }]);
    setShowCalendario(!showCalendario);
  }

  const rangeSelection = (selection: any) => {
    setStateCalendario(selection);
    setShowCalendario(!showCalendario);
  }

  const showNotification = useCallback(debounce(() =>
    new Notification("Nómina creada exitosamente")
    , 3500), [])

  useEffect(() => {
    if (datosMutacion?.CREATE_PAYROLL?.mensaje === 'Creado') {
      showNotification()
    }
  
   const updatedInProgressPayroll = state.inProgressPayroll.map((lis: any) => {
      if (lis.uuid === datosMutacion?.CREATE_PAYROLL?.uuid) {
        let error = null
        if (datosMutacion?.CREATE_PAYROLL?.mensaje === 'Usuarios') {
          error = "No puedes crear una nómina sin usuarios enlazados"
        } else if (datosMutacion?.CREATE_PAYROLL?.mensaje === 'Nomina') {
          error = "Ya existe una nómina terminada con el periodo seleccionado"
        } else if (datosMutacion?.CREATE_PAYROLL?.mensaje === 'Politica') {
          error = "No puedes crear una nómina sin políticas enlazadas"
        } else if (datosMutacion?.CREATE_PAYROLL?.mensaje !== 'Creado') {
          error = "Error al crear nómina"
        }
        return {
          ...lis,
          inProgress: false,
          error,
          id: datosMutacion?.CREATE_PAYROLL?.id
        }
      }
      return { ...lis }
    })
    dispatch({
      type: Types.UPDATE_IN_PROGRESS_PAYROLL,
      payload: updatedInProgressPayroll
    })
  }, [datosMutacion?.CREATE_PAYROLL])

  const handleAddIncidencias = (e: any) => {
    const {id, checked} = e.target
    if (checked === true) {
      setAgregaIncidencias(true)
    } else {
      setAgregaIncidencias(false)
    }
  }

  return (
    <Dialog onClose={cerrarModal} aria-labelledby="customized-dialog-title" open={state.createPrenomina} fullWidth={false} maxWidth={"md"}>
      <div className={styles.contenedorTitulo}>
        <span className={styles.tituloCrearModal}>Crear pre-nómina</span>
      </div>
      <DialogContent>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.contenedorInputs}>

            <div className={styles.columnaUno}>
              <fieldset className={styles.fieldsetInput}>
                <legend className={styles.tituloFieldset}>Tipo de nómina</legend>
                <select
                  value={PayrollType}
                  className={styles.selectTipoNomina}
                  onChange={({ target }) =>
                    onChange(target.value as string, "PayrollType")
                  }
                  name="PayrollType"
                >
                  <option value="" disabled className="optionSelect">
                    Tipo Nomina
                  </option>
                  <option value="Periodica">Periódica</option>
                  <option value="Extraordinaria">Extraordinaria</option>
                </select>
              </fieldset>
            </div>

            <div className={styles.columnaDos}>
              <fieldset className={styles.fieldsetInput}>
                <legend className={styles.tituloFieldset}>Frecuencia de pago</legend>
                <select
                  value={PaymentFrecuency}
                  onChange={({ target }) =>
                    onChange(target.value as string, "PaymentFrecuency")
                  }
                  name="PaymentFrecuency"
                  className={styles.selectTipoNomina}
                >
                  <option value="" disabled className="optionSelect">
                    Frecuencia de pago
                  </option>
                  <option value="Semanal">Semanal</option>
                  <option value="Catorcenal">Catorcenal</option>
                  <option value="Quincenal">Quincenal</option>
                  <option value="Mensual">Mensual</option>
                </select>
              </fieldset>
            </div>

            <div className={styles.columnaTres} onClick={() => toggleCalendar()}>
              <div className={styles.contenedorFechas}>
                <div >
                  {stateCalendario.map(home => <div id="hora">
                    <span className={styles.textoFecha}>{new Date(home.startDate).toLocaleDateString()} - {new Date(home.endDate).toLocaleDateString()}</span></div>)}

                </div>
                <div id="payroll_2" className={styles.contenedorCalendario}>
                  <img
                    className={styles.calendario}
                    src="/assets/svg/icono-calendario.svg"
                    onClick={() => toggleCalendar()}
                    alt="Editar" />
                  {
                    (showCalendario ?
                      <DateRange className="Prueba"
                        onChange={(item: any) => {                          
                          const startDate                 :any  = parseISO(format(item.selection.startDate, 'yyyy-MM-dd'));                          
                          const endDate                   :any  = parseISO(format(item.selection.endDate, 'yyyy-MM-dd'));
                          const dayStart                  :any  = getDate(startDate);
                          const dayDiff                   :any  = differenceInCalendarDays(endDate,startDate)+1;
                          const daysMonth                 :any  = getDaysInMonth(startDate);                
                          const month                     :any  = getMonth(startDate);       
                          const leapYear                  :any  = isLeapYear(startDate);                                                       
                          const monthNumberStartDate      :any  = getMonth(startDate);                      
                          const monthNumberEndDate        :any  = getMonth(endDate);  
                          const values                    :any  = {
                                                                    catorcenal          : 14,
                                                                    quincenal           : [13,14,15,16],                                                                  
                                                                    week                :  7,
                                                                    startFortnight      : 16,
                                                                    endMonth            : 31  
                                                                   };
                      
                          switch (PaymentFrecuency) {
                            case "Semanal":
                              if(dayDiff !== values.week ){
                              ErrorAlert({text:'Para la nomina semanal debe seleccionar 7 días.'});
                              return;
                              }                            
                            break;
                            case "Catorcenal":
                              if(dayDiff !== values.catorcenal){
                              ErrorAlert({text:'Para la nomina catorcenal debe seleccionar 14 días.'});
                              return;
                              }                            
                            break;
                            case "Quincenal":
                              switch (month) {
                                   case 0 : case 2 : case 4 : case 6 : case 7 : case 9 : case 11 :
                                    console.log(dayDiff )
                                   if( dayStart === values.startFortnight ){
                                      if (dayDiff !== values.quincenal[3]){
                                       return  ErrorAlert({text:`Para la nomina quincenal debe seleccionar 15 días`});  
                                      }
                                    }
                                    else if(dayDiff !== values.quincenal[2]){  
                                       return  ErrorAlert({text:`Para la nomina quincenal debe seleccionar 15 días`});                                      
                                    }   
                                   break;
                                   case 1 : 
                                     if(leapYear){
                                      if( dayStart === values.startFortnight){
                                        if (dayDiff !== values.quincenal[1]){
                                         return  ErrorAlert({text:`Para la nomina quincenal debe seleccionar 15 días`});  
                                        }
                                      }
                                      else if(dayDiff !== values.quincenal[2]){  
                                         return  ErrorAlert({text:`Para la nomina quincenal debe seleccionar 15 días`});                                      
                                      }   
                                    }
                                    else{
                                      if( dayStart === values.startFortnight ){
                                        if (dayDiff !== values.quincenal[0]){
                                         return  ErrorAlert({text:`Para la nomina quincenal debe seleccionar 15 días`});  
                                        }
                                      }
                                      else if(dayDiff !== values.quincenal[2]){  
                                         return  ErrorAlert({text:`Para la nomina quincenal debe seleccionar 15 días`});                                      
                                      }   
                                    }                             
                                   break;
                                   default:
                                    if(dayDiff !== values.quincenal[2]){
                                      return  ErrorAlert({text:`Para la nomina quincenal debe seleccionar 15 días`});
                                     }         
                                  break;
                                 }                                               
                            break;
                            case "Mensual":                                
                              if( monthNumberStartDate === monthNumberEndDate ){
                                if(daysMonth !== dayDiff){
                                  ErrorAlert({text:`Para la nomina mensual debe seleccionar ${daysMonth} días`});
                                  return;
                                }                                    
                              }
                              else{
                              ErrorAlert({text:`Para la nomina mensual debe ser el mismo mes`});  
                              } 
                            break;         
                          }  
                          // setStateCalendario([item.selection]);
                          rangeSelection([item.selection])
                        }}
                        retainEndDateOnFirstSelection={true}
                        editableDateInputs={true}
                        dateDisplayFormat={"dd/MM/yyyy"}
                        moveRangeOnFirstSelection={false}
                        // locale={es}
                        ranges={stateCalendario}
                        scroll={{ enabled: true }}
                      /> : null
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          {tipoNomina === "Extraordinaria" ?
          <div className={styles.contenedorCheck}>
            <div>
              <div id="colaboladores" className={styles.checkboxitem}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="check"
                    key="check"
                    type="checkbox"
                    onChange={(e:any) => { handleAddIncidencias(e) }} 
                  ></input>
                    <label htmlFor="check" className={styles.pc_textoContenedorCheck}>N&oacute;mina Extraordinaria de Incidencias</label>
                </div>
              </div>
            </div>
          </div>
          :<div className={styles.contenedorCheck}></div>
          }
          <div className={styles.contenedorTitulosColumnas}>
            <div className={styles.contenedorTituloUno}>
              <span className={styles.titulosColumnas}>Todos los grupos de nómina disponibles</span>
            </div>
            <div className={styles.contenedorTituloDos}>
              <span className={styles.titulosColumnas}>Grupos de nóminas seleccionadas</span>
            </div>
          </div>
          <div className={styles.contenedorColumnas}>
            <div>
              <div className={styles.columnaTabla}>
                <ul className='ulDisponibles'>
                  {
                    seleccionadas && seleccionadas.map((item: any) => {
                      //if(item && item.status==='Pending')
                      return (
                        item.payroll_period === formulario.PaymentFrecuency && (
                          <>
                            <li className="listaGruposDisponibles">
                              <div>
                                <span className="textoGrupoDisponibles">
                                  {item.group_name}
                                </span>
                              </div>
                              <div
                                className="botonListaDisponibles"
                                onClick={() => agregaSeleccionadas({ id: item.id, group_name: item.group_name })}>
                                <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-derecha.svg' />
                              </div>
                            </li>
                          </>
                        )
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            <div id='payroll_3'>
              <div className={styles.botonIzquierda}>
                <img
                  onClick={() => eliminaTodas()}
                  className={styles.imagenCursor}
                  src='/assets/icons/icono-flecha-izquierda.svg' />
              </div>
              <div className={styles.botonDerecha}>
                <img
                  onClick={() => agregaTodas()}
                  className={styles.imagenCursor}
                  src='/assets/icons/icono-flecha-derecha.svg' />
              </div>
            </div>
            <div className={styles.columnaTabla}>
              <ul className='ulDisponibles'>
                {
                  estadoInicial && estadoInicial.map((item: any) => {
                    //if(item && item.status==='Pending')
                    return (
                      item.payroll_period === formulario.PaymentFrecuency && (
                        <>
                          <li className="listaGruposSeleccionadas">
                            <div
                              className="botonListaSeleccionadas"
                              onClick={() => eliminaSeleccionada({ id: item.id, group_name: item.group_name, payroll_period: item.payroll_period })}>
                              <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-izquierda.svg' />
                            </div>
                            <div>
                              <span className="textoGrupoSeleccionadas">
                                {item.group_name}
                              </span>
                            </div>
                          </li>
                        </>
                      )
                    )
                  })
                }
              </ul>
            </div>
          </div>
          <div className={styles.contenedorBotones}>
            <div className={styles.botonCancelar} onClick={cerrarModal}>
              <span className={styles.textoBoton}>Cancelar</span>
            </div>
            <div onClick={() => guardarInformacion()} className={styles.botonCrear + " " + (disabledButton ? styles.disabledButton : '')}>
              <span className={styles.textoBoton}>Crear</span>
            </div>
          </div>
        </div>
      </DialogContent>

    </Dialog>
  )
}

export default CreaPayroll