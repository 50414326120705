import { useEffect,useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { HistoricalMenuCampaign } from './components/HistoricalMenuCampaign';
import "./css/CardCampaigns.css"
import { HistoricalCampaign } from './HistoricalCampaigns';

const HistoricalCard = ({ campaign }: { campaign: HistoricalCampaign }) => {
  const {
    id,
    nameCampaign,
    startCampaign,
    endCampaign,
    maxObjectives,
    finishedObjectives,
    totalCollaborators,
      } = campaign;
  const history = useHistory();
  const dateInitFormat = moment(startCampaign).utc().format('DD/MM/YYYY');
  const dateEndFormat = moment(endCampaign).utc().format('DD/MM/YYYY');
  const [pctFin, setPctFin] = useState('0');

  const goToObjectives = () => {
    history.push(`/objetivos/${id}`)
  }

  useEffect(() => {
   const pct=((finishedObjectives / (maxObjectives * totalCollaborators))*100)
   let forPct
   if(isNaN(pct)){
    forPct='0.00'
    setPctFin(forPct)
   }else{
    forPct= pct.toFixed(2)
    setPctFin(forPct)
   }
     }, [finishedObjectives, maxObjectives, totalCollaborators]);

  return (
    <>
      <Card className='cardCampaign'>
        <CardContent className="contentCampaign">
          <div  style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',            
            padding: '0 20px',
            width: '100%'
          }}>
            <div style={{
              flexGrow: 1,
            }}>
              <div><span className='titleCampaign'>{nameCampaign} </span></div>
              <div><span className='periodoCampaign'>{`Periodo: ${dateInitFormat} - ${dateEndFormat}`}</span></div>
            </div>
            <div className='btnCampaign' style={{
              width: '20px'
            }}>
              <div className='menCampaign'>
                <HistoricalMenuCampaign id={id} campaign={{ ...campaign, status: "finalizado" }} />
              </div>
            </div>
          </div>
          <div className='definirCard' style={{
            marginTop:'50px',            
            cursor: 'pointer'
          }}
            onClick={goToObjectives}
          >
         
            <div className='evaluarContain'>
              <div><span className='evaluarTxt'>{pctFin} %</span></div>
              <div className='contMsjEvaluar'><span className='msjEvaluar'>Finalizada</span> </div>
            </div>
            <div className="ev-line"></div>
            <div className='evaluarContain'>
              <div><span className='evaluarTxt'>{finishedObjectives} de {maxObjectives * totalCollaborators}</span></div>
              <div><span className='msjEvaluar'>Objetivos</span></div>
            </div>
            <div className="ev-line"></div>
            <div className='evaluarContain'>
              <div><span className='evaluarTxt'>{totalCollaborators}</span></div>
              <div><span className='msjEvaluar'>Colaboradores</span></div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default HistoricalCard

