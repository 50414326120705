/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery,useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import PayrollProcessContext from "../../context/PayrollProcess/PayrollProcessContext";
import styles from "../../components/Payroll/PayrollStyles.module.css";
import CustomTabs from '../Collaborators/Tab/CustomTabs';
import CustomTab from '../Collaborators/Tab/CustomTabMain';
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import {
        GET_PAYROLL_DISPERSION_FINIQUITO_OUT,
        GET_COLLABORATORS_OUTSORCERS_DISPERSION_FINIQUITO,        
        GET_ALL_DISPERSIONLAYOUT_FINIQUITO_OUT} from "../../Querys/querys";
import { getDateDay, getDateYear } from "../../helpers/Payroll/Payroll";
import { formatter } from "../../helpers/formatoMoneda";
import AutorizaOutsorcers from "./modals/AutorizaOutsorcers";
import { openAutorizaOutsorcers, openDispersionConfirmaOutsorcers, openEliminaOutsorcer } from "../../context/OutsorcersNomina/Actions";
import OutsorcersNominaContext from "../../context/OutsorcersNomina/OutsorcersNominaContext";
import DispersionOutsorcers from "./modals/DispersionOutsorcers";
import DescartaOutsorcers from "./modals/DescartaOutsorcers";
import { openfondosInsuficientes, openSinNominasSeleccionadas } from "../../context/PayrollProcess/Actions";
import SinNominasModal from "../Payroll/Modals/SinNominasModal";
import FondosInsufucientesModal from "../Payroll/Modals/FondosInsuficientesModal";
import { CreateExcelFilePayrollDispersar } from "../Payroll/Modals/ExcelPayrollD";
import { ErrorAlert } from "../../alerts/errorAlert";


const OutsourcersDispersionLiquidacion = () =>{
  const {state, dispatch} = useContext(OutsorcersNominaContext)
  const {state: statePayroll, dispatch: dispatchPayroll} = useContext(PayrollProcessContext)
  const { id, tab ,tipo} = useParams<any>();
  const history = useHistory();
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true)
  const [inicio, setInicio] = useState(0)
  const [total, setTotal] = useState(0)
  const [disponibleDispersar, setDisponibleDispersar] = useState(true)
  
  const [diferencia, setDiferencia] = useState(0)
  const [filtrado, setFiltrado] = useState<any[]>([])
  const [datosTablas, setDatosTablas] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [isCheck, setIsCheck] = useState<any[]>([])
  const [procesoDispersion, setProcesoDispersion] = useState(false)
  const [barraPorcentaje, setBarraPorcentaje] = useState(0)
  const [registrosEnEspera, setRegistroEnEspera] = useState(0)
  const [dispersados,setDispersados] = useState(0)
  const [randomRequest, setRandomRequest] = useState(Math.random() * 10000);
  const [disabledTerminar,setDisabledTerminar]=useState(true)
  const [statusEnd,setStatusEnd]=useState(0)

  const handleChange = () =>{
  }
  
  const {loading:loadingPrincipal, error:errorPrincipal, data:dataPrincipal} = useQuery(GET_PAYROLL_DISPERSION_FINIQUITO_OUT,{
    variables:{
      getPayrollDispersionId: id,
      randomRequest,
    }
  })

  const PayrollData = dataPrincipal?.GET_PAYROLL_DISPERSION_FINIQUITO_OUT

  const {data:dataDispersion, loading:loadingTabla, startPolling, stopPolling} = useQuery(GET_COLLABORATORS_OUTSORCERS_DISPERSION_FINIQUITO,{
    variables: { getCollaboratorsOutsorcersDispersionId: id },
  });

  const {data: dataDipsersionExcell, loading: loadingDispersionExcell } = useQuery(GET_ALL_DISPERSIONLAYOUT_FINIQUITO_OUT, {
    variables: { getAllDispersionlayoutId: id },
  });

  const allPayrollCollaboratorExcell = dataDipsersionExcell?.GET_ALL_DISPERSIONLAYOUT_FINIQUITO_OUT;

  const allPayrollDispersion = dataDispersion?.GET_COLLABORATORS_OUTSORCERS_DISPERSION_FINIQUITO

  const datosIniciales = () =>{
    setCargandoPrincipal((current) => current = false)
    setInicio((current) =>current = PayrollData?.saldoCuenta)
  }

  const busqueda = (e:any)=>{
      setSearch((current) => current = e.target.value)
    guardaPaginado()
  }

  const getDatosTabla = async() =>{
    setDatosTablas([...allPayrollDispersion])
    setRegistroEnEspera((current)=> current = allPayrollDispersion.filter((e:any) => e.idDispersion > 0 && e.dispersionStatus ==='ENPROCESO'))
    const tokenActual = PayrollData?.tokenActual

    let registrosConTockenEnEspera = (allPayrollDispersion.filter((e:any) =>
    e.tokenDispersar === tokenActual &&
    (e.dispersionStatus === 'LIQUIDADO' || e.dispersionStatus==='ERROR'))).length
    let porcentajeDispersados =(registrosConTockenEnEspera/ PayrollData?.numeroRegistrosToken)*100
    setBarraPorcentaje((current) => current = porcentajeDispersados)
    let registrosLiquidado = (allPayrollDispersion.filter((e:any) => e.dispersionStatus ==='LIQUIDADO')).length
    setDispersados((current) => current = registrosLiquidado)
  }

  const guardaPaginado = () =>{
    const results = !search ? datosTablas : datosTablas?.filter(
      (dato) => (dato.colaborator.toLowerCase().includes(search.toLocaleLowerCase())
      ))
      setFiltrado((current) => current = results)
  }

  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([])
    }
    let filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (lis.statusOutsorcer ==='Autorizado')
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    allPayrollDispersion.filter((lis:any)=>{
      if(lis.id===id && lis.statusOutsorcer ==='Autorizado'){
        setIsCheck([...isCheck, id])
      }
    })
    
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }
  }

  const actualizaSaldos = ( ) =>{
    let arrayTotales = (allPayrollDispersion?.filter((lis:any)=>{
      return isCheck.includes(lis.id)
    }).map((lis:any) =>(lis.netIncomeTaxable)))

    let arrayLiquidadas = (allPayrollDispersion?.filter((lis:any) =>lis?.dispersionStatus === 'LIQUIDADO'))

    if(arrayTotales?.length > 0) {
      const suma = (acc:any, curr:any) => acc +curr

      setTotal(arrayTotales.reduce(suma))
      setDiferencia(inicio - arrayTotales.reduce(suma))

      if((inicio - arrayTotales.reduce(suma)) >= 0){
        setDisponibleDispersar(true)
      }else{
        setDisponibleDispersar(false)
      }

    }else{
      setTotal(0)
      setDiferencia(0)
      setDisponibleDispersar(true)
    }
  }

  const reinicioDatos = () =>{
    setIsCheck([])
    actualizaSaldos()
  }

  const handleOnExport = (Tipo: any) => {
    if(Tipo === 'Layout') {
      // var woorkBook = utils.book_new(),
      // woorkSheet = utils.json_to_sheet(allPayrollCollaboratorExcell);

      // utils.book_append_sheet(woorkBook,woorkSheet,"ReporteDispersionLayout")

      // writeFile(woorkBook, "ReporteDispersionLayout.xlsx"
    } else {
       CreateExcelFilePayrollDispersar(allPayrollCollaboratorExcell);
    }
  }
    
  const updateDataAfterDispersar = () => {
    setRandomRequest(Math.random() * 10000);
  }

  const abreAutorizaOutsorcers =() =>{
    openDispersionConfirmaOutsorcers({openDispersionOutsorcer:true}, dispatch)
  }

  const abreEliminaDescartaOutsorcer =(id:any,dispersionStatusOutsorcer:any)=>{
    if(dispersionStatusOutsorcer==="LIQUIDADO"){
      ErrorAlert({text:'No es posible eliminar al colaborador'});  
    }else{
      openEliminaOutsorcer({openDescartarOutsorcers:true, _id:id}, dispatch)
    }
   }

  const openFondosInsuficientes = () => {
    openfondosInsuficientes({aceptarDisperar:true}, dispatchPayroll)
  }

  const openSinSeleccion = () => {
    openSinNominasSeleccionadas({sinNominasSeleccionadas:true }, dispatchPayroll);
  }

  const irTerminar = () => {
    history.push(`/outsorcersTerminarFiniquito/${id}/${tab}`);    
  }



   useEffect(()=>{
      let terminarEnd = allPayrollDispersion?.reduce((cont:any,col:any)=>{
        if(col.dispersionStatusOutsorcer === "LIQUIDADO")
          return cont+1;
        else
          return cont;
      },0)
      //
      if(terminarEnd >0){
        setDisabledTerminar(false)    
        setStatusEnd(terminarEnd)  
      }
    else{
      setDisabledTerminar(true)
      setStatusEnd(0)      
    }   
  },[])

  useEffect(()=>{
    if(dataPrincipal){
      datosIniciales()
      actualizaSaldos()
    }
  },[dataPrincipal])

  useEffect(()=>{
    if(datosTablas){
      guardaPaginado()
    }
  },[datosTablas, search])

  useEffect(()=>{
    if(allPayrollDispersion){
      getDatosTabla();
      actualizaSaldos()
    }
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[allPayrollDispersion, startPolling, stopPolling, isCheck])
  
  return(
    <>
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={1}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Outsorcers" value={0} />
            <CustomTab label="Historicas" value={1} />
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}>
            </div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
          </div>
        </div>
          <div className={styles.pc_contenedorBotones}>
            {(isCheck.length > 0)?
              (disponibleDispersar)?
                <button
                  className={styles.pd_botonDispersarDos}
                  onClick={()=> abreAutorizaOutsorcers()}
                >
                  <div className={styles.pd_iconoDispersarDos}></div>
                    Dispersar
                  </button>
            :
              <button
                className={styles.pd_botonDispersarDosInactivo}
                onClick={()=> openFondosInsuficientes()}
              >
                <div className={styles.pd_iconoDispersarDos}></div>
                  Dispersar
              </button>
            :
              <button
                className={styles.pd_botonDispersarDosInactivo}
                onClick={()=> openSinSeleccion()}
              >
                <div className={styles.pd_iconoDispersarDos}></div>
                  Dispersar
              </button>
            }
          { parseInt(statusEnd.toString()) > 0 ?
              <button
                className={styles.pd_botonDispersarDos}   
                disabled={disabledTerminar}
                onClick={() => {irTerminar()               
                }}
                >
                <div>Ir a Terminar </div>
                <div className={styles.pc_iconoDispersar}></div>
              </button>         
          :
          <button
              className={styles.pd_botonDispersarDosInactivo}   
              disabled={disabledTerminar}
              onClick={() => {irTerminar()               
              }}
              >
              <div>Ir a Terminar </div>
              <div className={styles.pc_iconoDispersar}></div>
          </button>
         }
        </div>
      </div>
        {(cargandoPrincipal === true || loadingPrincipal === true)?
          <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.contenedorLoading}>
              <div className={styles.loading}></div>
            </div>
          </div>
          :
          <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.pc_tituloContenedorPrincipal}>
              <div className={styles.pc_contenedorNombre}>
                <div>
                  <span className={styles.pc_tituloPrincipal}>{tab}</span>
                </div>
                <div>
                  <span className={styles.pc_tituloPeriodo}>Peri&oacute;do &nbsp;{getDateDay(PayrollData?.fechaInicio)}</span> &nbsp; - &nbsp;
                  <span className={styles.pc_tituloPeriodo}>{getDateDay(PayrollData?.fechaFin)}</span>&nbsp;
                  <span className={styles.pc_tituloPeriodo}>{getDateYear(PayrollData?.fechaFin)}</span>
                </div>
              </div>
              <div className={styles.pd_contenedorTituloSaldos}>
                <div className={styles.pd_cuentaBancaria}>
                  <div className={styles.pd_textoCuentaBancaria}>
                    Cuenta Bancaria
                  </div>
                  <div className={styles.pd_contenedorCuentaBancaria}>
                    {PayrollData?.cuentaBancaria}
                  </div>
                </div>
                <div className={styles.pd_contenedorSaldos}>
                  <div className={styles.pd_contenedorInterno}>
                    <div className={styles.pd_tituloSaldos}>
                      Saldo disponible
                    </div>
                    <div className={styles.pd_saldoInterno}>
                      {formatter(inicio)}
                    </div>
                  </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Monto a pagar
                  </div>
                  <div className={styles.pd_saldoInterno}>
                    {formatter(total)}
                  </div>
                </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Diferencia
                  </div>
                    {(disponibleDispersar)?
                      <div className={styles.pd_saldoInterno}>
                        {formatter(diferencia)}
                      </div>
                    :
                      <div className={styles.pd_saldoInternoNegativo}>
                        {formatter(diferencia)}
                      </div>
                    }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.pc_contenedorBuscadorDescargar}>
            <div className={styles.pc_contenedorBuscador}>
              <input
                value={search}
                className={styles.pc_inputBuscador}
                type="text"
                placeholder="Ingresa el nombre del colaborador"
                onChange={busqueda}
              />
              <div className={styles.pc_iconoBuscador}></div>
            </div>
            <div className={styles.contenedorBotonesDescargas}>
              <button
                className={styles.pc_botonDescargar}
                onClick={()=> handleOnExport("Reporte")}>
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar reporte
                </div>
              </button>
            </div>
          </div>
          <div className={styles.p_contenedorTablaDispersion}>
            <table className={styles.pd_tablaPrincipal}>
              <thead className={styles.pd_contenedorTitulos}>
                <td className={`${styles.pd_columnaTitulos} ${styles.pd_primerColumnaTitulo}`}>
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="check"
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e)}
                    ></input>
                      <label htmlFor="check"> Colaboradores </label>
                    </div>
                </td>
                <td className={styles.pd_columnaTitulos}>RFC</td>
                <td className={styles.pd_columnaTitulos}>Banco</td>
                <td className={styles.pd_columnaTitulos}>Cuenta bancaria</td>
                <td className={styles.pd_columnaTitulos}>CLABE</td>
                <td className={styles.pd_columnaTitulos}>Total a dispersar no gravable</td>
                <td id="payroll_9" className={styles.pd_columnaTitulos}>Estatus</td>
                <td className={styles.pd_columnaTitulos}>Accion</td>
              </thead>
              <tbody className={styles.pc_contenedorTitulos}>
                {filtrado?.map((lis:any)=>(
                  <tr className={styles.pc_bordePrimeraColumna}>
                    <td className={`${styles.pd_primerColumnaTitulo}`}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={lis?.id}
                          key={lis?.id}
                          type="checkbox"
                          checked ={isCheck.includes(lis?.id)}
                          value={lis?.id}
                          onChange={(e) => handleClick(e)}
                          disabled={(lis?.dispersionStatusOutsorcer !== "EnEspera")?true:false}
                          ></input>
                        <label htmlFor={lis?.id} className={styles.datosLabel}>  {lis?.colaborator} </label>
                      </div>
                    </td> 
                    <td className={styles.pc_columnaDatos}>{lis?.RFC}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.bank}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.accountNumber}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.clabeNum}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.TotalIncomeNotTaxable)}</td>
                    <td className={styles.pc_columnaDatos}>
                    {(lis?.dispersionStatusOutsorcer === "ENPROCESO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                          <div className={styles.pd_textoBotonEstatusDos}>
                            En proceso...
                          </div>
                        </div>
                      : null
                      }
                      {(lis?.dispersionStatusOutsorcer === "ERROR")?
                        <div
                          className={styles.pd_botonEstatusTres}>
                          <div className={styles.pd_contenedorEstatusTres}>
                            <div className={styles.pd_contEstatusTres}>
                              <div className={styles.pd_iconoError}></div>
                                <div className={styles.pd_textoBotonError}>
                                  Error al dispersar
                                </div>
                              </div>
                            <div className={styles.pd_textoBotonErrorDos}>
                              {lis.mensajeDispersion}
                            </div>
                          </div>
                          <div className ={styles.pd_botonInternoDispersar}>
                            <div className={styles.pd_iconoIntentar}></div>
                          </div>
                        </div>
                      : null
                      }
                      {(lis?.dispersionStatusOutsorcer === "LIQUIDADO" || lis?.statusOutsorcer === "Liquidado")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                          <div className={styles.pd_textoBotonEstatusCuatro}>
                            Finalizado
                          </div>
                          <div className={styles.pd_iconoEstatusCuatro}></div>
                        </div>
                      : null
                      }
                    </td>
                    <td> 
                    {(lis?.statusOutsorcer === 'Cancelado' || lis?.statusOutsorcer === 'Pago por otro metodo' || lis?.statusOutsorcer ==='Devolver a revision')?
                      <div className={styles.po_contenedorBotonDescartado}>
                        <div
                        className={styles.po_botonEstatusTres}>
                        <div className={styles.po_contenedorEstatusTres}>
                          <div className={styles.po_contEstatusTres}>
                            <div className={styles.po_iconoError}></div>
                              <div className={styles.po_textoBotonError}>
                                Descartado
                              </div>
                            </div>
                          <div className={styles.po_textoBotonErrorDos}>
                            {(lis?.statusOutsorcer ==='Devolver a revision')?
                              <span>Devolver a revis&iacute;on</span>
                            :
                              lis?.statusOutsorcer
                            }
                          </div>
                        </div>
                      </div>
                      </div>
                      : null
                      }
                      {(lis?.statusOutsorcer ==='Autorizado')?
                        <div
                          className={styles.pp_contenedorIconoEliminar}
                          onClick={()=>abreEliminaDescartaOutsorcer(lis?.id,lis?.dispersionStatusOutsorcer)}
                        >
                         <img className={styles.pp_iconoEliminar} src="/assets/svg/icono_eliminar-nomina.svg"></img>
                          <span className={styles.pp_textoEliminar}>Descartar</span>
                        </div>
                      :null
                      }
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                  onClick={() => history.push(`/outsourcers`)}
              >
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Calcular"</div>
              </div>
                <button className={styles.pd_botonSalir}
                  onClick={() => history.push(`/outsourcers`)}
                >
                  Salir
                </button>
            </div>
          </div>
        </div> 
        }
        <DispersionOutsorcers
          totalesDispersar={isCheck?.length}
          totales={allPayrollDispersion?.length}
          seleccionados={isCheck}
          setProcesoDispersion={setProcesoDispersion}
          reinicia={() => reinicioDatos()}
          idRegistro={id}
          tipoNomina={"liquidacion"}
        />
        <DescartaOutsorcers 
          totalesDispersar={isCheck?.length}
          totales={allPayrollDispersion?.length}
          seleccionados={isCheck}
          setProcesoDispersion={setProcesoDispersion}
          reinicia={() => reinicioDatos()}
          idRegistro={id}
          tiponomina={"liquidacion"}
        />
        <SinNominasModal/>
        <FondosInsufucientesModal />
    </>
  )
}

export default OutsourcersDispersionLiquidacion
