/* eslint-disable react-hooks/exhaustive-deps */
import {
    Dialog,
    DialogContent,
  } from '@material-ui/core';
  import { useEffect, useState, useContext, useCallback } from 'react';
  import { debounce } from "lodash";
  import { v4 as uuidv4 } from 'uuid';
  import { MonthlyISRModal } from '../../../interfaces/TabMonthlyISR.interfaces';
  import { useForm } from '../../../hooks/useForm';
  import '../Payroll.css'
  import "react-date-range/dist/styles.css";
  import "react-date-range/dist/theme/default.css";
  import { es } from 'date-fns/locale';
  import { DateRange } from 'react-date-range'
  import { useMutation, useQuery } from "@apollo/client";
  import { GET_ALL_PAYROLL_GROUP, CREATE_MONTHLYISR, GET_ALL_MONTHLYISR } from "../../../Querys/querys";
  import styles from '../PayrollStyles.module.css'
  import MonthlyISRProcessContext, { Types } from '../../../context/PayrollProcess/MonthlyISRProcessContext';
  import { createMonthlyISRModal } from '../../../context/PayrollProcess/MonthlyISRActions';
  import { ErrorAlert } from "../../../alerts/errorAlert";
  import { addDays } from 'date-fns';
import { subMonths } from 'date-fns';
  
  const CrearMonthlyISR = (props: any) => {
    const { state, dispatch } = useContext(MonthlyISRProcessContext)
    const [estadoInicial, setEstadoInicial] = useState<any[]>([])
    const [seleccionadas, setSeleccionadas] = useState<any[]>([])
    const [showCalendario, setShowCalendario] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [stateCalendario, setStateCalendario] = useState([
      {
        startDate: subMonths(new Date(), 0),
        endDate: addDays(new Date(), 0),
        key: "selection"
      }]
    );
    const resultPayrollGroup = useQuery(GET_ALL_PAYROLL_GROUP);
    const allPayrollGroup = resultPayrollGroup.data?.GET_ALL_PAYROLL_GROUP;
    const [createNewPayroll, { data: datosMutacion, loading: loadingMutation }] = useMutation(CREATE_MONTHLYISR, {
      refetchQueries: [
        { query: GET_ALL_MONTHLYISR }  
      ],
    });
  
  
    const guardarInformacion = async () => {
      if (!disabledButton) {
        setDisabledButton(true);
  
        if (formulario.frecuency_payment === "") {
          ErrorAlert({ text: "Frecuencia de pago es requerida." });
          setDisabledButton(false);
          return;
        }
  
        let nuevoEstadoIncial = estadoInicial.filter((lis) => lis?.payroll_period === formulario.frecuency_payment)
        nuevoEstadoIncial = nuevoEstadoIncial.map((lis) => ({ ...lis, uuid: uuidv4() }))
        const inProgressPayroll = nuevoEstadoIncial.map((lis) => ({
          uuid: lis.uuid,
          id: lis.uuid,
          group_name: lis.group_name,
          frecuency_payment: lis.payroll_period,
          Company: null,
          status: "Activo",
          statusProgress: "Creación",
          inProgress: true,
          error: null,
          init_date: stateCalendario[0].startDate.toISOString(),
          end_date: stateCalendario[0].endDate.toISOString(),
          deleted: false,
        }))
        setEstadoInicial(nuevoEstadoIncial)
  
        dispatch({
          type: Types.ADD_IN_PROGRESS_PAYROLL,
          payload: inProgressPayroll
        })
  
        cerrarModal();
  
        for await (const lis of nuevoEstadoIncial) {
          await createNewPayroll({
            variables: {
              input: {
                uuid: lis.uuid,
                group_name: lis.group_name,
                frecuency_payment: formulario.frecuency_payment,
                init_date: stateCalendario[0].startDate,
                end_date: stateCalendario[0].endDate,
                id_group_payroll: parseInt(lis.id)
              },
            },
          });
        }
      }
  
    }

    const rangeSelection = (selection: any) => {
      setStateCalendario(selection);
      setShowCalendario(!showCalendario);
    }
  
  
    const toggleCalendar = () => {
      if (!frecuency_payment) {
       // alert("Debe seleccionar la frecuencia de pago.");
       ErrorAlert({text:'Debe seleccionar la frecuencia de pago.'});
        return;
      }
  
      // setShowCalendario(!showCalendario);
      if (showCalendario === false) {
        setShowCalendario(true);
      }
    }
  
    const eliminaSeleccionada = (objeto: any) => {
      setSeleccionadas([...seleccionadas, { id: objeto.id, group_name: objeto.group_name, payroll_period: objeto.payroll_period }])
      const nuevoSeleccionadas = estadoInicial.filter((lis) => lis?.id !== objeto.id)
      setEstadoInicial(nuevoSeleccionadas)
    }
  
    const agregaSeleccionadas = (objeto: any) => {
      const agregaSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.id === objeto.id)
      agregaSeleccionadas.map((lis: any) => {
        setEstadoInicial([...estadoInicial, lis])
      })
      const eliminaDisponibles = seleccionadas.filter((lis: any) => lis?.id !== objeto.id)
      setSeleccionadas(eliminaDisponibles)
    }
  
    const eliminaTodas = () => {
      const nuevoSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.payroll_period === formulario.frecuency_payment)
      setSeleccionadas(nuevoSeleccionadas)
      const nuevoEstadoInicial = allPayrollGroup.filter((lis: any) => lis?.payroll_period !== formulario.frecuency_payment)
      setEstadoInicial(nuevoEstadoInicial)
    }
  
    const agregaTodas = () => {
      const nuevoSeleccionadas = allPayrollGroup.filter((lis: any) => lis?.payroll_period !== formulario.frecuency_payment)
      setSeleccionadas(nuevoSeleccionadas)
      const nuevoEstadoInicial = allPayrollGroup.filter((lis: any) => lis?.payroll_period === formulario.frecuency_payment)
      setEstadoInicial(nuevoEstadoInicial)
    }
  
  
    useEffect(() => {
      initData()
    }, [allPayrollGroup]);
  
    const initData = async () => {
      setEstadoInicial(allPayrollGroup)
    };
  
  
    const {
      frecuency_payment,
      onChange, formulario, reset, setForm
    } = useForm<MonthlyISRModal>({
      init_date: "",
      end_date: "",
      frecuency_payment: ""
    });

  
    const cerrarModal = () => {
      setDisabledButton(false);
      createMonthlyISRModal({ id: '', createMonthlyISR: false }, dispatch)
      setStateCalendario([
        {
          startDate: subMonths(new Date(), 0),
          endDate: addDays(new Date(), 0),
          key: "selection"
        }]);
      setShowCalendario(!showCalendario);
    }
  
    const showNotification = useCallback(debounce(() =>
      new Notification("Calculo creado exitosamente")
      , 3500), [])
  
    useEffect(() => {
      if (datosMutacion?.CREATE_MONTHLYISR?.mensaje === 'Creado') {
        showNotification()
      }
    
     const updatedInProgressPayroll = state.inProgressPayroll.map((lis: any) => {
        if (lis.uuid === datosMutacion?.CREATE_MONTHLYISR?.uuid) {
          let error = null
          if (datosMutacion?.CREATE_MONTHLYISR?.mensaje === 'Usuarios') {
            error = "No puedes crear un calculo sin usuarios enlazados"
          } else if (datosMutacion?.CREATE_MONTHLYISR?.mensaje === 'Nomina') {
            error = "Ya existe un calculo terminada con el periodo seleccionado"
          } else if (datosMutacion?.CREATE_MONTHLYISR?.mensaje === 'Politica') {
            error = "No puedes crear un calculo sin políticas enlazadas"
          } else if (datosMutacion?.CREATE_MONTHLYISR?.mensaje !== 'Creado') {
            error = "Error al crear el calculo"
          }
          return {
            ...lis,
            inProgress: false,
            error,
            id: datosMutacion?.CREATE_MONTHLYISR?.id
          }
        }
        return { ...lis }
      })
      dispatch({
        type: Types.UPDATE_IN_PROGRESS_PAYROLL,
        payload: updatedInProgressPayroll
      })
    }, [datosMutacion?.CREATE_MONTHLYISR])

  
    return (
      <Dialog onClose={cerrarModal} aria-labelledby="customized-dialog-title" open={state.createMonthlyISR} fullWidth={false} maxWidth={"md"}>
        <div className={styles.contenedorTitulo}>
          <span className={styles.tituloCrearModal}>Crear calculo ISR</span>
        </div>
        <DialogContent>
          <div className={styles.contenedorPrincipal}>
            <div className={styles.contenedorInputs}>  
              <div className={styles.columnaDos}>
                <fieldset className={styles.fieldsetInput}>
                  <legend className={styles.tituloFieldset}>Frecuencia de pago</legend>
                  <select
                    value={frecuency_payment}
                    onChange={({ target }) =>
                      onChange(target.value as string, "frecuency_payment")
                    }
                    name="frecuency_payment"
                    className={styles.selectTipoNomina}
                  >
                    <option value="" disabled className="optionSelect">
                      Frecuencia de pago
                    </option>
                    <option value="Semanal">Semanal</option>
                    <option value="Catorcenal">Catorcenal</option>
                    <option value="Quincenal">Quincenal</option>
                    <option value="Mensual">Mensual</option>
                  </select>
                </fieldset>
              </div>
  
              <div className={styles.columnaTres} onClick={() => toggleCalendar()}>
                <div className={styles.contenedorFechas}>
                  <div >
                    {stateCalendario.map(home => <div id="hora">
                      <span className={styles.textoFecha}>{new Date(home.startDate).toLocaleDateString()} - {new Date(home.endDate).toLocaleDateString()}</span></div>)}
  
                  </div>
                  <div id="payroll_2" className={styles.contenedorCalendario}>
                    <img
                      className={styles.calendario}
                      src="/assets/svg/icono-calendario.svg"
                      onClick={() => toggleCalendar()}
                      alt="Editar" />
{
                    (showCalendario ?
                      <DateRange className="Prueba"
                        onChange={(item: any) => {                          
                          rangeSelection([item.selection])
                        }}
                        retainEndDateOnFirstSelection={true}
                        editableDateInputs={true}
                        dateDisplayFormat={"dd/MM/yyyy"}
                        moveRangeOnFirstSelection={false}
                        locale={es}
                        ranges={stateCalendario}
                        scroll={{ enabled: true }}
                      /> : null
                    )
                  }
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.contenedorTitulosColumnas}>
              <div className={styles.contenedorTituloUno}>
                <span className={styles.titulosColumnas}>Todos los grupos de nómina disponibles</span>
              </div>
              <div className={styles.contenedorTituloDos}>
                <span className={styles.titulosColumnas}>Grupos de nóminas seleccionadas</span>
              </div>
            </div>
            <div className={styles.contenedorColumnas}>
              <div>
                <div className={styles.columnaTabla}>
                  <ul className='ulDisponibles'>
                    {
                      seleccionadas && seleccionadas.map((item: any) => {
                        //if(item && item.status==='Pending')
                        return (
                          item.payroll_period === formulario.frecuency_payment && (
                            <>
                              <li className="listaGruposDisponibles">
                                <div>
                                  <span className="textoGrupoDisponibles">
                                    {item.group_name}
                                  </span>
                                </div>
                                <div
                                  className="botonListaDisponibles"
                                  onClick={() => agregaSeleccionadas({ id: item.id, group_name: item.group_name })}>
                                  <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-derecha.svg' />
                                </div>
                              </li>
                            </>
                          )
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
              <div id='payroll_3'>
                <div className={styles.botonIzquierda}>
                  <img
                    onClick={() => eliminaTodas()}
                    className={styles.imagenCursor}
                    src='/assets/icons/icono-flecha-izquierda.svg' />
                </div>
                <div className={styles.botonDerecha}>
                  <img
                    onClick={() => agregaTodas()}
                    className={styles.imagenCursor}
                    src='/assets/icons/icono-flecha-derecha.svg' />
                </div>
              </div>
              <div className={styles.columnaTabla}>
                <ul className='ulDisponibles'>
                  {
                    estadoInicial && estadoInicial.map((item: any) => {
                      //if(item && item.status==='Pending')
                      return (
                        item.payroll_period === formulario.frecuency_payment && (
                          <>
                            <li className="listaGruposSeleccionadas">
                              <div
                                className="botonListaSeleccionadas"
                                onClick={() => eliminaSeleccionada({ id: item.id, group_name: item.group_name, payroll_period: item.payroll_period })}>
                                <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-izquierda.svg' />
                              </div>
                              <div>
                                <span className="textoGrupoSeleccionadas">
                                  {item.group_name}
                                </span>
                              </div>
                            </li>
                          </>
                        )
                      )
                    })
                  }
                </ul>
              </div>
            </div>
            <div className={styles.contenedorBotones}>
              <div className={styles.botonCancelar} onClick={cerrarModal}>
                <span className={styles.textoBoton}>Cancelar</span>
              </div>
              <div onClick={() => guardarInformacion()} className={styles.botonCrear + " " + (disabledButton ? styles.disabledButton : '')}>
                <span className={styles.textoBoton}>Crear</span>
              </div>
            </div>
          </div>
        </DialogContent>
  
      </Dialog>
    )
  }
  
  export default CrearMonthlyISR