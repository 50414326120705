import {createContext, Dispatch} from 'react';

type initialStateType = {
    _id:string,
    createModalCampaigns:boolean,  
    createModalUserKpi:boolean
    findModalCampaigns:boolean,
    createDuplicateCampaigns: boolean,    
    campaign: any   
}

type ModalPartial = {
    state:initialStateType,
    dispatch: Dispatch<any>
}

export const initialState = {
    _id: '',
    createModalCampaigns: false, 
    findModalCampaigns:false,    
    createDuplicateCampaigns: false,
    createModalUserKpi:false,
    campaign:null
}

export enum Types {
    CREATE_CAMPAIGNS       = "CREATE_CAMPAIGNS",
    CLEAR_CAMPAIGNS        = "CLEAR_CAMPAIGNS",
    FIND_DATE_CAMPAIGNS    ="FIND_DATE_CAMPAIGNS",
    CREATE_USER_KPI        = "CREATE_USER_KPI",
    CLEAR_USER_KPI_CAMPAIGNS  = "CLEAR_USER_KPI_CAMPAIGNS",
    CREATE_DUPLICATE_CAMPAIGNS = "CREATE_DUPLICATE_CAMPAIGNS"
}

const ModalInitialState = {
    state:initialState,
    dispatch:() => null
}

const CampaignContext = createContext<ModalPartial>(ModalInitialState)

export default CampaignContext; 
