import { useState, MouseEvent, useEffect } from "react";
// import Box from '@mui/material/Box';
import Box from '@mui/material/Box';
import AddTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import { Bar } from 'react-chartjs-2';
import FilterTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/FilterTable";
import FilterOptions from "./Fields/FilterOptions";
import { GET_MYMOOD_PROJECTS } from "../../Querys/querys";
import { useQuery, useLazyQuery } from "@apollo/client";
import DateRangeField from "./Fields/DateRangeField";
import { Alert } from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { endOfDay} from 'date-fns';
import styles from '../../components/Payroll/PayrollStyles.module.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from "moment";

const options: any = {
    indexAxis: 'x',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'right',
            display: false
        },
        title: {
            display: true,
            text: 'MY MOOD',
        },
        datalabels: {
            display: true,
            color: "white",
            formatter: Math.round,
            anchor: "end",
            align: "start",
            font: {
                weight: 'bold',
                size: 16,
            }
        }
    },
};
const fechaActual = moment().format();
const primerFecha = moment().subtract(12,'months').format()
const BodyStadisticts = ({ onHandleChange }: any) => {
    const [data, setData] = useState<any>();
    const [projects, setProject] = useState<any>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { data: myMoodResult } = useQuery(GET_MYMOOD_PROJECTS, { variables: { type: 'all' } });
    const [dates, setDates]: any = useState([{
        startDate:primerFecha,
        endDate: fechaActual
    }]);
    const [filters, setFilters] = useState(['all']);
    const [getMyMoodProjects, { data: resultMood }] = useLazyQuery(GET_MYMOOD_PROJECTS,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const [focusDate, setFocusDate] = useState(false);

    useEffect(() => {
        console.log('estas con las fechas',dates[0].startDate, endOfDay(dates[0].endDate))
        console.log('fechaActual',fechaActual, 'primer fecha',primerFecha)
        const interval = setInterval(() => {
            if (!focusDate) {
                getMyMoodProjects({
                    variables: {
                        type: filters.join(','),
                        startDate: dates[0].startDate,
                        endDate: endOfDay(dates[0].endDate)
                    }
                });
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [dates, filters, focusDate]);

    useEffect(() => {
        if (myMoodResult && myMoodResult.GET_MYMOOD_PROJECTS) {
            const data = myMoodResult.GET_MYMOOD_PROJECTS;
            setGraph(data);
        }
    }, [myMoodResult]);

    useEffect(() => {
        if (resultMood && resultMood.GET_MYMOOD_PROJECTS) {
            const data = resultMood.GET_MYMOOD_PROJECTS;
            setGraph(data);
        } else {
            setData({
                labels: [],
                datasets: []
            })
        }
    }, [resultMood]);


    const setGraph = (data: any) => {
        setData({
            labels: data.data,
            datasets: data.finalArrayStates,
        });
        setProject(data.projects ? data.projects.map((project: any) => { return project.projectName }) : []);
    }

    useEffect(() => {
        if (dates[0] || filters) {
            getMyMoodProjects(
                {
                    variables: {
                        type: filters.join(','),
                        startDate: dates[0].startDate,
                        endDate: dates[0].endDate
                    }
                }
            )
        }
    }, [dates, filters, getMyMoodProjects])

    const handleOpen = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

    return (
        <div>
            <Box p={1} pb={3} display="flex" flexDirection="row" >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems={'center'}
                    style={{ width: "100%" }}
                >
                    <Box className="mymood-date-field">
                        <DateRangeField setDate={setDates} setFocusDate={setFocusDate} containerClass={styles.fieldsetInput} daySelected2={dates[0].endDate} daySelected1={dates[0].startDate}></DateRangeField>
                    </Box>
                    <FilterTable onClick={handleOpen} />
                    <FilterOptions anchorEl={anchorEl} setAnchorEl={setAnchorEl} projects={projects} setData={setData} setFilters={setFilters} />

                </Box>
            </Box>

            {
                data && data.datasets && data.datasets.length === 0 ? (
                    <Alert severity="info">No hay datos para el periodo seleccionado</Alert>
                ) : (

                    <Bar type="bar" data={data} options={options} plugins={[ChartDataLabels]} height={100} />
                )
            }
            <Box  display="flex" marginTop={3} alignItems={'center'} onClick={onHandleChange} >
                {/* <AddTable
                            func={onHandleChange}
                            img={"blue_arrow_left.svg"}
                            text={"Regresar"}
                        /> */}
                <ArrowBackIcon
                    fontSize="small"
                    style={{ color: "#fabb00"}}
                />
                <span className="Return" style={{ marginTop:'0%'}} onClick={onHandleChange}>
                    Regresar
                </span>
            </Box>
        </div >
    )
}

export default BodyStadisticts;