import { useContext, useEffect, useState } from "react";
import { TextField, FormControl, InputLabel, MenuItem, Select,  Checkbox } from "@material-ui/core";
import styles from "../../../../styles/MiEmpresa/IdentidadLegal.module.css"
import EmpresaContext from "../../../../context/Empresa/EmpresaContext";
import { updateState, clearEmpresaModal } from "../../../../context/Empresa/Actions";
import { useFormik } from "formik"
import * as Yup from "yup"
import { Form } from "semantic-ui-react";
import FileHelperIncident from "../../../Collaborator/Expedient/Fields/FileHelperIncident";
import RemoveFile from "../../../../assets/svg/remove_file.svg";
import { useQuery,useMutation } from "@apollo/client";
import { 
  GET_ALL_ENTERPRISE,
  GET_ALL_REGIMENFISCAL,
  CREATE_ENTERPRISE,    
} from "../../../../Querys/querys";
import InputMask from "react-input-mask";
import { ErrorAlert } from '../../../../alerts/errorAlert';
import { SuccessfulAlert } from "../../../../alerts/successAlerts";
import {
  getMunicipios,
  getStates,
} from "../../../../helpers/Json/getStatesAndMunicipios";

  const IdentidadLegal = (props:any) => {

    const {state, dispatch} = useContext(EmpresaContext)
    const states = getStates('México');
    const [municipios, setMunicipios] = useState<Array<string>>([]);
    const [allRegimenFiscal, setAllRegimenFiscal] = useState<Array<any>>([]);
    const [createNewCompany] = useMutation(CREATE_ENTERPRISE, {
      refetchQueries: [{ query: GET_ALL_ENTERPRISE }],
    });
 
    const initalValues = () =>{
      return{
        nombre: state.identidadLegal.nombre,
        razonSocial: state.identidadLegal.razonSocial,
        logo: "",
        rfc: state.identidadLegal.rfc,        
        regimenFiscal: state.identidadLegal.regimenFiscal,
        direccion: state.identidadLegal.direccion,
        estado: state.identidadLegal.estado,
        codigoPostal: state.identidadLegal.codigoPostal,
        municipio: state.identidadLegal.municipio,
        legalRepresentative: state.identidadLegal.legalRepresentative,
        notarialTestimony: state.identidadLegal.notarialTestimony,
        notarialTestimonyVolume: state.identidadLegal.notarialTestimonyVolume ,
        notarialTestimonyDate: state.identidadLegal.notarialTestimonyDate ,
        notaryPublicNumber: state.identidadLegal.notaryPublicNumber,
        notaryPublic: state.identidadLegal.notaryPublic,
        judicialDistrict: state.identidadLegal.judicialDistrict,
        addressCourt:  state.identidadLegal.addressCourt,
        outsourcers: state.identidadLegal.outsourcers,
      }
    }

    const containsOnlyLetters = (value: any) => {
      return /^[a-zA-Z ]+$/.test(value);
    };
  
    const validationSchema = () =>{
      const evaluateAll= {
        nombre: Yup.string().required("Obligatorio").max(100, 'El tamaño maximo es 100 caracteres'),
        razonSocial: Yup.string().required("Obligatorio").max(100, 'El tamaño maximo es 100 caracteres'),
        rfc: Yup.string().required("Obligatorio"),
        direccion: Yup.string().required("Obligatorio").max(100,'El tamaño maximo es 100 caracteres'),
        codigoPostal: Yup.string().required("Obligatorio").max(5, 'El tamaño maximo es 5 caracteres'),
        legalRepresentative: Yup.string().required("Obligatorio"),
        notarialTestimony: Yup.string().required("Obligatorio").test(
          "containsOnlyLetters",
          "Ingresar texto únicamente",
          containsOnlyLetters
        ),
        notarialTestimonyVolume: Yup.string().required("Obligatorio").test(
          "containsOnlyLetters",
          "Ingresar texto únicamente",
          containsOnlyLetters
        ),
        notaryPublicNumber: Yup.string().required("Obligatorio"),
        notaryPublic: Yup.string().required("Obligatorio"),
        judicialDistrict: Yup.string().required("Obligatorio"),
        addressCourt: Yup.string().required("Obligatorio")  
      }

      const evaluateOnlyOutsources= {
        nombre: Yup.string().required("Obligatorio").max(100, 'El tamaño maximo es 100 caracteres'),
        razonSocial: Yup.string().required("Obligatorio").max(100, 'El tamaño maximo es 100 caracteres'),
        rfc: Yup.string().required("Obligatorio"),     
      }
      if(!props.outsourcers){
        return evaluateAll
      }else{
        return evaluateOnlyOutsources
      }
    }

    const cambioEstado = async(event:any, value:{}) =>{
      await updateState(event, state, dispatch,'identidadLegal',value, 'tabUno')
    }

    const formik = useFormik({
      initialValues: initalValues(),
      validationSchema: Yup.object(validationSchema()),
      onSubmit:(formData) =>{
        let bandera = true
        removeError()
        if(props.fileLogo === undefined){
          bandera = false
          ErrorAlert({ text: "Agregue logo." });
        }
        if(String(props.regimenFiscalState) === "" && ! props.outsourcers){
          bandera = false          
          const errorRegimenFiscal = document.querySelectorAll('#errorRegimenFiscal');          
          errorRegimenFiscal.forEach((regimen) => {
            const span = document.createElement('span')
            span.textContent = "Obligatorio."
            span.id="alertRegimen"
            regimen.appendChild(span)
          });
        }      
        if(String(props.estadoState) === "" && !props.outsourcers){
          bandera = false
          const errorEstado = document.querySelectorAll('#errorEstado');          
          errorEstado.forEach((list) => {
          const span = document.createElement('span')
          span.textContent = "Obligatorio."
          span.id="alertEstado"
          list.appendChild(span)
          });
        }

        if(String(props.municipioState) === "" && !props.outsourcers){
          bandera = false      
          const errorMunicipio = document.querySelectorAll('#errorMunicipio');          
          errorMunicipio.forEach((list) => {
            const span = document.createElement('span')
            span.textContent = "Obligatorio."
            span.id="alertMunicipio"
            list.appendChild(span)

          });
        }
      
        if(bandera && !props.outsourcers){
          state.identidadLegal.regimenFiscal = props.regimenFiscalState
          formData.regimenFiscal = props.regimenFiscalState
          state.identidadLegal.estado = props.estadoState
          formData.estado = props.estadoState
          state.identidadLegal.municipio = props.municipioState
          formData.municipio = props.municipioState
          state.identidadLegal.addressCourt=props.addressCourt
          state.identidadLegal.outsourcers = props.outsourcers
          formData.outsourcers = props.outsourcers
          props.setActivo('2')
          cambioEstado('', formData)
        }else{
         createNewCompany({
          variables: {
            input: {
              name: formData.nombre,
              logo: '',
              taxRegime: formData.rfc,
              RazonSocial: formData.razonSocial,              
              outsourcers: props.outsourcers,              
            },
          },
          }).then((data)=>{
              handleClose();
              SuccessfulAlert({
                  title: "¡Exito!",
                  text: "¡Se agregó la empresa!",
              });            
          })
        }
      }
    })

    const removeError = () =>{
      const errorRegimenFiscal = document.querySelectorAll('#errorRegimenFiscal');
      errorRegimenFiscal.forEach((regimen) => {
          const span = document.querySelector('#alertRegimen')
          if(span){
            regimen.removeChild(span)
          }          
      });

      const errorEstado = document.querySelectorAll('#errorEstado');
      errorEstado.forEach((list) => {
          const span = document.querySelector('#alertEstado')
          if(span){
            list.removeChild(span)
          }
          
      });

      const errorMunicipio = document.querySelectorAll('#errorMunicipio');
      errorMunicipio.forEach((list) => {
          const span = document.querySelector('#alertMunicipio')
          if(span){
            list.removeChild(span)
          }
          
      });

    }

    const handleClose = () => {
      props.setFileLogo(undefined)
      props.setFileIMSS(undefined)
      props.setFileSello(undefined)
      props.setFileKeyTimbre(undefined)
      props.setFileCerTimbre(undefined)
      props.setActivo('1')
      props.setRegimenFiscalState('')
      props.setEstadoState('')   
      props.setMunicipioState('')
      props.setBankState('')
      props.setRiskPlaced('')
      props.setOutsourcers(false)
      clearEmpresaModal({}, dispatch);
    };

    const inputFile = () => {

      if(props.fileLogo === undefined){
          return <div className={styles.contenedorInternoIconoLogo}>
              <FileHelperIncident
              name="ArchivoLogoEmpresa"
              parametrofrom="ArchivoLogoEmpresa"
              setArchives={props.setFileLogo}
              archives={props.fileLogo}
              accept = ".PNG, .JGP, .png, .jpg"
              />
          </div>
      }else{
          return <div className={styles.contenedorInternoIconoLogoWithFile}>
              <FileHelperIncident
                  name="ArchivoLogoEmpresa"
                  parametrofrom="ArchivoLogoEmpresa"
                  className="image-file"
                  setArchives={props.setFileLogo}
                  archives={props.fileLogo}
                  image={RemoveFile}
                  accept = ".PNG, .JGP, .png, .jpg"
              />
          </div>
      }

      
    }

    const {data} = useQuery(GET_ALL_REGIMENFISCAL);

    useEffect(() => {
      if(props.estadoState){
        setMunicipios(getMunicipios(props.estadoState));
      }
    }, [props.estadoState]);

      
    useEffect(() => {
      if(data){
        setAllRegimenFiscal(data.GET_ALL_REGIMENFISCAL);
      }
    }, [data]);

    return(
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <div className={styles.contenedorNuevo}>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="nombre"
                defaultValue={state.identidadLegal.nombre}
                label="Nombre de la empresa*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={styles.inputEjemplo}
                error={
                  formik.touched.nombre && Boolean(formik.errors.nombre)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
            </div>
            <div className={styles.contenedorError}>
              <span>{formik.touched.nombre && Boolean(formik.errors.nombre) && formik.errors.nombre}</span>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="razonSocial"
                defaultValue={state.identidadLegal.razonSocial}
                label="Razon Social*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.razonSocial && Boolean(formik.errors.razonSocial)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
                <div className={styles.contenedorError}>
                  <span>{formik.touched.razonSocial && Boolean(formik.errors.razonSocial) && formik.errors.razonSocial}</span>
                </div>
            </div>

            <div className={styles.contenedorInputLogo}>
              <div className={styles.contenedorInternoLogo}>
                <p className={styles.textInternoLogo}>Cargar imagen de logotipo …*</p>
              </div>
              {inputFile()}
            </div>
    

            <div className={styles.contenedorError}>
                <span>{formik.touched.logo && Boolean(formik.errors.logo) && formik.errors.logo}</span>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="rfc"
                defaultValue={state.identidadLegal.rfc}
                label="RFC*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.rfc && Boolean(formik.errors.rfc)
                }
                inputProps={{ maxLength: String(props.regimenFiscalState) === '612'? 13: 12 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
                <div className={styles.contenedorError}>
                  <span>{formik.touched.rfc && Boolean(formik.errors.rfc) && formik.errors.rfc}</span>
                </div>
            </div>
            
            <div className={styles.contenedorInput}>
              <FormControl 
                  fullWidth 
                  size="small"
                  variant="outlined" 
                  className={styles.dialogFields} 
                  error={formik.touched.regimenFiscal && Boolean(formik.errors.regimenFiscal)}
              >
                  <InputLabel id="demo-simple-select-label"
                  style={{
                    backgroundColor: "#FFFFFF"
                  }}
                  >Regimen fiscal*</InputLabel>
                  <Select
                  style={{
                    textOverflow: "ellipsis" ,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "420px",
                    
                  }}
                      
                      labelId="demo-simple-select-label"
                      id="regimenFiscal"
                      name = "regimenFiscal"
                      value = {props.regimenFiscalState}
                      onChange={(e)=>{
                        removeError()
                        props.setRegimenFiscalState(e.target.value)
                      }}
                  >
                      {allRegimenFiscal?.map((Regimens:any,index:number) => {
                              return <MenuItem value={Regimens.clave} key={index}>{Regimens.Descripcion}</MenuItem>
                          })}
                  </Select>
                  <div className={styles.contenedorError} id="errorRegimenFiscal">
                
                  </div>
                </FormControl>

            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="direccion"
                defaultValue={state.identidadLegal.direccion}
                label="Direcci&oacute;n fiscal*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.direccion && Boolean(formik.errors.direccion)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                />
                <div className={styles.contenedorError}>
                  <span>{formik.touched.direccion && Boolean(formik.errors.direccion) && formik.errors.direccion}</span>
                </div>
            </div>
            <div className={styles.contenedorInput}>
              <FormControl 
                  fullWidth 
                  size="small"
                  variant="outlined" 
                  className={styles.dialogFields} 
                  error={formik.touched.estado && Boolean(formik.errors.estado)}
              >
                  <InputLabel id="demo-simple-select-label"
                  style={{
                    backgroundColor: "#FFFFFF"
                  }}
                  >Estado*</InputLabel>
                  <Select
                  style={{
                    textOverflow: "ellipsis" ,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "420px",
                    
                  }}
                      
                      labelId="demo-simple-select-label"
                      id="estado"
                      name = "estado"
                      value = {props.estadoState}
                      onChange={(e)=>{
                        removeError()
                        props.setEstadoState(e.target.value)
                      }}
                  >
                    {states?.map((estadoMX:any,index:number) => {
                        return <MenuItem value={estadoMX} key={index}>{estadoMX}</MenuItem>
                    })}
                  </Select>
                  <div className={styles.contenedorError} id="errorEstado">
                
                  </div>
                </FormControl>

            </div>

            <div className={styles.contenedorInput}>
              <FormControl 
                  fullWidth 
                  size="small"
                  variant="outlined" 
                  className={styles.dialogFields} 
                  error={formik.touched.municipio && Boolean(formik.errors.municipio)}
              >
                <InputLabel id="demo-simple-select-label"
                style={{
                  backgroundColor: "#FFFFFF"
                }}
                >Municipio*</InputLabel>
                <Select
                style={{
                  textOverflow: "ellipsis" ,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "420px",
                  
                }}
                    
                    labelId="demo-simple-select-label"
                    id="municipio"
                    name = "municipio"
                    value = {props.municipio}
                    onChange={(e)=>{
                      removeError()
                      props.setMunicipioState(e.target.value)
                    }}
                >
                  {municipios?.map((municipioMX:any,index:number) => {
                      return <MenuItem value={municipioMX} key={index}>{municipioMX}</MenuItem>
                  })}
                </Select>
                <div className={styles.contenedorError} id="errorMunicipio">
              
                </div>
              </FormControl>

            </div>
            <div className={styles.contenedorInput}>


              <InputMask
                mask="99999"
                defaultValue={state.identidadLegal.codigoPostal}
                onChange={formik.handleChange}
                disabled={false}
                onBlur={formik.handleBlur}
              >
                  <TextField
                    name="codigoPostal"
                    error={
                      formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal)
                    }
                    label="Código postal"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    id="codigoPostal"
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                </InputMask>
              
                <div className={styles.contenedorError}>
                  <span>{formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal) && formik.errors.codigoPostal}</span>
                </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="legalRepresentative"
                defaultValue={state.identidadLegal.legalRepresentative}
                label="Representante legal*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.legalRepresentative && Boolean(formik.errors.legalRepresentative)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.legalRepresentative && Boolean(formik.errors.legalRepresentative) && formik.errors.legalRepresentative}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="notarialTestimony"
                defaultValue={state.identidadLegal.notarialTestimony}
                label="Instrumento notarial*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.notarialTestimony && Boolean(formik.errors.notarialTestimony)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.notarialTestimony && Boolean(formik.errors.notarialTestimony) && formik.errors.notarialTestimony}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="notarialTestimonyVolume"
                defaultValue={state.identidadLegal.notarialTestimonyVolume}
                label="Testimonio Notarial Volumen*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.notarialTestimonyVolume && Boolean(formik.errors.notarialTestimonyVolume)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.notarialTestimonyVolume && Boolean(formik.errors.notarialTestimonyVolume) && formik.errors.notarialTestimonyVolume}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="date"
                name="notarialTestimonyDate"
                // defaultValue={
                //   state.identidadLegal.notarialTestimonyDate
                //   ? moment(state.identidadLegal.notarialTestimonyDate).format(
                //       "YYYY-MM-DD"
                //     )
                //   : ""
                //   }
                InputLabelProps={{ shrink: true }} 
                label="Testimonio Notarial Fecha*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.notarialTestimonyDate && Boolean(formik.errors.notarialTestimonyDate)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.notarialTestimonyDate && Boolean(formik.errors.notarialTestimonyDate) && formik.errors.notarialTestimonyDate}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="notaryPublicNumber"
                defaultValue={state.identidadLegal.notaryPublicNumber}
                label="Notaria Publica Numero*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.notaryPublicNumber && Boolean(formik.errors.notaryPublicNumber)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.notaryPublicNumber && Boolean(formik.errors.notaryPublicNumber) && formik.errors.notaryPublicNumber}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="notaryPublic"
                defaultValue={state.identidadLegal.notaryPublic}
                label="Notario*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.notaryPublic && Boolean(formik.errors.notaryPublic)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.notaryPublic && Boolean(formik.errors.notaryPublic) && formik.errors.notaryPublic}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="judicialDistrict"
                defaultValue={state.identidadLegal.judicialDistrict}
                label="Distrito Judicial*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.judicialDistrict && Boolean(formik.errors.judicialDistrict)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.judicialDistrict && Boolean(formik.errors.judicialDistrict) && formik.errors.judicialDistrict}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <TextField
                type="text"
                name="addressCourt"
                defaultValue={state.identidadLegal.addressCourt}                
                label="Dirección Tribunal*"
                variant="outlined"
                size="small"
                fullWidth={true}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.addressCourt && Boolean(formik.errors.addressCourt)
                }
                inputProps={{ maxLength: 100 }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
              />
              <div className={styles.contenedorError}>
                <span>{formik.touched.addressCourt && Boolean(formik.errors.addressCourt) && formik.errors.addressCourt}</span>
              </div>
            </div>
            <div className={styles.contenedorInput}>
              <Checkbox
                checked={props?.outsourcers}
                value={true}
                onChange={() => props?.setOutsourcers(!props?.outsourcers)}
                name="Outsourcer"
                color="primary"
              />

              <label style={{color:'#093c5d'}} >Outsourcer</label>
            </div>
            
            <div className={styles.contenedorBotones}>
              <button className={styles.botonCancelar}  onClick={handleClose}>
                Cancelar
              </button>
              <button 
                className={styles.botonSiguiente}
                type="submit"
                >
                <div>
                Siguiente 
                </div>
                <div className={styles.iconoSiguiente}></div>
              </button>
            </div>
          </div>
        </Form>
      </div>
    )
  }

 

  export default IdentidadLegal