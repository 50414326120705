/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from '../../Payroll/Modals/PayrollModals.module.css'
import { useMutation } from "@apollo/client";
import { GET_PAYROLL_DISPERSION_2, 
         POST_DESCARTA_OUTSORCERS,
         GET_PAYROLL_DISPERSION_EVENTUAL,
         POST_DESCARTA_OUTSORCERS_EVENTUAL,
         GET_PAYROLL_DISPERSION_FINIQUITO_OUT,
         POST_DESCARTA_FIN_LIQ_OUT
         } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import OutsorcersNominaContext from "../../../context/OutsorcersNomina/OutsorcersNominaContext";
import { clearOutsorcers } from "../../../context/OutsorcersNomina/Actions";

const DescartaOutsorcers = (props:any) =>{
  const {totalesDispersar, totales, seleccionados, idRegistro} = props
  const {state, dispatch} = useContext(OutsorcersNominaContext)
  const [activo, setactivo] = useState(false)
  const [opcionSeleccionada, setOpcionSelecciona] = useState('')
  const [activoMensajeDos, setactivoMensajeDos] = useState(false)
  const [mensaje, setMensaje] = useState('Uno')
  const [token, setToken] = useState('')
  
  const [descartaOutsorcersEventual] = useMutation(POST_DESCARTA_OUTSORCERS_EVENTUAL,{
    refetchQueries:[
      {query: GET_PAYROLL_DISPERSION_EVENTUAL, variables:{getPayrollDispersionId: idRegistro}}
    ]
  })

  const [descartaOutsorcersFiniquito] = useMutation(POST_DESCARTA_FIN_LIQ_OUT,{
    refetchQueries:[
      {query: GET_PAYROLL_DISPERSION_FINIQUITO_OUT, variables:{getPayrollDispersionId: idRegistro}}
    ]
  })  

  const [descartaOutsorcers] = useMutation(POST_DESCARTA_OUTSORCERS,{
    refetchQueries:[
      {query: GET_PAYROLL_DISPERSION_2, variables:{getPayrollDispersionId: idRegistro}}
    ]
  })

  const handleClose = () =>{
    clearOutsorcers({}, dispatch)
  }

  const cambioEstado = (estado:any) =>{ 
    setOpcionSelecciona((current) => current = estado)
  }

  const handleChange = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivo(true)
    }else{
        setactivo(false)
    }
  }

  const agregaSeleccionados = async() =>{
    setMensaje((current) => current = 'Dos')
 console.log(props?.tiponomina)
    switch (props?.tiponomina) {
      case "eventual":
        await descartaOutsorcersEventual({
          variables:{
            input:{
              id: parseInt(state._id),
              statusDispersion:opcionSeleccionada
            }
          }
        })
        break;
      
       case "Finiquito":
          await descartaOutsorcersFiniquito({
            variables:{
              input:{
                id: parseInt(state._id),
                Concepto:opcionSeleccionada,
                tipo:"Finiquito"
              }
            }
          })
          break;
          case "liquidacion":
            await descartaOutsorcersFiniquito({
                variables:{
                  input:{
                    id: parseInt(state._id),
                    Concepto: opcionSeleccionada,
                    tipo:"Liquidacion"
                  }
                }
              })
              break;    

    
      default:
        await descartaOutsorcers({
          variables:{
            input:{
              id: parseInt(state._id),
              statusDispersion:opcionSeleccionada
            }
          }
        })
        break;
    }
    
    props.reinicia()
    setactivo(false)
    setactivoMensajeDos((current) => current = false)
    SuccessfulAlert({text: "Registros enviados, por favor espera la respuesta"})
    setMensaje((current) => current ='Uno' )
    handleClose()
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.openDescartarOutsorcer} fullWidth={false} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          {mensaje === 'Tres'?
          null
          :
          <div
          className={styles.cerrarModal}
          onClick={()=> handleClose()}
        >
        </div>
          }

        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}>
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        {mensaje === 'Uno'?
          <>
            <div className={`${styles.em_mensajePrtincipal} ${styles.em_espacioentreTexto}`}>
            Est&aacute;s por <span className={styles.em_textoNegritas}>DESCARTAR</span> los registros
            <span className={styles.em_textoNegritas}>{state.nombreColaborador}</span>
            </div>
            <div className={`${styles.em_mensajePrtincipal} ${styles.em_espacioentreTexto}`}>
              por favor selecciona el concepto
            </div>
            <div className={styles.em_contenedorSelectOutsourcers}>
              <select
                className={styles.em_selectOutsourcers}
                onChange={(e) => cambioEstado(e.target.value)}
              >
                <option>Seleccione...</option>
                <option>Cancelado</option>
                <option>Pago por otro metodo</option>
                <option>Devolver a revision</option>
              </select>
            </div>
          <div className={`${styles.em_mensajePrtincipal} ${styles.em_espacioentreTexto}`}>
            Escribe CONFIRMAR para continuar
          </div>

          <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
          </div>
          </>
          :null
          }
          {mensaje === 'Dos'?
            <>
              <div className={styles.em_mensajePrtincipal}>
                La solicitud fue enviada, no actualices ni cierres la pantalla
              </div>
              <div className={styles.em_mensajePrtincipal}>
                hasta que el proceso haya terminado.
              </div>
              <div className={styles.contenedorLoadingDispersion}>
                <div className={styles.loading}></div>
              </div>
            </>
          : null
          }
        <div className={styles.em_contenedorBotones}>
              {mensaje === 'Dos'?
              null
              :
              <button
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              }
              {mensaje === 'Uno'?
                (activo === true ?
                  <button 
                    className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                    onClick={()=> agregaSeleccionados()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                  :
                  <button
                    className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                )
                :null
              }
            </div>
      </div>
    </Dialog>
  )
}

export default DescartaOutsorcers