import {
    Popper,
    Grow,
    Paper,
    MenuList,
    MenuItem,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import CalendlyContext from '../../context/Calendly/CalendlyContext';
import { openCreateCalendlyModal } from '../../context/Calendly/Actions';
import ShowCalendly from '../../components/Calendly/Modals/ShowCalendly'

const PopperHeader = ({ open, anchorRef, setOpen }: any) => {

    const {state, dispatch} = useContext(CalendlyContext);
    const [calendly, setCalendly] = useState("")

    return (
        <>
            {/* <Box zIndex="tooltip">
                <Popper data-testid="PopperMenu" open={open} anchorEl={anchorRef.current} transition disablePortal placement='bottom-end'>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onClick={() => {
                                    setCalendly("0")
                                    openCreateCalendlyModal({}, dispatch)
                                    setOpen(!open)
                                }}>
                                    <MenuItem>Meeting</MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                    
                </Popper>
                 
            </Box>*/}
            <ShowCalendly calendly={calendly} setCalendly={setCalendly}/>
        </>
            
    );
};

export default PopperHeader;