import {Grid } from "@material-ui/core";
import './MyMood.css';
import {TextField,Select, InputLabel,FormControl,MenuItem } from "@material-ui/core"
import * as Yup from "yup"
import prefactura from "../../assets/svg/icono_prefactura.svg";
import GreenSwitch from "../ConfigPayroll/GreenSwitch";
import MyMoodContext from '../../context/MyMoodContext/MyMoodContext';
import {openQuestion} from '../../context/MyMoodContext/Actions'
import { useContext, useEffect, useState, useCallback } from 'react';
import {GraphicalQuestions} from './Modal/GraphicalQuestions';
import {FilterQuestions} from './Modal/FilterQuestions';
import {GET_INDICADORES_GLOBALES, GET_RESPUESTAS_RECIBIDAS, GET_ALL_PROJECT, GET_ALL_SEDE, GET_ALL_ENCUESTAS, UPDATE_ENCUESTA_STATUS} from "../../Querys/querys";
import { useMutation, useQuery } from "@apollo/client";
import { IProject } from "../../interfaces/Project";
import moment from "moment";
import { GeneralPieChart } from "../Dashboard/CustomPieChart";
import CustomBarGraph from "../Dashboard/CustomBarGraph";
import NewSurvey from "./Modal/NewSurvey";

const Surveys = (props: any) => {
    const {dispatch} = useContext(MyMoodContext)
    const [global , setGlobal] = useState<any>([])
    const [received , setReceived] = useState<any>([]);
    const [survey, setSurvey] = useState<any>([])
    const [surveySinFiltro, setSurveySinFiltro] = useState<any>([])
    const [surveySinFiltroStart, setSurveySinFiltroStart] = useState<any>([])
    const [surveyConFiltro, setSurveyConFiltro] = useState<any>([])
    const [filterArea, setFilterArea] = useState<any>("")
    const [filterSede, setFilterSede] = useState<any>("")
    const [filterEstatus, setFilterEstatus] = useState<any>("")
    const [filterFecha, setFilterFecha] = useState<any>("")
    const [textSearch, setTextSearch] = useState<any>("")
    const [order, setOrder] = useState<any>("Mayor")
    const { data: resultProject } = useQuery(GET_ALL_PROJECT);
    const allProject = resultProject?.GET_ALL_PROJECT;
    const { data: resultSede } = useQuery(GET_ALL_SEDE);
    const allSede = resultSede?.GET_ALL_SEDE;
    const { data: resultEncuestas, startPolling: startPollingEncuestas, stopPolling: stopPollingEncuestas } = useQuery(GET_ALL_ENCUESTAS);
    const allEncuestas = resultEncuestas?.GET_ALL_ENCUESTAS
    const [sede, setSede] = useState<any>([])
    const [projects, setProjects] = useState<Array<IProject>>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [activeFilter, setActiveFilter] = useState<boolean>(false);

    
    const [updateStatus] = useMutation(UPDATE_ENCUESTA_STATUS,{
        refetchQueries: [{query: GET_ALL_ENCUESTAS}]
    })

    const updatePayrollStatus = (id:any) =>{
        
        updateStatus({
            variables:{
                id: id
            }
        })
    }

    const datosFiltradosStart = useCallback((data: any)=>{
        
        let filtrados = data.filter((lis: any) =>
            lis?.Pregunta
            .toUpperCase()
            .includes(textSearch.toString().toUpperCase())
        );

        if (textSearch === '') {
            filtrados = data;
        }
        
        setSurveySinFiltro(data);
        const surveyAux = [...filtrados]
        if(order === "Menor"){
          
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(b.Fecha).isBefore(moment(a.Fecha)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }else{
         
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(a.Fecha).isBefore(moment(b.Fecha)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }
        
    }, [textSearch, order])

    const { data: globaTotal, startPolling: startPollingIndicadores, stopPolling: stopPollingIndicadores  } = useQuery(GET_INDICADORES_GLOBALES);
    useEffect(() => {
        if(globaTotal){
            setGlobal(globaTotal.GET_INDICADORES_GLOBALES)
            
        }
    }, [globaTotal])
    
    const { data: responsesReceived, startPolling: startPollingRespuestas, stopPolling: stopPollingRespuestas} = useQuery(GET_RESPUESTAS_RECIBIDAS);

    useEffect(() => {
        if(responsesReceived){
            setReceived(responsesReceived.GET_RESPUESTAS_RECIBIDAS)
        }
    }, [responsesReceived])

    useEffect(() => {
        startPollingIndicadores(2000);
        return () => {
            stopPollingIndicadores();
        };
    }, [startPollingIndicadores, stopPollingIndicadores]);

    useEffect(() => {
        startPollingRespuestas(2000);
        return () => {
            stopPollingRespuestas();
        };
    }, [startPollingRespuestas, stopPollingRespuestas]);

    useEffect(() => {
        if (allProject) setProjects(allProject);
    }, [allProject]);

    useEffect(() => {
        if(allSede){
            setSede(allSede)
        }
    }, [allSede]);    

    
    const getDisplay = () => {

        const surveyAux = [...survey]
        const e = document.getElementById("idFecha")

        if(e?.className === 'sortAsc'){
            e?.classList.remove('sortAsc')
            e?.classList.add('sortDesc')
        }else{
            e?.classList.remove('sortDesc')
            e?.classList.add('sortAsc')
        }

        if(order === "Mayor"){
            setOrder("Menor")
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(b.Fecha).isBefore(moment(a.Fecha)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }else{
            setOrder("Mayor")
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(a.Fecha).isBefore(moment(b.Fecha)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }
        
    }
    useEffect(() => {
        if(allEncuestas){
            setSurveySinFiltroStart(allEncuestas)
            
            if(activeFilter){

                datosFiltradosStart(allEncuestas.filter((lis: any) =>  surveyConFiltro.includes(lis?.Pregunta)))
            }else{
                datosFiltradosStart(allEncuestas)
            }

            
            
        }
    }, [allEncuestas, surveyConFiltro, datosFiltradosStart, activeFilter]);


    useEffect(() => {
        startPollingEncuestas(2000);
        return () => {
            stopPollingEncuestas();
        };
    }, [startPollingEncuestas, stopPollingEncuestas]);

    const openModal = (idEncuestas_colaborador: any, Encuestas: any) => {
        
        openQuestion({idEncuestas_colaborador, Encuestas},dispatch)
    }

    const datosFiltrados = (text: string) => {
        let filtrados = surveySinFiltro.filter((lis: any) =>
            lis?.Pregunta
            .toUpperCase()
            .includes(text.toString().toUpperCase())
        );
  
        if (text === '') {
            filtrados = surveySinFiltro;
            setTextSearch('')
        }else{
            setTextSearch(text)
        }
        
        setSurvey(filtrados);
    };

    const showDivFilter = () => {
        setShowFilter(!showFilter)
        const e = document.getElementById("idFiltros")

        if(e?.className === 'imgLowerPosition'){
            e?.classList.remove('imgLowerPosition')
            e?.classList.add('imgLowerPositionOpen')
        }else{
            e?.classList.remove('imgLowerPositionOpen')
            e?.classList.add('imgLowerPosition')
        }
    
    }

    const filterData = () => {
        let filtrados: any
        let filtrados2: any
        let filtrados3: any
        let filtrados4: any
        
        if(filterArea !== ''){
            filtrados = surveySinFiltroStart.filter((lis: any) =>
                String(lis?.idArea) ===  String(filterArea)
            );
        }else{
            filtrados = surveySinFiltroStart
        }

        if(filterSede !== ''){
            filtrados2 = filtrados.filter((lis: any) =>
                String(lis?.campus) ===  String(filterSede)
            );
        }else{
            filtrados2 = filtrados
        }

        if(filterEstatus !== ''){
            filtrados3 = filtrados2.filter((lis: any) =>
                String(lis?.Estatus) ===  String(filterEstatus)
            );
        }else{
            filtrados3 = filtrados2
        }
        
        if(filterFecha !== ''){
            filtrados4 = filtrados3.filter((lis: any) =>
                moment(lis?.Fecha).format('YYYY-MM-DD') ===  moment(filterFecha).format('YYYY-MM-DD')  
            );
        }else{
            filtrados4 = filtrados3
        }

        if (filterArea === '' && filterSede === '' && filterEstatus === '' && filterFecha === '') {
            filtrados4 = surveySinFiltroStart;
            setSurveyConFiltro([]);
            setActiveFilter(false)
        }else{
  
            setSurveyConFiltro(
                filtrados4.map((lis: any) => lis.Pregunta)
            )
            setActiveFilter(true)
        }
        setShowFilter(false)
        
    }

    return (
        <div
        style={
            {
                position: "relative"
            }
        }
        >
            <NewSurvey locations={allSede || []} projects={allProject || []}></NewSurvey>
            <Grid
            container
            spacing={6}
            style={{
                marginBottom: "30px",
            }}
            >
                <Grid item xs={12} md={6}>
                    <div className="conteinerSurvey containerPadding">
                        <div className="containerResponses" >
                            <div className="titleResponse" >
                                <p>Indicadores globales de encuestas</p>
                            </div>
                            <Grid container alignItems="center">
                                <Grid item xs={12} md={2}>
                                    </Grid>
                                <Grid item xs={12} md={4}>
                                    <div className="labelsGraph">
                                        <div>
                                            <p className="divTitleResponse">Realizadas</p>
                                            <p className="divTextResponse">{global?.TotalEncuestas}</p>
                                        </div>
                                        <div className="divTextResponse" >
                                            <p className="divTitleResponse">Enviadas</p>
                                            <p className="divTextResponse">{global?.Enviadas}</p>
                                        </div>
                                        <div className="divTextResponse" >
                                            <p className="divTitleResponse">Respondidas</p>
                                            <p className="divTextResponse textGreen">{global?.Respondidas}</p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="graphData">
                                            <GeneralPieChart 
                                            height={200}
                                            percentage={ global?.Respondidas > 0 ? Math.ceil(( global?.Respondidas / global?.Enviadas) * 100) : 0}
                                            percentageSymbol={'%'}
                                            number={global?.Respondidas > 0 ? String( Math.ceil(( global?.Respondidas / global?.Enviadas) * 100)) : '0' } 
                                            textSize={'20px'} 
                                            color={'#11CA73'} 
                                            y={56}></GeneralPieChart>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="conteinerSurvey containerPadding">
                        <div className="containerResponses" >
                            <div className="titleResponse" >
                                <p>Respuestas recibidas por mes</p>
                            </div>
                            <Grid container alignItems="center">
                                <Grid xs={12} md={2}></Grid>
                                <Grid item xs={12} md={8}>
                                    {
                                        received.map((item:any,key:number)=>(
                                            <CustomBarGraph key={key} number={key+1} month={item.Mes} totalAnswered={item.TotalRespuestas} total={item.Total}></CustomBarGraph>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div>
                <Grid className="conteinerSurvey2" >
                    <div className="containerRiskSurveyFilter" >
                        <div className="conteinerAllQuestion">
                            
                                <div className="pc_contenedorBuscador"
                                style={{
                                    marginTop: "0px",
                                    height: "32px",
                                }}
                                >
                                    <input
                                    type="text"
                                    placeholder="Palabra clave"
                                    className="pc_inputBuscador"
                                    style={{
                                        height: "32px",
                                    }}
                                    id="idInput"
                                    onChange={(e) => datosFiltrados(e.target.value)}
                                    ></input>
                                    
                                </div>
                          
                        </div>
                        <div className="conteinerAllQuestion"
                        style={{
                            width: "100%",
                        }}
                        >
                            <div className="containerOrder">
                                <div className="divOrder" onClick={() => {
                                    //openFilter({},dispatch)
                                    showDivFilter()
                                }}>
                                    <div>Filtros</div>
                                    <div className="imgLowerPosition" id="idFiltros"></div>
                                </div>
                                <div className="divOrder" onClick={() => {getDisplay()}}>
                                    <div>Fecha</div>
                                    <div className="sortAsc" id="idFecha"></div>
                                </div>
                            </div>
                            
                            
                        </div>
                        
                    </div>
                    <div className="containerSurveyFilter" 
                    style={{
                        paddingTop: "0px",
                        height: "0px",
                    }}
                    >
                        {
                            showFilter?
                            <div 
                            style={{
                                marginLeft: "auto",
                                width: "392px",
                            }}
                            >
                                <div className="questionContainer" >
                                    
                                    <div className="dialogoContento">
                                        
                                        <div className="conteinerAllQuestion">
                                        
                                            <FormControl 
                                                fullWidth 
                                                size="small"
                                                variant="outlined" 
                                                className="question" 
                                            >
                                                <InputLabel id="demo-simple-select-label"
                                                style={{
                                                    backgroundColor: "#FFFFFF"
                                                }}
                                                >&Aacute;rea o sub &aacute;rea</InputLabel>
                                                <Select
                                                    style={{
                                                        textOverflow: "ellipsis" ,
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",                                            
                                                    }}
                                                    value={
                                                        filterArea
                                                    }
                                                    onChange={
                                                        (e:any)=>{
                                                            
                                                            setFilterArea(e.target.value)
                                                        }
                                                    }
                                                    labelId="demo-simple-select-label"
                                                    id="filterArea"
                                                    name = "filterArea"
                                                >
                                                    <MenuItem value="" key="" >Seleccione</MenuItem>
                                                    {
                                                        projects.map((project:any)=>{
                                                            return <MenuItem value={project.id} key={project.id} >{project.proyectNameAndClient}</MenuItem>
                                                        })
                                                    }
                                                    
                                                </Select>
                                            </FormControl>
                                        </div>
                                
                                    </div>
                                    <div className="conteinerAllQuestion">
                                        <FormControl 
                                            fullWidth 
                                            size="small"
                                            variant="outlined" 
                                            className="question" 
                                        >
                                            <InputLabel id="demo-simple-select-label"
                                            style={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                            >Sede</InputLabel>
                                            <Select
                                                style={{
                                                    textOverflow: "ellipsis" ,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",   
                                                                                                
                                                }}
                                                value={
                                                    filterSede
                                                }
                                                onChange={
                                                    (e:any)=>{
                                                        
                                                        setFilterSede(e.target.value)
                                                    }
                                                }
                                                labelId="demo-simple-select-label"
                                                id="filterSede"
                                                name = "filtersede"
                                            >
                                                <MenuItem value="" key=""  >Seleccione</MenuItem>
                                                {
                                                    sede.map((item:any)=>{
                                                        return <MenuItem value={item.sedeName} key={item.id} >{item.sedeName}</MenuItem>
                                                    })
                                                }
                                                
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="conteinerAllQuestion">
                                        <FormControl 
                                            fullWidth 
                                            size="small"
                                            variant="outlined" 
                                            className="question" 
                                        >
                                            <InputLabel id="demo-simple-select-label"
                                            style={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                            >Estatus</InputLabel>
                                            <Select
                                                style={{
                                                    textOverflow: "ellipsis" ,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",   
                                                                                                
                                                }}
                                                value={
                                                    filterEstatus
                                                }
                                                onChange={
                                                    (e:any)=>{
                                                        
                                                        setFilterEstatus(e.target.value)
                                                    }
                                                }
                                                labelId="demo-simple-select-label"
                                                id="filterEstatus"
                                                name = "filterEstatus"
                                            >
                                                <MenuItem value="" key=""  >Seleccione</MenuItem>
                                                <MenuItem value="Activo" key="1"  >Activo</MenuItem>
                                                <MenuItem value="Inactivo" key="2"  >Inactivo</MenuItem>
                                                
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="conteinerAllQuestion">
                                        <TextField
                                            className="question"
                                            type="date"
                                            name="filterFecha"
                                            value={
                                                filterFecha
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth={true}
                                            onChange={(e:any)=>{
                                                
                                                setFilterFecha(e.target.value)
                                            }}
                                            inputProps={{ maxLength: 100 }}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        />
                                    </div>
                                    <div className="questionAction"  >
                                        <button className="botonSiguiente"
                                        onClick={(()=>filterData())}
                                        >
                                            <div>
                                                Aplicar
                                            </div>
                                        </button>
                                            
                                        

                                    </div>
                                </div>
                            </div>
                            :<div></div>
                        }
                        

                    </div>
                    <div className="conteinerAllQuestionTable" >
                        <div className="pc_contenedorTabla">
                            <table className="pc_tablaPrincipal">
                                <thead className="pd_contenedorTitulos">
                                    <td className="pc_columnaTitulosDate">Fecha</td>
                                    <td className="pc_columnaTitulosQuestion">Encuesta</td>
                                    <td className="pc_columnaTitulos">Motivado</td>
                                    <td className="pc_columnaTitulos">Productivo</td>
                                    <td className="pc_columnaTitulos">Aburrido</td>
                                    <td className="pc_columnaTitulos">Presionado</td>
                                    <td className="pc_columnaTitulos">Enfadado</td>
                                    <td className="pc_columnaTitulos">Estatus</td>
                                    <td className="pc_columnaTitulos"></td>
                                </thead>
                                {
                                    survey?.map((item:any)=>(
                                        <tbody>
                                            <tr>
                                                <td className="pc_columnaDatos columnDate"
                                                >
                                                    {moment(item.Fecha).format("DD/MM/YYYY")}
                                                </td>
                                                <td className="pc_columnaDatos">
                                                    {item.Pregunta}
                                                </td>
                                                <td className="pc_columnaDatos">
                                                <p
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                                >
                                                    {item.Motivado}
                                                </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                <p
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                                >
                                                    {item.Productivo}
                                                </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                <p
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                                >
                                                    {item.Aburrido}
                                                </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                <p
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                                >
                                                    {item.Presionado}
                                                </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                <p
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                                >
                                                    {item.Enfadado}
                                                </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                <p
                                                style={{
                                                    marginLeft: "10px",
                                                }}
                                                >
                                                    <div className="divIconos">
                                                        <div className="statusLabel">
                                                            {item.Estatus}
                                                        </div>
                                                        <GreenSwitch updatePayrollStatus={updatePayrollStatus} id= {item.id} status={item.Estatus}/>
                                                    </div>
                                                </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                    <div className="divDoc">
                                                        <div className="divIconos">
                                                            {
                                                                item.Estatus === "Inactivo" ?
                                                                <a
                                                                    style={{ marginRight: "5px", marginLeft: "auto" }}
                                                                    target="_blank"
                                                                    title="Vista previa"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => openModal(item.id, item.Pregunta)}
                                                                   
                                                                >
                                                                    <img
                                                                    src={prefactura}
                                                                    style={{ marginRight: "auto", marginLeft: "auto" }}
                                                                    className="cursor"
                                                                    alt="Vista previa"
                                                                    />
                                                                </a>
                                                                : null
                                                            }
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    ))
                                }
                                
                            </table>
                        </div>
                    </div>
                </Grid>
            </div>
            <GraphicalQuestions/>
            <FilterQuestions 
                projects={projects}
                sede={sede}
                filterArea={filterArea}
                setFilterArea={setFilterArea}
                filterSede={filterSede}
                setFilterSede={setFilterSede}
                filterEstatus={filterEstatus} 
                setFilterEstatus={setFilterEstatus}
                filterFecha={filterFecha}
                setFilterFecha={setFilterFecha}
                surveySinFiltroStart={surveySinFiltroStart}
                setSurveySinFiltroStart={setSurveySinFiltroStart}
                setSurveyConFiltro={setSurveyConFiltro}
                surveyConFiltro={surveyConFiltro}
                setSurvey={setSurvey}
                survey={survey}
                setSurveySinFiltro={setSurveySinFiltro}
                surveySinFiltro={surveySinFiltro}
                />
        </div>
    )
};

export default Surveys;
