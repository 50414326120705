/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from "semantic-ui-react";
import InputAdornment from '@mui/material/InputAdornment';
import Add from "../../../assets/icons/add-goal.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import { formatterMxn } from '../../../helpers/formatoMoneda';
import {
  CREATE_CAMPAIGN_OBJECTIVE_BY_USER,
  UPDATE_CAMPAIGN_OBJECTIVE,
  CREATE_CAMPAIGN_OBJECTIVE_BY_ADMIN,
  CREATE_CAMPAIGN_OBJECTIVE_BY_LEADER,
  GET_ALL_USER_CAMPAIGN_OBJECTIVES
} from "../../../Querys/querys";
import { Objective } from "../../../interfaces/Objective.interface";
import { ErrorAlert } from "../../../alerts/errorAlert";
//import { WarningAlert } from '../../../alerts/WarningAlert';
import { AdminContext } from "../../../context/AdminContext/AdminContext";
import "../css/ModalObjectives.css"

const CreateObjectiveModal = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const { adminState } = useContext(AdminContext);
  const [createObjectiveByUser] = useMutation(CREATE_CAMPAIGN_OBJECTIVE_BY_USER);
  const [createObjectiveByLeader] = useMutation(CREATE_CAMPAIGN_OBJECTIVE_BY_LEADER);
  const [createObjectiveByAdmin] = useMutation(CREATE_CAMPAIGN_OBJECTIVE_BY_ADMIN);
  const [getCollaboratorInfo] = useLazyQuery(GET_ALL_USER_CAMPAIGN_OBJECTIVES);
  const [editObjective] = useMutation(UPDATE_CAMPAIGN_OBJECTIVE);
  const [valueMetricIndicator,setValueMetricIndicator] = useState('')
  const [valueMetricObjective,setValueMetricObjective] = useState('')
  const [valueMinObjective,setValueMinObjective] = useState(0)
  const [initialValuesForm, setInitialValuesForm] = useState({
    name: "",
    nameIndicator: "",    
    metricIndicator: "",
    metricObjective: "",
    weight: "",
    description: "",
  });
  
  const arrayMetricIndicator = [
    {value: "pesos",      label: "Pesos" },
    {value: "porcentaje", label: "Porcentaje"},
    {value: "unidades",   label: "Unidades" }
  ];

   const openCollaboratorModal = () => {
    dispatch({ type: Types.SELECT_COLLABORATOR_MODAL })
  } 

  const handleCleanFormat = (value: any) => {
    const clean= String(value)
     if(clean.includes('$')){
       return clean.replace("$", "").replaceAll(",", "");      
     }     
     if(clean.includes('%')){
       return clean.replace("%", "").replaceAll(",", "")   
     }     
     return clean;    
   };

  const validationSchema = () => {
    return {
      name:            Yup.string().required("Obligatorio"),
      nameIndicator:   Yup.string().required("Obligatorio"),
      metricIndicator: Yup.string().required("Obligatorio"),                                
      metricObjective: Yup.string().required("Obligatorio"),                         
      weight:          Yup.number().required("Obligatorio")
                          .positive("Tiene que ser un número positivo")
                          .max(100, "No puede ser mayor a 99")            
    }    
  } 

  const changeFormat=(e:any) =>{
     setValueMetricIndicator(e)     
     if(valueMetricObjective !==''){
      const metricObjetiveClean= handleCleanFormat(formik.values.metricObjective)
      switch (e) {
        case 'pesos':
             if (typeof metricObjetiveClean === "string") {
              let valueFormatted =metricObjetiveClean.replace(/,/g, "");
                  valueFormatted = parseFloat(valueFormatted).toFixed(2);
              let valPesos = formatterMxn(valueFormatted);
              const valFin= (valPesos=== 'nan') ? '': valPesos;  
              formik.setFieldValue("metricObjective", valFin);       
            }   
          break;
          case 'porcentaje':
            const value= parseFloat(metricObjetiveClean) 
            const valPor= isNaN(value)? '': `${value} %`;  
            formik.setFieldValue("metricObjective", valPor);            
          break;
          case 'unidades':
            const valUn= (metricObjetiveClean=== 'nan') ? '': metricObjetiveClean;  
            formik.setFieldValue("metricObjective", valUn);            
          break;      
        }  
     }
  }
  
  const handleSetFormat=() =>{
    setValueMetricObjective(formik.values.metricObjective)  
    if(valueMetricIndicator !== '') { 
    switch (valueMetricIndicator) {
      case 'pesos':
          if (typeof formik.values.metricObjective === "string") {
            let valueFormatted = formik.values.metricObjective.replace(/,/g, "");
                valueFormatted = parseFloat(valueFormatted).toFixed(2);
            let valPesos = formatterMxn(valueFormatted);
            const valFin= (valPesos=== 'nan') ? '': valPesos;              
            formik.setFieldValue("metricObjective", valFin);       
          }   
        break;
        case 'porcentaje':
          const valueMetricObjetic= formik.values.metricObjective
          const valuePercentage= valueMetricObjetic.replace("$", "").replaceAll(",", "")
          const value= parseFloat(valuePercentage) 
          const valPor= isNaN(value)? '': `${value} %`;  
          formik.setFieldValue("metricObjective", valPor);            
        break;
        case 'unidades':
          const value2=parseInt(formik.values.metricObjective)
          const valUn= isNaN(value2)? '': value2;  
          formik.setFieldValue("metricObjective", valUn);            
        break;      
      }     
    }
    else{
     return ErrorAlert({text:'Es necesario seleccionar el tipo del indicador '});
    }    
  }

  const  handleFormatMetric=(metricObjectiveValue:any,metricIndicator:any) =>{
    switch (metricIndicator) {      
      case 'pesos':
            let valueFormatted = parseFloat(metricObjectiveValue).toFixed(2);              
            let valPesos = formatterMxn(valueFormatted);
            const valFin= (valPesos=== 'nan') ? '': valPesos;            
        return valFin          
        case 'porcentaje':
          const valueMetricObjetic= metricObjectiveValue          
          const value= parseFloat(valueMetricObjetic) 
          const valPor= isNaN(value)? '': `${value} %`;  
          return valPor          
        
        case 'unidades':
          const value2=parseInt(metricObjectiveValue)
          const valUn= isNaN(value2)? '': value2;  
          return valUn          
          
          default:
             return metricObjectiveValue     
          
      }        
  }

  const updateWeight = (e: any) => {
    if (parseInt(e, 10) > 100) {
      formik.setFieldValue("weight", 100);  
      return;
    }
    if (parseInt(e, 10) < 0) {
      formik.setFieldValue("weight", 0);  
      return;
    }
    formik.setFieldValue("weight", e);  
  }

  useEffect(() => {
    if (state.selectedObjective) {
      const formatMetricObjective= handleFormatMetric(state.selectedObjective.metricObjective,state.selectedObjective.metricIndicator)
      setInitialValuesForm({
        name: state.selectedObjective.ObjectiveName,
        nameIndicator:   state.selectedObjective.nameIndicator,
        metricIndicator: state.selectedObjective.metricIndicator,
        metricObjective: formatMetricObjective,
        weight:          handleCleanFormat(state.selectedObjective.weight) ,
        description    : state.selectedObjective.descriptionIndicator
      });
      setValueMetricIndicator(state.selectedObjective.metricIndicator)
      setValueMetricObjective(state.selectedObjective.metricObjective)      
    } else {
      setInitialValuesForm({
        name: "",
        nameIndicator: "",
        metricObjective: "",
        metricIndicator: "",
        weight: "",
        description: "",
      });
    }
  }, [ state.selectedObjective]);
 
  
  useEffect(() => {
    if (state?.campaign) {
        setValueMinObjective(state?.campaign.minObjectives)       
    } 
  }, [ state?.campaign]);
  
  const formik = useFormik({
    initialValues: initialValuesForm,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData ) => {   
    let valueMetrciObjetic = handleCleanFormat(formData.metricObjective)
    if(state.createModal){
      
      switch (state?.rol) {
            case "admin":
                if (state.activeTable === "campaign") {
                  if(state.collaboratorsIds.length > 0){
                      for await (const collaborator of state.collaboratorsIds) {
                        console.log(collaborator)
                         const { data } = await getCollaboratorInfo({
                              variables: {
                                 input: {
                                 campaignId: state.campaignId,
                                collaboratorId: collaborator,
                              }
                            }
                      })
                    const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;    
                    // console.log(1, collaboratorInfo)      
                          let error=false
                         if(collaboratorInfo.length > 0){                              
                            if(collaboratorInfo[0].Totalobjectives===collaboratorInfo[0].maxObjectives){
                              error=true;                   
                            }                   
                            const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
                            if (weightCount + parseInt(formData.weight, 10) > 100) {
                              error=true                                        
                            }                       
                         }
                         if(!error){
                          createObjectiveByAdmin({
                            variables: {
                              input: {
                                idCampaign: state.campaignId,
                                idCollaborator: collaborator,
                                objectiveName: formData.name,
                                nameIndicator: formData.nameIndicator,
                                metricObjective: parseFloat(valueMetrciObjetic),
                                metricIndicator: formData.metricIndicator,
                                weight: parseInt(formData.weight, 10),
                                descriptionIndicator: formData.description,
                                iscreatedAdmin:1    
                                }
                            }
                            })
                          }                                         
                     }
                    }
                    else{
                      ErrorAlert({text:'Es necesario administrar a los colaboradores'});
                      return
                    }
                  }
                if (state.activeTable === "collaborator") {  
                  const { data } = await getCollaboratorInfo({
                    variables: {
                        input: {
                         campaignId: state.campaignId,
                         collaboratorId: state.collaboratorId,
                      }
                    }
                 })
                const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
                const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
                if(collaboratorInfo.length > 0)
                {
                
                if((collaboratorInfo.length+1 < valueMinObjective) && (weightCount + parseInt(formData.weight, 10) >= 100)){
                   return ErrorAlert({text:`No es posible crear el objetivo pues se requiere de ${state.campaign?.minObjectives} objetivos minímo y que estos sumen el 100% de peso`});
                 }
                if ((weightCount + parseInt(formData.weight, 10) > 100)) {
                  return ErrorAlert({text:'El peso total de los objetivos no puede ser mayor a 100 '});
                }      
                 createObjectiveByUser({
                   variables: {
                     input: {
                       idCampaign: state.campaignId,
                       idCollaborator: state.collaboratorId,
                       objectiveName: formData.name,
                       nameIndicator: formData.nameIndicator,
                       metricObjective: parseFloat(valueMetrciObjetic),
                       metricIndicator: formData.metricIndicator,
                       weight: parseInt(formData.weight, 10),
                       descriptionIndicator: formData.description,
                     }
                   }
                 }); 
                }
                else{
                 let error=false
                  if(valueMinObjective===1 && parseInt(formData.weight, 10)=== 100){
                     error=false;
                   }
                 if(valueMinObjective !==1  && parseInt(formData.weight, 10)>= 100 )  {
                    error=true              
                 }
                 
                 if(error) {
                     return ErrorAlert({text:`No es posible crear el objetivo pues se requiere de ${state.campaign?.minObjectives} objetivos minímo y que estos sumen el 100% de peso`});
                 }else{  
                   createObjectiveByUser({
                     variables: {
                       input: {
                       idCampaign: state.campaignId,
                       idCollaborator: state.collaboratorId,
                       objectiveName: formData.name,
                       nameIndicator: formData.nameIndicator,
                       metricObjective: parseFloat(valueMetrciObjetic),
                       metricIndicator: formData.metricIndicator,
                       weight: parseFloat(formData.weight),
                       descriptionIndicator: formData.description,
                     }
                     }
                   }); 
                   }     
                
                  }
                }
            break;
            case "lider":
              if (state.activeTable === "campaign") {
                
                if(state.collaboratorsIds.length > 0){
                  for await (const collaborator of state.collaboratorsIds) {
                     const { data } = await getCollaboratorInfo({
                          variables: {
                             input: {
                             campaignId: state.campaignId,
                            collaboratorId: collaborator,
                          }
                        }
                })
                const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
                const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
                // if (weightCount + parseInt(form.weight, 10) > 100) {
                //return ErrorAlert({text:'El peso total de los objetivos no puede ser mayor a 100 '});
                // }       
                //    createObjectiveByLeader({
                //        variables: {
                //          input: {
                //            idCampaign: state.campaignId,
                //            idCollaborator: collaborator,
                //            objectiveName: form.name,
                //            nameIndicator: form.nameIndicator,
                //            metricObjective: parseFloat(valueMetrciObjetic),
                //            metricIndicator: form.metricIndicator,
                //            weight: parseInt(form.weight, 10),
                //            descriptionIndicator: form.description,
                //           idLeader: parseInt(adminState?.id as any || state?.userFromLink?.id as any, 10),
                //           isCreatedLeader: 1
                //         }
                //        }
                //      })
                let error=false
                if(collaboratorInfo.length > 0){                              
                   if(collaboratorInfo[0].Totalobjectives===collaboratorInfo[0].maxObjectives){
                     error=true;                   
                   }                   
                   const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
                   if (weightCount + parseInt(formData.weight, 10) > 100) {
                     error=true                                        
                   }                       
                }
                if(!error){
                  createObjectiveByLeader({
                       variables: {
                         input: {
                           idCampaign: state.campaignId,
                           idCollaborator: collaborator,
                           objectiveName: formData.name,
                           nameIndicator: formData.nameIndicator,
                           metricObjective: parseFloat(valueMetrciObjetic),
                           metricIndicator: formData.metricIndicator,
                           weight: parseInt(formData.weight, 10),
                           descriptionIndicator: formData.description,
                          idLeader: parseInt(adminState?.id as any || state?.userFromLink?.id as any, 10),
                          isCreatedLeader: 1
                        }
                       }
                     })
                 }        
                 }
                }
                else{
                  ErrorAlert({text:'Es necesario administrar a los colaboradores'});
                  return
                }
              }
              if (state.activeTable === "collaborator") {  
                const { data } = await getCollaboratorInfo({
                  variables: {
                      input: {
                       campaignId: state.campaignId,
                       collaboratorId: state.collaboratorId,
                    }
                  }
               })
              const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
              const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
              if(collaboratorInfo.length > 0)
              {
              
              if((collaboratorInfo.length+1 < valueMinObjective) && (weightCount + parseInt(formData.weight, 10) >= 100)){
                 return ErrorAlert({text:`No es posible crear el objetivo pues se requiere de ${state.campaign?.minObjectives} objetivos minímo y que estos sumen el 100% de peso`});
               }
              if ((weightCount + parseInt(formData.weight, 10) > 100)) {
                return ErrorAlert({text:'El peso total de los objetivos no puede ser mayor a 100 '});
              }      
               createObjectiveByUser({
                 variables: {
                   input: {
                     idCampaign: state.campaignId,
                     idCollaborator: state.collaboratorId,
                     objectiveName: formData.name,
                     nameIndicator: formData.nameIndicator,
                     metricObjective: parseFloat(valueMetrciObjetic),
                     metricIndicator: formData.metricIndicator,
                     weight: parseInt(formData.weight, 10),
                     descriptionIndicator: formData.description,
                   }
                 }
               }); 
              }
              else{
               let error=false
                if(valueMinObjective===1 && parseInt(formData.weight, 10)=== 100){
                   error=false;
                 }
               if(valueMinObjective !==1  && parseInt(formData.weight, 10)>= 100 )  {
                  error=true              
               }
               
               if(error) {
                   return ErrorAlert({text:`No es posible crear el objetivo pues se requiere de ${state.campaign?.minObjectives} objetivos minímo y que estos sumen el 100% de peso`});
               }else{  
                 createObjectiveByUser({
                   variables: {
                     input: {
                     idCampaign: state.campaignId,
                     idCollaborator: state.collaboratorId,
                     objectiveName: formData.name,
                     nameIndicator: formData.nameIndicator,
                     metricObjective: parseFloat(valueMetrciObjetic),
                     metricIndicator: formData.metricIndicator,
                     weight: parseFloat(formData.weight),
                     descriptionIndicator: formData.description,
                   }
                   }
                 }); 
                 }     
              
            }
              }
            break;
            default:
             const { data } = await getCollaboratorInfo({
                  variables: {
                      input: {
                       campaignId: state.campaignId,
                       collaboratorId: state.collaboratorId,
                    }
                  }
               })               
               const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
               const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);              
               if(collaboratorInfo.length > 0)
               {
               
               if((collaboratorInfo.length+1 < valueMinObjective) && (weightCount + parseInt(formData.weight, 10) >= 100)){
                  return ErrorAlert({text:`No es posible crear el objetivo pues se requiere de ${state.campaign?.minObjectives} objetivos minímo y que estos sumen el 100% de peso`});
                }
               if ((weightCount + parseInt(formData.weight, 10) > 100)) {
                 return ErrorAlert({text:'El peso total de los objetivos no puede ser mayor a 100 '});
               }      
                createObjectiveByUser({
                  variables: {
                    input: {
                      idCampaign: state.campaignId,
                      idCollaborator: state.collaboratorId,
                      objectiveName: formData.name,
                      nameIndicator: formData.nameIndicator,
                      metricObjective: parseFloat(valueMetrciObjetic),
                      metricIndicator: formData.metricIndicator,
                      weight: parseInt(formData.weight, 10),
                      descriptionIndicator: formData.description,
                    }
                  }
                }); 
               }
               else{
                let error=false
                 if(valueMinObjective===1 && parseInt(formData.weight, 10)=== 100){
                    error=false;
                  }
                if(valueMinObjective !==1  && parseInt(formData.weight, 10)>= 100 )  {
                   error=true              
                }
                
                if(error) {
                    return ErrorAlert({text:`No es posible crear el objetivo pues se requiere de ${state.campaign?.minObjectives} objetivos minímo y que estos sumen el 100% de peso`});
                }else{  
                  createObjectiveByUser({
                    variables: {
                      input: {
                      idCampaign: state.campaignId,
                      idCollaborator: state.collaboratorId,
                      objectiveName: formData.name,
                      nameIndicator: formData.nameIndicator,
                      metricObjective: parseFloat(valueMetrciObjetic),
                      metricIndicator: formData.metricIndicator,
                      weight: parseFloat(formData.weight),
                      descriptionIndicator: formData.description,
                    }
                    }
                  }); 
                  }               
             }
            break;
            }
            formik.resetForm(); 
            setValueMetricIndicator('');
            setValueMetricObjective('');
            if (state.editModal) dispatch({ type: Types.CANCEL_EDIT_MODAL })
            if (state.createModal) dispatch({ type: Types.CANCEL_CREATE_MODAL })
            if (state.duplicateModal) dispatch({ type: Types.CANCEL_DUPLICATE_MODAL })
            dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE })    
            setInitialValuesForm({
              name: "",
              nameIndicator: "",
              metricObjective: "",
              metricIndicator: "",
              weight: "",
              description: "",
            });      
       
      return
    }
    if(state.editModal){
      console.log("edit objective")
      const { data } = await getCollaboratorInfo({
      variables: {
        input: {
          campaignId: state.campaignId,
          collaboratorId: state.collaboratorId,
        }
      }
    })
    const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
    const weightCount = collaboratorInfo.reduce((acc, curr) => {
      if (parseInt(curr.id as any, 10) === parseInt(state?.selectedObjective?.id as any, 10)) {
        return acc;
      } else {
        return acc + curr.weight;
      }
    }, 0);
    if (weightCount + parseInt(formData.weight, 10) > 100) {
      return ErrorAlert({text:'El peso total de los objetivos no puede ser mayor a 100 '});
    }
    let valueMetrciObjetic=handleCleanFormat(formData.metricObjective)
        
    editObjective({
      variables: {
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
          idCampaign: state.campaignId,
          ObjectiveName: formData.name,
          nameIndicator: formData.nameIndicator,
          metricObjective: parseFloat(valueMetrciObjetic),
          metricIndicator: formData.metricIndicator,
          weight: parseInt(formData.weight, 10),
          descriptionIndicator: formData.description,
          objetiveSteps:'Creada'
        }
      }
    });
    setValueMetricIndicator('');
    setValueMetricObjective('');
    closeModal();
    dispatch({ type: Types.CANCEL_EDIT_MODAL });
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    setInitialValuesForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });  
    return
    }
    if(state.duplicateModal){
    
    const { data } = await getCollaboratorInfo({
      variables: {
        input: {
          campaignId: state.campaignId,
          collaboratorId: state.collaboratorId,
        }
      }
    })
    const collaboratorInfo: Objective[] = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES;
    const weightCount = collaboratorInfo.reduce((acc, curr) => acc + curr.weight, 0);
    if (weightCount + parseInt(formData.weight, 10) > 100) {
      return ErrorAlert({text:'El peso total de los objetivos no puede ser mayor a 100 '});      
    }
    if (state?.rol === 'leader') {
      createObjectiveByLeader({
        variables: {
          input: {
            idCampaign: state.campaignId,
            idCollaborator: state.collaboratorId,
            objectiveName: formData.name,
            nameIndicator: formData.nameIndicator,
            metricObjective: parseFloat(valueMetrciObjetic),
            metricIndicator: formData.metricIndicator,
            weight: parseInt(formData.weight, 10),
            descriptionIndicator: formData.description,
            idLeader: parseInt(adminState?.id || "0", 10)
          }
        }
      });
    } else {
      createObjectiveByUser({
        variables: {
          input: {
            idCampaign: state.campaignId,
            idCollaborator: state.collaboratorId,
            objectiveName: formData.name,
            nameIndicator: formData.nameIndicator,
            metricObjective: parseFloat(valueMetrciObjetic),
            metricIndicator: formData.metricIndicator,
            weight: parseInt(formData.weight, 10),
            descriptionIndicator: formData.description,
          }
        }
      });
    }
    formik.resetForm();
    setValueMetricIndicator('');
    setValueMetricObjective('');    
    dispatch({ type: Types.CANCEL_DUPLICATE_MODAL });
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    setInitialValuesForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });  
      return
    }    
    }
  });  

  const closeModal = () => {
    formik.resetForm();
    setValueMetricIndicator('');
    setValueMetricObjective('');
    if (state.editModal) dispatch({ type: Types.CANCEL_EDIT_MODAL })
    if (state.createModal) dispatch({ type: Types.CANCEL_CREATE_MODAL })
    if (state.duplicateModal) dispatch({ type: Types.CANCEL_DUPLICATE_MODAL })
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE })    
    setInitialValuesForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });   
  }
  return (
   <>
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.createModal || state.editModal || state.duplicateModal}
      fullWidth={true}
     >
    {state.rol==="collaborator" ?
     <DialogContent className="closeCollaborator">
        <IconButton aria-label="close" onClick={(()=> { 
              formik.resetForm();
              closeModal();
              })}
        sx={{position: 'absolute', right: 8, top: 8,color: (theme) => theme.palette.grey[500], }}
        ><CloseIcon />
        </IconButton>  
      </DialogContent>
     :
    <IconButton aria-label="close" onClick={(()=> { 
                                                      formik.resetForm();
                                                      closeModal();
                                                      })}
      sx={{position: 'absolute', right: 8, top: 8,color: (theme) => theme.palette.grey[500], }}
     ><CloseIcon />
    </IconButton>  
}
    <DialogContent >
    
          <Form  
            onSubmit={formik.handleSubmit} 
            className="containerMdalObjective" >
            <DialogTitle ><span className="titlePrestacion"> {state.editModal ? "Editar objetivo" : "Crear objetivo"}</span></DialogTitle> 
            <DialogContent className="oneDivObjective">
                  <TextField
                    name="name"
                    value={formik.values.name}
                    label="Nombre del objetivo *"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}                   
                    InputProps={{
                      readOnly: false,
                    }}                    
                    disabled={false}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name} 
                  />
            </DialogContent>
            <DialogContent className="twoInputsObjective">
               <TextField
                name="nameIndicator"             
                value={formik.values.nameIndicator}
                label="Nombre del indicador *"
                variant="outlined"
                size="small"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                InputProps={{
                  readOnly: false,
                }}
                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                error={formik.touched.nameIndicator && Boolean(formik.errors.nameIndicator)}
                    helperText={formik.touched.nameIndicator && formik.errors.nameIndicator} 
              />

             <TextField
              select
              id="metricIndicator"
              name="metricIndicator" 
              label="Tipo del indicador *"             
              value={formik.values.metricIndicator}
              onChange={(e) => {
                                formik.handleChange(e);
                                changeFormat(e.target.value)}}              
              margin="dense"
              variant="outlined"
              fullWidth
              error={formik.touched.metricIndicator && Boolean(formik.errors.metricIndicator)}
              helperText={formik.touched.metricIndicator && formik.errors.metricIndicator} 
             >
               {arrayMetricIndicator.map((option) => (
                   <option key={option.value} value={option.value}>
                   {option.label}
                 </option>
                  ))}         
            </TextField>
            </DialogContent>           
            <DialogContent className="twoInputsObjective">
              
                    <TextField
                      name="metricObjective"
                      value={formik.values.metricObjective}
                      label="Métrica objetivo *"
                      variant="outlined"              
                      onKeyPress={(event: any) => {
                        if (!/^[0-9.]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}        
                      size="small"
                      fullWidth={true}                      
                      onBlur={() => handleSetFormat()}                      
                      onChange={formik.handleChange}  
                      error={formik.touched.metricObjective && Boolean(formik.errors.metricObjective)}
                      helperText={formik.touched.metricObjective && formik.errors.metricObjective} 
                    />              
                    <TextField
                      name="weight"
                      value={formik.values.weight}
                      label="Peso *"
                      variant="outlined"
                      size="small"                      
                      onKeyPress={(event: any) => {
                        if (!/^[0-9.]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      fullWidth                                                        
                      onChange={(e) => {
                        formik.handleChange(e);
                        updateWeight(e.target.value)}}
                      disabled={false}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      error={formik.touched.weight && Boolean(formik.errors.weight)}
                      helperText={formik.touched.weight && formik.errors.weight} 
                    />     

            </DialogContent>
            <DialogContent className="oneDivObjective">
                <TextField
                 name="description"
                 label="Descripción del objetivo"
                 variant="outlined"             
                 value={formik.values.description}
                 onChange={formik.handleChange}
                 fullWidth
                 multiline
                 size="small"
                 rows={2}
              />
            </DialogContent>
            {
             (state?.rol === 'leader' || state?.rol === 'admin') && state.activeTable === "campaign" && (
              <DialogContent className="oneDivObjective">
               <button onClick={openCollaboratorModal} type="button" className="selectCollaboratorsButton">
                    Administrar colaboradores {state.collaboratorsIds.length > 0 && `(${state.collaboratorsIds.length})`}
                </button>
              </DialogContent>
              )
            }
             <DialogContent className="twoBtnsObjectives">
                 <button className="cancelObjectives" type="button"
                   onClick={(() => {
                    formik.resetForm();
                    closeModal();
                }
                )}>
                Cancelar
              </button>
              <div className="containerAddPrestacion">
                      <button className="btnAddPrestacion" type="submit">
                        <div className="infobtnAddRestriccion">
                          <div><img src={Add} alt="add" /></div>
                          <div><span className="txtBtnAdd">
                             {state.createModal   ?"Crear Objetivo" :
                              state.duplicateModal?"Crear Objetivo" :
                              state.editModal     ?"Editar Objetivo":
                              ""
                             } 
                          </span> </div>
                      </div>
                    </button>
                </div>
              
              </DialogContent>   
           </Form> 
       </DialogContent>
    </Dialog>

   </>
  );
};

export default CreateObjectiveModal;
