import { Grid } from "@material-ui/core";
import { postFileProcessConfronta } from "../../../../services/ConfrontaService";
import { ErrorAlert } from "../../../../alerts/errorAlert";
import { Alert } from "@mui/material";

const FileConfronta = ({
  name,
  Empresa,
  Bimestre,
  accept = ".xls , .xlsx",
  disabled = false
  // setSystemData,
  // setSuaData,
  // setIdseData,
}: any) => {
  const uploadFileProcessSUA = async (e: any): Promise<void> => {
    if(!Empresa || Empresa === ''){
      ErrorAlert({ text: 'Es necesario seleccionar una empresa' });
    }else{
      let result = await postFileProcessConfronta(e, name, Empresa, Bimestre);
      
      // if (result) {
      //   switch (result.type) {
      //     case "IDSE":
      //       setSuaData(result.data);
      //       setSystemData(result.system);
      //       break;
      //     case "SUA":
      //         setIdseData(result.data);
      //         setSystemData(result.system);
      //       break;
      //     default:
      //       break;
      //   }
      // }
    }
  };

  return (
    <Grid>
      <label className="botonSubirDocumento">
        <input
          type="file"
          name={name}
          accept={accept}
          disabled={disabled}
          onChange={(e) => {
            uploadFileProcessSUA(e)
            e.target.value = "";
          }}
        />
        Subir archivo de {name}
      </label>
    </Grid>
  );
};

export default FileConfronta;
