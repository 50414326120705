import { useEffect, useState, useContext, Fragment } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {
  Grid,
  TextField,
  InputLabel,
  Button,
  Checkbox,
  Tooltip,
  FormHelperText,
} from "@material-ui/core";
import CollaboratorContext from "../../../context/CollaboratorContext/CollaboratorContext";
import InputAdornment from "@mui/material/InputAdornment";
import {
  handleSetActiveStep,
  updateData,
} from "../../../helpers/Collaborator/Collaborator";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Select } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { WarningAlert } from "../../../alerts/WarningAlert";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "semantic-ui-react";
import moment from "moment";
import {
  UPDATE_USERS,
  GET_ALL_PAYROLL_GROUP,
  GET_USERS_BY_ID,
  GET_ALL_ENTERPRISE,
  GET_ALL_OUTSOURCERS,
  GET_ALL_TIPOJORNADA,
  GET_ALL_CALENDARS,
  GET_ALL_USER_EVENTUAL,
  GET_ALL_WEEKPARTTIME,
  GET_ALL_USERS_COLLABORATOR,
  GET_EXCEL_ACTIVE_USER,
  GET_POLITIC_SAVING_BY_PAYROLLGROUP,
  GET_USER_HOLIDAYS,
  GET_TIMELINE_EVENTS,
  CREATE_RENEWAL_HISTORY,
  GET_ALL_WORKINGHOURS
} from "../../../Querys/querys";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import SaveIcon from "@material-ui/icons/Save";
import { EventualPayroll } from "../../../interfaces/TabEventualPayroll.interfaces";
import EyeIcon from "../../../assets/svg/eye.svg";
import RemoveFile from "../../../assets/svg/remove_file.svg";
import FileHelper from "../Expedient/Fields/File_Helper";
import { formatterMxn } from "../../../helpers/formatoMoneda";
import CheckPermission from "../../../helpers/Administradores/Permissions";
import { withStyles, Theme } from "@material-ui/core/styles";
import { PoliticSaving as Politic } from "../../../interfaces/TabPolitic.interfaces";
import {addSalaryHistory} from "../../../context/CollaboratorContext/Actions";
import { AdminContext } from "../../../context/AdminContext/AdminContext";
import {SalaryHistory} from "./SalaryHistory";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const limitPercentage = 13;

const HiringData = (props: any) => {
  const history:any = useHistory();
  const { data: resultCompany } = useQuery(GET_ALL_ENTERPRISE);
  const allCompanies = resultCompany?.GET_ALL_ENTERPRISE;
  const { data: resultWeekPartTime } = useQuery(GET_ALL_WEEKPARTTIME);
  const allWeekPartTime = resultWeekPartTime?.GET_ALL_WEEKPARTTIME;
  const { data: resultOutsources } = useQuery(GET_ALL_OUTSOURCERS);
  const allOutsources = resultOutsources?.GET_ALL_OUTSOURCERS;
  const { data: resultWorkingHours } = useQuery(GET_ALL_WORKINGHOURS);
  const allWorkingHours = resultWorkingHours?.GET_ALL_WORKINGHOURS;
  const [companies, setCompanies] = useState([]);
  const [archives, setArchives] = useState([]);
  const [registros_patronales, setRegistrosPatronales] = useState([]);
  const [isFondoAhorro, setIsFondoAhorro] = useState(false);

  const { data: resultPayRollGroup } = useQuery(GET_ALL_PAYROLL_GROUP,{fetchPolicy: 'no-cache'});
  const allPayRollGroups = resultPayRollGroup?.GET_ALL_PAYROLL_GROUP;
  const [payRollGroups, setPayRollGroups] = useState([]);
  const [checkPayrollSavingConfig, { data:resultPolitic }] = useLazyQuery(GET_POLITIC_SAVING_BY_PAYROLLGROUP,{fetchPolicy: 'no-cache'});
  const [createRenewalHistory] = useMutation(CREATE_RENEWAL_HISTORY);
  const [checkSelectedPayrollSavingConfig, { data:resultPoliticSelected }] = useLazyQuery(GET_POLITIC_SAVING_BY_PAYROLLGROUP,{fetchPolicy: 'no-cache'});
  const [tipoContratacion, setTipoContratacion] = useState("Directa");
  const [tipoEsquema, setTipoEsquema] = useState("Nomina");
  const [recordableSalary, setRecordableSalary] = useState("");
  const [typeContract, setTypeContract] = useState("indefinida");
  const [typeSalary, setTypeSalary] = useState("Bruto");
  const [employerRegister, setEmployerRegister] = useState("");
  const [idEnterprise, setIdEnterprise] = useState("");
  const [idPayRollGroup, setIdPayRollGroup] = useState("");
  const { state, dispatch } = useContext(CollaboratorContext);
  const disabled = false;  
  const [disabledInfoCompany, setDisabledInfoCompany] = useState(false);
  const [outsources, setOutsources] = useState([]);
  const [workingHours, setWorkingsHours] = useState([]);
  const [jornadas, setJornadas] = useState<Array<any>>([]);
  const [weekPartTime, setWeekPartTime] = useState<Array<any>>([]);
  const [calendars, setCalendars] = useState<Array<any>>([]);
  const [filterCalendars, setFilterCalendars] = useState<Array<any>>([]);
  const [selectedPolitic, setSelectedPolitic] = useState<Partial<Politic>>({
    saving_fund_cap: 0
  });
  const { adminState } = useContext(AdminContext);
  const [updateColaboradores] = useMutation(UPDATE_USERS, {
    refetchQueries: [
      {
        query: GET_USERS_BY_ID,
        variables: { getUsersId: state.collaborator.id },
      },
      {
        query: GET_ALL_USER_EVENTUAL,
      },
      { query: GET_ALL_USERS_COLLABORATOR },
      { query: GET_EXCEL_ACTIVE_USER },
      {
        query: GET_TIMELINE_EVENTS,
        variables: { id: state.collaborator.id },
      },
      {
        query: GET_USER_HOLIDAYS,
        variables: { id: state.collaborator.id },
      }
    ],
  });

  const { data: resultJornada } = useQuery(GET_ALL_TIPOJORNADA);
  const allJornadas = resultJornada?.GET_ALL_TIPOJORNADA;

  const { data: resultCalendars } = useQuery(GET_ALL_CALENDARS);
  const allCalendars = resultCalendars?.GET_ALL_CALENDARS;

  const initialValues = () => {
  
    return {
      tittle: state.collaborator?.tittle,
      idWorkingHours : state.collaborator.idWorkingHours,
      EmployerRegister: state.collaborator?.EmployerRegister,
      journey: state.collaborator?.journey,
      idJourney: state.collaborator?.idJourney,
      typeContract: state.collaborator?.typeContract,
      dateContractDate: state.collaborator?.dateContractDate,
      nominesPayroll: state.collaborator?.nominesPayroll,
      ingresoBruto: state.collaborator?.ingresoBruto,
      ingresoNeto: state.collaborator?.ingresoNeto,
      grossSalary: state.collaborator?.typeSalary === "Bruto" ? state.collaborator?.grossSalary : state.collaborator?.typeSalary === "Neto" ? state.collaborator?.netSalary : "",
      recordableSalary: state.collaborator?.recordableSalary,
      notRecordableSalary: state.collaborator?.notRecordableSalary,
      totalSalary: state.collaborator?.totalSalary,
      periodicidadDePago: state.collaborator?.periodicidadDePago,
      idEnterprise: state.collaborator?.idEnterprise,
      typeSalary: state.collaborator?.typeSalary,
      id_payroll_group: state.collaborator?.id_payroll_group,
      DS: state.collaborator?.DS,
      ISD: state.collaborator?.ISD,
      SDG: state.collaborator?.SDG,
      accept_info: state.collaborator?.accept_info,
      unionized: state.collaborator?.unionized,
      calendarId: state.collaborator?.calendarId,
      typeWorker: state.collaborator?.typeWorker,
      typeSalaryN: state.collaborator?.typeSalaryN,
      weekPartTimeId: state.collaborator?.weekPartTimeId,
      weekPartTime: state.collaborator?.weekPartTime,
      isFondoAhorro: state.collaborator?.isFondoAhorro ?? "",
      PorcentajeFondoAhorro: state.collaborator?.PorcentajeFondoAhorro ?? "",
    };
  };

  const validationSchema = () => {
    return {
      grossSalary: Yup.string().when("nominesPayroll", {
        is: "Nomina",
        then: Yup.string().required(
          "Es necesario que ingrese el ingreso mensual bruto"
        ),
        otherwise(schema) {
          return schema.required("Es necesario que ingrese el ingreso mensual bruto");
        },
      }),

      DS: Yup.string()
        .required("Obligatorio")
        .nullable(),
      recordableSalary: Yup.string().nullable()
        .when("nominesPayroll", {
          is: "Mixto",
          then: Yup.string().required(
            "Obligatorio"
          ),
        }),
      ISD: Yup.string()
        .required("Obligatorio")
        .test(
          "compare_salary",
          "El valor del salario diario integrado no puede ser menor que el salario diario gravable",
          function (ISD: string | undefined): boolean {
            return ISD && this.parent.SDG && this.parent.nominesPayroll === 'Mixto'
              ? Number(ISD.replace(/,/g,'')) > Number(this.parent.SDG.replace(/,/g,''))
              : true;
          }
        )
        .nullable(),
      SDG: Yup.string().when("nominesPayroll", {
        is: "Nomina",
        then: Yup.string().notRequired().nullable(),
        otherwise: Yup.string()
          .required("Es necesario que ingrese el salario diario integrado")
          .nullable(),
      }),
      idJourney: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required("Es necesario que ingrese la jornada"),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      unionized: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que ingrese si es sindicalizado"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      id_payroll_group: Yup.string().required(),

      idEnterprise: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que seleccione la empresa"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      idWorkingHours: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que seleccione una jornada laboral"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      EmployerRegister: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que seleccione el registro patronal"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      calendarId: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que seleccione el calendario"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      typeWorker: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que seleccione el calendario"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      typeSalaryN: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que seleccione el calendario"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      weekPartTimeId: Yup.string().when("tittle", {
        is: "Directa",
        then: Yup.string().required(
          "Es necesario que seleccione el calendario"
        ),
        otherwise: Yup.string().notRequired().nullable(),
      }),
      PorcentajeFondoAhorro: Yup.string().nullable().test(
        "compare_percentage",
        "Obligatorio",
        function (PorcentajeFondoAhorro: string | null | undefined): boolean {
          if(!this.parent.isFondoAhorro){
            return true;
          }else{
            if(PorcentajeFondoAhorro){
              return true
            }else{
              return false;
            }
          }
        }
      ).test(
        "compare_percentage",
        "El total no puede ser mayor a "+( selectedPolitic.saving_fund_type === 'Porcentual' ? limitPercentage+"%" : "$"+selectedPolitic.saving_fund_cap ),
        function (PorcentajeFondoAhorro: string | null | undefined): boolean {
          if(!this.parent.isFondoAhorro){
            return true;
          }else{
            if(PorcentajeFondoAhorro){
              let total = PorcentajeFondoAhorro.replace(/\..*/, "");
              total = total.replace(/[^0-9]/g, "");
              let x = parseInt(total);
              const limit = selectedPolitic && selectedPolitic.saving_fund_cap ? selectedPolitic.saving_fund_cap : 0;
              if ( limit > 0 &&((selectedPolitic.saving_fund_type === 'Porcentual' && x > limitPercentage ) 
                  || (selectedPolitic.saving_fund_type === 'Fijo' && x > limit) )
                ) {
                return false;
              } else {
                return true;
              }
            }else{
              return false;
            }
          }
        }
      )
    };
  };

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (formData) => {
      let grossSalaryM = formData.grossSalary;
      let DSM = formData.DS;
      let recordableSalaryM = formData.recordableSalary;
      let SDGM = formData.SDG;
      let ISDM = formData.ISD;
      if (formData.nominesPayroll === "Nomina") {
        formData.typeSalary = "Bruto";
      }
      if (formData.nominesPayroll === "Mixto") {
        formData.typeSalary = "Neto";
      }
      formData.idEnterprise = parseInt(formData.idEnterprise);
      formData.id_payroll_group = parseInt(formData.id_payroll_group);
      formData.calendarId = parseInt(formData.calendarId);

      if (formData.unionized === "0" || formData.unionized === false) {
        formData.unionized = false;
      } else if (formData.unionized === "1" || formData.unionized === true) {
        formData.unionized = true;
      } else {
        formData.unionized = null;
      }

      if (formData.DS) {
        formData.DS = handleCleanFormat(String(formData.DS));
      }
      if (formData.ISD) {
        formData.ISD = handleCleanFormat(String(formData.ISD));
      }
      if (formData.SDG) {
        formData.SDG = handleCleanFormat(String(formData.SDG));
      }

      if (formData.recordableSalary) {
        formData.recordableSalary = handleCleanFormat(
          String(formData.recordableSalary)
        );
      }

      if (formData.notRecordableSalary) {
        formData.notRecordableSalary = handleCleanFormat(
          String(formData.notRecordableSalary)
        );
      }

      if (formData.totalSalary) {
        formData.totalSalary = handleCleanFormat(String(formData.totalSalary));
      }
      if (formData.grossSalary) {
        formData.grossSalary = handleCleanFormat(String(formData.grossSalary));
      }

      if (formData.nominesPayroll === "Nomina") {
        formData.recordableSalary = null;
        formData.notRecordableSalary = null;
      }
      formData.accept_info = state.collaborator?.accept_info;
      if(formData.tittle !== 'outsource'){
        const journey = jornadas.find((j) => {
          // console.log(j.clave, formData.idJourney);
          return String(j.clave) === String(formData.idJourney);
        });
        formData.journey = journey.Descripcion;

        const weekParttime = weekPartTime.find((j) => {
          return String(j.id) === String(formData.weekPartTimeId);
        });
        formData.weekPartTime = weekParttime.name;
        formData.weekPartTimeId = parseInt(formData.weekPartTimeId);
      }

      if (formData.isFondoAhorro === true) {
        formData.isFondoAhorro = true;
      } else {
        formData.isFondoAhorro = false;
      }
      formData.idWorkingHours = Number(formData.idWorkingHours);

      if (formData.PorcentajeFondoAhorro === "" || !isFondoAhorro) {
        formData.PorcentajeFondoAhorro = null;
      }
      if (formData.PorcentajeFondoAhorro) {
        formData.PorcentajeFondoAhorro = handleCleanFormat(String(formData.PorcentajeFondoAhorro));
      }
      if(formData.typeContract === 'temporal' && formData.dateContractDate !== state.collaborator?.dateContractDate){
        createRenewalHistory({
          variables:{
            input:{
              previousDate: state.collaborator?.dateContractDate ? state.collaborator?.dateContractDate : formData.dateContractDate,
              currentDate: formData.dateContractDate,
              user: adminState?.Usuario,
              dateAdd: new Date(),
              collaboratorId: Number(state.collaborator.id),
            }
          }
        });
      }
      if( tipoEsquema === 'Mixto' && (
        formData.grossSalary !== state.collaborator?.grossSalary || 
        formData.DS !== state.collaborator?.DS ||
        formData.recordableSalary !== state.collaborator?.recordableSalary ||
        formData.SDG !== state.collaborator?.SDG ||
        formData.ISD !== state.collaborator?.ISD
      )){
        addSalaryHistory(
          adminState?.Usuario
          ,grossSalaryM
          ,DSM
          ,recordableSalaryM
          ,SDGM
          ,ISDM
          ,tipoEsquema
          ,formData
          ,dispatch 
        )
      }
      else if( tipoEsquema === 'Nomina' && (
        formData.grossSalary !== state.collaborator?.grossSalary || 
        formData.ISD !== state.collaborator?.ISD
      )){
        // console.log("100 %")
         addSalaryHistory(
          adminState?.Usuario
          ,grossSalaryM
          ,DSM
          ,recordableSalaryM
          ,SDGM
          ,ISDM
          ,tipoEsquema
          ,formData
          ,dispatch 
        )
      }
      else{
        updateColaboradores({
          variables: {
            updateUsersId: state.collaborator?.id,
            input: formData,
            process: {
              tab: "information",
              section: "section_3",
            },
          },
        })
        .then(() => {
          handleSetFormat(formData?.DS, "DS");
          handleSetFormat(formData?.ISD, "ISD");
          handleSetFormat(formData?.SDG, "SDG");
          handleSetFormat(formData?.recordableSalary, "recordableSalary");
          handleSetFormat(formData?.notRecordableSalary, "notRecordableSalary");
          handleSetFormat(formData?.totalSalary, "totalSalary");
          handleSetFormat(formData?.grossSalary ?? "0", "grossSalary");
          handleSetFormat(formData.PorcentajeFondoAhorro, "PorcentajeFondoAhorro");
        })
        .finally(() => {
          SuccessfulAlert({ text: "Se actualizó correctamente" }).then(() => {
            history.push(`/collaborators/${state.collaborator?.id}/2`, history.location.state && history.location.state.from ? {from: history.location.state.from} : undefined);
            handleSetActiveStep(0, dispatch);
          });
        });
      }
    },
  });

  const handleCleanFormat = (value: string) => {
     //check if string contains .00
    if (value.includes(".00")) {
      value = value.substring(0, value.indexOf(".00"));
    }
    value = value.replace(/,/g, "");
    // value = value.replace(/[^0-9]/g, "");
    //parse to float and fixed to 2 decimals take the decimal after the comma
    let x = parseFloat(value).toFixed(2);
    let y = parseFloat(x);
    return y;
  };

  const handleSetFormat = (value: any, field: any) => {
    //
    if (typeof value === "string") {
      // if (value?.indexOf(".") > -1) {
      //   value = value.substring(0, value.indexOf("."));
      // }
      // valueFormatted = valueFormatted.replace(/[^0-9]/g, "");
      let valueFormatted = value.replace(/,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);

      let x = formatterMxn(valueFormatted);
      if (x === "nan") return false;
      x = x.substring(1);
      formik.setFieldValue(field, x);
    } else {
      let x = formatterMxn(value);
      if (x === "nan") return false;
      x = x.substring(1);
      formik.setFieldValue(field, x);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (allJornadas) setJornadas(allJornadas);
    if (allWeekPartTime) setWeekPartTime(allWeekPartTime);
    if (allCalendars) {
      setCalendars([...allCalendars]);
    }
    if(allWorkingHours){
      setWorkingsHours(allWorkingHours);
    }
    handleSetFormat(state.collaborator?.PorcentajeFondoAhorro, "PorcentajeFondoAhorro");
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allJornadas, allCalendars, allWeekPartTime, state.collaborator, allWorkingHours]);

  useEffect(()=>{
    if(resultPolitic && resultPolitic.GET_POLITIC_SAVING_BY_PAYROLLGROUP){
      const result = resultPolitic.GET_POLITIC_SAVING_BY_PAYROLLGROUP;
      setSelectedPolitic( result );
        if(result && result.saving_fund_cap > 0){
          setIsFondoAhorro(true);
          formik.setFieldValue('isFondoAhorro', true);
          formik.setFieldValue('PorcentajeFondoAhorro', result.saving_fund_type === 'Porcentual' ? limitPercentage : result.saving_fund_cap); 
        }else{
          formik.setFieldValue('PorcentajeFondoAhorro', 0); 
          formik.setFieldValue('isFondoAhorro', false); 
        }

    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultPolitic]);

  useEffect(()=>{
    if(resultPoliticSelected && resultPoliticSelected.GET_POLITIC_SAVING_BY_PAYROLLGROUP){
      const result = resultPoliticSelected.GET_POLITIC_SAVING_BY_PAYROLLGROUP;
      setSelectedPolitic( result );
      if(!result.saving_fund_cap || result.saving_fund_cap === 0){
        formik.setFieldValue('isFondoAhorro', false);
        formik.setFieldValue('PorcentajeFondoAhorro', 0); 
      }else{
        setIsFondoAhorro(state.collaborator.isFondoAhorro);
        if(state.collaborator.isFondoAhorro && !state.collaborator.PorcentajeFondoAhorro){
          formik.setFieldValue('PorcentajeFondoAhorro', result.saving_fund_cap);
        }
        //formik.setFieldValue('PorcentajeFondoAhorro', state.collaborator.isFondoAhorro); 
      }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultPoliticSelected, state]);

  useEffect(() => {
    if (state.collaborator) {
      // console.log(state.collaborator)
      setTipoContratacion(state.collaborator?.tittle);
      setTipoEsquema(state.collaborator?.nominesPayroll);
      setTypeContract(state.collaborator?.typeContract);
      setRecordableSalary(state.collaborator?.recordableSalary);
      setTypeSalary(state.collaborator?.typeSalary);
      setEmployerRegister(state.collaborator?.EmployerRegister);
      setIdEnterprise(state.collaborator?.idEnterprise);

      if(state.collaborator?.collaborator){
        setDisabledInfoCompany(true)
      }

      if(state.collaborator.weekPartTimeId){
        formik.setFieldValue('weekPartTimeId', state.collaborator.weekPartTimeId)
      }
      if(state.collaborator.idEnterprise){
        formik.setFieldValue('idEnterprise', state.collaborator.idEnterprise)
      }
      if(state.collaborator.idWorkingHours){
        formik.setFieldValue('idWorkingHours', state.collaborator.idWorkingHours)
      }
      if(state.collaborator.calendarId){
        formik.setFieldValue('calendarId', state.collaborator.calendarId)
      }
      if(state.collaborator.idJourney){
        formik.setFieldValue('idJourney', state.collaborator.idJourney)
      }
      if(state.collaborator?.id_payroll_group){
        setIdPayRollGroup(state.collaborator.id_payroll_group);
        checkSelectedPayrollSavingConfig({ variables:{ payrollGroupId: state.collaborator.id_payroll_group } });
      }
      // setCartaOferta(state.collaborator.cartaOferta);
      if (state.collaborator?.archive) {
        setArchives(state.collaborator.archive);
      }
      handleSetFormat(state.collaborator?.DS, "DS");
      handleSetFormat(state.collaborator?.ISD, "ISD");
      handleSetFormat(state.collaborator?.SDG, "SDG");
      handleSetFormat(state.collaborator?.recordableSalary, "recordableSalary");
      handleSetFormat(
        state.collaborator?.notRecordableSalary,
        "notRecordableSalary"
      );
      handleSetFormat(state.collaborator?.totalSalary, "totalSalary");
      handleSetFormat(state.collaborator?.grossSalary, "grossSalary");
    }
    if (allCompanies) {
      setCompanies(allCompanies);

      // if (state.collaborator?.tittle == "Directa") {
      for(const company of allCompanies){
        if (String(company.id) === String(state.collaborator?.idEnterprise)) {
          if (company.registro_patronal) {
            setRegistrosPatronales(company.registro_patronal);
            break;
          }
        } else {
          setRegistrosPatronales(allCompanies[0].registro_patronal);
        }
      };

      const mycalendars = calendars.filter(
        (calendar: any) =>
          String(calendar.enterpriseId) === String(state.collaborator?.calendarId)
      );
      if (mycalendars.length > 0) {
        setFilterCalendars(mycalendars);
      } else {
        let x = calendars.filter(
          (calendar: any) => String(calendar.enterpriseId) === String(allCompanies[0].id)
        );
        setFilterCalendars(x);
      }
    }
    if (allOutsources) {
      setOutsources(allOutsources);
    }
    if (allPayRollGroups) {
      setPayRollGroups(allPayRollGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCompanies, allPayRollGroups, state, allOutsources, calendars, checkPayrollSavingConfig]);

  const handleChange = async (e: any) => {
    await updateData(e, state, dispatch, 0);
  };

  const cambioTipoContratacion = async (e: any) => {
    setTipoContratacion(e.target.value);
    await updateData(e, state, dispatch, 0);
  };
  const handleTypeContract = async (e: any) => {
    setTypeContract(e.target.value);
    await updateData(e, state, dispatch, 0);
  };
  const cambioTipoEsquema = async (e: any) => {
    
    if(e.target.value === 'Nomina'){

      state.collaborator.typeSalary = "Bruto";
      setTypeSalary('Bruto')  
    }
    if(e.target.value === 'Mixto'){

      state.collaborator.typeSalary = "Neto";
      setTypeSalary('Neto')  
    }
    setTipoEsquema(e.target.value);
    await updateData(e, state, dispatch, 0);
  };

  const onChangeWorkingHours = (e:any) => {
    const filterWorkingHours:any = workingHours.filter((item:any)=>{
      return String(item.id) === String(e.target.value);
    });
    if(filterWorkingHours[0]){
      const filterJourney:any = jornadas.filter((item:any)=>{
        return String(item.id) === String(filterWorkingHours[0].idTypeJourney);
      });
      if(filterJourney[0]){
        formik.setFieldValue('weekPartTimeId', filterWorkingHours[0].idWeek);
        formik.setFieldValue('idJourney', filterJourney[0].clave);
        // console.log(formik.values);
      }
    }
  }


  const handleChangeRegistroPatronal = async (id: any) => {
    for(const company of allCompanies){
      if (String(company.id) === String(id)) {
        if (company.registro_patronal) {
          setRegistrosPatronales(company.registro_patronal);
        }
      }
    };

    const filteredCalendars = calendars.filter(
      (calendar: any) => String(calendar.enterpriseId) === String(id)
    );
    setFilterCalendars(filteredCalendars);
  };

  const handleSetDS = async (e: any) => {
    //if e is empty, set DS to 0
    if (e === "") return false;

    let value = await handleCleanFormat(e);
    value = value / 30;
    const valueRes = await handleSetFormat2(value);
    if (valueRes === false) {
      formik.setFieldValue("DS", "0.00");
    } else {
      formik.setFieldValue("DS", valueRes);
    }
    return valueRes;
  };

  const handleSetFormat2 = (value: any) => {
    //parse value to string if is number
    if (typeof value === "number") {
      value = value.toString();
    }

    if (typeof value === "string") {
      let valueFormatted = value.replace(/,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);

      let x = formatterMxn(valueFormatted);
      if (x === "nan") return false;
      let y = x.substring(1);
      return y;
      // formik.setFieldValue(field, x);
    }
  };

  const handleSetNotRecordableSalary = async (itm = "0.00", itg = "0.00") => {
    if (itm && itg) {
      let value = await handleCleanFormat(itm);
      let value2 = await handleCleanFormat(itg);
      let valueRes = value - value2;
      let valueRes2 = await handleSetFormat(valueRes, "notRecordableSalary");
      // console.log(value, value2, valueRes, valueRes2);
      if (valueRes2 !== false) {
        formik.setFieldValue("notRecordableSalary", valueRes2);
        //get an input by id and click it
        document.getElementById("notRecordableSalary")?.click();
        const MonthlyIncome :any= (value2 / 30);     
        const stringMonthlyIncome= MonthlyIncome.toFixed(2)
        formik.setFieldValue("SDG", stringMonthlyIncome);      
      }
    } else {
      formik.setFieldValue("notRecordableSalary", "0.00");
      document.getElementById("notRecordableSalary")?.click();
    }
  };

  const handleSetMonthlyIncome =  (sdg:any = 0.00) => { 
    if(parseFloat(sdg) !== 0.00){
    const MonthlyIncome = sdg * 30;
    const stringMonthlyIncome=handleCleanFormat(MonthlyIncome.toString())
     formik.setFieldValue("recordableSalary", stringMonthlyIncome);
    }    
  };

  const upDateTotalSavingFound = (total: any) => {
    total = total.replace(/\..*/, "");
    //remove all especial characters and letters from string
    total = total.replace(/[^0-9]/g, "");
    //parse to int and check if is bigger than 100
    let x = parseInt(total);
    const limit = selectedPolitic && selectedPolitic.saving_fund_cap ? selectedPolitic.saving_fund_cap : 0;
    if ( limit > 0 && ((selectedPolitic.saving_fund_type === 'Porcentual' && x > limitPercentage ) 
        || (selectedPolitic.saving_fund_type === 'Fijo' && x > limit)) 
      ) {
      WarningAlert({ text: "El total no puede ser mayor a "+( selectedPolitic.saving_fund_type === 'Porcentual' ? limitPercentage+"%" : "$"+limit ) });
      return "";
    } else {
      return total;
    }
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <div className="novalidate__border">
        <span style={{ fontWeight: "bold" }}>Tipo de contratación</span>
        <Grid
          direction="row"
          container
          spacing={3}
          //   style={{ border: "1px solid red" }}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Directa"
                name="tittle"
              >
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Contratación directa"
                  value="Directa"
                  checked={tipoContratacion === "Directa" ? true : false}
                  onChange={cambioTipoContratacion}
                  name="tittle"
                  onBlur={formik.handleChange}
                />
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Outsourcer"
                  value="outsource"
                  checked={tipoContratacion === "outsource" ? true : false}
                  onChange={cambioTipoContratacion}
                  name="tittle"
                  onBlur={formik.handleChange}
                />
              </RadioGroup>
            </FormControl>
            {tipoContratacion === "Directa" ? (
              <>
                <Grid spacing={3} direction="row" container>
                  <Grid xs={4} item>
                    <FormControl fullWidth={true} size="small">
                      <InputLabel>Empresa</InputLabel>

                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={(e) => {
                          handleChangeRegistroPatronal(e.target.value);
                        }}
                        name="idEnterprise"
                        defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabledInfoCompany}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.idEnterprise &&
                          Boolean(formik.errors.idEnterprise)
                        }
                      >
                        <option selected disabled>
                          Seleccione una empresa
                        </option>
                        {companies.map(({ name, id }: any, index: number) => (
                          <>
                            {String(idEnterprise) === String(id) ? (
                              <option key={index} value={id} selected>
                                {name}
                              </option>
                            ) : (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            )}
                          </>
                        ))}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.idEnterprise &&
                          !formik.values.idEnterprise &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Registro patronal</InputLabel>

                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="EmployerRegister"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabledInfoCompany}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.EmployerRegister &&
                          Boolean(formik.errors.EmployerRegister)
                        }
                      >
                        <option selected disabled>
                          Seleccione un registro patronal
                        </option>
                        {registros_patronales.map(
                          ({ registroPatronal }: any, index: number) => (
                            <>
                              {String(employerRegister) === String(registroPatronal) ? (
                                <option
                                  key={index}
                                  value={registroPatronal}
                                  selected
                                >
                                  {registroPatronal}
                                </option>
                              ) : (
                                <option key={index} value={registroPatronal}>
                                  {registroPatronal}
                                </option>
                              )}
                            </>
                          )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.EmployerRegister &&
                          !formik.values.EmployerRegister &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Calendario</InputLabel>

                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="calendarId"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.calendarId &&
                          Boolean(formik.errors.calendarId)
                        }
                      >
                        <option selected disabled>
                          Seleccione un calendario
                        </option>
                        {filterCalendars.map(
                          ({ name, id }: any, index: number) => (
                            <>
                              {String(state.collaborator?.calendarId) === String(id) ? (
                                <option key={index} value={id} selected>
                                  {name}
                                </option>
                              ) : (
                                <option key={index} value={id}>
                                  {name}
                                </option>
                              )}
                            </>
                          )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.calendarId &&
                          !formik.values.calendarId &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  
                </Grid>

                <Grid
                  direction="row"
                  container
                  spacing={3}
                  //   style={{ border: "1px solid red" }}
                >
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Sindicalizado</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="unionized"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.unionized &&
                          Boolean(formik.errors.unionized)
                        }
                      >
                        <option disabled selected>
                          Seleccione una opción
                        </option>
                        <option
                          value={"1"}
                          selected={formik.values.unionized === true}
                        >
                          {"Si"}
                        </option>
                        <option
                          value={"0"}
                          selected={formik.values.unionized === false}
                        >
                          {"No"}
                        </option>
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.unionized &&
                        formik.errors.unionized}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Tipo de trabajador</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="typeWorker"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.typeWorker &&
                          Boolean(formik.errors.typeWorker)
                        }
                      >
                        <option disabled selected
                        value=""
                        >
                          Seleccione una opción
                        </option>
                        <option
                          value={"1) Trab. permanente"}
                          selected={formik.values.typeWorker === "1) Trab. permanente"}
                        >
                          {"1) Trab. permanente"}
                        </option>
                        <option
                          value={"2) Trab. Ev. Ciudad"}
                          selected={formik.values.typeWorker === "2) Trab. Ev. Ciudad"}
                        >
                          {"2) Trab. Ev. Ciudad"}
                        </option>
                        <option
                          value={"3) Trab. Ev. Construcción"}
                          selected={formik.values.typeWorker === "3) Trab. Ev. Construcción"}
                        >
                          {"3) Trab. Ev. Construcción"}
                        </option>
                        <option
                          value={"4) Eventual del campo"}
                          selected={formik.values.typeWorker === "4) Eventual del campo"}
                        >
                          {"4) Eventual del campo"}
                        </option>
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.typeWorker &&
                        !formik.values.typeWorker &&
                        "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Tipo de salario</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="typeSalaryN"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.typeSalaryN &&
                          Boolean(formik.errors.typeSalaryN)
                        }
                      >
                        <option disabled selected
                        value={""}
                        >
                          Seleccione una opción
                        </option>
                        <option
                          value={"Salario fijo"}
                          selected={formik.values.typeSalaryN === "Salario fijo"}
                        >
                          {"Salario fijo"}
                        </option>
                        <option
                          value={"Salario variable"}
                          selected={formik.values.typeSalaryN === "Salario variable"}
                        >
                          {"Salario variable"}
                        </option>
                        <option
                          value={"Salario mixto"}
                          selected={formik.values.typeSalaryN === "Salario mixto"}
                        >
                          {"Salario mixto"}
                        </option>
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.typeSalaryN &&
                        !formik.values.typeSalaryN &&
                        "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                  //   style={{ border: "1px solid red" }}
                >
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Jornada laboral</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={(e) => {formik.handleChange(e); onChangeWorkingHours(e)}}
                        name="idWorkingHours"
                        defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={disabled}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.idWorkingHours &&
                          Boolean(formik.errors.idWorkingHours)
                        }
                      >
                        <option selected disabled>
                          Seleccione una jornada laboral
                        </option>
                        {workingHours.map(
                          ({ id, Nombre }: any, index: number) =>
                            String(state.collaborator?.idWorkingHours) === String(id) ? (
                              <option key={index} value={id} selected>
                                {Nombre}
                              </option>
                            ) : (
                              <option key={index} value={id}>
                                {Nombre}
                              </option>
                            )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.idWorkingHours &&
                          !formik.values.idWorkingHours &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Jornada</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="idJourney"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.idJourney &&
                          Boolean(formik.errors.idJourney)
                        }
                        value={formik.values.idJourney || ''}
                      >
                        <option disabled>
                          Seleccione una jornada
                        </option>
                        {jornadas.map(
                          ({ clave, Descripcion }: any, index: number) =>
                            (
                              <option key={index} value={clave}>
                                {Descripcion}
                              </option>
                            )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.idJourney &&
                          !formik.values.idJourney &&
                          "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Semana/jornada reducida</InputLabel>
                      <Select
                        native
                        onBlur={formik.handleChange}
                        onChange={formik.handleChange}
                        name="weekPartTimeId"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                        error={
                          formik.touched.weekPartTimeId &&
                          Boolean(formik.errors.weekPartTimeId)
                        }
                        value={formik.values.weekPartTimeId || ''}
                      >
                        <option disabled
                        value=""
                        >
                          Seleccione opción
                        </option>
                        {weekPartTime.map(
                          ({ id, clave, name }: any, index: number) =>
                            (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            )
                        )}
                      </Select>
                      <FormHelperText style={{color: "red"}}>
                        {formik.touched.weekPartTimeId &&
                        !formik.values.weekPartTimeId &&
                        "Obligatorio"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

              </>
            ) : (
              <Grid spacing={3} direction="row" container>
                <Grid xs={5} item>
                  <FormControl variant="outlined" fullWidth={true} size="small">
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      native
                      onBlur={formik.handleChange}
                      name="idEnterprise"
                      onChange={formik.handleChange}
                      defaultValue={idEnterprise || ""}
                      autoFocus={true}
                      disabled={disabled}
                      style={{ height: "42px" }}
                      variant="outlined"
                      error={
                        formik.touched.idEnterprise &&
                        Boolean(formik.errors.idEnterprise)
                      }
                    >
                      <option selected disabled>
                        Seleccione una empresa
                      </option>
                      {outsources.map(({ nombre, id }: any, index: number) => (
                        <>
                          {String(idEnterprise) === (id) ? (
                            <option key={index} value={id} selected>
                              {nombre}
                            </option>
                          ) : (
                            <option key={index} value={id}>
                              {nombre}
                            </option>
                          )}
                        </>
                      ))}
                    </Select>
                    <FormHelperText style={{color: "red"}}>
                      {formik.touched.idEnterprise &&
                      !formik.values.idEnterprise &&
                      "Obligatorio"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                ></Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span>Vigencia del contrato</span>
        <Grid
          direction="row"
          container
          spacing={3}
          //   style={{ border: "1px solid red" }}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="indefinida"
                name="typeContract"
              >
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  onChange={(e) => handleTypeContract(e)}
                  label="Indefinida"
                  value="indefinida"
                  name="typeContract"
                  checked={typeContract === "indefinida" ? true : false}
                  onBlur={formik.handleChange}
                />
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Temporal"
                  value="temporal"
                  onChange={(e) => handleTypeContract(e)}
                  name="typeContract"
                  checked={typeContract === "temporal" ? true : false}
                  onBlur={formik.handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {typeContract === "temporal" ? (
          <>
            <span>Fecha de vigencia del contrato</span>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="date"
              size="small"
              style={{ width: "100%" }}
              name="dateContractDate"
              onChange={formik.handleChange}
              defaultValue={
                state.collaborator
                  ? moment(state.collaborator.dateContractDate).add(1, "day").format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              InputProps={{
                inputProps: {
                  min: moment(state.collaborator.dateOfAdmission)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                },
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span style={{ fontWeight: "bold" }}>Grupo de nóminas</span>
        <Grid
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <FormControl fullWidth={true} size="small" style={{ width: "100%" }}>
            <Select
              native
              onBlur={formik.handleChange}
              name="id_payroll_group"
              onChange={(e) => { 
                formik.handleChange(e); 
                setIsFondoAhorro(false);
                formik.setFieldValue('isFondoAhorro', false);
                checkPayrollSavingConfig({ variables: { payrollGroupId: e.target.value } });
              }}
              defaultValue={idPayRollGroup || ""}
              autoFocus={true}
              disabled={disabled}
              style={{ height: "42px" }}
              variant="outlined"
              error={formik.touched.id_payroll_group && Boolean(formik.errors.id_payroll_group)}
            >
              <option selected disabled>Seleccione grupo de nómina</option>
              {payRollGroups.map(
                ({ group_name, id }: EventualPayroll, index: number) => (
                  <>
                    {String(idPayRollGroup) === String(id) ? (
                      <option key={index} value={id} selected>
                        {group_name}
                      </option>
                    ) : (
                      <option key={index} value={id}>
                        {group_name}
                      </option>
                    )}
                  </>
                )
              )}
            </Select>
            <FormHelperText style={{color: "red"}}>
              {formik.touched.id_payroll_group && !formik.values.id_payroll_group && "Obligatorio"}
            </FormHelperText>
          </FormControl>
        </Grid>
        <span style={{ fontWeight: "bold" }}>Fondo de ahorro</span>
        {
          ( selectedPolitic && selectedPolitic.saving_fund_cap === 0 ) && (
            <p className="error-text">El grupo de nómina seleccionado no cuenta con una configuración de fondo de ahorro</p>
          )
        }
        <Grid direction="row" container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={isFondoAhorro}
                    value={isFondoAhorro}
                    onChange={() => setIsFondoAhorro(!isFondoAhorro)}
                    onBlur={formik.handleChange}
                    name="isFondoAhorro"
                    color="primary"
                    disabled={ selectedPolitic && selectedPolitic.saving_fund_cap === 0 }
                  />
                }
                label="Tiene Fondo de ahorro"
              />
          </Grid>
          <Grid item xs={4}>
            <TextField
                style={{
                  width: "100%",
                  display: "inline-block",
                }}
                label="Porcentaje Fondo Ahorro"
                id="outlined-basic"
                variant="outlined"
                type="text"
                size="small"
                value={formik.values.PorcentajeFondoAhorro}
                name="PorcentajeFondoAhorro"
                InputProps={{
                  startAdornment: (
                    selectedPolitic.saving_fund_type === 'Porcentual' ?   <InputAdornment position="start">%</InputAdornment>  : <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onKeyPress={(event: any) => {
                  if (!/^[0-9.]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  upDateTotalSavingFound(e.target.value);
                  // e = res
                  formik.handleChange(e);
                }}
                onBlur={() =>
                  handleSetFormat(formik.values.PorcentajeFondoAhorro, "PorcentajeFondoAhorro")
                }
                error={
                  formik.touched.PorcentajeFondoAhorro &&
                  Boolean(formik.errors.PorcentajeFondoAhorro)
                }
                helperText={
                  formik.touched.PorcentajeFondoAhorro &&
                  formik.errors.PorcentajeFondoAhorro
                }
                disabled={selectedPolitic && selectedPolitic.saving_fund_cap === 0 ? true : isFondoAhorro ? false : true}
              />
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>

        <span style={{ fontWeight: "bold" }}>Tipo de esquema</span>
        <Grid direction="row" container spacing={3}>
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Nomina"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Nomina"
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="100% Nómina"
                  checked={tipoEsquema === "Nomina" ? true : false}
                  onChange={cambioTipoEsquema}
                  name="nominesPayroll"
                  onBlur={formik.handleChange}
                />
                <FormControlLabel
                  value="Mixto"
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Mixto"
                  checked={tipoEsquema === "Mixto" ? true : false}
                  onChange={cambioTipoEsquema}
                  name="nominesPayroll"
                  onBlur={formik.handleChange}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {/* 100% nomina */}

        {tipoEsquema === "Nomina" ? (
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={formik.values.grossSalary}
                    name="grossSalary"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSetDS(e.target.value);
                    }}
                    onBlur={() =>
                      handleSetFormat(formik.values.grossSalary, "grossSalary")
                    }
                    error={
                      formik.touched.grossSalary &&
                      Boolean(formik.errors.grossSalary)
                    }
                    helperText={
                      formik.touched.grossSalary &&
                      Boolean(formik.errors.grossSalary) &&
                      "El campo es requerido y no puede ser menor a  5186.10"
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7}>
                <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Bruto</FormLabel>
              {
              /*
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Bruto"
                    name="typeSalary"
                    id="typeSalary"
                    onBlur={formik.handleChange}
                  >
                    <FormControlLabel
                      value="Bruto"
                      control={
                        <Radio size="small" style={{ color: "#fabb00" }} />
                      }
                      label="Bruto"
                      name="typeSalary"
                      onChange={(e) => handleTypeSalary(e)}
                      checked={typeSalary === "Bruto" ? true : false}
                      onBlur={formik.handleChange}
                      style={{ marginRight: "20px" }}
                    />
                    
                  </RadioGroup>
                </FormControl>
              */
              }
              </Grid>
            </Grid>
          </>
        ) : (
          // Esquema Mixto
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{
                display: "flex",
                marginTop: "2px",
              }}
            >
              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px" }}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSetDS(e.target.value);
                      handleSetNotRecordableSalary(
                        e.target.value,
                        formik.values.recordableSalary
                      );
                    }}
                    error={
                      formik.touched.grossSalary &&
                      Boolean(formik.errors.grossSalary)
                    }
                    helperText={
                      formik.touched.grossSalary &&
                      Boolean(formik.errors.grossSalary) &&
                      "El campo es requerido y no puede ser menor a  5186.10"
                    }
                    name="grossSalary"
                    onBlur={() =>
                      handleSetFormat(formik.values.grossSalary, "grossSalary")
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    defaultValue={formik.values.grossSalary ?? 0}
                    value={formik.values.grossSalary}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px" }}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Salario diario"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="DS"
                    onChange={formik.handleChange}
                    error={formik.touched.DS && Boolean(formik.errors.DS)}
                    helperText={formik.touched.DS && formik.errors.DS}
                    onBlur={() => handleSetFormat(formik.values.DS, "DS")}
                    value={formik.values.DS}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>

            <FormLabel style={{ fontWeight: "bold" }}>
              Ingreso gravable
            </FormLabel>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%" }}
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="recordableSalary"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleSetNotRecordableSalary(
                        formik.values.grossSalary,
                        e.target.value
                      );
                    }}
                    error={formik.touched.recordableSalary && Boolean(formik.errors.recordableSalary)}
                    helperText={
                      formik.errors.recordableSalary &&
                      "El campo es requerido y no puede ser menor a  5186.10"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    defaultValue={recordableSalary ?? 0}
                    value={formik.values.recordableSalary ?? 0}
                    onBlur={() =>
                      handleSetFormat(
                        formik.values.recordableSalary,
                        "recordableSalary"
                      )
                    }
                    onKeyPress={(event: any) => {
                      //check if the key is a dot

                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              {tipoEsquema === "Mixto" && (
                <>
                  <Grid item xs={4}>
                    <FormControl>
                      <TextField
                        style={{ width: "100%" }}
                        label="Salario diario gravable"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        size="small"
                        name="SDG"
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleSetMonthlyIncome(
                             e.target.value
                          );
                          // handleSetNotRecordableSalary(
                          //   formik.values.grossSalary,
                          //   formik.values.recordableSalary
                          // );
                        }}
                        error={formik.touched.SDG && Boolean(formik.errors.SDG)}
                        helperText={formik.touched.SDG && formik.errors.SDG}
                        onBlur={() => handleSetFormat(formik.values.SDG, "SDG")}
                        value={formik.values.SDG}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onKeyPress={(event: any) => {
                          if (!/^[0-9.]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%" }}
                    label="Salario diario integrado"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="ISD"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    error={formik.touched.ISD && Boolean(formik.errors.ISD)}
                    helperText={formik.touched.ISD && formik.errors.ISD}
                    onBlur={() => handleSetFormat(formik.values.ISD, "ISD")}
                    value={formik.values.ISD}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {tipoEsquema === "Mixto" && (
              <>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    marginTop: "2px",
                  }}
                >
                  <Grid item xs={4}>
                    <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Neto</FormLabel>

                    {
                      /*
                    <FormControl>
                      <RadioGroup
                        row
                        defaultValue="Neto"
                        name="grabable"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                            value="Neto"
                            control={
                              <Radio size="small" style={{ color: "#fabb00" }} />
                            }
                            label="Neto !!"
                            name="typeSalary"
                            onBlur={formik.handleChange}
                            checked={typeSalary === "Neto" ? true : false}
                            onChange={(e) => handleTypeSalary(e)}
                          />

                      </RadioGroup>
                    </FormControl>
                    */
                  }
                  </Grid>
                </Grid>
                <FormLabel style={{ fontWeight: "bold" }}>
                  Ingreso no gravable aproximado
                </FormLabel>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    marginTop: "2px",
                  }}
                >
                  <Grid item xs={4}>
                    <FormControl>
                      <TextField
                        style={{ width: "100%" }}
                        label="Total"
                        id="notRecordableSalary"
                        variant="outlined"
                        type="text"
                        size="small"
                        name="notRecordableSalary"
                        value={formik.values.notRecordableSalary}
                        onClick={formik.handleChange}
                        onBlur={formik.handleChange}
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onKeyPress={(event: any) => {
                          if (!/^[0-9.]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </FormControl>
                    {typeSalary === "Bruto" && (
                      <span className="span-file" style={{ marginTop: "2%" }}>
                        Este monto aún no tiene la deducción de impuestos
                        correspondiente
                      </span>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid
          spacing={3}
          style={{
            marginTop: "10px",
          }}
          direction="row"
          container
        >
          {tipoEsquema === "Nomina" && (
            <>
              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px" }}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Salario diario"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="DS"
                    onChange={formik.handleChange}
                    error={formik.touched.DS && Boolean(formik.errors.DS)}
                    helperText={formik.touched.DS && formik.errors.DS}
                    onBlur={() => handleSetFormat(formik.values.DS, "DS")}
                    value={formik.values.DS}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%" }}
                    label="Salario diario integrado"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="ISD"
                    onChange={formik.handleChange}
                    error={formik.touched.ISD && Boolean(formik.errors.ISD)}
                    helperText={formik.touched.ISD && formik.errors.ISD}
                    onBlur={() => handleSetFormat(formik.values.ISD, "ISD")}
                    value={formik.values.ISD}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: any) => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div className="novalidate__border">
        <Grid
          direction="row"
          container
          spacing={3}
          style={{
            // border: "1px solid red",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            disabled
            id="outlined-basic"
            variant="outlined"
            type="text"
            size="small"
            label="Periodicidad de pago"
            value={formik.values.periodicidadDePago}
            style={{ background: "#f1f2f5" }}
            name="periodicidadDePago"
            onBlur={formik.handleChange}
          />

          <Grid xs item>
            <Grid
              direction="row"
              container
              justify="flex-end"
              alignItems="center"
            >
              {CheckPermission(
                "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosContratacion.Guardar"
              ) && (
                <>
                  <label style={{ marginRight: "10px" }}>
                    Documentos para firma
                    <HtmlTooltip
                      title={
                        <Fragment>
                          Considera subir en un sólo pdf todos los documentos
                        </Fragment>
                      }
                    >
                      <img
                        src="/assets/icons/PreguntaAzul.png"
                        alt="Question"
                        height="15"
                      />
                    </HtmlTooltip>
                  </label>
                  <br />
                  {/*AVISO DE RETENCIÓN  */}
                  {archives?.map(
                    (archive: any, index: number) =>
                      archive?.name === "DocumentosParaFirma" && (
                        <div className="flex-container">
                          <div className="flex-child">
                            <span className="span-file">
                              <a
                                className="view-document"
                                target="_blank"
                                rel="noreferrer"
                                href={archive.URL}
                              >
                                <img
                                  style={{ height: "10px", marginTop: "5px" }}
                                  src={EyeIcon}
                                  alt=""
                                />
                                Ver documento
                              </a>
                            </span>
                          </div>

                          <div className="flex-child-2">
                            <FileHelper
                              name="DocumentosParaFirma"
                              accept=".pdf"
                              parametrofrom="collaborator"
                              idUsr={state.collaborator?.id}
                              className="image-file"
                              setArchives={setArchives}
                              archives={archives}
                              image={RemoveFile}
                              remove_file={true}
                            />
                          </div>
                        </div>
                      )
                  )}
                  {!state.collaborator?.CartaOferta && (
                    <div className="flex-container">
                      <div className="flex-child-2">
                        <FileHelper
                          name="DocumentosParaFirma"
                          accept=".pdf"
                          parametrofrom="collaborator"
                          idUsr={state.collaborator?.id}
                          className="image-file"
                          setArchives={setArchives}
                          archives={archives}
                          style={{ marginRight: "15px" }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SalaryHistory />
      </div>
      {CheckPermission(
        "Colaboradores.Colaboradores.Editar.InformacionAlta.DatosContratacion.Guardar"
      ) && (
        <Grid
          direction="row"
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button type="submit" className="buttonSave">
            <SaveIcon />
            &nbsp; Guardar
          </Button>
        </Grid>
      )}
    </Form>
  );
};

export default HiringData;
