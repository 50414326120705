/* eslint-disable array-callback-return */
import { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { useHistory, useParams } from 'react-router-dom';
import "../css/CardCampaigns.css"
import ObjectivesContext, { Types } from '../../../context/ObjectiveContext/ObjectiveContext';

interface CampaignFromLink {
  id: number
  nameCampaign: string
  startCampaign: string
  endCampaign: string
  step: string
  remandingDays: number
  totalCollaborators: number
  objetives: number
  campaignBond: string
  maxObjectives: number
  minObjectives: number
  createdObjectives:number
  objetivesAvance: {
    id: number
    objetiveEvidence: string
  }[]
  isLeader: boolean
}

const CollaboratorCampaignCard = ({ campaign }: { campaign: CampaignFromLink }) => {
  const {
    id,
    nameCampaign,
    startCampaign,
    endCampaign,
    step,
    remandingDays,
    maxObjectives,
    totalCollaborators,
    createdObjectives,
    campaignBond,  
    objetivesAvance,
    isLeader,
  } = campaign
  const { campaign: campaignIdFromUrl, user, token } = useParams<{ campaign: string; user: string; token: string }>();
  const dateInitFormat = moment(startCampaign).utc().format('DD/MM/YYYY');
  const dateEndFormat = moment(endCampaign).utc().format('DD/MM/YYYY');
  const [stepValue, setStepValue] = useState("")
  const [countHundred, setCountHundred] = useState(0);
  const [countZero, setCountZero] = useState(0);
  const history = useHistory();
  const { dispatch } = useContext(ObjectivesContext);

  const stepText = (step: string) => {
    switch (step) {
      case "definir":
        return "Definir"
      case "evaluar":
        return "Evaluar"
      case "actuar":
        return "Actuar"  
      default:
        return step
    }
  }

  useEffect(() => {
    if (stepValue === "") {
      setStepValue(step)
    }
  }, [step, stepValue]);

  useEffect(() => {
    if (objetivesAvance.length >0) {
      const arrayHundred:any=[]
      const arrayZero:any=[]
      objetivesAvance.map((objAv:any)=>{
         if(objAv.objetiveEvidence === 100){
          arrayHundred.push(objAv)
          }
          if(objAv.objetiveEvidence === 0){
            arrayZero.push(objAv)
          }          
      })
      setCountHundred(arrayHundred.length)  
      setCountZero(arrayZero.length)  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countHundred,countZero]);

  const goToObjectives = () => {
    dispatch({
      type: Types.SET_CAMPAIGN_INFO,
      payload: campaign
    })
    dispatch({
      type: Types.SET_ROL,
      payload: isLeader ? 'leader' : 'collaborator'
    })
    dispatch({
      type: Types.SET_CAMPAIGN_ID,
      payload: id
    })    
    if (isLeader) {
      history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}/campaignId/${id}/info`)
    }
  }

  return (
    <>
      <Card className='cardCampaign'>
        <CardContent className="contentCampaign">
          <div>
            <div style={{ textAlign: 'center' }}><span className='infoCampaignCenter'>{stepText(step)}</span></div>
            <div style={{ textAlign: 'center' }}><span className='infoBonoCenter'>{campaignBond === "true" ? "Con bono" : "Sin bono"}</span></div>
          </div>
          <div className='infoCard'>
            <div><span className='titleCampaign'>{nameCampaign}</span></div>
            <div><span className='periodoCampaign'>{`Periodo: ${dateInitFormat} - ${dateEndFormat}`} </span></div>
          </div>
          
          {step === "definir" && (
            <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${createdObjectives} de ${maxObjectives * totalCollaborators}`}</span></div>                
                <div><span className='msjDefinir'>Objetivos</span> </div>
              </div>
              <div className="v-line"></div>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${totalCollaborators}`}</span></div>
                <div><span className='msjDefinir'>Colaboradores</span> </div>
              </div>
            </div>)
          }
        {console.log(createdObjectives)}
        {step === "actuar" && (
             <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
             <div className='evaluarContain'>
               <div><span className='evaluarTxt'>{`${createdObjectives} `}</span>  </div>
               <div className='contMsjEvaluar'><span className='msjEvaluar'>Objetivos</span> </div>
               <div className='contMsjEvaluar'><span className='msjEvaluar'>Totales</span> </div>
             </div>
             <div className="ev-line"></div>
             <div className='evaluarContain'>
               <div><span className='evaluarTxt'>{`${ ((countHundred*100) / createdObjectives).toFixed(2) } %`}</span></div>
               <div><span className='msjEvaluar'>Progreso</span></div>
               <div><span className='msjEvaluar'>Acumulado</span> </div>
             </div>
             <div className="ev-line"></div>
             <div className='evaluarContain'>
               <div><span className='evaluarTxt'>{countZero}</span></div>
               <div><span className='msjEvaluar'>Objetivos </span> </div>
               <div><span className='msjEvaluar'>Sin avance</span> </div>
             </div>
           </div>       
            )
          }

          {step === "evaluar" && (
            <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`0`}</span></div>                
                <div><span className='msjDefinir'>Objetivos Calificados</span> </div>
              </div>
              <div className="v-line"></div>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${totalCollaborators}`}</span></div>
                <div><span className='msjDefinir'>Colaboradores</span> </div>
              </div>
            </div>)
          }
          
          {/* <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer', marginTop: 0 }}>
            <div className='definirContain'>
              <div><span className='remandingDays'>{remandingDays > 0 ? remandingDays : 0} días restantes</span></div>
              {/* {(step === 'crear' || step === 'definicion') && (
                <div><span className='obtevosTxt'>{`${objetives} de ${maxObjectives}`}</span></div>
              )}
              {(step !== 'crear' && step !== 'definicion') && (
                <div><span className='obtevosTxt'>{`${objetivesComplte} de ${objetives}`}</span></div>
              )} 
              <div><span className='obtevosTxt'>{`${objetivesComplte} de ${objetives}`}</span></div>
              <div><span className='msjDefinir'>Objetivos</span> </div>
            </div>
          </div> */}
        </CardContent>
      </Card>
    </>
  )
}

export default CollaboratorCampaignCard

