import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import {
  GET_ALL_ENTERPRISE,
  GET_ALL_SEDE,
  GET_ALL_PROJECT,
  GET_ALL_PUESTOS,
  
} from "../../../../Querys/querys";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { IProject } from "../../../../interfaces/Project";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";

const InputDataInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const [projects, setProjects] = useState<Array<IProject>>([]);
  const [puestos, setPuestos] = useState<Array<any>>([]);
  const [campus, setCampus]: any = useState(null);

  const { data: resultProject } = useQuery(GET_ALL_PROJECT);
  const allProject = resultProject?.GET_ALL_PROJECT;

  const { data: resultPuestos } = useQuery(GET_ALL_PUESTOS);
  const allPuestos = resultPuestos?.GET_ALL_PUESTOS;

  const { data: dataSedes } = useQuery(GET_ALL_SEDE, {
    fetchPolicy: 'no-cache'
  });
  const allCampus = dataSedes?.GET_ALL_SEDE;

  const [puesto, setPuesto] = useState('');
  const [project, setProject] = useState('');
  const [sede, setSede] = useState('');

  useEffect(() => {
    if (allProject) {
      for(const item of allProject){
        if(state && state.collaborator && Number(state.collaborator.idProject) === Number(item.id)){
          
          setProject(item.proyectNameAndClient)
        }
      }
      setProjects(allProject);
    }
  }, [ allProject, state]);

  useEffect(() => {
    if (allPuestos){
      for(const item of allPuestos){
        if(state && state.collaborator && Number(state.collaborator.puestoId) === Number(item.idPuesto)){
          setPuesto(item.NombrePuesto)
        }
      }
      setPuestos(allPuestos);
    } 
  }, [allPuestos, state]);

  useEffect(() => {
    if (allCampus){
      setSede(state.collaborator.campus)
      // for(const item of allCampus){
      //   if(state && state.collaborator && state.collaborator.campus === item.campus){
      //     setPuesto(state.collaborator.campus)
      //   }
      // }
      setCampus(allCampus);
    } 
  }, [allCampus, state]);




  return (
    <>
      <div
        className={
          state.sections[1] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <Grid
          direction="row"
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid xs item>
            <span className="Fecha-de-nacimiento">Fecha de ingreso</span>
          </Grid>
          <Grid xs item container justify="flex-end" alignItems="center">
            <TextField
              type="date"
              defaultValue={
                state.collaborator
                  ? moment.utc(state.collaborator.dateOfAdmission).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
              autoFocus={true}
              name="dateOfAdmission"
              variant="outlined"
              size="small"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>

        <Box mb={2} mt={2}>
          <FormControl variant="outlined" fullWidth={true} size="small">
            <TextField
              type="text"
              name="officePlace"
              label="Lugar de trabajo"
              defaultValue={state.collaborator?.officePlace}
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              style={{ opacity:0.5 }}
            />
          </FormControl>
        </Box>

        <Box mb={2} mt={2}>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <TextField
                type="text"
                name="campus"
                label="Selecciona sede"
                value={sede}
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{ opacity:0.5 }}
              />
            </FormControl>
          </Grid>
        </Grid> 
        </Box>

        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <TextField
                type="text"
                name="idProject"
                label="Selecciona área o proyecto"
                value={project}
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{ opacity:0.5 }}
              />
            </FormControl>
          </Grid>
          <Grid xs item>
            <FormControl variant="outlined" fullWidth={true} size="small">
              <TextField
                type="text"
                name="puestoId"
                label="Puesto"
                value={puesto}
                variant="outlined"
                size="small"
                fullWidth={true}
                InputProps={{
                  readOnly: true,
                }}
                style={{ opacity:0.5 }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default InputDataInactivos;
