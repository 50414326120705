import { gql } from "@apollo/client";

export const GET_ALL_COMPANY = gql`
  query {
    GET_ALL_COMPANY {
      id
      Name
      fundationDate
      logo
      website
      status
      SistemaActivo
    }
  }
`;

export const GET_COMPANY = gql`
  query GET_COMPANY($getCompanyId: ID!) {
    GET_COMPANY(id: $getCompanyId) {
      Name
      fundationDate
      logo
      website
      status
      archive {
        URL
        id
      }
    }
  }
`;
export const GET_COMPANY_VALUES = gql`
  query GET_COMPANY_VALUES($companyId: ID!) {
    GET_COMPANY_VALUES(id: $companyId) {
      totalDispersar
      CollaboratorCost
    }
  }
`;
export const GET_ALL_SURVEY = gql`
query GET_ALL_SURVEY {
  GET_ALL_SURVEY {
    id
    displayText
    dateAdd
    project
    campus
    status
    enviados
    respuestas
    calificacion
  }
}
`;
export const CREATE_SURVEY = gql`
mutation CREATE_SURVEY($input:survey_Input) {
  CREATE_SURVEY(input: $input)
}
`;
export const UPDATE_SURVEY_STATUS = gql`
mutation UPDATE_SURVEY_STATUS($id:ID!) {
  UPDATE_SURVEY_STATUS(id: $id)
}
`;
export const GET_RESULTADO_SURVEY = gql`
  query GET_RESULTADO_SURVEY($id:ID!) {
    GET_RESULTADO_SURVEY(id:$id) {
      Pregunta
      Promedio
      Cinco
      Cuatro
      Tres
      Dos
      Uno
    }
  }
`;
export const GET_COMENTARIOS_SURVEY = gql`
  query GET_COMENTARIOS_SURVEY($id:ID!) {
    GET_COMENTARIOS_SURVEY(id:$id) {
      Comentarios
      DiasPasados
      Colaborador
      Pregunta
    }
  }
`;
export const GET_USER_TEAM = gql`
  query GET_USER_TEAM ($id:ID!) {
    GET_USER_TEAM (id:$id){
      email
      bussinesName
      cellphone
    }
  }
`;

export const UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_DEMO = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_DEMO($id: ID!, $tokenTimbre: String!, $iTimbre: Int!) {
  UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_DEMO(id: $id, tokenTimbre: $tokenTimbre, iTimbre: $iTimbre){
    xml
    Folio: id
    Colaborador: colaborator
    xml
    UUID
  }
}
`;

export const UPDATE_PAYROLL_COLLABORATOR_INFO = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_INFO($input: [timbrado_info]!) {
  UPDATE_PAYROLL_COLLABORATOR_INFO(input: $input)
}
`;

export const UPDATE_SETTLEMENTCOLLABORATOR_TOKENTIMBRE_DEMO = gql`
mutation UPDATE_SETTLEMENTCOLLABORATOR_TOKENTIMBRE_DEMO($id: ID!, $tokenTimbre: String!, $iTimbre: Int!) {
  UPDATE_SETTLEMENTCOLLABORATOR_TOKENTIMBRE_DEMO(id: $id, tokenTimbre: $tokenTimbre, iTimbre: $iTimbre){
    xml
    Folio: id
    Colaborador: colaborator
    xml
    UUID
  }
}
`;


export const UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_DEMO = gql`
mutation UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_DEMO($id: ID!, $tokenTimbre: String!, $iTimbre: Int!) {
  UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_DEMO(id: $id, tokenTimbre: $tokenTimbre, iTimbre: $iTimbre){
    xml
    Folio: id
    Colaborador: colaborator
    xml
    UUID
  }
}
`;



export const GET_ALL_ENTERPRISE = gql`
  query {
    GET_ALL_ENTERPRISE {
      id
      name
      taxRegime
      state
      address
      zipCode
      bankaccount
      status
      Outsourcer
      registro_patronal {
        id
        registroPatronal
      }
      users {
        id
        name
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DELETE_COMPANY($deleteCompanyId: ID!) {
    DELETE_COMPANY(id: $deleteCompanyId)
  }
`;

export const DELETE_ENTERPRISE = gql`
  mutation DELETE_ENTERPRISE($deleteEnterpriseId: ID!) {
    DELETE_ENTERPRISE(id: $deleteEnterpriseId)
  }
`;

export const GET_ENTERPRISE = gql`
  query GET_ENTERPRISE($getEnterpriseId: ID!) {
    GET_ENTERPRISE(id: $getEnterpriseId) {
      id
      name
      logo
      showlogo      
      taxRegime
      propertyRegime
      surcharge
      state
      address
      zipCode
      bankaccount
      IMSSSubdelegationKey
      fileCER
      extrahours
      automaticCalculationsVariables
      useSTPaspaymentmethod
      STPaccount
      stpCLABE
      IMSSminimumwage
      operationsIMSSSender
      CertificateOfUserIMSS
      CertificatePaswordIMSS
      IMSSCertificate
      FIELCertificate
      FIELPrivateKey
      userAdd
      dateAdd
      userMod
      dateMod
      status
      RazonSocial
      TimbreCertificate
      Timbrekey
      municipio
      CertificatePaswordTimbre
      NoCertificado
      country
      userIMSS
      saldo
      isn     
      Outsourcer
      legalRepresentative
      notarialTestimony
      notarialTestimonyVolume
      notarialTestimonyDate
      notaryPublicNumber
      notaryPublic
      judicialDistrict
      addressCourt

    }
  }
`;

export const CREATE_ENTERPRISE = gql`
  mutation CREATE_ENTERPRISE($input: enterprise_Input) {
    CREATE_ENTERPRISE(input: $input) {
      id
      name
      logo
      taxRegime
      RazonSocial
      propertyRegime
      surcharge
      state
      address
      zipCode
      bankaccount
      IMSSSubdelegationKey
      fileCER
      extrahours
      automaticCalculationsVariables
      useSTPaspaymentmethod
      STPaccount
      stpCLABE
      IMSSminimumwage
      operationsIMSSSender
      CertificateOfUserIMSS
      CertificatePaswordIMSS
      IMSSCertificate
      FIELCertificate
      FIELPrivateKey
      NoCertificado
      Outsourcer
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UPDATE_COMPANY($updateCompanyId: ID!, $input: company_Input) {
    UPDATE_COMPANY(id: $updateCompanyId, input: $input) {
      id
      Name
      fundationDate
      logo
      website
    }
  }
`;

export const UPDATE_ENTERPRISE = gql`
  mutation UPDATE_ENTERPRISE(
    $updateEnterpriseId: ID!
    $input: enterprise_Input
  ) {
    UPDATE_ENTERPRISE(id: $updateEnterpriseId, input: $input) {
      id
      name
      logo
      showlogo
      industry
      taxRegime
      propertyRegime
      surcharge
      state
      address
      zipCode
      bankaccount
      IMSSSubdelegationKey
      fileCER
      extrahours
      automaticCalculationsVariables
      useSTPaspaymentmethod
      STPaccount
      stpCLABE
      IMSSminimumwage
      operationsIMSSSender
      CertificateOfUserIMSS
      CertificatePaswordIMSS
      IMSSCertificate
      FIELCertificate
      FIELPrivateKey
      status
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation CREATE_TRANSACTION($input: transaction_Input) {
    CREATE_TRANSACTION(input: $input) {
      enterpriseId
      companyId
      fullname
      clabe
      bank
      transactionAmount
      User
      transactionTotal
      comission
    }
  }
`;

export const UPDATE_TRANSACTION = gql`
  mutation UPDATE_TRANSACTION($transactionId: ID!, $input: transaction_Input) {
    UPDATE_TRANSACTION(id: $transactionId, input: $input) {
      enterpriseId
      companyId
      fullname
      clabe
      bank
      transactionAmount
      User
      transactionTotal
      comission
    }
  }
`;

export const DELETE_TRANSACTION = gql`
  mutation DELETE_TRANSACTION($transactionId: ID!, $input: transaction_Input) {
    DELETE_TRANSACTION(id: $transactionId, input: $input) {
      enterpriseId
      companyId
      fullname
      clabe
      bank
      transactionAmount
      User
      transactionTotal
      comission
    }
  }
`;

export const GET_ALL_TRANSACTION = gql`
  query {
    GET_ALL_TRANSACTION {
      id
      fullname
      clabe
      bank
      transactionAmount
      comission
      transactionTotal
      companyId
    }
  }
`;

export const GET_ALL_TRANSACTION_BY_ENTERPRISE = gql`
  query GET_ALL_TRANSACTION_BY_ENTERPRISE($enterpriseId: ID!){
    GET_ALL_TRANSACTION_BY_ENTERPRISE(enterpriseId: $companyId) {
      id
      fullname
      clabe
      bank
      transactionAmount
      comission
      transactionTotal
      companyId
    }
  }
`;

export const GET_ALL_OUTSOURCERS = gql`
  query {
    GET_ALL_OUTSOURCERS {
      id
      nombre
      razonSocial
      logo
      rfc
      numeroDeRepse
      sitioWeb
      direccionFiscal
      direccionEmpresa
      nombreDeContacto
      correoContacto
      telefonoContacto
      comentariosAdicionales
    }
  }
`;

export const GET_OUTSOURCER = gql`
  query GET_OUTSOURCERS($getOutsourcersId: ID!) {
    GET_OUTSOURCERS(id: $getOutsourcersId) {
      id
      nombre
      logo
      razonSocial
      rfc
      numeroDeRepse
      sitioWeb
      direccionEmpresa
      direccionFiscal
      nombreDeContacto
      correoContacto
      telefonoContacto
      comentariosAdicionales
      userAdd
      dateAdd
      userMod
      dateMod
      status
      initDate
      fileRepse
    }
  }
`;

export const CREATE_OUTSOURCERS = gql`
  mutation CREATE_OUTSOURCERS($input: outsoucers_Input) {
    CREATE_OUTSOURCERS(input: $input) {
      id
      nombre
      logo
      razonSocial
      rfc
      numeroDeRepse
      sitioWeb
      direccionFiscal
      direccionEmpresa
      nombreDeContacto
      correoContacto
      telefonoContacto
      comentariosAdicionales
    }
  }
`;

export const UPDATE_OUTSOURCER = gql`
  mutation UPDATE_OUTSOURCERS(
    $updateOutsourcersId: ID!
    $input: outsoucers_Input
  ) {
    UPDATE_OUTSOURCERS(id: $updateOutsourcersId, input: $input) {
      id
      nombre
      logo
      razonSocial
      rfc
      numeroDeRepse
      sitioWeb
      direccionFiscal
      direccionEmpresa
      nombreDeContacto
      correoContacto
      telefonoContacto
      comentariosAdicionales
    }
  }
`;
export const UPDATE_OUTSOURCER_STATE = gql `
  mutation UPDATE_OUTSOURCER_STATE($input: outsoucers_Input_state){
    UPDATE_OUTSOURCER_STATE(input: $input){
      id
      statusProgressO
    }
  }
  `;

export const DELETE_OUTSOURCERS = gql`
  mutation DELETE_OUTSOURCERS($deleteOutsourcersId: ID!) {
    DELETE_OUTSOURCERS(id: $deleteOutsourcersId)
  }
`;

export const GET_ALL_PAYROLL_GROUP = gql`
  query GET_ALL_PAYROLL_GROUP {
    GET_ALL_PAYROLL_GROUP {
      id
      group_name
      company_name
      payroll_period
      statusPayroll_group
      pantry_value
      saving_fund
    }
  }
`;

export const CREATE_PAYROLL_GROUP = gql`
  mutation CREATE_PAYROLL_GROUP($input: payroll_group_Input) {
    CREATE_PAYROLL_GROUP(input: $input)
  }
`;

export const UPDATE_PAYROLL_GROUP = gql`
  mutation UPDATE_PAYROLL_GROUP(
    $updatePayrollGroupId: ID!
    $input: payroll_group_Input
  ) {
    UPDATE_PAYROLL_GROUP(id: $updatePayrollGroupId, input: $input)
  }
`;

export const DELETE_PAYROLL_GROUP = gql`
  mutation DELETE_PAYROLL_GROUP($deletePayrollGroupId: ID!) {
    DELETE_PAYROLL_GROUP(id: $deletePayrollGroupId)
  }
`;

export const GET_ALL_POLITIC = gql`
  query GET_ALL_POLITIC {
    GET_ALL_POLITIC {
      id
      policy_name
      economic_days
      anniversary_vacation_premium
      pantry_value_type
      pantry_value_cap
      pantry_value
      saving_fund_type
      saving_fund_cap
      saving_fund
      restaurant_value
      restaurant_value_type
      restaurant_value_cap
      absence_discount
      disability_discount
      voucher_cost
      pantry_type
      saving_type
      restaurant_type
      idPayrollGroup
      group_name
      vacation_bonus_type
      vacation_bonus_cap
      vacation_bonus
      DiasAguinaldo
    }
  }
`;

export const CREATE_POLITIC = gql`
  mutation CREATE_POLITIC($input: politic_Input) {
    CREATE_POLITIC(input: $input) {
      policy_name
      economic_days
      anniversary_vacation_premium
      pantry_value_type
      pantry_value_cap
      pantry_value
      saving_fund_type
      saving_fund_cap
      saving_fund
      restaurant_value
      restaurant_value_type
      restaurant_value_cap
      absence_discount
      disability_discount
      voucher_cost
      pantry_type
      saving_type
      restaurant_type
      idPayrollGroup
      DiasAguinaldo
    }
  }
`;

export const UPDATE_POLITIC = gql`
  mutation UPDATE_POLITIC($updatePoliticId: ID!, $input: politic_Input) {
    UPDATE_POLITIC(id: $updatePoliticId, input: $input) {
      policy_name
      economic_days
      anniversary_vacation_premium
      pantry_value_type
      pantry_value_cap
      pantry_value
      saving_fund_type
      saving_fund_cap
      saving_fund
      restaurant_value
      restaurant_value_type
      restaurant_value_cap
      absence_discount
      disability_discount
      voucher_cost
      pantry_type
      saving_type
      restaurant_type
      idPayrollGroup
    }
  }
`;

export const DELETE_POLITIC = gql`
  mutation DELETE_POLITIC($deletePoliticId: ID!) {
    DELETE_POLITIC(id: $deletePoliticId)
  }
`;

export const GET_PERIODICISRSEMANAL = gql`
  query {
    GET_PERIODICISRSEMANAL {
      id
      TypeISR
      PeriodicISRcol
      LowerLimit
      UpperLimit
      FixedQuota
      Excess
    }
  }
`;

export const GET_PERIODICISRCATORCENAL = gql`
  query {
    GET_PERIODICISRCATORCENAL {
      id
      TypeISR
      PeriodicISRcol
      LowerLimit
      UpperLimit
      FixedQuota
      Excess
    }
  }
`;

export const GET_PERIODICISRQUINCENAL = gql`
  query {
    GET_PERIODICISRQUINCENAL {
      id
      TypeISR
      PeriodicISRcol
      LowerLimit
      UpperLimit
      FixedQuota
      Excess
    }
  }
`;

export const GET_PERIODICISRMENSUAL = gql`
  query {
    GET_PERIODICISRMENSUAL {
      id
      TypeISR
      PeriodicISRcol
      LowerLimit
      UpperLimit
      FixedQuota
      Excess
    }
  }
`;

export const GET_ALL_PAYROLL = gql`
  query {
    GET_ALL_PAYROLL {
      id
      group_name
      frecuency_payment
      init_date
      end_date
      employees
      perception
      deduction
      total
      status
      payrollCompleted
      payroll_type
      statusProgress
      Company
      uuid
    }
  }
`;

export const CREATE_PAYROLL = gql`
  mutation CREATE_PAYROLL($input: payroll_Input) {
    CREATE_PAYROLL(input: $input){
      id
      mensaje
      respuesta
      uuid
  }
  }
`;

export const CREATE_MONTHLYISR = gql`
  mutation CREATE_MONTHLYISR($input: monthlyISR_Input) {
    CREATE_MONTHLYISR(input: $input){
      id
      mensaje
      respuesta
      uuid
  }
  }
`;

export const GET_ALL_PAYROLL_COLLABORATOR = gql`
  query {
    GET_ALL_PAYROLL_COLLABORATOR {
      id
      idPayroll
      colaborator
      netIncome
      DS
      GDS
      periodDays
      workingDays
      grossSalary
      Subsidy
      ISR
      IMSS
      SavingsFund
      INFONAVIT
      incident
      netIncomeTaxable
      TotalIncomeNotTaxable
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR = gql`
  query GET_PAYROLLCOLLABORATOR($getPayrollcollaboratorId: ID!) {
    GET_PAYROLLCOLLABORATOR(id: $getPayrollcollaboratorId) {
      id
      idPayroll
      netIncome
      colaborator
      DS
      GDS
      periodDays
      workingDays
      grossSalary
      Subsidy
      ISR
      IMSS
      SavingsFund
      INFONAVIT
      incident
      netIncomeTaxable
      TotalIncomeNotTaxable
      id_Collaborator
      dispersionStatus
      UUID
      totalIncome
      area
      Incidencias
      ISD
      salaries{
        isFrom
        netIncome
        totalIncome
        DS
        GDS
        periodDays 
        workingDays
        grossSalary
        Subsidy
        ISR
        IMSS
        SavingsFund
        INFONAVIT
        netIncomeTaxable 
        TotalIncomeNotTaxable
      }
    }
  }
`;

export const GET_MONTHLYISRCOLLABORATOR = gql`
  query GET_MONTHLYISRCOLLABORATOR($getPayrollcollaboratorId: ID!) {
    GET_MONTHLYISRCOLLABORATOR(id: $getPayrollcollaboratorId) {
      id
      idMonthlyISR
      colaborator
      id_Collaborator
      baseGravable
      LimiteInferior
      Excedente
      Tasa
      ISRMarginal
      CuotaFija
      ISRCausado
      SUPECausado
      ISRAcreditado
      SUPEAcreditado
      ISR
      SUPE
      TotalDiferencia
      dateDelete
      userDelete
      status
      finishDate
      finishUser
      area
    }
  }
`;

export const GET_ALL_PAYROLLCOLLABORATOR = gql`
  query {
    GET_ALL_PAYROLLCOLLABORATOR {
      id
      idPayroll
      netIncome
      colaborator
      DS
      GDS
      periodDays
      workingDays
      grossSalary
      Subsidy
      ISR
      IMSS
      SavingsFund
      INFONAVIT
      incident
      netIncomeTaxable
      TotalIncomeNotTaxable
    }
  }
`;

export const GET_PERIODICSUBSIDYSEMANAL = gql`
  query {
    GET_PERIODICSUBSIDYSEMANAL {
      id
      TypeSubsidy
      LowerIncome
      UpperIncome
      SubsidyAmount
    }
  }
`;

export const GET_PERIODICSUBSIDYCATORCENAL = gql`
  query {
    GET_PERIODICSUBSIDYCATORCENAL {
      id
      TypeSubsidy
      LowerIncome
      UpperIncome
      SubsidyAmount
    }
  }
`;

export const GET_PERIODICSUBSIDYQUINCENAL = gql`
  query {
    GET_PERIODICSUBSIDYQUINCENAL {
      id
      TypeSubsidy
      LowerIncome
      UpperIncome
      SubsidyAmount
    }
  }
`;

export const GET_PERIODICSUBSIDYMENSUAL = gql`
  query {
    GET_PERIODICSUBSIDYMENSUAL {
      id
      TypeSubsidy
      LowerIncome
      UpperIncome
      SubsidyAmount
    }
  }
`;

export const GET_ALL_PERCEPTIONS = gql`
  query GET_ALL_PERCEPTIONS {
    GET_ALL_PERCEPTIONS {
      id
      ConceptName
      SATKey
      Concept_Type
      AccuntingAccount
      PayType
      ISRTax
      ISNTax
      SocialSecurity
      IntegratesIMSS
      TaxBoth
      Calendar
      time
      gravaISR
      statusPerceptions
      isMoney
      muestraCalendario
      noDelete
    }
  }
`;

export const GET_ALL_PERCEPTIONS_INCIDENCIAS = gql`
  query GET_ALL_PERCEPTIONS_INCIDENCIAS {
    GET_ALL_PERCEPTIONS_INCIDENCIAS {
      id
      ConceptName
      SATKey
      Concept_Type
      AccuntingAccount
      PayType
      ISRTax
      ISNTax
      SocialSecurity
      IntegratesIMSS
      TaxBoth
      Calendar
      time
      gravaISR
      statusPerceptions
      isMoney
      muestraCalendario
      noDelete
    }
  }
`;

export const CREATE_PERCEPTIONS = gql`
  mutation CREATE_PERCEPTIONS($input: perceptions_Input) {
    CREATE_PERCEPTIONS(input: $input)
  }
`;

export const UPDATE_PERCEPTIONS = gql`
  mutation UPDATE_PERCEPTIONS(
    $updatePerceptionsId: ID!
    $input: perceptions_Update
  ) {
    UPDATE_PERCEPTIONS(id: $updatePerceptionsId, input: $input)
  }
`;

export const DELETE_PERCEPTIONS = gql`
  mutation DELETE_PERCEPTIONS($deletePerceptionsId: ID!) {
    DELETE_PERCEPTIONS(id: $deletePerceptionsId)
  }
`;

//Deductions

export const GET_DEDUCTIONS = gql`
  query {
    GET_DEDUCTIONS {
      id
      concept_type
      SATKey
      Calendar
    }
  }
`;

export const GET_ALL_DEDUCTIONS = gql`
  query GET_ALL_DEDUCTIONS {
    GET_ALL_DEDUCTIONS {
      id
      concept_type
      SATKey
      ISRTax
      TaxBoth
      Calendar
      time
      statusDeduction
      isMoney
      muestraCalendario
    }
  }
`;

export const GET_ALL_DEDUCTIONS_INCIDENCIAS = gql`
  query GET_ALL_DEDUCTIONS_INCIDENCIAS {
    GET_ALL_DEDUCTIONS_INCIDENCIAS {
      id
      concept_type
      SATKey
      ISRTax
      TaxBoth
      Calendar
      time
      statusDeduction
      isMoney
      muestraCalendario
    }
  }
`;


export const CREATE_DEDUCTIONS = gql`
  mutation CREATE_DEDUCTIONS($input: deductions_Input) {
    CREATE_DEDUCTIONS(input: $input)
  }
`;

export const UPDATE_DEDUCTIONS = gql`
  mutation UPDATE_DEDUCTIONS(
    $updateDeductionsId: ID!
    $input: deductions_Input
  ) {
    UPDATE_DEDUCTIONS(id: $updateDeductionsId, input: $input)
  }
`;

export const DELETE_DEDUCTIONS = gql`
  mutation DELETE_DEDUCTIONS($deleteDeductionId: ID!) {
    DELETE_DEDUCTIONS(id: $deleteDeductionId)
  }
`;

export const CREATE_ARCHIVE = gql`
  mutation CREATE_ARCHIVE($input: archive_Input) {
    CREATE_ARCHIVE(input: $input) {
      usersId
      URL
      fromto
      name
    }
  }
`;

export const DELETE_ARCHIVE = gql`
  mutation DELETE_ARCHIVE($idArchive: ID!) {
    DELETE_ARCHIVE(id: $idArchive)
  }
`;

export const GET_ALL_ARCHIVE = gql`
  query {
    GET_ALL_ARCHIVE {
      id
      URL
      idUsuario
      fromto
    }
  }
`;

export const GET_ARCHIVE = gql`
  query GET_ARCHIVE($idUsuario: String!, $fromto: String!) {
    GET_ARCHIVE(idUsuario: $idUsuario, fromto: $fromto) {
      id
      URL
    }
  }
`;

export const GET_ARCHIVE_NAME = gql`
  query GET_ARCHIVE_NAME(
    $idUsuario: String!
    $fromto: String!
    $name: String!
  ) {
    GET_ARCHIVE_NAME(idUsuario: $idUsuario, fromto: $fromto, name: $name) {
      id
      URL
      fromto
      name
    }
  }
`;

export const CREATE_PAYROLL_INCIDENT = gql`
  mutation CREATE_PAYROLL_INCIDENT($input: payroll_Incident_Input) {
    CREATE_PAYROLL_INCIDENT(input: $input) {
      id
      idPayroll
      idCollaborator
      Incident_type
      InitDate
      EndDate
      Total
      Taxable
      NotTaxable
      Mixed
      idConcept
      Dias
      Horas
      Comentarios
      TypeConcept
      Periodico
      FechaPeriodica
    }
  }
`;

export const GET_ALL_USERS_NUEVOINGRESO = gql`
query GET_ALL_USERS_NUEVOINGRESO(
    $pageNumber: Int
    $rowsPerPage: Int
    $searchTerm: String
    $order: String
    $orderBy: String
) {
  GET_ALL_USERS_NUEVOINGRESO(
    pageNumber: $pageNumber
    rowsPerPage: $rowsPerPage
    searchTerm: $searchTerm
    order: $order
    orderBy: $orderBy
  ) {
    id
    bussinesName
    email
    campus
    project
    done
    client
    dateOfAdmission
    Puesto
    netSalary
    grossSalary
    nominesPayroll
    totalRows
  }
}
`;

export const GET_ALL_USERS_DECLINADOS = gql`
  query GET_ALL_USERS_DECLINADOS {
    GET_ALL_USERS_DECLINADOS {
      bussinesName
      comment
      email
    }
  }
`;

export const GET_ALL_USERS_INACTIVOS = gql`
  query GET_ALL_USERS_INACTIVOS(
    $pageNumber: Int
    $rowsPerPage: Int
    $searchTerm: String
    $order: String
    $orderBy: String
  ) {
    GET_ALL_USERS_INACTIVOS(
      pageNumber: $pageNumber
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      order: $order
      orderBy: $orderBy
    ) {
      id
      email
      bussinesName
      dropType
      comment
      project
      dateOfAdmission
      FechaIngreso
      FechaBaja
      totalRows
    }
  }
`;


export const GET_ALL_USERS_COLLABORATOR = gql`
  query GET_ALL_USERS_COLLABORATOR(
    $pageNumber: Int
    $rowsPerPage: Int
    $searchTerm: String
    $order: String
    $orderBy: String
  ) {
    GET_ALL_USERS_COLLABORATOR(
      pageNumber: $pageNumber
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      order: $order
      orderBy: $orderBy
    ) {
      id
      _id: id
      name
      firstName
      lastName
      fullName
      dateOfBirth
      email
      dateOfAdmission
      userType
      project
      campus
      client
      password
      active
      token
      linkedAccount
      cellphone
      area
      tittle
      officePlace
      bussinesName
      contractName
      netSalary
      grossSalary
      recordableSalary
      DS
      ISD
      nominesPayroll
      paymentPeridicity
      journey
      comment
      userStatus
      expiredAlta
      expiredPersonales
      currentToken
      host
      address
      municipality
      ZC
      state
      country
      placeOfBirth
      nacionality
      gender
      civilStatus
      children
      educationalLevel
      ClaveElectoralPasaporte
      IMSS
      CURP
      workPermission
      creditoInfonavit
      benefitiary
      emergencyContact
      diseases
      bank
      accountNumber
      clabeNum
      RFC
      id_payroll_group
      SDG
      id_User
      id_Bank
      done
      collaborator
      drop
      baja
      Puesto
      Company
      new
      suburb
      benefitiaryCountry
      benefitiaryState
      benefitiaryMunicipality
      benefitiaryCURP
      ParentescoB
      benefitiaryDateOfBirth
      benefitiaryZC
      benefitiaryAddress
      benefitiarySuburb
      ParentescoCE
      cellphoneCE
      phoneCE
      suburbCE
      addressCE
      Alergias
      PadEnfer
      Cirugias
      TraMeRe
      dropType
      dropDate
      terminationDate
      dateContractDate
      FechaIngreso
      FechaBaja
      ZCSAT
      ImagenUsuario
      totalRows
    }
  }
`;

export const GET_USERS_BY_ID = gql`
  query GET_USERS($getUsersId: String!) {
    GET_USERS(id: $getUsersId) {
      id
      name
      idWorkingHours
      firstName
      lastName
      dateOfBirth
      email
      dateOfAdmission
      userType
      project
      campus
      client
      password
      active
      token
      linkedAccount
      cellphone
      area
      tittle
      officePlace
      bussinesName
      contractName
      netSalary
      grossSalary
      DS
      ISD
      nominesPayroll
      paymentPeridicity
      journey
      comment
      userStatus
      expiredAlta
      expiredPersonales
      currentToken
      host
      address
      municipality
      ZC
      state
      country
      placeOfBirth
      nacionality
      gender
      civilStatus
      children
      educationalLevel
      ClaveElectoralPasaporte
      IMSS
      CURP
      workPermission
      creditoInfonavit
      benefitiary
      emergencyContact
      diseases
      bank
      accountNumber
      clabeNum
      status
      RFC
      id_payroll_group
      SDG
      id_User
      id_Bank
      done
      collaborator
      drop
      baja
      Puesto
      Company
      suburb
      benefitiaryCountry
      benefitiaryState
      benefitiaryMunicipality
      benefitiaryCURP
      ParentescoB
      benefitiaryDateOfBirth
      benefitiaryZC
      benefitiaryAddress
      benefitiarySuburb
      ParentescoCE
      cellphoneCE
      phoneCE
      suburbCE
      addressCE
      Alergias
      PadEnfer
      Cirugias
      TraMeRe
      dropDate
      dropType
      archive {
        URL
        id
        idUsuario
        fromto
        name
        idTypeArchive
      }
      dateContractDate
      typeContract
      totalSalary
      recordableSalary
      notRecordableSalary
      typeSalary
      EmployerRegister
      idEnterprise
      id_payroll_group
      ActaNacimiento_PDF
      ComprobanteEstudios_PDF
      ComprobanteDomicilio_PDF
      IdentificacionOficial_PDF
      RFC_PDF
      CURP_PDF
      ComprobanteNSS_PDF
      Foto_IMG
      AvisoRetencion_PDF
      CartaGMM_PDF
      CartaOfertaFirmada
      AGREEMENT_PDF
      CartaOferta
      isAvisoRetencion
      isCartaGMM
      CuentaBancaria_PDF
      register_process {
        tab
        section_1
        section_2
        section_3
        percent
        completed
      }
      own_register
      accept_info
      puestoId
      idJourney
      unionized
      calendarId
      INFONAVIT
      TypeInfonavit
      VSMINFONAVIT
      Periodicity_INFONAVIT
      typeWorker
      typeSalaryN
      weekPartTimeId
      weekPartTime
      ZCSAT
      idProject
      isFondoAhorro
      PorcentajeFondoAhorro
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_DISPERSION = gql`
  query GET_PAYROLLCOLLABORATOR_DISPERSION(
    $getPayrollcollaboratorDispersionId: ID!
  ) {
    GET_PAYROLLCOLLABORATOR_DISPERSION(
      id: $getPayrollcollaboratorDispersionId
    ) {
      id
      colaborator
      netIncomeTaxable
      TotalIncomeNotTaxable
      dispersionStatus
      RFC
      accountNumber
      bank
      clabeNum
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_TIMBRADO = gql`
  query GET_PAYROLLCOLLABORATOR_TIMBRADO(
    $getPayrollcollaboratorTimbradoId: ID!
  ) {
    GET_PAYROLLCOLLABORATOR_TIMBRADO(id: $getPayrollcollaboratorTimbradoId) {
      id
      colaborator
      perception
      deduction
      netIncomeTaxable
      UUID
      tokenTimbre
      xml
      RFC
      status
      dispersionStatus
      LinkPDF
      AcuseCancelacion
      PDF_back
      Pendientes
    }
  }
`;



export const GET_PAYROLLCOLLABORATOR_TIMBRADO_EXCELL = gql`
  query GET_PAYROLLCOLLABORATOR_TIMBRADO_EXCELL(
    $getPayrollcollaboratorTimbradoExcellId: ID!
  ) {
    GET_PAYROLLCOLLABORATOR_TIMBRADO_EXCELL(id: $getPayrollcollaboratorTimbradoExcellId) {
      Colaborador
      Percepcion
      Deduccion
      NetoGravable
      UUID
    }
  }
`;




export const GET_ALL_PROJECT = gql`
  query {
    GET_ALL_PROJECT {
      id
      proyectName
      client
      sede
      idClient
      idSede
      users {
        id
        name
      }
      puestos{
        id
      }
      proyectNameAndClient
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CREATE_PROJECT($input: project_Input) {
    CREATE_PROJECT(input: $input) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UPDATE_PROJECT($updateProjectId: ID!, $input: project_Input) {
    UPDATE_PROJECT(id: $updateProjectId, input: $input) {
      id
      proyectName
      client
      sede
      idClient
      idSede
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DELETE_PROJECT($deleteProjectId: ID!) {
    DELETE_PROJECT(id: $deleteProjectId)
  }
`;

export const GET_ALL_SEDE = gql`
  query {
    GET_ALL_SEDE {
      id
      sedeName
    }
  }
`;

export const CREATE_SEDE = gql`
  mutation CREATE_SEDE($input: sede_Input) {
    CREATE_SEDE(input: $input) {
      sedeName
    }
  }
`;

export const UPDATE_SEDE = gql`
  mutation UPDATE_SEDE($updateSedeId: ID!, $input: sede_Input) {
    UPDATE_SEDE(id: $updateSedeId, input: $input) {
      id
      sedeName
    }
  }
`;

export const DELETE_SEDE = gql`
  mutation DELETE_SEDE($deleteSedeId: ID!) {
    DELETE_SEDE(id: $deleteSedeId)
  }
`;

export const CREATE_USERS = gql`
  mutation CREATE_USERS($input: users_Input) {
    CREATE_USERS(input: $input) {
      id
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UPDATE_USERS(
    $updateUsersId: ID!
    $input: users_Input
    $process: input_process
  ) {
    UPDATE_USERS(id: $updateUsersId, input: $input, process: $process) {
      id
      name
      firstName
      lastName
      dateOfBirth
      email
      dateOfAdmission
      userType
      project
      campus
      client
      password
      active
      token
      linkedAccount
      cellphone
      area
      tittle
      officePlace
      bussinesName
      contractName
      netSalary
      grossSalary
      DS
      ISD
      nominesPayroll
      paymentPeridicity
      journey
      comment
      userStatus
      expiredAlta
      expiredPersonales
      currentToken
      host
      address
      municipality
      ZC
      state
      country
      placeOfBirth
      nacionality
      gender
      civilStatus
      children
      educationalLevel
      ClaveElectoralPasaporte
      IMSS
      CURP
      workPermission
      creditoInfonavit
      benefitiary
      emergencyContact
      diseases
      bank
      accountNumber
      clabeNum
      status
      RFC
      id_payroll_group
      SDG
      id_User
      id_Bank
      done
      collaborator
      drop
      baja
      Puesto
      Company
      suburb
      benefitiaryCountry
      benefitiaryState
      benefitiaryMunicipality
      benefitiaryCURP
      ParentescoB
      benefitiaryDateOfBirth
      benefitiaryZC
      benefitiaryAddress
      benefitiarySuburb
      ParentescoCE
      cellphoneCE
      phoneCE
      suburbCE
      addressCE
      Alergias
      PadEnfer
      Cirugias
      TraMeRe
      dropDate
      dropType
      EmployerRegister
      own_register
      isFondoAhorro
      PorcentajeFondoAhorro      
    }
  }
`;

export const DELETE_PAYROLL = gql`
  mutation DELETE_PAYROLL($deletePayrollId: ID!) {
    DELETE_PAYROLL(id: $deletePayrollId)
  }
`;

export const DELETE_MONTHLYISR = gql`
  mutation DELETE_MONTHLYISR($deletePayrollId: ID!) {
    DELETE_MONTHLYISR(id: $deletePayrollId)
  }
`;

export const GET_ALL_FILES = gql`
  query {
    GET_ALL_FILES {
      id
      name
      documentType
      maxWeight
      obligatory
      status
    }
  }
`;

export const CREATE_FILES = gql`
  mutation CREATE_FILES($input: files_Input) {
    CREATE_FILES(input: $input) 
  }
`;

export const UPDATE_FILES = gql`
  mutation UPDATE_FILES($updateFilesId: ID!, $input: files_Input) {
    UPDATE_FILES(id: $updateFilesId, input: $input) 
  }
`;

export const GET_FILE = gql`
  query GET_FILE($id: ID!) {
    GET_FILE(id: $id) {
      name
      documentType
      maxWeight
      obligatory
      status
    }
  }
`;

export const DELETE_FILES = gql`
  mutation DELETE_FILES($deleteFilesId: ID!) {
    DELETE_FILES(id: $deleteFilesId)
  }
`;

export const GET_ALL_AREA_PROJECT = gql`
  query {
    GET_ALL_PROJECT {
      id
      Cliente: client
      NombreProyecto: proyectName
    }
  }
`;

export const GET_ALL_PUESTOS = gql`
  query {
    GET_ALL_PUESTOS {
      idPuesto: id
      NombrePuesto
      AreaProyecto
      PuestoSuperior
      Descripcion     
      users {
        id
      }
      projectId
    }
  }
`;

export const GET_PUESTO = gql`
  query GET_PUESTO($getPuestoId: ID!) {
    GET_PUESTO(id: $getPuestoId) {
      idPuesto: id
      NombrePuesto
      AreaProyecto
      PuestoSuperior
      Descripcion
      NombrePuestoSuperior: NombreUsuarioPuestoSuperior
      idUsuarioPuestoSuperior
      ImagenUsuario
      userAdd
      dateAdd
      userMod
      dateMod
      status
    }
  }
`;

export const GET_USER_AVAILABLE = gql`
  query GET_USER_AVAILABLE($puesto: ID!) {
    GET_USER_AVAILABLE(puesto: $puesto) {
      _id: id
      ApellidoUsuario: firstName
      PrimerNombre: name
      lastName
      FullName
      Usuario: email
      id_User
      id_Bank
      ImagenUsuario
      NombreUsuario: name
    }
  }
`;

export const GET_USER_ADMIN = gql`
  query GET_USER_ADMIN($puesto: ID!) {
    GET_USER_ADMIN(puesto: $puesto) {
      _id: id
      ApellidoUsuario: firstName
      PrimerNombre: name
      lastName
      FullName
      Usuario: email
      id_User
      id_Bank
      ImagenUsuario
      NombreUsuario: name
    }
  }
`;

export const UPDATE_PUESTO = gql`
  mutation UPDATE_PUESTO($idPuesto: ID!, $input: puesto_Input) {
    UPDATE_PUESTO(id: $idPuesto, input: $input) {
      id
      NombrePuesto
      AreaProyecto
      PuestoSuperior
      Descripcion
    }
  }
`;

export const DELETE_PUESTO = gql`
  mutation DELETE_PUESTO($deletePuestosId: ID!) {
    DELETE_PUESTO(id: $deletePuestosId)
  }
`;

export const DELETE_USER_PUESTO = gql`
  mutation DELETE_USER_PUESTO($deleteUserPuestoId: ID!) {
    DELETE_USER_PUESTO(id: $deleteUserPuestoId)
  }
`;

export const ADD_USER_PUESTO = gql`
  mutation ADD_USER_PUESTO($addUserPuestoId: ID!, $puestoId: ID!) {
    ADD_USER_PUESTO(id: $addUserPuestoId, puestoId: $puestoId)
  }
`;

export const CREATE_PUESTO = gql`
  mutation CREATE_PUESTO($input: puesto_Input) {
    CREATE_PUESTO(input: $input) {
      NombrePuesto
      AreaProyecto
      PuestoSuperior
      Descripcion
      id
    }
  }
`;

export const GET_ALL_PUESTOS_USERS = gql`
  query {
    GET_ALL_PUESTOS_USERS {
      idPuesto: id
      NombrePuesto
      AreaProyecto
      PuestoSuperior
      Descripcion
      NombreUsuario: NombreUsuarioPuestoSuperior
      idUsuario: idUsuarioPuestoSuperior
      ImagenUsuario
      users {
        idUsuario: id
        NombreUsuario: bussinesName
        ApellidoUsuario: firstName
        Usuario: email
      }
    }
  }
`;

export const GET_ALL_SETTLEMENTPAYROLL = gql`
  query {
    GET_ALL_SETTLEMENTPAYROLL {
      id
      dischargeDate
      dischargeType
      reason
      recessionJob
      Taxable
      NotTaxable
      Mixed
      Total
      idConcept
      idCollaborator
      Collaborator
      RFC
      Company
      dateOfAdmission
      payrollCompleted
      statusProgress
    }
  }
`;

export const GET_SETTLEMENTPAYROLL = gql`
query GET_SETTLEMENTPAYROLL($getSettlementpayrollId: ID!) {
  GET_SETTLEMENTPAYROLL(id: $getSettlementpayrollId) {
    id
    dischargeDate
    dischargeType
    reason
    recessionJob
    Taxable
    NotTaxable
    Mixed
    Total
    idConcept
    dateAdd
    userAdd
    dateMod
    userMod
    status
    idCollaborator
    Collaborator
    dateFinish
  }
}
`;

export const CREATE_SETTLEMENTPAYROLL = gql`
  mutation CREATE_SETTLEMENTPAYROLL($input: settlementpayroll_Input) {
    CREATE_SETTLEMENTPAYROLL(input: $input) {
      id
      dischargeDate
      dischargeType
      reason
      recessionJob
      Taxable
      NotTaxable
      Mixed
      Total
      idConcept
      idCollaborator
    }
  }
`;

export const DELETE_SETTLEMENTPAYROLL = gql`
  mutation DELETE_SETTLEMENTPAYROLL($deleteSettlementPayrollId: ID!) {
    DELETE_SETTLEMENTPAYROLL(id: $deleteSettlementPayrollId)
  }
`;

export const EXTERNO_SETTLEMENTPAYROLL = gql`
  mutation EXTERNO_SETTLEMENTPAYROLL($externoSettlementPayrollId: ID!) {
    EXTERNO_SETTLEMENTPAYROLL(id: $externoSettlementPayrollId)
  }
`;

export const GET_SETTLEMENTCOLLABORATOR = gql`
  query GET_SETTLEMENTCOLLABORATOR($getSettlementPayrollcollaboratorId: ID!) {
    GET_SETTLEMENTCOLLABORATOR(id: $getSettlementPayrollcollaboratorId) {
      id
      idSettlement
      idCollaborator
      colaborator
      salary
      incident
      aguinaldo
      holidays
      perception
      deduction
      taxes
      total
      primaVacacional
      PrimaAntiguedad
      AniosServicio
      settlementDaysSalary
      salaryTaxable
      aguinaldoTaxable
      holidaysTaxable
      taxesTaxable
      PrimaVacacionalTaxable
      totalTaxable
      AniosServicioNoTaxable
      settlementDaysSalaryNoTaxable
      WorkingDays
      UUID
      dispersionStatus
      Subsidio
      SubsidioNoGravable
      IMSS
      IMSSNoGravable
      status
      FondoAhorro
      FechaBaja
      FechaIngreso
      AguinaldoDias
      VacacionesDias
      SueldoDias
      PrimaVacacionalDias
      settlementLetterLink
      resignationLetterLink
      liquidationLetterLink
      incidentNoGravable
      DS
      ISD
      Infonavit
    }
  }
`;

export const GET_SETTLEMENTCOLLABORATOR_DISPERSION = gql`
query GET_SETTLEMENTCOLLABORATOR_DISPERSION($getSettlementcollaboratorDispersionId: ID!) {
  GET_SETTLEMENTCOLLABORATOR_DISPERSION(id: $getSettlementcollaboratorDispersionId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    percepcion
    deduccion
    total
    Tipo
  }
}
`;

export const GET_SETTLEMENTCOLLABORATOR_DISPERSION_EXCELL = gql`
query GET_SETTLEMENTCOLLABORATOR_DISPERSION_EXCELL($getSettlementcollaboratorDispersionId: ID!) {
  GET_SETTLEMENTCOLLABORATOR_DISPERSION_EXCELL(id: $getSettlementcollaboratorDispersionId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    percepcion
    deduccion
    total
    Tipo
  }
}
`;

export const UPDATE_SETTLEMENT_COLLABORATOR_LETTER = gql`
  mutation UPDATE_SETTLEMENT_COLLABORATOR_LETTER(
    $id: ID! , $input: settlement_letter
  ) {
    UPDATE_SETTLEMENT_COLLABORATOR_LETTER(
      id: $id, input: $input
    )
  }
`;

export const GET_SETTLEMENTCOLLABORATOR_TIMBRADO = gql`
  query GET_SETTLEMENTCOLLABORATOR_TIMBRADO(
    $getSettlementPayrollcollaboratorTimbradoId: ID!
  ) {
    GET_SETTLEMENTCOLLABORATOR_TIMBRADO(
      id: $getSettlementPayrollcollaboratorTimbradoId
    ) {
      colaborator
      total
      perception
      deduction
      users {
        RFC
      }
    }
  }
`;


export const GET_ALL_SETTLEMENT_COLLABORATOR = gql`
  query {
    GET_ALL_SETTLEMENT_COLLABORATOR {
      id
      idSettlement
      idCollaborator
      colaborator
      salary
      incident
      aguinaldo
      holidays
      perception
      deduction
      taxes
      total
      primaVacacional
    }
  }
`;
export const GET_ALL_CALENDARS = gql`
  query {
    GET_ALL_CALENDARS {
      id
      name
      enterpriseId
      calendarScheme
      status
      idScheme
      NameScheme
      NameEnterprise
    }
  }
`;

export const GET_ALL_CALENDARS_SCHEME = gql`
  query {
    GET_ALL_CALENDARS_SCHEME {
      id
      name
    }
  }
`;

export const GET_CALENDARS = gql`
  query GET_CALENDARS($id: ID!) {
    GET_CALENDARS(id: $id) {
      name
      enterpriseId
      calendarScheme
      status
      idScheme
      NameScheme
      NameEnterprise
    }
  }
`;
export const UPDATE_CALENDARS = gql`
  mutation UPDATE_CALENDARS($updateCalendarsId: ID!, $input: calendars_Input) {
    UPDATE_CALENDARS(id: $updateCalendarsId, input: $input) {
      name
      enterpriseId
      calendarScheme
    }
  }
`;

export const DELETE_CALENDARS = gql`
  mutation DELETE_CALENDARS($deleteCalendarId: ID!) {
    DELETE_CALENDARS(id: $deleteCalendarId)
  }
`;
export const CREATE_CALENDARS = gql`
  mutation CREATE_CALENDARS($input: calendars_Input) {
    CREATE_CALENDARS(input: $input) {
      name
      enterpriseId
      calendarScheme
    }
  }
`;
export const GET_PAYROLL = gql`
  query GET_PAYROLL($getPayrollId: ID!) {
    GET_PAYROLL(id: $getPayrollId) {
      id
      group_name
      payroll_type
      frecuency_payment
      init_date
      end_date
      employees
      perception
      deduction
      total
      status
      dateFinish
      payroll_type
      Incident
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_INFO_XML = gql`
query GET_PAYROLLCOLLABORATOR_INFO_XML(
  $idPayroll: String!
) {
  GET_PAYROLLCOLLABORATOR_INFO_XML(idPayroll: $idPayroll) {
  xml
  Folio
  Fecha
  Colaborador
}
}
`;
 

export const GET_MONTHLYISR = gql`
  query GET_MONTHLYISR($getPayrollId: ID!) {
    GET_MONTHLYISR(id: $getPayrollId) {
      id
      group_name
      frecuency_payment
      init_date
      end_date
      employees
      positive
      negative
      diference
      id_group_payroll
    }
  }
`;

export const SEND_DISPERSION = gql`
  mutation SEND_DISPERSION($sendDispersionId: [dispersion_Input!]) {
    SEND_DISPERSION(id: $sendDispersionId)
  }
`;

export const SEND_DISPERSION_FINIQUITO = gql`
mutation SEND_DISPERSION_FINIQUITO($sendDispersionFiniquitoId: [dispersion_Input!]) {
  SEND_DISPERSION_FINIQUITO(id: $sendDispersionFiniquitoId)
}
`;

export const SEND_DISPERSION_EVENTUAL = gql`
  mutation SEND_DISPERSION_EVENTUAL($sendDispersionEventualId: [dispersion_Input!]) {
    SEND_DISPERSION_EVENTUAL(id: $sendDispersionEventualId)
  }
`;

export const ENVIO_MOVIMIENTO = gql`
mutation ENVIO_MOVIMIENTO($envioMovimientoId: [dispersion_Input!]) {
  ENVIO_MOVIMIENTO(id: $envioMovimientoId)
}`


export const GET_PAYROLL_BY_CALENDAR = gql`
  query GET_PAYROLL_BY_CALENDAR($id: Int!) {
    GET_PAYROLL_BY_CALENDAR(calendarId: $id) {
      id
      idPayroll
      idCollaborator
      payroll_collaborator {
        idPayroll
        colaborator
      }
    }
  }
`;
export const GET_ALL_EVENT = gql`
  query GET_ALL_EVENT($id: Int!) {
    GET_ALL_EVENT(calendarId: $id) {
      id
    }
  }
`;

export const GET_ALL_NON_WORKING = gql`
  query GET_ALL_NON_WORKING {
    GET_ALL_NON_WORKING {
      id
      calendarId
      name
      initDate
      endDate
      status
    }
  }
`;

export const GET_NON_WORKING = gql`
  query GET_NON_WORKING($id: ID!) {
    GET_NON_WORKING(id: $id) {
      id
      calendarId
      name
      initDate
      endDate
      status
    }
  }
`;
export const GET_NON_WORKING_BY_DATE = gql`
  query GET_NON_WORKING_BY_DATE($calendarId: Int!, $initDate: String!) {
    GET_NON_WORKING_BY_DATE(calendarId: $calendarId, initDate: $initDate) {
      id
      name
      calendarId
      initDate
      endDate
      status
    }
  }
`;

export const GET_NON_WORKING_MONTH = gql`
  query GET_NON_WORKING_MONTH($calendarId: Int!, $initDate: String!) {
    GET_NON_WORKING_MONTH(calendarId: $calendarId, initDate: $initDate) {
      id
      name
      calendarId
      initDate
      endDate
      status
    }
  }
`;

export const CREATE_NON_WORKING = gql`
  mutation CREATE_NON_WORKING($input: non_working_Input) {
    CREATE_NON_WORKING(input: $input) {
      name
      calendarId
      initDate
      endDate
    }
  }
`;

export const GET_ALL_PERCEPTIONS_CALENDAR = gql`
  query GET_ALL_PERCEPTIONS_CALENDAR {
    GET_ALL_PERCEPTIONS_CALENDAR {
      id
      ConceptName
      SATKey
      Concept_Type
      AccuntingAccount
      PayType
      ISRTax
      ISNTax
      SocialSecurity
      IntegratesIMSS
      descriptionPerception
      TaxBoth
      Calendar
      time
      colorCode
      legendTitle
    }
  }
`;

export const GET_ALL_PERCEPTIONS_CALENDAR_MONTH_YEAR = gql`
  query GET_ALL_PERCEPTIONS_CALENDAR_MONTH_YEAR($calendarId: Int!, $month: Int!, $year: Int!) {
    GET_ALL_PERCEPTIONS_CALENDAR_MONTH_YEAR(calendarId: $calendarId, month: $month, year: $year) {
      id
      ConceptName
      SATKey
      Concept_Type
      AccuntingAccount
      PayType
      ISRTax
      ISNTax
      SocialSecurity
      IntegratesIMSS
      descriptionPerception
      TaxBoth
      Calendar
      time
      colorCode
      legendTitle
    }
  }
`;

export const GET_DEDUCTIONS_CALENDAR = gql`
  query GET_DEDUCTIONS_CALENDAR {
    GET_DEDUCTIONS_CALENDAR {
      id
      concept_type
      SATKey
      ISRTax
      TaxBoth
      Calendar
      time
      descriptiondeductions
      colorCode
      legendTitle
    }
  }
`;

export const GET_DEDUCTIONS_CALENDAR_MONTH_YEAR = gql`
  query GET_DEDUCTIONS_CALENDAR_MONTH_YEAR($calendarId: Int!, $month: Int!, $year: Int!) {
    GET_DEDUCTIONS_CALENDAR_MONTH_YEAR(calendarId: $calendarId, month: $month, year: $year) {
      id
      concept_type
      SATKey
      ISRTax
      TaxBoth
      Calendar
      time
      descriptiondeductions
      colorCode
      legendTitle
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CREATE_EVENT($input: event_Input) {
    CREATE_EVENT(input: $input) {
      id
      name
      calendarId
      description
      initDate
      endDate      
      eventType
    }
  }
`;

export const GET_CALENDAR = gql`
  query GET_CALENDAR($id: ID!) {
    GET_CALENDAR(id: $id) {
      id
      name
      enterpriseId
      calendarScheme
      status
    }
  }
`;

export const GET_COUNT_COMMENT = gql`
  query GET_COUNT_COMMENT {
    GET_COUNT_COMMENT {
      name
      value
      percentage
    }
  }
`;

export const GET_COUNT_COMMENT_15 = gql`
  query GET_COUNT_COMMENT_15 {
    GET_COUNT_COMMENT_15 {
      name
      value
      fecha
      percentage
    }
  }
`;


export const GET_ALL_FINISH_PAYROLL = gql`
  query {
    GET_ALL_FINISH_PAYROLL {
      id
      group_name
      payroll_type
      frecuency_payment
      init_date
      end_date
      employees
      perception
      deduction
      total
      id_group_payroll
      status
    }
  }
`;

export const GET_ALL_FINISH_PAYROLL_YEAR = gql`
  query GET_ALL_FINISH_PAYROLL_YEAR($init_date: String!) {
    GET_ALL_FINISH_PAYROLL_YEAR(init_date: $init_date) {
      id
      group_name
      payroll_type
      frecuency_payment
      init_date
      end_date
      employees
      perception
      deduction
      total
      id_group_payroll
      status
    }
  }
`;

export const GET_PAYROLL_DAY = gql`
  query GET_PAYROLL_DAY {
    GET_PAYROLL_DAY {
      id
      group_name
      payroll_type
      frecuency_payment
      init_date
      end_date
      employees
      perception
      deduction
      total
      id_group_payroll
      status
      statusProgress
    }
  }
`;

export const GET_ALL_PAYROLL_ACTIVE = gql`
  query GET_ALL_PAYROLL_ACTIVE {
    GET_ALL_PAYROLL_ACTIVE {
      id
      group_name
      payroll_type
      frecuency_payment
      init_date
      end_date
      employees
      perception
      deduction
      total
      id_group_payroll
      status
      statusProgress
    }
  }
`;

export const GET_ALL_MONTHLYISR_ACTIVE = gql`
  query GET_ALL_MONTHLYISR_ACTIVE {
    GET_ALL_MONTHLYISR_ACTIVE {
      id
      group_name
      frecuency_payment
      init_date
      end_date
      employees
      positive
      negative
      diference
      id_group_payroll
      status
      statusProgress
    }
  }
`;


export const GET_ALL_PAYROLLCOLLABORATOR_EXCELL = gql`
  query GET_ALL_PAYROLLCOLLABORATOR_EXCELL(
    $getAllPayrollcollaboratorExcellId: ID!
  ) {
    GET_ALL_PAYROLLCOLLABORATOR_EXCELL(id: $getAllPayrollcollaboratorExcellId) {
      id
      idPayroll
      colaborator
      netIncome
      DS
      GDS
      periodDays
      workingDays
      grossSalary
      Subsidy
      ISR
      IMSS
      SavingsFund
      INFONAVIT
      incident
      netIncomeTaxable
      TotalIncomeNotTaxable
    }
  }
`;

export const GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL = gql`
  query GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL(
    $getAllPayrollcollaboratorExcellId: ID!, $type: String!
  ) {
    GET_ALL_PAYROLLCOLLABORATORSETTLEMENTEVENT_EXCELL(id: $getAllPayrollcollaboratorExcellId, type: $type) {
      id
      idPayroll
      colaborator
      netIncome
      DS
      GDS
      periodDays
      workingDays
      grossSalary
      Subsidy
      ISR
      IMSS
      SavingsFund
      INFONAVIT
      incident
      netIncomeTaxable
      TotalIncomeNotTaxable
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO = gql`
  query GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO(
    $getPayrollcollaboratorExcellCalculoId: ID!
  ) {
    GET_PAYROLLCOLLABORATOR_EXCELL_CALCULO(id: $getPayrollcollaboratorExcellCalculoId) {
      Colaborador
      SalarioNeto
      SalarioDiario
      SalarioDiarioGravable
      DiasPeriodo
      DiasTrabajados
      SalarioBruto
      Subsidio
      ISR
      IMSS
      FondoDeAhorro
      INFONAVIT
      Incidencias
      NetoGravable
      NetoNoGravable
      area
    }
  }
`;

export const GET_MONTHLYISRCOLLABORATOR_EXCELL_CALCULO = gql`
  query GET_MONTHLYISRCOLLABORATOR_EXCELL_CALCULO(
    $getPayrollcollaboratorExcellCalculoId: ID!
  ) {
    GET_MONTHLYISRCOLLABORATOR_EXCELL_CALCULO(id: $getPayrollcollaboratorExcellCalculoId) {
      Colaborador
      baseGravable
      LimiteInferior
      Excedente
      Tasa
      ISRMarginal
      CuotaFija
      ISRCausado
      SUPECausado
      ISRAcreditado
      SUPEAcreditado
      ISR
      SUPE
      TotalDiferencia
      area
    }
  }
`;


export const GET_PAYROLLCOLLABORATOR_HISTORICAL = gql`
  query GET_PAYROLLCOLLABORATOR_HISTORICAL($payroll: String!) {
    GET_PAYROLLCOLLABORATOR_HISTORICAL(payroll: $payroll) {
      id
      idPayroll
      netIncome
      colaborator
      DS
      GDS
      periodDays
      workingDays
      grossSalary
      Subsidy
      ISR
      IMSS
      SavingsFund
      INFONAVIT
      incident
      netIncomeTaxable
      TotalIncomeNotTaxable
    }
  }
`;

export const GET_REPORTEPOLIZA_CONTABLE = gql`
query GET_REPORTEPOLIZA_CONTABLE($getReportepolizaContableId: ID!) {
  GET_REPORTEPOLIZA_CONTABLE(id: $getReportepolizaContableId) {
    Concepto
    CuentaContable
    Monto
  }
}
`;

export const GET_ALL_DISPERSIONLAYOUT = gql`
  query GET_ALL_DISPERSIONLAYOUT($getAllDispersionlayoutId: ID!) {
    GET_ALL_DISPERSIONLAYOUT(id: $getAllDispersionlayoutId) {
      id
      idPayroll
      Nombre
      RFC
      Cuenta
      Clabe
      Banco
      netIncomeTaxable
      MontoDispersar
    }
  }
`;
export const GET_ALL_DISPERSIONLAYOUTEVENTUAL = gql`
  query GET_ALL_DISPERSIONLAYOUTEVENTUAL($getAllDispersionlayoutId: ID!) {
    GET_ALL_DISPERSIONLAYOUTEVENTUAL(id: $getAllDispersionlayoutId) {
      id
      idPayroll
      Nombre
      RFC
      Cuenta
      Clabe
      Banco
      netIncomeTaxable
      MontoDispersar
    }
  }
`;

export const GET_ALL_DISPERSIONLAYOUT_FINIQUITO_OUT = gql`
  query GET_ALL_DISPERSIONLAYOUT_FINIQUITO_OUT($getAllDispersionlayoutId: ID!) {
    GET_ALL_DISPERSIONLAYOUT_FINIQUITO_OUT(id: $getAllDispersionlayoutId) {
      id
      idPayroll
      Nombre
      RFC
      Cuenta
      Clabe
      Banco
      netIncomeTaxable
      MontoDispersar
    }
  }
`;

export const GET_ALL_DISPERSIONLAYOUT_FINIQUITO = gql `
query GET_ALL_DISPERSIONLAYOUT_FINIQUITO($getAllDispersionlayoutFiniquitoId: ID!) {
  GET_ALL_DISPERSIONLAYOUT_FINIQUITO(id: $getAllDispersionlayoutFiniquitoId) {
    id
    idPayroll
    Nombre
    RFC
    Cuenta
    Clabe
    Banco
    MontoDispersar
  }
}
`

export const GET_NOTIFICATIONS_MOBILE = gql`
  query GET_NOTIFICATIONS_MOBILE {
    GET_NOTIFICATIONS_MOBILE {
      id
      Titulo
      Descripcion
      Fecha
      scheduleDate
      projects
    }
  }
`;

export const GET_NOTIFICATION_MOBILE = gql`
  query GET_NOTIFICATION_MOBILE ($id: ID!) {
    GET_NOTIFICATION_MOBILE(id: $id) {
      id
      Titulo
      Descripcion
      Fecha
      scheduleDate
      projects
    }
  }
`;

export const CREATE_NOTIFICATION_MOBILE = gql`
  mutation CREATE_NOTIFICATION_MOBILE($input: notifications_mobile_Input!) {
    CREATE_NOTIFICATION_MOBILE(input: $input) {
      Titulo
      Descripcion
      Fecha
      scheduleDate
      projects
    }
  }
`;

export const UPDATE_NOTIFICATION_MOBILE = gql`
  mutation UPDATE_NOTIFICATION_MOBILE($id: ID!, $input: notifications_mobile_Input!) {
    UPDATE_NOTIFICATION_MOBILE(id: $id, input: $input) 
  }
`;

export const DELETE_NOTIFICATION_MOBILE = gql`
  mutation DELETE_NOTIFICATION_MOBILE($id: ID!) {
    DELETE_NOTIFICATION_MOBILE(id: $id)
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CREATE_NOTIFICATION($input: notifications_Input) {
    CREATE_NOTIFICATION(input: $input) {
      id
      mensaje
      status
      statusNotification
    }
  }
`;
export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS($id: ID!) {
    GET_NOTIFICATIONS(id: $id) {
      id
      mensaje
      statusNotification
      tipo
      dateAdd
      idRegistro
      Desde
      payrollGroup
      eventualGroup
      collaborator
    }
  }
`;

export const GET_NOTIFICATIONS_SINVER = gql`
  query GET_NOTIFICATIONS_SINVER($id: ID!) {
    GET_NOTIFICATIONS_SINVER(id: $id) {
      id
    }
  }
`;

export const MARCAR_LEIDAS = gql`
  mutation MARCAR_LEIDAS($id: ID!) {
    MARCAR_LEIDAS(id: $id)
  }
`;

export const DELETE_NOTIFICATIONS = gql`
  mutation DELETE_NOTIFICATIONS($id: ID!) {
    DELETE_NOTIFICATIONS(id: $id)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DELETE_NOTIFICATION($deleteNotificationId: ID!, $id: ID!) {
    DELETE_NOTIFICATION(id: $deleteNotificationId, idAdmin: $id)
  }
`;
export const UPDATE_PAYROLL_STATE = gql`
  mutation UPDATE_PAYROLL_STATE($input: payroll_Input_state) {
    UPDATE_PAYROLL_STATE(input: $input) {
      id
      statusProgress
    }
  }
`;

export const UPDATE_MONTHLYISR_STATE = gql`
  mutation UPDATE_MONTHLYISR_STATE($input: monthlyISR_Input_state) {
    UPDATE_MONTHLYISR_STATE(input: $input) {
      id
      statusProgress
    }
  }
`;

export const GET_USER_CONTRACT_CAL = gql`
  query GET_USER_CONTRACT_CAL {
    GET_USER_CONTRACT_CAL {
      id
      bussinesName
      numberday
      percentage
    }
  }
`;

export const GET_ALL_EVENTUALPAYROLL = gql`
  query GET_ALL_EVENTUALPAYROLL {
    GET_ALL_EVENTUALPAYROLL {
      id
      group_name
      payroll_type
      init_date
      end_date
      employees
      perception
      deduction
      total
      id_group_payroll
      status
      statusProgress
      AportacionPatronal
      AnioPTU
      MontoRepartirPTU
      FactorIngresos
      FactorDias
      AguinaldoPeriod
      token
      Mes
      pantryVouchersMonth
      payrollCompleted
    }
  }
`;

export const GET_ALL_MONTHLYISR = gql`
  query GET_ALL_MONTHLYISR {
    GET_ALL_MONTHLYISR {
      id
      group_name
      frecuency_payment
      init_date
      end_date
      employees
      positive
      negative
      diference
      id_group_payroll
      userAdd
      dateAdd
      status
      statusProgress
      userFinish
      dateFinish
      uuid
    }
  }
`;

export const CREATE_EVENTUALPAYROLL = gql`
  mutation Mutation($input: eventualPayroll_Input) {
    CREATE_EVENTUALPAYROLL(input: $input) {
      group_name
      payroll_type
      init_date
      end_date
      id_group_payroll
      AportacionPatronal
      AnioPTU
      MontoRepartirPTU
      AguinaldoPeriod
    }
  }
`;

export const CREATE_EVENTUALPAYROLL_WITH_TOKEN = gql`
  mutation CREATE_EVENTUALPAYROLL_WITH_TOKEN($input: eventualPayroll_Input) {
    CREATE_EVENTUALPAYROLL_WITH_TOKEN(input: $input) {
      respuesta
      mensaje
      GrupoNomina
      TipoNomina
    }
  }
`;

export const UPDATE_EVENTUALPAYROLL_STATE = gql`
  mutation UPDATE_EVENTUALPAYROLL_STATE($input: eventualPayroll_Input_state) {
    UPDATE_EVENTUALPAYROLL_STATE(input: $input) {
      id
      statusProgress
    }
  }
`;

export const DELETE_EVENTUALPAYROLL = gql`
  mutation DELETE_EVENTUALPAYROLL($deleteEventualPayrollId: ID!) {
    DELETE_EVENTUALPAYROLL(id: $deleteEventualPayrollId)
  }
`;

export const GET_EVENTUALPAYROLLCOLLABORATOR_DESGLOCE = gql`
  query GET_EVENTUALPAYROLLCOLLABORATOR_DESGLOCE(
    $getEventualpayrollcollaboratorId: ID!
  ) {
    GET_EVENTUALPAYROLLCOLLABORATOR_DESGLOCE(id: $getEventualpayrollcollaboratorId) {
      colaborador
      fechaInicio
      fechaFin
      total
    }
  }
`;


export const GET_EVENTUALPAYROLLCOLLABORATOR = gql`
  query GET_EVENTUALPAYROLLCOLLABORATOR(
    $getEventualpayrollcollaboratorId: ID!
  ) {
    GET_EVENTUALPAYROLLCOLLABORATOR(id: $getEventualpayrollcollaboratorId) {
      id
      idEventualPayroll
      id_Collaborator
      colaborator
      area
      project
      perception
      deduction
      total
      idDispersion
      dispersionStatus
      SD
      init_datePTU
      end_datePTU
      workingDaysPTU
      AnnualIncomePTU
      IncomeAmountPTU
      IncomeDaysPTU
      TotalDealPTU
      NoContributions
      PercentageContributions
      WorkerContributions
      EmployerContributions
      AguinaldoPeriod
      AntiguedadPrimaVacacional
      VacacionesPrimaVacacional
      TasaPrimaVacacional
      AguinaldoDays
      RFC
      accountNumber
      bank
      clabeNum
      FechaIngreso
      netIncomeAguinaldo
      AguinaldoGravable
      AguinaldoNoGravable
      DiasLaborados
      FactorAguinaldo
      SDG
      Excento
      Gravado
      fechaAlta
      UUID
      TotalNoGravablePrimaVacacional
      SDNoGravablePrimaVacacional
      TipoAportacion
      baja
      collaborator
    }
  }
`;

export const GET_EVENTUALPAYROLL = gql`
  query GET_EVENTUALPAYROLL($getEventualPayrollId: ID!) {
    GET_EVENTUALPAYROLL(id: $getEventualPayrollId) {
      id
      group_name
      payroll_type
      init_date
      end_date
      employees
      perception
      deduction
      total
      id_group_payroll
      status
      statusProgress
      AportacionPatronal
      AnioPTU
      MontoRepartirPTU
      FactorIngresos
      FactorDias
      AguinaldoPeriod
      dateFinish
      payroll_type
      pantryVouchersMonth
    }
  }
`;

export const GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION = gql`
  query GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION($getEventualpayrollcollaboratorDispersionId: ID!) {
    GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION(id: $getEventualpayrollcollaboratorDispersionId) {
      id
      colaborator
      RFC
      bank
      accountNumber
      clabeNum
      netIncomeTaxable
      TotalIncomeNotTaxable
      dispersionStatus
      idDispersion
      tokenDispersar
      mensajeDispersion
      percepcion
      deduccion
      total
      AguinaldoNoGravable
      TotalAguinaldo
      baja
      collaborator
    }
  }
`;


/*
export const GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION = gql`
  query GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION($getEventualpayrollcollaboratorDispersionId: ID!) {
    GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION(id: $getEventualpayrollcollaboratorDispersionId) {
      id
      colaborator
      RFC
      bank
      accountNumber
      clabeNum
      dispersionStatus
      total
    }
  }
`;
*/


export const GET_EVENTUALPAYROLLCOLLABORATOR_TIMBRADO = gql`
  query GET_EVENTUALPAYROLLCOLLABORATOR_TIMBRADO(
    $getEventualpayrollcollaboratorTimbradoId: ID!
  ) {
    GET_EVENTUALPAYROLLCOLLABORATOR_TIMBRADO(
      id: $getEventualpayrollcollaboratorTimbradoId
    ) {
      colaborator
      perception
      deduction
      total
      AguinaldoPeriod
      AntiguedadPrimaVacacional
      VacacionesPrimaVacacional
      TasaPrimaVacacional
      AguinaldoDays
      users {
        RFC
      }
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_IMPUESTO = gql`
  query GET_PAYROLLCOLLABORATOR_IMPUESTO($input: reports_Input) {
    GET_PAYROLLCOLLABORATOR_IMPUESTO(input: $input) {
      Tipo
      MontoImpuesto
      Mes
    }
  }
`;
export const GET_CALCULADORA = gql`
  query GET_CALCULADORA($input: calculadora_Input) {
    GET_CALCULADORA(input: $input) {
      LimiteInferior
      Excedente
      PorcentajeExcedente
      CuotaFija
      ImpuestoMarginal
      ImpuestoRetenido
      Imss
      SubsidioEmpleo
      SueldoNeto
    }
  }
`;

export const GET_PAYROLLINCIDENT_REPORTE = gql`
  query GET_PAYROLLINCIDENT_REPORTE($input: reporte_Input) {
    GET_PAYROLLINCIDENT_REPORTE(input: $input) {
      Nombre
      FechaAdmision
      Proyecto
      FechaIncidencia
      TipoIncidencia
      Dias
      Horas
      Monto
    }
  }
`;

export const REACTIVATE_USERS = gql`
  mutation REACTIVATE_USERS($id: ID!, $input: users_Input_Reactivate) {
    REACTIVATE_USERS(id: $id, input: $input) {
      id
      name
      FechaIngreso
      FechaBaja
      typeDrop
      reasonCollaborator
    }
  }
`;

export const GET_EXCEL_ACTIVE_USER = gql`
  query GET_EXCEL_ACTIVE_USER {
    GET_EXCEL_ACTIVE_USER {
      name
      firstName
      lastName
      fullName
      FechaIngreso
      FechaBaja
      client
      project
      area
      Puesto
      officePlace
      reasonCollaborator
      contractName
      paymentPeridicity
      journey
      netSalary
      grossSalary
      DS
      ISD
      typeDrop
      recordableSalary
      notRecordableSalary
      SDG

      nominesPayroll
      email
      cellphone
      address
      suburb
      ZC
      municipality
      country
      nacionality
      ClaveElectoralPasaporte
      dateOfBirth
      placeOfBirth
      gender
      state
      children
      IMSS
      creditoInfonavit
      CURP
      RFC
      bank
      accountNumber
      clabeNum

      benefitiary
      ParentescoB
      benefitiaryDateOfBirth
      benefitiaryCURP
      benefitiaryAddress
      benefitiarySuburb
      benefitiaryZC
      benefitiaryCountry
      benefitiaryState
      benefitiaryMunicipality

      emergencyContact
      tittle
      ParentescoCE
      phoneCE
      cellphoneCE
      addressCE
      suburbCE
      calendarName
      nameEnterprise
      EmployerRegister
      unionized
      payrollGroupName
      educationalLevel
      workPermission
      Alergias
      PadEnfer
      Cirugias
      TraMeRe
      tittle2

      typeWorkerExcel
      typeSalaryNExcel  
      week_PartTimeExcel
      saving_fund_type
      saving_fund_cap
      isFondoAhorro
      PorcentajeFondoAhorro
    }
  }
`;

export const GET_EXCEL_INACTIVE_USER = gql`
  query GET_EXCEL_INACTIVE_USER {
    GET_EXCEL_INACTIVE_USER {
      name
      firstName
      lastName
      fullName
      FechaIngreso
      FechaBaja
      client
      project
      area
      Puesto
      officePlace
      reasonCollaborator
      contractName
      paymentPeridicity
      journey
      netSalary
      grossSalary
      DS
      ISD
      typeDrop

      nominesPayroll
      email
      cellphone
      address
      suburb
      ZC
      municipality
      country
      nacionality
      ClaveElectoralPasaporte
      dateOfBirth
      placeOfBirth
      gender
      state
      children
      IMSS
      creditoInfonavit
      CURP
      RFC
      bank
      accountNumber
      clabeNum

      benefitiary
      ParentescoB
      benefitiaryDateOfBirth
      benefitiaryCURP
      benefitiaryAddress
      benefitiarySuburb
      benefitiaryZC
      benefitiaryCountry
      benefitiaryState
      benefitiaryMunicipality

      emergencyContact
      tittle
      ParentescoCE
      phoneCE
      cellphoneCE
      addressCE
      suburbCE
      calendarName
      nameEnterprise
      EmployerRegister
      unionized
      payrollGroupName
      educationalLevel
      workPermission
      Alergias
      PadEnfer
      Cirugias
      TraMeRe
      tittle2

      typeWorkerExcel
      typeSalaryNExcel
      week_PartTimeExcel
    }
  }
`;


export const DEACTIVATE_USERS = gql`
  mutation DEACTIVATE_USERS($id: ID!, $input: users_Input_Deactivate) {
    DEACTIVATE_USERS(id: $id, input: $input) 
  }
`;

export const GET_PAYROLL_BY_CALENDAR_BY_DATE = gql`
  query GET_PAYROLL_BY_CALENDAR_BY_DATE(
    $idCalendar: Int!
    $dateFilter: String!
  ) {
    GET_PAYROLL_BY_CALENDAR_BY_DATE(
      idCalendar: $idCalendar
      date_Incident: $dateFilter
    ) {
      id
      idCollaborator
      collaboratorName
      incident_type_Name
      date_Incident
      date_Incident_End
      idCalendar
    }
  }
`;

export const CREATE_PAYROLL_INCIDENT_CALENDAR = gql`
  mutation CREATE_PAYROLL_INCIDENT_CALENDAR(
    $input: payroll_Incident_Input_Calendar
  ) {
    CREATE_PAYROLL_INCIDENT_CALENDAR(input: $input) {
      id
      idPayroll
      idCollaborator
      Incident_type
      InitDate
      EndDate
      Total
      Taxable
      NotTaxable
      Mixed
      idConcept
      Dias
      Horas
      Comentarios
      TypeConcept
      idCalendar
      Periodico
      FechaPeriodica
    }
  }
`;
export const GET_MOVIMIENTOSIDSE = gql`
  query GET_MOVIMIENTOSIDSE {
    GET_MOVIMIENTOSIDSE {
      Nombre
      idMovimiento
      SCB
      Tipo
      Fecha
      estatusMovimiento
      Externo
      IMSS
      terminado
      solicitudIDSE
      respuestaIDSE
      Respuesta
      respuestaIDSEDos
      noProcesado
      Comentario
    }
  }
`;

export const CREATE_MOVIMIENTOSIDSE = gql`
  mutation CREATE_MOVIMIENTOSIDSE($input: movimientosIDSE_input) {
    CREATE_MOVIMIENTOSIDSE(input: $input)
  }
`;

export const UPDATE_ESTATUSMOVIMIENTO = gql`
  mutation UPDATE_ESTATUSMOVIMIENTO($updateEstatusmovimientoId: ID) {
    UPDATE_ESTATUSMOVIMIENTO(id: $updateEstatusmovimientoId)
  }
`;

export const GET_ENTERPRISE_SELECT = gql`
  query GET_ENTERPRISE_SELECT {
    GET_ENTERPRISE_SELECT {
      id
      name
      cuentaBancaria
      Outsourcer
    }
  }
`;
export const GET_ALL_EVENT_CALENDAR = gql`
  query GET_ALL_EVENT_CALENDAR($calendarId: Int!, $initDate: String!) {
    GET_ALL_EVENT_CALENDAR(calendarId: $calendarId, initDate: $initDate) {
      id
      name
      initDate
      endDate
    }
  }
`;

export const UPDATE_PAYROLL_GROUP_STATUS = gql`
  mutation UPDATE_PAYROLL_GROUP_STATUS($updatePayrollGroupStatusId: ID!) {
    UPDATE_PAYROLL_GROUP_STATUS(id: $updatePayrollGroupStatusId)
  }
`;

export const UPDATE_PERCEPTION_STATUS = gql`
  mutation UPDATE_PERCEPTION_STATUS($updatePerceptionStatusId: ID!) {
    UPDATE_PERCEPTION_STATUS(id: $updatePerceptionStatusId)
  }
`;

export const UPDATE_DEDUCTION_STATUS = gql`
  mutation UPDATE_DEDUCTION_STATUS($updateDeductionStatusId: ID!) {
    UPDATE_DEDUCTION_STATUS(id: $updateDeductionStatusId)
  }
`;

export const GET_PAYROLL_GROUP = gql`
  query GET_PAYROLL_GROUP($getPayrollGroupId: ID!) {
    GET_PAYROLL_GROUP(id: $getPayrollGroupId) {
      id
      group_name
      company_name
      payroll_period
      statusPayroll_group
      idOutsourcer
    }
  }
`;

export const GET_ALL_EVENT_NONWORKINGDAY = gql`
  query GET_ALL_EVENT_NONWORKINGDAY($calendarId: Int!) {
    GET_ALL_EVENT_NONWORKINGDAY(calendarId: $calendarId) {
      name
      eventType
      initDate
      endDate
    }
  }
`;

export const GET_COUNT_EVENT_NONWORKINGDAY = gql`
  query GET_COUNT_EVENT_NONWORKINGDAY {
    GET_COUNT_EVENT_NONWORKINGDAY {
      calendarId
      accumulated
      accumulatedNotStarted
    }
  }
`;

export const UPDATE_NON_WORKING = gql`
  mutation UPDATE_NON_WORKING(
    $upDateNonWorkingDayId: ID!
    $input: non_working_Input
  ) {
    UPDATE_NON_WORKING(id: $upDateNonWorkingDayId, input: $input) {
      name
      initDate
      endDate
    }
  }
`;

export const DELETE_NON_WORKING = gql`
  mutation DELETE_NON_WORKING($deleteNonWorkingDayId: ID!) {
    DELETE_NON_WORKING(id: $deleteNonWorkingDayId)
  }
`;

export const GET_EVENT = gql`
  query GET_EVENT($id: ID!) {
    GET_EVENT(id: $id) {
      id
      name
      calendarId
      description
      initDate
      endDate
      projectId
      eventType
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DELETE_EVENT($deleteEventId: ID!) {
    DELETE_EVENT(id: $deleteEventId)
  }
`;

export const UPDATE_EVENT = gql`
  mutation UPDATE_EVENT($upDateEventId: ID!, $input: event_Input) {
    UPDATE_EVENT(id: $upDateEventId, input: $input) {
      name
      calendarId
      description
      initDate
      endDate
      projectId
      eventType
    }
  }
`;

export const GET_ALL_NOTICES = gql`
  query GET_ALL_NOTICES {
    GET_ALL_NOTICES {
      id
      autor
      tittle
      Descripcion
      fecha
      projects
      fechaAdd
      Archivo
      user{
        Fullname
        img
      }
    }
  }
`;

export const GET_USER_HOLIDAYS = gql`
  query GET_USER_HOLIDAYS($id: ID!) {
    GET_USER_HOLIDAYS(id: $id) {
      holidays_total
      economic_total
      economic_used
      holidays_used
    }
  }
`;

export const CREATE_POSITION_HISTORY = gql`
  mutation CREATE_POSITION_HISTORY($id: ID!, $currentPosition: ID!, $previousPosition: ID!, $userAdd: String) {
    CREATE_POSITION_HISTORY(id: $id, currentPosition: $currentPosition, previousPosition: $previousPosition, userAdd: $userAdd)
  }
`;

export const GET_TIMELINE_EVENTS = gql`
  query GET_TIMELINE_EVENTS($id: ID!){
    GET_TIMELINE_EVENTS(id: $id) {
      year
      events{
        event
        date
        title
        amount
        previousAmount
        datePrevious
        dateCurrent
        currentPosition
        previousPosition
      }
    }
  }
`;

export const CREATE_NOTICES = gql`
  mutation CREATE_NOTICES($input: notices_Input) {
    CREATE_NOTICES(input: $input){
      id
    }
  }
`;

export const UPDATE_NOTICES = gql`
  mutation UPDATE_NOTICES($updateNoticesId: ID!, $input: notices_Input) {
    UPDATE_NOTICES(id: $updateNoticesId, input: $input)
  }
`;

export const DELETE_NOTICES = gql`
  mutation DELETE_NOTICES($deleteNoticesId: ID!) {
    DELETE_NOTICES(id: $deleteNoticesId)
  }
`;

export const CREATE_ARCHIVE_INCIDENT = gql`
  mutation CREATE_ARCHIVE_INCIDENT($input: archive_Incident_Input) {
    CREATE_ARCHIVE_INCIDENT(input: $input) {
      idIncident
      URL
      fromto
      name
    }
  }
`;
export const CONVERT_USERS = gql`
  mutation CONVERT_USERS($id: ID!, $input: users_Input_Reactivate) {
    CONVERT_USERS(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const GET_ALL_EVENT_CALENDAR_BY_DATE = gql`
  query GET_ALL_EVENT_CALENDAR_BY_DATE($calendarId: Int!, $initDate: String!) {
    GET_ALL_EVENT_CALENDAR_BY_DATE(
      calendarId: $calendarId
      initDate: $initDate
    ) {
      id
      name
      initDate
      endDate
      eventType
    }
  }
`;

export const CREATE_REGISTROPATRONAL = gql`
  mutation CREATE_REGISTROPATRONAL($input: input_Registro_Pratronal) {
    CREATE_REGISTROPATRONAL(input: $input) {
      id
    }
  }
`;

export const GET_REGISTROPATRONAL = gql`
  query GET_REGISTROPATRONAL($enterpriseId: Int!) {
    GET_REGISTROPATRONAL(enterpriseId: $enterpriseId) {
      id
      enterpriseId
      registroPatronal
      primaRiesgo
      riskPlaced
    }
  }
`;

export const UPDATE_ENTERPRISE_WITHOUT_FILES = gql`
  mutation UPDATE_ENTERPRISE_WITHOUT_FILES(
    $id: ID!
    $input: enterprise_Input_Without_File
  ) {
    UPDATE_ENTERPRISE_WITHOUT_FILES(id: $id, input: $input) {
      name      
      taxRegime
      propertyRegime
      surcharge
      state
      address
      zipCode
      bankaccount
      IMSSSubdelegationKey
      extrahours
      automaticCalculationsVariables
      useSTPaspaymentmethod
      STPaccount
      stpCLABE
      IMSSminimumwage
      operationsIMSSSender
      CertificateOfUserIMSS
      CertificatePaswordIMSS
      IMSSCertificate
      RazonSocial
      NoCertificado
      userIMSS
    }
  }
`;

export const DELETE_REGISTROPATRONAL = gql`
  mutation DELETE_REGISTROPATRONAL($id: ID!) {
    DELETE_REGISTROPATRONAL(id: $id)
  }
`;

export const GET_ALL_REGIMENFISCAL = gql`
  query {
    GET_ALL_REGIMENFISCAL {
      id
      clave
      Descripcion
    }
  }
`;

export const GET_USER_NUEVOINGRESO_REGISTER = gql`
  query GET_USER_NUEVOINGRESO_REGISTER($token: String!) {
    GET_USER_NUEVOINGRESO_REGISTER(token: $token) {
      id
      name
      firstName
      lastName
      fullName
      dateOfBirth
      email
      dateOfAdmission
      userType
      project
      campus
      client
      password
      active
      token
      linkedAccount
      cellphone
      area
      tittle
      officePlace
      bussinesName
      contractName
      netSalary
      grossSalary
      DS
      ISD
      nominesPayroll
      paymentPeridicity
      journey
      comment
      userStatus
      expiredAlta
      expiredPersonales
      currentToken
      host
      address
      municipality
      ZC
      state
      country
      placeOfBirth
      nacionality
      gender
      civilStatus
      children
      educationalLevel
      ClaveElectoralPasaporte
      IMSS
      CURP
      workPermission
      creditoInfonavit
      benefitiary
      emergencyContact
      diseases
      bank
      accountNumber
      clabeNum
      status
      RFC
      id_payroll_group
      SDG
      id_User
      id_Bank
      done
      collaborator
      drop
      baja
      Puesto
      Company
      suburb
      benefitiaryCountry
      benefitiaryState
      benefitiaryMunicipality
      benefitiaryCURP
      ParentescoB
      benefitiaryDateOfBirth
      benefitiaryZC
      benefitiaryAddress
      benefitiarySuburb
      ParentescoCE
      cellphoneCE
      phoneCE
      suburbCE
      addressCE
      Alergias
      PadEnfer
      Cirugias
      TraMeRe
      dropDate
      dropType
      archive {
        URL
        id
        idUsuario
        fromto
        name
        idTypeArchive
      }
      dateContractDate
      typeContract
      totalSalary
      recordableSalary
      notRecordableSalary
      typeSalary
      EmployerRegister
      idEnterprise
      id_payroll_group
      ActaNacimiento_PDF
      ComprobanteEstudios_PDF
      ComprobanteDomicilio_PDF
      IdentificacionOficial_PDF
      RFC_PDF
      CURP_PDF
      ComprobanteNSS_PDF
      Foto_IMG
      AvisoRetencion_PDF
      CartaGMM_PDF
      CartaOfertaFirmada
      AGREEMENT_PDF
      CartaOferta
      isAvisoRetencion
      isCartaGMM
      CuentaBancaria_PDF
      register_process {
        tab
        section_1
        section_2
        section_3
        percent
        completed
      }
      userAdd
      accept_info
      puestoId
      idJourney
      unionized
      calendarId
      ZCSAT
      idProject
    }
  }
`;

export const UPDATE_PUESTO_USERS = gql`
  mutation UPDATE_PUESTO_USERS($puestoId: Int!) {
    UPDATE_PUESTO_USERS(id: $puestoId)
  }
`;

export const GET_PROJECT = gql`
  query ($id: ID!) {
    GET_PROJECT(id: $id) {
      id
      proyectName
      client
      sede
      idClient
      idSede
      status
    }
  }
`;

export const GET_SEDE = gql`
  query ($id: ID!) {
    GET_SEDE(id: $id) {
      id
      sedeName
    }
  }
`;

export const GET_SUBORDINADOS_PUESTOS = gql`
  query {
    GET_SUBORDINADOS_PUESTOS {
      subordinados
      id
    }
  }
`;

export const UPDATE_PAYROLL_COLLABORATOR_XML = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_XML($id: ID!, $input: xml_Input) {
  UPDATE_PAYROLL_COLLABORATOR_XML(id: $id, input: $input){
    id
  }
}
`;

export const UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE($id: ID!, $tokenTimbre: String!) {
  UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE(id: $id, tokenTimbre: $tokenTimbre)
}
`;

export const UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN($id: ID!, $tokenTimbre: String!, $userAdd: String!) {
  UPDATE_PAYROLL_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN(id: $id, tokenTimbre: $tokenTimbre, userAdd: $userAdd)
}
`;


export const GET_MODIFICACIONSALARIO = gql`
  query GET_MODIFICACIONSALARIO {
    GET_MODIFICACIONSALARIO {
      id
      Nombre
      SCB
      HETriple
      Puntualidad
      SobreSueldo
      Descanso
      PrimaDominical
      Incentivos
      Comisiones
      ViaticosGravados
      NuevoSBC
    }
  }
`;


export const UPDATE_MODIFICACIONSALARIO = gql`
mutation UPDATE_MODIFICACIONSALARIO($updateModificacionsalarioId: ID) {
  UPDATE_MODIFICACIONSALARIO(id: $updateModificacionsalarioId)
}
`;

export const UPDATE_USERS_PERMISSIONS = gql`
  mutation UPDATE_USERS_PERMISSIONS($id: ID!, $input: users_permissions_input) {
    UPDATE_USERS_PERMISSIONS(id: $id, input: $input)
  }
`;
export const GET_PERMISSIONS_USER = gql`
  query GET_PERMISSIONS_USER($getUsersId: ID!) {
    GET_PERMISSIONS_USER(id: $getUsersId) {
      name
      status
    }
  }
`;
export const GET_ALL_USER_ADMIN = gql`
  query GET_ALL_USER_ADMIN {
    GET_ALL_USER_ADMIN {
      FullName: Fullname
      Usuario
      img
      _id: id
    }
  }
`;
export const CREATE_ADMIN_USERS = gql`
  mutation CREATE_ADMIN_USERS($input: users_admin_input) {
    CREATE_ADMIN_USERS(input: $input)
  }
`;

export const GET_ALL_USER = gql`
  query {
    GET_ALL_USER {
      _id: id
      ApellidoUsuario: firstName
      PrimerNombre: name
      lastName
      FullName
      Usuario: email
      id_User
      id_Bank
      ImagenUsuario
      NombreUsuario: name
      id_payroll_group
    }
  }
`;

export const GET_ALL_USER_EVENTUAL = gql`
  query {
    GET_ALL_USER_EVENTUAL {
      _id: id
      ApellidoUsuario: firstName
      lastName
      Usuario: email
      id_payroll_group
      PrimerNombre: name
      NombreUsuario: name
      ValeDespensa
      collaborator
      baja
    }
  }
`;

export const GET_USER_MOVEMENT_ALTA = gql`
query GET_USER_MOVEMENT_ALTA($id: Int!) {
  GET_USER_MOVEMENT_ALTA(id:$id) {
    Externo
    solicitudIDSE
  }
}
`;

export const GET_ALL_USERS_SETTLEMENT = gql`
  query {
    GET_ALL_USERS_SETTLEMENT {
      id
      ApellidoUsuario: firstName
      PrimerNombre: name
      lastName
      Usuario: email
      id_User
      id_Bank
      name
      id_payroll_group
      nominesPayroll
    }
  }
`;

export const UPDATE_TOKEN_EVENTUAL = gql`
  mutation UPDATE_TOKEN_EVENTUAL($id: ID!, $token: String!) {
    UPDATE_TOKEN_EVENTUAL(id: $id, token: $token)
  }
`;

export const GET_PAYROLLGROUP_POLITIC = gql`
  query GET_PAYROLLGROUP_POLITIC {
    GET_PAYROLLGROUP_POLITIC {
      id
      group_name
      Eliminar
  }
}
`;

export const GET_POLITIC_SAVING_BY_PAYROLLGROUP = gql`
  query GET_POLITIC_SAVING_BY_PAYROLLGROUP($payrollGroupId: ID!) {
    GET_POLITIC_SAVING_BY_PAYROLLGROUP(payrollGroupId: $payrollGroupId) {
      id
      saving_fund
      saving_fund_cap
      saving_fund_type
  }
}
`;

export const GET_ALL_MYMOOD = gql`
  query GET_ALL_MYMOOD {
    GET_ALL_MYMOOD {
      id
      fullName
      moodName
      idUsuario{
        name
        firstName
        lastName
        ImagenUsuario
      }
      idMood{
        Nombre
      }
      Comentario
      Fecha
      status
      idProyecto
  }
}
`;

export const GET_TODAY_MYMOOD = gql`
  query GET_TODAY_MYMOOD($startDate: String, $endDate: String) {
    GET_TODAY_MYMOOD(startDate: $startDate, endDate: $endDate) {
      id
      fullName
      moodName
      idUsuario{
        name
        firstName
        lastName
      }
      idMood{
        Nombre
      }
      Comentario
      Fecha
      status
      idProyecto
  }
}
`;

export const GET_MYMOOD_PROJECTS = gql`
  query GET_MYMOOD_PROJECTS($type: String!, $startDate: String, $endDate: String) {
    GET_MYMOOD_PROJECTS(type: $type, startDate: $startDate, endDate: $endDate) {
      data 
      finalArrayStates {
        backgroundColor
        data
        label
        type
        borderColor
        fill
      }
      projects{
        projectName
        count
      }
  }
}
`;

export const GET_PAYROLLYEAR = gql`
  query GET_PAYROLLYEAR {
    GET_PAYROLLYEAR {
      year
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE = gql`
  query GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE(
    $getPayrollcollaboratorTimbradoId: ID!
  ) {
    GET_PAYROLLCOLLABORATOR_CANCEL_TIMBRE(id: $getPayrollcollaboratorTimbradoId) {
      id
      colaborator
      perception
      deduction
      netIncomeTaxable
      UUID
      tokenTimbre
      xml
      users {
        RFC
      }
      enterprise {
        RazonSocial
      }
      CancelaSATfecha
      AcuseCancelacion
      LinkPDF
    }
  }
`;

export const UPDATE_PAYROLL_COLLABORATOR_TOKENCANCELATIMBRE = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_TOKENCANCELATIMBRE($id: ID!, $tokenTimbre: String!) {
  UPDATE_PAYROLL_COLLABORATOR_TOKENCANCELATIMBRE(id: $id, tokenTimbre: $tokenTimbre)
}
`;

export const UPDATE_PAYROLL_COLLABORATOR_XML_ACUSE = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_XML_ACUSE($id: ID!, $input: acuseXML_Input) {
  UPDATE_PAYROLL_COLLABORATOR_XML_ACUSE(id: $id, input: $input){
    id
  }
}
`;


export const DALETE_PAYROLL_COLLABORATOR = gql`
mutation DALETE_PAYROLL_COLLABORATOR($id: ID!, $input: delete_Input) {
  DALETE_PAYROLL_COLLABORATOR(id: $id, input: $input){
    id
  }
}
`;

export const GET_ALL_BANK = gql`
 query GET_ALL_BANK {
    GET_ALL_BANK{
      id
      name
      clabe
    }
  }
`;

export const UPDATE_PAYROLL_COLLABORATOR_DELETE = gql`
mutation UPDATE_PAYROLL_COLLABORATOR_DELETE($id: ID!) {
  UPDATE_PAYROLL_COLLABORATOR_DELETE(id: $id)
}
`;

export const UPDATE_MONTHLYISR_COLLABORATOR_DELETE = gql`
mutation UPDATE_MONTHLYISR_COLLABORATOR_DELETE($id: ID!) {
  UPDATE_MONTHLYISR_COLLABORATOR_DELETE(id: $id)
}
`;

export const UPDATE_EVENTUAL_COLLABORATOR_DELETE = gql`
mutation UPDATE_EVENTUAL_COLLABORATOR_DELETE($id: ID!) {
  UPDATE_EVENTUAL_COLLABORATOR_DELETE(id: $id)
}
`;

export const GET_ALL_TIPOJORNADA = gql`
  query GET_ALL_TIPOJORNADA {
    GET_ALL_TIPOJORNADA {
      id
      clave
      Descripcion
    } 
  }
`;

export const GET_ALL_SCHEDULE_BY_MONTH = gql`
query GET_ALL_SCHEDULE_BY_MONTH($date: String!) {
  GET_ALL_SCHEDULE_BY_MONTH(date:$date
  ) {
    name
    initDate
    description
    Tipo: eventType
  }
}
`;

export const GET_PAYROLLCOLLABORATOR_CALENDAR = gql`
  query GET_PAYROLLCOLLABORATOR_CALENDAR {
    GET_PAYROLLCOLLABORATOR_CALENDAR {
      id
      colaborator
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_CALENDAR2 = gql`
  query GET_PAYROLLCOLLABORATOR_CALENDAR2($id: ID!) {
    GET_PAYROLLCOLLABORATOR_CALENDAR2(id: $id) {
      id
      colaborator
    }
  }
`;

export const CREATE_HISTORICOSALARIO  = gql`
mutation CREATE_HISTORICOSALARIO($input: createHistorico_Input) {
  CREATE_HISTORICOSALARIO(input: $input)
}
`;
export const ENVIO_MOVIMIENTO_SALARIO = gql`
mutation ENVIO_MOVIMIENTO_SALARIO($envioMovimientoSalarioId: ID) {
  ENVIO_MOVIMIENTO_SALARIO(id: $envioMovimientoSalarioId)
}
`

export const GET_COLLABORATORS_DISPERSION = gql`
query GET_COLLABORATORS_DISPERSION($getCollaboratorsDispersionId: ID!) {
  GET_COLLABORATORS_DISPERSION(id: $getCollaboratorsDispersionId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    Adelanto
  }
}
`;

export const GET_INFORMACIONBANCARIA = gql`
query    GET_INFORMACIONBANCARIA($getInformacionbancariaId: ID!) {
  GET_INFORMACIONBANCARIA(id: $getInformacionbancariaId) {
    cuentaBancaria
    saldoDisponible
  }
}
`;

export const GET_NON_WORKING_BY_MONTH_YEAR = gql`
query GET_NON_WORKING_BY_MONTH_YEAR($calendarId: Int!, $month: Int!, $year: Int!) {
  GET_NON_WORKING_BY_MONTH_YEAR(calendarId: $calendarId, month: $month, year: $year ) {
    id
    name
    calendarId
    initDate
    endDate
    status
  }
}
`;

export const GET_ALL_TABLEISN = gql`
query GET_ALL_TABLEISN {
  GET_ALL_TABLEISN {
    id
    idEmpresa
    RazonSocial
    Porcentaje
  }
}
`;

export const GET_PAYROLL_DISPERSION_2 = gql`
query GET_PAYROLL_DISPERSION_2($getPayrollDispersionId: ID!, $randomRequest: Float) {
  GET_PAYROLL_DISPERSION_2(id: $getPayrollDispersionId, randomRequest:$randomRequest) {
    Nombre
    fechaInicio
    fechaFin
    porcentajeBarra
    cuentaBancaria
    saldoCuenta
    tokenActual
    numeroRegistrosToken
    resumenAutorizadosOutsourcers
    resumenPorAutorizarOutsourcers
    resumenConRespuestaOutsourcers
    estatusOutsourcers
    dispersionEnProceso
    dispersionGravable
  }
}
`;

export const GET_PAYROLL_DISPERSION_EVENTUAL = gql`
query GET_PAYROLL_DISPERSION_EVENTUAL($getPayrollDispersionId: ID!, $randomRequest: Float) {
  GET_PAYROLL_DISPERSION_EVENTUAL(id: $getPayrollDispersionId, randomRequest:$randomRequest) {
    Nombre
    fechaInicio
    fechaFin
    porcentajeBarra
    cuentaBancaria
    saldoCuenta
    tokenActual
    numeroRegistrosToken
    resumenAutorizadosOutsourcers
    resumenPorAutorizarOutsourcers
    resumenConRespuestaOutsourcers
    estatusOutsourcers
    dispersionEnProceso
    dispersionGravable
  }
}
`;

export const GET_PAYROLL_DISPERSION_FINIQUITO_OUT = gql`
query GET_PAYROLL_DISPERSION_FINIQUITO_OUT($getPayrollDispersionId: ID!, $randomRequest: Float) {
  GET_PAYROLL_DISPERSION_FINIQUITO_OUT(id: $getPayrollDispersionId, randomRequest:$randomRequest) {
    Nombre
    fechaInicio
    fechaFin
    porcentajeBarra
    cuentaBancaria
    saldoCuenta
    tokenActual
    numeroRegistrosToken
    resumenAutorizadosOutsourcers
    resumenPorAutorizarOutsourcers
    resumenConRespuestaOutsourcers
    estatusOutsourcers
    dispersionEnProceso
    dispersionGravable
  }
}
`;

export const GET_PAYROLL_DISPERSAR_EVENTUAL = gql`
query GET_PAYROLL_DISPERSAR_EVENTUAL($getPayrollDispersionId: ID!, $randomRequest: Float) {
  GET_PAYROLL_DISPERSAR_EVENTUAL(id: $getPayrollDispersionId, randomRequest:$randomRequest) {
    Nombre
    fechaInicio
    fechaFin
    porcentajeBarra
    cuentaBancaria
    saldoCuenta
    tokenActual
    numeroRegistrosToken
    resumenAutorizadosOutsourcers
    resumenPorAutorizarOutsourcers
    resumenConRespuestaOutsourcers
    estatusOutsourcers
    dispersionEnProceso
    dispersionGravable
      }
    }
`;

export const GET_DISPERSAR_FINIQUITOS_OUTSOURCES = gql`
query GET_DISPERSAR_FINIQUITOS_OUTSOURCES($getPayrollDispersionId: ID! ) {
  GET_DISPERSAR_FINIQUITOS_OUTSOURCES(id: $getPayrollDispersionId ) {
    # Nombre
    # fechaInicio
    # fechaFin
    # porcentajeBarra
    # cuentaBancaria
    # saldoCuenta
    # tokenActual
    # numeroRegistrosToken
    resumenAutorizadosOutsourcers
    resumenPorAutorizarOutsourcers
    resumenConRespuestaOutsourcers
    estatusOutsourcers
    dispersionEnProceso
    dispersionGravable
      }
    }
`;



export const GET_PAYROLL_DISPERSAR_FINIQUITO = gql`
query GET_PAYROLL_DISPERSAR_FINIQUITO($getPayrollDispersarFiniquitoId: ID!) {
  GET_PAYROLL_DISPERSAR_FINIQUITO(id: $getPayrollDispersarFiniquitoId) {
    Nombre
    fechaInicio
    fechaFin
    porcentajeBarra
    cuentaBancaria
    saldoCuenta
  }
}
`



export const DELETE_ADMIN_USERS = gql`
mutation DELETE_ADMIN_USERS($id:ID!) {
  DELETE_ADMIN_USERS(id:$id)
  }
`;

export const GET_INCIDENT_PAYROLL_COLLABORATOR = gql`
query GET_INCIDENT_PAYROLL_COLLABORATOR(
  $idPayroll: Int!
  $idCollaborator: Int!
) {
  GET_INCIDENT_PAYROLL_COLLABORATOR(
    idPayroll: $idPayroll
    idCollaborator: $idCollaborator
  ) {
    id
    idPayroll
    idCollaborator
    TypeConcept
    Incident_type
    Grabable
    Total
    Concepto
    Clave
    Horas
    InitDate
  }
}`

export const GET_INCIDENT_PAYROLL = gql`
query GET_INCIDENT_PAYROLL(
  $idPayroll: Int!
) {
  GET_INCIDENT_PAYROLL(
    idPayroll: $idPayroll
  ) {
    id
    idPayroll
    idCollaborator
    TypeConcept
    Incident_type
    Grabable
    Total
    Concepto
    Clave
    Horas
    InitDate
    Comentarios
    archive
    EndDate
    Dias
    Economicos
    idAdelanto
  }
}`

export const GET_INCIDENT_SETTLEMENT = gql`
query GET_INCIDENT_SETTLEMENT(
  $idSettlement: Int!
) {
  GET_INCIDENT_SETTLEMENT(
    idSettlement: $idSettlement
  ) {
    id
    idSettlement
    idCollaborator
    Incident_type
    Total
    Taxable
    NotTaxable 
    Mixed
    idConcept
    TypeConcept
    Grabable
    Concepto
    Clave
  }
}`


export const DELETE_INCIDENT = gql`
mutation DELETE_INCIDENT($id: ID!, $input: delete_incidencias) {
  DELETE_INCIDENT(id:$id, input: $input)
}`

export const DELETE_INCIDENT_SETTLEMENT = gql`
mutation DELETE_INCIDENT_SETTLEMENT($id: ID!, $input: delete_incidenciasSettlement) {
  DELETE_INCIDENT_SETTLEMENT(id:$id, input: $input)
}`

export const GET_ALL_RIESGOPUESTO = gql`
query {
  GET_ALL_RIESGOPUESTO {
    id
    code
    name
  }
}`;

export const DALETE_PAYROLL = gql`
mutation DALETE_PAYROLL($input: payroll_finish) {
  DALETE_PAYROLL(input: $input)
}`;

export const GET_SETTLEMENTCOLLABORATORTIMBRADO = gql`
  query GET_SETTLEMENTCOLLABORATORTIMBRADO(
    $getSettlementPayrollcollaboratorTimbradoId: ID!
  ) {
    GET_SETTLEMENTCOLLABORATORTIMBRADO(id: $getSettlementPayrollcollaboratorTimbradoId) {
      id
      colaborator
      perception
      deduction
      netIncomeTaxable :total
      UUID
      tokenTimbre
      xml
      RFC
      status
      dispersionStatus
      LinkPDF
      AcuseCancelacion
      PDF_back
      Tipo
    }
  }
`;

export const UPDATE_SETTLEMENTCOLLABORATOR_TOKENTIMBRE = gql`
mutation UPDATE_SETTLEMENTCOLLABORATOR_TOKENTIMBRE($id: ID!, $tokenTimbre: String!) {
  UPDATE_SETTLEMENTCOLLABORATOR_TOKENTIMBRE(id: $id, tokenTimbre: $tokenTimbre)
}
`;

export const UPDATE_SETTLEMENTCOLLABORATOR_XML = gql`
mutation UPDATE_SETTLEMENTCOLLABORATOR_XML($id: ID!, $input: xml_settlement_Input) {
  UPDATE_SETTLEMENTCOLLABORATOR_XML(id: $id, input: $input){
    id: Folio
  }
}
`;

export const GET_PAYROLLGRAPHIC_FINALBALANCES = gql`
query GET_PAYROLLGRAPHIC_FINALBALANCES($getPayrollgraphicFinalbalancesId: ID!) {
  GET_PAYROLLGRAPHIC_FINALBALANCES(id: $getPayrollgraphicFinalbalancesId) {
    SaldoInicial
    MontoDispersado
    SaldoFinal
    PorcentajeFinal
  }
}`

export const GET_PAYROLLGRAPHIC_TAXESCONTRIBUTIONS = gql`
query GET_PAYROLLGRAPHIC_TAXESCONTRIBUTIONS($getPayrollgraphicTaxescontributionsId: ID!) {
  GET_PAYROLLGRAPHIC_TAXESCONTRIBUTIONS(id: $getPayrollgraphicTaxescontributionsId) {
    Total
    IMSS
    Infonavit
    ISR
  }
}`

export const GET_PAYROLLGRAPHIC_INCIDENTS = gql`
query GET_PAYROLLGRAPHIC_INCIDENTS($getPayrollgraphicIncidentsId: ID!) {
  GET_PAYROLLGRAPHIC_INCIDENTS(id: $getPayrollgraphicIncidentsId) {
    DosMeses
    PercepcionDosMeses
    DeduccionDosMeses
    UnMes
    PercepcionUnMes
    DeduccionUnMes
    MesActual
    PercepcionMesActual
    DeduccionMesActual
  }
}`

export const GET_PAYROLLGRAPHIC_COLLABORATOR = gql`
query GET_PAYROLLGRAPHIC_COLLABORATOR($getPayrollgraphicCollaboratorId: ID!) {
  GET_PAYROLLGRAPHIC_COLLABORATOR(id: $getPayrollgraphicCollaboratorId) {
    TotalColaboradores
    ColaboradoresBaja
    PorcentajeBajas
    ColaboradoresIncidencias
    PorcentajeIncidencias
    PorcentajeAlta
    ColaboradoresAlta
  }
}`

export const GET_EVENTUALCOLLABORATORTIMBRADO = gql`
  query GET_EVENTUALCOLLABORATORTIMBRADO(
    $getSettlementPayrollcollaboratorTimbradoId: ID!
  ) {
    GET_EVENTUALCOLLABORATORTIMBRADO(id: $getSettlementPayrollcollaboratorTimbradoId) {
      id
      colaborator
      perception
      deduction
      netIncomeTaxable :total
      UUID
      tokenTimbre
      xml
      RFC
      status
      dispersionStatus
      LinkPDF
      AcuseCancelacion
      PDF_back
      RazonSocial
      CancelaSATfecha
    }
  }
`;

export const UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE = gql`
mutation UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE($id: ID!, $tokenTimbre: String!) {
  UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE(id: $id, tokenTimbre: $tokenTimbre)
}
`;

export const UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN = gql`
mutation UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN($id: ID!, $tokenTimbre: String!, $userAdd: String!) {
  UPDATE_EVENTUALCOLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN(id: $id, tokenTimbre: $tokenTimbre, userAdd: $userAdd)
}
`;

export const UPDATE_EVENTUALCOLLABORATOR_XML = gql`
mutation UPDATE_EVENTUALCOLLABORATOR_XML($id: ID!, $input: xml_Eventual_Input) {
  UPDATE_EVENTUALCOLLABORATOR_XML(id: $id, input: $input){
    id
  }
}
`;


export const FINISH_SETTLEMENTPAYROLL = gql`
mutation FINISH_SETTLEMENTPAYROLL($input: payroll_finishSettlement) {
  FINISH_SETTLEMENTPAYROLL(input: $input)
}`;


export const FINISH_EVENTUALPAYROLL = gql`
mutation FINISH_EVENTUALPAYROLL($input: payroll_finishEventual) {
  FINISH_EVENTUALPAYROLL(input: $input)
}`;

export const GET_PAYROLLEVENTUALGRAPHIC_FINALBALANCES = gql`
query GET_PAYROLLEVENTUALGRAPHIC_FINALBALANCES($getPayrollgraphicFinalbalancesId: ID!) {
  GET_PAYROLLEVENTUALGRAPHIC_FINALBALANCES(id: $getPayrollgraphicFinalbalancesId) {
    SaldoInicial
    MontoDispersado
    SaldoFinal
    PorcentajeFinal
  }
}`;

export const GET_PAYROLLEVENTUALGRAPHIC_TAXESCONTRIBUTIONS = gql`
query GET_PAYROLLEVENTUALGRAPHIC_TAXESCONTRIBUTIONS($getPayrollgraphicTaxescontributionsId: ID!) {
  GET_PAYROLLEVENTUALGRAPHIC_TAXESCONTRIBUTIONS(id: $getPayrollgraphicTaxescontributionsId) {
    Total
    IMSS
    Infonavit
    ISR
  }
}`;

export const GET_PAYROLLEVENTUALGRAPHIC_INCIDENTS = gql`
query GET_PAYROLLEVENTUALGRAPHIC_INCIDENTS($getPayrollgraphicIncidentsId: ID!) {
  GET_PAYROLLEVENTUALGRAPHIC_INCIDENTS(id: $getPayrollgraphicIncidentsId) {
    DosMeses
    PercepcionDosMeses
    DeduccionDosMeses
    UnMes
    PercepcionUnMes
    DeduccionUnMes
    MesActual
    PercepcionMesActual
    DeduccionMesActual
  }
}`;

export const GET_PAYROLLEVENTUALGRAPHIC_COLLABORATOR = gql`
query GET_PAYROLLEVENTUALGRAPHIC_COLLABORATOR($getPayrollgraphicCollaboratorId: ID!) {
  GET_PAYROLLEVENTUALGRAPHIC_COLLABORATOR(id: $getPayrollgraphicCollaboratorId) {
    TotalColaboradores
    ColaboradoresBaja
    PorcentajeBajas
    ColaboradoresIncidencias
    PorcentajeIncidencias
  }
}`;

export const GET_PAYROLLSETTLEMENTGRAPHIC_FINALBALANCES = gql`
query GET_PAYROLLSETTLEMENTGRAPHIC_FINALBALANCES($getPayrollgraphicFinalbalancesId: ID!) {
  GET_PAYROLLSETTLEMENTGRAPHIC_FINALBALANCES(id: $getPayrollgraphicFinalbalancesId) {
    SaldoInicial
    MontoDispersado
    SaldoFinal
    PorcentajeFinal
  }
}`;

export const GET_PAYROLLSETTLEMENTGRAPHIC_TAXESCONTRIBUTIONS = gql`
query GET_PAYROLLSETTLEMENTGRAPHIC_TAXESCONTRIBUTIONS($getPayrollgraphicTaxescontributionsId: ID!) {
  GET_PAYROLLSETTLEMENTGRAPHIC_TAXESCONTRIBUTIONS(id: $getPayrollgraphicTaxescontributionsId) {
    Total
    IMSS
    Infonavit
    ISR
  }
}`;

export const GET_PAYROLLSETTLEMENTGRAPHIC_INCIDENTS = gql`
query GET_PAYROLLSETTLEMENTGRAPHIC_INCIDENTS($getPayrollgraphicIncidentsId: ID!) {
  GET_PAYROLLSETTLEMENTGRAPHIC_INCIDENTS(id: $getPayrollgraphicIncidentsId) {
    DosMeses
    PercepcionDosMeses
    DeduccionDosMeses
    UnMes
    PercepcionUnMes
    DeduccionUnMes
    MesActual
    PercepcionMesActual
    DeduccionMesActual
  }
}`;

export const GET_PAYROLLSETTLEMENTGRAPHIC_COLLABORATOR = gql`
query GET_PAYROLLSETTLEMENTGRAPHIC_COLLABORATOR($getPayrollgraphicCollaboratorId: ID!) {
  GET_PAYROLLSETTLEMENTGRAPHIC_COLLABORATOR(id: $getPayrollgraphicCollaboratorId) {
    TotalColaboradores
    ColaboradoresBaja
    PorcentajeBajas
    ColaboradoresIncidencias
    PorcentajeIncidencias
  }
}`;



export const CREATE_ENTERPRISEEVENT = gql`
mutation CREATE_ENTERPRISEEVENT($input: enterprise_EventInput) {
  CREATE_ENTERPRISEEVENT(input: $input) {
    id
    enterpriseid 
    eventid 
    userAdd  
    dateAdd
    status
    userDelete
    dateDelete
  }
}
`;

export const GET_ALL_PROJECT_EVENT = gql`
  query {
    GET_ALL_PROJECT_EVENT {
      id
      proyectName
      client
      sede
      idClient
      idSede
      status
      users {
        id
        name
      }
      puestos{
        id
      }
    }
  }
`;

export const GET_PAYROLLHISTORIC = gql`
  query GET_PAYROLLHISTORIC($init_date: String!) {
    GET_PAYROLLHISTORIC(init_date: $init_date) {
      id
      group_name
      payroll_type
      init_date
      end_date
      employees
      total
      Historic_name
      Colaborators{
        idPayRollColaborator
        colaborator
        email
      }
    }
  }
`;


export const GET_PAYROLLEVENT_REPO_CANCEL = gql`
  query GET_PAYROLLEVENT_REPO_CANCEL($id: ID!) {
    GET_PAYROLLEVENT_REPO_CANCEL(id: $id) {
      id
      group_name
      payroll_type
      init_date
      end_date
      employees
      total
    }
  }
`;

export const GET_EVENTCOLLABORATOR_CANCEL_TIMBRE = gql`
  query GET_EVENTCOLLABORATOR_CANCEL_TIMBRE(
    $getPayrollcollaboratorTimbradoId: ID!
  ) {
    GET_EVENTCOLLABORATOR_CANCEL_TIMBRE(id: $getPayrollcollaboratorTimbradoId) {
      id
      colaborator
      perception
      deduction
      total
      UUID
      tokenTimbre
      xml
      users {
        RFC
      }
      enterprise {
        RazonSocial
      }
      CancelaSATfecha
      AcuseCancelacion
    }
  }
`;

export const UPDATE_EVENTUAL_COLLABORATOR_TOKENCANCELATIMBRE = gql`
mutation UPDATE_EVENTUAL_COLLABORATOR_TOKENCANCELATIMBRE($id: ID!, $tokenTimbre: String!) {
  UPDATE_EVENTUAL_COLLABORATOR_TOKENCANCELATIMBRE(id: $id, tokenTimbre: $tokenTimbre)
}
`;

export const UPDATE_EVENTUAL_COLLABORATOR_XML_ACUSE = gql`
mutation UPDATE_EVENTUAL_COLLABORATOR_XML_ACUSE($id: ID!, $input: acuseXML_Input_Eventual) {
  UPDATE_EVENTUAL_COLLABORATOR_XML_ACUSE(id: $id, input: $input){
    id
  }
}
`;

export const DALETE_EVENTUAL_COLLABORATOR = gql`
mutation DALETE_EVENTUAL_COLLABORATOR($id: ID!, $input: delete_Input_Event) {
  DALETE_EVENTUAL_COLLABORATOR(id: $id, input: $input){
    id
  }
}
`;


export const GET_PAYROLLSETTELEMNT_REPO_CANCEL = gql`
  query GET_PAYROLLSETTELEMNT_REPO_CANCEL($id: ID!) {
    GET_PAYROLLSETTELEMNT_REPO_CANCEL(id: $id) {
      id
      group_name
      payroll_type
      init_date
      end_date
      employees
      total
    }
  }
`;

export const GET_SETTLEMENTCOLLABORATOR_CANCEL_TIMBRE = gql`
  query GET_SETTLEMENTCOLLABORATOR_CANCEL_TIMBRE(
    $getPayrollcollaboratorTimbradoId: ID!
  ) {
    GET_SETTLEMENTCOLLABORATOR_CANCEL_TIMBRE(id: $getPayrollcollaboratorTimbradoId) {
      id
      colaborator
      perception
      deduction
      total
      UUID
      tokenTimbre
      xml
      RFC
      RazonSocial
      CancelaSATfecha
      AcuseCancelacion
      LinkPDF
      Tipo
    }
  }
`;

export const UPDATE_SETTLEMENT_COLLABORATOR_TOKENCANCELATIMBRE = gql`
mutation UPDATE_SETTLEMENT_COLLABORATOR_TOKENCANCELATIMBRE($id: ID!, $tokenTimbre: String!) {
  UPDATE_SETTLEMENT_COLLABORATOR_TOKENCANCELATIMBRE(id: $id, tokenTimbre: $tokenTimbre)
}
`;

export const UPDATE_SETTLMENT_COLLABORATOR_XML_ACUSE = gql`
mutation UPDATE_SETTLMENT_COLLABORATOR_XML_ACUSE($id: ID!, $input: acuseXML_Input_Settlement) {
  UPDATE_SETTLMENT_COLLABORATOR_XML_ACUSE(id: $id, input: $input){
    id
  }
}
`;

export const DALETE_SETTLEMENT_COLLABORATOR = gql`
mutation DALETE_SETTLEMENT_COLLABORATOR($id: ID!, $input: delete_Input_Settlement) {
  DALETE_SETTLEMENT_COLLABORATOR(id: $id, input: $input){
    id
  }
}`

export const  GET_EXCEL_TAXES_REPORT = gql`
query GET_EXCEL_TAXES_REPORT($input:taxes_report ) {
  GET_EXCEL_TAXES_REPORT(input:$input)
}
`;

export const MOVIMIENTO_EXTERNO = gql`
mutation MOVIMIENTO_EXTERNO($movimientoExternoId: ID) {
  MOVIMIENTO_EXTERNO(id: $movimientoExternoId)
}
`;

export const GET_ENTERPRISE_EVENT = gql`
  query GET_ENTERPRISE_EVENT(
    $eventid: Int!
  ) {
    GET_ENTERPRISE_EVENT(eventid: $eventid) {
      id
      enterpriseid
      eventid
      userAdd
      dateAdd
      status
      proyectName
    }
  }
`;

export const UPDATE_PENTERPRISE_EVENT= gql`
mutation UPDATE_PENTERPRISE_EVENT($input: update_enterprise_EventInput) {
  UPDATE_PENTERPRISE_EVENT(input:$input){
    id
  }
}
`;

export const GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR = gql`
query GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR($calendarId: Int!, $month: Int!, $year: Int!) {
  GET_ALL_EVENT_CALENDAR_BY_MONTH_YRAR(calendarId: $calendarId, month: $month, year: $year ) {
    id
    name
    initDate
    endDate
    eventType
  }
}
`;

export const GET_PAYROLL_BY_CALENDAR_BY_MONTH_YEAR = gql`
  query GET_PAYROLL_BY_CALENDAR_BY_MONTH_YEAR(
    $calendarId: Int!, $month: Int!, $year: Int!
  ) {
    GET_PAYROLL_BY_CALENDAR_BY_MONTH_YEAR(
      calendarId: $calendarId, month: $month, year: $year
    ) {
      id
      idCollaborator
      collaboratorName
      incident_type_Name
      date_Incident
      date_Incident_End
      idCalendar
      colorCode
    }
  }
`;

export const GET_PAYROLLCOLLABORATOR_DASHBOARD = gql`
  query {
    GET_PAYROLLCOLLABORATOR_DASHBOARD{
      id
      colaborator
    }
  }
`;

export const CREATE_PAYROLL_INCIDENT_DASHBOARD = gql`
  mutation CREATE_PAYROLL_INCIDENT_DASHBOARD(
    $input: payroll_Incident_Input_Calendar
  ) {
    CREATE_PAYROLL_INCIDENT_DASHBOARD(input: $input) {
      id
      idPayroll
      idCollaborator
      Incident_type
      InitDate
      EndDate
      Total
      Taxable
      NotTaxable
      Mixed
      idConcept
      Dias
      Horas
      Comentarios
      TypeConcept
      idCalendar
      Periodico
      FechaPeriodica
    }
  }
`;

export const GET_ALL_BIRTHDAY_BY_MONTH = gql`
query GET_ALL_BIRTHDAY_BY_MONTH {
  GET_ALL_BIRTHDAY_BY_MONTH
  {
    name
    initDate
    Tipo: eventType
    firstName
    ImagenUsuario
  }
}
`;

export const GET_ALL_SCHEDULE_BY_MONTH_YEAR = gql`
query GET_ALL_SCHEDULE_BY_MONTH_YEAR($month: Int!, $year: Int!) {
  GET_ALL_SCHEDULE_BY_MONTH_YEAR(month:$month, year:$year)
  {
    name
    initDate
    description
    Tipo: eventType
    firstName
    ImagenUsuario
  }
}
`;

export const GET_ALL_EVENTUALPAYROLL_SETTINGS = gql`
 query GET_ALL_EVENTUALPAYROLL_SETTINGS {
  GET_ALL_EVENTUALPAYROLL_SETTINGS{
      id
      Reason
      initDate
      endDate
      applyDate
    }
  }
`;

export const GET_ALL_WORKINGHOURS = gql`
 query GET_ALL_WORKINGHOURS {
  GET_ALL_WORKINGHOURS{
      id
      idTypeJourney
      idWeek
      Nombre
      TipodeJornada
      Semana
      HorarioLunes
      HorarioMartes
      HorarioJueves
      HorarioMiercoles
      HorarioViernes
      HorarioSabado
      HorarioDomingo
    }
  }
`;

export const GET_WORKINGHOURS_BYIDCALENDAR = gql`
 query GET_WORKINGHOURS_BYIDCALENDAR($id: ID!) {
  GET_WORKINGHOURS_BYIDCALENDAR(id: $id){
      id
      idTypeJourney
      idWeek
      Nombre
      TipodeJornada
      Semana
      HorarioLunes
      HorarioMartes
      HorarioJueves
      HorarioMiercoles
      HorarioViernes
      HorarioSabado
      HorarioDomingo
    }
 }
`;

export const GET_WORKINGHOURS = gql`
 query GET_WORKINGHOURS($id: ID!) {
  GET_WORKINGHOURS(id: $id){
      id
      Name
      idTypeJourney
      idWeek
      days{
        start
        end
        day
      }
    }
  }
`;

export const UPDATE_WORKINGHOURS = gql`
 mutation UPDATE_WORKINGHOURS($id: ID!, $input: WorkingHours_input) {
  UPDATE_WORKINGHOURS(id: $id, input: $input)
  }
`;

export const DELETE_WORKINGHOURS = gql`
 mutation DELETE_WORKINGHOURS($id: ID!) {
  DELETE_WORKINGHOURS(id: $id)
  }
`;

export const UPDATE_CALENDAR_EVENTUAL_PAYROLL = gql`
  mutation UPDATE_CALENDAR_EVENTUAL_PAYROLL(
    $id: ID!,$input: update_calendar_event_payroll
  ) {
    UPDATE_CALENDAR_EVENTUAL_PAYROLL(id: $id,input: $input) {
      id
      Reason
    }
  }
`;

export const CREATE_WORKINGHOURS = gql`
mutation CREATE_WORKINGHOURS(
  $id: ID!,$input: WorkingHours_input
) {
  CREATE_WORKINGHOURS(id: $id,input: $input)
}
`;

export const UPDATE_SETTLEMENTPAYROLL_STATE = gql`
  mutation UPDATE_SETTLEMENTPAYROLL_STATE($input: payroll_Input_state) {
    UPDATE_SETTLEMENTPAYROLL_STATE(input: $input)
  }
`;


export const CREATE_SETTLEMENT_INCIDENT = gql`
  mutation CREATE_SETTLEMENT_INCIDENT(
    $input: settlement_Incident_Input
  ) {
    CREATE_SETTLEMENT_INCIDENT(input: $input) {
      id
    }
  }`;

export const UPDATE_RECALCULATE_PAYROLL = gql`
  mutation UPDATE_RECALCULATE_PAYROLL($updateRecalculatePayrollId: ID!) {
    UPDATE_RECALCULATE_PAYROLL(id: $updateRecalculatePayrollId)

  }
`;

export const GET_ALL_WEEKPARTTIME = gql`
query {
  GET_ALL_WEEKPARTTIME {
    id
    clave
    name
  }
}`;

export const CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL = gql`
  mutation CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL($input: payroll_Incident_Input) {
    CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL(input: $input) {
      mensaje
      respuesta
    }
  }
`;

export const GET_ALL_PUESTOS_USERS_5 = gql`
query {
  GET_ALL_PUESTOS_USERS_5 {
    idPuesto: id
    NombrePuesto
    AreaProyecto
    PuestoSuperior
    Descripcion
    NombreUsuario: NombreUsuarioPuestoSuperior
    idUsuario: idUsuarioPuestoSuperior
    ImagenUsuario
    users {
      idUsuario: id
      NombreUsuario: bussinesName
      ApellidoUsuario: firstName
      Usuario: email
      cellphone
      area
    }
    level
    subPuestos
    subordinados
  }
}
`;

export const GET_ALL_PUESTOS_USERS_PROJECT = gql`
query {
  GET_ALL_PUESTOS_USERS_PROJECT {
    idPuesto: id
    NombrePuesto
    AreaProyecto
    PuestoSuperior
    Descripcion
    NombreUsuario: NombreUsuarioPuestoSuperior
    idUsuario: idUsuarioPuestoSuperior
    ImagenUsuario
    users {
      idUsuario: id
      NombreUsuario: bussinesName
      ApellidoUsuario: firstName
      Usuario: email
      cellphone
      area
      ImagenUsuario
    }
    level
    subPuestos
    subordinados
    projectId
    higherPostId
    lowerPosition{
      id
      NombrePuesto
      projectId
      AreaProyecto
      colorCode
      colorCodeProject
    }
    colorCode
    colorCodeProject
  }
}
`;

export const CREACION_TOKEN_DISPERSION = gql`
mutation CREACION_TOKEN_DISPERSION($input: creacionToken_Input) {
  CREACION_TOKEN_DISPERSION(input: $input)
}
`

export const GET_LAST_BILL = gql`
query GET_LAST_BILL {
  GET_LAST_BILL {
    CostoColaborador
    dateStart
    dateEnd
    Company
    TotalPago
    SistemaActivo
    saldo
    totalColaboradoresActivos
  }
}`;

export const GET_ALL_PAYMENTS = gql`
query GET_ALL_PAYMENTS {
  GET_ALL_PAYMENTS {
    id
    Total
    Mes
    UUID
    factura
    XML
    Fecha
    idDispersion
    statusDispersion
    comentarioDispersion
    TotalCollaborators
    PDF
    mensajeDispersion
  }
}`;

export const DECLINE_USERS = gql`
  mutation DECLINE_USERS($declineUsersId: ID!, $input: users_comment) {
    DECLINE_USERS(id: $declineUsersId, input: $input)
  }
`;

export const GET_NEXTPAYROLL_DAY = gql`
query GET_NEXTPAYROLL_DAY {
  GET_NEXTPAYROLL_DAY {
    DiasFaltantes
  }
}
`;

export const GET_ALL_PAYROLL_INCIDENTPERIODIC = gql`
query GET_ALL_PAYROLL_INCIDENTPERIODIC {
  GET_ALL_PAYROLL_INCIDENTPERIODIC {
    id
    Colaborador
    Tipo
    FechaGenerado
    FechaFin
    Total
    Dias
    TipoCobro
    TipoConcepto
    Comentarios
    ApprovedRH
    ApprovedDirect
    idAdelanto
  }
}
`;

export const DELETE_PAYROLL_INCIDENTPERIODIC = gql`
mutation DELETE_PAYROLL_INCIDENTPERIODIC($deletePayrollIncidentperiodicId: ID!) {
  DELETE_PAYROLL_INCIDENTPERIODIC(id: $deletePayrollIncidentperiodicId)
}
`;

export const APPROVE_PAYROLL_INCIDENTPERIODIC = gql`
mutation APPROVE_PAYROLL_INCIDENTPERIODIC($approvePayrollIncidentperiodicId: ID!) {
  APPROVE_PAYROLL_INCIDENTPERIODIC(id: $approvePayrollIncidentperiodicId)
}
`;

export const GET_HOLIDAY = gql`
  query GET_HOLIDAYS($id: ID!){
    GET_HOLIDAYS(id: $id){
      id
      AniosTrabajados
      vacaciones
      idPolitic
      status
    }
  }
`

export const GET_HOLIDAYS_TEMPLATE = gql`
  query GET_HOLIDAYS_TEMPLATE{
    GET_HOLIDAYS_TEMPLATE{
      id
      AniosTrabajados
      vacaciones
    }
  }
`

export const GET_ALL_HOLIDAYS = gql`
  query GET_ALL_HOLIDAYS($id: ID!){
    GET_ALL_HOLIDAYS(idPolitic: $id){
      id
      year
      days
      idPolitic
      status
    }
  }
`

export const CREATE_HOLIDAYS = gql`
  mutation CREATE_HOLIDAYS($input: holidays_input){
    CREATE_HOLIDAYS(input: $input){
      id
      year
      days
      idPolitic
      status
    }
  }
`

export const UPDATE_HOLIDAYS = gql`
  mutation UPDATE_HOLIDAYS($id: ID!, $input: holidays_input){
    UPDATE_HOLIDAYS(id: $id, input: $input){
      id
      year
      days
      idPolitic
      status
    }
  }
`

export const DELETE_HOLIDAYS = gql`
  mutation DELETE_HOLIDAYS($id: ID!){
    DELETE_HOLIDAYS(id: $id)
  }
`

export const GET_DISPERSIONACEPTADA_ZIP = gql`
  query GET_DISPERSIONACEPTADA_ZIP(
    $id_payroll: Int!, $type: String!
  ) {
      GET_DISPERSIONACEPTADA_ZIP(id_payroll: $id_payroll, type: $type) {
        id
        fechaHoraConsultaSTP
        Orden
        fechaOperacion
        claveRastreo
        Folio
        Prioridad
        Contraparte
        estado
        tipoPago
        Usuario
        importe
        IVA
        nombreOrdenante
        rfcCurpOrdenante
        tipoCuentaOrdenante
        cuentaOrdenante
        nombreBeneficiario
        rfcCurpBeneficiario
        tipoCuentaBeneficiario
        cuentaBeneficiario
        nombreBeneficiario2
        rfcCurpBeneficiario2
        tipoCuentaBeneficiario2
        cuentaBeneficiario2
        Referencia
        ReferenciaCobranza
        referenciaNumerica
        Concepto
        conceptoPago
        conceptoPago2
        RazonDe
        RazonCancelacion
        causaDevolucion
      }
    }
`;

export const  GET_EXCEL_TAXES_REPORT_BY_PAYROLL_GROUP = gql`
query GET_EXCEL_TAXES_REPORT_BY_PAYROLL_GROUP($input:taxes_report ) {
  GET_EXCEL_TAXES_REPORT_BY_PAYROLL_GROUP(input:$input)
}
`;

export const GET_ALL_PAYROLLREPORT = gql`
  query GET_ALL_PAYROLLREPORT {
    GET_ALL_PAYROLLREPORT {
      RazonSocial
      GrupoNomina
      percepcion
      deduccion
      total
    }
  }
`;


export const GET_USER_COUNT = gql`
  query GET_USER_COUNT {
    GET_USER_COUNT {
      UsuarioTotal
    }
  }
`;

export const GET_SETTLEMENTDISPERSION = gql`
query GET_SETTLEMENTDISPERSION($getSettlementdispersionId: ID) {
  GET_SETTLEMENTDISPERSION(id: $getSettlementdispersionId) {
    Nombre
    porcentajeBarra
    cuentaBancaria
    saldoCuenta
    tokenActual
    numeroRegistrosToken
  }
}
`;

export const SEND_DISPERSION_FACTURACION_PAGOS = gql`
mutation SEND_DISPERSION_FACTURACION_PAGOS($sendDispersionFacturacionPagosId: [dispersion_Input!]) {
  SEND_DISPERSION_FACTURACION_PAGOS(id: $sendDispersionFacturacionPagosId)
}`;

export const GET_EXCEL_INCIDENT_COLLABORATOR = gql`
query GET_EXCEL_INCIDENT_COLLABORATOR {
  GET_EXCEL_INCIDENT_COLLABORATOR {
    idColaborador
    NombreColaborador
  }
}
`
export const GET_EXCEL_INCIDENT_PERCEPTION = gql`
query GET_EXCEL_INCIDENT_PERCEPTION {
  GET_EXCEL_INCIDENT_PERCEPTION {
    idPercepcion
    NombrePercepcion
  }
}
`
export const GET_EXCEL_INCIDENT_DEDUCTION = gql`
query GET_EXCEL_INCIDENT_DEDUCTION {
  GET_EXCEL_INCIDENT_DEDUCTION {
    idDeduccion
    NombreDeduccion
  }
}
`
export const GET_EXCEL_INCIDENT_TYPECONCEPT = gql`
query GET_EXCEL_INCIDENT_TYPECONCEPT {
  GET_EXCEL_INCIDENT_TYPECONCEPT {
    TipoConcepto
  }
}
`
export const GET_EXCEL_INCIDENT_TYPEINCIDENTS = gql`
query GET_EXCEL_INCIDENT_TYPEINCIDENTS {
  GET_EXCEL_INCIDENT_TYPEINCIDENTS {
    TipoIncidencia
  }
}
`

export const GET_EXCEL_INCIDENT_TIPOBASES = gql`
query GET_EXCEL_INCIDENT_TIPOBASES {
  GET_EXCEL_INCIDENT_TIPOBASES {
    TipoBase
  }
}
`
export const GET_EXCEL_INCIDENT_PERIODICO = gql`
query GET_EXCEL_INCIDENT_PERIODICO {
  GET_EXCEL_INCIDENT_PERIODICO {
    Periodico
  }
}
`

export const CREATE_SALARYHISTORY = gql`
mutation CREATE_SALARYHISTORY($input: salary_History_Input) {
  CREATE_SALARYHISTORY(input:$input)

}`;

export const CREATE_RENEWAL_HISTORY = gql`
mutation CREATE_RENEWAL_HISTORY($input: renewal_History_Input) {
  CREATE_RENEWAL_HISTORY(input:$input)

}`;


export const POST_TRANSACTION = gql`
mutation POST_TRANSACTION($input: postTransaction_input) {
  POST_TRANSACTION(input: $input)
}`

export const GET_TRANSACTION= gql`
query GET_TRANSACTIONS($getTransactionsId: ID!) {
  GET_TRANSACTIONS(id: $getTransactionsId) {
    id
    fullname
    clabe
    transactionTotal
    Banco
    dispersionStatus
    enterprise
    comission
    montoDescontar
    Concepto
  }
}
`
export const ELIMINA_TRANSACTION = gql`
mutation ELIMINA_TRANSACTION($eliminaTransactionId: ID!) {
  ELIMINA_TRANSACTION(id: $eliminaTransactionId)
}
`
export const GET_ENTERPRISE_CENTRAL_PAGOS = gql`
query GET_ENTERPRISE_CENTRAL_PAGOS($getEnterpriseCentralPagosId: ID!) {
  GET_ENTERPRISE_CENTRAL_PAGOS(id: $getEnterpriseCentralPagosId) {
    id
    name
    cuentaBancaria
    saldo
  }
}
`

export const GET_TRANSACTIONS_HISTORICAS = gql `
query GET_TRANSACTIONS_HISTORICAS($input: historico_Transactions_input) {
  GET_TRANSACTIONS_HISTORICAS(input: $input) {
    Banco
    clabe
    comission
    dispersionStatus
    enterprise
    fullname
    id
    montoDescontar
    transactionTotal
    idDispersion
  }
}
`

export const GET_REFERENCE_VALUE = gql `
  query GET_REFERENCE_VALUE {
    GET_REFERENCE_VALUE {
      UMA
      SalarioMinimo
      DescuentoInfonavit
      SalarioMinimoFrontera
    }
  }
`


export const UPDATE_SETTLEMENT_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN = gql`
mutation UPDATE_SETTLEMENT_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN($id: ID!, $tokenTimbre: String!, $userAdd: String!) {
  UPDATE_SETTLEMENT_COLLABORATOR_TOKENTIMBRE_TIMBRAR_AGAIN(id: $id, tokenTimbre: $tokenTimbre, userAdd: $userAdd)
}
`;

export const GET_CONTRACT_STATUS = gql`
query GET_CONTRACT_STATUS {
  GET_CONTRACT_STATUS {
    days,
    Collaborator,
    dateOfAdmission,
    FechaBaja,
    contractStatus,
    idCollaborator,
  }
}`;

export const GET_USERS_COLLABORATOR_CONTRACT = gql`
  query GET_USERS_COLLABORATOR_CONTRACT($type: String) {
    GET_USERS_COLLABORATOR_CONTRACT(type: $type) {
      id
      _id: id
      name
      firstName
      lastName
      fullName
      dateOfBirth
      email
      dateOfAdmission
      userType
      project
      campus
      client
      password
      active
      token
      linkedAccount
      cellphone
      area
      tittle
      officePlace
      bussinesName
      contractName
      netSalary
      grossSalary
      recordableSalary
      DS
      ISD
      nominesPayroll
      DSText
      paymentPeridicity
      journey
      comment
      userStatus
      expiredAlta
      expiredPersonales
      currentToken
      host
      address
      municipality
      ZC
      state
      country
      placeOfBirth
      nacionality
      gender
      civilStatus
      children
      educationalLevel
      ClaveElectoralPasaporte
      IMSS
      CURP
      workPermission
      creditoInfonavit
      benefitiary
      emergencyContact
      diseases
      bank
      accountNumber
      clabeNum
      RFC
      id_payroll_group
      SDG
      id_User
      id_Bank
      done
      collaborator
      drop
      baja
      Puesto
      Company
      new
      suburb
      benefitiaryCountry
      benefitiaryState
      benefitiaryMunicipality
      benefitiaryCURP
      ParentescoB
      benefitiaryDateOfBirth
      benefitiaryZC
      benefitiaryAddress
      benefitiarySuburb
      ParentescoCE
      cellphoneCE
      phoneCE
      suburbCE
      addressCE
      Alergias
      PadEnfer
      Cirugias
      TraMeRe
      dropType
      dropDate
      terminationDate
      dateContractDate
      FechaIngreso
      FechaBaja
      ZCSAT
      judicialDistrict
      legalRepresentative
      notarialTestimony
      notarialTestimonyVolume
      notarialTestimonyDate
      notaryPublicNumber
      notaryPublic
      
      addressCourt
      SDGText
      Age
      estadoEmpresa
      municipioEmpresa
      nameEnterprise
      addressEnterprise
      taxRegime
      dayRemaining
      monthRemaining
      dateOfAdmissionText
    }
  }
`;

export const SEND_DISPERSION_CENTRAL_PAGOS = gql`
mutation SEND_DISPERSION_CENTRAL_PAGOS($sendDispersionCentralPagosId: [dispersion_Input!]) {
  SEND_DISPERSION_CENTRAL_PAGOS(id: $sendDispersionCentralPagosId)
}
`

export const GET_ALL_VARIABLESGLOBALES = gql`
query GET_ALL_VARIABLESGLOBALES {
  GET_ALL_VARIABLESGLOBALES {
    id
    SalarioMinimo
  }
}
`
export const GET_CONFRONTA = gql`
query GET_CONFRONTA {
  GET_CONFRONTA {
    NSS
    Nombre
    CuotaFija
    Dias
    ExcedenteObrero
    ExcedentePatronal
    GastosMedicosyPensionadosObrero
    GastosMedicosyPensionadosPatronal
    GuarderiasyPrestacionesSociales
    InvalidezyVidaObrero
    InvalidezyVidaPatronal
    PrestacionesenDineroObrero
    PrestacionesenDineroPatronal
    RiesgodeTrabajo
    SalarioDiario
    Total
  }
}
`

export const GET_IDSE_CONFRONTA = gql`
query GET_IDSE_CONFRONTA {
  GET_IDSE_CONFRONTA {
    NSS
    Nombre
    CuotaFija
    Dias
    ExcedenteObrero
    ExcedentePatronal
    GastosMedicosyPensionadosObrero
    GastosMedicosyPensionadosPatronal
    GuarderiasyPrestacionesSociales
    InvalidezyVidaObrero
    InvalidezyVidaPatronal
    PrestacionesenDineroObrero
    PrestacionesenDineroPatronal
    RiesgodeTrabajo
    SalarioDiario
    Total
  }
}
`

export const GET_IDSEBIMESTRE_CONFRONTA = gql`
query GET_IDSEBIMESTRE_CONFRONTA {
  GET_IDSEBIMESTRE_CONFRONTA {
    NSS
    Nombre
    CuotaFija
    Dias
    ExcedenteObrero
    ExcedentePatronal
    GastosMedicosyPensionadosObrero
    GastosMedicosyPensionadosPatronal
    GuarderiasyPrestacionesSociales
    InvalidezyVidaObrero
    InvalidezyVidaPatronal
    PrestacionesenDineroObrero
    PrestacionesenDineroPatronal
    RiesgodeTrabajo
    SalarioDiario
    Total
  }
}
`

export const GET_SUA_CONFRONTA = gql`
query GET_SUA_CONFRONTA {
  GET_SUA_CONFRONTA {
    NSS
    Nombre
    CuotaFija
    Dias
    ExcedenteObrero
    ExcedentePatronal
    GastosMedicosyPensionadosObrero
    GastosMedicosyPensionadosPatronal
    GuarderiasyPrestacionesSociales
    InvalidezyVidaObrero
    InvalidezyVidaPatronal
    PrestacionesenDineroObrero
    PrestacionesenDineroPatronal
    RiesgodeTrabajo
    SalarioDiario
    Total
  }
}
`

export const GET_COLLABORATORS_OUTSORCERS = gql`
query GET_COLLABORATORS_OUTSORCERS($getCollaboratorsOutsorcersId: ID!) {
  GET_COLLABORATORS_OUTSORCERS(id: $getCollaboratorsOutsorcersId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    statusOutsorcer
  }
}
`
export const GET_COLLABORATORS_OUTSORCERS_DISPERSION_AUTORIZA = gql`
query GET_COLLABORATORS_OUTSORCERS_DISPERSION_AUTORIZA($getCollaboratorsOutsorcersDispersionId: ID!) {
  GET_COLLABORATORS_OUTSORCERS_DISPERSION_AUTORIZA(id: $getCollaboratorsOutsorcersDispersionId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    idDispersionOutsorcer
    dispersionStatusOutsorcer
    statusOutsorcer
  }
} 
`

export const GET_COLLABORATORS_OUTSORCERS_EVENTUAL = gql`
query GET_COLLABORATORS_OUTSORCERS_EVENTUAL($getCollaboratorsOutsorcersId: ID!) {
  GET_COLLABORATORS_OUTSORCERS_EVENTUAL(id: $getCollaboratorsOutsorcersId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    statusOutsorcer
  }
}
`

export const GET_COLLABORATORS_OUTSORCERS_EVENTUAL_V2 = gql`
query GET_COLLABORATORS_OUTSORCERS_EVENTUAL_V2($getCollaboratorsOutsorcersId: ID!) {
  GET_COLLABORATORS_OUTSORCERS_EVENTUAL_V2(id: $getCollaboratorsOutsorcersId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    statusOutsorcer
  }
}
`

export const GET_COLLABORATORS_OUTSORCERS_FINIQUITO = gql`
query GET_COLLABORATORS_OUTSORCERS_FINIQUITO($getCollaboratorsOutsorcersId: ID!) {
  GET_COLLABORATORS_OUTSORCERS_FINIQUITO(id: $getCollaboratorsOutsorcersId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    statusOutsorcer
  }
}
`

export const POST_AUTORIZA_OUTSORCERS = gql`
mutation POST_AUTORIZA_OUTSORCERS($postAutorizaOutsorcersId: [autorizaOutsorcers_input!]) {
  POST_AUTORIZA_OUTSORCERS(id: $postAutorizaOutsorcersId)
}
`
export const POST_AUTORIZA_OUTSORCERS_EVENTUAL = gql`
mutation POST_AUTORIZA_OUTSORCERS_EVENTUAL($postAutorizaOutsorcersId: [autorizaOutsorcers_input!]) {
  POST_AUTORIZA_OUTSORCERS_EVENTUAL(id: $postAutorizaOutsorcersId)
}
`
export const POST_AUTORIZA_OUTSORCERS_FINIQUITO = gql`
mutation POST_AUTORIZA_OUTSORCERS_FINIQUITO($postAutorizaOutsorcersId: [autorizaOutsorcers_input!]) {
  POST_AUTORIZA_OUTSORCERS_FINIQUITO(id: $postAutorizaOutsorcersId)
}
`

export const GET_NOMINAS_OUTSORCERS = gql`
query GET_NOMINAS_OUTSORCERS($input: outsorcersSolicitudes_input) {
  GET_NOMINAS_OUTSORCERS(input: $input) {
    id
    nombreNomina
    fechaInicio
    fechaFin
    Empresa
    Dispersiones
    totalDispersion
    Autorizadas
    totalAutorizadas
    tipoNomina
    payroll_type
    totalLiquidadas
  }
}
`
export const GET_NOMINAS_OUTSORCERS_HISTORICAS = gql`
query GET_NOMINAS_OUTSORCERS_HISTORICAS($input: outsorcersSolicitudes_input) {
  GET_NOMINAS_OUTSORCERS_HISTORICAS(input: $input) {
    id
    nombreNomina
    fechaInicio
    fechaFin
    Empresa
    Dispersiones
    totalDispersion
    Autorizadas
    totalAutorizadas
    tipoNomina
    payroll_type
    totalLiquidadas
  }
}
`
export const GET_COLLABORATORS_OUTSORCERS_DISPERSION = gql`
query GET_COLLABORATORS_OUTSORCERS_DISPERSION($getCollaboratorsOutsorcersDispersionId: ID!) {
  GET_COLLABORATORS_OUTSORCERS_DISPERSION(id: $getCollaboratorsOutsorcersDispersionId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    idDispersionOutsorcer
    dispersionStatusOutsorcer
    statusOutsorcer
  }
} 
`
export const GET_COLLABORATORS_OUTSORCERS_DISPERSION_EVENTUAL = gql`
query GET_COLLABORATORS_OUTSORCERS_DISPERSION_EVENTUAL($getCollaboratorsOutsorcersDispersionId: ID!) {
  GET_COLLABORATORS_OUTSORCERS_DISPERSION_EVENTUAL(id: $getCollaboratorsOutsorcersDispersionId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    idDispersionOutsorcer
    dispersionStatusOutsorcer
    statusOutsorcer
  }
} 
`
export const GET_COLLABORATORS_OUTSORCERS_DISPERSION_FINIQUITO = gql`
query GET_COLLABORATORS_OUTSORCERS_DISPERSION_FINIQUITO($getCollaboratorsOutsorcersDispersionId: ID!) {
  GET_COLLABORATORS_OUTSORCERS_DISPERSION_FINIQUITO(id: $getCollaboratorsOutsorcersDispersionId) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    idDispersionOutsorcer
    dispersionStatusOutsorcer
    statusOutsorcer
  }
} 
`

export const SEND_DISPERSION_OUTSORCERS = gql`
mutation SEND_DISPERSION_OUTSORCERS($sendDispersionOutsorcersId: [dispersion_Input!]) {
  SEND_DISPERSION_OUTSORCERS(id: $sendDispersionOutsorcersId)
}
`
export const SEND_DISPERSION_OUTSORCERS_EVENTUAL = gql`
mutation SEND_DISPERSION_OUTSORCERS_EVENTUAL($sendDispersionOutsorcersId: [dispersion_Input!]) {
  SEND_DISPERSION_OUTSORCERS_EVENTUAL(id: $sendDispersionOutsorcersId)
}
`
export const SEND_DISPERSION_OUTSORCERS_FINIQUITO = gql`
mutation SEND_DISPERSION_OUTSORCERS_FINIQUITO($sendDispersionOutsorcersId: [dispersion_Input!]) {
  SEND_DISPERSION_OUTSORCERS_FINIQUITO(id: $sendDispersionOutsorcersId)
}
`



export const POST_DESCARTA_OUTSORCERS = gql`
mutation POST_DESCARTA_OUTSORCERS($input: descartaOutsorcers_input) {
  POST_DESCARTA_OUTSORCERS(input: $input)
}
`
export const POST_DESCARTA_OUTSORCERS_EVENTUAL = gql`
mutation POST_DESCARTA_OUTSORCERS_EVENTUAL($input: descartaOutsorcers_input) {
  POST_DESCARTA_OUTSORCERS_EVENTUAL(input: $input)
}
`

export const GET_PAYROLL_AUTORIZA_OUTSOURCERS = gql `
query GET_PAYROLL_AUTORIZA_OUTSOURCERS($getPayrollAutorizaOutsourcersId: ID!) {
  GET_PAYROLL_AUTORIZA_OUTSOURCERS(id: $getPayrollAutorizaOutsourcersId) {
    Nombre
    fechaInicio
    fechaFin
    cuentaBancaria
    Empresa
  }
}
`
export const GET_PAYROLL_AUTORIZA_OUTSOURCERS_EVENTUAL = gql `
query GET_PAYROLL_AUTORIZA_OUTSOURCERS_EVENTUAL($getPayrollAutorizaOutsourcersId: ID!) {
  GET_PAYROLL_AUTORIZA_OUTSOURCERS_EVENTUAL(id: $getPayrollAutorizaOutsourcersId) {
    Nombre
    fechaInicio
    fechaFin
    cuentaBancaria
    Empresa
  }
}
`
export const GET_PAYROLL_AUTORIZA_OUTSOURCERS_FINIQUITO = gql `
query GET_PAYROLL_AUTORIZA_OUTSOURCERS_FINIQUITO($getPayrollAutorizaOutsourcersId: ID!) {
  GET_PAYROLL_AUTORIZA_OUTSOURCERS_FINIQUITO(id: $getPayrollAutorizaOutsourcersId) {
    Nombre
    fechaInicio
    fechaFin
    cuentaBancaria
    Empresa
  }
}
`

export const POST_ACEPTAR_RECHAZO_OUTSOURCERS = gql`
mutation POST_ACEPTAR_RECHAZO_OUTSOURCERS($postAceptarRechazoOutsourcersId: ID!) {
  POST_ACEPTAR_RECHAZO_OUTSOURCERS(id: $postAceptarRechazoOutsourcersId)
}
`
export const POST_ACEPTAR_RECHAZO_OUTSOURCERS_EVENTUAL = gql`
mutation POST_ACEPTAR_RECHAZO_OUTSOURCERS_EVENTUAL($postAceptarRechazoOutsourcersId: ID!) {
  POST_ACEPTAR_RECHAZO_OUTSOURCERS_EVENTUAL(id: $postAceptarRechazoOutsourcersId)
}
`
export const POST_ACEPTAR_RECHAZO_OUTSOURCERS_FINIQUITO = gql`
mutation POST_ACEPTAR_RECHAZO_OUTSOURCERS_FINIQUITO($postAceptarRechazoOutsourcersId: ID!) {
  POST_ACEPTAR_RECHAZO_OUTSOURCERS_FINIQUITO(id: $postAceptarRechazoOutsourcersId)
}
`
export const POST_RECHAZAR_RECHAZADO_OUTSOURCERS = gql `
mutation POST_RECHAZAR_RECHAZADO_OUTSOURCERS($postRechazarRechazadoOutsourcersId: ID!) {
  POST_RECHAZAR_RECHAZADO_OUTSOURCERS(id: $postRechazarRechazadoOutsourcersId)
}
`
export const POST_RECHAZAR_RECHAZADO_OUTSOURCERS_EVENTUAL = gql `
mutation POST_RECHAZAR_RECHAZADO_OUTSOURCERS_EVENTUAL($postRechazarRechazadoOutsourcersId: ID!) {
  POST_RECHAZAR_RECHAZADO_OUTSOURCERS_EVENTUAL(id: $postRechazarRechazadoOutsourcersId)
}
`

export const POST_RECHAZAR_RECHAZADO_OUTSOURCERS_FINIQUITO = gql `
mutation POST_RECHAZAR_RECHAZADO_OUTSOURCERS_FINIQUITO($postRechazarRechazadoOutsourcersId: ID!) {
  POST_RECHAZAR_RECHAZADO_OUTSOURCERS_FINIQUITO(id: $postRechazarRechazadoOutsourcersId)
}
`

export const POST_DESCARTA_TODAS = gql`
mutation POST_DESCARTA_TODAS($input: descartaTodasOutsourcers_input) {
  POST_DESCARTA_TODAS(input: $input)
}
`
export const POST_DESCARTA_TODASEVENTUAL = gql`
mutation POST_DESCARTA_TODASEVENTUAL($input: descartaTodasOutsourcers_input) {
  POST_DESCARTA_TODASEVENTUAL(input: $input)
}
`

export const POST_DESCARTA_FINIQUITO_OUT = gql`
mutation POST_DESCARTA_FINIQUITO_OUT($input: descartaTodasOutsourcers_input) {
  POST_DESCARTA_FINIQUITO_OUT(input: $input)
}
`
export const POST_DESCARTA_FIN_LIQ_OUT = gql`
mutation POST_DESCARTA_FIN_LIQ_OUT($input: descartaTodasOutsourcers_input) {
  POST_DESCARTA_FIN_LIQ_OUT(input: $input)
}
`

export const GET_HISTORICOS_OUTSORCERS = gql`
query GET_HISTORICOS_OUTSORCERS($input: outsorcersSolicitudes_input) {
  GET_HISTORICOS_OUTSORCERS(input: $input) {
    id
    colaborator
    RFC
    bank
    accountNumber
    clabeNum
    netIncomeTaxable
    TotalIncomeNotTaxable
    dispersionStatus
    idDispersion
    tokenDispersar
    mensajeDispersion
    autorizaOutsorcers
    statusOutsorcer
  }
}
`

export const GET_ENTERPRISE_OUTSOURCER = gql `
query GET_ENTERPRISE_OUTSOURCER {
  GET_ENTERPRISE_OUTSOURCER {
    id
    name
    RazonSocial
  }
}
`

export const GET_TERMINAR_OUTSOURCERS = gql`
query GET_TERMINAR_OUTSOURCERS($getTerminarOutsourcersId: ID!) {
  GET_TERMINAR_OUTSOURCERS(id: $getTerminarOutsourcersId) {
    id
    porcentajePagos
    Pagos
    porcentajeOtrosMetodos
    pagosOtrosMetodos
    Canceladas
    Total
  }
}
`

export const GET_TERMINAR_OUTSOURCERS_EVENTUAL = gql`
query GET_TERMINAR_OUTSOURCERS_EVENTUAL($getTerminarOutsourcersId: ID!) {
  GET_TERMINAR_OUTSOURCERS_EVENTUAL(id: $getTerminarOutsourcersId) {
    id
    porcentajePagos
    Pagos
    porcentajeOtrosMetodos
    pagosOtrosMetodos
    Canceladas
    Total
  }
}
`

export const GET_TERMINAR_OUTSOURCERS_FINIQUITO = gql`
query GET_TERMINAR_OUTSOURCERS_FINIQUITO($getTerminarOutsourcersId: ID!) {
  GET_TERMINAR_OUTSOURCERS_FINIQUITO(id: $getTerminarOutsourcersId) {
    id
    porcentajePagos
    Pagos
    porcentajeOtrosMetodos
    pagosOtrosMetodos
    Canceladas
    Total
  }
}
`

export const GET_MOVIMIENTOS_SUA = gql`
query GET_MOVIMIENTOS_SUA($input: movimientosSUAFiltro_input) {
  GET_MOVIMIENTOS_SUA(input: $input) {
    idMovimiento
    Nombre
    SBC
    Movimiento
    fechaMovimiento
    estatusMovimiento
    respuestaSua
    registroPatronal
    empresa
  }
}
`

export const GET_RESULTADO_REPORTE_IDSE = gql`
query GET_RESULTADO_REPORTE_IDSE {
  GET_RESULTADO_REPORTE_IDSE {
    Nombre
    SBC
    Movimiento
    Fecha
    Respuesta
  }
}`

export const GET_EVENTUALPAYROLLFINISH = gql`
  query GET_EVENTUALPAYROLLFINISH($getEventualPayrollId: ID!) {
    GET_EVENTUALPAYROLLFINISH(id: $getEventualPayrollId) {
      id
      group_name
      payroll_type
      init_date
      end_date
      employees
      perception
      deduction
      total
      id_group_payroll
      status
      statusProgress
      AportacionPatronal
      AnioPTU
      MontoRepartirPTU
      AguinaldoPeriod
      payroll_type
      company_name
      init_date_month
      pantryVouchersMonth
    }
  }
`;


export const GET_EVENTUALCOLLABORATOR_FINISH = gql`
  query GET_EVENTUALCOLLABORATOR_FINISH($id: ID!) {
    GET_EVENTUALCOLLABORATOR_FINISH(id: $id) {
      allPayroll
      timbresPayroll
      timbresPayrollPercent
      total
      totalTimbrado
      totalTimbradoPercent
      allCollaborators
      timbresCollaborators
      timbresCollaboratorsPercent
      withoutTimbre
    }
  }
`;

export const GET_PAYROLLSETTLEMENTGRAPHIC_FINISH = gql`
  query GET_PAYROLLSETTLEMENTGRAPHIC_FINISH($id: ID!) {
    GET_PAYROLLSETTLEMENTGRAPHIC_FINISH(id: $id) {
      workedDays
      workedDaysPercentage
      PorcentajeBajas
      ColaboradoresBajaMes
      ColaboradoresBajaAnio
      TotalColaboradores
    }
  }
`;

export const GET_INDICADORES_GLOBALES = gql`
query GET_INDICADORES_GLOBALES {
  GET_INDICADORES_GLOBALES{
    TotalEncuestas
    RepuestaPromedio
    Respondidas
    Enviadas
  }
}`

export const GET_RESPUESTAS_RECIBIDAS = gql`
query GET_RESPUESTAS_RECIBIDAS {
  GET_RESPUESTAS_RECIBIDAS{
    TotalRespuestas
    Mes
    Total
  }
}`

export const CREATE_ENCUESTA = gql`
  mutation CREATE_ENCUESTA($input: encuestas_Input) {
    CREATE_ENCUESTA(input: $input)
  }
`;


export const GET_ALL_ENCUESTAS = gql`
query GET_ALL_ENCUESTAS {
  GET_ALL_ENCUESTAS{
    id
    Pregunta
    Fecha
    Estatus
    Motivado
    Productivo
    Aburrido
    Presionado
    Enfadado
    idArea
    area
    campus
  }
}`

export const UPDATE_ENCUESTA_STATUS = gql`
  mutation UPDATE_ENCUESTA_STATUS($id: ID!) {
    UPDATE_ENCUESTA_STATUS(id: $id)
  }
`;

export const GET_RESULTADO_ENCUESTA = gql`
query GET_RESULTADO_ENCUESTA($id: ID!) {
  GET_RESULTADO_ENCUESTA(id: $id){
    TotalRespuestas
    Motivados
    Productivos
    Aburridos
    Presionados
    Enfadados
  }
}`;

export const GET_USERS_BYDAY = gql`
query GET_USERS_BYDAY($input: DayInfo_input) {
  GET_USERS_BYDAY(input: $input) {
    createdAt
    bussinesName
    id
  }
}`

export const GET_TIMECHECKER_EXPORT = gql`
query GET_TIMECHECKER_EXPORT {
  GET_TIMECHECKER_EXPORT {
    id
    Nombre
    Sede
    Area
    HorarioLaboral
    Hora
    TipoChecada
    Diferencia
    Ubicacion
    Acumulado
    Fecha
  }
}`

export const GET_TIMECHECKER_OPT = gql`
query GET_TIMECHECKER_OPT($input: Schedule_input) {
  GET_TIMECHECKER_OPT(input: $input) {
    id
    Nombre
    Sede
    Area
    HorarioLaboral
    Hora
    TipoChecada
    Diferencia
    Ubicacion
    Acumulado
    Fecha
  }
}`

export const GET_ALL_USER_CAMPAIGN_OBJECTIVES = gql`
  query GET_ALL_USER_CAMPAIGN_OBJECTIVES($input: campaignObjectiveByCollaborator) {
    GET_ALL_USER_CAMPAIGN_OBJECTIVES(input: $input) {
      id
      idCampaing
      idCollaborator
      ObjectiveName
      nameIndicator
      metricObjective
      extraChar
      metricIndicator
      weight
      descriptionIndicator
      dateAdd
      IdLider
      progress
      result
      status
      objetiveSteps
      evaluation
      evidenceCount
      isLeaderNotified
      iscreatedLeader
      iscreatedAdmin
      Totalobjectives
      aprobdasTotal
      minObjectives
      maxObjectives
      nombre
      coment
    }
  }
`

export const CREATE_CAMPAIGN_OBJECTIVE_BY_USER = gql`
mutation CREATE_CAMPAIGN_OBJECTIVE_BY_USER($input: createCampaignObjectiveByUser_Input) {
  CREATE_CAMPAIGN_OBJECTIVE_BY_USER(input: $input)
}`

export const CREATE_CAMPAIGN_OBJECTIVE_BY_LEADER = gql`
mutation CREATE_CAMPAIGN_OBJECTIVE_BY_LEADER($input: createCampaignObjectiveByLeader_Input) {
  CREATE_CAMPAIGN_OBJECTIVE_BY_LEADER(input: $input)
}`

export const CREATE_CAMPAIGN_OBJECTIVE_BY_ADMIN = gql`
mutation CREATE_CAMPAIGN_OBJECTIVE_BY_ADMIN($input: createCampaignObjectiveByAdmin_Input) {
  CREATE_CAMPAIGN_OBJECTIVE_BY_ADMIN(input: $input)
}`

export const UPDATE_CAMPAIGN_OBJECTIVE = gql`
mutation UPDATE_CAMPAIGN_OBJECTIVE($input: updateCampaignObjective_Input) {
  UPDATE_CAMPAIGN_OBJECTIVE(input: $input)
}`

export const UPDATE_COLABORADOR_BOND = gql`
mutation UPDATE_COLABORADOR_BOND($idCampaign: ID!,$idCollaborator:ID!, $idPayRoll:ID!) {
  UPDATE_COLABORADOR_BOND(idCampaign: $idCampaign,idCollaborator:$idCollaborator,idPayRoll:$idPayRoll)
}`

export const QUALITY_CAMPAIGN_OBJECTIVE = gql`
 mutation QUALITY_CAMPAIGN_OBJECTIVE($idCampaign: ID,$comment:String, $qualify:Float, $input: qualityCampaignObjectiveEvidence_Input) {
  QUALITY_CAMPAIGN_OBJECTIVE(idCampaign: $idCampaign,comment:$comment,qualify:$qualify,input: $input)
}`

export const GET_CAMPAIGN_BOND = gql`
query GET_CAMPAIGN_BOND($idCollaborator: ID!, $bond: Float!,$idCampaign:ID!) {
  GET_CAMPAIGN_BOND(idCollaborator: $idCollaborator, bond: $bond,idCampaign:$idCampaign) {
    bond
    id
  }
}`

export const UPDATE_OBJECTIVE_EVIDENCE = gql`
mutation UPDATE_OBJECTIVE_EVIDENCE($input: campaignObjectiveFileEvidenceInput) {
  UPDATE_OBJECTIVE_EVIDENCE(input: $input)
}`

export const DELETE_CAMPAIGN_OBJECTIVE = gql`
mutation DELETE_CAMPAIGN_OBJECTIVE($input: deleteCampaignObjective_Input) {
  DELETE_CAMPAIGN_OBJECTIVE(input: $input)
}`

export const DELETE_OBJECTIVE_EVIDENCE = gql`
mutation DELETE_OBJECTIVE_EVIDENCE($input: deleteCampaignObjective_Input) {
  DELETE_OBJECTIVE_EVIDENCE(input: $input)
}`

export const GET_CAMPAIGN_USERS_BY_LEADER = gql`
query GET_CAMPAIGN_USERS_BY_LEADER($idCollaborator: Int!, $idCampaing: Int!) {
  GET_CAMPAIGN_USERS_BY_LEADER(idCollaborator: $idCollaborator, idCampaing: $idCampaing) {
    sede
    objectivesCount
    name
    id
    email
    area
    enterprise
  }
}`

export const GET_CAMPAIGN_USERS_BY_ADMIN = gql`
query GET_CAMPAIGN_USERS_BY_ADMIN($idCampaing: Int!,$step:String) {
  GET_CAMPAIGN_USERS_BY_ADMIN(idCampaing: $idCampaing,step:$step) {
    sede
    objectivesCount
    name
    id
    idPayrollIncident
    email
    area
    enterprise
    qualFinal
    bond
  }
}`
export const GET_CAMPAIGN_USERS_BY_ADMIN_SELECT = gql`
query GET_CAMPAIGN_USERS_BY_ADMIN_SELECT($idCampaing: Int!) {
  GET_CAMPAIGN_USERS_BY_ADMIN_SELECT(idCampaing: $idCampaing) {
    sede  
    name
    id   
    email
    area
    enterprise    
  }
}`

export const GET_CAMPAIGN_USERS_BY_ADMIN_EXCEL = gql`
query GET_CAMPAIGN_USERS_BY_ADMIN_EXCEL($idCampaing: Int!) {
  GET_CAMPAIGN_USERS_BY_ADMIN_EXCEL(idCampaing: $idCampaing) {
    FechaIngreso
    Totalobjectives
    apellidos
    bono
    campus
    descriptionBond
    email
    id
    ingresoMensual
    minQualification
    nombre
    objectivesCount
    project
    qualFinal
    tipoBono
  }
}`

export const GET_OBJECTIVE_EVIDENCE = gql`
query GET_OBJECTIVE_EVIDENCE($input: campaignObjectiveEvidenceInput) {
  GET_OBJECTIVE_EVIDENCE(input: $input) {
    id
    idObjective
    idMediaType
    idCollaborator
    URL
    name
    date
    progress
    result
    metricIndicator
    approved
  }
}`

export const GET_CAMPAIGN_BY_TOKEN = gql`
  query GET_CAMPAIGN_BY_TOKEN($campaignId: Int!, $userId: Int!, $token: String!) {
    GET_CAMPAIGN_BY_TOKEN(campaignId: $campaignId, userId: $userId, token: $token) {
      user {
        id
        name
        image
      }
      campaigns {
        id  
        nameCampaign
        startCampaign
        endCampaign
        step
        remandingDays      
        campaignBond
        maxObjectives
        minObjectives
        totalCollaborators
        createdObjectives
        objetivesAvance {
        id
        objetiveEvidence
      }
        isLeader
      }
    }
  }
`;

export const VERIFY_TOKEN_OBJECTIVES = gql`
  query VERIFY_TOKEN_OBJECTIVES($campaignId: Int!, $userId: Int!, $token: String!) {
    VERIFY_TOKEN_OBJECTIVES(campaignId: $campaignId, userId: $userId, token: $token)      
  }
`;

export const GET_CAMPAIGN_BY_TOKEN_AND_DATE = gql`
  query GET_CAMPAIGN_BY_TOKEN_AND_DATE($campaignId: Int!, $userId: Int!, $token: String!, $startCampaign: String!, $endCampaign:String!) {
    GET_CAMPAIGN_BY_TOKEN_AND_DATE(campaignId: $campaignId, userId: $userId, token: $token, startCampaign: $startCampaign, endCampaign: $endCampaign) {
      user {
        id
        name
        image
      }
      campaigns {
        id  
        nameCampaign
        startCampaign
        endCampaign
        step
        remandingDays
        objetivesComplte
        objetives
        campaignBond
        maxObjectives
        minObjectives
        isLeader
      }
    }
  }
`;

export const GET_DATASOURCE = gql`
  query GET_DATASOURCE {
    GET_DATASOURCE {
      id
      Tables
      Name
    }
  }
`;

export const GET_DATACOLUMNS = gql`
  query GET_DATACOLUMNS {
    GET_DATACOLUMNS {
      id
      column
      table
      idDataSource
      Name
    }
  }
`;

export const CREATE_CUSTOM_REPORT = gql`
mutation CREATE_CUSTOM_REPORT($input: create_custom_report_input) {
  CREATE_CUSTOM_REPORT(input: $input) {
    id
  }
}`

export const GET_ALL_CUSTOMREPORTS = gql`
query GET_ALL_CUSTOMREPORTS {
  GET_ALL_CUSTOMREPORTS {
    id
    name
    dateAdd
    sqlQueryRows
    sqlQueryColumns
    filtros
  }
}`

export const SEND_EMAIL_TIMBRADO_INIT = gql`
mutation SEND_EMAIL_TIMBRADO_INIT($input: timbradoSend_Input) {
  SEND_EMAIL_TIMBRADO_INIT(input: $input)
}
`;

export const SEND_EMAIL_TIMBRADO_INIT_UAT = gql`
mutation SEND_EMAIL_TIMBRADO_INIT_UAT($input: timbradoSend_Input) {
  SEND_EMAIL_TIMBRADO_INIT_UAT(input: $input)
}
`;

export const NOTIFY_COLLABORATOR_EVIDENCES = gql`
  mutation NOTIFY_COLLABORATOR_EVIDENCES($input: evidenceReminderInput) {
    NOTIFY_COLLABORATOR_EVIDENCES(input: $input)
  }`

export const GET_COLABORADORES_PAGOS_REPORTE = gql`
query GET_COLABORADORES_PAGOS_REPORTE($getColaboradoresPagosReporteId: ID!) {
  GET_COLABORADORES_PAGOS_REPORTE(id: $getColaboradoresPagosReporteId) {
    name
    firstName
    lastName
    fechaIngreso
  }
}
`
export const GET_DISPERSIONACEPTADAREPORTE = gql `
query GET_DISPERSIONACEPTADAREPORTE($mes: Int!, $anio: Int!, $empresa: Int!) {
  GET_DISPERSIONACEPTADAREPORTE(mes: $mes, anio: $anio, empresa: $empresa) {
    id
    fechaOperacion
    nombreBeneficiario
    nombreOrdenante
    conceptoPago
    claveRastreo
    cargoAbono
    esRecibida
    tipoOperacion
    saldo
  }
}
`
export const GET_LIST_ENTERPRISE_MOVIMIENTOS_STP = gql ` 
query GET_LIST_ENTERPRISE_MOVIMIENTOS_STP {
  GET_LIST_ENTERPRISE_MOVIMIENTOS_STP {
    id
    razonSocial
  }
}
`
export const GET_ENTERPRISE_MOVIMIENTOS_STP = gql`
query GET_ENTERPRISE_MOVIMIENTOS_STP($getEnterpriseMovimientosStpId: ID!) {
  GET_ENTERPRISE_MOVIMIENTOS_STP(id: $getEnterpriseMovimientosStpId) {
    id
    cuentaBancaria
    razonSocial
    saldo
  }
}
`;