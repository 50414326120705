const Excel = require("exceljs");

export const CreateExcelFilePayrollEvD = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteDispersion = workbook.addWorksheet("Reporte Dispersion");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };



  ReporteDispersion.columns = [
    { header: "COLABORADOR", key: "colaborador", width },
    { header: "RFC", key: "rfc", width },
    { header: "BANCO", key: "banco", width },
    { header: "NUMERO CUENTA", key: "AccountN", width },
    { header: "NUMERO DE CLABE", key: "ClabeN", width },
    { header: "PERCEPCION", key: "percepcion", width },
    { header: "DEDUCCION", key: "deduccionFila", width },
    { header: "GRAVABLE", key: "gravable", width },
    { header: "NO GRAVABLE", key: "NoGravable", width },    
    { header: "TOTAL", key: "total", width },
  ];
  


  let letter = 65;
  ReporteDispersion.columns.forEach(() => {
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  
  data.forEach((item: any) => {
    ReporteDispersion.addRow({
      id: item.id,
      colaborador: item.colaborator,
      percepcion: item.percepcion,
      deduccionFila: item.deduccion,
      NoGravable: item.TotalIncomeNotTaxable,
      gravable: item.netIncomeTaxable,
      total: item.TotalAguinaldo,
      DispersionS: item.dispersionStatus,
      AguinaldoP: item.AguinaldoPeriod,
      AntiguedadPV: item.AntiguedadPrimaVacacional,
      VacacionesPV: item.VacacionesPrimaVacacional,
      TasaPV: item.TasaPrimaVacacional,
      AguinaldoD: item.AguinaldoDays,
      rfc: item.RFC,
      AccountN: item.accountNumber,
      banco: item.bank,
      ClabeN: item.clabeNum,
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteDispersion.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
