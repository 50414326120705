/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import { useLazyQuery} from "@apollo/client";
import Box from '@mui/material/Box';
import SearcherTable from "../../../screens/Colaboradores/Colaboradores/CollaboratorTab/SearcherTable";
import TableBits from "../../TableBits/TableBits";
import TableCampaignInfoCollaborators from "./TableCampaignInfoCollaborators";
import { GET_CAMPAIGN_USERS_BY_ADMIN,
         GET_CAMPAIGN_USERS_BY_LEADER,
         GET_CAMPAIGN_USERS_BY_ADMIN_EXCEL         
        } from "../../../Querys/querys";
import ObjectivesContext from "../../../context/ObjectiveContext/ObjectiveContext";
import campaignUsersTableColumns from "./CampaignUsersTableColumn";
import CollaboratorRowOptions from "../Modals/CollaboratorRowOptions";
import styles from "../Objectives.module.css"

const CampaignUsersTable = (props:any) => {
  const { state } = useContext(ObjectivesContext);
  const [getCampaignUsersByAdmin]  = useLazyQuery(GET_CAMPAIGN_USERS_BY_ADMIN, { fetchPolicy: 'no-cache' });
  const [getCampaignUsersReport]   = useLazyQuery(GET_CAMPAIGN_USERS_BY_ADMIN_EXCEL, { fetchPolicy: 'no-cache' });
  const [getCampaignUsersByLeader] = useLazyQuery(GET_CAMPAIGN_USERS_BY_LEADER, { fetchPolicy: 'no-cache' });  
  

  const [data, setData] = useState([]);  
  // const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);    
  

  const getUsers = async () => {
    if (state?.rol === 'admin') {
      const { data } = await getCampaignUsersByAdmin({
        variables: {
          idCampaing: state?.campaignId,
          step:state?.campaign?.step
        },        
      })
      if (data?.GET_CAMPAIGN_USERS_BY_ADMIN?.length > 0) {    
        props.setObjectivesListFilter(data.GET_CAMPAIGN_USERS_BY_ADMIN);       
        setData(data.GET_CAMPAIGN_USERS_BY_ADMIN);
        let totalObjCols = data.GET_CAMPAIGN_USERS_BY_ADMIN.reduce((sum:any, objt:any) => (typeof objt.objectivesCount == "number" ? sum + objt.objectivesCount : sum), 0);
        props.setAddColObj(totalObjCols)
      }
      const {data:report}=await getCampaignUsersReport({
        variables: {
          idCampaing: state?.campaignId,        
        },        
      })
      if (report?.GET_CAMPAIGN_USERS_BY_ADMIN_EXCEL?.length > 0) {    
       props.setInfoReport(report?.GET_CAMPAIGN_USERS_BY_ADMIN_EXCEL)
      }
      return
    } else {      
      const { data } = await getCampaignUsersByLeader({
        variables: {
          idCollaborator:state?.userFromLink?.id !==undefined ? Number(state?.userFromLink?.id):0,        
          idCampaing: state?.campaignId
        },        
      })
      if (data?.GET_CAMPAIGN_USERS_BY_LEADER?.length > 0) {           
        props.setObjectivesListFilter(data.GET_CAMPAIGN_USERS_BY_LEADER);
        setData(data.GET_CAMPAIGN_USERS_BY_LEADER);        
        let totalObjCols = data.GET_CAMPAIGN_USERS_BY_LEADER.reduce((sum:any, objt:any) => (typeof objt.objectivesCount == "number" ? sum + objt.objectivesCount : sum), 0);
        props.setAddColObj(totalObjCols)        
      }
      return
    }
  }

  console.log(state)
  useEffect(() => {    
    if (state?.campaignId && ((state?.rol === 'leader' && state?.userFromLink?.id) || state.rol !== 'leader')) {      
      getUsers()
      const pollInterval = setInterval(getUsers, 10000)
      return () => clearInterval(pollInterval)
    }
  }, [state?.campaignId, state?.userFromLink?.id]);

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box p={1} pb={3} className={styles.containerBtnsEvaluate}>
        <Box display="flex" justifyContent="flex-start">
          <SearcherTable
            label="Buscar nombre /sede / area"
            initState={data || [] as any}
            setState={props.setObjectivesListFilter}
            fields={["name", "business", "sede", "area"]}
          />
        </Box>     
      </Box>
      {
        data.length === 0 && (
          <div className={styles.noObjectives}>
            <div className={styles.noObjectivesContainer}>
              <div className={styles.noObjectivesText}>Comienza a crear objetivos para </div>
              <div className={styles.noObjectivesText}>alcanzar todas las metas de la organización</div>
            </div>
          </div>
        )
      } 
      {
        (props.objectivesListFilter.length > 0 && state?.campaign?.step !=='evaluar') ?
        (
          <TableBits
             columns={campaignUsersTableColumns}
             rows={props.objectivesListFilter}
             componentOptions={CollaboratorRowOptions}
             skipPermission
             skipSort
           />
         )
        :
         (props.objectivesListFilter.length > 0 && state?.campaign?.campaignBond !== 'false') ? (
          <TableCampaignInfoCollaborators
            rows={props.objectivesListFilter}        
            setArrayIncident={props.setArrayIncident}
            selected={props.selected}
            setSelected={props.setSelected}
            page={page}
            setPage={setPage}
          />          
        ):props.objectivesListFilter.length >0 &&
        (
         <TableBits
          columns={campaignUsersTableColumns}
          rows={props.objectivesListFilter}
          componentOptions={CollaboratorRowOptions}
          skipPermission
          skipSort
        />
        )
      }
    </Box>
  );
};

export default CampaignUsersTable;