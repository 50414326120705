import { createContext, Dispatch } from 'react';

type initialStateType = {
  _id: string,
  createPrenomina: boolean,
  createIncidencia: boolean,
  deleteModal: boolean;
  fondosInsuficientes: boolean;
  aceptarDispersar: boolean;
  aceptarDispersarEventual: boolean;
  sinNominasSeleccionadas: boolean;
  alertaReporte: boolean;
  alertaEnviarIDSE: boolean;
  alertaDescartarIDSE: boolean;
  isOnboarding: boolean;
  showDataCancelTimbre: boolean;
  cancelAllTimbres: boolean;
  sendMeilCancelaTimbre: boolean;
  showIncident: boolean;
  idPayroll: string;
  idCollaborator: string;
  showDeleteIncident: boolean;
  idDeleteIncident: string;
  varDelete: boolean;
  showFinish: boolean;
  typeCancelTimbre: string;
  alertaEnvioIDSEDos: boolean;
  alertaEnvioIDSEExterno: boolean;
  alertaNoProcesado: boolean,
  Comentario: string,
  aceptarDispersarIndividual: boolean,
  aceptarDispersarFiniquito: boolean,
  aceptarDispersarIndividualEventual: boolean,
  varDeleteIncuidentSettlement: boolean,
  showIncidentSettlement: boolean,
  idDeleteIncidentSettlement: string,
  showDeleteIncidentSettlement: boolean,
  showDeleteIncidentPeriodic: boolean,
  aceptarDispersarSettlement: boolean,
  aceptarDispersarFacturacionPagos: boolean,
  showApproveIncidentPeriodic: boolean,
  openModalOutsourcersResumen: boolean,
  openDispersionEnProceso: boolean,
  openAlertaNoGravable: boolean,
  inProgressPayroll: any[],
  isNewPayrollCreated: boolean,
};

type ModalPartial = {
  state: initialStateType,
  dispatch: Dispatch<any>
}

export const initialState = {
  _id: '',
  createPrenomina: false,
  createIncidencia: false,
  deleteModal: false,
  fondosInsuficientes: false,
  aceptarDispersar: false,
  aceptarDispersarEventual: false,
  sinNominasSeleccionadas: false,
  alertaReporte: false,
  alertaEnviarIDSE: false,
  alertaDescartarIDSE: false,
  isOnboarding: false,
  showDataCancelTimbre: false,
  cancelAllTimbres: false,
  sendMeilCancelaTimbre: false,
  showIncident: false,
  idPayroll: '',
  idCollaborator: '',
  showDeleteIncident: false,
  idDeleteIncident: '',
  varDelete: true,
  showFinish: false,
  typeCancelTimbre: '',
  alertaEnvioIDSEDos: false,
  alertaEnvioIDSEExterno: false,
  alertaNoProcesado: false,
  Comentario: '',
  aceptarDispersarIndividual: false,
  aceptarDispersarFiniquito: false,
  aceptarDispersarIndividualEventual: false,
  varDeleteIncuidentSettlement: false,
  showIncidentSettlement: false,
  idDeleteIncidentSettlement: '',
  showDeleteIncidentSettlement: false,
  showDeleteIncidentPeriodic: false,
  aceptarDispersarSettlement: false,
  aceptarDispersarFacturacionPagos: false,
  showApproveIncidentPeriodic: false,
  openModalOutsourcersResumen: false,
  openDispersionEnProceso: false,
  openAlertaNoGravable: false,
  inProgressPayroll: [],
  isNewPayrollCreated: false,
}

export enum Types {
  CREATE_PRENOMINA = "CREATE_PRENOMINA",
  CREATE_INCIDENCIA = "CREATE_INCIDENCIA",
  CLEAR_PAYROLL = "CLEAR_PAYROLL",
  DELETE_PAYROLL = "DELETE_PAYROLL",
  FONDOS_INSUFICIENTES = "FONDOS_INSUFICIENTES",
  ACEPTAR_DISPERSAR = "ACEPTAR_DISPERSAR",
  SIN_NOMINAS = "SIN_NOMINAS",
  ALERTA_REPORTE = "ALERTA_REPORTE",
  ALERTA_ENVIARIDSE = "ALERTA_ENVIARIDSE",
  ALERTA_DESCARTARIDSE = "ALERTA_DESCARTARIDSE",
  IS_ONBOARDING = "IS_ONBOARDING",
  CLOSE_ONBOARDING = "CLOSE_ONBOARDING",
  OPEN_PAYROLL_DATA_CANCEL_TIMBRE = 'OPEN_PAYROLL_DATA_CANCEL_TIMBRE',
  OPEN_SHOW_INCIDENT = 'OPEN_SHOW_INCIDENT',
  OPEN_DELETE_INCIDENT = 'OPEN_DELETE_INCIDENT',
  CLEAR_DELETE_INCIDENT = 'CLEAR_DELETE_INCIDENT',
  OPEN_FINISH = 'OPEN_FINISH',
  OPEN_ENVIOIDSE_DOS = 'OPEN_ENVIOIDSE_DOS',
  OPEN_MOVIMIENTO_EXTERNO = 'OPEN_MOVIMIENTO_EXTERNO',
  OPEN_NO_PROCESADO = 'OPEN_NO_PROCESADO',
  OPEN_DISPERSAR_INDIVIDUAL = 'OPEN_DISPERSAR_INDIVIDUAL',
  OPEN_DISPERSAR_FINIQUITO = 'OPEN_DISPERSAR_FINQUITO',
  ACEPTAR_DISPERSAR_EVENTUAL = "ACEPTAR_DISPERSAR_EVENTUAL",
  OPEN_DISPERSAR_INDIVIDUAL_EVENTUAL = "OPEN_DISPERSAR_INDIVIDUAL_EVENTUAL",
  OPEN_SHOW_INCIDENT_SETTLEMENT = "OPEN_SHOW_INCIDENT_SETTLEMENT",
  OPEN_DELETE_INCIDENT_SETTLEMENT = "OPEN_DELETE_INCIDENT_SETTLEMENT",
  CLEAR_DELETE_INCIDENT_SETTLEMENT = 'CLEAR_DELETE_INCIDENT_SETTLEMENT',
  OPEN_DELETE_INCIDENCIAS_PERIODICAS = "OPEN_DELETE_INCIDENCIAS_PERIODICAS",
  OPEN_APPROVE_INCIDENCIAS_PERIODICAS = "OPEN_APPROVE_INCIDENCIAS_PERIODICAS",
  CLEAR_DELETE_INCIDENCIAS_PERIODICAS = "CLEAR_DELETE_INCIDENCIAS_PERIODICAS",
  ACEPTAR_DISPERSAR_SETTLEMENT = "ACEPTAR_DISPERSAR_SETTLEMENT",
  ACEPTAR_DISPERSAR_FACTURACION_PAGOS = "ACEPTAR_DISPERSAR_FACTURACION_PAGOS",
  OPEN_OUTSOURCERS_MODAL_RESUMEN = "OPEN_OUTSORCERS_MODAL_RESUMEN",
  OPEN_DISPERSIONENPROCESO = "OPEN_DISPERSIONENPROCESO",
  OPEN_ALERTANOGRAVABLE = "OPEN_ALERTANOGRAVABLE",
  ADD_IN_PROGRESS_PAYROLL = "ADD_IN_PROGRESS_PAYROLL",
  UPDATE_IN_PROGRESS_PAYROLL = "UPDATE_IN_PROGRESS_PAYROLL",
  SET_IS_NEW_PAYROLL_CREATED = "SET_IS_NEW_PAYROLL_CREATED",
}

const ModalInitialState = {
  state: initialState,
  dispatch: () => null
}

const PayrollProcessContext = createContext<ModalPartial>(ModalInitialState);

export default PayrollProcessContext;