/* eslint-disable array-callback-return */
import {useState,useContext,ChangeEvent,MouseEvent,useMemo} from 'react'
import ObjectivesContext from "../../../context/ObjectiveContext/ObjectiveContext";
import CollaboratorRowOptions from "../Modals/CollaboratorRowOptions";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import SquareIcon from '@mui/icons-material/Square';
import { formatterMxn } from "../../../helpers/formatoMoneda";
import { v4 as uuidv4} from 'uuid'


interface Data {
  id: number;
  name: string;
  objectivesCount: string;
  sede: string;
  area: string;
  bond: number;
  idPayrollIncident:any;
  qualFinal:number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
} 

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id:  keyof Data;
  label: string;    
  key: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',      
    key: uuidv4(),
    disablePadding: true,
    label: 'Nombre de colaborador',
  },
  {
    id: 'objectivesCount',   
    key: uuidv4(), 
    disablePadding: false,
    label: 'objetivos',
  },
  {
    id: 'sede',   
    key: uuidv4(), 
    disablePadding: false,
    label: 'Sede',
  },
  {
    id: 'area',   
    key: uuidv4(),    
    disablePadding: false,
    label: 'Area',
  },
   {
     id: 'bond',  
     key: uuidv4(),     
     disablePadding: false,
     label: 'Bono',
   },
  {
    id: 'name',    
    key: uuidv4(),   
    disablePadding: false,
    label: 'Opciones',
  } 
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  rows:any;
}

function EnhancedTableHead(props: EnhancedTableProps) {  
  const { onSelectAllClick, order, orderBy, numSelected,
      rowCount,onRequestSort,rows, } =    props;
      const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
    let dis=false    
    let arrayNull=[]
    rows.map((col:any)=>{
      if(col.qualFinal===null){
        arrayNull.push(col)
      }
    })
    
    if(arrayNull.length === rows.length){
      dis=true
    }

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"  className="tableHeader">
            <Checkbox
            disabled={dis}            
             checkedIcon={
                    <SquareIcon sx={{  
                               background: 'radial-gradient(#979797 10px 100%,  #ffffff 100%)',
                               color: "#fabb00", 
                               stroke: "#ffffff",
                              strokeWidth: 2 
                                                  }}
                                                    />
                    }
              indeterminateIcon={
                <SquareIcon sx={{  
                  background: 'radial-gradient(#979797 10px 100%,  #ffffff 100%)',
                  color: "#fabb00", 
                  stroke: "#ffffff",
                  strokeWidth: 2}} />
              }
              sx={{
                color: "#979797",       
            }}  
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'Seleccionar todas las incidencias',
              }}
            />    
          </TableCell>
           {headCells.map((headCell) => (
            <TableCell
              className="tableHeader"
              key={headCell.key}                   
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
               <TableSortLabel
                active={orderBy === headCell.id}
                 direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className="labelHeader"
              >
                {headCell.label}             
              </TableSortLabel>
            </TableCell>
          ))} 
        </TableRow>
      </TableHead>
    );
  }   

const TableCampaignInfoCollaborators = ({rows,setArrayIncident,selected,setSelected,page,setPage}:any) => {
  const { state } = useContext(ObjectivesContext);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Data>("id");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    let select=false;
    rows.map((r:any)=>{
      if(r.bond > 0 && r.idPayrollIncident===null)
        select=true 
    })

    if(select){
        if (event.target.checked) {
          let newSelected = rows.map((n:any) => {        
            if(n.bond > 0 && n.idPayrollIncident===null) return n.id         
            });         
          setSelected(newSelected);
          setArrayIncident(newSelected)
          return;
        }      
        setSelected([]);
        setArrayIncident([]);
    }

  };

  const handleClick = (event: MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setArrayIncident(newSelected)
  };

  const handleChangePage = (event: unknown, newPage: number) => {      
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;
 
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;    
  
  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rows, rowsPerPage]
  );
  return (
    <Box sx={{ width: "100%" }}>
    <Paper sx={{ width: "100%"}}>
      <TableContainer>
        <Table
          sx={{ minWidth: 600 }}
          aria-labelledby="tableTitle"             
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length} 
            rows={rows}           
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const castId=Number(row.id)
              const isItemSelected = isSelected(castId);
              const labelId = `enhanced-table-checkbox-${index}`;  
              return (
                <TableRow              
                  hover  
                  tabIndex={-1}
                  key={`${row.id}-${row.name}`}                
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                  {(Number(row.qualFinal) >= Number(state?.campaign?.minQualification)) && (
                     row.idPayrollIncident !== null?
                    <Checkbox
                      disabled                       
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}                                                               
                    />:
                    <Checkbox
                      onClick={(event) => handleClick(event, castId)}
                      checked={isItemSelected}                       
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                      checkedIcon={
                        <SquareIcon sx={{  
                          background: 'radial-gradient(#979797 10px 100%,  #ffffff 100%)',
                          color: "#fabb00", 
                          stroke: "#ffffff",
                         strokeWidth: 2 
                          }}
                        />
                      }                     
                      sx={{ color: "#979797", }}                                                   
                    />                            
                   )}
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"                       
                  >
                     {row.name} 
                   
                  </TableCell>
                  <TableCell align="left">{row.objectivesCount}</TableCell>
                  <TableCell align="left">{row.area}</TableCell>
                  <TableCell align="left">{row.sede}</TableCell>
                  { 
                   ((state?.campaign?.typeBond === "Monto Fijo")) ?
                    (<TableCell align="left" >
                        {(Number(row.qualFinal) >= Number(state?.campaign?.minQualification)) ? formatterMxn(row.bond):'$0.00'} 
                     </TableCell>)
                  :
                  (<TableCell align="left" >
                    {(Number(row.qualFinal) >= Number(state?.campaign?.minQualification)) ? formatterMxn(row.bond):'$0.00'} 
                 </TableCell>)
                }
                 <TableCell align="left" >
                    <CollaboratorRowOptions {...row}/>
                 </TableCell>
                </TableRow>
              );
            })} 
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={11} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage='Número de registros'    
      />
    </Paper>        
  </Box>
  )
}

export default TableCampaignInfoCollaborators