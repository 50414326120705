const tablas :{[key:string]:string} = {"PayrollName": "Nombre", "CollaboratorName": "Empleado", "":"Gravable" , "TypeConcept":"Tipo Concepto",
"group_name":"Grupo", "payroll_type": "Tipo Nómina", "init_date":"Fecha Inicio", "end_date":"Fecha Fin", "idPayroll":"id Nómina", "incident_type": "Tipo Incidencia", "id":"ID",
"bussinesName":"Nombre", "project":"Area", "campus": "Sede", "dateOfAdmission":"Fecha de ingreso", "colaborator":"Empleado", "netIncomeTaxable":"Total Neto", "TotalIncomeNotTaxable":"Total Sindical"}
const renameKeysInObject = (keysMap: any, obj :any) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );

export const renamePropertiesSpanish = ( arrayOfObjects: any[], keysMap = tablas) =>
  arrayOfObjects.map(obj => renameKeysInObject(keysMap, obj));

export const renamePivots = (arrayOfPivots: any[]) : any[] =>{
 const a = arrayOfPivots.map(elemento => tablas[elemento] ? tablas[elemento] : elemento)
 return a
} 

