import { gql } from "@apollo/client";

export const GET_ALL_CAMPAIGNS_PREV = gql`
    query GET_ALL_CAMPAIGNS {
        GET_ALL_CAMPAIGNS {
        id
        nameCampaign
        sedes {
            id
            sede
        }
        areas {
            id
            area
        }
        collaborators {
          id
         idUsuario
        }
        minObjectives
        maxObjectives
        minQualification
        campaignBond
        descriptionBond
        startCampaign
        endCampaign
        descriptionCampaign
        step
        active
        countObjetivos
        countColaboradores
        countObjetivosEvaluados
        objetivosProgresoEsperado
        objetivosProgresoReal
        objetivosIndiceExito
        typeBond
        pctAvance
        objetivesAvance {
             id
             objetiveEvidence
          }
        }
  }
`;

export const GET_ALL_CAMPAIGNS = gql`
  query GET_ALL_CAMPAIGNS {
    GET_ALL_CAMPAIGNS {
      id
      nameCampaign
      startCampaign
      endCampaign
      step
      campaignBond
      descriptionBond
      minObjectives
      maxObjectives
      minQualification
      descriptionCampaign
      active
      remandingDays
      expectedProgress
      createdObjectives
      finishedObjectives
      pctAvance
      sedes {
        id
        sede
      }
      areas {
        id
        area
      }
      collaborators {
          id
         idUsuario
        }
      totalCollaborators      
      typeBond
      objetivesAvance {
      id
      objetiveEvidence
    }
    }
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
query GET_CAMPAIGN_BY_ID($id: ID! ) {
  GET_CAMPAIGN_BY_ID(id: $id) {
    id
    nameCampaign
    startCampaign
    endCampaign
    step
    campaignBond
    descriptionBond
    minObjectives
    maxObjectives
    minQualification
    descriptionCampaign
    active
    remandingDays
    expectedProgress
    createdObjectives
    finishedObjectives
    sedes {
      id
      sede
    }
    areas {
      id
      area
    }
    collaborators {
      id
      idUsuario
    }
    totalCollaborators    
    status
    typeBond
  }
}
`;

export const GET_CAMPAIGN_BY_ID_LEADER = gql`
query GET_CAMPAIGN_BY_ID_LEADER($idLeader: ID!, $idCampaign: ID! ) {
  GET_CAMPAIGN_BY_ID_LEADER(idLeader: $idLeader,idCampaign: $idCampaign) {
    active
    campaignBond  
    createdObjectives   
    endCampaign 
    id
    maxObjectives
    minObjectives
    minQualification
    nameCampaign
    objetivesAvance {
      id
      objetiveEvidence
    }
    remandingDays
    startCampaign
    status
    step
    totalCollaborators
    typeBond
    collaborators {
      idUsuario
      id
    }
  }
}
`;

export const GET_INFO_COLABORATORS_CAMPAIGN = gql`
query GET_INFO_COLABORATORS_CAMPAIGN($id: ID! ) {
  GET_INFO_COLABORATORS_CAMPAIGN(id: $id) {
    name
    objectivesCount
  }
}
`;


export const CREATE_CAMPAIGN = gql`
  mutation CREATE_CAMPAIGN($input: campaign_Input) {
    CREATE_CAMPAIGN(input: $input)
   }
`;

export const UPDATE_CAMPAIGN_ACTIVE = gql`
mutation UPDATE_CAMPAIGN_ACTIVE($id:ID!) {
  UPDATE_CAMPAIGN_ACTIVE(id: $id)
}
`;

export const UPDATE_CAMPAIGN_STEP = gql`
mutation UPDATE_CAMPAIGN_STEP($id:ID!,$stepValue:String! ) {
  UPDATE_CAMPAIGN_STEP(id: $id, stepValue:$stepValue )
}
`;

export const UPDATE_CAMPAIGN = gql`
mutation UPDATE_CAMPAIGN($id: ID!,$input: campaign_Input) {
  UPDATE_CAMPAIGN(id: $id, input:$input)
}
`;

export const DELETE_CAMPAIGN = gql`
mutation DELETE_CAMPAIGN($id: ID!) {
  DELETE_CAMPAIGN(id: $id)
}
`;

export const GET_CAMPAIGNS_BY_DATE = gql`
query GET_CAMPAIGNS_BY_DATE($startCampaign: String!,$endCampaign:String!) {
  GET_CAMPAIGNS_BY_DATE(startCampaign: $startCampaign, endCampaign: $endCampaign) {
    id
    nameCampaign
    startCampaign
    endCampaign
    step
    campaignBond
    descriptionBond
    minObjectives
    maxObjectives
    minQualification
    descriptionCampaign
    active
    remandingDays
    expectedProgress
    createdObjectives
    finishedObjectives
    sedes {
      id
      sede
    }
    areas {
      id
      area
    }
    totalCollaborators
    realProgress
  }
}
`;

export const CREATE_DUPLICATE_CAMPAIGN = gql`
  mutation CREATE_DUPLICATE_CAMPAIGN($createDuplicateCampaignId: ID!) {
    CREATE_DUPLICATE_CAMPAIGN(id: $createDuplicateCampaignId)
}
`;

export const GET_HISTORICAL_CAMPAIGNS = gql`
  query GET_HISTORICAL_CAMPAIGNS {
    GET_HISTORICAL_CAMPAIGNS {
      id
      nameCampaign
      startCampaign
      endCampaign
      step
      campaignBond
      descriptionBond
      minObjectives
      maxObjectives
      minQualification
      descriptionCampaign
      active
      remandingDays
      expectedProgress
      createdObjectives
      finishedObjectives
      sedes {
        id
        sede
      }
      areas {
        id
        area
      }
      totalCollaborators
      # realProgress
    }
  }
`;

export const GET_HISTORICAL_CAMPAIGNS_BY_DATE = gql`
  query GET_HISTORICAL_CAMPAIGNS_BY_DATE($startCampaign: String!,$endCampaign:String!) {
    GET_HISTORICAL_CAMPAIGNS_BY_DATE(startCampaign: $startCampaign, endCampaign: $endCampaign) {
      id
      nameCampaign
      startCampaign
      endCampaign
      step
      campaignBond
      descriptionBond
      minObjectives
      maxObjectives
      minQualification
      descriptionCampaign
      active
      remandingDays
      expectedProgress
      createdObjectives
      finishedObjectives
      sedes {
        id
        sede
      }
      areas {
        id
        area
      }
      totalCollaborators
      realProgress
    }
  }
`;

export const APPROVE_OBJECTIVES = gql`
  mutation APPROVE_OBJECTIVES($input: approveObjectivesInput) {
    APPROVE_OBJECTIVES(input: $input)
  }
`;

export const UPDATE_OBJECTIVES = gql`
  mutation UPDATE_OBJECTIVES($input: approveObjectivesInput) {
    UPDATE_OBJECTIVES(input: $input)
  }
`;

export const NOTIFY_LEADER = gql`
  mutation NOTIFY_LEADER($input: approveObjectivesInput) {
    NOTIFY_LEADER(input: $input)
  }
`;

export const VERIFY_APROVE_OBJECTIVES = gql`
  mutation VERIFY_APROVE_OBJECTIVES($input: approveObjectivesInput) {
    VERIFY_APROVE_OBJECTIVES(input: $input)
  }
`;