import Swal from "sweetalert2";

export const errorAlert2 = ({
    title = '¡Error!',
    text = ''
}) => Swal.fire({
    icon: 'error',
    title,
    text,      
    focusDeny:true,
    customClass: {
        confirmButton: 'confirmButton',
    }
});