/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import ObjectiveRowOptions from "../Modals/ObjectiveRowOptions";
import ObjectiveRowOptionsAdmin from "../Modals/ObjectiveRowOptionsAdmin";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { formatter } from "../../../helpers/formatoMoneda";
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import styles from "./ObjectivesTableApprove.module.css";

const ObjectivesTableApprove = (props:any) => {  
  const { state, dispatch } = useContext(ObjectivesContext);  
  const [isCheckRes, setIsCheckRes] =  useState(0)
  const [activeCheck, setActiveCheck] =  useState(true)  
  const allPayrollCollaborator:any = props.rows;

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    props.setBtnAproveObjectives(true)
    props.setIsCheck([...props.isCheck, id])
    if(!checked ){      
      props.setIsCheck(props.isCheck.filter((lis:any) => lis !== id))      
    }         
  }

  useEffect(() => {
    if(props.rows.length>0){
      if(props.rows[0].Totalobjectives ===props.rows[0].aprobdasTotal){
        setActiveCheck(false)
      }      
    }
    }, [props.rows]);


   useEffect(() => {
    let numberArray=props.isCheck.length
    if(isCheckRes ===numberArray ){
      props.setBtnAproveObjectives(false)
    }
    }, [props.isCheck]);

  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      props.setIsCheck([])
      props.setBtnAproveObjectives(false)    
      return
    }
    let filtradoSeleccionado = (allPayrollCollaborator.filter((lis:any)=>{
            return e.target.checked && !props.isCheck.includes(lis.id)
    }).map((lis:any) =>(lis.id)))
     props.setIsCheck(filtradoSeleccionado)
     props.setBtnAproveObjectives(true)    
  }

 useEffect(() => {
   if (allPayrollCollaborator.length !== 0) {
     let arrayAprove: any=[]
     // eslint-disable-next-line array-callback-return
     allPayrollCollaborator.map((lis:any)=>{
      if(lis.objetiveSteps === 'Aprobada'){
        arrayAprove.push(lis.id)    
      }
    })
    props.setIsCheck(arrayAprove)   
    setIsCheckRes(arrayAprove.length)
   } 
  }, [allPayrollCollaborator]);
  console.log(props.rows )
  
  return (
    <Box display="flex" flexDirection="column" p={2}> 
        <div className={styles.pc_contenedorTabla}>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          {activeCheck ?
           <TableCell align="left">
            <FormGroup>
            <FormControlLabel  control={<Checkbox  
                                        onChange={(e) => handleSelectAll(e)}
                                        style ={{color: "#FABB00",}}/>} label="Nombre del Objetivo" />
           </FormGroup>          
            </TableCell>:
            <TableCell align="left">
            <FormGroup>
            <FormControlLabel  control={<Checkbox  
                                        onChange={(e) => handleSelectAll(e)}
                                        disabled
                                        style ={{color: "#FABB00",}}/>} label="Nombre del Objetivo" />
           </FormGroup>          
            </TableCell>
            }
            <TableCell align="left">Indicador</TableCell>
            <TableCell align="left">Tipo de Indicador</TableCell>
            <TableCell align="left">Metrica Objetivo</TableCell>
            <TableCell align="left">Peso</TableCell>
            {state?.campaign?.step !== 'definir'?
            <>
            <TableCell align="left">Evidencias</TableCell>
            <TableCell align="left">Progreso</TableCell>
            </>
            :
            <></>
            }
            <TableCell align="left">Estatus</TableCell>
            <TableCell align="left">Opciones</TableCell>           
          </TableRow>
        </TableHead>
        <TableBody>
        {props.rows?.map((row: any, index: any) => (  
           <TableRow
           key={index}
           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
         >
         
            {row.objetiveSteps.toUpperCase() === 'Creada'.toUpperCase()?
             <TableCell align="left" >
             <FormGroup>
             <FormControlLabel  control={<Checkbox  
                                        id={row.id}
                                        key={row.id}
                                        checked ={props.isCheck.includes(row.id)}
                                        onChange={(e) => handleClick(e)}                                           
                                        style ={{color: "#FABB00",}}/>} 
                                        label={row.ObjectiveName} />
            </FormGroup>                  
             </TableCell>:
             <TableCell align="left" >
             <FormGroup>
             <FormControlLabel  control={<Checkbox  
                                        id={row.id}
                                        key={row.id}
                                        checked ={props.isCheck.includes(row.id)}
                                        onChange={(e) => handleClick(e)}  
                                        disabled                                         
                                        style ={{color: "#FABB00",}}/>} 
                                        label={row.ObjectiveName} />
            </FormGroup>                  
             </TableCell>             
            }
             <TableCell align="left">{row.nameIndicator}</TableCell>
             <TableCell align="left">{row.metricIndicator}</TableCell>
             {row.metricIndicator === 'pesos'?              
              <TableCell align="left">{formatter(row.metricObjective)} </TableCell>:
               row.metricIndicator === 'porcentaje'?
              <TableCell align="left">{row.metricObjective} %</TableCell>:
              <TableCell align="left">{row.metricObjective} </TableCell>
             }
             <TableCell align="left">{row.weight} % </TableCell>
           
             {state?.campaign?.step !== 'definir'?
            <>
             <TableCell align="left">{row.evidenceCount} </TableCell>
             <TableCell align="left">{row.Progreso} </TableCell>
            </>
            :
            <></>
            }
            
           { row.objetiveSteps.toUpperCase() === 'Creada'.toUpperCase()?
              <TableCell align="left">
                <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                   <div className={styles.pd_textoBotonEstatusDos}>
                     Pendiente
                 </div>
                </div>
              </TableCell>:
               <TableCell align="left">
                <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                  <div className={styles.pd_textoBotonEstatusCuatro}>
                    Aprobado
                  </div>
                  <div className={styles.pd_iconoEstatusCuatro}></div>
                </div> 
               </TableCell>               
              }

               { state?.rol === 'admin'?
                  <TableCell align="left"><ObjectiveRowOptionsAdmin 
                                      {...row}
                                      addColObj={props.addColObj}
                                      maxObjInd={props.maxObjInd}  
                                      weightObjectives={props.weightObjectives}                                     
                                      /> </TableCell>
                :<TableCell align="left"><ObjectiveRowOptions 
                {...row}
                addColObj={props.addColObj}
                maxObjInd={props.maxObjInd}  
                weightObjectives={props.weightObjectives}                                     
                /> </TableCell>
               }
          </TableRow>
        ))}
         </TableBody>
      </Table>
    </TableContainer>
   </div>      
    </Box>
  );
};

export default ObjectivesTableApprove;