/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import {
  GET_ALL_DATAREPORTS,
  GET_DATAREPORTS,
  GET_CUSTOMDATAREPORT,
  GET_CUSTOM_REPORT_PREVIEW
} from "../../Querys/reports/querys";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import ReportIndividual from "./ReportIndividual";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import CachedIcon from '@mui/icons-material/Cached';
import GridOnIcon from '@mui/icons-material/GridOn';
import SendIcon from '@mui/icons-material/Send';
import DownExcel from "../../assets/icons/downExcel.svg";
import TuneIcon from '@mui/icons-material/Tune';
import { DownloadExcel } from "../../helpers/DownloadExcel";
import { CREATE_CUSTOM_REPORT, GET_ALL_CUSTOMREPORTS } from "../../Querys/querys";
import ReportContext from "../../context/ReportsContext/ReportContext";
import AddTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import { SuccessfulAlert } from "../../alerts/successAlerts";
import { renamePivots, renamePropertiesSpanish } from "../../helpers/renamePropertiesSpanish";
import { AttentionAlert2 } from "../../alerts/attentionAlert2";

const ReportInfo = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { state, dispatch } = useContext(ReportContext);
  const [infoReport, setInfoReport] = useState<Array<any>>([]);
  const [report, setReport] = useState<Array<any>>([]);  
  const [reportFilter, setReportFilter] = useState<Array<any>>([]);
  const [viewFilter, setViewFilter] = useState(true)
  const [selViewFilter, setSelViewFilter] = useState('#093C5D')
  const [viewSquare, setViewSquare] = useState(false)
  const [selViewSquare, setSelViewSquare] = useState('#707070')
  const [reportType, setReportType] = useState('')
  const [reportName, setReportName] = useState('')
  const [camposBd, setCamposBd] = useState<Array<any>>([]);  
  const [camposBdArray, setCamposBdArray] = useState<Array<any>>([]); 
  const [inpuntBdValues, setInpuntBdValues] = useState({}); 
  const [deleteColumns, setDeleteColumns] = useState([]);
  const [inpuntValues, setInpuntValues] = useState({});
  const [reload, setReload] = useState(false);
  const [columnas, setColumnas] = useState<Array<any>>([]);
  const [filas, setFilas]= useState<Array<any>>([]);
  const [camposPivotes, setCamposPivotes] = useState<Array<any>>([]);
  const [previewLoading, setPreviewLoading] = useState(true)
  const [getReportSingle] = useLazyQuery(GET_DATAREPORTS);
  const [getCustomReportPreview] = useLazyQuery(GET_CUSTOM_REPORT_PREVIEW);
  const [getCustomReportSingle]= useLazyQuery(GET_CUSTOMDATAREPORT);
  //console.log(id)

  const { data: reportsInfo } = useQuery(GET_ALL_DATAREPORTS,
    {
      fetchPolicy: 'no-cache',
      skip: id.includes('custom-') || id.includes('preview')
    }
  );

  const { data: customReportsInfo } = useQuery(GET_ALL_CUSTOMREPORTS,
    {
      fetchPolicy: 'no-cache',
      skip: !id.includes('custom-') || id.includes('preview')
    }
  );

  const { data: reportIndividual, loading } = useQuery(GET_DATAREPORTS, {
    variables: { getDatareportsId: id },
    skip: id.includes('custom-') || id.includes('preview'),
    fetchPolicy: 'no-cache'
  });

  const { data: customReportIndividual, loading: loadingCustomReport } = useQuery(GET_CUSTOMDATAREPORT, {
    variables: { getDatareportsId: id?.replace('custom-', '') },
    skip: !id.includes('custom-') || id.includes('preview'),
    fetchPolicy: 'no-cache'
  });
  const [createCustomReport] = useMutation<{
    variables: {
      input: {
        name: string;
        rows?: any[];
        columns?: any[];
        filterJson?: any[];
      }
    }
  }>(CREATE_CUSTOM_REPORT);

  const filterMyReports = (arrayReports: any) => {
    let reports = arrayReports.filter((report: any) => {
      return report.id === id
    }
    )
    return reports
  }
  const getPreviewInfo = async () => {
    let { data } = await getCustomReportPreview({
      variables: { input: state.previewData },
    })
    if (data?.GET_CUSTOM_REPORT_PREVIEW?.length > 0) {
      const reportJson = JSON.parse(data.GET_CUSTOM_REPORT_PREVIEW)
      setReport(reportJson);
      setReportFilter(reportJson);
      let pivotsPreview: any[] = []
      const filter = state.previewData?.filterJson === null ? {} : JSON.parse(state.previewData.filterJson)
      //optenemos las columnas y filas
      const columnsPreview = state.previewData?.columns?.map((item: any) =>  item.columnName)
      const rowsPreview = state.previewData?.rows?.map((item: any) => item.columnName)
      //filtrado de pivotes     
      pivotsPreview = [...state.previewData?.columns, ...state.previewData?.rows]
      pivotsPreview =  pivotsPreview.flatMap(({columnName, isPivot }) => isPivot ? [columnName] : []);

      //Eliminamos pivotes duplicados en caso de existir y agregamos los pivotes dado que si existen
      if(pivotsPreview.length > 0 ){
        const lol = pivotsPreview.filter((item,
          index) => pivotsPreview.indexOf(item) === index);

          setCamposPivotes(lol)
      }
 
      setColumnas(columnsPreview);
      setFilas(rowsPreview);
      setInpuntBdValues(filter)
      setPreviewLoading(false);
    }
    
  }

  useEffect(() => {
    if (state.previewData) {
      getPreviewInfo();
    }
  }, [state.previewData])

  useEffect(() => {
    if (reportsInfo && reportsInfo.GET_ALL_DATAREPORTS && !id.includes('custom-')) {
        setInfoReport(filterMyReports(reportsInfo.GET_ALL_DATAREPORTS));  
    }
  }, [reportsInfo]);

  useEffect(() => {
    if (infoReport.length > 0) {
      setReportType(infoReport[0]?.ReportType)
      setReportName(infoReport[0]?.ReportName)      
      if(infoReport[0]?.campos !== null){
         let splitCampos=infoReport[0]?.campos.split(',')
         const splitCamposSpanish = renamePivots(splitCampos)
         setCamposBdArray(splitCamposSpanish)
      }else{ 
      setCamposBdArray([])
    }      
      setCamposBd(infoReport[0]?.campos)  
     //console.log("camposbd", camposBd)   
     //inpuntBdValues, setInpuntBdValues
     if(infoReport[0]?.filtros !== null ){
       setInpuntBdValues(JSON.parse(infoReport[0]?.filtros))      
      }
      else{ 
      setInpuntBdValues({})  
     }
   }
  }, [infoReport]);


  useEffect(() => {    
    if (customReportsInfo && customReportsInfo.GET_ALL_CUSTOMREPORTS && id.includes('custom-') ) {
      setInfoReport(filterMyReports(customReportsInfo.GET_ALL_CUSTOMREPORTS.map((customReport: any) => ({        
        ReportDate: customReport.dateAdd,
        ReportDescription: "",
        ReportName: customReport.name,
        ReportSQL: customReport.sqlQueryRows + ';' + customReport.sqlQueryColumns,
        ReportType: "Personalizado",
        id: 'custom-' + customReport.id,
        userAdd: "custom_report",
        campos: null,
        filtros: customReport.filtros
      }))));
    }
  }, [customReportsInfo]);

  useEffect(() => {
    if (reportIndividual && reportIndividual.GET_DATAREPORTS && !id.includes('custom-')) {
      const objReport = renamePropertiesSpanish(JSON.parse(reportIndividual.GET_DATAREPORTS));
      setReport(objReport)
      setReportFilter(objReport)
      setColumnas(Object.keys(objReport[0]))
    }
  }, [reportIndividual]);

  useEffect(() => {
    if (customReportIndividual && customReportIndividual.GET_CUSTOMDATAREPORT && id.includes('custom-')) {
      const objReport = JSON.parse(customReportIndividual.GET_CUSTOMDATAREPORT);
      setReport(objReport?.query)
      setReportFilter(objReport?.query)
      setFilas(objReport?.filas)
      setColumnas(objReport?.columnas)
      const pivotes2 = renamePivots(objReport?.pivotes?.map((el: any) =>  el.dataColumn))
      setCamposPivotes(pivotes2)
    }
  }, [customReportIndividual]);

   const handleViewFilter = () => {
      viewFilter === true ? setViewFilter(false) : setViewFilter(true);
      viewFilter === true ? setSelViewFilter('#707070') : setSelViewFilter("#093C5D");
    }

  const handleDownExcel = () => {
    if (report.length > 0) {
      const columns = report.map((re: any) => {
        return (Object.keys(re))
      });

      const valuesRep = report.map((data: any) => {
        return Object.values(data)
      });
      let nameFile = infoReport[0]?.ReportName
      let sheetName = infoReport[0]?.ReportName
      DownloadExcel(nameFile, sheetName, columns[0], valuesRep);
    }
  }

  const handleViewSquare = () => {
    viewSquare === false ? setViewSquare(true) : setViewSquare(false);
    viewSquare === false ? setSelViewSquare("#093C5D") : setSelViewSquare('#707070');
  }

  const handleReload = async () => {
    setReload(false)
    setDeleteColumns([]);
    setInpuntValues({});
    
    if(!id.includes('custom-')){
      let { data } = await getReportSingle({
        variables: { getDatareportsId: id },
      })
      if (data?.GET_DATAREPORTS?.length > 0) {
        const reportJson = renamePropertiesSpanish(JSON.parse(data.GET_DATAREPORTS))
        setReport(reportJson);
        setReportFilter(reportJson)
        setColumnas(Object.keys(reportJson[0]))
        const valor = JSON.parse(infoReport[0]?.filtros) === null ? [] : JSON.parse(infoReport[0]?.filtros)
        setInpuntBdValues(valor)
        setReload(true)
      }

    }else{
      let {data} = await getCustomReportSingle({
        variables: { getDatareportsId: id?.replace('custom-', '') },
      })
      if(data?.GET_CUSTOMDATAREPORT?.length > 0){
        const reportJson = JSON.parse(data.GET_CUSTOMDATAREPORT)
        setReport(reportJson?.query)
        setReportFilter(reportJson?.query)
        setFilas(reportJson?.filas)
        setColumnas(reportJson?.columnas)
        const valor = JSON.parse(infoReport[0]?.filtros) === null ? [] : JSON.parse(infoReport[0]?.filtros)
        setInpuntBdValues(valor)
        setReload(true)

      }
    }    
  }

  const goBack = () => {
    dispatch({
      type: 'PREVIEW_DATA',
      payload: null
    })
    history.push('/informes')
  }

  const createReport = async () => {
    if(state.previewData.name && state.previewData.rows.length > 0 && state.previewData.columns.length > 0){
      await createCustomReport({
        variables: {
          input: {
            name: state.previewData?.name,
            ...(state.previewData?.rows?.length > 0 && { rows: state.previewData?.rows }),
            ...(state.previewData?.columns?.length > 0 && { columns: state.previewData?.columns }),
            ...(state.previewData?.filterJson && {filterJson: state.previewData?.filterJson})
          }
        }
      })
      await SuccessfulAlert({ text: "Se ha creado el informe correctamente" });
      goBack()
    }else{
      let faltanes: any[] =[]
      Object.keys(state.previewData).map((val :any) => {
        if(!state.previewData[val] || state.previewData[val].length === 0){
          let spanish = ''
          switch(val){
            case 'name':
              spanish = " el nombre del informe"
              break;
            case 'rows':
              spanish = ' las filas'
              break
            case 'columns':
              spanish = ' las columnas'
              break
          }
          faltanes.push(spanish)
        }
      })

      let text2 = faltanes.join(',')
      
      await AttentionAlert2({text: `No es posible crear este informe, faltan datos requeridos (${text2}) `})
    }
  }
      
  return (
    <div>
      <Box mt={3} ml={5} className="TitleReportSingle" mb={2} p={5} pb={3} pt={0}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
        >
          <Grid className="btnsInformes">
            <Grid className="btnsInformesFiltros" >
              {state.previewData && <>
                {`Personalizado / Previsualizaci�n / ${state.previewData?.name}`}
              </>}
               {state.previewData === null && (
                <>
                   {`${reportType} / ${reportName}`} 
                </>
              )}
            </Grid>
            {
              state.previewData === null && (
                <Grid className="actionsReports" >
                  <TuneIcon
                    className="pointR"
                    sx={{ color: selViewFilter }}
                    onClick={() => handleViewFilter()}
                  />
                  <img
                    className='downExcelReport'
                    src={DownExcel}
                    onClick={() => handleDownExcel()
                    }
                    alt="descargar excel" />
                  <CachedIcon
                    className="pointR"
                    sx={{ color: '#093C5D' }}
                    onClick={() => handleReload()}
                  />
                  <div className="r-line"></div>
                  <GridOnIcon
                    className="pointR"
                    sx={{ color: selViewSquare }}
                    onClick={() => handleViewSquare()}
                  />
                  <div className="r-line"></div>
                  <SendIcon
                    className="senR"
                    sx={{ color: '#093C5D' }}
                  />
                </Grid>
              )
            }
            {
              state.previewData && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '20px',
                }}>
                  <button style={{
                    width: '168px',
                    height: '38px',
                    borderRadius: '6px',
                    background: 'white',
                    border: '1px solid #c7ccdc',
                    fontFamily: 'Roboto, sans-serif',
                    fontSize: '14px',
                    // fontWeight: '700',
                    lineHeight: '16px',
                    letterSpacing: '0px',
                    color: '#093c5d',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                    type="button" onClick={goBack}>Regresar</button>
                  <AddTable
                    func={createReport}
                    text="Crear informe"
                    img="report-icon.svg"
                    type="button"
                  />
                </div>
              )
            }
          </Grid>
        </Grid>
      </Box>
      <div className="contenedorReportSingle">
        <ReportIndividual
          infoReport={infoReport}
          camposBd={camposBd}
          camposBdArray={camposBdArray}
          setCamposBdArray={setCamposBdArray}
          inpuntBdValues={inpuntBdValues}
          setInpuntBdValues={setInpuntBdValues}
          report={report}          
          setReport={setReport}
          reportFilter={reportFilter}
          setReportFilter={setReportFilter}
          reload={reload}
          setReload={setReload}
          viewFilter={viewFilter}
          viewSquare={viewSquare}
          loading={!id.includes('custom-') && !id.includes('preview') ? loading : 
            id.includes('custom-') && !id.includes('preview') ? loadingCustomReport :
            previewLoading
          }
          setDeleteColumns={setDeleteColumns}
          deleteColumns={deleteColumns}
          inpuntValues     = {inpuntValues}
          setInpuntValues  = {setInpuntValues}
          columnas = {columnas}
          setColumnas = {setColumnas}
          filas = {filas}
          setFilas = {setFilas}
          camposPivotes={camposPivotes}
        />
      </div>
      <button style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    height: '24px'
                  }}
                    onClick={goBack}
                  >
                    <img src="/assets/svg/back-button.svg" alt="Regresar" />
                    <span style={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      // fontWeight: '400',
                      lineHeight: '19px',
                      color: '#222222',
                      marginLeft: '20px'
                    }}>Regresar</span>
                  </button>  
    </div>
  )
}

export default ReportInfo
