/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/space-before-blocks */
export class Queue {
    private _items: any[] = [];
  
    enqueue(item: any) {
      this._items.push(item);
    }
  
    dequeue() {
      return this._items.shift();
    }
  
    isEmpty() {
      return this._items.length === 0;
    }
  
    size() {
      return this._items.length;
    }
  }
  