import { useContext } from "react";
import {
  GET_CUSTOMDATAREPORT,
  GET_DATAREPORTS
} from "../../../Querys/reports/querys";
import { useLazyQuery } from "@apollo/client";
import ReportContext from "../../../context/ReportsContext/ReportContext";
import { useHistory } from 'react-router-dom';
import { editReportsModal, deleteReportsModal } from "../../../context/ReportsContext/Actions"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment-timezone"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateIcon from '@mui/icons-material/Create';
import DownExcel from "../../../assets/icons/downExcel.svg";
import { DownloadExcel } from "../../../helpers/DownloadExcel";

import { renamePropertiesSpanish } from "../../../helpers/renamePropertiesSpanish";
import { AdminContext } from "../../../context/AdminContext/AdminContext";

const TableReport = ({ reports }: any) => {
  const { adminState } = useContext(AdminContext);
  const { dispatch } = useContext(ReportContext)
  const history = useHistory();
  const [getReportSingle] = useLazyQuery(GET_DATAREPORTS);
  const [getCustomReportSingle] = useLazyQuery(GET_CUSTOMDATAREPORT);
 
 
  const handleEliminar = (id: any, reportType: any = null) => {
    const tipo = reportType === "Personalizado" ? "Personalizado" : reportType
    deleteReportsModal(id, true, tipo ,dispatch)
  }

  const handleEditReportPredefined = (id: any) => {
    editReportsModal(id, true, dispatch)
  }

  const handleEditReport = (id: any, reportType: any = null) => {
   if (reportType === "Personalizado") {
      history.push(`/informes/custom-${id}`)
    } 
    else {
      history.push(`/informes/${id}`)
    }
  }

  const handleDownloadReport = async (id: any) => {
    let reportById = reports.filter((report: any) => {
      return report.id === id
    })

    if (reportById[0]?.ReportType === "Personalizado") {
      let { data } = await getCustomReportSingle({
        variables: { getDatareportsId: id },
      })
      if (data?.GET_CUSTOMDATAREPORT?.length > 0) {
        const reportJson = JSON.parse(data.GET_CUSTOMDATAREPORT)
        const columns = reportJson?.query.map((re: any) => {
          return (Object.keys(re))
        })
        const valuesRep = reportJson?.query.map((data: any) => {
          return Object.values(data)
        })
        let nameFile = reportById[0]?.ReportName
        let sheetName = reportById[0]?.ReportName
        DownloadExcel(nameFile, sheetName, columns[0], valuesRep);
      }
    } else {
      let { data } = await getReportSingle({
        variables: { getDatareportsId: id },
      })
      if (data?.GET_DATAREPORTS?.length > 0) {
        const reportJson = renamePropertiesSpanish(JSON.parse(data.GET_DATAREPORTS))
        const columns = reportJson.map((re: any) => {
          return (Object.keys(re))
        })
        const valuesRep = reportJson.map((data: any) => {
          return Object.values(data)
        })
        let nameFile = reportById[0]?.ReportName
        let sheetName = reportById[0]?.ReportName
        DownloadExcel(nameFile, sheetName, columns[0], valuesRep);
      }

    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow className='pd_contenedorTitulos'>
            <TableCell className='cellSpanTitleReport' align="left">Tipo de Informe</TableCell>
            <TableCell className='cellSpanTitleReportName' align="left">Nombre</TableCell>
            <TableCell className='cellSpanTitleReportDes' align="left">Descripción</TableCell>
            <TableCell className='cellSpanTitleReport' align="left">Fecha de creación</TableCell>
            <TableCell className='cellSpanTitleButonns' align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reports.map((report: any) => (
            <TableRow key={`${report.id}${report.ReportType}`}>
              <TableCell component="th" scope="row">
                {report.ReportType}
              </TableCell>
              <TableCell align="left">{report.ReportName}</TableCell>
              <TableCell align="left">{report.ReportDescription}</TableCell>
              <TableCell align="left"> {moment(report.ReportDate).format("DD/MM/YYYY")}</TableCell>
              <TableCell align="left">
                <div className='containerIcons'>
                  {report.userAdd !== null && (report.ReportType === 'Personalizado' || adminState?.Usuario === report.userAdd ) ?
                    <>
                      <DeleteOutlineIcon
                        className='deleteReport'
                        sx={{ color: '#E0544B' }}
                        onClick={() => handleEliminar(report.id, report.ReportType)}
                      />
                      <CreateIcon
                        className='editReport'
                        sx={{ color: '#093C5D' }}
                        onClick={() => handleEditReport(report.id, report.ReportType)}
                      />
                    </>
                    :
                    <>
                      <DeleteOutlineIcon color="disabled" />
                      <CreateIcon
                        className='editReport'
                        sx={{ color: '#093C5D' }}
                        onClick={() => handleEditReportPredefined(report.id)}
                      />
                    </>
                  }
                  <img
                    className='downExcelReport'
                    src={DownExcel} alt="descargar excel"
                    onClick={() => handleDownloadReport(report.id)}
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableReport

