import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import styles from "../../src/components/Payroll/PayrollStyles.module.css";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import { GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION, GET_EVENTUALPAYROLL, GET_ALL_COMPANY, GET_PAYROLL_DISPERSAR_EVENTUAL } from "../Querys/querys";
import { useContext, useEffect, useState, useCallback } from "react";
import { getDateDay, getDateYear } from "../helpers/Payroll/Payroll";
import { formatter } from "../helpers/formatoMoneda";
import SinNominasModal from "../components/Payroll/Modals/SinNominasModal";
import PayrollProcessContext from "../context/PayrollProcess/PayrollProcessContext";
import FondosInsufucientesModal from '../components/Payroll/Modals/FondosInsuficientesModal';
import { 
  openfondosInsuficientes,
  openSinNominasSeleccionadas,
  openaceptarDispersarEventual,
  openAceptarDispersarIndividualEventual,
  openModalAlertaNoGravable,
  openModalOutsorcersResumenActivo
} from "../context/PayrollProcess/Actions";
import AceptarDispersarEventual from "../components/Payroll/Modals/AceptarDispersarEventual";
import AceptarDispersarIndividualEventual from "../components/Payroll/Modals/AceptarDispersarIndividualEventual";
import AlertaNoGravable from "../components/Payroll/Modals/AlertaNoGravable";
import ResumenOutsourcers from "../components/Payroll/Modals/Outsourcers/ResumenOutsourcers";
import { CreateExcelFilePayrollEvDL } from "../components/Payroll/Modals/ExcelPayrollEvDL";
import { CreateExcelFilePayrollEvD } from "../components/Payroll/Modals/ExcelPayrollEvD";
import LockedSystem from "../alerts/LockedSystem";
import { ErrorAlert } from "../alerts/errorAlert";
import CheckPermission from "../helpers/Administradores/Permissions";

const EventualPayrollDispersion = () =>{
  const {dispatch} = useContext(PayrollProcessContext)
  const { id, tab } = useParams<any>();
  const history = useHistory();
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true)
  const [inicio, setInicio] = useState(0)
  const [total, setTotal] = useState(0)
  const [disponibleDispersar, setDisponibleDispersar] = useState(true)
  const [diferencia, setDiferencia] = useState(0)
  const [filtrado, setFiltrado] = useState<any[]>([])
  const [datosTablas, setDatosTablas] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [isCheckInactivos, setIsCheckInactivos] = useState<any[]>([]);
  const [arrayFilter, setArrayFilter] = useState<any[]>([]);
  const [procesoDispersion, setProcesoDispersion] = useState(false);
  const [dispersados,setDispersados] = useState(0);
  const [randomRequest, setRandomRequest] = useState(Math.random() * 10000);

  const updateDataAfterDispersar = () => {
    setRandomRequest(Math.random() * 10000);
  }

  const {data: dataDipsersionEventual, loading: loadingDispersionEventual} = useQuery(GET_PAYROLL_DISPERSAR_EVENTUAL,{
    variables:{getPayrollDispersionId: id, randomRequest}
  })
  const PayrollDataDispersion = dataDipsersionEventual?.GET_PAYROLL_DISPERSAR_EVENTUAL
  const handleChange = () =>{
  }
  //Datos principales y headers
  const {loading:loadingPrincipal, data:dataPrincipal} = useQuery(GET_EVENTUALPAYROLL,{
    variables:{getEventualPayrollId: id}
  })

  const PayrollData = dataPrincipal?.GET_EVENTUALPAYROLL;

  //Datos de tablas
  const {data:dataDispersion, loading:loadingTabla, startPolling, stopPolling} = useQuery(GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION,{
    variables: { getEventualpayrollcollaboratorDispersionId: id },
  });

  const allPayrollDispersion = dataDispersion?.GET_EVENTUALPAYROLLCOLLABORATOR_DISPERSION;
  
  //Datos de archivos

  const busqueda = (e:any)=>{
      setSearch((current) => current = e.target.value)
    guardaPaginado()
  }
  /*
  const getDatosTabla = async() =>{
    setDatosTablas([...allPayrollDispersion]);
    let registrosLiquidado = (allPayrollDispersion.filter((e:any) => e.dispersionStatus ==='LIQUIDADO')).length;
    setDispersados((current) => current = registrosLiquidado)
  }
  */
  const getDatosTabla = useCallback(async() =>{
    setDatosTablas([...allPayrollDispersion]);
    let registrosLiquidado = (allPayrollDispersion.filter((e:any) => e.dispersionStatus ==='LIQUIDADO')).length;
    setDispersados((current) => current = registrosLiquidado)
  },[allPayrollDispersion])

  const guardaPaginado = () =>{
    const results = !search ? datosTablas : datosTablas?.filter(
      (dato) => (dato.colaborator.toLowerCase().includes(search.toLocaleLowerCase())
      ));
      setFiltrado((current) => current = results);
  }

  const handleSelectAll = (e:any,status:any,type:any) =>{
    let filtradoSeleccionado:any
    let res:any
    switch (type) {
      case 'PTU':
        if(status === 'activo')
         { 
           if(!e.target.checked){
               setIsCheck([])
               setArrayFilter([])               
            return    
           }
             filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{
             return e.target.checked && !isCheck.includes(lis.id) && (lis.dispersionStatus === "EnEspera") &&(lis.collaborator===true) }))
             
             if(arrayFilter.length>0){
              for (let clave in arrayFilter){
               if(arrayFilter[clave].baja === true){                  
                  return  ErrorAlert({text:'No es posible enviar a dispersión a colaboradores activos e inactivos.'});
                }
              }                        
            }else{                           
             setArrayFilter(filtradoSeleccionado)
             res = filtradoSeleccionado.map((lis:any) =>(lis.id))          
             setIsCheck(res)
            }          
             
         }
         if(status === 'inactivo')
          { 
            if(!e.target.checked){
                setIsCheck([])
                setArrayFilter([])               
             return    
            }
               filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{               
               return e.target.checked && !isCheck.includes(lis.id) && (lis.dispersionStatus === "EnEspera") &&(lis.baja===true) }))
               if(arrayFilter.length>0){
                for (let clave in arrayFilter){
                   if(arrayFilter[clave].collaborator ===true){
                     return  ErrorAlert({text:'No es posible enviar a dispersión a colaboradores activos e inactivos.'});
                  }
                }          
              }else{            
              setArrayFilter(filtradoSeleccionado)
               res = filtradoSeleccionado.map((lis:any) =>(lis.id))          
               setIsCheck(res)
              }              
          }
      break;
      default:
        if(!e.target.checked){
            setIsCheck([])           
          return    
       }
       filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{
       return e.target.checked && !isCheck.includes(lis.id) && (lis.dispersionStatus === "EnEspera")}).map((lis:any) =>(lis.id)))           
        setIsCheck(filtradoSeleccionado)
      break;
    }
   }

  
  // const handleSelectAllInactivos = (e:any) =>{
  //   if(!e.target.checked){
  //     setIsCheckInactivos([])
  //   }
  //   let filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{
  //     return e.target.checked && !isCheckInactivos.includes(lis.id) && (lis.dispersionStatus === "EnEspera")
  //   }).map((lis:any) =>(lis.id)))
  //   setIsCheckInactivos(filtradoSeleccionado)
  // }

  const handleClick =  (e:any,status:any,type:any) => {
    let filtradoSeleccionado:any
    const {id, checked} = e.target
    let res:any
    switch (type) {
      case 'PTU':
         if(status === 'activo'){         
          filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{          
          return lis.id===id && (lis.dispersionStatus === "EnEspera") && (lis.collaborator===true) }))
          if(arrayFilter.length>0){
            console.log(arrayFilter)
            for (let clave in arrayFilter){
             if(arrayFilter[clave].baja === true){                
                return  ErrorAlert({text:'No es posible enviar a dispersión a colaboradores activos e inactivos.'});
              }
            }
            setArrayFilter([...arrayFilter, filtradoSeleccionado[0]])
            res = filtradoSeleccionado.map((lis:any) =>(lis.id))          
            setIsCheck([...isCheck, res[0]])
          }else{            
           setArrayFilter([filtradoSeleccionado[0]])
           res = filtradoSeleccionado.map((lis:any) =>(lis.id))          
           setIsCheck([...isCheck, res[0]])
          }                   
          if(!checked){
            setIsCheck(isCheck.filter((lis:any) => lis !== id))
            setArrayFilter(arrayFilter.filter((lis:any) => lis !== id))
          }
        }
        if(status === 'inactivo'){
          filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{
            return lis.id===id && (lis.dispersionStatus === "EnEspera") && (lis.baja===true) }))
             if(arrayFilter.length>0){
              console.log(arrayFilter)
              for (let clave in arrayFilter){
                 if(arrayFilter[clave].collaborator === true){
                   setArrayFilter([])
                   return  ErrorAlert({text:'No es posible enviar a dispersión a colaboradores activos e inactivos.'});
                }
              }
              setArrayFilter([...arrayFilter, filtradoSeleccionado[0]])
              res = filtradoSeleccionado.map((lis:any) =>(lis.id))          
              setIsCheck([...isCheck, res[0]])
            }else{            
             setArrayFilter([filtradoSeleccionado[0]])
             res = filtradoSeleccionado.map((lis:any) =>(lis.id))          
             setIsCheck([...isCheck, res[0]])
            }
           if(!checked){
               setIsCheck(isCheck.filter((lis:any) => lis !== id))
               setArrayFilter(arrayFilter.filter((lis:any) => lis !== id))
            }
        }
      break;
      default:
        setIsCheck([...isCheck, id])
       if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }
        break;
    } 
    
  }
  
  // const handleClickInactivos =  (e:any) => {
  //   const {id, checked} = e.target
  //   setIsCheckInactivos([...isCheckInactivos, id])
  //   if(!checked){
  //     setIsCheckInactivos(isCheckInactivos.filter((lis:any) => lis !== id))
  //   }
  // }


  const actualizaSaldos = useCallback(( ) =>{
    let arrayTotales = allPayrollDispersion?.filter((lis: any) => {
      return isCheck.includes(lis.id);
    }).map((lis: any) => {
      return lis.netIncomeTaxable ?? lis.total;
    });

    if(arrayTotales?.length > 0) {
      const suma = (acc:any, curr:any) => acc +curr

      setTotal(arrayTotales.reduce(suma))
      setDiferencia(inicio - arrayTotales.reduce(suma))

      if((inicio - arrayTotales.reduce(suma)) >= 0){
        setDisponibleDispersar(true)
      }else{
        setDisponibleDispersar(false)
      }

    }else{
      setTotal(0)
      setDiferencia(inicio)
      setDisponibleDispersar(true)
    }
  },[allPayrollDispersion, inicio, isCheck]);

  const reinicioDatos = () =>{
    setIsCheck([])
    setArrayFilter([])
    actualizaSaldos()
  }

  const handleOnExport = (Tipo: any) => {
    if (Tipo === "Layout") {
      CreateExcelFilePayrollEvDL(allPayrollDispersion);
    } else {
      CreateExcelFilePayrollEvD(allPayrollDispersion);
    }
  };

  //Alertas
  const openFondosInsuficientes = () => {
    openfondosInsuficientes({aceptarDisperar:true}, dispatch)
  }

  const openSinSeleccion = () => {
    openSinNominasSeleccionadas({sinNominasSeleccionadas:true }, dispatch);
  }

  const openAceptaDispersar = () => {
  //   if(isCheck.length > 0 && isCheckInactivos.length >0){
  //     setIsCheck([]);
  //     setIsCheckInactivos([]);
  //    return  ErrorAlert({text:'No es posible enviar a dispersión a colaboradores activos e inactivos.'});
  //  }
  //  if(isCheckInactivos.length >0){
  //    setIsCheck(isCheckInactivos)
  //    setIsCheckInactivos([])
  //  } 
   return openaceptarDispersarEventual({ aceptarDispersarEventual: true }, dispatch);   
    };

  const abreAceptarDispersarIndividualEventual = (id:any) =>{
    openAceptarDispersarIndividualEventual({aceptarDispersarIndividualEventual:true, id}, dispatch)
  }

  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling: startPollingSistemaActivo, stopPolling: stopPollingSistemaActivo } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);
  useEffect(()=>{
    startPollingSistemaActivo(1000);
    return () =>{
      stopPollingSistemaActivo()
    }
  },[startPollingSistemaActivo,stopPollingSistemaActivo])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  useEffect(()=>{
    if(dataPrincipal && PayrollDataDispersion?.saldoCuenta){
      setCargandoPrincipal((current) => current = false)
      setInicio((current) =>current = PayrollDataDispersion?.saldoCuenta)
      actualizaSaldos()
    }
  },[dataPrincipal,actualizaSaldos, PayrollDataDispersion])

  useEffect(()=>{
    if(datosTablas){
      const results = !search ? datosTablas : datosTablas?.filter(
        (dato) => (dato.colaborator.toLowerCase().includes(search.toLocaleLowerCase())
        ))
        setFiltrado((current) => current = results)
    }
         // eslint-disable-next-line react-hooks/exhaustive-deps
  },[datosTablas, search])

  useEffect(()=>{
    if(allPayrollDispersion){
      getDatosTabla();
      actualizaSaldos()
    }
    startPolling(1000);
    return () =>{
      stopPolling()
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allPayrollDispersion, startPolling, stopPolling, isCheck,actualizaSaldos, getDatosTabla, loadingDispersionEventual],)

  const enviaOutsourcersAutorizar = () =>{
     history.push(`/OutsourcersAutorizarEventual/${id}/${tab}`)
  }

  const abreResumenOutsourcers = () =>{
    openModalOutsorcersResumenActivo({openModalOutsourcersResumen:true}, dispatch)
  }

  const abreModalAlertaNoGravable = () =>{
    openModalAlertaNoGravable({openAlertaNoGravable:true}, dispatch)
  }



  return(
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 3 para que se muestre la pestaña de Eventuales
            value={3}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}>
            </div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
          </div>
        </div>
        {(cargandoPrincipal === false)?
          <div className={styles.pc_contenedorBotones}>
            {CheckPermission("Nominas.Eventuales.Dispersar.Dispersar") && (
              <>
                {
                  sistemaActivo?
                    (procesoDispersion === false)?
                      (isCheck.length > 0 || isCheckInactivos.length>0)?
                        (disponibleDispersar)?
                          <button
                            className={styles.pd_botonDispersarDos}
                            onClick={()=> openAceptaDispersar()}
                            >
                            <div className={styles.pd_iconoDispersarDos}></div>
                              Dispersar
                          </button>
                        :
                          <button
                            className={styles.pd_botonDispersarDosInactivo}
                            onClick={()=> openFondosInsuficientes()}
                          >
                            <div className={styles.pd_iconoDispersarDos}></div>
                              Dispersar
                          </button>
                      :
                        <button
                          className={styles.pd_botonDispersarDosInactivo}
                          onClick={()=> openSinSeleccion()}
                        >
                          <div className={styles.pd_iconoDispersarDos}></div>
                            Dispersar
                        </button>
                    :
                      <button
                      className={styles.pd_botonDispersarDosInactivo}
                      onClick={()=> openSinSeleccion()}
                      >
                        <div className={styles.pd_iconoDispersarDos}></div>
                          Dispersar
                      </button>
                  : null
                }
              </>
            )}
            {CheckPermission("Nominas.Eventuales.Dispersar.IrATimbrar") && (
              <>
                {(dispersados > 0)?
                  <button
                    className={styles.pd_botonDispersarDos}
                    onClick={() => history.push(`/EventualPayrollTimbrar/${id}/${tab}`)}
                    >
                    <div>Ir a Timbrar</div>
                    <div className={styles.pc_iconoDispersar}></div>
                  </button>
                :
                  <button
                    className={styles.pd_botonDispersarDos}
                    style={{opacity:0.5}}
                    >
                    <div>Ir a Timbrar</div>
                    <div className={styles.pc_iconoDispersar}></div>
                  </button>
                }
              </>
            )}
          </div>
          :
          null
        }
      </div>
        {(cargandoPrincipal === true || loadingPrincipal === true)?
          <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.contenedorLoading}>
              <div className={styles.loading}></div>
            </div>
          </div>
          :
          <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.pc_tituloContenedorPrincipal}>
              {loadingPrincipal ? null : PayrollData?.payroll_type === "PTU" ? (
                <div className={styles.e_titulo}>
                  <span className={styles.pc_tituloPeriodo}>
                    Monto:{formatter(PayrollData.total)}{" "}
                  </span>
                  &nbsp;-&nbsp;
                  <span className={styles.pc_tituloPeriodo}>
                    Factor Ingreso: {PayrollData.FactorIngresos}
                  </span>
                  &nbsp;
                  <span className={styles.pc_tituloPeriodo}>
                    Factor Dias: {PayrollData.FactorDias}
                  </span>
                </div>
              ) : null }
              
              {loadingPrincipal ? null : PayrollData?.payroll_type === "Fondo Ahorro" ? (
                <div className={styles.e_titulo}>
                  <span className={styles.pc_tituloPeriodo}>Fondo de ahorro:</span>
                  &nbsp;-&nbsp;
                  <span className={styles.pc_tituloPeriodo}>
                    {getDateDay(PayrollData?.init_date)}{" "}
                    {getDateDay(PayrollData?.end_date)}
                  </span>
                  &nbsp;
                </div>
              ) : null }

              {loadingPrincipal ? null : PayrollData?.payroll_type === "Aguinaldo" ? (
                <div className={styles.e_titulo}>
                  <span className={styles.pc_tituloPeriodo}>Aguinaldo</span>
                  &nbsp;
                  <span className={styles.pc_tituloPeriodo}>
                    Periodo: {PayrollData?.AguinaldoPeriod}
                  </span>
                  &nbsp;
                </div>
              ) : null }

              {loadingPrincipal ? null : PayrollData?.payroll_type === "Prima Vacacional" ? (
                <div className={styles.e_titulo}>
                  <span className={styles.pc_tituloPeriodo}>Prima Vacacional</span>
                  &nbsp;
                  &nbsp;
                </div>
              ) : null }


              <div className={styles.pd_contenedorTituloSaldos}>
                <div className={styles.pd_cuentaBancaria}>
                  <div className={styles.pd_textoCuentaBancaria}>
                    Cuenta Bancaria
                  </div>
                  <div className={styles.pd_contenedorCuentaBancaria}>
                    {PayrollDataDispersion?.cuentaBancaria}
                  </div>
                </div>
                <div className={styles.pd_contenedorSaldos}>
                  <div className={styles.pd_contenedorInterno}>
                    <div className={styles.pd_tituloSaldos}>
                      Saldo disponible
                    </div>
                    <div className={styles.pd_saldoInterno}>
                      {formatter(inicio)}
                    </div>
                  </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Monto a pagar
                  </div>
                  <div className={styles.pd_saldoInterno}>
                    {formatter(total)}
                  </div>
                </div>
                <div className={styles.pd_contenedorInterno}>
                  <div className={styles.pd_tituloSaldos}>
                    Diferencia
                  </div>
                    {(disponibleDispersar)?
                      <div className={styles.pd_saldoInterno}>
                        {formatter(diferencia)}
                      </div>
                    :
                      <div className={styles.pd_saldoInternoNegativo}>
                        {formatter(diferencia)}
                      </div>
                    }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.pc_contenedorBuscadorDescargar}>
            <div className={styles.pc_contenedorBuscador}>
              <input
                value={search}
                className={styles.pc_inputBuscador}
                type="text"
                placeholder="Ingresa el nombre del colaborador"
                onChange={busqueda}
              />
              <div className={styles.pc_iconoBuscador}></div>
            </div>
            <div className={styles.contenedorBotonesDescargas}>
              <button
                className={styles.pc_botonDescargar}
                onClick={()=> handleOnExport("Layout")}
                >
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar layout
                </div>
              </button>
              <button
                className={styles.pc_botonDescargar}
                onClick={()=> handleOnExport("Reporte")}>
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar reporte
                </div>
              </button>
            </div>
          </div>

           <div className={styles.contenedorBotonOutosrcer}>       
            {(PayrollDataDispersion?.dispersionGravable > 0)?
              <button
                className={styles.pd_botonOutosoursers}
                onClick={() => enviaOutsourcersAutorizar()}
              >
                <div>Outsourcers</div>
                <div className={styles.pc_iconoDispersar}></div>
              </button> 
            :
              <button
                className={styles.pd_botonOutosoursers}
                onClick={() => abreModalAlertaNoGravable()}
              >
                <div>Outsourcers</div>
                <div className={styles.pc_iconoDispersar}></div>
              </button>
            }       
              {(PayrollDataDispersion?.estatusOutsourcers) === 'Descartadas'?
              <div 
                className={styles.po_botonDescartado}
                onClick={()=> abreResumenOutsourcers()}
              >
                <div className={styles.po_textoBotonRechazado}>X</div>
              </div> 
              :null
              }
              {(PayrollDataDispersion?.estatusOutsourcers) === 'Autorizados'?
                <div 
                  className={styles.po_iconoPendiente}
                  onClick={()=> abreResumenOutsourcers()}
                >
                </div>  
              :null
              }
              {(PayrollDataDispersion?.estatusOutsourcers) === 'Finalizados'?
                <div 
                  className={styles.po_botoneEnProceso}
                  onClick={()=> abreResumenOutsourcers()}
                >
                  <div className={styles.po_iconoEstatusCuatro}></div>
                </div> 
              :null
              }
            </div>
          
        {PayrollData?.payroll_type === "PTU" ? (
          <>
          <div className={styles.pc_contenedorNombre}>
          <span className={`${styles.pc_tituloPrincipal} ${styles.e_titulo}`}>
            Activos
          </span>
        </div>
          <div className={styles.p_contenedorTablaDispersion}>
          <table className={styles.pd_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={`${styles.pd_columnaTitulos} ${styles.pd_primerColumnaTitulo}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="check"
                    type="checkbox"
                    //onChange={(e) => handleSelectAll(e)}
                    onChange={(e) => handleSelectAll(e,'activo','PTU')}
                    disabled={loadingTabla ? true : false}
                  ></input>
                    <label htmlFor="check"> Colaboradores </label>
                  </div>
              </td>
              <td className={styles.pd_columnaTitulos}>RFC</td>
              <td className={styles.pd_columnaTitulos}>Banco</td>
              <td className={styles.pd_columnaTitulos}>Cuenta bancaria</td>
              <td className={styles.pd_columnaTitulos}>CLABE</td>
              <td className={styles.pd_columnaTitulos}>Percepcion</td>
              <td className={styles.pd_columnaTitulos}>Deduccion</td>
              <td className={styles.pd_columnaTitulos}>Total a Dispersar</td>
              <td className={styles.pd_columnaTitulos}>Total no Gravable</td>
              <td id="payroll_9" className={styles.pd_columnaTitulos}>Estatus</td>
            </thead>
            <tbody className={styles.pc_contenedorTitulos}>
            {filtrado?.filter((lis: any) => lis.collaborator === true).map((lis: any, index: any) => (
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={`${styles.pd_primerColumnaTitulo}`}>
                    <div id="colaboladores" className={styles.checkboxitem}>
                      <input
                        id={lis?.id}
                        key={lis?.id}
                        type="checkbox"
                        checked ={isCheck.includes(lis?.id)}
                        //onChange={(e) => handleClick(e)}
                        onChange={(e) => handleClick(e,'activo','PTU')}
                        value={lis?.id}
                        disabled={(lis?.dispersionStatus !== "EnEspera")?true:false}
                        ></input>
                      <label htmlFor={lis?.id} className={styles.datosLabel}>  {lis?.colaborator} </label>
                    </div>
                  </td> 
                  {console.log(lis)}
                  <td className={styles.pc_columnaDatos}>{lis?.RFC}</td>
                  <td className={styles.pc_columnaDatos}>{lis?.bank}</td>
                  <td className={styles.pc_columnaDatos}>{lis?.accountNumber}</td>
                  <td className={styles.pc_columnaDatos}>{lis?.clabeNum}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.percepcion)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.deduccion)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.total)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.TotalIncomeNotTaxable)}</td>
                  <td className={styles.pc_columnaDatos}>
                    {(lis?.dispersionStatus === "ENPROCESO")?
                      <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                        <div className={styles.pd_textoBotonEstatusDos}>
                          En proceso...
                        </div>
                      </div>
                    : null
                    }
                    {(lis?.dispersionStatus === "ERROR")?
                      <div
                        onClick={()=> abreAceptarDispersarIndividualEventual(lis.id)}
                        className={styles.pd_botonEstatusTres}>
                        <div className={styles.pd_contenedorEstatusTres}>
                          <div className={styles.pd_contEstatusTres}>
                            <div className={styles.pd_iconoError}></div>
                              <div className={styles.pd_textoBotonError}>
                                Error al dispersar
                              </div>
                            </div>
                          <div className={styles.pd_textoBotonErrorDos}>
                            {lis.mensajeDispersion}
                          </div>
                        </div>
                        <div className ={styles.pd_botonInternoDispersar}>
                          <div className={styles.pd_iconoIntentar}></div>
                        </div>
                      </div>
                    : null
                    }
                    {(lis?.dispersionStatus === "LIQUIDADO")?
                      <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                        <div className={styles.pd_textoBotonEstatusCuatro}>
                          Finalizado
                        </div>
                        <div className={styles.pd_iconoEstatusCuatro}></div>
                      </div>
                    : null
                    }
                  </td>
                </tr>
                ))
              }
            </tbody>
          </table>
          </div>


          <div className={styles.pc_contenedorNombre}>
          <span className={`${styles.pc_tituloPrincipal} ${styles.e_titulo}`}>
            Inactivos
          </span>
        </div>
          <div className={styles.p_contenedorTablaDispersion}>
          <table className={styles.pd_tablaPrincipal}>
            <thead className={styles.pd_contenedorTitulos}>
              <td className={`${styles.pd_columnaTitulos} ${styles.pd_primerColumnaTitulo}`}>
                <div id="colaboladores" className={styles.checkboxitem}>
                  <input
                    id="checkInactivos"
                    type="checkbox"
                    // onChange={(e) => handleSelectAllInactivos(e)}
                    onChange={(e) => handleSelectAll(e,'inactivo','PTU')}
                    disabled={loadingTabla ? true : false}
                  ></input>
                    <label htmlFor="checkInactivos"> Colaboradores </label>
                  </div>
              </td>
              <td className={styles.pd_columnaTitulos}>RFC</td>
              <td className={styles.pd_columnaTitulos}>Banco</td>
              <td className={styles.pd_columnaTitulos}>Cuenta bancaria</td>
              <td className={styles.pd_columnaTitulos}>CLABE</td>
              <td className={styles.pd_columnaTitulos}>Percepcion</td>
              <td className={styles.pd_columnaTitulos}>Deduccion</td>
              <td className={styles.pd_columnaTitulos}>Total a Dispersar</td>
              <td className={styles.pd_columnaTitulos}>Total no Gravable</td>
              <td id="payroll_9" className={styles.pd_columnaTitulos}>Estatus</td>
            </thead>
            <tbody className={styles.pc_contenedorTitulos}>
            {filtrado?.filter((lis: any) => lis.baja === true).map((lis: any, index: any) => (
                <tr className={styles.pc_bordePrimeraColumna}>
                  <td className={`${styles.pd_primerColumnaTitulo}`}>
                    <div id="colaboladores" className={styles.checkboxitem}>
                      <input
                        id={lis?.id}
                        key={lis?.id}
                        type="checkbox"
                        //checked ={isCheckInactivos.includes(lis?.id)}
                        checked ={isCheck.includes(lis?.id)}                        
                        //onChange={(e) => handleClickInactivos(e)}
                        onChange={(e) => handleClick(e,'inactivo','PTU')}
                        value={lis?.id}
                        disabled={(lis?.dispersionStatus !== "EnEspera")?true:false}
                        ></input>
                      <label htmlFor={lis?.id} className={styles.datosLabel}>  {lis?.colaborator} </label>
                    </div>
                  </td> 
                  {console.log(lis)}
                  <td className={styles.pc_columnaDatos}>{lis?.RFC}</td>
                  <td className={styles.pc_columnaDatos}>{lis?.bank}</td>
                  <td className={styles.pc_columnaDatos}>{lis?.accountNumber}</td>
                  <td className={styles.pc_columnaDatos}>{lis?.clabeNum}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.percepcion)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.deduccion)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.total)}</td>
                  <td className={styles.pc_columnaDatos}>{formatter(lis?.TotalIncomeNotTaxable)}</td>
                  <td className={styles.pc_columnaDatos}>
                    {(lis?.dispersionStatus === "ENPROCESO")?
                      <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                        <div className={styles.pd_textoBotonEstatusDos}>
                          En proceso...
                        </div>
                      </div>
                    : null
                    }
                    {(lis?.dispersionStatus === "ERROR")?
                      <div
                        onClick={()=> abreAceptarDispersarIndividualEventual(lis.id)}
                        className={styles.pd_botonEstatusTres}>
                        <div className={styles.pd_contenedorEstatusTres}>
                          <div className={styles.pd_contEstatusTres}>
                            <div className={styles.pd_iconoError}></div>
                              <div className={styles.pd_textoBotonError}>
                                Error al dispersar
                              </div>
                            </div>
                          <div className={styles.pd_textoBotonErrorDos}>
                            {lis.mensajeDispersion}
                          </div>
                        </div>
                        <div className ={styles.pd_botonInternoDispersar}>
                          <div className={styles.pd_iconoIntentar}></div>
                        </div>
                      </div>
                    : null
                    }
                    {(lis?.dispersionStatus === "LIQUIDADO")?
                      <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                        <div className={styles.pd_textoBotonEstatusCuatro}>
                          Finalizado
                        </div>
                        <div className={styles.pd_iconoEstatusCuatro}></div>
                      </div>
                    : null
                    }
                  </td>
                </tr>
                ))
              }
            </tbody>
          </table>
          </div>
          </>
        ) :

          
          <div className={styles.p_contenedorTablaDispersion}>
            <table className={styles.pd_tablaPrincipal}>
              <thead className={styles.pd_contenedorTitulos}>
                <td className={`${styles.pd_columnaTitulos} ${styles.pd_primerColumnaTitulo}`}>
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="check"
                      type="checkbox"
                      //onChange={(e) => handleSelectAll(e)}
                      onChange={(e) => handleSelectAll(e,'','')}
                      disabled={loadingTabla ? true : false}
                    ></input>
                      <label htmlFor="check"> Colaboradores </label>
                    </div>
                </td>
                <td className={styles.pd_columnaTitulos}>RFC</td>
                <td className={styles.pd_columnaTitulos}>Banco</td>
                <td className={styles.pd_columnaTitulos}>Cuenta bancaria</td>
                <td className={styles.pd_columnaTitulos}>CLABE</td>
                <td className={styles.pd_columnaTitulos}>Percepcion</td>
                <td className={styles.pd_columnaTitulos}>Deduccion</td>
                <td className={styles.pd_columnaTitulos}>Total a Dispersar</td>
                <td className={styles.pd_columnaTitulos}>Total no Gravable</td>
                <td id="payroll_9" className={styles.pd_columnaTitulos}>Estatus</td>
              </thead>
              <tbody className={styles.pc_contenedorTitulos}>
                {filtrado?.map((lis:any)=>(
                  <tr className={styles.pc_bordePrimeraColumna}>
                    <td className={`${styles.pd_primerColumnaTitulo}`}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={lis?.id}
                          key={lis?.id}
                          type="checkbox"
                          checked ={isCheck.includes(lis?.id)}
                          //onChange={(e) => handleClick(e)}
                          onChange={(e) => handleClick(e,'','')}
                          value={lis?.id}
                          disabled={(lis?.dispersionStatus !== "EnEspera")?true:false}
                          ></input>
                        <label htmlFor={lis?.id} className={styles.datosLabel}>  {lis?.colaborator} </label>
                      </div>
                    </td> 
                    {console.log(lis)}
                    <td className={styles.pc_columnaDatos}>{lis?.RFC}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.bank}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.accountNumber}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.clabeNum}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.percepcion)}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.deduccion)}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.total)}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.TotalIncomeNotTaxable)}</td>
                    <td className={styles.pc_columnaDatos}>
                      {(lis?.dispersionStatus === "ENPROCESO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                          <div className={styles.pd_textoBotonEstatusDos}>
                            En proceso...
                          </div>
                        </div>
                      : null
                      }
                      {(lis?.dispersionStatus === "ERROR")?
                        <div
                          onClick={()=> abreAceptarDispersarIndividualEventual(lis.id)}
                          className={styles.pd_botonEstatusTres}>
                          <div className={styles.pd_contenedorEstatusTres}>
                            <div className={styles.pd_contEstatusTres}>
                              <div className={styles.pd_iconoError}></div>
                                <div className={styles.pd_textoBotonError}>
                                  Error al dispersar
                                </div>
                              </div>
                            <div className={styles.pd_textoBotonErrorDos}>
                              {lis.mensajeDispersion}
                            </div>
                          </div>
                          <div className ={styles.pd_botonInternoDispersar}>
                            <div className={styles.pd_iconoIntentar}></div>
                          </div>
                        </div>
                      : null
                      }
                      {(lis?.dispersionStatus === "LIQUIDADO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                          <div className={styles.pd_textoBotonEstatusCuatro}>
                            Finalizado
                          </div>
                          <div className={styles.pd_iconoEstatusCuatro}></div>
                        </div>
                      : null
                      }
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

           }

          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                onClick={() =>history.push(`/InitEventualPayroll/${id}/${tab}`)}
              >
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Calcular"</div>
              </div>
                <button className={styles.pd_botonSalir}
                  onClick={() => history.push(`/payroll`)}
                >
                  Salir
                </button>
            </div>
          </div>

        </div>
        }
        <SinNominasModal/>
        <FondosInsufucientesModal />
        <AceptarDispersarEventual
          totalesDispersar={isCheck?.length}
          totales={allPayrollDispersion?.length}
          seleccionados={isCheck}
          setProcesoDispersion={setProcesoDispersion}
          reinicia={() => reinicioDatos()}
          idRegistro={id}
          updateDataAfterDispersar={updateDataAfterDispersar}
        />
        <SinNominasModal />
        <AceptarDispersarIndividualEventual
          seleccionados={isCheck}
          setProcesoDispersion={setProcesoDispersion}
          reinicia={() => reinicioDatos()}
          idRegistro={id}/>
        <AlertaNoGravable />
        <ResumenOutsourcers 
          porAutorizar={PayrollDataDispersion?.resumenPorAutorizarOutsourcers}
          autorizadas={PayrollDataDispersion?.resumenAutorizadosOutsourcers}
          conRespuesta={PayrollDataDispersion?.resumenConRespuestaOutsourcers}
          typeNomina={"eventual"}
        />
    </>
  )

}

export default EventualPayrollDispersion