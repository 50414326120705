import { Grid, Rating } from '@mui/material';
import CollaboratorIconText from '../../CollaboratorIcon/CollaboratorIconText';

function TestComment({comment}:any){
  return (
    <div className="textComment">
        <Grid container alignItems={'center'}>
            <Grid xs={2}>
                {
                    !comment.image ? (
                        <CollaboratorIconText
                            Nombre={comment.Colaborador && comment.Colaborador.split(' ')[0] ? comment.Colaborador.split(' ')[0] : ''}
                            Apellido={comment.Colaborador && comment.Colaborador.split(' ')[1] ? comment.Colaborador.split(' ')[1] : ''}
                            width="50px"
                            height="50px"
                        />
                    ) : (
                        <img src={''} className="commentProfile"></img>
                    )
                }
            </Grid>
            <Grid xs={10}>
                <p className="commentAuthor">{comment.Colaborador}</p>
                <p className="commentDays"> { comment.DiasPasados}</p>
            </Grid>
            <Grid xs={12}>
                <p className="commentText">{comment.Comentarios}</p>
            </Grid>
        </Grid>
    </div>
  )
}

export default TestComment;