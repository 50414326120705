import * as d3 from "d3";
import { useEffect, useRef } from "react";

type DesnityProps = {
  width: number;
  height: number;
  data: number[];
};

const DensityPlot = ({ data, width, height }: DesnityProps) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const margin = { top: 30, right: 30, bottom: 30, left: 50 },
      width2 = width - margin.left - margin.right,
      height2 = height - margin.top - margin.bottom;

    // Clear previous chart
    d3.select(ref.current).selectAll("*").remove();
    // append the svg object to the body of the page
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width2 + margin.left + margin.right)
      .attr("height", height2 + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // add the x Axis
    const x = d3.scaleLinear().domain([-1000, 1000]).range([0, width2]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height2})`)
      .call(d3.axisBottom(x));

    // add the y Axis
    const y = d3.scaleLinear().range([height2, 0]).domain([0, 0.01]);
    svg.append("g").call(d3.axisLeft(y));
    // Compute kernel density estimation
    const kde = kernelDensityEstimator(kernelEpanechnikov(7), x.ticks(40));
    const density = kde(data);
    //console.log(density)

    // Plot the area
    svg
      .append("path")
      .attr("class", "mypath")
      .datum(density)
      .attr("fill", "#69b3a2")
      .attr("opacity", ".8")
      .attr("stroke", "#000")
      .attr("stroke-width", 1)
      .attr("stroke-linejoin", "round")
      .attr(
        "d",
        d3
          .line()
          .curve(d3.curveBasis)
          .x((d) => x(d[0]))
          .y((d) => y(d[1]))
      );
  }, [data, width, height]);

  return <svg ref={ref} width={width} height={height} />;
};

export default DensityPlot;

function kernelDensityEstimator(kernel: any, X: any) {
  return function (V: any) {
    return X.map(function (x: any) {
      return [
        x,
        d3.mean(V, function (v: any) {
          return kernel(x - v);
        }),
      ];
    });
  };
}
function kernelEpanechnikov(k: any) {
  return function (v: any) {
    return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
  };
}
