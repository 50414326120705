import {
    ActionTabMonthlyISRReducer,
    MonthlyISR,
    StateTabMonthlyISRInterface,
    TabMonthlyISRTypes
} from '../../interfaces/TabMonthlyISR.interfaces';

const initialState: StateTabMonthlyISRInterface = {
    loading: true,
    MonthlyISRs: [],
    MonthlyISRFilter: [],
    MonthlyISR: {
        id: '',
        _id: '',
        group_name: '',
        frecuency_payment:'',
        init_date: '',
        end_date: '',
        employees: 0,
        positive: 0,
        negative: 0,
        diference: 0,
        id_group_payroll: 0,
        status: '',
        statusProgress: ''
    }
};


export const tabMonthlyISRReducer = (
    state = initialState,
    action: ActionTabMonthlyISRReducer
) => {

    const substractByID = (e: MonthlyISR) => e._id !== action!.payload;
    const updateMonthlyISR = (MonthlyISR: MonthlyISR) => MonthlyISR._id === action.payload._id
        ? { ...MonthlyISR, ...action.payload.data }
        : MonthlyISR;

    const dictionary = {
        [TabMonthlyISRTypes.init]: () => ({
            MonthlyISRs : action.payload,
            MonthlyISRFilter: action.payload,
            loading: false
        }),
        [TabMonthlyISRTypes.add]: () => ({
            ...state,
            MonthlyISRs: [...state.MonthlyISRs, action.payload],
            MonthlyISRFilter: [...state.MonthlyISRFilter!, action.payload],
        }),
        [TabMonthlyISRTypes.substract]: () => ({
            ...state,
            MonthlyISRs: state.MonthlyISRs.filter(substractByID),
            MonthlyISRFilter: state.MonthlyISRs.filter(substractByID)
        }),
        [TabMonthlyISRTypes.scheduleDrop]: () => ({
            ...state,
            MonthlyISRs: state.MonthlyISRs.map(updateMonthlyISR),
            MonthlyISRFilter: state.MonthlyISRs.map(updateMonthlyISR)
        }),
        [TabMonthlyISRTypes.filter]: () => ({
            ...state,
            MonthlyISRFilter: action.payload
        }),

        [TabMonthlyISRTypes.setMonthlyISR]: () => ({
            ...state,
            MonthlyISR: action.payload
        }),
        [TabMonthlyISRTypes.cleanMonthlyISR]: () => ({
            ...state,
            MonthlyISR: {}
        }),
    };

    return dictionary[action.type]() || state;
};