import { useCallback, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styles from "../../styles/FacturacionPagos/FacturacionPagos.module.css"
import { GET_DISPERSIONACEPTADAREPORTE, GET_LIST_ENTERPRISE_MOVIMIENTOS_STP, GET_ENTERPRISE_MOVIMIENTOS_STP } from "../../Querys/querys";
import { CreateExcelMovimientosSTP } from "./ExcelMovimientosStp";
import { formatter } from "../../helpers/formatoMoneda";
import { ref } from "yup";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const formatDate = (fecha: string) => {
  // Extraer año, mes y día de la cadena de fecha
  const year = fecha.substring(0, 4);
  const month = fecha.substring(4, 6);
  const day = fecha.substring(6, 8);

  // Formatear la fecha en el formato dd-mm-aaaa
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};



const ReporteMovimientosSTPTable =  (props: TabPanelProps) =>{
  const [search, setSearch] = useState('');
  const [filtrado, setFiltrado] = useState([])
  const [datosTablas, setDatosTablas] = useState<any>([])
  const [datosPrincipales, setDatosPrincipales] = useState<any>([])

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [muestraListaPaginacion, setMuestraListaPaginacion] = useState(false);

  const [mes, setMes] = useState(() => {
    const storedMonth = localStorage.getItem("selectedMonth");
    return storedMonth ? parseInt(storedMonth) : 0;
  }); // Valor inicial para enero


    const [anio, setAnio] = useState(() => {
    const storedYear = localStorage.getItem("selectedYear");
    return storedYear ? parseInt(storedYear) : 2024;
  }); // Valor inicial para el año 2023
  const [empresa, setEmpresa] = useState(1); 
  

  const {data: datosListaEmpresa, loading: loadingListaEmpresa, error: errorListaEmpresa} = useQuery(GET_LIST_ENTERPRISE_MOVIMIENTOS_STP)
  const allListaEmpresa = datosListaEmpresa?.GET_LIST_ENTERPRISE_MOVIMIENTOS_STP;

  const {
    data: datosMovimientosEmpresa, 
    loading: loadingMovimientosEmpresa, 
    error: errorMovimientosEmpresa,
    refetch: refetchMovimientosEmpresa
  } = useQuery(GET_ENTERPRISE_MOVIMIENTOS_STP, {
    variables: {
      getEnterpriseMovimientosStpId: 1
    }
  });



  const { 
    data: datosMovimientos, 
    loading: loadingMovimientos, 
    error: errorMovimientos, 
    refetch: refetchMovimientos 
  } = useQuery(GET_DISPERSIONACEPTADAREPORTE, {
    variables: {
        mes:mes,
        anio:anio,
        empresa: empresa,
    }
});

  const allMovimientos = datosMovimientos?.GET_DISPERSIONACEPTADAREPORTE;


  

  const mainData = datosMovimientosEmpresa?.GET_ENTERPRISE_MOVIMIENTOS_STP



  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedEnterprise  = parseInt(event.target.value);
    setEmpresa(selectedEnterprise);
    refetchMovimientosEmpresa({ getEnterpriseMovimientosStpId: selectedEnterprise });
    refetchMovimientos({ mes, anio, empresa: selectedEnterprise });

  };

  const handleMesChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMes = parseInt(event.target.value);
    setMes(selectedMes);
    setCurrentPage(0); // Volver a la primera página
    localStorage.setItem("selectedMonth", String(selectedMes)); // Guardar en localStorage
  refetchMovimientos({ mes: selectedMes, anio, empresa });
  };
  
  const handleAnioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAnio = parseInt(event.target.value);
    setAnio(selectedAnio);
    setCurrentPage(0); // Volver a la primera página
    localStorage.setItem("selectedYear", String(selectedAnio)); // Guardar en localStorage
    refetchMovimientos({ mes, anio: selectedAnio, empresa });
  };

  useEffect(() => {
    let storedMonth = localStorage.getItem("selectedMonth");
    if (storedMonth) {
      setMes(parseInt(storedMonth));
    }
    let storedYear = localStorage.getItem("selectedYear");
    if (storedYear) {
      setAnio(parseInt(storedYear));
    }
    
  }, []); // Cargar valores de localStorage al montar el componente
  useEffect(() => {
    refetchMovimientos({ mes, anio, empresa });
  }, [mes, anio, empresa]); // Ejecutar refetchMovimientos cada vez que mes, anio o empresa cambie



  const busqueda = (e:any) =>{
    setSearch(e.target.value);
    guardaPaginado()
  }

  const getDatosTabla = useCallback(() => {
    setDatosTablas([...allMovimientos])
  },[allMovimientos])

  const cambioTamanoPagina = (valor:number) =>{
    setMuestraListaPaginacion(!muestraListaPaginacion);
    setItemsPerPage(valor);
  }

  const guardaPaginado = useCallback(()=>{
    const result = !search ? datosTablas : datosTablas?.filter(
      (dato:any) =>
        dato.fechaOperacion.includes(search) ||  
        dato.nombreBeneficiario.toLowerCase().includes(search.toLocaleLowerCase()) ||
        dato.claveRastreo.toLowerCase().includes(search.toLocaleLowerCase()) ||
        dato.tipoOperacion.toLowerCase().includes(search.toLocaleLowerCase()) ||
        dato.conceptoPago.toLowerCase().includes(search.toLocaleLowerCase()) 
    );
    setFiltrado(result)
  },[datosTablas, search])

  const exportaReporte = () =>{
    CreateExcelMovimientosSTP(allMovimientos)
  }


  

  useEffect(()=>{
    if(allMovimientos){
      getDatosTabla();
      guardaPaginado();
      setFiltrado(allMovimientos)
    }
  },[allMovimientos])

  useEffect(()=>{
    guardaPaginado();
  },[search])
  
  const pageCount = Math.ceil(filtrado?.length / itemsPerPage);
  const itemsToShow = filtrado?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  console.log('Esta es la respuesta', mainData)

  return(
    <div className={styles.fp_contenedorPrincipal}>
      <div className={styles.fp_contenedorHeader}>
        <div className={styles.fp_filaHeader}>
        <div className={styles.r_contenedorTitulos}>        
      </div>
          <div className={styles.fp_contenedorCompania}>{mainData?.razonSocial}</div>
          <div className={styles.spacer}></div> {/* Elemento adicional */}
          <div className={styles.fp_contenedorSeleccionEmpresa}>
          <div className={styles.fp_contenedorTituloEmpresaInterno}>
              <div>Mes</div>
              <div>
                <select className={styles.fp_selectEmpresa} value={mes} onChange={handleMesChange}>
                <option value="0">Selecciona...</option>
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>              
                </select>
              </div>
            </div>
          <div className={styles.fp_contenedorTituloEmpresaInterno}>
              <div>A&ntilde;o</div>
              <div>
                <select className={styles.fp_selectEmpresa} value={anio} onChange={handleAnioChange}>
                  <option value="">Selecciona...</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>                
                </select>
              </div>
            </div>
            <div className={styles.fp_contenedorTituloEmpresaInterno}>
              <div>Empresa</div>
              <div>
                <select className={styles.fp_selectEmpresa} onChange={handleSelectChange}>
                  <option disabled>Seleccionar Empresa</option>
                    {allListaEmpresa?.map((empresa:any, index:any) => (
                  <option key={index} value={empresa.id}>
                    {empresa.razonSocial}
                  </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.fp_contenedorTituloCuentaBancaria}>
              <div>Cuenta Bancaria</div>
              <div className={styles.fp_contenedorCuenta}>
                <div className={styles.fp_contenedorInternoCuenta}>  
                  {mainData?.cuentaBancaria}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.fp_filaHeader}>
          <div className={styles.fp_contenedorFiltro}>
            <input
              value={search}
              type="text"
              placeholder="Buscar"
              className={styles.pf_inputBuscador}
              onChange={busqueda}
              />
            <div className={styles.fp_iconoBuscador}></div>
        </div>
        <div className={styles.fp_contenedorExportaReporte}>
          <div className={styles.fp_contenedorInternoSaldo}>
            <div className={styles.fp_textoSaldoCuenta}>Saldo cuenta STP</div>
            <div className={styles.fp_contenedorSaldo}> { formatter(mainData?.saldo || 0) }</div>
          </div>  
          <div className={styles.fp_contenedorBotonDescargar}>
            <div className={styles.fp_botonDescargar} onClick={() => exportaReporte()}>
              <div className={styles.fp_iconoDescargar}></div>
              <div>Descargar reporte</div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div className={styles.fp_contenedorTablaPrincipal}>
        <table className={styles.fp_tablaPrincipal}>
          <thead className={styles.pf_contenedorTitulos}>
            <tr>
              <th className={`${styles.pf_columnaTitulos} ${styles.pf_primerColumnaTitulo}`}>Fecha de movimiento</th>
              <th className={styles.pf_columnaTitulos}>Concepto</th>
              <th className={styles.pf_columnaTitulos}>Clave Rastreo</th>
              <th className={styles.pf_columnaTitulos}>Nombre Beneficiario</th>
              <th className={styles.pf_columnaTitulos}>Abono</th>
              <th className={styles.pf_columnaTitulos}>Cargo</th>
              
              <th className={styles.pf_columnaTitulos}>Saldo Final</th>
            </tr>
          </thead>
          <tbody>
            {itemsToShow?.map((item:any)=>(
              <tr className={styles.pf_borderPrimeraColumna}>
                <td className={`${styles.pf_primerColumnaTitulo} ${styles.pf_columnaDatos}`}>{formatDate(item.fechaOperacion)}</td>
                <td className={styles.pf_columnaDatos}>{item.conceptoPago}</td>
                <td className={styles.pf_columnaDatos}>{item.claveRastreo}</td>
                <td className={styles.pf_columnaDatos}>{item.nombreBeneficiario}</td>
                <td className={styles.pf_columnaDatos}>
                  {item.tipoOperacion === 'Abono' ? formatter(item.cargoAbono) : '0'}
                </td>
                <td className={styles.pf_columnaDatos}>
                  {item.tipoOperacion === 'Cargo' ? formatter(item.cargoAbono) : '0'}
                </td>
             
                
                {(item.saldo === "") ?
                  <td className={styles.pf_columnaDatos}></td>
                :
                  <td className={styles.pf_columnaDatos}>{formatter(item.saldo)}</td>  
                }
              </tr>
            ))}
          </tbody>
        </table>

      </div>
      <div className={styles.contenedorPaginacion}>
        <div className={styles.columnaUnoPaginacion}>
          Pagina {currentPage + 1} de {pageCount}
        </div>
        <div
          className={`${styles.textoPaginacion} ${styles.columnaInternaPaginacion}`}
        >
          <span onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}> Mostrar por p&aacute;gina {itemsPerPage}</span>
          <div className={styles.iconoPaginacion} onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}></div>
          <div className={styles.contenedorListaElementos} >
            {(muestraListaPaginacion === true)?
              <ul className={styles.listaElementos}>
                <li onClick={()=> cambioTamanoPagina(10)}>10</li>
                <li onClick={()=> cambioTamanoPagina(20)}>20</li>
                <li onClick={()=> cambioTamanoPagina(50)}>50</li>
              </ul>
            :null
            }
            </div>
            <div className={styles.columnaBotonesPaginacion} >
              <div
                className={styles.iconoPaginacionAnteriorActivo}
                onClick={() => setCurrentPage(prevPage => prevPage > 0 ? prevPage - 1 : prevPage)}
              ></div>
              <div className={styles.textoPaginacion}>
                {currentPage+1}/{pageCount}
              </div>
              <div
                className={styles.iconoPaginacionSiguienteActivo}
                onClick={() => setCurrentPage(prevPage => prevPage < pageCount - 1 ? prevPage + 1 : prevPage)}
              >
              </div>
            </div>
          </div>  
        </div>
      </div>
   )
}

export default ReporteMovimientosSTPTable;