import { Dispatch } from "react";
import {Types} from "./ReportContext";

export const createReportsModal = (_id = "",createModalReports = true, dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.CREATE_REPORTS,
       payload:{
        _id,
        createModalReports
       } 
    })
}

export const editReportsModal = (_id = "",editModalReports = true, dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.EDIT_REPORTS,
       payload:{
        _id,
        editModalReports
       } 
    })
}

export const findReportsModal = (_id = "",findModalReports = true, dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.FIND_DATE_REPORTS,
       payload:{
        _id,
        findModalReports
       } 
    })
}

export const deleteReportsModal = (_id = "",deleteModalReports = true ,report = null , dispatch: Dispatch<any>):void =>{
    dispatch({
       type:Types.DELETE_REPORTS,
       payload:{
        _id,
        deleteModalReports,
        report
       } 
    })
}

export const clearReportsModal = ({_id="", createModalReports = false,editModalReports=false,findModalReports=false,deleteModalReports=false}:any, dispatch: Dispatch<any>):void => {
    dispatch({
        type: Types.CLEAR_REPORTS,
        payload: {
            _id,
            createModalReports,
            editModalReports,
            findModalReports,
            deleteModalReports           
        }
    })
}
