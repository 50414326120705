
const Excel = require("exceljs");

export const CreateExcelFilePayrollDispersar = (data: any) => {
  const workbook = new Excel.Workbook();
  const ReporteDispersion= workbook.addWorksheet("Reporte Dispersion");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteDispersion.columns = [
    { header: "Colaborator", key: "colaborator", width },
    { header: "RFC", key: "RFC", width },
    { header: "Banco", key: "bank", width },
    { header: "Cuenta bancaria", key: "accountNumber", width },
    { header: "CLABE", key: "clabeNum", width },
    { header: "Total a dispersar por nómina", key: "netIncomeTaxable", width },
    { header: "Total a dispersar no gravable", key: "TotalIncomeNotTaxable", width },
    // { header: "Estatus", key: "dispersionStatus", width },
  ];

  let letter = 65;
  ReporteDispersion.columns.forEach(() => {
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  console.log(data)

  data.forEach((item: any) => {
    // let name = item?.Colaborador.toUpperCase() || "";

    // ReporteDispersion.addRow({
    //   colaborator: item.colaborator,
    //   RFC: item.RFC,
    //   bank: item.bank,
    //   accountNumber: item.accountNumber,
    //   clabeNum: item.clabeNum,
    //   netIncomeTaxable: item.netIncomeTaxable,
    //   TotalIncomeNotTaxable: item.TotalIncomeNotTaxable,
    //   dispersionStatus: item.dispersionStatus === 'EnEspera'? "": item.dispersionStatus      
    // });
    
    ReporteDispersion.addRow({
      colaborator: item.Nombre,
      RFC: item.RFC,
      bank: item.Banco,
      accountNumber: item.Cuenta,
      clabeNum: item.Clabe,
      netIncomeTaxable: item.netIncomeTaxable,
      TotalIncomeNotTaxable: item.MontoDispersar,
     // dispersionStatus: item.dispersionStatus === 'EnEspera'? "": item.dispersionStatus
      
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteDispersion.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};