import { useContext, useState, useEffect } from "react";
import CalendarsContext from "../../../../context/NewCalendarContext/CalendarsContext";
import { closeListUserSchedule } from "../../../../context/NewCalendarContext/Actions";
import { GET_TIMECHECKER_EXPORT,GET_TIMECHECKER_OPT } from "../../../../Querys/querys";
import { useLazyQuery } from "@apollo/client";
import {Dialog,
        DialogContent, 
        Select,  
        TextField,         
       } from "@material-ui/core";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; 
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';      
import TableBits from "../../../TableBits/TableBits";
import { TBColumn } from "../../../../interfaces/TableBits.interfaces";
import '../../ClockCalendar.css';
import { ErrorAlert } from "../../../../alerts/errorAlert";
import {format,addMonths,subMonths,addDays,isAfter,isBefore,isEqual } from "date-fns";
import { es } from 'date-fns/locale';
import {CreateExcelRelogChecador} from './ExcelRelojReport';

const columns: TBColumn[] = [
  { id: "Fecha", label: "Fecha", align: "left" },
  { id: "Hora", label: "Hora", align: "left" },
  { id: "TipoChecada",label: "Tipo", align: "left" },
  { id: "Nombre", label: "Nombre", align: "left" },
  { id: "Area", label: "Area o proyecto", align: "left" },
  { id: "Sede", label: "Sede", align: "left" },
  { id: "HorarioLaboral", label: "Horario Laboral", align: "left" }, 
  { id: "Ubicacion", label: "Ubicación", align: "left" },
  { id: "Acumulado", label: "T. trabajado", align: "left" },
  { id: "Diferencia", label: "Retardos", align: "left" },  
];

function UserScheduleList({idCalendar}:any){

  const {state, dispatch} = useContext(CalendarsContext);  
  const [usersClock, setUsersClock] = useState([]);  
  const [valueFilter, setValueFilter] = useState("day"); 
  const [valueMonthNumber, setValueMonthNumber] = useState(format(new Date(), 'L', { locale: es }));     
  const [valueMonth, setValueMonth] = useState(format(new Date(), 'MMMM', { locale: es }));  
  const [valueDay, setValueDay] = useState(format(new Date(), 'yyyy-MM-dd', { locale: es }));  
  const [month,setMonth]=useState<any>(new Date())
  const [viewColaboradoes, setViewColaboradoes] = useState(false);  
  const [userFilterCombo,setUserFilerCombo]=useState([])
  const [sizeUsers,setSizeUser]=useState<number>()
  const [usersCheck,setUsersCheck]=useState<any[]>([]);
  const [usersFilter,setUsersFilter]=useState<any[]>([]); 
  const [valueCalendarDay,setValueCalendarDay]=useState<any>();
  const [valueDateStart,setValueDateStart]=useState<any>();
  const [valueDateEnd,setValueDateEnd]=useState<any>();
  
  // const [getUsersClock, {data:resultUserClock}] = useLazyQuery(GET_TIMECHECKER_EXPORT, {
  //   fetchPolicy: 'no-cache'
  // });

  const [getUsersClock, {data:resultUserClock}] = useLazyQuery(GET_TIMECHECKER_OPT, {
   fetchPolicy: 'no-cache'
    });

    useEffect(()=>{
      if(resultUserClock && resultUserClock.GET_TIMECHECKER_OPT){
       const lenghtArrayUsers=resultUserClock.GET_TIMECHECKER_OPT.length;
       console.log(resultUserClock.GET_TIMECHECKER_OPT)
       setUsersClock(resultUserClock.GET_TIMECHECKER_OPT);     
       setSizeUser(lenghtArrayUsers);
       setUsersFilter(resultUserClock.GET_TIMECHECKER_OPT);  
      }
      else{
       setUsersClock([]);     
       setSizeUser(0);
       setUsersFilter([]);  
      }
    }, [resultUserClock]);
 
  const handleChangeFilter=async (e:any)=>{
   setValueFilter(e.target.value); 
   setValueMonth(format(new Date(), 'MMMM', { locale: es }))
   setUsersFilter(usersClock)
   const array:any=[]
   
   const valor= e.target.value;    
   if(valor === 'month'){
       /*usersClock.map((user: any) => {
         if(format(new Date(user.Fecha), 'MMMM', { locale: es }) === valueMonth){
                 array.push(user)        
            }
         return array         
      }) 
      setUsersFilter(array)*/
      let mes =format(new Date(), 'L', { locale: es })
      let year =format(new Date(), 'yyyy', { locale: es })
        const  scheduleInput= {
          option:3,
          idCalendar:parseInt(idCalendar),
          day:'',
          month:parseInt(mes),
          initialDay:'',
          endDay:'',
          year:parseInt(year)
        } 
        await  getUsersClock({variables:
            {input:scheduleInput}
        });   
     }
   if(valor === 'day'){
      let dateCalendar=new Date(); 
      let dateFormatCalendar=format(dateCalendar, 'yyyy/MM/dd', { locale: es });
      setValueCalendarDay(dateFormatCalendar)
      const  scheduleInput= {
        option:1,
        idCalendar:parseInt(idCalendar),
        day:valueCalendarDay,
        month:parseInt(valueMonthNumber),
        initialDay:'',
        endDay:'',
        year:0
      } 
      await  getUsersClock({variables:
           {input:scheduleInput}
       });
     //  if(resultUserClock.GET_TIMECHECKER_OPT.length>0){
        setUsersFilter(resultUserClock.GET_TIMECHECKER_OPT)      
      // }
     }  
   if(valor === 'date'){
         setUsersFilter([])            
     }   
  }
 
  const backMonth=async()=>{
   const res = subMonths((month), 1)  
   setUsersFilter([])      
   setMonth(res)  
   setValueMonth(format(res, 'MMMM', { locale: es })) 
   let mes =format(res, 'L', { locale: es })
   let year =format(new Date(), 'yyyy', { locale: es })
   const  scheduleInput= {
    option:3,
    idCalendar:parseInt(idCalendar),
    day:'',
    month:parseInt(mes),
    initialDay:'',
    endDay:'',
    year:parseInt(year),
  } 
  await  getUsersClock({variables:
       {input:scheduleInput}
   });   
 /*const array:any=[]
   if(usersCheck.length >0){ 
    const array:any=[]
       usersCheck.map((id:any) => {
         usersClock.map((user: any) => {
             if(user.id === id && format(new Date(user.Fecha), 'MMMM', { locale: es }) === format(res, 'MMMM', { locale: es })){
                array.push(user)        
               }
               return array                
          })         
          return array       
         })  
         array.filter((data: any, index:any, j:any) => 
       index === j.findIndex((t:any) => (t.id === data.id))
      )      
       setUsersFilter(array)    
     }else{
    usersClock.map((user: any) => {
     if(format(new Date(user.Fecha), 'MMMM', { locale: es }) === format(res, 'MMMM', { locale: es })){
             array.push(user)        
        }
     return array         
   }) 
   setUsersFilter(array)
  }*/
  }

  const nextMonth=async()=>{
  const res = addMonths((month), 1)  
   setMonth(res)
   setValueMonth(format(res, 'MMMM', { locale: es }))    
   let mes =format(res, 'L', { locale: es })
   let year =format(new Date(), 'yyyy', { locale: es })
   const  scheduleInput= {
    option:3,
    idCalendar:parseInt(idCalendar),
    day:'',
    month:parseInt(mes),
    initialDay:'',
    endDay:'',
    year:parseInt(year)
  } 
  await  getUsersClock({variables:
       {input:scheduleInput}
   }); 
  /* const array:any=[]
   if(usersCheck.length >0){ 
    const array:any=[]
       usersCheck.map((id:any) => {
         usersClock.map((user: any) => {
             if(user.id === id && format(new Date(user.Fecha), 'MMMM', { locale: es }) === format(res, 'MMMM', { locale: es })){
                array.push(user)        
               }
               return array                
          })         
          return array       
         })  
         array.filter((data: any, index:any, j:any) => 
       index === j.findIndex((t:any) => (t.id === data.id))
      )      
       setUsersFilter(array)    
     }else{
          usersClock.map((user: any) => {
            if(format(new Date(user.Fecha), 'MMMM', { locale: es }) === format(res, 'MMMM', { locale: es })){
                    array.push(user)        
              }
            return array         
        }) 
        setUsersFilter(array)
      }*/
   }

  const handleViewColaboradores=()=>{
  const view =false;
  if(viewColaboradoes===view )
  {
    setViewColaboradoes(true)    
    if(usersClock.length !==0 && usersClock.length === sizeUsers){
     let array:any=[];
     let obj={}     
     usersClock.filter((data: any, index, j) => 
      index === j.findIndex((t:any) => (t.id === data.id))
     ).map((user:any)=>{
       obj={ id:user.id,nombre: user.Nombre }
       array.push(obj)   
       return array
       })               
       setUserFilerCombo(array)            
     } 
  }
  else{
    setViewColaboradoes(false)
  }  
  }

  useEffect(()=>{
    if(state.openListUserSchedule){
        const  scheduleInput= {
        option:1,
        idCalendar:parseInt(idCalendar),
        day:valueDay,
        month:parseInt(valueMonthNumber),
        initialDay:'',
        endDay:'',
        year:0
      } 
       getUsersClock({variables:
           {input:scheduleInput}
       });
    }
  }, [state.openListUserSchedule]);
  
  const handleClickI= async (e: any,id:number)=>{
       setUsersCheck([...usersCheck, id]);
    if (!e.target.checked) {
      setUsersCheck(usersCheck.filter((lis: any) => lis !== id));
     }
  }

 const handleFilterUser=async () =>{
  const filter=valueFilter;  
  const array:any=[]
   switch (filter) {
    case "day":
       if(usersCheck.length >0){ 
           usersCheck.map((id:any) => {
             usersClock.map((user: any) => {
                 if(user.id === id && (user.Fecha === valueCalendarDay)){
                    array.push(user)        
                   }
                   return array                
              })         
              return array       
             })  
             array.filter((data: any, index:any, j:any) => 
           index === j.findIndex((t:any) => (t.id === data.id))
          )      
           setUsersFilter(array)    
         }else{
          setUsersFilter(usersClock)
         }
      break;
     case "month":    
      if(usersCheck.length >0){ 
       const array:any=[]
          usersCheck.map((id:any) => {
            usersClock.map((user: any) => {
                if(user.id === id && format(new Date(user.Fecha), 'MMMM', { locale: es }) === format(month, 'MMMM', { locale: es })){
                   array.push(user)        
                  }
                  return array                
             })         
             return array       
            })  
            array.filter((data: any, index:any, j:any) => 
          index === j.findIndex((t:any) => (t.id === data.id))
         )      
          setUsersFilter(array)    
        }else{
          setUsersFilter(usersClock)
         }
      break
     case "date":
      if(usersCheck.length >0){ 
        usersCheck.map((id:any) => {
         usersClock.map((user: any) => {
             if(user.id === id && ( (isEqual (new Date(user.Fecha ), new Date(valueDateStart ))  ||
                                     isAfter(new Date(user.Fecha), new Date(valueDateStart )) ) &&
                                    (isBefore(new Date(user.Fecha ), new Date(valueDateEnd )) ||
                                     isEqual (new Date(user.Fecha ), new Date(valueDateEnd )) )  )){
                array.push(user)        
               }
               return array                
          })         
          return array       
         })  
         array.filter((data: any, index:any, j:any) => 
       index === j.findIndex((t:any) => (t.id === data.id))
      )      
       setUsersFilter(array)    
     }else{
      setUsersFilter(usersClock)
     }      
    break;   
     }
       setViewColaboradoes(false);  
    }

  const handleFilterDate=async (e: any)=>{
  let dateCalendar=addDays(new Date(e.target.value),1) 
  let dateFormatCalendar=format(dateCalendar, 'yyyy-MM-dd', { locale: es });
  const  scheduleInput= {
    option:1,
    idCalendar:parseInt(idCalendar),
    day:dateFormatCalendar,
    month:parseInt(valueMonthNumber),
    initialDay:'',
    endDay:'',
    year:0
  } 
  await  getUsersClock({variables:
       {input:scheduleInput}
   });
  setUsersFilter(resultUserClock.GET_TIMECHECKER_OPT)      
   // const array:any=[]
  // if(usersCheck.length >0){ 
  //   const array:any=[]
  //      usersCheck.map((id:any) => {
  //        usersClock.map((user: any) => {
  //            if(user.id === id && user.Fecha === dateFormatCalendar){
  //               array.push(user)        
  //              }
  //              return array                
  //         })         
  //         return array       
  //        })  
  //        array.filter((data: any, index:any, j:any) => 
  //      index === j.findIndex((t:any) => (t.id === data.id))
  //     )      
  //      setUsersFilter(array)    
  //    }else{
  //     usersClock.map((user: any) => {
  //       if(user.Fecha === dateFormatCalendar){
  //               array.push(user)        
  //          }
  //       return array                
  //      })    
  //      array.filter((data: any, index:any, j:any) => 
  //      index === j.findIndex((t:any) => (t.id === data.id))
  //     )       
  //      setUsersFilter(array)
     // }     
   }

  const handleStartDate=async (e: any)=>{
  let dateCalendar=addDays(new Date(e.target.value),1) 
  let dateFormatCalendar=format(dateCalendar,'yyyy-MM-dd', { locale: es });
  setValueDateStart(dateFormatCalendar)
  const  scheduleInput= {
    option:2,
    idCalendar:parseInt(idCalendar),
    day:'',
    month:parseInt(valueMonthNumber),
    initialDay:dateFormatCalendar,
    endDay:valueDateEnd,
    year:0
  } 
  await  getUsersClock({variables:
       {input:scheduleInput}
   });
  setUsersFilter(resultUserClock.GET_TIMECHECKER_OPT)

   }

  const handleEndDate=async(e: any)=>{
  let dateCalendarEnd=addDays(new Date(e.target.value),1) 
  let dateFormatCalendarEnd=format(dateCalendarEnd,'yyyy-MM-dd', { locale: es });
  setValueDateEnd(dateFormatCalendarEnd)
  if(!valueDateStart)
  {
   ErrorAlert({text:'Seleccione el fecha inicial'});
   return 
  }
  const dateAfterEnd = isAfter(new Date(dateFormatCalendarEnd), new Date(valueDateStart))
  if(!dateAfterEnd){
    ErrorAlert({text:'la fecha inicial debe ser menor a la final'});
    return
  }
  const  scheduleInput= {
    option:2,
    idCalendar:parseInt(idCalendar),
    day:'',
    month:parseInt(valueMonthNumber),
    initialDay:valueDateStart,
    endDay:dateFormatCalendarEnd,
    year:0
  } 
  await  getUsersClock({variables:
       {input:scheduleInput}
   });
  setUsersFilter(resultUserClock.GET_TIMECHECKER_OPT)
  /*const array:any=[]
  if(usersCheck.length >0){ 
       usersCheck.map((id:any) => {
        usersClock.map((user: any) => {
            if(user.id === id && ( (isEqual (new Date(user.Fecha ), new Date(valueDateStart ))  ||
                                    isAfter(new Date(user.Fecha), new Date(valueDateStart )) ) &&
                                   (isBefore(new Date(user.Fecha ), new Date(dateFormatCalendarEnd )) ||
                                    isEqual (new Date(user.Fecha ), new Date(dateFormatCalendarEnd )) )  )){
               array.push(user)        
              }
              return array                
         })         
         return array       
        })  
        array.filter((data: any, index:any, j:any) => 
      index === j.findIndex((t:any) => (t.id === data.id))
     )      
      setUsersFilter(array)    
    }else{
        let busca = usersClock.filter((user:any) =>{
          if(
            (isEqual (new Date(user.Fecha ), new Date(valueDateStart ))  ||
              isAfter(new Date(user.Fecha), new Date(valueDateStart )) ) &&
              (isBefore(new Date(user.Fecha ), new Date(dateFormatCalendarEnd )) ||
            isEqual (new Date(user.Fecha ), new Date(dateFormatCalendarEnd )) )      
              ){         
              return user;
          }    
          })
        setUsersFilter(busca)
     } */   
   }

  const handleUserFilter =(e:any)=>{
   if (e !== '') {
   const userFiltrado = userFilterCombo.filter((lis: any) =>lis.nombre.toUpperCase().includes(e.toUpperCase()))
   setUserFilerCombo(userFiltrado)
  }
  else{
    let array:any=[];
    let obj={}     
    usersClock.filter((data: any, index, j) => 
     index === j.findIndex((t:any) => (t.id === data.id))
    ).map((user:any)=>{
      obj={ id:user.id,nombre: user.Nombre }
      array.push(obj)   
      return array
      })               
      setUserFilerCombo(array)     
  }
  }

  const handleReportrelogUser=()=>{
    const TipoReporte="Reporte Registros de tiempos";   
    CreateExcelRelogChecador(usersFilter,TipoReporte)    
  }

  const resetStates=()=>{
    setUsersFilter([]);
    setUsersClock([]);
    setUserFilerCombo([]);
    setUsersCheck([]);
    setViewColaboradoes(false);
    setValueFilter('day')
    setMonth(new Date())
    setValueMonth(format(new Date(), 'MMMM', { locale: es }));
  }

  return (
    <Dialog open={state.openListUserSchedule} aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth={true} >
        <div className="dialogContainer" >
          <div className="containerTitle">
           <div className="centerContainer"
              style={{
                  marginLeft:'auto',
                  marginRight:'auto'
              }}
              >             
           </div>
           <div className="contenedorCerrarModal">
                  <div className="iconoCerrarModal" onClick={(()=> { resetStates(); closeListUserSchedule(false, dispatch)} )}></div>      
           </div> 
          </div>
          <div className="containerFiltroRelojChecador">
            <h2 id="form-dialog-title" data-testid="TitleModal">Registros de tiempos</h2>
            <div className="selectFiltroCheck">
            <Select
                native
                label="filtroRelojChecador"
                name="filtroRelojChecador"
                autoFocus={true}              
                onChange={(e) => { handleChangeFilter(e) }}
              > 
                <option value="day"  >Mostrar listado por día</option>  
                <option value="month">Mostrar listado por mes</option>                             
                <option value="date" >Mostrar listado por fecha</option>               
              </Select>   
            </div>            
            <div className="elementFiltro">
              {
              valueFilter === 'day'?
              (<>
               <TextField
                id="date"
                variant="outlined"
                margin="dense"
                label="Día*"
                type="date"
                onChange={(e) => { handleFilterDate(e) }}
                defaultValue={format(new Date(), "yyyy-MM-dd")}
               /></> ):
               valueFilter === 'month'? 
               (<>
                  <div className="contenedorMonth">
                    <div className="backArrow"><ArrowBackIosIcon onClick={((e)=>backMonth())}/></div>
                    <div className="infoMont">{valueMonth}</div>                   
                    <div className="nextArrow"><ArrowForwardIosIcon onClick={(()=>nextMonth())}/></div>
                  </div>                                
                </>)
               :
               (<>
               <div className="dateInit">
               <TextField
                id="dateInit"
                variant="outlined"
                margin="dense"
                label="Fecha inicial*"  
                onChange={(e) => { handleStartDate(e) }}              
                type="date"
                defaultValue={format(new Date(), "yyyy-MM-dd")}
               />
               </div>  
              
               <TextField
                id="dateEnd"
                variant="outlined"
                margin="dense"
                label="Fecha final*"
                type="date"
                onChange={(e) => { handleEndDate(e) }}                
                defaultValue={format(new Date(), "yyyy-MM-dd")}
               />              
               </>
                )
             }
           
            </div>    
          </div>          
          <div className="containerFindColaborador">
            <div className="containderImputsSearcher">
                 <input
                   placeholder="Filtrar por Colaborador"
                   className="inputSearcherColaborador"   
                   onChange={(e: any) => handleUserFilter(e.target.value)}                
                   onClick={(e: any) => handleViewColaboradores()}
              />
            </div>
            {viewColaboradoes && (
            <div>        
            <div className="divColaboradoresList">
             <fieldset  className="fieldsetUpdateFiltro">
               <div className="checkboxitemColaboradores">
                 <input
                   type="checkbox"
                   id="erer"
                   // onChange={(e) => handleSelectAll(e)}
                 ></input>
                 <label htmlFor="erer"> </label>
               </div>

               <input
                 className="inputUpdateColaboradores"
                 type="text"
                 id="txtFiltro"
                 placeholder="Colaboradores"
               //  onChange={(e) => filtrarCollaborator(e.target.value)}
               />
               <img src={`/assets/svg/find.svg`} alt="Buscar" />
             </fieldset>
             <ul className="contenedorListaColaboradoresReloj">
               {
                userFilterCombo.length >0 ?
                userFilterCombo.map((lis: any, key:number) => (
                  <li className="listaNombresColaboradores" key={key}>
                    <div id="UniversoI" className="checkboxitemColaboradores">
                      <input
                        key={"Colaborador"+lis.nombre}
                         id={"Colaborador"+lis.nombre}
                         type="checkbox"
                         value={lis.id}
                         checked={usersCheck.includes(lis.id)}
                        className="colaborador"
                        onClick={(e) => handleClickI(e, lis.id)}
                       ></input>
                      <label htmlFor={"Colaborador"+lis.nombre}> {lis.nombre}</label>
                    </div>
                  </li>
               )):<></>
              }
             </ul>
             <br></br>
             <div className="contenedorBtnAgregarColaborador">
             <button className="botonAgregarColaborador" 
               onClick={(e) => handleFilterUser()}                
              >                 
               <span className="textoAgregarColaborador">Guardar</span>
             </button>
             </div>
           </div>
            </div>
            )}  
       </div>
        <DialogContent>
            <TableBits
              columns={columns}
              rows={usersFilter}            
              options={false}
            />            

          </DialogContent>
          <div className="btnRelojChecador">
              <button className="botonCancelarReloj" type="button" 
              onClick={(() =>{
                     resetStates();
                     closeListUserSchedule(false, dispatch); 
                      }
                     )}>
                  Cancelar
              </button>

              <button className="btnDescargarLista" 
                      onClick={(e: any) => handleReportrelogUser()}
              >                 
               <span className="textoAgregarColaborador">Descargar Lista</span>
             </button>
          </div>         
        </div>
    </Dialog>
  )
}

export default UserScheduleList;