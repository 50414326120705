import { useEffect, useState, useContext, Fragment } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import {
  Grid,
  TextField,
  InputLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import { Select } from "@material-ui/core";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import {
  GET_ALL_PAYROLL_GROUP,
  GET_ALL_ENTERPRISE,
  GET_ALL_OUTSOURCERS,
  GET_ALL_TIPOJORNADA,
  GET_ALL_CALENDARS,
  GET_ALL_WEEKPARTTIME,
  GET_POLITIC_SAVING_BY_PAYROLLGROUP,
  GET_ALL_WORKINGHOURS
} from "../../../../Querys/querys";
import { useQuery, useLazyQuery } from "@apollo/client";
import { EventualPayroll } from "../../../../interfaces/TabEventualPayroll.interfaces";
import EyeIcon from "../../../../assets/svg/eye.svg";
import { formatterMxn } from "../../../../helpers/formatoMoneda";
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import { withStyles, Theme } from "@material-ui/core/styles";
import { PoliticSaving as Politic } from "../../../../interfaces/TabPolitic.interfaces";

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 1000,
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const HiringDataInactivos = (props: any) => {
  const { data: resultCompany } = useQuery(GET_ALL_ENTERPRISE);
  const allCompanies = resultCompany?.GET_ALL_ENTERPRISE;
  const { data: resultOutsources } = useQuery(GET_ALL_OUTSOURCERS);
  const allOutsources = resultOutsources?.GET_ALL_OUTSOURCERS;
  const [companies, setCompanies] = useState([]);
  const [archives, setArchives] = useState([]);
  const [registros_patronales, setRegistrosPatronales] = useState([]);
  const { data: resultWorkingHours } = useQuery(GET_ALL_WORKINGHOURS);
  const allWorkingHours = resultWorkingHours?.GET_ALL_WORKINGHOURS;
  const { data: resultPayRollGroup } = useQuery(GET_ALL_PAYROLL_GROUP);
  const allPayRollGroups = resultPayRollGroup?.GET_ALL_PAYROLL_GROUP;
  const [payRollGroups, setPayRollGroups] = useState([]);

  const [tipoContratacion, setTipoContratacion] = useState("Directa");
  const [tipoEsquema, setTipoEsquema] = useState("Nomina");
  const [typeContract, setTypeContract] = useState("indefinida");
  const [typeSalary, setTypeSalary] = useState("Bruto");
  const [employerRegister, setEmployerRegister] = useState("");
  const [idEnterprise, setIdEnterprise] = useState("");
  const [idPayRollGroup, setIdPayRollGroup] = useState("");
  const { state } = useContext(CollaboratorContext);
  const [outsources, setOutsources] = useState([]);
  const [jornadas, setJornadas] = useState<Array<any>>([]);
  const [filterCalendars, setFilterCalendars] = useState<Array<any>>([]);
  const [weekPartTime, setWeekPartTime] = useState<Array<any>>([]);
  const { data: resultJornada } = useQuery(GET_ALL_TIPOJORNADA);
  const allJornadas = resultJornada?.GET_ALL_TIPOJORNADA;
  const { data: resultWeekPartTime } = useQuery(GET_ALL_WEEKPARTTIME);
  const allWeekPartTime = resultWeekPartTime?.GET_ALL_WEEKPARTTIME;
  const { data: resultCalendars } = useQuery(GET_ALL_CALENDARS);
  const allCalendars = resultCalendars?.GET_ALL_CALENDARS;
  const [selectedPolitic, setSelectedPolitic] = useState<Partial<Politic>>({
    saving_fund_cap: 0
  });
  const [checkPayrollSavingConfig, { data:resultPolitic }] = useLazyQuery(GET_POLITIC_SAVING_BY_PAYROLLGROUP);
  const [workingHours, setWorkingsHours] = useState([]);

  useEffect(() => {
    if (allJornadas) setJornadas(allJornadas);
    if (allWeekPartTime) setWeekPartTime(allWeekPartTime);
    if (allCalendars) {
      setFilterCalendars([...allCalendars]);
    }
    if(allWorkingHours){
      setWorkingsHours(allWorkingHours);
    }
  }, [allJornadas, allCalendars, allWeekPartTime]);

  useEffect(()=>{
    if(resultPolitic && resultPolitic.GET_POLITIC_SAVING_BY_PAYROLLGROUP){
      const result = resultPolitic.GET_POLITIC_SAVING_BY_PAYROLLGROUP;
      setSelectedPolitic( result );
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultPolitic]);

  const handleSetFormat = (value: any, field: any) => {

    if (typeof value === "string") {
      let valueFormatted = value.replace(/,/g, "");

      valueFormatted = parseFloat(valueFormatted).toFixed(2);

      let x = formatterMxn(valueFormatted);
      if (x === "nan") return false;
      x = x.substring(1);
      return x;
    } else {
      let x = formatterMxn(value);
      if (x === "nan") return false;
      x = x.substring(1);
      return x;
    }
  };

  useEffect(() => {
    if (state.collaborator) {
      setTipoContratacion(state.collaborator?.tittle);
      setTipoEsquema(state.collaborator?.nominesPayroll);
      setTypeContract(state.collaborator?.typeContract);
      setTypeSalary(state.collaborator?.typeSalary);
      setEmployerRegister(state.collaborator?.EmployerRegister);
      setIdEnterprise(state.collaborator?.idEnterprise);
      setIdPayRollGroup(state.collaborator?.id_payroll_group);
      if (state.collaborator?.archive) {
        setArchives(state.collaborator.archive);
      }
      if(state.collaborator?.id_payroll_group){
        checkPayrollSavingConfig({ variables:{ payrollGroupId: state.collaborator.id_payroll_group } });
      }
      state.collaborator.DS = handleSetFormat(state.collaborator?.DS, "DS");
      state.collaborator.ISD = handleSetFormat(state.collaborator?.ISD, "ISD");
      state.collaborator.SDG = handleSetFormat(state.collaborator?.SDG, "SDG");
      state.collaborator.recordableSalary = handleSetFormat(
        state.collaborator?.recordableSalary,
        "recordableSalary"
      );
      state.collaborator.notRecordableSalary = handleSetFormat(
        state.collaborator?.notRecordableSalary,
        "notRecordableSalary"
      );
      state.collaborator.totalSalary = handleSetFormat(
        state.collaborator?.totalSalary,
        "totalSalary"
      );
      state.collaborator.grossSalary = handleSetFormat(
        state.collaborator?.grossSalary,
        "grossSalary"
      );
    }
    if (allCompanies) {
      setCompanies(allCompanies);

      if (state.collaborator?.tittle === "Directa") {
        for( const company of allCompanies){
          if (String(company.id) === String(state.collaborator?.idEnterprise)) {
            if (company.registro_patronal) {
              setRegistrosPatronales(company.registro_patronal);
            }
          }
        };
      }
    }
    if (allOutsources) {
      setOutsources(allOutsources);
    }
    if (allPayRollGroups) {
      setPayRollGroups(allPayRollGroups);
    }
  }, [allCompanies, allPayRollGroups, state.collaborator, allOutsources]);

  return (
    <>
      <div className="novalidate__border">
        <span style={{ fontWeight: "bold" }}>Tipo de contratación</span>
        <Grid
          direction="row"
          container
          spacing={3}
        //   style={{ border: "1px solid red" }}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Directa"
                name="tittle"
              >
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Contratación directa"
                  value="Directa"
                  checked={tipoContratacion === "Directa" ? true : false}
                  name="tittle"
                  disabled={true}
                />
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Outsourcer"
                  value="outsource"
                  checked={tipoContratacion === "outsource" ? true : false}
                  name="tittle"
                  disabled={true}
                />
              </RadioGroup>
            </FormControl>
            {tipoContratacion === "Directa" ? (
              <>
                <Grid spacing={3} direction="row" container>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Empresaa</InputLabel>

                      <Select
                        native
                        name="idEnterprise"
                        defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        {companies.map(({ name, id }: any, index: number) => (
                          <>
                            {String(idEnterprise) === String(id) ? (
                              <option key={index} value={id} selected>
                                {name}
                              </option>
                            ) : (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            )}
                          </>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Registro patronal</InputLabel>

                      <Select
                        native
                        name="EmployerRegister"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        {registros_patronales.map(
                          ({ registroPatronal }: any, index: number) => (
                            <>
                              {String(employerRegister) === String(registroPatronal) ? (
                                <option
                                  key={index}
                                  value={registroPatronal}
                                  selected
                                >
                                  {registroPatronal}
                                </option>
                              ) : (
                                <option key={index} value={registroPatronal}>
                                  {registroPatronal}
                                </option>
                              )}
                            </>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Calendario</InputLabel>

                      <Select
                        native
                        name="calendarId"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        {filterCalendars.map(
                          ({ name, id }: any, index: number) => (
                            <>
                              {String(state.collaborator?.calendarId) === String(id) ? (
                                <option key={index} value={id} selected>
                                  {name}
                                </option>
                              ) : (
                                <option key={index} value={id}>
                                  {name}
                                </option>
                              )}
                            </>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                //   style={{ border: "1px solid red" }}
                >
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Sindicalizado</InputLabel>
                      <Select
                        native
                        name="unionized"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        <option value={""} disabled={true} selected>
                          {""}
                        </option>
                        <option
                          value={"1"}
                          selected={state.collaborator?.unionized === true}
                        >
                          {"Si"}
                        </option>
                        <option
                          value={"0"}
                          selected={state.collaborator?.unionized === false}
                        >
                          {"No"}
                        </option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Tipo de trabajador</InputLabel>
                      <Select
                        native
                        name="typeWorker"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        <option disabled={true}
                          value=""
                        >
                          {""}
                        </option>
                        <option
                          value={"1) Trab. permanente"}
                          selected={state.collaborator?.typeWorker === "1) Trab. permanente"}
                        >
                          {"1) Trab. permanente"}
                        </option>
                        <option
                          value={"2) Trab. Ev. Ciudad"}
                          selected={state.collaborator?.typeWorker === "2) Trab. Ev. Ciudad"}
                        >
                          {"2) Trab. Ev. Ciudad"}
                        </option>
                        <option
                          value={"3) Trab. Ev. Construcción"}
                          selected={state.collaborator?.typeWorker === "3) Trab. Ev. Construcción"}
                        >
                          {"3) Trab. Ev. Construcción"}
                        </option>
                        <option
                          value={"4) Eventual del campo"}
                          selected={state.collaborator?.typeWorker === "4) Eventual del campo"}
                        >
                          {"4) Eventual del campo"}
                        </option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Tipo de salario</InputLabel>
                      <Select
                        native
                        name="typeSalaryN"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        <option disabled={true}
                          value={""}
                        >
                          {""}
                        </option>
                        <option
                          value={"Salario fijo"}
                          selected={state.collaborator?.typeSalaryN === "Salario fijo"}
                        >
                          {"Salario fijo"}
                        </option>
                        <option
                          value={"Salario variable"}
                          selected={state.collaborator?.typeSalaryN === "Salario variable"}
                        >
                          {"Salario variable"}
                        </option>
                        <option
                          value={"Salario mixto"}
                          selected={state.collaborator?.typeSalaryN === "Salario mixto"}
                        >
                          {"Salario mixto"}
                        </option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  direction="row"
                  container
                  spacing={3}
                //   style={{ border: "1px solid red" }}
                >
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Jornada laboral</InputLabel>
                      <Select
                        native
                        name="idWorkingHours"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        <option selected disabled>
                          Seleccione una jornada laboral
                        </option>
                        {workingHours.map(
                          ({ id, Nombre }: any, index: number) =>
                            String(state.collaborator?.idWorkingHours) === String(id) ? (
                              <option key={index} value={id} selected>
                                {Nombre}
                              </option>
                            ) : (
                              <option key={index} value={id}>
                                {Nombre}
                              </option>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Jornada</InputLabel>
                      <Select
                        native
                        name="idJourney"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        <option value={""} disabled={true}>
                          {""}
                        </option>
                        {jornadas.map(
                          ({ clave, Descripcion }: any, index: number) =>
                            String(state.collaborator?.idJourney) === String(clave) ? (
                              <option key={index} value={clave} selected>
                                {Descripcion}
                              </option>
                            ) : (
                              <option key={index} value={clave}>
                                {Descripcion}
                              </option>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={4} item>
                    <FormControl
                      variant="outlined"
                      fullWidth={true}
                      size="small"
                    >
                      <InputLabel>Semana/jornada reducida</InputLabel>
                      <Select
                        native
                        name="weekPartTimeId"
                        // defaultValue={idEnterprise || ""}
                        autoFocus={true}
                        disabled={true}
                        style={{ height: "42px" }}
                        variant="outlined"
                      >
                        <option disabled={true}
                          value=""
                        >
                          {""}
                        </option>
                        {weekPartTime.map(
                          ({ id, clave, name }: any, index: number) =>
                            String(state.collaborator?.weekPartTimeId) === String(id) ? (
                              <option key={index} value={id} selected>
                                {name}
                              </option>
                            ) : (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>
              </>
            ) : (
              <Grid spacing={3} direction="row" container>
                <Grid xs={5} item>
                  <FormControl variant="outlined" fullWidth={true} size="small">
                    <InputLabel>Empresa</InputLabel>
                    <Select
                      native
                      name="idEnterprise"
                      defaultValue={idEnterprise || ""}
                      autoFocus={true}
                      disabled={true}
                      style={{ height: "42px" }}
                      variant="outlined"
                    >
                      {outsources.map(({ nombre, id }: any, index: number) => (
                        <>
                          {idEnterprise === id ? (
                            <option key={index} value={id} selected>
                              {nombre}
                            </option>
                          ) : (
                            <option key={index} value={id}>
                              {nombre}
                            </option>
                          )}
                        </>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  spacing={3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                ></Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span>Vigencia del contrato</span>
        <Grid
          direction="row"
          container
          spacing={3}
        //   style={{ border: "1px solid red" }}
        >
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="indefinida"
                name="typeContract"
              >
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Indefinida"
                  value="indefinida"
                  name="typeContract"
                  checked={typeContract === "indefinida" ? true : false}
                  disabled={true}
                />
                <FormControlLabel
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Temporal"
                  value="temporal"
                  name="typeContract"
                  checked={typeContract === "temporal" ? true : false}
                  disabled={true}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {typeContract === "temporal" ? (
          <>
            <span>Fecha de vigencia del contrato</span>

            <TextField
              id="outlined-basic"
              variant="outlined"
              type="date"
              size="small"
              style={{ width: "100%" }}
              name="dateContractDate"
              disabled={true}
              defaultValue={
                state.collaborator
                  ? moment(state.collaborator.dateContractDate).add(1, "day").format(
                    "YYYY-MM-DD"
                  )
                  : ""
              }
              InputProps={{
                readOnly: true,
                inputProps: {
                  min: moment(state.collaborator.dateOfAdmission)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                },
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className={
          state.sections[2] >= 100 ? `validate__border` : `novalidate__border`
        }
      >
        <span style={{ fontWeight: "bold" }}>Grupo de nóminas</span>
        <Grid
          spacing={3}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <FormControl fullWidth={true} size="small" style={{ width: "100%" }}>
            <Select
              native
              name="id_payroll_group"
              defaultValue={idPayRollGroup || ""}
              autoFocus={true}
              disabled={true}
              style={{ height: "42px" }}
              variant="outlined"
            >
              {payRollGroups.map(
                ({ group_name, id }: EventualPayroll, index: number) => (
                  <>
                    {String(idPayRollGroup) === String(id) ? (
                      <option key={index} value={id} selected>
                        {group_name}
                      </option>
                    ) : (
                      <option key={index} value={id}>
                        {group_name}
                      </option>
                    )}
                  </>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <span style={{ fontWeight: "bold" }}>Fondo de ahorro</span>
        <Grid direction="row" container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
                control={
                  <Checkbox
                    checked={state.collaborator?.isFondoAhorro}
                    value={state.collaborator?.isFondoAhorro}
                    name="isFondoAhorro"
                    color="primary"
                    disabled={true}
                  />
                }
                label="Tiene Fondo de ahorro"
              />
          </Grid>
          <Grid item xs={4}>
            <TextField
                style={{
                  width: "100%",
                  display: "inline-block",
                }}
                label="Porcentaje Fondo Ahorro"
                id="outlined-basic"
                variant="outlined"
                type="text"
                size="small"
                value={state.collaborator?.PorcentajeFondoAhorro}
                name="PorcentajeFondoAhorro"
                InputProps={{
                  startAdornment: (
                    selectedPolitic.saving_fund_type === 'Porcentual' ? <InputAdornment position="start">%</InputAdornment>  : <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={4}></Grid>
        </Grid>
        <span style={{ fontWeight: "bold" }}>Tipo de esquema</span>
        <Grid direction="row" container spacing={3}>
          <Grid xs item>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="Nomina"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="Nomina"
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="100% Nómina !"
                  checked={tipoEsquema === "Nomina" ? true : false}
                  name="nominesPayroll"
                  disabled={true}
                />
                <FormControlLabel
                  value="Mixto"
                  control={<Radio size="small" style={{ color: "#fabb00" }} />}
                  label="Mixto"
                  checked={tipoEsquema === "Mixto" ? true : false}
                  name="nominesPayroll"
                  disabled={true}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {/* 100% nomina */}

        {tipoEsquema === "Nomina" ? (
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{ marginTop: "4px" }}
            >
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={state.collaborator?.grossSalary}
                    name="grossSalary"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    disabled={true}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7}>
              {
                  typeSalary === "Neto" &&
                    <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Neto </FormLabel>
                }
                {
                  typeSalary === "Bruto" &&
                    <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Bruto </FormLabel>
                } 
              </Grid>
            </Grid>
          </>
        ) : (
          // Esquema Mixto
          <>
            <FormLabel style={{ marginBottom: "4px", fontWeight: "bold" }}>
              Ingreso Mensual
            </FormLabel>
            <Grid
              direction="row"
              container
              spacing={3}
              style={{
                display: "flex",
                marginTop: "2px",
              }}
            >
              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px" }}>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="grossSalary"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    defaultValue={state.collaborator?.grossSalary}
                    value={state.collaborator?.grossSalary}
                    disabled={true}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px" }}>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Salario diario"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="DS"
                    value={state.collaborator?.DS}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>

            <FormLabel style={{ fontWeight: "bold" }}>
              Ingreso gravable
            </FormLabel>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Ingreso mensual"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="recordableSalary"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    defaultValue={state.collaborator?.recordableSalary}
                    value={state.collaborator?.recordableSalary}
                    disabled={true}
                  />
                </FormControl>
              </Grid>

              {tipoEsquema === "Mixto" && (
                <>
                  <Grid item xs={4}>
                    <FormControl>
                      <TextField
                        style={{ width: "100%" }}
                        required
                        label="Salario diario gravable"
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        size="small"
                        name="SDG"
                        value={state.collaborator?.SDG}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Salario diario integrado"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="ISD"
                    value={state.collaborator?.ISD}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {tipoEsquema === "Mixto" && (
              <>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    marginTop: "2px",
                  }}
                >
                  <Grid item xs={4}>
                    {
                      typeSalary === "Neto" &&
                        <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Neto </FormLabel>
                    }
                    {
                      typeSalary === "Bruto" &&
                        <FormLabel  style={{ marginRight: "20px", color: "inherit" }}> Bruto </FormLabel>
                    }
                  </Grid>
                </Grid>
                <FormLabel style={{ fontWeight: "bold" }}>
                  Ingreso no gravable aproximado
                </FormLabel>
                <Grid
                  direction="row"
                  container
                  spacing={3}
                  style={{
                    display: "flex",
                    marginTop: "2px",
                  }}
                >
                  <Grid item xs={4}>
                    <FormControl>
                      <TextField
                        style={{ width: "100%" }}
                        required
                        label="Total"
                        id="notRecordableSalary"
                        variant="outlined"
                        type="text"
                        size="small"
                        name="notRecordableSalary"
                        value={state.collaborator?.notRecordableSalary}
                        InputProps={{
                          readOnly: true,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid
          spacing={3}
          style={{
            marginTop: "10px",
          }}
          direction="row"
          container
        >
          {tipoEsquema === "Nomina" && (
            <>
              <Grid item xs={4}>
                <FormControl style={{ marginBottom: "4px", opacity: 0.5 }}>
                  <TextField
                    style={{ width: "100%" }}
                    required
                    label="Salario diario"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="DS"
                    value={state.collaborator?.DS}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    disabled={true}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl>
                  <TextField
                    style={{ width: "100%", opacity: 0.5 }}
                    required
                    label="Salario diario integrado"
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="ISD"
                    value={state.collaborator?.ISD}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <div className="novalidate__border">
        <Grid
          direction="row"
          container
          spacing={3}
          style={{
            // border: "1px solid red",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            disabled
            id="outlined-basic"
            variant="outlined"
            type="text"
            size="small"
            label="Periodicidad de pago"
            value={state.collaborator?.paymentPeridicity}
            style={{ background: "#f1f2f5" }}
            name="periodicidadDePago"
            InputProps={{
              readOnly: true,
            }}
          />

          <Grid xs item>
            <Grid
              direction="row"
              container
              justify="flex-end"
              alignItems="center"
            >
              <>
                <label style={{ marginRight: "10px" }}>
                  Documentos para firma
                  <HtmlTooltip
                    title={
                      <Fragment>
                        Considera subir en un sólo pdf todos los documentos
                      </Fragment>
                    }
                  >
                    <img
                      src="/assets/icons/PreguntaAzul.png"
                      alt="Question"
                      height="15"
                    />
                  </HtmlTooltip>
                </label>
                <br />
                {/*AVISO DE RETENCIÓN  */}
                {archives?.map(
                  (archive: any, index: number) =>
                    archive?.name === "DocumentosParaFirma" && (
                      <div className="flex-container">
                        <div className="flex-child">
                          <span className="span-file">
                            <a
                              className="view-document"
                              target="_blank"
                              rel="noreferrer" 
                              href={archive.URL}
                            >
                              <img
                                style={{ height: "10px", marginTop: "5px" }}
                                src={EyeIcon}
                                alt=""
                              />
                              Ver documento
                            </a>
                          </span>
                        </div>
                      </div>
                    )
                )}
              </>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default HiringDataInactivos;
