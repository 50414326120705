import * as d3 from "d3";
import { useEffect, useRef } from "react";

type DataItem = {
  name: string;
  value: number;
};

type LollipopProps = {
  width: number;
  height: number;
  data: DataItem[];
};

const LollipopPlot = ({ data, width, height }: LollipopProps) => {
  const ref = useRef<SVGSVGElement>(null);
  const sortData = data.sort((a, b) => a.value - b.value);

  useEffect(() => {
    const margin = { top: 10, right: 30, bottom: 40, left: 100 },
      width2 = width - margin.left - margin.right,
      height2 = height - margin.top - margin.bottom;

    // Clear previous chart
    d3.select(ref.current).selectAll("*").remove();
    //Creacion de svg
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width2 + margin.left + margin.right)
      .attr("height", height2 + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const xScale = d3
      .scaleLinear()
      .domain([0, d3.max(sortData, (d) => d.value) as number])
      .range([margin.left, width2 - margin.right]);

    const yScale = d3
      .scaleBand()
      .domain(sortData.map((d) => d.name))
      .rangeRound([height2 - margin.bottom, margin.top])
      .padding(1);

    //Axis x
    svg
      .append("g")
      .attr("transform", `translate(0, ${height2 - margin.bottom})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .attr("transform", "translate(-10,0)rotate(-45)")
      .style("text-anchor", "end");

    //Eje y  o axis y
    svg
      .append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale));

    //Lineas de data
    svg
      .selectAll("myline")
      .data(sortData)
      .join("line")
      .attr("x1", (d) => xScale(d.value))
      .attr("x2", xScale(0))
      .attr("y1", (d) => yScale(d.name)!)
      .attr("y2", (d) => yScale(d.name)!)
      .attr("stroke", "#fabb00");

    //Circulos del final
    svg
      .selectAll("mycircle")
      .data(sortData)
      .join("circle")
      .attr("cx", (d) => xScale(d.value))
      .attr("cy", (d) => yScale(d.name)!)
      .attr("r", "4")
      .style("fill", "#fabb00")
      .attr("stroke", "#fabb00");
  }, [data, width, height, sortData]);

  return <svg ref={ref} width={width} height={height} />;
};

export default LollipopPlot;
