/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
import { useState,useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import {
  EDIT_DATAREPORT_CAMPS
} from "../../../Querys/reports/querys";
import SelectIcon from "../../../assets/icons/reportSelect.svg";
import selSelectIcon from "../../../assets/icons/selReportSelect.svg";
import SeeIcon from "../../../assets/icons/reportSee.svg";
import selSeeIcon from "../../../assets/icons/selReportSee.svg";
import aplicar from "../../../assets/icons/aplicar.svg";
// import Filter from "../../../assets/icons/filterReport.svg";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import SaveIcon from '@mui/icons-material/Save';
import TreeView from "./TreeView";
import FilesTable from "./FilesTable";
import { removeAccents } from '../../../helpers/RemoveAccents';
import { Queue } from '../../../helpers/queue';




const FilterContainer = ({infoReport,camposBd,camposBdArray, setCamposBdArrayreport,
                          inpuntBdValues, setInpuntBdValues,
                          report,setReport,reportFilter, setReportFilter,reload,setReload,
                          deleteColumns,setDeleteColumns,inpuntValues,setInpuntValues, 
                          columnas,setColumnas, filas,setFilas,camposPivotes}:any) => {
    const { id } = useParams<{ id: string }>();
    const [selSelect    ,setSelSelect  ]  = useState(true);
    const [selView      ,setSelView    ]  = useState(false);
    const [addTxt       ,setAddTxt     ]  = useState(false);     
    const [valueFilter     ,setValueFilter]  = useState(0);
    const [chargeOnlyTime  ,setChargeOnlyTime]  = useState(false);
    const [count, setCount] = useState(0);   
    const [nameColumns     ,setNameColumns]  =  useState<any[]>([])   
    
    // const [nameColumnsOriginal  ,setNameColumnsOriginal]  = useState([]);    
    const queue = new Queue()
    

    async function run () {
      let val:any 
       while (!queue.isEmpty()) {
        let array:any =[]          
        const res = queue.dequeue()

        reportFilter.map((re:any)=>{
          for (val of Object.values(re)) {
           if(removeAccents(val.toString().toUpperCase()).includes(res.toUpperCase())){
                array.push(re)             
             }      
           }          
        })
        setReportFilter(array)       
     }
    }
    const [editDateReportCamps] = useMutation(EDIT_DATAREPORT_CAMPS); 

    const handleSelect=()=>{   
        setSelView(false)
        setValueFilter(0)     
        selSelect===false?setSelSelect(true):setSelSelect(false)        
    }

    const handleSee=()=>{   
        setSelSelect(false)   
        setValueFilter(1)       
        selView===false?setSelView(true):setSelView(false)        
    }

useEffect(() => {
  setCount(count + 1)  
  const copyObj=[...report]    
  if(camposBdArray.length >0 && count===2){
     let arrayDeleted=[];
     let filterColumns = []
     let filterRows =[]
     setDeleteColumns(camposBdArray)
     //setDeleteColumns([...deleteColumns , camposBd.replace(" ' ", '')])
     arrayDeleted = nameColumns.filter((element: any) => !camposBdArray.includes(element))
     filterColumns = columnas.filter((element: any) => !camposBdArray.includes(element))
     filterRows = filas.filter((element: any) => !camposBdArray.includes(element))
     setNameColumns(arrayDeleted.sort()) 
     setFilas(filterRows)
     setColumnas(filterColumns)   
     setCount(0)             
     setChargeOnlyTime(true)
    
    }
    if((deleteColumns.length >0 ) && (deleteColumns.length < nameColumns.length ) && (chargeOnlyTime)){
      const arrayRep:any=[];
      copyObj.map((objRep:any)=>{ 
          deleteColumns.map((column:any)=>{
              delete objRep[column]; 
              arrayRep.push(objRep)     
          })              
      })
      const columnsEliminate =  columnas.filter((item: any) => !deleteColumns.includes(item));
      const rowsEliminate = filas.filter((item: any) => !deleteColumns.includes(item));
      setColumnas(columnsEliminate)
      setFilas(rowsEliminate)        
      setReportFilter(arrayRep) 
      setChargeOnlyTime(false)
          
    }  
    let valFind:any 
    //encolamos los datos a buscar
    for(valFind of Object.values(inpuntBdValues)){
          
           if(valFind !== ''){
               queue.enqueue(valFind)          
          }
      }
     run()     
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [nameColumns,deleteColumns]);


  //   const handleAddField=()=>{   
  //     let arrayDeleted=[];
  //     if(nameColumns.length === nameColumnsOriginal.length){
  //       return ErrorAlert({text:'no puedes agregar mas columnas'});              
  //     }
  //     arrayDeleted = nameColumnsOriginal.filter((element: any) => !nameColumns.includes(element) )
  //     let unionArray=[...nameColumns, ...arrayDeleted]
  //     setNameColumns(unionArray.sort())
      
  // }


    useEffect(() => {
      const columns= Object.keys(inpuntBdValues) 
      if(columns.length >0 ){
       setNameColumns(columns.sort())
       //setNameColumnsOriginal(columns[0].sort())
      }
      else {
        setNameColumns([])
        // setNameColumnsOriginal([])
      }  
     
    }, [inpuntBdValues]);
    
   const handleAplicar=()=>{
        const copyObj=[...report]         
        if(valueFilter===0){
          const valInputs=Object.keys(inpuntValues).length
            if(valInputs>0){ 
              let arrayFilter:any=[];
                Object.entries(inpuntValues).map( ([k,values]:any,indexVal) => {
                  if(indexVal !== 0){                
                    const copyArray=[...arrayFilter]
                    arrayFilter=[];
                    copyArray.map((newObj:any)=>{
                    if(((typeof newObj[k] === "string") && (typeof values === "string")))
                      {
                        if(newObj[k].toUpperCase().includes(values.toUpperCase())) {
                          arrayFilter.push(newObj)                     
                        }                         
                      }else{
                        if(newObj[k] === parseInt(values))
                            {
                              arrayFilter.push(newObj)
                          }
                      }
                      setReportFilter(arrayFilter)
                    })
                  }
                  else{
                    copyObj.map((objRep:any,index:any) => {
                      if(((typeof objRep[k] === "string") && (typeof values === "string")))
                      {
                        if(objRep[k] !== null){
                          if(objRep[k].toUpperCase().includes(values.toUpperCase())) {
                                arrayFilter.push(objRep)                     
                            }                               
                        }
                      }
                      else{
                        if(objRep[k] === parseInt(values))
                            {
                              arrayFilter.push(objRep)
                          }    
                        } 
                        setReportFilter(arrayFilter)                        
                    }) 
                  }               
              })              
            }

            if((deleteColumns.length >0 ) && (deleteColumns.length < nameColumns.length )){
              const arrayRep:any=[];
              copyObj.map((objRep:any)=>{ 
                  deleteColumns.map((column:any)=>{
                      delete objRep[column]; 
                      arrayRep.push(objRep)     
                  })              
              })
              
              const columnsEliminate =  columnas.filter((item: any) => !deleteColumns.includes(item));
              const rowsEliminate = filas.filter((item: any) => !deleteColumns.includes(item));
              setColumnas(columnsEliminate)
              setFilas(rowsEliminate)
              setReportFilter(arrayRep)          
            }
        }
        if(addTxt)
        {
          
            //let array:any =[]
            //  let val:any 
            //  let valFind:any 
            //  reportFilter.map((re:any)=>{
            //  for (val of Object.values(re)) {
            //   for(valFind of Object.values(inpuntValues)){
            //      if(removeAccents(val?.toString()?.toUpperCase())?.startsWith(valFind.toUpperCase())){
            //       array.push(re)
            //     }
            //    }
            //   }
            // })
            // 
            //console.log("InputValalues", inpuntValues)
            const result = reportFilter.filter((o: any) => {
              return Object.keys(inpuntValues).every((k) => 
                removeAccents(o[k]?.replace(/ /g, "").toUpperCase())?.includes(removeAccents(inpuntValues[k]?.toString()?.replace(/ /g, "").toUpperCase()))
             )
           })
           setReportFilter(result)    
           setInpuntValues({})
           setAddTxt(false)          
        }
    }

    const handleSave=async ()=>{
    let arrayFilters:any
    if( Object.keys(inpuntValues).length === 0){
      arrayFilters=[]
    }else{
      arrayFilters=[JSON.stringify(inpuntValues)] 
    }
    
     const  edit_Fields= {
        filtros: arrayFilters,
        campos: deleteColumns
      }
      await editDateReportCamps({
        variables: {
          Id:id,
          input: edit_Fields,
        },
      });         
      SuccessfulAlert({ text: "Se edito correctamente el informe" });   
    }
    const namecoulumsID = nameColumns.map((valor, index) => ({value: valor, id:`${index+1}columnName` }))
  //console.log("con id",namecoulumsID)
  return (
    <Box className='containerFilterInforme'>
      <Grid className="buttonsReportSingle">   
      {selSelect === false?
      <img  className='select' 
            src={SelectIcon} alt="select" 
            onClick={handleSelect}
        />
        :<img  className='select' 
          src={selSelectIcon} alt="select" 
          onClick={handleSelect}
       />
      }
      {selView=== false ?
       <img  
        className='see' 
        src={SeeIcon} alt="see"
        onClick={handleSee} />
      :
      <img  
       className='see' 
       src={selSeeIcon} alt="see"
       onClick={handleSee} /> 
      }      
      </Grid>
      <Grid className="separatorReportSingle">   
          <div className="imgAplicar">
            {selSelect === true? 
                <img  
                className='aplicar' 
                src={aplicar} alt="aplicar"
                onClick={()=> handleAplicar()}
                />:<></> 
            }
            </div>
            <div className="containerFilter">
            {/* {selSelect === true?   
                <img  
                className='filter' 
                src={Filter} alt="filter"
                onClick={handleAddField} 
                />:
                <></> 
             } */}
           {selSelect === true || selView=== true?         
             <SaveIcon  className='save' fontSize="small" color="disabled"   onClick={()=> handleSave()} />:<></>
            }
            </div>       
      </Grid>
      <Grid className="filtersReport">
      {
       selSelect === false && selView=== true?   
        <TreeView columnas={columnas} filas={filas} camposBdArray={camposBdArray} camposPivotes={camposPivotes} />
       :selSelect === true && selView=== false?
       namecoulumsID.length >0 ?
       namecoulumsID.map((column:any)=>{
            return (
                <FilesTable key={column.id} 
                       type                   = {'text'}
                       name                   = {column.value}
                       label                  = {column.value}
                       width                  = {'250px'}
                       nameColumns            = {nameColumns}
                       setNameColumns         = {setNameColumns}                         
                       report={report}    
                       setReport={setReport}                       
                       reload={reload}
                       setReload={setReload}     
                       setAddTxt={setAddTxt}            
                       deleteColumns    = {deleteColumns}
                       setDeleteColumns = {setDeleteColumns}
                       inpuntValues     = {inpuntValues}
                       setInpuntValues  = {setInpuntValues}
                     
                />)      
          })            
         :<><p className="containerComponentReport">Este informe no tiene filtros</p></>        
         :<></>        
        }
      </Grid>    

    </Box>
    
  )
}

export default FilterContainer


