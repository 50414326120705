import { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DoneIcon from '@mui/icons-material/Done';
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import { WarningAlert } from "../../../alerts/WarningAlert";
import { useMutation } from "@apollo/client";
import { DELETE_CAMPAIGN_OBJECTIVE } from "../../../Querys/querys";

const ObjectiveRowOptions = (props: any) => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);  

 const [deleteObjective] = useMutation(DELETE_CAMPAIGN_OBJECTIVE);

  const open = Boolean(anchorEl);

  const openAddEvidenceModal = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.ADD_EVIDENCE_MODAL });
    setAnchorEl(null);
  };

  const openDuplicateModal = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.DUPLICATE_MODAL });
    setAnchorEl(null);
  };

  const openEditModal = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.EDIT_MODAL });
  }

  const deleteObjectiveAction = async (): Promise<void> => {
    setAnchorEl(null);
    let response = await WarningAlert({ text: "¿Deseas eliminar el objetivo?", showDenyButton: true, confirmButtonText: "Eliminar" });
    if (response.isConfirmed) {
      await deleteObjective({
        variables: {
          input: {
            id: parseInt(props.id, 10)
          }
        }
      });
    }
  }

  const evaluateObjective = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.EVALUATE_OBJECTIVE_MODAL });
    setAnchorEl(null);
  }

  const openQualityModal = () => {
    dispatch({ type: Types.SET_SELECTED_OBJECTIVE, payload: props });
    dispatch({ type: Types.SET_COUNT_NO_APROVE, payload: null });
    dispatch({ type: Types.QUALITY_OBJECTIVE_MODAL });
    setAnchorEl(null);
  };
  
  return (
    <div>
      <Tooltip title="Editar" placement="right">
        <Box className="IconButtonPoints" mr={2}>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon style={{ color: "#fabb00" }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        className="MoreVerIcon"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAnchorEl(null)}
      >
     { (state.campaign?.step=== 'evaluar') && (state?.rol === "collaborator" )?<div></div>:
      (
        <MenuItem
          divider
          button
          onClick={evaluateObjective}
        >
         Ver       
          <Grid container item justify="flex-end">
            <img src={`/assets/svg/info-icon.svg`} alt="Información" />
          </Grid>
        </MenuItem>)
      }
    
      {
          state?.campaign?.status !== 'finalizado' && (
            <div>
              {
              (state.campaign?.step !== 'definir' &&  props.weightObjectives===100) ? <div></div>:
              (props.addColObj <  props.maxObjInd &&  props.weightObjectives < 100)?
              (
               <MenuItem
                  divider
                  button
                  onClick={openDuplicateModal}
                >
                  Duplicar&nbsp;
                  <Grid container item justify="flex-end">
                    <img src={`/assets/svg/copy-icon.svg`} alt="Duplicar" />
                  </Grid>
                </MenuItem>
              )
              :<div></div>              
              }

              {
                (state.campaign?.step=== 'actuar' || state.campaign?.step=== 'evaluar')? <div></div>:
                 (props.objetiveSteps.toUpperCase() === 'Creada'.toUpperCase() )?
                 (                
                  <MenuItem
                    button
                    divider
                    onClick={openEditModal}
                  >
                    Editar&nbsp;
                    <Grid container item justify="flex-end">
                      <img src="/assets/svg/btn-editar.svg" alt="Editar" />
                    </Grid>
                  </MenuItem>
                  ) :<div></div>
               }
              {
                (state.campaign?.step === 'definir' || state.campaign?.step === 'evaluar') ?<></>:
                  <MenuItem
                    button
                    divider
                    onClick={openAddEvidenceModal}
                  >
                    Evidencias&nbsp;
                    <Grid container item justify="flex-end">
                      <img src="/assets/svg/upload-file.svg" alt="Evidencias" />
                    </Grid>
                  </MenuItem>                
              }              
             {
                (state.campaign?.step=== 'actuar' || state.campaign?.step=== 'evaluar') ?
                 <div></div>
                 :
                //  (props.iscreatedAdmin=== true || props.iscreatedLeader===true)? <div></div>:
                 (props.objetiveSteps.toUpperCase() === 'Creada'.toUpperCase() )?
                 (
                  <MenuItem
                    button
                    onClick={deleteObjectiveAction}
                  >
                    Eliminar&nbsp;
                    <Grid container item justify="flex-end">
                      <img src="/assets/svg/delete-icon.svg" alt="Eliminar" />
                    </Grid>
                  </MenuItem>
                )             :<div></div>
              }
              {
                (state.campaign?.step === 'evaluar' && state?.rol === 'leader'? 
                 <MenuItem
                    button
                    onClick={openQualityModal}
                  >
                    Calificar
                    <Grid container item justify="flex-end">
                    <DoneIcon fontSize="small" color="secondary" />
                    </Grid>
                  </MenuItem> :<></>  
                )           
               }  
            </div>
          )
        }
      </Menu>
    </div>
  );
};

export default ObjectiveRowOptions;
