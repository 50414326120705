import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  TextField,
  Grid,
  Grow,
  Paper,
  FormHelperText,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import CollaboratorContext from "../../../../context/CollaboratorContext/CollaboratorContext";
import bancos from "../../../../helpers/Json/bancos";
import InputMask from "react-input-mask";
import EyeIcon from "../../../../assets/svg/eye.svg";

const BankDataInactivos = () => {
  const { state } = useContext(CollaboratorContext);
  const [archives, setArchives] = useState([]);

  useEffect(() => {
    if (state.collaborator?.archive) {
      setArchives(state.collaborator.archive);
    }
  }, [state.collaborator]);

  return (
    <>
      <Grow in={true}>
        <Paper>
          <div className="novalidate__border">
            <Box mt={2} mb={2}>
              {/* { (reCheck == "true" || reCheck == null) && */}
              <FormControl variant="outlined" fullWidth={true} size="small">
                <InputLabel htmlFor="outlined-age-native-simple"
                style={{
                  opacity: '0.5',
                }}
                >
                  Banco
                </InputLabel>
                <Select
                  native
                  label={"Banco"}
                  name="id_Bank"
                  defaultValue={state.collaborator?.id_Bank || ""}
                  autoFocus={true}
                  disabled={true}
                >
                  <option
                    value={state.collaborator?.id_Bank || ""}
                    disabled={true}
                  >
                    {state.collaborator?.bank || ""}
                  </option>
                  {bancos.map(({ id,marca }: any, index: number) => (
                    <option key={index} value={id}>
                      {marca}
                    </option>
                  ))}
                </Select>
                <FormHelperText>
                  {!state.collaborator?.id_Bank && "Obligatorio"}
                </FormHelperText>
              </FormControl>
            </Box>

            <Box mt={2} mb={2}>
              {/* { (reCheck == "true" || reCheck == null) && */}

              <InputMask
                mask="99999999999"
                defaultValue={state.collaborator?.accountNumber}
              >
                <TextField
                  name="accountNumber"
                  label="Número de cuenta bancaria"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    opacity: '0.5',
                  }}
                />
              </InputMask>
            </Box>

            <Box mt={2} mb={2}>
              <InputMask
                mask="999999999999999999"
                defaultValue={state.collaborator?.clabeNum}
              >
                <TextField
                  name="clabeNum"
                  label="Número de clabe bancaria"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{
                    opacity: '0.5',
                  }}   
              />
              </InputMask>
            </Box>

            <Grid direction="row" container>
              <Grid xs item direction="row" container justify="flex-end">
                {/*DOCUMENTO OFICIAL DEL BANCO  */}

                {archives?.map(
                  (archive: any, index: number) =>
                    archive?.name === "CuentaBancaria_PDF" && (
                      <div className="flex-container">
                        <div className="flex-child">
                          <label>Subir documento Oficial del Banco</label>
                          <span className="span-file">
                            <a
                              className="view-document"
                              target="_blank"
                              rel="noreferrer" 
                              href={archive.URL}
                            >
                              <img
                                style={{ height: "10px", marginTop: "5px" }}
                                src={EyeIcon}
                                alt=""
                              />
                              Ver documento
                            </a>
                          </span>
                        </div>
                      </div>
                    )
                )}
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grow>
      <div></div>
    </>
  );
};

export default BankDataInactivos;
