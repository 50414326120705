/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Dialog } from '@material-ui/core';

import ObjectivesContext, { Types } from '../../../context/ObjectiveContext/ObjectiveContext';
import { GET_CAMPAIGN_USERS_BY_ADMIN_SELECT, GET_CAMPAIGN_USERS_BY_LEADER } from '../../../Querys/querys';

import styles from '../../Payroll/PayrollStyles.module.css';
import objectiveStyles from '../Objectives.module.css';

function SelectCollaborators(): JSX.Element {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [estadoInicial, setEstadoInicial] = useState<any[]>([]);
  const [seleccionadas, setSeleccionadas] = useState<any[]>([]);
  const [getCampaignUsersByAdmin] = useLazyQuery(GET_CAMPAIGN_USERS_BY_ADMIN_SELECT);
  const [getCampaignUsersByLeader] = useLazyQuery(GET_CAMPAIGN_USERS_BY_LEADER);
  const [dataByAdmin, setDataByAdmin] = useState<any[]>([]);
  const [dataByLeader, setDataByLeader] = useState<any[]>([]);

  const getUsers = async () => {
    if (state?.rol === 'admin') {
      const { data } = await getCampaignUsersByAdmin({
        variables: {
          idCampaing: state?.campaignId
        }
      })
      if (data?.GET_CAMPAIGN_USERS_BY_ADMIN_SELECT?.length > 0) {
        setEstadoInicial(data.GET_CAMPAIGN_USERS_BY_ADMIN_SELECT);
        setDataByAdmin(data.GET_CAMPAIGN_USERS_BY_ADMIN_SELECT);
      }
      return
    } else {
      
      const { data } = await getCampaignUsersByLeader({
        variables: {
          idCollaborator: state?.userFromLink?.id !==undefined ?state?.userFromLink?.id:0,
          idCampaing: state?.campaignId
        }
      })
      if (data?.GET_CAMPAIGN_USERS_BY_LEADER?.length > 0) {
        setEstadoInicial(data.GET_CAMPAIGN_USERS_BY_LEADER);
        setDataByLeader(data.GET_CAMPAIGN_USERS_BY_LEADER);
      }
      return
    }
  }

  useEffect(() => {
    getUsers()
  }, []);

  const eliminaSeleccionada = (id: any) => {
    const eliminaSeleccionada = seleccionadas.filter((lis: any) => lis?.id === id)
    eliminaSeleccionada.map((lis: any) => {
      setEstadoInicial([...estadoInicial, lis])
    })
    const eliminaDisponibles = seleccionadas.filter((lis: any) => lis?.id !== id)
    setSeleccionadas(eliminaDisponibles)
  }

  const agregaSeleccionadas = (id: any) => {
    const agregaSeleccionadas = estadoInicial.filter((lis: any) => lis?.id === id)
    agregaSeleccionadas.map((lis: any) => {
      setSeleccionadas([...seleccionadas, lis])
    })
    const eliminaDisponibles = estadoInicial.filter((lis: any) => lis?.id !== id)
    setEstadoInicial(eliminaDisponibles)
  }

  const eliminaTodas = () => {
    if (state?.rol === 'admin') {
      setEstadoInicial([...dataByAdmin])
    } else {
      setEstadoInicial([...dataByLeader])
    }
    setSeleccionadas([])
  }

  const agregaTodas = () => {
    setEstadoInicial([])
    if (state?.rol === 'admin') {
      setSeleccionadas([...dataByAdmin])
    } else {
      setSeleccionadas([...dataByLeader])
    }
  }

  const closeCollaboratorModal = () => {
    dispatch({ type: Types.CANCEL_SELECT_COLLABORATOR_MODAL })
  }

  const selectCollaboratorModal = () => {
    dispatch({ type: Types.SET_COLLABORATORS_IDS, payload: seleccionadas.map((item: any) => item.id) })
    dispatch({ type: Types.CANCEL_SELECT_COLLABORATOR_MODAL })
  }

  return (
    <Dialog open={state.selectCollaboratorModal} fullWidth maxWidth="md">
      <div className={styles.contenedorTitulosColumnas} style={{ maxWidth: '950px', width: '950px' }}>
        <div className={styles.contenedorTituloUno}>
          <span className={styles.titulosColumnas}>Todos los colaboradores disponibles 0</span>
        </div>
        <div className={styles.contenedorTituloDos} style={{marginLeft: '320px'}}>
          <span className={styles.titulosColumnas}>Colaboradores incluidos en el objetivo</span>
        </div>
      </div>
      <div className={styles.contenedorColumnas} style={{ maxWidth: '950px', width: '950px' }}>
        <div>
          <div className={styles.columnaTabla}>
            <ul className='ulDisponibles'>
              {
                estadoInicial?.map((item: any) => {
                  return (
                    <>
                      <li className="listaGruposDisponibles">
                        <div>
                          <span className="textoGrupoSeleccionadas">
                            {item.name}
                          </span>
                        </div>
                        <div
                          className="textoGrupoDisponibles"
                          onClick={() => agregaSeleccionadas(item.id)}>
                          <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-derecha.svg' />
                        </div>
                      </li>
                    </>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div id='payroll_3'>
          <div className={styles.botonIzquierda}>
            <img
              onClick={() => eliminaTodas()}
              className={styles.imagenCursor}
              src='/assets/icons/icono-flecha-izquierda.svg' />
          </div>
          <div className={styles.botonDerecha}>
            <img
              onClick={() => agregaTodas()}
              className={styles.imagenCursor}
              src='/assets/icons/icono-flecha-derecha.svg' />
          </div>
        </div>
        <div>
          <div className={styles.columnaTabla}>
            <ul className='ulDisponibles'>
              {
                seleccionadas?.map((item: any) => {
                  return (
                    <li className="listaGruposSeleccionadas">
                      <div
                        className="botonListaSeleccionadas"
                        onClick={() => eliminaSeleccionada(item.id)}>
                        <img className={styles.imagenCursor} src='/assets/icons/icono-flecha-izquierda.svg' />
                      </div>
                      <div>
                        <span className="textoGrupoSeleccionadas">
                          {item.name}
                        </span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>

        </div>
      </div>
      <div className={objectiveStyles.collaboratorsActionsContainer}>
        <button onClick={closeCollaboratorModal} className={objectiveStyles.collaboratorCloseButton}>Cerrar</button>
        <button onClick={selectCollaboratorModal} className={objectiveStyles.collaboratorSaveButton}>Aplicar</button>
      </div>
    </Dialog>
  )
}

export default SelectCollaborators