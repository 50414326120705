import Box from '@mui/material/Box';
import { Collaborator } from '../../interfaces/TabCollaborator.interfaces';
import { isAfter ,format } from 'date-fns'
import moment from 'moment';
import CollaboratorIconText from '../CollaboratorIcon/CollaboratorIconText';
import CollaboratorIconIMG from '../CollaboratorIcon/CollaboratorIconIMG';

const UserField = ({ bussinesName, email, img, FechaBaja, baja, ImagenUsuario, lastName }: Collaborator) => {

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={`${FechaBaja && 'border-red'}`}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                {ImagenUsuario === undefined || ImagenUsuario === '' || ImagenUsuario === null ? (
                    <CollaboratorIconText
                    Nombre={bussinesName}
                    Apellido={lastName}
                    width="33px"
                    height="33px"
                    />
                ) : (
                        
                    <CollaboratorIconIMG  
                    ImagenUsuario={ImagenUsuario}
                    width="33px"
                    height="33px"
                    widthImg="31px"
                    heightImg="31px"

                    />
                )}
            </Box>

            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                className="boxData__userField"
            >
                <label className="name__userField">{bussinesName}</label>
                <label className="email__userField">
                    {email}
                </label>
                {
                    bussinesName && FechaBaja &&
                    <label className="email__userField text-red">
                        <span className="dot-red"></span>
                        {
                          FechaBaja!== null?
                           isAfter(Date.parse(format(new Date(), 'MM-dd-yyyy')), Date.parse(format(new Date(FechaBaja), 'MM-dd-yyyy')))?
                                    'Baja confirmada'
                                :'Baja Programada'
                           :<></>
                        }                      
                        <label className="dateInactive__userField">&nbsp;{moment(FechaBaja).format('DD/MM/YYYY')}</label>
                    </label>
                }
            </Box>
        </Box>
    )
}

export default UserField
