import { useEffect, useContext, useState } from "react";
import EliminaIncidentPeriodic from "./Modals/EliminaIncidentPeriodic";
import ApproveIncidentPeriodic from "./Modals/ApproveIncidentPeriodic";
import { deleteIncidentPeriodicProcess, approveIncidentPeriodicProcess } from "../../context/PayrollProcess/Actions";
import PayrollProcessContext from "../../context/PayrollProcess/PayrollProcessContext";
import { useQuery } from "@apollo/client";
import { GET_ALL_PAYROLL_INCIDENTPERIODIC,
         GET_EXCEL_INCIDENT_COLLABORATOR,
         GET_EXCEL_INCIDENT_PERCEPTION,
         GET_EXCEL_INCIDENT_DEDUCTION,
         GET_EXCEL_INCIDENT_TYPECONCEPT,
         GET_EXCEL_INCIDENT_TYPEINCIDENTS,
         GET_EXCEL_INCIDENT_TIPOBASES,
         GET_EXCEL_INCIDENT_PERIODICO } from "../../Querys/querys";
import styles from "./PayrollStyles.module.css";
import { getDateComplete } from "../../helpers/Payroll/Payroll";
import { formatter } from "../../helpers/formatoMoneda";
import IconoSend from "../../assets/png/icono-send.png";
import IconoCheck from "../../assets/png/icono-check.png";
import IconoDoubleCheck from "../../assets/png/icono-doublecheck.png";
import CrearIncidenciasDashboard from "../../components/Schedule/Modals/CrearIncidenciasDashboard";
import {
  createincidenciaModal,
} from "../../context/PayrollProcess/Actions";
import { CreateExcelFileIncidentLayout } from "../../components/Payroll/Modals/ExcelPayroll";
import FileIncidentReport from "../Collaborator/Expedient/Fields/File_Incident_Report";
import { Alert } from "@material-ui/lab";

interface TabPanelProps{
  children?: React.ReactNode;
  index: any;
  value: any;
  sistemaActivo: boolean;
}

const IncidentPayrollTable = (props:TabPanelProps) => {

    const {sistemaActivo} = props;
    const {data: resultPayroll, startPolling, stopPolling} = useQuery(GET_ALL_PAYROLL_INCIDENTPERIODIC);
    const allPayroll = resultPayroll?.GET_ALL_PAYROLL_INCIDENTPERIODIC;
    const { dispatch } = useContext(PayrollProcessContext);
    const [saveIncident, setSaveIncident] = useState(true);
    const { dispatch: payrollDispatch } = useContext(
      PayrollProcessContext
    );
    const resultIncidentCollaboratorExcell = useQuery(GET_EXCEL_INCIDENT_COLLABORATOR);
    const allIncidentCollaboratorExcell = resultIncidentCollaboratorExcell.data?.GET_EXCEL_INCIDENT_COLLABORATOR;

    const resultIncidentPercepcionExcell = useQuery(GET_EXCEL_INCIDENT_PERCEPTION);
    const allIncidentPercepcionExcell = resultIncidentPercepcionExcell.data?.GET_EXCEL_INCIDENT_PERCEPTION;

    const resultIncidentDeduccionExcell = useQuery(GET_EXCEL_INCIDENT_DEDUCTION);
    const allIncidentDeduccionExcell = resultIncidentDeduccionExcell.data?.GET_EXCEL_INCIDENT_DEDUCTION;

    const resultIncidentTipoConceptoExcell = useQuery(GET_EXCEL_INCIDENT_TYPECONCEPT);
    const allIncidentTipoConceptoExcell = resultIncidentTipoConceptoExcell.data?.GET_EXCEL_INCIDENT_TYPECONCEPT;

    const resultIncidentTipoIncidenciaExcell = useQuery(GET_EXCEL_INCIDENT_TYPEINCIDENTS);
    const allIncidentTipoIncidenciaExcell = resultIncidentTipoIncidenciaExcell.data?.GET_EXCEL_INCIDENT_TYPEINCIDENTS;

    const resultIncidentTipoBaseExcell = useQuery(GET_EXCEL_INCIDENT_TIPOBASES);
    const allIncidentTipoBaseExcell = resultIncidentTipoBaseExcell.data?.GET_EXCEL_INCIDENT_TIPOBASES;

    const resultIncidentPeriodicoExcell = useQuery(GET_EXCEL_INCIDENT_PERIODICO);
    const allIncidentPeriodicoExcell = resultIncidentPeriodicoExcell.data?.GET_EXCEL_INCIDENT_PERIODICO;

    useEffect(() => {
      startPolling(1000);
      return () =>{
      stopPolling()
      }
    }, [allPayroll,startPolling, stopPolling]);     

    const filterPayroll = (payroll:any,type:string) => {
      return payroll.Tipo === type
    }

    const createModal = () => {
      createincidenciaModal({ _id: '0', createIncidencia: true }, payrollDispatch);
    };

    const handleOnExport = () => {
       CreateExcelFileIncidentLayout(allIncidentCollaboratorExcell,allIncidentPercepcionExcell,allIncidentDeduccionExcell,allIncidentTipoConceptoExcell,allIncidentTipoIncidenciaExcell,allIncidentTipoBaseExcell,allIncidentPeriodicoExcell);
    };
    
    return (
        <>
         <div className={styles.pp_contenedorCargarReporte}>
          <div className={styles.pp_contenedorBotonReporte}>
              <button className={styles.pc_botonDescargar}  onClick={() => handleOnExport()}>
                <div className={styles.pc_iconoDescargar}></div>
                <div>
                  Descargar layout
                </div>
              </button>
            </div>
          </div>
          <div className={styles.pp_contenedorReporte}>
          <div className={styles.pp_contenedorBotonReporte}>
            <FileIncidentReport/>
            </div>
          </div>
          <CrearIncidenciasDashboard saveIncident={saveIncident} setSaveIncident={setSaveIncident}/>
          <div className={styles.pp_contenedor}>
             <div className={styles.pp_contenedorBoton}>
              <button
                onClick={() => createModal()}
                className={styles.pp_botonCrear}
                type="button"
              >
                <span className={styles.pp_signo}>+</span>
                <span> Agregar incidencias</span>
              </button>
            </div>
          </div>
          <div className={styles.pp_contenedorLista_Incident}>
            <h2>Tiempo</h2>
            {allPayroll?.filter((payroll:any)=>filterPayroll(payroll, 'Tiempo')).map(
              (payroll: any, key: any) =>
                <PayrollRow key={key} sistemaActivo={sistemaActivo} payroll={payroll} dispatch={dispatch}></PayrollRow>
            )}
            {
              allPayroll?.filter((payroll:any)=>filterPayroll(payroll, 'Tiempo')).length === 0 && (
                <Alert severity="info">No hay incidencias de tipo Tiempo registradas.</Alert>
              )
            }
            <h2>Dinero</h2>
            {allPayroll?.filter((payroll:any)=>filterPayroll(payroll, 'Dinero')).map(
              (payroll: any, key: any) =>
                <PayrollRow key={key} sistemaActivo={sistemaActivo} payroll={payroll} dispatch={dispatch}></PayrollRow>
            )}
            {
              allPayroll?.filter((payroll:any)=>filterPayroll(payroll, 'Dinero')).length === 0 && (
                <Alert severity="info">No hay incidencias de tipo Dinero registradas.</Alert>
              )
            }
          </div>
          <EliminaIncidentPeriodic />
          <ApproveIncidentPeriodic />
        </>
      );
};

export default IncidentPayrollTable;

function PayrollRow({payroll, key, sistemaActivo, dispatch}:any){

  const eliminaPayroll = (id: any) => {
    deleteIncidentPeriodicProcess({ _id: id, showDeleteIncidentPeriodic: true }, dispatch);
  };

  const deletePayroll = (payroll: any) => {
    return <>
      <div
        className={styles.pp_contenedorIconoEliminar}
        onClick={() => eliminaPayroll(payroll.id)}
      >
        <img
          className={styles.pp_iconoEliminar}
          src="/assets/svg/icono_eliminar-nomina.svg"
          alt=""
        ></img>
        <span className={styles.pp_textoEliminar}>Eliminar</span>
      </div>
    </>
  }

  const approveIncident = (payroll:any) => {
    approveIncidentPeriodicProcess({ _id: payroll.id, showApproveIncidentPeriodic: true }, dispatch);
  }

  return (
    <div className={styles.pp_lista_Incident}>
      <div className={styles.pp_ColumnaUnoLista}>
        <div className={styles.pp_tituloGrupo}>
          <span>{payroll.Colaborador}</span>
        </div>
          {
            payroll.Tipo === 'Tiempo' && (
              <>
                <div className={styles.pp_procesoCalcular}>
                  <span>{(!payroll.ApprovedDirect) ? 'Enviada' : (!payroll.ApprovedDirect) ? 'Aprobada' : 'Autorizada'}</span>
                </div>
                <div
                    id={key}
                    className={styles.pp_contenedorBarras}
                  >
                  <div className={styles.pc_circuloAzul}>
                    <img className="pc_circleIcon" src={IconoSend} alt=""></img>
                  </div>
                  <div
                    className={`${styles.pc_lineaSeguimientoIncident} ${payroll.ApprovedDirect ? styles.pc_lineaSeguimientoIncidentAzul : ''}`}
                  ></div>
                  <div
                    className={payroll.ApprovedDirect ? styles.pc_circuloVerde : styles.pc_circuloGris24}
                  >
                    {
                      payroll.ApprovedDirect && (
                        <img className="pc_circleIcon" src={IconoCheck} alt=""></img>
                      )
                    }
                  </div>
                  <div
                    className={`${styles.pc_lineaSeguimientoIncident} ${ payroll.ApprovedRH ? styles.pc_lineaSeguimientoIncidentVerde : ''}`}
                  ></div>
                  <div
                    className={payroll.ApprovedRH ? styles.pc_circuloVerdeLimon : styles.pc_circuloGris24}
                  >
                    {
                      payroll.ApprovedRH && (
                        <img className="pc_circleIcon" src={IconoDoubleCheck} width={16} alt=""></img>
                      )
                    }
                  </div>
                </div>
              </>
            )
          }
      </div>
      <div className={styles.pp_ColumnaDosListaIncident_Incident}>
        <div
          className={`${styles.pp_columnaInternaIncident} ${styles.pp_alignColumn}`}
        >
          <div>
            <div>
              <span className={styles.pp_textoInternoIncident}>
                Fecha Inicial
              </span>
            </div>
            <div>
              <span className={styles.pp_valorInternoIncident}>
                {getDateComplete(payroll.FechaGenerado)}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles.pp_columnaInternaIncident} ${styles.pp_alignColumn}`}
        >
          <div>
            <div>
              <span className={styles.pp_textoInternoIncident}>Fecha Fin</span>
            </div>
            <div>
              <span className={styles.pp_valorInternoIncident}>
              {getDateComplete(payroll.FechaFin)}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles.pp_columnaInternaIncident} ${styles.pp_alignColumn}`}
        >
          <div>
            <div>
              <span className={styles.pp_textoInternoIncident}>Dias</span>
            </div>
            <div>
              <span className={styles.pp_valorInternoIncident}>
                {payroll.Dias}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles.pp_columnaInternaIncident} ${styles.pp_alignColumn}`}
        >
          <div>
            <div>
              <span className={styles.pp_textoInternoIncident}>Total</span>
            </div>
            <div>
              <span className={styles.pp_valorInternoIncident}>
                {formatter(payroll.Total)}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles.pp_columnaInternaIncident} ${styles.pp_alignColumn}`}
        >
          <div>
            <div>
              <span className={styles.pp_textoInternoIncident}>Tipo Cobro</span>
            </div>
            <div
            style={{
              width: "100%",
              height: "100%",
            }}
            title={payroll.TipoCobro}
            >
              <span className={styles.pp_valorInternoIncident}>
                {payroll.TipoCobro}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles.pp_columnaInternaIncident} ${styles.pp_alignColumn}`}
        >
          <div>
            <div>
              <span className={styles.pp_textoInternoIncident}>Tipo Concepto</span>
            </div>
            <div
            style={{
              width: "100%",
              height: "100%",
            }}
            title={payroll.TipoConcepto}
            >
              <span className={styles.pp_valorInternoIncident}>
                {payroll.TipoConcepto}
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${styles.pp_columnaInternaIncident} ${styles.pp_alignColumn}`}
        >
          <div>
            <div>
              <span className={styles.pp_textoInternoIncident}>Comentarios</span>
            </div>
            <div
            style={{
              width: "100%",
              height: "100%",
            }}
            title={payroll.Comentarios}
            >
              <span className={styles.pp_valorInternoIncident} >
                {payroll.Comentarios}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.pp_ColumnaOpciones}>
        {
          payroll.Tipo === 'Tiempo' && payroll.ApprovedDirect && !payroll.ApprovedRH && (
            <button
              id="payroll_5"
              className={styles.pp_botonComenzar}
              onClick={() => { approveIncident(payroll) }}
            >
              Aprobar
            </button>
          )
        }
      {
        sistemaActivo && payroll.idAdelanto <= 0 ? (deletePayroll(payroll)) : null
      }
      </div>
    </div>
  )
}