import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";
import { useCallback, useState } from "react";
import AddTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import Histogram from "./Graphs/Histogram";
import LollipopPlot from "./Graphs/LollipopPlot";
import { PieChart } from "./Graphs/PieChart";
import { DonutChart } from "./Graphs/DonutChart";
import { CircleBarplot } from "./Graphs/CircleBarPlot";
import DensityPlot from "./Graphs/DensityPlot";
import { Ridgeline } from "./Graphs/Ridgeline";
import BoxPlot from "./Graphs/BoxPlot";
import ScatterPlot from "./Graphs/ScatterPlot";
import HexbinPlot from "./Graphs/HexbinPlot";

const graphs = [

  {id:'histogram', value:'histogram', label: 'Histograma'},
  {id:'lollipop', value:'lollipop', label: 'Piruleta'},
  {id:'pie', value:'pie', label: 'Pastel'},
  {id:'donut', value:'donut', label: 'Dona'},
  {id:'circularBar', value:'circularBar', label: 'Circular'},
  {id:'density', value:'density', label: 'Densidad'},
  {id:'rid', value:'rid', label: 'Ridgeline'},
  {id:'box', value:'box', label: 'Caja'},
  {id:'sccater', value:'sccater', label: 'Dispersión'},
  {id:'density2d', value:'density2d', label: 'Hexbin'}

]

//DumieData
const dataPruebaPie = [
  {name:"Mark", value: 90},
  {name:"Robert", value: 12},
  {name:"Emily", value: 34},
  {name:"Marion", value: 53},
  {name:"Nicolas", value: 58},
]

const dataBar = [
  {name:"Staff", value: 90},
  {name:"TresMontes", value: 12},
  {name:"Chinoin", value: 34},
  {name:"Directivos", value: 53},
  {name:"StaffOperacional", value: 58},
]
const dataRid = [
  {
    key: "Almost Certainly",
    values: [
      95, 95, 95, 95, 98, 95, 85, 97, 95, 90, 90, 99, 60, 88.7, 99, 95, 97, 99,
      95, 95, 90, 92, 98, 98, 90, 95, 95, 98, 85, 80, 98, 96, 99, 85, 90, 95,
      98, 98, 80, 95, 99, 85, 90, 95, 85, 95,
    ],
  },
  {
    key: "Highly Likely",
    values: [
      80, 75, 85, 85, 95, 99, 95, 95, 95, 85, 90, 97, 80, 69, 98, 90, 90, 95,
      95, 90, 80, 85, 90, 92, 90, 85, 90, 95, 85, 15, 80, 85, 85, 84, 95, 85,
      96, 96, 90, 90, 90, 80, 70, 80, 90, 80,
    ],
  }
]

export const datw = [
  { name: "A", value: 10.7577 },
  { name: "A", value: 19.9273 },
  { name: "A", value: 13.8917 },
  { name: "A", value: 0.5102 },
  { name: "A", value: 10.5524 },
  { name: "A", value: 11.0879 },
  { name: "A", value: 12.1972 },
  { name: "A", value: 6.0339 },
  { name: "A", value: 8.0231 },
  { name: "A", value: 11.9294 },
  { name: "A", value: 14.1865 },
  { name: "A", value: 14.2671 },
  { name: "A", value: 17.6488 },
  { name: "A", value: 4.2926 },
  { name: "A", value: 10.6018 },
  { name: "A", value: 14.5647 },
  { name: "A", value: 4.3337 },
  { name: "A", value: 10.2936 },
  { name: "A", value: 19.6195 },
  { name: "A", value: 9.7996 },
  { name: "A", value: 9.9985 },
  { name: "A", value: 6.9065 },
  { name: "A", value: 10.3672 },
  { name: "A", value: 11.1755 },
  { name: "A", value: 17.5454 },
  { name: "A", value: 14.1935 },
  { name: "A", value: 0.8668 },
  { name: "A", value: 8.1776 },
  { name: "A", value: 9.7151 },
  { name: "A", value: 8.3053 },
  { name: "A", value: 13.5903 },
  { name: "A", value: 1.2625 },
  { name: "A", value: 7.4425 },
  { name: "A", value: 11.5665 },
  { name: "A", value: 5.7762 },
  { name: "A", value: 16.3077 },
  { name: "A", value: 9.8548 },
  { name: "A", value: -3.0575 },
  { name: "A", value: 9.4651 },
  { name: "A", value: -0.2331 },
  { name: "A", value: 6.7485 },
  { name: "A", value: 11.4731 },
  { name: "A", value: 5.8715 },
  { name: "A", value: 6.056 },
  { name: "A", value: 7.6169 },
  { name: "A", value: 2.5121 },
  { name: "A", value: 16.6059 },
  { name: "A", value: 12.8562 },
  { name: "A", value: 3.9064 },
  { name: "A", value: 11.2966 },
  { name: "A", value: 20.3486 },
  { name: "A", value: 10.8421 },
  { name: "A", value: 3.7848 },
  { name: "A", value: 5.5405 },
  { name: "A", value: 7.0684 },
  { name: "A", value: 8.9981 },
  { name: "A", value: 3.9075 },
  { name: "A", value: 11.0618 },
  { name: "A", value: 17.6071 },
  { name: "A", value: 6.7749 },
  { name: "A", value: 4.1787 },
  { name: "A", value: 9.4642 },
  { name: "A", value: 16.6865 },
  { name: "A", value: 13.4322 },
  { name: "A", value: 19.336 },
  { name: "A", value: 11.3786 },
  { name: "A", value: 9.5744 },
  { name: "A", value: 6.3846 },
  { name: "A", value: 15.3828 },
  { name: "A", value: 12.0497 },
  { name: "A", value: 4.2574 },
  { name: "A", value: 0.5707 },
  { name: "A", value: 13.8761 },
  { name: "A", value: 13.2071 },
  { name: "A", value: 5.5027 },
  { name: "A", value: 7.4563 },
  { name: "A", value: 15.9532 },
  { name: "A", value: 14.578 },
  { name: "A", value: 3.6959 },
  { name: "A", value: 16.5209 },
  { name: "A", value: 5.5568 },
  { name: "A", value: 12.0214 },
  { name: "A", value: 10.4248 },
  { name: "A", value: 13.1838 },
  { name: "A", value: 10.1525 },
  { name: "A", value: 16.7015 },
  { name: "A", value: 15.6428 },
  { name: "A", value: 7.4315 },
  { name: "A", value: 2.7061 },
  { name: "A", value: 13.0906 },
  { name: "A", value: 1.1177 },
  { name: "A", value: 8.7708 },
  { name: "A", value: 6.5524 },
  { name: "A", value: 23.7266 },
  { name: "A", value: 8.5406 },
  { name: "A", value: 12.0263 },
  { name: "A", value: 10.9345 },
  { name: "A", value: 12.4004 },
  { name: "A", value: 16.6804 },
  { name: "A", value: 14.0291 },
  { name: "A", value: 10.5627 },
  { name: "A", value: 13.1986 },
  { name: "A", value: 7.3306 },
  { name: "A", value: 14.6531 },
  { name: "A", value: 8.9369 },
  { name: "A", value: 9.2501 },
]
const data2 = [
  75.0,
  104.0,
  369.0,
  300.0,
  92.0,
  64.0,
  265.0,
  35.0,
  287.0,
  69.0,
  52.0,
  23.0,
  287.0,
  87.0,
  114.0,
  114.0,
  98.0,
  137.0,
  87.0,
  90.0,
  63.0,
  69.0,
  80.0,
  113.0,
  58.0,
  115.0,
  30.0,
  35.0,
  92.0,
  460.0,
  74.0,
  72.0,
  63.0,
  115.0,
  60.0,
  75.0,
  31.0,
  277.0,
  52.0,
  218.0,
  132.0,
  316.0,
  127.0,
  87.0,
  449.0,
  46.0,
  345.0,
  48.0,
  184.0,
  149.0,
  345.0,
  92.0,
  749.0,
  93.0,
  9502.0,
  138.0,
  48.0,
  87.0,
  103.0,
  32.0,
  93.0,
  57.0,
  109.0,
  127.0,
  149.0,
  78.0,
  162.0,
  173.0,
  87.0,
  184.0,
  288.0,
  576.0,
  460.0,
  150.0,
  127.0,
  92.0,
  84.0,
  115.0,
  218.0,
  404.0,
  52.0,
  85.0,
  66.0,
  52.0,
  201.0,
  287.0,
  69.0,
  114.0,
  379.0,
  115.0,
  161.0,
  91.0,
  231.0,
  230.0,
  822.0,
  115.0,
  80.0,
  58.0,
  207.0,
  171.0,
  156.0,
  91.0,
  138.0,
  104.0,
  691.0,
  74.0,
  87.0,
  63.0,
  333.0,
  125.0,
  196.0,
  57.0,
  92.0,
  127.0,
  136.0,
  129.0,
  66.0,
  80.0,
  115.0,
  87.0,
  57.0,
  172.0,
  184.0,
  230.0,
  153.0,
  162.0,
  104.0,
  165.0,
  1036.0,
  69.0]

const dataSccater = [
    {
      x: 2,
      y: 4,
    },
    {
      x: 3,
      y: 5,
    },
    {
      x: 1,
      y: 2,
    },
    {
      x: 5,
      y: 5,
    },
    {
      x: 7,
      y: 8,
    },
    {
      x: 9,
      y: 9,
    },
    {
      x: 6,
      y: 8,
    },
    {
      x: 5,
      y: 4,
    },
    {
      x: 7,
      y: 5,
    },
    {
      x: 8,
      y: 9,
    },
    {
      x: 6,
      y: 9,
    },
    {
      x: 3,
      y: 6,
    },
    {
      x: 2,
      y: 9,
    },
    {
      x: 2,
      y: 1,
    },
    {
      x: 3,
      y: 8,
    },
    {
      x: 1,
      y: 5,
    },
    {
      x: 5,
      y: 1,
    },
    {
      x: 7,
      y: 3,
    },
    {
      x: 9,
      y: 1,
    },
    {
      x: 6,
      y: 2,
    },
    {
      x: 5,
      y: 9,
    },
    {
      x: 7,
      y: 8,
    },
    {
      x: 8,
      y: 4,
    },
    {
      x: 6,
      y: 3,
    },
    {
      x: 3,
      y: 2,
    },
    {
      x: 5,
      y: 1,
    },
  ];
const ReportDashboard = ({reports}:any) => {
   const [graph, setGraph] = useState('')
   const [draw,setDraw] = useState(false) 
   const [render,setRender] = useState('')
   
   const handleSelect = useCallback((event: any) => {
   setRender(event.target.value as string);
   }, []);

  // Callback function to update draw state
  const updateDrawState = () => {
    setDraw(true);
    setGraph(render)
  };
  
  const AddGraph = ({grafico}:any) => {
    switch(grafico){
      case 'histogram':
        return(
          <Histogram data={dataBar} width={700} height={600} />
        )
      
      case 'lollipop':
        return(
          <LollipopPlot data={dataBar} width={700} height={600} />
        )
      case 'pie':
        return(
          <PieChart data={dataPruebaPie} width={700} height={500} />
        )
      
      case 'donut':
        return(
          <DonutChart data={dataPruebaPie} width={700} height={500} />
        )
      
      case 'circularBar':
        return(
          <CircleBarplot data={dataBar} width={700} height={600} />
        )
      
      case 'density':
        return(
          <DensityPlot data={data2} width={700} height={600} />
        )
      
      case 'rid':
        return(
          <Ridgeline data={dataRid} width={700} height={500} />
        )
      
      // case 'box':
      //   return(
      //     <BoxPlot data={datw} width={700} height={500} />
      //   )
      
      case 'sccater':
        return(
          <ScatterPlot data={dataSccater} width={700} height={500} />
        )
      
      case 'density2d':
        return(
          <HexbinPlot data={dataSccater} width={700} height={500} />
        ) 

      default:
        return null
       
    }
  }

   return(
     <>
        <Box>
            <Grid
            container
            justify="flex-start"
            alignItems="center"
            >

                <Grid className="btnsInformes">
              <Grid className="btnsInformesFiltros">
               <div style={{marginRight: 20}}>
                <FormControl size="small" sx={{ minWidth: 120 }} variant="outlined">
                    <InputLabel id="demo-simple-select-label">Gráfico</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={render}
                        defaultValue="Seleciona"
                        label="Gráfico"
                        onChange={handleSelect}
                    >
                    {graphs.map((item) => (
                        <MenuItem key={item.id} value={item.value}>{item.label}</MenuItem>
                    ))}
                    </Select>
                </FormControl>
               </div>

               <AddTable
                    func={updateDrawState}
                    text="Generar Gráfico"
                    type="button"
                    noIcon={true}
                    disabled={!render}
                  />

              </Grid>
        </Grid>


            </Grid>
        </Box>
       {draw &&  <AddGraph grafico={graph} />}
     </>
   )
}

export default ReportDashboard
