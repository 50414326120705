import { gql } from "@apollo/client";

export const GET_ALL_DATAREPORTS = gql`
query GET_ALL_DATAREPORTS {
    GET_ALL_DATAREPORTS {
      id      
      ReportType
      ReportName
      ReportDescription
      ReportDate
      ReportSQL
      userAdd
      campos
      filtros
    }
  }
`;

export const GET_DATAREPORTS = gql`
query Query($getDatareportsId: ID!) {
  GET_DATAREPORTS(id: $getDatareportsId)
}
`;

export const DELETE_DATAREPORT = gql`
mutation DELETE_DATAREPORT($Id: ID!) {
  DELETE_DATAREPORT(id: $Id)
}
`;

export const DELETE_CUSTOM_REPORT = gql`
mutation DELETE_CUSTOM_REPORT($deleteCustomReportId: ID!) {
  DELETE_CUSTOM_REPORT(id: $deleteCustomReportId)
}
`;

export const EDIT_DATAREPORT = gql`
mutation EDIT_DATAREPORT($Id: ID!, $input: edit_dataReport) {
  EDIT_DATAREPORT(id: $Id, input: $input){
    id  
  }
}
`;

export const EDIT_DATAREPORT_CAMPS = gql`
mutation EDIT_DATAREPORT_CAMPS($Id: ID!, $input: edit_Fields) {
  EDIT_DATAREPORT_CAMPS(id: $Id, input: $input)
}
`;

export const GET_CUSTOMDATAREPORT = gql`
query GET_CUSTOMDATAREPORT($getDatareportsId: ID!) {
  GET_CUSTOMDATAREPORT(id: $getDatareportsId)
}
`;

export const GET_CUSTOM_REPORT_PREVIEW = gql`
query GET_CUSTOM_REPORT_PREVIEW($input: create_custom_report_input) {
  GET_CUSTOM_REPORT_PREVIEW(input: $input)
}
`;
