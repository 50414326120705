import {useState, ChangeEvent, useEffect} from 'react';
import Box from '@mui/material/Box';
import {Grid} from '@material-ui/core';
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import FacturacionPagosTab from '../components/FacturacionPagos/FacturacionPagosTab';
import ReporteMovimientosSTPTable from '../components/FacturacionPagos/ReporteMovimientosSTPTable';
import CheckPermission from '../helpers/Administradores/Permissions';

import InitPayments from "../screens/InitPayments";

const FacturacionPagosScreen = () =>{
  const [tab, setTab] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    localStorage.setItem("currentPill", String(newValue));
  };

  useEffect(() => {
    let pill = localStorage.getItem("currentPill");
    if (pill) setTab(Number(pill));
    else setTab(0);
  }, [tab]);

  return(
    <div>
      <Box mt={3} ml={5} className="Title">
        Facturacion y Pagos
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            value={tab}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            {<CustomTab label="Facturacion y Pagos" value={0}/>}
            {<CustomTab label="Reporte de Movimientos STP" value={1}/>}
          </CustomTabs>
        </Grid>
      </Box>
      
      <div>
        {(tab === 0) && <InitPayments value={tab} index={0}/>}
        {(tab === 1) && <ReporteMovimientosSTPTable value={tab} index={1}/>}
      </div>
    </div>

  )

}

export default FacturacionPagosScreen;