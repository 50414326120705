import { useContext, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, 
        Grid, 
       TextField } from '@material-ui/core';
import { useMutation,useLazyQuery } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import AddTable from '../../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '../../Button/Button';
import { GET_OBJECTIVE_EVIDENCE,
        UPDATE_OBJECTIVE_EVIDENCE,
        DELETE_OBJECTIVE_EVIDENCE,
        NOTIFY_COLLABORATOR_EVIDENCES
      } from '../../../Querys/querys';
import Minus from "../../../assets/svg/minus.svg";
import Charge from "../../../assets/icons/upload-file-blue.svg";
import { uploadEvidenceFile } from '../../../services/objectiveService';
import { ErrorAlert } from "../../../alerts/errorAlert";
import {WarningAlert} from "../../../alerts/WarningAlert";
import styles from '../Objectives.module.css';
import { formatter } from "../../../helpers/formatoMoneda";
import { SuccessfulAlert } from '../../../alerts/successAlerts';
import TableEvidences from './TableEvidences';



const sanitize = require("sanitize-filename");

const AddEvidenceModal = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [idImg, setIdImg] = useState(0)
  const [viewLoad, setViewLoad] = useState(false)
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [arrayEvidence, setArrayEvidence] =  useState<any[]>([])  
  const [sumPercentajeEvindence , setSumPercentajeEvindence] = useState(0)  ;
  const [notifyCollaborator] = useMutation(NOTIFY_COLLABORATOR_EVIDENCES);
 
  const [editObjective] = useMutation(UPDATE_OBJECTIVE_EVIDENCE,{		
  fetchPolicy: 'no-cache',
  onCompleted({ UPDATE_OBJECTIVE_EVIDENCE }) { },}); 
 

  const [objectivesEvidence] = useLazyQuery(GET_OBJECTIVE_EVIDENCE, {
		fetchPolicy: 'no-cache',
		onCompleted({ GET_OBJECTIVE_EVIDENCE }) {           
      if(GET_OBJECTIVE_EVIDENCE.length>0){
        setArrayEvidence(GET_OBJECTIVE_EVIDENCE)      
        let sumPercentaje =arrayEvidence.reduce((acum :any , result: any) => acum + result.result, 0);       
        setSumPercentajeEvindence(sumPercentaje)
        setViewLoad(false)
      }	else{
        setArrayEvidence([])      
        setSumPercentajeEvindence(0)
        setViewLoad(false)
      }		
		},
	});
  
  const [deleteObjective] = useMutation(DELETE_OBJECTIVE_EVIDENCE,{
    fetchPolicy: 'no-cache',
        onCompleted({ DELETE_OBJECTIVE_EVIDENCE }) {
      console.log(DELETE_OBJECTIVE_EVIDENCE)
      if(DELETE_OBJECTIVE_EVIDENCE ==="Campaign Objective Deleted"){
        objectivesEvidence({ 
          variables: {
            input: {
              id: parseInt(state?.selectedObjective?.id as any, 10),
            }
          }})
      }      
    }
  }); 
   useEffect(() => {  
    if(state?.selectedObjective?.id !== null){
     objectivesEvidence({ 
        variables: {
          input: {
            id: parseInt(state?.selectedObjective?.id as any, 10),
          }
        }})
    }  
   }, [objectivesEvidence, state?.selectedObjective]);

  const [form, setForm] = useState<any>({
    name: "",
    nameIndicator: "",
    metricObjective: "",
    metricIndicator: "",
    weight: "",
    description: "",
    progress: 0,
  });
  const [result, setResult] = useState(0);
  const [evaluation, setEvaluation] = useState<string>("");

  useEffect(() => {
    if (state.selectedObjective) {      
      setForm({
        name: state.selectedObjective.ObjectiveName,
        nameIndicator: state.selectedObjective.nameIndicator,
        metricObjective: state.selectedObjective.metricObjective,
        metricIndicator: state.selectedObjective.metricIndicator,
        weight: state.selectedObjective.weight?.toString() + "",
        description: state.selectedObjective.descriptionIndicator || "",
        // progress: state.selectedObjective.progress || 0,
        progress:  0,
      });
      setEvaluation(state.selectedObjective.evaluation || "");
      setResult(state.selectedObjective.result || 0);
    }
  }, [state.selectedObjective]);

  const eliminarEvidencia = async (id:any)=>{
    setDisabledBtn(false)
    setViewLoad(true) 
    await deleteObjective({
      variables: {
        input: {
          id: parseInt(id, 10)
        }
      }
    });       
  }

  const cancelButton =async () => {    
    const copyEvidence= [...arrayEvidence]
    if(copyEvidence.length >0){
    const lastElement = copyEvidence.slice(-1).pop()  
    console.log(lastElement)
    if(lastElement.progress !== null){
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CANCEL_EVALUATE_OBJECTIVE_MODAL });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
    setArrayEvidence([])
    setIdImg(0)
    setViewLoad(false) 
    }else{
    await eliminarEvidencia(lastElement.id)
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CANCEL_EVALUATE_OBJECTIVE_MODAL });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
    setArrayEvidence([])
    setIdImg(0)
    setViewLoad(false) 
     }
   }else{
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
    dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
    dispatch({ type: Types.CANCEL_EVALUATE_OBJECTIVE_MODAL });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
    setArrayEvidence([])
    setIdImg(0)
   }
   
  }

  const updateProgressForm = (e: any) => {
    
    let value = parseInt(e.target.value.toString().replace("$", "").replaceAll(",", ""), 10) || 
                parseInt(e.target.value.toString().replace("%", "").replaceAll(",", ""), 10)  
    if (isNaN(value) || value < 0) value = 0;    
    let objectiveString = state.selectedObjective?.metricObjective.toString().replace("$", "").replaceAll(",", "") ||
                          state.selectedObjective?.metricObjective.toString().replace("%", "").replaceAll(",", "")
    let objective=parseFloat(objectiveString??'')  
    let progressBD= state.selectedObjective?.progress.toString()??''
    if(progressBD ==='0'){
      if (value > (objective as any)) value = objective as number;
       const resultValue = value / (objective as number);    
        setForm({ ...form, progress: value });
        const res=resultValue * 100       
        console.log(res)
        setResult(Number(res.toFixed(2)));
    }
    else{
      let newprogressBD=progressBD.split("/")[0]
      let res=(objective - parseFloat(newprogressBD) )
      if (value > (res as any)) value = res as number;
      setForm({ ...form, progress: value });  
      const resultValue = value / (res as number);  
      let porcentaje=(100- Number(progressBD.split("/")[1]))       
      const resNew=(resultValue * Number(porcentaje) )    
      let resOk =isNaN(resNew)?100:resNew      
      setResult(Number(resOk.toFixed(2)));
  }

  }

  const updateProgress = () => {   
    const copyEvidence= [...arrayEvidence]
    const lastId = copyEvidence.slice(-1).pop()       
    let error =false;
    if(sumPercentajeEvindence===100)
    {  
       if(lastId?.id !== idImg)
       {
        error = true;
        return ErrorAlert({ text: 'Es necesario agregar la evidencia para guardar' });  
       }
       error = false;
       WarningAlert({ text: 'Seguira cargando Evidencia aun cuando ha llegado al 100%  del objetivo' });

       console.log(error)
       if(!error){
       editObjective({
        variables: {
          input: {             
            objectiveId:idImg,              
            progress: 0,
            result: 0,
          }
        }
      });
        dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
        dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
         setArrayEvidence([])
          setIdImg(0)
          setViewLoad(false) 
          setDisabledBtn(false)
        setForm({
          name: "",
          nameIndicator: "",
          metricObjective: "",
          metricIndicator: "",
          weight: "",
          description: "",
        }); 
      }
      return
    }


    if(form.progress === 0 && result === 0 && lastId===undefined){
         error = true;
         return ErrorAlert({ text: 'No es posible guardar una evidencia sin datos' });  
      }
     if(form.progress === 0 && result === 0 ){
        error = true;
        return ErrorAlert({ text: 'Es necesario capturar el progreso'});  
     } 
     if(lastId===undefined ){
      error = true;
      return ErrorAlert({ text: 'Es necesario cargar la evidencia'});  
     }   
     if(lastId?.id !== idImg)
     {
      error = true;
      return ErrorAlert({ text: 'Es necesario agregar la evidencia para guardar' });  
     }      
   if(!error){
     editObjective({
            variables: {
              input: {             
                objectiveId:idImg,              
                progress: parseInt(form.progress, 10),
                result: result,
              }
            }
          });
      dispatch({ type: Types.CANCEL_ADD_EVIDENCE_MODAL });
      dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });
      setIdImg(0)
      setDisabledBtn(false)
      setForm({
        name: "",
        nameIndicator: "",
        metricObjective: "",
        metricIndicator: "",
        weight: "",
        description: "",
       });
     }
  }


  const inputFileRef: any = useRef(null);

  const onChangeImage = async (e: any) => {   
     setViewLoad(true)   
    const idImgRes:any= await uploadEvidenceFile(
      e.target.files[0],
      state.selectedObjective?.id as any,
      state.selectedObjective?.idCollaborator?.toString() as any,
      sanitize(e.target.files[0].name),
      ); 
     setIdImg(parseInt(idImgRes?.data?.id,10))
     objectivesEvidence({ 
      variables: {
        input: {
          id: parseInt(state?.selectedObjective?.id as any, 10),
        }
      }})
  }

   const uploadEvidence = () => {
    setDisabledBtn(true)
    const copyEvidence= [...arrayEvidence]
    if(copyEvidence.length >0){
    const lastElement = copyEvidence.slice(-1).pop()      
    if(lastElement.progress !== null){
      inputFileRef.current.click();     
    }
    else{
      return ErrorAlert({ text: 'no puede cargar otra evidencia sin asignarle un progreso a la actual' });  
    } 
    }   
    else{
      inputFileRef.current.click();   
    }

   }

  const sendReminder = async () => {
    console.log("sendReminder");
    await notifyCollaborator({
      variables: {
        input: {
          campaignId: parseInt(state.campaign?.id as string, 10),
          collaboratorId: state.collaboratorId
        }
      }
    })
    SuccessfulAlert({ text: "Líder notificado exitosamente" })
  }



  return (
    
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.addEvidenceModal || state.evaluateObjectiveModal}
      fullWidth={true}    
    >
    
      <h2 id="form-dialog-title" className="text-center">
        {form.name} 
      </h2>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.nameIndicator}
              label="Nombre del indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricIndicator}
              label="Métrica objetivo"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricObjective}
              label="Indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.weight}
              label="Peso"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      {/* {
        state.evaluateObjectiveModal && (
          <DialogContent>
            <Grid direction="row" container spacing={2}>
              <Grid xs item>
                <TextField
                  name="progress"                 
                  defaultValue={ form.progress }
                  label="Progreso"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />

              </Grid>
              <Grid xs item>
                <TextField
                  name="result"
                  // defaultValue={parseInt(form.progress, 10) / parseInt(form.metricObjective, 10) * 100 + " %"}
                  defaultValue={parseInt(form.progress, 10)}
                  label="Resultado"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                />
              </Grid>
            </Grid>
          </DialogContent>
        )
      } */}

      <DialogContent>       
        <Grid xs item>
          <TextField
            name="name"
            value={form.description}
            label="Descripción del objetivo"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
      </DialogContent>  
      {
        (state?.campaign?.step!=='definicion' && arrayEvidence.length > 0 )&&
        <DialogContent className={styles.tableEvidences}>
            <h4 id="form-dialog-title" className="text-center">
              Evidencias
              {arrayEvidence.length > 0  && (
                <span>{` (${arrayEvidence.length})`}</span>
              )}
            </h4> 
            {viewLoad ?
            <div id="form-dialog-title" className="text-center"> <CircularProgress/></div>
            :(<></>)
            }
            <TableEvidences 
             data={arrayEvidence} 
             setDisabledBtn={setDisabledBtn}
             setViewLoad={setViewLoad}
             arrayEvidence={arrayEvidence}
             setArrayEvidence={setArrayEvidence}
             setSumPercentajeEvindence={setSumPercentajeEvindence}             
             />      
        </DialogContent>
      }

      {
        state.addEvidenceModal && (
          <>
            <input
              type="file"
              ref={inputFileRef}
              name="Image"
              onChange={(e) => onChangeImage(e)}
              accept=".pdf, .jpg, .jpeg, .png, .docx, .xlsx, .mp4, .zip"
            />
            <DialogContent className={styles.evidenceActions}>
              {!disabledBtn ?
             <>
             <AddTable
                func={uploadEvidence}
                text="Cargar Evidencia"
                img="upload-file-blue.svg"
              />
              </>
              :<>
              <button
                className={styles.pd_botonInactive}
                 disabled             
               >                 
                <div><img src={Charge} alt="charge" /></div>
                Cargar Evidencia
               </button>
              </>
                
              }
              <Grid xs item>
                <TextField
                  name="metricName"
                // defaultValue={form.progress}
                  defaultValue={ state?.selectedObjective?.metricIndicator === "pesos" ? `$ ${form.progress}` :
                                  state?.selectedObjective?.metricIndicator === "porcentaje" ? `${form.progress} % `:form.progress }
                  label="Progreso"
                  variant="outlined"
                  size="small"
                  type='text'                                  
                  onChange={updateProgressForm}
                  onKeyPress={(event: any) => {
                    if (!/^[0-9.]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} 
                 />
              </Grid>
              <span className={styles.progress}>
                {result} %
              </span>
            </DialogContent>

            <DialogContent className={styles.evidenceCancelContainer}>
              <button
                onClick={cancelButton}
                className={styles.evidenceCancelButton}
              >Cancelar</button>
              <Button text="Aceptar" func={updateProgress} />
            </DialogContent>
          </>
        )
      }   
    
      {
        state.evaluateObjectiveModal && (
          <DialogContent className={styles.evidenceCancelContainer}>
            <button
              onClick={cancelButton}
              className={styles.evidenceCancelButton}
            >
            {state?.campaign?.status === 'finalizado' ? 'Cerrar' : 'Cancelar'}</button>
            {
              state?.campaign?.step !== 'definir' &&(state.rol==='admin' || state.rol==='leader') && (
                <AddTable
                  func={sendReminder}
                  text="Recordatorio"
                  img="add-icon.svg"
                />
              )
            }            
          </DialogContent>
        )
      }   
    </Dialog>    
  );
};

export default AddEvidenceModal;
