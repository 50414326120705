import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { formatter } from '../../helpers/formatoMoneda'
import { GET_ENTERPRISE_SELECT,GET_HISTORICOS_OUTSORCERS, GET_NOMINAS_OUTSORCERS ,GET_NOMINAS_OUTSORCERS_HISTORICAS} from '../../Querys/querys'
 //import styles from '../../styles/CentralPagos/HistoricoCentralPagos.module.css'
 import styles from '../../styles/Outsourcers/Outsourcers.module.css'
import { server } from "../../global/server";
import axios from 'axios'
import { getDateDay } from '../../helpers/Payroll/Payroll'
import { useHistory } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  year: any;
};

const HistoricoOutsourcersTable =()=>{
  const [inputTotalValue, setinputTotalValue] = useState(0);
  const [empresa, setEmpresa] =  useState("")
  const [filtro, setFiltro] =  useState("")
  const [datosTablas, setDatosTablas] = useState<any[]>([])
  const history = useHistory()
  const {loading:loadingListaEmpresa, error:errorListaEmpresa, data:dataListaEmpresa} = useQuery(GET_ENTERPRISE_SELECT,{})
  const dataEmpresaSelect = dataListaEmpresa?.GET_ENTERPRISE_SELECT.filter((enterprise:any)=>{return enterprise.Outsourcer})

  // const {loading:loadingHistorico, error:errorHistorico, data: dataHistorico,refetch} = useQuery(GET_HISTORICOS_OUTSORCERS,{
  //   variables:{
  //     input:{
  //       filtro: filtro
  //     }
  //   }
  // })
  const {loading: loadingHistorico, error: errorHistorico, data:dataHistorico, refetch} = useQuery(GET_NOMINAS_OUTSORCERS_HISTORICAS,{
    variables:{
      input:{
        filtro:filtro
      }
    }
  })
  const datosHistorico = dataHistorico?.GET_NOMINAS_OUTSORCERS_HISTORICAS
  
  const datosIniciales = () =>{
    if(empresa === "" && dataEmpresaSelect.length > 0){
       setEmpresa((current) => current = dataEmpresaSelect["0"]["id"]);    
    }
  }

  const cambioEstado = (estado:any) =>{
    setEmpresa((current) => current = estado)
  }

  const cambioFiltro = (e:any) =>{
    setFiltro((current) => current = e.target.value)
  }

  useEffect(()=>{
    if(dataHistorico){
      datosIniciales()
    }
  },[dataHistorico])

  useEffect(()=>{
    console.log(datosHistorico);
  },[empresa, filtro])

  
  const exportarPDF = async (id: any) => {

    const PDF: any = await axios.get(`${server}/report/cepDispersion/${id}`)
    const link = document.createElement("a");
    link.href = PDF?.data?.urlPDF;
    link.setAttribute("download", "downloadtesting.PDF");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  const filtrarDatos = (filtro: string) => {
    if (!filtro) {
      // Si el filtro est� vac�o, retornar todos los datos sin filtrar
      return datosHistorico || [];
    }
  
    // Convertir el filtro a min�sculas para hacer la b�squeda insensible a may�sculas
    const filtroLowerCase = filtro.toLowerCase();
  
    // Filtrar datos basados en el nombre del colaborador o RFC
    return (datosHistorico || []).filter((solicitud: { nombreNomina: string }) => 
      solicitud.nombreNomina.toLowerCase().includes(filtroLowerCase) 
    );
  };
  const datosFiltrados=filtrarDatos(filtro);

  const dispersionOutsourcer = (id: any, nombreNomina: any, tipo: any)=>{
    console.log(tipo)
  switch (tipo){
    case "eventual":
      history.push(`/outsorcersdispersionEventual/${id}/${nombreNomina}`)
      break;
    case "Finiquito":
      history.push(`/outsorcersdispersionFiniquito/${id}/${nombreNomina}`)
      break;
    case "Liquidacion":
      history.push(`/outsorcerdispersionLiquidacion/${id}/${nombreNomina}`)
      break;
    default:
        history.push(`/outsorcersdispersion/${id}/${nombreNomina}`)
        break;
  }
  console.log("enviar")
  }
  return(
    <>          
    <div className="conceptsHeaders2">
    <div className="containderImputs-playroll">
    <div className="containderImputsSearcher-playroll">
        <input 
        className="inputSearcher-playroll"
        placeholder='Buscar '
         onChange={(e: any) => setFiltro(e.target.value)}
        />
        <div className="buttonSearch">
          <p>Buscar</p>
        </div>
    </div>
  </div>
    </div>
    
      {datosFiltrados?.map((solicitud:any, index:any)=>(
            <div className={styles.seleccion}>
            <div className={styles.seleccionInternoUno}>
              <div className={styles.tituloInternoUno}>
                {solicitud?.nombreNomina} 
              </div>
              <div className={`${styles.textoInternoUno} ${styles.margenTextoInternoUno}`}>
            <span>
              {getDateDay(solicitud?.fechaInicio)}{" "}
              {getDateDay(solicitud?.fechaFin)}
            </span>
            </div>
            <div className={styles.textoInternoUno}>
              {solicitud?.Empresa}
            </div>
            <div className={styles.textoInternoUno}>
              {solicitud?.tipoNomina}
            </div>
            </div>
            <div className={styles.seleccionInternoTres}>
            <div className={styles.contenedorInternoDatos}>
                <div className={styles.contenedorInternoDatosUno}>
                  <div className={styles.tituloContenedorDatos}>Dispersiones</div>
                  <div className={styles.textoContenedorDatos}>{solicitud?.Dispersiones}</div>
                </div>
                <div className={styles.contenedorInternoDatosUno}>
                  <div className={styles.tituloContenedorDatos}>Total Dispersion</div>
                  <div className={styles.textoContenedorDatos}>{formatter(solicitud?.totalDispersion)}</div>
                </div>
                <div className={styles.contenedorInternoDatosUno}>
                  <div className={styles.tituloContenedorDatos}>Autorizadas </div>
                  <div className={styles.textoContenedorDatos}>{solicitud?.Autorizadas}</div>
                </div>
                <div className={styles.contenedorInternoDatosUno}>
                  <div className={styles.tituloContenedorDatos}>Monto Autorizado </div>
                  <div className={styles.textoContenedorDatos}>{formatter(solicitud?.totalAutorizadas)}</div>
                </div>
              </div>
            </div>
            <div className={styles.seleccionInternoCuatro}>
              <div className={styles.botonContinuaInterno}
                //onClick={() => exportarPDF(solicitud.idDispersion)}
                 onClick={() => dispersionOutsourcer(solicitud?.id,solicitud?.nombreNomina,solicitud?.tipoNomina)}
              >
              Ver Detalles
              </div>
            </div>
          </div>
            ))}
    </>
  )
}

export default HistoricoOutsourcersTable