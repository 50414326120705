/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useHistory, } from 'react-router-dom';
import SearcherTable from "../../../screens/Colaboradores/Colaboradores/CollaboratorTab/SearcherTable";
import AddTable from "../../Collaborators/CollaboratorTab/AddTable";
import Box from '@mui/material/Box';
import ObjectivesTableApprove from "./ObjectivesTableApprove";
import { GET_ALL_USER_CAMPAIGN_OBJECTIVES } from '../../../Querys/querys';
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import { formatter } from "../../../helpers/formatoMoneda";
import styles from "../Objectives.module.css";
import TableObjectives from "./TableObjectives";

const ObjectivesTable = (props:any) => {
  const history = useHistory();
  const { state, dispatch } = useContext(ObjectivesContext);  
  const { data, refetch } = useQuery(GET_ALL_USER_CAMPAIGN_OBJECTIVES, {
    variables: {
      input: {
        campaignId: state.campaignId,
        collaboratorId: state?.collaboratorId || 0,
      }
    },
    pollInterval: 2000,
  });
  const [objectivesListFilter, setObjectivesListFilter] = useState([]);
  const [objectivesListFilterAprove, setObjectivesListFilterAprove] = useState([]);
   


  useEffect(() => {
    if (state.campaignId !== 0 && state.collaboratorId !== 0) {
      refetch({
        input: {
          campaignId: state.campaignId,
          collaboratorId: state?.collaboratorId || 0,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.campaignId, state.collaboratorId]);

  
  useEffect(() => {
     if(state?.collaboratorId !== null){    
      if(isNaN(state?.collaboratorId)){
        history.push('/campanias')
     }
    } 
  }, [state.collaboratorId]);

  useEffect(() => {
    let isLeaderNotified
    if (data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES) {
      data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.map((objective :any)=>{
         objective.isLeaderNotified===true ? isLeaderNotified=true:isLeaderNotified=false
      })
      props.setAddColObj(data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.length)
      props.setLeaderNotified(isLeaderNotified)
      setObjectivesListFilter(
        data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.map( (x: any) => ({
          ...x,          
            progress: x.progress
             ? `${x.progress} / ${  (parseFloat(x.progress || '0') / parseInt(x.metricObjective, 10)*100).toFixed(2) }`
             : '0',
           metricObjective: x.extraChar === '$'?`${formatter(x.metricObjective)}`:`${x.metricObjective}${x.extraChar}`,
           weight: `${x.weight}`
        }))
      );      
      setObjectivesListFilterAprove(data.GET_ALL_USER_CAMPAIGN_OBJECTIVES)      
      let qualificationByproject= data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.map((x: any) =>({
         ...x,
         qualificatioFinal:(x.weight * x.result)/100        
      })) 
      let allQualified=data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES.filter((objectives:any) => objectives.result ===null);            
      props.setCountCalification(allQualified)
      let qual =qualificationByproject.reduce((acum :any ,qual : any) => acum + qual.qualificatioFinal, 0);  
      props.setQualification(qual)
      let sumWeight = data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES.reduce((acum :any , weigth: any) => acum + weigth.weight, 0); 
      props.setWeightObjectives(sumWeight)      
      dispatch({ type: Types.SET_COLLABORATOR_OBJECTIVES_COUNT, payload: data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.length });      
      const allObjectivesAreNotified = data.GET_ALL_USER_CAMPAIGN_OBJECTIVES.every((objective: any) => objective.isLeaderNotified);
      dispatch({ type: Types.SET_DISPLAY_NOTIFY_LEADER_BUTTON, payload: !allObjectivesAreNotified });   
      dispatch({ type: Types.SET_COUNT_NO_APROVE, payload:null });
    }
  }, [data]);
  

  

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box p={1} pb={3} display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" justifyContent="flex-start">
          <SearcherTable
            label="Buscar objetivos ..."
            initState={data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES || [] as any}
            setState={setObjectivesListFilter}
            fields={["ObjectiveName", "nameIndicator", "metricIndicator"]}
          />
        </Box>  
      </Box>
      {
        data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES?.length === 0 && (
          <div className={styles.noObjectives}>
            <div className={styles.noObjectivesContainer}>
              <div className={styles.noObjectivesText}>Comienza a crear objetivos para </div>
              <div className={styles.noObjectivesText}>alcanzar todas las metas de la organización</div>
            </div>
          </div>
        )
      }
       
     {
     state?.rol === 'collaborator'  ?
        objectivesListFilter?.length > 0 && (
           <TableObjectives
            rows={objectivesListFilter}  
            weightObjectives={props.weightObjectives}
            addColObj={props.addColObj}
            maxObjInd={props.maxObjInd}  
            isCheck={props.isCheck}
            setIsCheck={props.setIsCheck}  
           />          
        ):      
        (objectivesListFilterAprove?.length > 0 && state.campaign?.step==='definir'  )
        ?
        <>  
         <ObjectivesTableApprove
            rows={objectivesListFilterAprove}  
            weightObjectives={props.weightObjectives}
            isCheck={props.isCheck}
            setIsCheck={props.setIsCheck}   
            setBtnAproveObjectives={props.setBtnAproveObjectives}     
            addColObj={props.addColObj}
            maxObjInd={props.maxObjInd}   
           
         />    
        </>
        : data?.GET_ALL_USER_CAMPAIGN_OBJECTIVES.length > 0 && state.campaign?.step!=='definir'?
        <>
        <TableObjectives
        rows={objectivesListFilter}  
        weightObjectives={props.weightObjectives}
        addColObj={props.addColObj}
        maxObjInd={props.maxObjInd}  
        isCheck={props.isCheck}
        setIsCheck={props.setIsCheck}  
       />
       </>:
       <></>
      }
    </Box>
  );
};

export default ObjectivesTable;