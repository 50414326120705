import {Grid } from "@material-ui/core";
import './MyMood.css';
import {TextField,Select, InputLabel,FormControl,MenuItem } from "@material-ui/core"
import * as Yup from "yup"
import prefactura from "../../assets/svg/icono_prefactura.svg";
import GreenSwitch from "../ConfigPayroll/GreenSwitch";
import MyMoodContext from '../../context/MyMoodContext/MyMoodContext';
import { useContext, useEffect, useState, useCallback } from 'react';
import {FilterQuestions} from './Modal/FilterQuestions';
import {GET_ALL_PROJECT, GET_ALL_SEDE, GET_ALL_SURVEY, UPDATE_SURVEY_STATUS} from "../../Querys/querys";
import { useMutation, useQuery } from "@apollo/client";
import { IProject } from "../../interfaces/Project";
import moment from "moment";
import TestCommentsDetail from "./Modal/TestCommentsDetail";
import {openTestComments} from '../../context/MyMoodContext/Actions';
import NewRiskSurvey from "./Modal/NewRiskSurvey";

type Survey = {
  id:number;
  displayText: string;
  dateAdd: string;
  project: string;
  campus: string;
  status: string;
  enviados: number;
  respuestas: number;
  Calificacion: number;
}

const RiskSurveys = (props: any) => {
    const {dispatch} = useContext(MyMoodContext)
    const [survey, setSurvey] = useState<Survey[]>([]);
    const [surveySinFiltro, setSurveySinFiltro] = useState<Survey[]>([])
    const [surveySinFiltroStart, setSurveySinFiltroStart] = useState<Survey[]>([])
    const [surveyConFiltro, setSurveyConFiltro] = useState<Survey[]>([])
    const [filterArea, setFilterArea] = useState<any>("")
    const [filterSede, setFilterSede] = useState<any>("")
    const [filterEstatus, setFilterEstatus] = useState<any>("")
    const [filterFecha, setFilterFecha] = useState<any>("")
    const [textSearch, setTextSearch] = useState<any>("")
    const [order, setOrder] = useState<any>("Mayor")
    const { data: resultProject } = useQuery(GET_ALL_PROJECT);
    const allProject = resultProject?.GET_ALL_PROJECT;
    const { data: resultSede } = useQuery(GET_ALL_SEDE);
    const allSede = resultSede?.GET_ALL_SEDE;
    const { data: resultEncuestas, startPolling: startPollingEncuestas, stopPolling: stopPollingEncuestas } = useQuery(GET_ALL_SURVEY);
    const [allEncuestas, setAllEncuestas] = useState<Survey[]>([]);
    const [sede, setSede] = useState<any>([])
    const [projects, setProjects] = useState<Array<IProject>>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [activeFilter, setActiveFilter] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const openTest = ( item: Survey ) => {
      openTestComments( {
          idSurvey: item.id,
          openComments: true,
          Encuestas: item.displayText
      }, dispatch );
    }

    useEffect(()=> {
      if(resultEncuestas && resultEncuestas.GET_ALL_SURVEY){
        setAllEncuestas(resultEncuestas.GET_ALL_SURVEY);
      }
    }, [resultEncuestas]);
    
    const [updateStatus, {data:resultUpdate}] = useMutation(UPDATE_SURVEY_STATUS,{
        refetchQueries: [{query: GET_ALL_SURVEY}]
    });

    useEffect(()=>{
        if(resultUpdate && resultUpdate.UPDATE_SURVEY_STATUS){
            setIsUpdating(false);
        }
    }, [resultUpdate])

    const updateSurveyStatus = async (id:any) =>{
        setIsUpdating(true);
        await updateStatus({
            variables:{
                id: id
            }
        });
    }

    const datosFiltradosStart = useCallback((data: any)=>{
        
        let filtrados = data.filter((lis: any) => {

                return lis?.displayText.toUpperCase().includes(textSearch.toString().toUpperCase()) 
                || lis?.project.toUpperCase().includes(textSearch.toString().toUpperCase())
                || lis?.campus.toUpperCase().includes(textSearch.toString().toUpperCase())
            }
        );

        if (textSearch === '') {
            filtrados = data;
        }
        
        setSurveySinFiltro(data);
        const surveyAux = [...filtrados]
        if(order === "Menor"){
          
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(b.dateAdd).isBefore(moment(a.dateAdd)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }else{
         
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(a.dateAdd).isBefore(moment(b.dateAdd)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }
        
    }, [textSearch, order])

    useEffect(() => {
        if (allProject) setProjects(allProject);
    }, [allProject]);

    useEffect(() => {
        if(allSede){
            setSede(allSede)
        }
    }, [allSede]);    

    
    const getDisplay = () => {

        const surveyAux = [...survey]
        const e = document.getElementById("idFecha")

        if(e?.className === 'sortAsc'){
            e?.classList.remove('sortAsc')
            e?.classList.add('sortDesc')
        }else{
            e?.classList.remove('sortDesc')
            e?.classList.add('sortAsc')
        }

        if(order === "Mayor"){
            setOrder("Menor")
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(b.dateAdd).isBefore(moment(a.dateAdd)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }else{
            setOrder("Mayor")
            const newOrder = surveyAux.sort((a:any, b:any) => ((
                moment(a.dateAdd).isBefore(moment(b.dateAdd)) ? 1 : -1
            )))
            setSurvey(newOrder)
        }
        
    }
    useEffect(() => {
        if(allEncuestas){
            setSurveySinFiltroStart(allEncuestas)
            
            if(activeFilter){

                datosFiltradosStart(allEncuestas.filter((lis: any) =>  surveyConFiltro.includes(lis?.displayText)))
            }else{
                datosFiltradosStart(allEncuestas)
            }

            
            
        }
    }, [allEncuestas, surveyConFiltro, datosFiltradosStart, activeFilter]);


    useEffect(() => {
        if(!isUpdating)
        startPollingEncuestas(2000);
        return () => {
            stopPollingEncuestas();
        };
    }, [startPollingEncuestas, stopPollingEncuestas, isUpdating]);

    const datosFiltrados = (text: string) => {
        let filtrados = surveySinFiltro.filter((lis: any) =>
            lis?.displayText
            .toUpperCase()
            .includes(text.toString().toUpperCase())
        );
  
        if (text === '') {
            filtrados = surveySinFiltro;
            setTextSearch('')
        }else{
            setTextSearch(text)
        }
        
        setSurvey(filtrados);
    };

    const showDivFilter = () => {
        setShowFilter(!showFilter)
        const e = document.getElementById("idFiltros")

        if(e?.className === 'imgLowerPosition'){
            e?.classList.remove('imgLowerPosition')
            e?.classList.add('imgLowerPositionOpen')
        }else{
            e?.classList.remove('imgLowerPositionOpen')
            e?.classList.add('imgLowerPosition')
        }
    
    }

    const filterData = () => {
        let filtrados: any
        let filtrados2: any
        let filtrados3: any
        let filtrados4: any
        
        if(filterArea !== ''){
            filtrados = surveySinFiltroStart.filter((lis: any) =>{
              console.log(lis, filterArea);
                  return String(lis?.project) ===  String(filterArea)
            }
            );
        }else{
            filtrados = surveySinFiltroStart
        }

        if(filterSede !== ''){
            filtrados2 = filtrados.filter((lis: any) =>
                String(lis?.campus) ===  String(filterSede)
            );
        }else{
            filtrados2 = filtrados
        }

        if(filterEstatus !== ''){
            filtrados3 = filtrados2.filter((lis: any) =>
                String(lis?.status) ===  String(filterEstatus)
            );
        }else{
            filtrados3 = filtrados2
        }
        
        if(filterFecha !== ''){
            filtrados4 = filtrados3.filter((lis: any) =>
                moment(lis?.dateAdd).format('YYYY-MM-DD') ===  moment(filterFecha).format('YYYY-MM-DD')  
            );
        }else{
            filtrados4 = filtrados3
        }

        if (filterArea === '' && filterSede === '' && filterEstatus === '' && filterFecha === '') {
            filtrados4 = surveySinFiltroStart;
            setSurveyConFiltro([]);
            setActiveFilter(false)
        }else{
  
            setSurveyConFiltro(
                filtrados4.map((lis: any) => lis.displayText)
            )
            setActiveFilter(true)
        }
        setShowFilter(false)
        
    }

    return (
        <div
        style={
            {
                position: "relative"
            }
        }
        >
            <div>
                <NewRiskSurvey projects={projects} locations={sede}></NewRiskSurvey>
                <Grid className="containerSurveyRisk" >
                    <div className="containerRiskTitle">
                        <div className="surveyTitle" >
                          Test de riesgo psicosocial
                        </div>
                    </div>
                    <div className="containerSurveyFilter" >
                        <div className="conteinerAllQuestion">
                            
                                <div className="pc_contenedorBuscador"
                                style={{
                                    marginTop: "0px",
                                    height: "32px",
                                }}
                                >
                                    <input
                                    type="text"
                                    placeholder="Palabra clave"
                                    className="pc_inputBuscador"
                                    style={{
                                        height: "32px",
                                    }}
                                    id="idInput"
                                    onChange={(e) => datosFiltrados(e.target.value)}
                                    ></input>
                                    
                                </div>
                          
                        </div>
                        <div className="conteinerAllQuestion"
                        style={{
                            width: "100%",
                        }}
                        >
                            <div className="containerOrder">
                                <div className="divOrder" onClick={() => {
                                    //openFilter({},dispatch)
                                    showDivFilter()
                                }}>
                                    <div>Filtros</div>
                                    <div className="imgLowerPosition" id="idFiltros"></div>
                                </div>
                                <div className="divOrder" onClick={() => {getDisplay()}}>
                                    <div>Fecha</div>
                                    <div className="sortAsc" id="idFecha"></div>
                                </div>
                            </div>
                            
                            
                        </div>
                        
                    </div>
                    <div className="containerSurveyFilter" 
                    style={{
                        paddingTop: "0px",
                        height: "0px",
                    }}
                    >
                        {
                            showFilter?
                            <div 
                            style={{
                                marginLeft: "auto",
                                width: "392px",
                            }}
                            >
                                <div className="questionContainer" >
                                    
                                    <div className="dialogoContento">
                                        
                                        <div className="conteinerAllQuestion">
                                        
                                            <FormControl 
                                                fullWidth 
                                                size="small"
                                                variant="outlined" 
                                                className="question" 
                                            >
                                                <InputLabel id="demo-simple-select-label"
                                                style={{
                                                    backgroundColor: "#FFFFFF"
                                                }}
                                                >&Aacute;rea o sub &aacute;rea</InputLabel>
                                                <Select
                                                    style={{
                                                        textOverflow: "ellipsis" ,
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",                                            
                                                    }}
                                                    value={
                                                        filterArea
                                                    }
                                                    onChange={
                                                        (e:any)=>{
                                                            
                                                            setFilterArea(e.target.value)
                                                        }
                                                    }
                                                    labelId="demo-simple-select-label"
                                                    id="filterArea"
                                                    name = "filterArea"
                                                >
                                                    <MenuItem value="" key="" >Seleccione</MenuItem>
                                                    {
                                                        projects.map((project:any)=>{
                                                            return <MenuItem value={project.proyectNameAndClient} key={project.id} >{project.proyectNameAndClient}</MenuItem>
                                                        })
                                                    }
                                                    
                                                </Select>
                                            </FormControl>
                                        </div>
                                
                                    </div>
                                    <div className="conteinerAllQuestion">
                                        <FormControl 
                                            fullWidth 
                                            size="small"
                                            variant="outlined" 
                                            className="question" 
                                        >
                                            <InputLabel id="demo-simple-select-label"
                                            style={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                            >Sede</InputLabel>
                                            <Select
                                                style={{
                                                    textOverflow: "ellipsis" ,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",   
                                                                                                
                                                }}
                                                value={
                                                    filterSede
                                                }
                                                onChange={
                                                    (e:any)=>{
                                                        
                                                        setFilterSede(e.target.value)
                                                    }
                                                }
                                                labelId="demo-simple-select-label"
                                                id="filterSede"
                                                name = "filtersede"
                                            >
                                                <MenuItem value="" key=""  >Seleccione</MenuItem>
                                                {
                                                    sede.map((item:any)=>{
                                                        return <MenuItem value={item.sedeName} key={item.id} >{item.sedeName}</MenuItem>
                                                    })
                                                }
                                                
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="conteinerAllQuestion">
                                        <FormControl 
                                            fullWidth 
                                            size="small"
                                            variant="outlined" 
                                            className="question" 
                                        >
                                            <InputLabel id="demo-simple-select-label"
                                            style={{
                                                backgroundColor: "#FFFFFF"
                                            }}
                                            >Estatus</InputLabel>
                                            <Select
                                                style={{
                                                    textOverflow: "ellipsis" ,
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",   
                                                                                                
                                                }}
                                                value={
                                                    filterEstatus
                                                }
                                                onChange={
                                                    (e:any)=>{
                                                        
                                                        setFilterEstatus(e.target.value)
                                                    }
                                                }
                                                labelId="demo-simple-select-label"
                                                id="filterEstatus"
                                                name = "filterEstatus"
                                            >
                                                <MenuItem value="" key=""  >Seleccione</MenuItem>
                                                <MenuItem value="Activo" key="1"  >Activo</MenuItem>
                                                <MenuItem value="Inactivo" key="2"  >Inactivo</MenuItem>
                                                
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="conteinerAllQuestion">
                                        <TextField
                                            className="question"
                                            type="date"
                                            name="filterFecha"
                                            value={
                                                filterFecha
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth={true}
                                            onChange={(e:any)=>{
                                                
                                                setFilterFecha(e.target.value)
                                            }}
                                            inputProps={{ maxLength: 100 }}
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        />
                                    </div>
                                    <div className="questionAction"  >
                                        <button className="botonSiguiente"
                                        onClick={(()=>filterData())}
                                        >
                                            <div>
                                                Aplicar
                                            </div>
                                        </button>
                                            
                                        

                                    </div>
                                </div>
                            </div>
                            :<div></div>
                        }
                        

                    </div>
                    <div>
                        <div className="pc_contenedorTabla">
                            <table className="pc_tablaPrincipal">
                                <thead className="pd_contenedorTitulos">
                                    <td className="pc_columnaTitulosDate">Fecha</td>
                                    <td className="pc_columnaTitulosSurvey">Enviados</td>
                                    <td className="pc_columnaTitulosSurvey">&Aacute;rea/Proyecto</td>
                                    <td className="pc_columnaTitulosSurvey">Sede</td>
                                    <td className="pc_columnaTitulosSurvey">Respuestas</td>
                                    <td className="pc_columnaTitulosSurvey">Calificación</td>
                                    <td className="pc_columnaTitulosSurvey">Estatus</td>
                                    <td className="pc_columnaTitulosSurvey"></td>
                                </thead>
                                {
                                    survey?.map((item:any)=>(
                                        <tbody>
                                            <tr>
                                                <td className="pc_columnaDatos columnDate"
                                                >
                                                    {moment(item.dateAdd).format("DD/MM/YYYY")}
                                                </td>
                                                <td className="pc_columnaDatos">
                                                    {item.enviados}
                                                </td>
                                                <td className="pc_columnaDatos">
                                                  <p
                                                  >
                                                      {item.project}
                                                  </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                  <p
                                                  >
                                                      {item.campus}
                                                  </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                  <p
                                                  >
                                                      {item.respuestas}
                                                  </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                  <p
                                                  >
                                                      {item.calificacion}
                                                  </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                  <p
                                                  >
                                                    <div className="divIconos">
                                                      <div className="statusLabel">
                                                        {item.status}
                                                      </div>
                                                      <GreenSwitch updatePayrollStatus={updateSurveyStatus} id= {item.id} status={item.status}/>
                                                    </div>
                                                  </p>
                                                </td>
                                                <td className="pc_columnaDatos">
                                                    <div className="divDoc">
                                                        <div className="divIconos">
                                                            {
                                                                item.status === "Inactivo" ?
                                                                <a
                                                                    style={{ marginRight: "5px", marginLeft: "auto" }}
                                                                    target="_blank"
                                                                    title="Vista previa"
                                                                    rel="noopener noreferrer"
                                                                    onClick={() => openTest(item)}
                                                                   
                                                                >
                                                                    <img
                                                                    src={prefactura}
                                                                    style={{ marginRight: "auto", marginLeft: "auto" }}
                                                                    className="cursor"
                                                                    alt="Vista previa"
                                                                    />
                                                                </a>
                                                                : null
                                                            }
                                                            
                                                        </div>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    ))
                                }
                                
                            </table>
                        </div>
                    </div>
                </Grid>
            </div>
            <TestCommentsDetail></TestCommentsDetail>
            <FilterQuestions 
                projects={projects}
                sede={sede}
                filterArea={filterArea}
                setFilterArea={setFilterArea}
                filterSede={filterSede}
                setFilterSede={setFilterSede}
                filterEstatus={filterEstatus} 
                setFilterEstatus={setFilterEstatus}
                filterFecha={filterFecha}
                setFilterFecha={setFilterFecha}
                surveySinFiltroStart={surveySinFiltroStart}
                setSurveySinFiltroStart={setSurveySinFiltroStart}
                setSurveyConFiltro={setSurveyConFiltro}
                surveyConFiltro={surveyConFiltro}
                setSurvey={setSurvey}
                survey={survey}
                setSurveySinFiltro={setSurveySinFiltro}
                surveySinFiltro={surveySinFiltro}
                />
        </div>
    )
};

const validationSchema = () =>{

    return{
        displayText: Yup.string(),
        project: Yup.string().test("area", "El área o proyecto no puede ser igual a la sede", function(value){
            if((this.parent.campus === undefined || this.parent.campus === "") && (value === undefined || value === "")){
                return false;
            }else{
                return true;
            }
        }),
        campus: Yup.string().test("area", "El área o proyecto no puede ser igual a la sede", function(value){
            if((this.parent.project === undefined || this.parent.project === "") && (value === undefined || value === "")){
                return false;
            }else{
                return true;
            }
        }),
    }
}


export default RiskSurveys;
