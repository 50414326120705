export interface TabMonthlyISRInterfaces {
    data?: MonthlyISR[];
    error?: string
}

export interface MonthlyISR {
    id: string,
    _id: string,
    group_name: string,
    frecuency_payment: string,
    init_date: string,
    end_date: string,
    employees: number,
    positive: number,
    negative: number,
    diference: number,
    id_group_payroll: number,
    status: string,
    statusProgress: string
}

export interface MonthlyISRModal {
    frecuency_payment: string,
    init_date: string,
    end_date: string
}

export enum TabMonthlyISRTypes {
    init = '[Tab MonthlyISR] Init',
    add = '[Tab MonthlyISR] Add',
    substract = '[Tab MonthlyISR] Substract',
    filter = '[Tab MonthlyISR] Filter',
    scheduleDrop = '[Tab MonthlyISR] Scuedule Drop',
    setMonthlyISR = '[Tab MonthlyISR] Set MonthlyISR',
    cleanMonthlyISR = '[Tab MonthlyISR] Clean MonthlyISR',
}

export type ActionTabMonthlyISRReducer = {
    type: TabMonthlyISRTypes,
    payload?: any
};

export interface StateTabMonthlyISRInterface {
    loading: boolean;
    MonthlyISRs: MonthlyISR[];
    MonthlyISRFilter?: MonthlyISR[];
    MonthlyISR?: MonthlyISR | null;
};
