import * as d3 from 'd3'
import { useEffect, useRef } from 'react';

type DataItem = {
  name: string;
  value: number;
};

type HistogramProps= {
  width: number;
  height: number;
  data: DataItem[];
}

const Histogram = ({data, width, height}:HistogramProps) =>{
   const ref = useRef<SVGSVGElement>(null);

   useEffect(() =>{
    // declare margins
    const margin = {top: 80, right: 60, bottom: 80, left: 60};
    // Clear previous chart
    d3.select(ref.current).selectAll('*').remove();
    // create the svg that holds the chart
    const svg = d3.select(ref.current)
    .append('svg')
          .style("background-color", "white")
          .attr("width", width)
          .attr("height", height)
          .append('g')
          .attr("transform",`translate(0,-${margin.bottom-40})`);

    // create the x axis scale, scaled to the states
    const xScale = d3.scaleBand()
        .domain(data.map(d => d.name))
        .rangeRound([margin.left, width - margin.right])
        .padding(0.1)

    // create the y axis scale, scaled from 0 to the max
    const yScale = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.value) as number])
        .range([height - margin.bottom, margin.top])

    // create a scale between colors that varies by the frequency
    const barColors = d3.scaleLinear()
      .domain([0,d3.max(data, d => d.value) as number])
      .range(["blue","red"] as any)

          // set the x axis on the bottom.
    // tilts the axis text so it's readable and not smushed.
    svg.append("g")
      .attr('transform', `translate(0,${height-margin.bottom})`)
      .call(d3.axisBottom(xScale))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)");

    // set the y axis on the left
    svg.append("g")
    .attr('transform', `translate(${margin.left},0)`)
        .call(d3.axisLeft(yScale));
    
    svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("x", -(width/2))
    .attr("y", 15)
    .style("text-anchor", "middle")
    .text("Cantidad elementos");
      

      // create the actual bars on the graph, appends a 'rect' for every data element
      // sets the x and y positions relative to the scales already established
      // sets the height according to the yscale
      // static bar width, color is scaled on the y axis
      // finally the bars have an outline
      const bars = svg
      .selectAll("rect")
      .data(data)
      .enter().append("rect")
        .attr('x', d => xScale(d.name)!)
        .attr('y', d => yScale(d.value))
        .attr('width', xScale.bandwidth())
        .attr('height', d => yScale(0) - yScale(d.value))
        .style("padding", "3px")
        .style("margin", "1px")
        .style("width", d => `${d as any*10} px`)
        .attr("fill", function(d) {return barColors(d.value)})
        .attr("stroke", "black")
        .attr("stroke-width", 1);
   },[data, height, width])
    
    
  return (
    <svg ref={ref} width={width} height={height} />
  )
    
}
export default Histogram