    import {
        Theme,
        Dialog,
        withStyles,
        Grid,
    } from "@material-ui/core";
    import style from "./ShowIncident.module.css";
    import { useEffect, useContext } from "react";
    import PayrollProcessContext from "../../../../context/PayrollProcess/PayrollProcessContext";
    import MuiDialogContent from "@material-ui/core/DialogContent";
    import MuiDialogActions from "@material-ui/core/DialogActions";
    import {
        clearPayrollProcess, openDeleteIncident
    } from "../../../../context/PayrollProcess/Actions";
    import {
        GET_INCIDENT_PAYROLL
    } from "../../../../Querys/querys";
    import { useQuery } from "@apollo/client";
    import { formatter } from "../../../../helpers/formatoMoneda";
    import moment from "moment";
    import DeleteIncident from "../DeleteIncident/DeleteIncident";
    import EyeIcon from "../../../../assets/svg/eye.svg";
    import iconoDelete from "../../../../assets/svg/iconoDelete.svg";
    import { getDateComplete } from "../../../../helpers/Payroll/Payroll";

    const DialogContent = withStyles((theme: Theme) => ({
        root: {
        padding: theme.spacing(2),
        },
    }))(MuiDialogContent);

    const DialogActions = withStyles((theme: Theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);
  
    const ShowIncident = (props: any) => {
        const { state, dispatch: payrollDispatch } = useContext(
            PayrollProcessContext
        );

        const {data: resultIncidentPayroll, startPolling, stopPolling} = useQuery(GET_INCIDENT_PAYROLL, {
            variables: { idPayroll: state.idPayroll == ''? 0: state.idPayroll }
        });
        const incident = resultIncidentPayroll?.GET_INCIDENT_PAYROLL;
        const incidentCollaborator = incident?.filter((item: any) => item.idCollaborator == state.idCollaborator);
        const filtradoP: any = incidentCollaborator?.filter((item: any) => item.TypeConcept === "Percepcion" && item.Economicos === 0)
        const filtradoD: any = incidentCollaborator?.filter((item: any) => item.TypeConcept === "Deduccion" && item.Economicos === 0)
        const filtradoV: any = incidentCollaborator?.filter((item: any) => item.Economicos === 1)

        useEffect(()=>{
            startPolling(1000);
            return () =>{
                stopPolling()
            }
        },[startPolling,stopPolling])
        const handleClose = () => {
            clearPayrollProcess({}, payrollDispatch);

        };

        const actionDeleteIncident = (idDeleteIncident: any) => {
            
            openDeleteIncident({idDeleteIncident}, payrollDispatch);
        }

        return (
            <div>
                
                <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={state.showIncident}
                fullWidth={true}
                maxWidth={"lg"}
                >
                    
                    <div className={style.containerTitle}>
                        <div className={style.centerContainer}
                        style={{
                            marginLeft:'auto',
                            marginRight:'auto'
                        }}
                        >
                            <h2 id="form-dialog-title" data-testid="TitleModal">Incidencias</h2>
                            
                        </div>
                        <div className={style.contenedorCerrarModal}>
                            <div className={style.iconoCerrarModal} onClick={(()=>handleClose())}></div>      
                        </div> 
                    </div>
                            
                    <DialogContent className={style.dialogoContento}>
                        {filtradoP?.length > 0 || filtradoD?.length > 0 || filtradoV?.length > 0 ? 
                        <>
                            <Grid item xs={12} className={style.ColumnaEditarPuesto}>
                                <Grid className={style.titulos}>
                                    <span>Percepciones</span>
                                </Grid>
                                <div className={style.pc_contenedorTabla}>
                                    <table className={style.pc_tablaPrincipal}>
                                        <thead className={style.pd_contenedorTitulos}>
                                            <td className={style.pc_columnaTitulos}>
                                                <p style={{
                                                    marginLeft: '30.61px'
                                                }}>Tipo</p>
                                            </td>
                                            <td className={style.pc_columnaTitulos}>Gravable</td>
                                            <td className={style.pc_columnaTitulosConcepto}>Concepto</td>
                                            <td className={style.pc_columnaTitulos}>Tiempo</td>
                                            <td className={style.pc_columnaTitulos}>Importe</td>
                                            <td className={style.pc_columnaTitulos}>Comentario</td>
                                            <td className={style.pc_columnaTitulos}></td>
                                        </thead>
                                        <tbody>
                                            {filtradoP?.map((reg: any) => (
                                                <tr>
                                            
                                                    <td className={style.pc_columnaDatos}>
                                                        <p style={{
                                                        marginLeft: '30.61px'
                                                        }}>{reg.Incident_type}</p>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        {reg.Grabable}
                                                    </td>

                                                    <td className={style.pc_columnaDatos}  >
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Concepto}>{reg.Concepto}</p>
                                                        </div>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        {reg.InitDate? moment(reg.InitDate).format('YYYY/MM/DD'): ""} 
                                                        {reg.Horas == 0? "": reg.Horas + " hrs"}
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        {formatter(reg.Total)}
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Comentarios}>{reg.Comentarios}</p>
                                                        </div>
                                                    </td>

                                                    <td  className={style.pc_columnaDatos}>
                                                        <div >
                                                            <div className={style.containerOptions}>
                                                                {reg?.archive?
                                                                <div className={style.iconoDocModal}>
                                                                    <a
                                                                        href={reg?.archive}
                                                                    >
                                                                        <img
                                                                        style={{ height: "14px","marginTop":"5px" }}
                                                                        src={EyeIcon}
                                                                        alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                : <div></div>
                                                                }
                                                                {state.varDelete?
                                                                <div className={style.iconoDocModal}>
                                                                    <a
                                                                       onClick={(()=>actionDeleteIncident(reg.id))}
                                                                    >
                                                                        <img
                                                                        style={{ height: "15px","marginTop":"5px", cursor: "pointer" }}
                                                                        src={iconoDelete}
                                                                        alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                : <div></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
        
                            <Grid item xs={12} className={style.ColumnaEditarPuesto}>
                                <Grid className={style.titulos}>
                                    <span>Deducciones</span>
                                </Grid>
                                <div className={style.pc_contenedorTabla}>
                                    <table className={style.pc_tablaPrincipal}>
                                        <thead className={style.pd_contenedorTitulos}>
                                            <td className={style.pc_columnaTitulos}>
                                                <p style={{
                                                    marginLeft: '30.61px'
                                                }}>Tipo</p>
                                            </td>
                                            <td className={style.pc_columnaTitulos}>Gravable</td>
                                            <td className={style.pc_columnaTitulosConcepto}>Concepto</td>
                                            <td className={style.pc_columnaTitulos}>Tiempo</td>
                                            <td className={style.pc_columnaTitulos}>Importe</td>
                                            <td className={style.pc_columnaTitulos}>Comentario</td>
                                            <td className={style.pc_columnaTitulos}></td>
                                        </thead>
                                        <tbody>
                                            {filtradoD?.map((reg: any, index: any) => (
                                                <tr>
                                            
                                                    <td className={style.pc_columnaDatos}>
                                                    <p style={{
                                                    marginLeft: '30.61px'
                                                    }}>{reg.Incident_type}</p>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        {reg.Grabable}
                                                    </td>

                                                    <td className={style.pc_columnaDatos} title={reg.Concept} >
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Concepto}>{reg.Concepto}</p>
                                                        </div>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        {reg.InitDate? moment(reg.InitDate).format('YYYY/MM/DD'): ""} 
                                                        {reg.Horas == 0? "": reg.Horas + " hrs"}
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        {formatter(reg.Total)}
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Comentarios}>{reg.Comentarios}</p>
                                                        </div>
                                                    </td>

                                                    <td  className={style.pc_columnaDatos}>
                                                        <div >
                                                            <div className={style.containerOptions}>
                                                                {reg?.archive?
                                                                <div className={style.iconoDocModal}>
                                                                    <a
                                                                        href={reg?.archive}
                                                                    >
                                                                        <img
                                                                        style={{ height: "14px","marginTop":"5px" }}
                                                                        src={EyeIcon}
                                                                        alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                : <div></div>
                                                                }
                                                                {state.varDelete && reg.idAdelanto <= 0 ?
                                                                <div className={style.iconoDocModal}>
                                                                    <a
                                                                       onClick={(()=>actionDeleteIncident(reg.id))}
                                                                    >
                                                                        <img
                                                                        style={{ height: "15px","marginTop":"5px", cursor: "pointer" }}
                                                                        src={iconoDelete}
                                                                        alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                : <div></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>

                            <Grid item xs={12} className={style.ColumnaEditarPuesto}>
                                <Grid className={style.titulos}>
                                    <span>Permisos</span>
                                </Grid>
                                <div className={style.pc_contenedorTabla}>
                                    <table className={style.pc_tablaPrincipal}>
                                        <thead className={style.pd_contenedorTitulos}>
                                            <td className={style.pc_columnaTitulosConcepto}>Concepto</td>
                                            <td className={style.pc_columnaTitulos}>Dias</td>
                                            <td className={style.pc_columnaTitulos}>Día Inicio</td>
                                            <td className={style.pc_columnaTitulos}>Día Fin</td>
                                            <td className={style.pc_columnaTitulos}>Comentario</td>
                                            <td className={style.pc_columnaTitulos}></td>
                                        </thead>
                                        <tbody>
                                            {filtradoV?.map((reg: any) => (
                                                <tr>
                                                    <td className={style.pc_columnaDatos}  >
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Concepto}>{reg.Concepto}</p>
                                                        </div>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}  >
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Dias}>{reg.Dias}</p>
                                                        </div>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}  >
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Dias}>{getDateComplete(reg.InitDate)}</p>
                                                        </div>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}  >
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Dias}>{getDateComplete(reg.EndDate)}</p>
                                                        </div>
                                                    </td>

                                                    <td className={style.pc_columnaDatos}>
                                                        <div className={style.tableDivConcept}>
                                                            <p title={reg.Comentarios}>{reg.Comentarios}</p>
                                                        </div>
                                                    </td>

                                                    <td  className={style.pc_columnaDatos}>
                                                        <div >
                                                            <div className={style.containerOptions}>
                                                                {reg?.archive?
                                                                <div className={style.iconoDocModal}>
                                                                    <a
                                                                        href={reg?.archive}
                                                                    >
                                                                        <img
                                                                        style={{ height: "14px","marginTop":"5px" }}
                                                                        src={EyeIcon}
                                                                        alt=""
                                                                        />
                                                                    </a>
                                                                </div>
                                                                : <div></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
                        </>
                    
                        : <div className={style.conteinerNoReg}>
                            <div className={style.divNoReg}>
                                <p className={style.textNoReg}>
                                    No hay registros
                                </p>
                            </div>
                        </div>
                        }
                    </DialogContent>
                    
                    <DialogActions>
                        <button className={style.botonCancelar} onClick={handleClose}>
                            Cerrar
                        </button>

                    </DialogActions>
                    
                </Dialog>
                <DeleteIncident/>
                   
            </div>
        )
    }
    export default ShowIncident