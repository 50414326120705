/* eslint-disable array-callback-return */
import { useContext,useState,useEffect } from 'react';
import React from 'react';
import ObjectivesContext,{ Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import { 
    Dialog,
    DialogTitle,
    DialogContent, 
    } from '@material-ui/core';
import { useMutation,useQuery } from "@apollo/client";    
import { 
  GET_CAMPAIGN_USERS_BY_ADMIN,
  CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL,
  UPDATE_COLABORADOR_BOND
 } from '../../../Querys/querys';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { ErrorAlert } from "../../../alerts/errorAlert";
import styles from '../Objectives.module.css';

const CreateIncidentBondKpi = ({arrayIncidEnt, setArrayIncident,selected,setSelected  }:any) => {
    const { state, dispatch } = useContext(ObjectivesContext);    
    const [schemeType, setSchemeType] = useState('');
    const [dataInfo, setDataInfo] = useState([]);  
    // const [getCampaignUsersByAdmin]  = useLazyQuery(GET_CAMPAIGN_USERS_BY_ADMIN, { fetchPolicy: 'no-cache' });
    const { data } = useQuery(GET_CAMPAIGN_USERS_BY_ADMIN, {
      variables: {
        idCampaing: state?.campaignId,
        step:state?.campaign?.step
      },
      pollInterval: 1000,
    });
    const [createIncidentKpis]    = useMutation(CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL, { });
    const [updateIncidentKpisCol] = useMutation(UPDATE_COLABORADOR_BOND, { 
     refetchQueries: [
      { query: GET_CAMPAIGN_USERS_BY_ADMIN,
        variables: {  idCampaing: state?.campaignId,
                      step:state?.campaign?.step },
      }
  ],});
    
    useEffect(() => {    
     if (data?.GET_CAMPAIGN_USERS_BY_ADMIN?.length > 0) {    
      setDataInfo(data.GET_CAMPAIGN_USERS_BY_ADMIN);
      }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data,dataInfo]);



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSchemeType((event.target as HTMLInputElement).value);
    };
    
    const createIncident= async ()=>{
        const now=moment().toISOString();
          let userBonds:any=[]
          let obSel:any={}
        if(schemeType ==='') {
        return   ErrorAlert({ text: 'Seleccion el tipo de esquema' });
       }     
     dataInfo.filter((col:any) => {
      arrayIncidEnt.filter((idSel:any)=>{
        if(col.id === idSel){
          obSel={id:col.id, totalImpo:col.bond,InitDate:now}
          userBonds.push(obSel)
        }
      })
     })
    const arrayResp:any=[];
     for await (let userBond of userBonds) {
         const res=await createIncidentKpis({
           variables: {
             input: {
              idCollaborator: parseInt(userBond.id),
               Incident_type: "Dinero",
               InitDate: userBond.InitDate ,
               Total: userBond.totalImpo,
               Scheme_type: schemeType,
               idConcept: 45,
               Horas: 0,
               Dias: 0,
              Comentarios: state?.campaign?.nameCampaign,
              TypeConcept: "Percepcion",
              Periodico:false,    
              FechaPeriodica:null
             },
           },
         });       
        if(res.data?.CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL.mensaje ==='Creado'){
          await updateIncidentKpisCol({
          variables: {
            idCollaborator: parseInt(userBond.id),
            idCampaign:parseInt(state?.campaignId as any, 10),
            idPayRoll:Number(res.data.CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL.id)
          }
         }) 
         arrayResp.push(res.data?.CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL)              
        }
        if(res.data?.CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL.mensaje ==='Nomina'){
          arrayResp.push(res.data?.CREATE_PAYROLL_INCIDENT_DASHBOARD_WITHOUT_PAYROLL)           
        }             
      }    
      let msj=true;
      arrayResp.map((resp:any)=>{
        if(resp.mensaje==='Nomina'){
          msj=false;
        }
      })

      if(msj){
        SuccessfulAlert({ title: "¡Exito!", text: "¡Se ha añadido la incidencia!", });   
      }else{
        ErrorAlert({ text: "Has seleccionado colaboradores con esquema 100% nomina, no es posible dar de alta incidencia no Gravable." });         
      }   
      setSchemeType('')
      setArrayIncident([])
      setSelected([])
      dispatch({ type: Types.CANCEL_INCIDENT_BOND_MODAL });     
    }

    const cancelButton =async () => {    
       setSchemeType('')
       dispatch({ type: Types.CANCEL_INCIDENT_BOND_MODAL });           
      }


  return (
    <Dialog
    aria-labelledby="customized-dialog-title"
    open={state.createBondKpi}
    fullWidth={true}      
  >
       <DialogTitle id="alert-dialog-title" >
          <IconButton aria-label="close" 
                  onClick={(()=> { cancelButton() } )}
                  sx={{position: 'absolute', right: 8, top: 8,color: (theme) => theme.palette.grey[500], }}
          >
          <CloseIcon />
        </IconButton> 
          <div className={styles.containerTitlesKpisBonds}> 
            <div className={styles.titlesKpisBonds1}>Campaña KPI's</div>
            <div className={styles.titlesKpisBonds2}>{state?.campaign?.nameCampaign} </div>
            <div className={styles.titlesKpisBonds3}>{state?.campaign?.campaignBond !== 'false' ?'Con bono':'Sin bono'} </div>
          </div>
          
     </DialogTitle>       
    <DialogContent>
        <div className={styles.checkButtonIncidentKpi}>
        <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"          
            name="radio-buttons-group"
            value={schemeType}
            onChange={handleChange}
          >            
          <FormControlLabel value="Gravable" control={<Radio />} label="Gravable" />
          <FormControlLabel value="NoGravable" control={<Radio />} label="No Gravable" />
        </RadioGroup>
        </div>            
    </DialogContent>
    <DialogContent 
     className={styles.containerbtnsModalBonds}
    >
      <div>
         <button
           onClick={createIncident}
           className={styles.pd_botonActive}
            >Crear Incidencia
        </button>                   
      </div>  
      <div>
         <button
           onClick={cancelButton}
           className={styles.evidenceCancelButton}
            >Cerrar
        </button>                   
      </div>       
   </DialogContent>
     
      
  </Dialog>  
  )
}

export default CreateIncidentBondKpi