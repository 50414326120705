import { useState, useContext } from "react";
import { Dialog } from "@material-ui/core"
import '../Payroll.css'
import styles from './PayrollModals.module.css'
import MonthlyISRProcessContext, { Types } from "../../../context/PayrollProcess/MonthlyISRProcessContext";
import { clearPayrollProcess } from "../../../context/PayrollProcess/Actions";
import { DELETE_MONTHLYISR, GET_ALL_MONTHLYISR, GET_ALL_MONTHLYISR_ACTIVE } from "../../../Querys/querys";
import { useMutation } from "@apollo/client";

const EliminaMonthlyISR = () => {
  const { state, dispatch } = useContext(MonthlyISRProcessContext)
  const [activo, setactivo] = useState(false)
  const [deletePayroll] = useMutation(DELETE_MONTHLYISR, {
    refetchQueries: [
        { query: GET_ALL_MONTHLYISR }, 
        { query: GET_ALL_MONTHLYISR_ACTIVE }
    ],
  });

  const handleChange = (e: any) => {
    if (e.target.value === 'ELIMINAR') {
      setactivo(true)
    } else {
      setactivo(false)
    }
  }

  const handleDelete = async () => {
    const inProgressPayroll = state?.inProgressPayroll.map(
      lis => lis.id === state._id 
      ? ({ ...lis, deleted: true })
      : ({ ...lis })
    )
    dispatch({
      type: Types.UPDATE_IN_PROGRESS_PAYROLL,
      payload: inProgressPayroll
    })
    deletePayroll({
      variables: {
        deletePayrollId: state._id
      }
    })
    handleClose()
  }

  const handleClose = () => {
    clearPayrollProcess({}, dispatch)
  }


  return (
    <Dialog aria-labelledby='costumized-dialog-title' open={state.deleteModal} fullWidth={false} onClose={handleClose} maxWidth={"md"}>
      <div className={styles.em_container}>
        <div className={styles.contenedorCerrarModal}>
          <div
            className={styles.cerrarModal}
            onClick={() => handleClose()}>
          </div>
        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.iconoEliminar}>
          </div>
          <div className={styles.em_titulo}>
            ¿Est&aacute;s seguro de que quieres eliminar el calculo?
          </div>
          <div className={styles.em_mensajePrtincipal}>
            <span>Una vez eliminado no podr&aacute;s recuperar la informaci&oacute;n</span>
            <br />
            <span className={styles.em_textoPrincipal}>Escribe ELIMINAR para confirmar</span>
          </div>
          <div>
            <input
              className={styles.input}
              type="text"
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <div className={styles.em_contenedorBotones}>
        <button
          className={styles.em_botonCancelar}
          onClick={() => handleClose()}
        >
          Cancelar
        </button>
        {(activo === true ?
          <button
            className={styles.em_botonConfirmarActivo}
            onClick={() => handleDelete()}
          >
            <div className={styles.em_iconoConfirmarEliminar}></div>
            <div>
              Confirmar
            </div>
          </button>
          :
          <button className={styles.em_botonConfirmarInactivo}>
            <div className={styles.em_iconoConfirmarEliminar}></div>
            <div>
              Confirmar
            </div>
          </button>
        )}

      </div>
    </Dialog>
  )
}

export default EliminaMonthlyISR