import { TabUnstyled, TabsListUnstyled, TabsUnstyled } from "@mui/base";
import HistoricoOutsourcersTable from "./HistoricoOutsourcersTable";
import { GET_PAYROLLYEAR } from "../../Querys/querys";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import OutsourcersState from "../../context/Outsourcers/OutsourcersState";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const HistoricoOutsorcersTab = (props: TabPanelProps) =>{
  const { children, value, index, ...other } = props;
  const [activo, setActivo] = useState('2023')
  const resulYearPayroll= useQuery(GET_PAYROLLYEAR);
  const yearPayroll = resulYearPayroll.data?.GET_PAYROLLYEAR;
  const [year, setYear] = useState(yearPayroll);
  const [loader, setLoader] = useState(false);
  return(
    <>
    <TabsUnstyled defaultValue={0} className="mainConceptsHeader">
        <div className="conceptsHeaders2">
          {/* <div className="concepTitle">
            <p>
              Per�odos
            </p>
          </div>
          <div className="concepConteinerYears" >
            <div className="concepYear">
              <TabsListUnstyled>
                {
                  year?.map((y: any) =>(
                    <TabUnstyled
                      id="botonHeader"
                      className={`configBotonHeader2 ${ activo === y?.year ? "activo" : "desactivo"}`}
                      onClick={(e)=> setActivo(y?.year)}>
                        <b
                        style={{
                          cursor: "pointer"
                        }}
                        >
                          {y?.year}
                        </b>
                      </TabUnstyled>
                  ))
                }
                
              </TabsListUnstyled>
            </div>
              
          </div> */}
            

        </div>
        {
          loader && (
            <div className="loading_historical_container">
                <div className="loading_historical" />
            </div>
          )
        }
      </TabsUnstyled>
      <HistoricoOutsourcersTable />
    </>
    
  )
}

export default HistoricoOutsorcersTab