import { useState, useContext } from "react";
import "./SideBar.css";
import LinkSideBar from "./LinkSideBar";
import { AdminContext } from "../../context/AdminContext/AdminContext";
import CheckPermission from "../../helpers/Administradores/Permissions";

const SideBar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { adminState } = useContext(AdminContext);

  
  const changeMenu = () => {
    if (isOpen) {
      document.documentElement.style.setProperty('--widthMenu', '50px');
      document.documentElement.style.setProperty('--marginLi', '0');
      document.documentElement.style.setProperty('--visibleText', 'hidden');
      document.documentElement.style.setProperty('--heightA', '0px');
      document.documentElement.style.setProperty('--widthLi', '60%');
      document.documentElement.style.setProperty('--marginRightIconMenu', '0');
      document.documentElement.style.setProperty('--locationIconMenu', 'center');

      const meeting = document.querySelectorAll('#meeting');
      meeting.forEach((meet) => {
          const p = document.querySelector('#textHelp')
          if(p){
              meet.removeChild(p)
          }
      });

      setIsOpen(false);
    } else {
      document.documentElement.style.setProperty('--widthMenu', 'auto');
      document.documentElement.style.setProperty('--marginLi', '0 6px 9px 0');
      document.documentElement.style.setProperty('--visibleText', 'visible');
      document.documentElement.style.setProperty('--heightA', 'none');
      document.documentElement.style.setProperty('--widthLi', 'none');
      document.documentElement.style.setProperty('--marginRightIconMenu', '-2vh');
      document.documentElement.style.setProperty('--locationIconMenu', 'flex-end');
      const meeting = document.querySelectorAll('#meeting');

      meeting.forEach((meet) => {
          const p = document.createElement('p')
          p.textContent = "Ayuda"
          p.id="textHelp"
          meet.appendChild(p)
      });
      
      setIsOpen(true);
    }
  };



    return (
        <nav>       
        <ul>
            <li id="IconMenu">
                <img alt="Dashbaord" onClick={changeMenu} />
            </li>

            {CheckPermission("Sidebar.Dashboard")?
            <LinkSideBar _id="dashboardIcon" name="Dashboard" path="/" />
            :null
            }
            {CheckPermission("Sidebar.Colaboradores")?
            <LinkSideBar _id="colaboradoresIcon" name="Colaboradores" path="/collaborators/id/0" />
            :null
            }
             {CheckPermission("Sidebar.MyMood")?
            <LinkSideBar _id="mymoodIcon" name="My Mood" path="/MyMood" />
            :null
            }
            {CheckPermission("Sidebar.Notificaciones")?
            <LinkSideBar _id="notificationsIcon" name="Notificaciones" path="/notifications" />
            :null
            }
            {CheckPermission("Sidebar.Noticias")?
            <LinkSideBar _id="noticesIcon" name="Noticias" path="/news" />
            :null
            }
            {CheckPermission("Sidebar.Calendario")?
            <LinkSideBar _id="calendarIcon" name="Calendario" path="/calendarNuevo" />
            :null
            }
            {CheckPermission("Sidebar.Organigrama")?
            <LinkSideBar _id="organigramaIcon" name="Organización" path="/organigrama" />
            :null
            }
        
            {CheckPermission("Sidebar.Nominas")?
            <LinkSideBar _id="payrollIcon" name="Nóminas" path="/payroll" />
            :null
            }
            {CheckPermission("Sidebar.IDSE")?
            <LinkSideBar _id="configPayrollIcon" name="IDSE-SUA" path="/IDSE" />
            :null
            }
            {
            <LinkSideBar _id="contratosIcon" name="Contratos" path="/contracts" />

            }
            {/* {CheckPermission("Sidebar.Prestaciones")? */}
           <LinkSideBar _id="benefits" name="Prestaciones" path="/prestaciones" />
            {/* :null
            } */}
            {/* {CheckPermission("Sidebar.KPIs")? */}
              <LinkSideBar _id="campaigns" name="KPI´s y Bonos" path="/campanias" />
              {/* :null
              } */}
            {/* {CheckPermission("Sidebar.Informes")? */}
              <LinkSideBar _id="reports" name="informes" path="/informes" />
              {/* :null
              } */}

            {CheckPermission("Sidebar.Outsourcers")?
            <LinkSideBar _id="outsorcersIcon" name="Outsorcers" path="/outsourcers" />
            :null
            }
            {CheckPermission("Sidebar.CentralPago")?
            <LinkSideBar _id="centralPagosIcon" name="Central de pagos" path="/centralpagos" />
            :null
            }
            {/* {
            <LinkSideBar _id="paymentIcon" name="Facturación y pagos" path="/payment" />
            }
            {CheckPermission("Sidebar.MiEmpresa")?
            <LinkSideBar _id="empresaIcon" name="Mi Compañia" path="/miempresa" />
            :null
            } */}
            
        </ul>
        </nav>
    )
};

export default SideBar;
