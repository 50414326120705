/* eslint-disable array-callback-return */
import { useContext,useState } from "react";
import { useHistory } from 'react-router-dom';
import { AdminContext } from '../../../context/AdminContext/AdminContext';
import ReportContext from "../../../context/ReportsContext/ReportContext";
import {useMutation } from "@apollo/client";
import {
  GET_ALL_DATAREPORTS,
  EDIT_DATAREPORT
  } from "../../../Querys/reports/querys";
import { clearReportsModal } from "../../../context/ReportsContext/Actions";
import { Dialog, DialogContent,TextField } from "@material-ui/core";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
// import BtnCreate from "../components/BtnCreate";
import { ErrorAlert } from "../../../alerts/errorAlert";
import { Formik, Form } from "formik";
import iconReport from "../../../assets/icons/add-report.svg";
import styles from '../../../components/NewCalendar/Calendars.module.css'

const EditReport = () => {
  const { state, dispatch }          = useContext(ReportContext)
  const { adminState } = useContext(AdminContext);
  const history = useHistory();  
  const [nameReport,setNameReport]   = useState('');
  
  const [editDataReport] = useMutation(EDIT_DATAREPORT, {
    refetchQueries: [
        { query: GET_ALL_DATAREPORTS }
    ],
  }); 
  
  const handleClose = () => {
     clearReportsModal(false, dispatch)
  }
  
  const handleChangeNameReport = (e:any) => {
    setNameReport(e.target.value)
 }

 const initialValues = () => {
    return {
      name: ""
    }
  }

  return (
    <Dialog
      open={state.editModalReports}
      aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth={true}>
      <div className={styles.dialogContainer}>
        <div className={styles.containerTitle}>
          <div className={styles.centerContainer}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <h2 id="form-dialog-title" data-testid="TitleModal">Editar informe</h2>
          </div>
          <div className={styles.contenedorCerrarModal}>
            <div className={styles.iconoCerrarModal} onClick={(() => handleClose())}></div>
          </div>
        </div>
        <DialogContent>
          <Formik
            initialValues={initialValues()}
            onSubmit={ async () => {
            const info={
                 userAdd :adminState?.Usuario,
               reportName:nameReport
             }         
            
            if(info.reportName===""){
              return ErrorAlert({text:'Es necesario asignar un nombre al informe'});              
            }

            const { data: reportEdit}= await editDataReport({
               variables: {
                 Id:parseInt(state._id),
                 input: info,
               },
             });              
              handleClose();
              history.push(`/informes/${reportEdit.EDIT_DATAREPORT[0].id}`)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <br />
                <TextField
                      type="text"
                      name="nameReport"
                      label="Nombre del informe *"
                      variant="outlined"
                      size="small"                     
                      onChange={(e:any)=>{
                        handleChangeNameReport(e);                                                                
                       }}   
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}                      
                      inputProps={{
                        style: {                          
                          width: "435px",
                        },                       
                      }}
                       InputProps={{
                         endAdornment:<InputAdornment position="end">
                                          <Tooltip title="Nombre informe">
                                          <HelpOutlineIcon
                                           fontSize="small"
                                           color="disabled"
                                          />
                                          </Tooltip>
                                      </InputAdornment>,
                       }}
                      InputLabelProps={{style: {fontSize: 12}}}
                    />  
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className={styles.centerContainer}>
                  <button 
                     type="button" 
                     className={styles.buttonCancelCalendar}
                     onClick={() => handleClose()}>
                     <b className={styles.buttonCancelCalendarText}
                      style={{
                        textTransform: "capitalize"
                      }}
                    >Cancelar</b>
                  </button>              
                  <div className={styles.conteinerSave}>
                     <button
                            id="addPrestacion"
                            type="submit"
                            className="btnNuevaPrestacion"
                        >
                          <div className="alineacionBoton">
                            <img src={iconReport} alt="ipon"/>
                            <div className="nombrePrestacion">
                            <b><span>Editar informe</span> </b>
                            </div>
                          </div>
                        </button> 
                   </div>  
                   <div className={styles.conteinerSave}>
                  {/* <BtnCreate 
                     alt={"Vista previa"}
                     title={"Vista previa"}
                     action={"see"} />                      */}
                   </div>  
                </div>

              </Form>
            )}
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default EditReport