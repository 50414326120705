import FilterContainer from "./components/FilterContainer"
import SquareReport from "./components/SquareReport";
import TableReportIndividual from "./components/TableReportIndividual"
import "./css/FilterConteiner.css";
const ReportIndividual = ({infoReport,camposBd,camposBdArray, setCamposBdArray,
                           inpuntBdValues, setInpuntBdValues,report,
                           setReport,reportFilter,setReportFilter,reload,
                           setReload,viewFilter,viewSquare,loading,setDeleteColumns,
                           deleteColumns,inpuntValues,setInpuntValues,
                           columnas,setColumnas, filas, setFilas,camposPivotes}:any) => {
  return (
    <>
    {(loading === true) ?
        <div className="c_contenedorPrincipal">
          <div className="contenedorLoadingPrincipal">
            <div className="loadingPrincipal"></div>
          </div>
        </div>
        :
        <div className="contenedorReportIndividual">
         {viewFilter?
         <FilterContainer
           infoReport={infoReport}
           camposBd={camposBd}
           camposBdArray={camposBdArray}
           setCamposBdArray={setCamposBdArray}
           inpuntBdValues={inpuntBdValues}
           setInpuntBdValues={setInpuntBdValues}
           report={report}
           setReport={setReport}   
           reportFilter={reportFilter}
           setReportFilter={setReportFilter}  
           reload={reload}
           setReload={setReload}
           setDeleteColumns={setDeleteColumns}
           deleteColumns={deleteColumns}     
           inpuntValues     ={inpuntValues} 
           setInpuntValues  ={setInpuntValues}
           columnas = {columnas}
           setColumnas = {setColumnas}
           filas = {filas}
           setFilas = {setFilas}
           camposPivotes={camposPivotes}     
         />:<></>
         }
         {
          viewSquare !== true ? 
          <TableReportIndividual
          report={reportFilter}
           />         
           :
           <SquareReport
           report={reportFilter}
           />           
         }
        
       </div>     
     }  
       
    </>    
  )
}

export default ReportIndividual
