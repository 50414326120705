import { gql } from "@apollo/client";


export const GET_ALL_TIPO_PRESTACIONES = gql`
query GET_ALL_TIPO_PRESTACIONES {
    GET_ALL_TIPO_PRESTACIONES {
      id      
      descripcion
    }
  }
`;


export const GET_USERS_BY_CAMPUS_OR_PROJECT = gql`
  query GET_USERS_BY_CAMPUS_OR_PROJECT($input: filterCompanyArea_Input) {
  GET_USERS_BY_CAMPUS_OR_PROJECT(input: $input) {
    id
    bussinesName
  }
}
`;

export const GET_ALL_TIPO_RESTRICCIONES_PRESTACIONES = gql`
query GET_ALL_TIPO_RESTRICCIONES_PRESTACIONES {
  GET_ALL_TIPO_RESTRICCIONES_PRESTACIONES {
      id      
      descripcion
    }
  }
`;

export const GET_ALL_PRESTACIONES = gql`
query GET_ALL_PRESTACIONES {
  GET_ALL_PRESTACIONES {
    id
    Titulo
    Descripcion
    URL
    fecha
    idTipoPrestacion
    TipoPrestacion
    Activa
    evaluable
    imgDefault
    sedes {
      id
      idPrestacion
      sede
    }
    areas {
      id
      idPrestacion
      Area
    }
    countColaboradores
    countRestricciones
    countComentarios
    promedio
    restricciones {
      id
      salInicial
      salTope
      tipoContrato      
      meses
      idTipoRestriccion
      otro
    }
    usuarios {
      id
      idUsuario
    }
    sedeByCounts {
      campus
      countBySede
    }
    commentBySedeCount {
      campus
      countByComentario
    }
    avgBySedes {
      campus
      promedioSede
    }
  }
}
`;


export const GET_PRESTACION_BY_ID = gql`
query GET_PRESTACION_BY_ID($getPrestacionByIdId: ID!) {
  GET_PRESTACION_BY_ID(id: $getPrestacionByIdId) {
    id
    Titulo
    Descripcion
    URL
    fecha
    idTipoPrestacion
    TipoPrestacion
    Activa
    evaluable
    imgDefault
    sedes {
      id
      idPrestacion
      sede
    }
    areas {
      id
      idPrestacion
      Area
    }
    countColaboradores
    countRestricciones
    countComentarios
    promedio
    restricciones {
      id
      salInicial
      salTope
      tipoContrato
      idTipoRestriccion
      meses
    }
    usuarios {
      id
      idUsuario
    }
  }
}
`;

export const CREATE_PRESTACION = gql`
mutation CREATE_PRESTACION($input: prestacion_Input) {
  CREATE_PRESTACION(input: $input) {
    id
  }
}
`;

export const UPDATE_PRESTACION_STATUS = gql`
mutation UPDATE_PRESTACION_STATUS($id:ID!) {
  UPDATE_PRESTACION_STATUS(id: $id)
}
`;


export const UPDATE_PRESTACION = gql`
mutation UPDATE_PRESTACION($updatePrestacionId: ID!, $input: prestacion_Input) {
  UPDATE_PRESTACION(id: $updatePrestacionId, input: $input)
}

`;



export const DELETE_PRESTACION = gql`
mutation DELETE_PRESTACION($deletePrestacionId: ID!) {
  DELETE_PRESTACION(id: $deletePrestacionId)
}
`;


export const DELETE_RESTRICCION = gql`
mutation DELETE_RESTRICCION($deleteRestriccionId: ID!) {
  DELETE_RESTRICCION(id: $deleteRestriccionId)
}
`;

export const DELETE_IMG_PRESTACION = gql`
mutation DELETE_IMG_PRESTACION($deleteRestriccionId: ID!) {
  DELETE_IMG_PRESTACION(id: $deleteRestriccionId)
}
`;