import {Types} from './OutsorcersNominaContext'

const ModalReducer = (state:any, action:any) =>{
  const {payload, type} = action;
  switch(type){
    case Types.CLEAR_OUTSORCERS:
      return{
        ...state,
        openAutoriza: false,
        openDispersionOutsorcer:false,
        openDescartarOutsorcer:false,
        openSinSeleccion:false, 
        openAceptarRechazado: false,
        openRechazarRechazado: false,
        openDescartaTodas: false
      }
    case Types.OPEN_AUTORIZA:
      return{
        ...state,
        openAutoriza: true
      }
    case Types.OPEN_DISPERSION_OUTSORCERS:
      return{
        ...state,
        openDispersionOutsorcer: true
      }
    case Types.OPEN_DESCARTAR_OUTSORCERS:
     return{
       ...state,
       _id: payload._id,
       openDescartarOutsorcer: true
     }
    case Types.OPEN_SIN_SELECCION:
     return{
       ...state,
       openSinSeleccion: true
     }
    case Types.OPEN_ACEPTAR_RECHAZADO:
      return{
        ...state,
        _id:payload._id,
        openAceptarRechazado: true,
        nombreColaborador: payload.nombreColaborador,
        conceptoDescartado: payload.conceptoDescartado
      }
      case Types.OPEN_RECHAZAR_RECHAZADO:
        return{
          ...state,
          _id:payload._id,
          openRechazarRechazado: true,
          nombreColaborador: payload.nombreColaborador,
          conceptoDescartado: payload.conceptoDescartado
        }
      case Types.OPEN_DESCARTA_TODAS:
        return{
          ...state,
          _id: payload._id,
          openDescartaTodas: true,
          tipo:payload.tipo,
        }
    default:
      return state 
  }
}

export default ModalReducer