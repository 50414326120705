import { useRef, useState } from 'react';
// import NotificationHeader from './NotificationHeader';
import { Button } from '@material-ui/core';
import HelpHeader from './HelpHeader';
import PopperHelp from './PopperHelp';

const MenuHelp = () => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen(!open);
    };
    return (
        <>
            {/* <NotificationHeader /> */}
            {/* <HelpHeader /> */}
            <PopperHelp anchorRef={anchorRef} open={open} setOpen={setOpen} />
        </>
    );
};



export default MenuHelp;