import {createContext, Dispatch} from 'react';

type initialStateType={
  _id: string,
  createMonthlyISR: boolean,
  deleteModal: boolean,
  inProgressPayroll: any[]
};

type ModalPartial = {
    state: initialStateType,
    dispatch: Dispatch<any>
}

export const initialState ={
  _id: '',
  createMonthlyISR: false,
  deleteModal: false,
  inProgressPayroll: []
}

export enum Types{
  CREATE_MONTHLYISR = "CREATE_MONTHLYISR",
  CLEAR_MONTHLYISR = "CLEAR_MONTHLYISR",
  DELETE_MONTHLYISR = "DELETE_MONTHLYISR",
  ADD_IN_PROGRESS_PAYROLL = "ADD_IN_PROGRESS_PAYROLL",
  UPDATE_IN_PROGRESS_PAYROLL = "UPDATE_IN_PROGRESS_PAYROLL",
}

const ModalInitialState = {
  state: initialState,
  dispatch: () => null
}

const MonthlyISRProcessContext = createContext<ModalPartial>(ModalInitialState);
    
export default MonthlyISRProcessContext;