/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from '../../Payroll/Modals/PayrollModals.module.css'
import { useMutation } from "@apollo/client";
import { POST_ACEPTAR_RECHAZO_OUTSOURCERS,
         POST_ACEPTAR_RECHAZO_OUTSOURCERS_EVENTUAL,
         POST_ACEPTAR_RECHAZO_OUTSOURCERS_FINIQUITO } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import OutsorcersNominaContext from "../../../context/OutsorcersNomina/OutsorcersNominaContext";
import { clearOutsorcers } from "../../../context/OutsorcersNomina/Actions";

const AceptarRechazado = (props:any) =>{
  const {totalesDispersar, totales, seleccionados, idRegistro} = props
  const {state, dispatch} = useContext(OutsorcersNominaContext)
  const [activo, setactivo] = useState(false)
  const [opcionSeleccionada, setOpcionSelecciona] = useState('')
  const [activoMensajeDos, setactivoMensajeDos] = useState(false)
  const [mensaje, setMensaje] = useState('Uno')
  const [token, setToken] = useState('')
  const [postAceptaRechazoOutsourcers] = useMutation(POST_ACEPTAR_RECHAZO_OUTSOURCERS,{})
  const [postAceptaRechazoOutsourcersEventual] = useMutation(POST_ACEPTAR_RECHAZO_OUTSOURCERS_EVENTUAL,{})
  const [postAceptaRechazoOutsourcersFiniquito] = useMutation(POST_ACEPTAR_RECHAZO_OUTSOURCERS_FINIQUITO,{})

  const handleClose = () =>{
    clearOutsorcers({}, dispatch)
  }

  const cambioEstado = (estado:any) =>{ 
    setOpcionSelecciona((current) => current = estado)
  }

  const handleChange = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivo(true)
    }else{
        setactivo(false)
    }
  }

  const agregaSeleccionados = async() =>{
    setMensaje((current) => current = 'Dos')
    switch (props?.tipo) {
      case "eventual":
        await  postAceptaRechazoOutsourcersEventual({
          variables:{
            postAceptarRechazoOutsourcersId: state._id
          }
        })
        break;
        case "Finiquito":
          await  postAceptaRechazoOutsourcersFiniquito({
            variables:{
              postAceptarRechazoOutsourcersId: state._id
            }
          })
          break;  
          case "liquidacion":
            await  postAceptaRechazoOutsourcersFiniquito({
              variables:{
                postAceptarRechazoOutsourcersId: state._id
              }
            })
            break;     
      default:
        await postAceptaRechazoOutsourcers({
          variables:{
            postAceptarRechazoOutsourcersId: state._id
          }
        })
        break;
    }
    setactivo(false)
    setactivoMensajeDos((current) => current = false)
    SuccessfulAlert({text: "Registro actualizado"})
    setMensaje((current) => current ='Uno' )
    handleClose()
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.openAceptarRechazado} fullWidth={false} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          {mensaje === 'Tres'?
          null
          :
          <div
          className={styles.cerrarModal}
          onClick={()=> handleClose()}
        >
        </div>
          }

        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}>
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        {mensaje === 'Uno'?
          <>
            <div className={`${styles.em_mensajePrtincipal} ${styles.em_espacioentreTexto}`}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Est&aacute;s por <span className={styles.em_textoNegritas}>ACEPTAR</span> el registro <span className={styles.em_textoNegritas}>DESCARTADO </span> 
            de <span className={styles.em_textoNegritas}>{state.nombreColaborador}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className={`${styles.em_mensajePrtincipal} ${styles.em_espacioentreTexto}`}>
              por el concepto <span className={styles.em_textoNegritas}>{state.conceptoDescartado}</span>, <span className={styles.em_textoNegritas}>No </span>
              podr&aacute;s volver a editar ni autorizar el registro
            </div>
          <div className={`${styles.em_mensajePrtincipal} ${styles.em_espacioentreTexto}`}>
            Escribe CONFIRMAR para continuar
          </div>

          <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
          </div>
          </>
          :null
          }
          {mensaje === 'Dos'?
            <>
              <div className={styles.em_mensajePrtincipal}>
                La solicitud fue enviada, no actualices ni cierres la pantalla
              </div>
              <div className={styles.em_mensajePrtincipal}>
                hasta que el proceso haya terminado.
              </div>
              <div className={styles.contenedorLoadingDispersion}>
                <div className={styles.loading}></div>
              </div>
            </>
          : null
          }
        <div className={styles.em_contenedorBotones}>
              {mensaje === 'Dos'?
              null
              :
              <button
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              }
              {mensaje === 'Uno'?
                (activo === true ?
                  <button 
                    className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                    onClick={()=> agregaSeleccionados()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                  :
                  <button
                    className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                )
                :null
              }
            </div>
      </div>
    </Dialog>
  )
}

export default AceptarRechazado