import {
    Grid
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { styleCollaboratorTab as style } from '../../Prestaciones/css/styleSheet';
import { Dispatch, SetStateAction } from 'react';
import { removeAccents } from '../../../helpers/RemoveAccents';

interface Props<T> {
    initState: Array<T>;
    setState: Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    stateInitial: Array<T>;
    initState1: Array<T>;
    setState1: Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    stateInitial1: Array<T>;
    tabReport?:number;
    label?: string;
    fields?: Array<string>;
    width?: number,   
}

const SearcherTable =<T extends Object>({
    initState,
    setState,
    stateInitial,
    initState1,
    setState1,
    stateInitial1,
    tabReport,
    label = "Buscar",   
    width = 420,    
}: Props<T>) => {

    const handleChange = (e: any) => {

        const tabsReport=[0,1];
        
        if(tabReport === tabsReport[0] ){
           const array=[...stateInitial]   
           if (e !== '') {           
                 const nuevoFiltrado =array.filter((lis: any) => {
                    return (
                        removeAccents(lis.ReportName.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase())) ||
                        removeAccents(lis.ReportDescription.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase())) ||
                        removeAccents(lis.ReportType.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase())) 
                    )
                 })
                 
                 setState(nuevoFiltrado)                        
             }else{
                setState(stateInitial);
            }               
         }
         if(tabReport === tabsReport[1] ){
            const array=[...stateInitial1]   
            if (e !== '') {           
                const nuevoFiltrado =array.filter((lis: any) => {
                    return (
                        removeAccents(lis.ReportName.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase())) ||
                        removeAccents(lis.ReportDescription.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase())) ||
                        removeAccents(lis.ReportType.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase())) 
                    )
                 })
                setState1(nuevoFiltrado)                        
              }else{
                setState1(stateInitial1);
             }               
          }

          //filtrado en los campos, del formulario
          //datasourcers
          if(tabReport === 3){
            
            const array=[...stateInitial]   
            if (e !== '') {           
                    const nuevoFiltrado =array.filter((lis: any) => {
                    return (
                        removeAccents(lis.Name.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase()))                         
                    )
                    })
                    
                    setState(nuevoFiltrado)                        
            }else{
                setState(stateInitial);
            }
          }

          //opciones de tipo fila,columna y pivote
          if(tabReport === 4){

            const array=stateInitial.map(item => ({...item}));
            //console.log("AAAAr", array)   
            if (e !== '') {   
                    
                    array.map((elemento: any) => {
                        elemento.options = elemento.options.filter((obj : any) =>  removeAccents(obj.name.replace(/ /g, "").toUpperCase()).includes(removeAccents(e.replace(/ /g, "").toUpperCase())))
                    })
                    
                    setState(array)                        
            }else{
                setState(stateInitial);
            }
          }  
        
        };        

  return (
    <Grid item>
            <Box >
                <Grid
                    container
                    direction="row"
                    style={{...style.containerSearcher, width: width, background:"white"}}
                >
                    <Grid item >
                        <input
                            style={{...style.inputSearcher, width: (width - 100)}}
                            className="collaboratorTab__searcher"
                            placeholder={label}
                            onChange={(e) => handleChange(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key !== "Escape") {
                                  // Prevents autoselecting item while typing (default Select behaviour)
                                  e.stopPropagation();
                                }
                              }}
                        />
                    </Grid>

                    <Grid item style={{
                        width: "20px",
                        padding: "0 20px 0 40px",
                        color: "#7f7f81",
                        fontStyle: "bold",
                    }}>
                        <img
                            src="/assets/icons/icono-buscar.svg"
                            alt="Buscar"
                        />
                    </Grid>

                </Grid>
            </Box>
        </Grid>
  )
}

export default SearcherTable
