import { useContext, useState } from "react";
import ReportContext from "../../../context/ReportsContext/ReportContext";
import { clearReportsModal } from "../../../context/ReportsContext/Actions"
import {Dialog} from "@material-ui/core"
import styles from '../../Documents/Documents.module.css'
import { useMutation } from "@apollo/client";
import {
  GET_ALL_DATAREPORTS,
  DELETE_DATAREPORT,
  DELETE_CUSTOM_REPORT
  } from "../../../Querys/reports/querys";
import { GET_ALL_CUSTOMREPORTS } from "../../../Querys/querys";

export const DeleteReport = (props: any) =>{
    const {state, dispatch} = useContext(ReportContext)
    const [activo, setactivo] = useState(false)
    //console.log("state de eliminar", state)
    const handleChange = (e: any) =>{ 
        if(e.target.value === 'ELIMINAR'){
            setactivo(true)
        }else{
            setactivo(false)
        }
    }
    const [deleteCustomReport] = useMutation(DELETE_CUSTOM_REPORT, {
      refetchQueries: [
        { query: GET_ALL_CUSTOMREPORTS }
      ]
    });
  //   const [deleteDocument] = useMutation(DELETE_FILES, {
  //     refetchQueries:[{query:GET_ALL_FILES}, {query:GET_FILE, variables:{id:state._id}}],
  // })

  const [deleteDataReport] = useMutation(DELETE_DATAREPORT, {
    refetchQueries: [
        { query: GET_ALL_DATAREPORTS }
    ],
});
  
  const handleClose =async ()=>{
    clearReportsModal(false, dispatch)
}

    const handleDelete = async () =>{
      if(state.report === "Personalizado"){
        await deleteCustomReport({
          variables:{
            deleteCustomReportId: parseInt(state._id)
          }
        })
      }else{
        await deleteDataReport({
          variables:{
            Id:parseInt(state._id)
          }
        });
      } 
      handleClose();   
    }

    return(
        <Dialog aria-labelledby='costumized-dialog-title' open={state.deleteModalReports} fullWidth={false}  onClose={handleClose} maxWidth={"md"}>
        <div className={styles.em_container}>
          <div className={styles.contenedorCerrarModal}>
            <div 
              className={styles.cerrarModal}
              onClick={()=> handleClose()}>
            </div>
          </div>
          <div className={styles.contenedorPrincipal}>
            <div className={styles.iconoEliminar}>
            </div>
            <div className={styles.em_titulo}>
              ¿Est&aacute;s seguro de que quieres eliminar el informe?
            </div>
            <div className={styles.em_mensajePrtincipal}>
                <span>Una vez eliminado no podr&aacute;s recuperar la informaci&oacute;n</span>
                <br />
                <span className={styles.em_textoPrincipal}>Escribe ELIMINAR para confirmar</span>
            </div>
            <div>
              <input 
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        <div className={styles.em_contenedorBotones}>
              <button 
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              {(activo === true ? 
                <button 
                  className={styles.em_botonConfirmarActivo}
                  onClick={()=> handleDelete()}
                  >
                  <div className={styles.em_iconoConfirmarEliminar}></div>
                    <div>
                      Confirmar
                    </div>
                </button>
              :
                <button className={styles.em_botonConfirmarInactivo}>
                  <div className={styles.em_iconoConfirmarEliminar}></div>
                    <div>
                      Confirmar
                    </div>
                </button>
              )}

            </div>
        </Dialog>
    )
}
