import { ChangeEvent, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { Grid } from "@material-ui/core";
import CollaboratorTab from "../components/Collaborators/CollaboratorTab";
import InactiveTab from "../components/Inactives/InactivesTab";
import ModalState from "../context/ModalContext/ModalState";
import DeclinadoTab from "../components/Declinado/DeclinadoTab";
import CandidatesTab from "../components/CandidatesTab/CandidatesTab";
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import "../components/Collaborators/collaborator.css";
import CheckPermission from "../helpers/Administradores/Permissions";
import AddTable from "../components/Collaborators/CollaboratorTab/AddTable";
import { useParams } from "react-router-dom";
import CollaboratorHistoryModal from '../components/Collaborator/History/CollaboratorHistoryModal'

const CollaboratorsScreenBase = () => {
  const [tab, setTab] = useState(0);
  const params = useParams<any>();
  const [id, setId] = useState<string>('');
  const [showModal, setModal] = useState(false);
  
  useEffect(() => {
    let pill = localStorage.getItem("currentPill");
    if (pill) setTab(Number(pill));
    else setTab(0);
  }, []);

  useEffect(() => {
      setId(params.id)
  }, [params]);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
    localStorage.setItem("currentPill", String(newValue));
  };

  const openHistory = () => {
    setModal(true);
  }

  return (
    <div>
      <Box mt={3} ml={5} className="Title">
        Colaboradores 
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <Grid >
            <CustomTabs
              value={tab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              {CheckPermission("Colaboradores.NuevoIngreso") && (
                <CustomTab label="Nuevo Ingreso" value={0} />
              )}
              {CheckPermission("Colaboradores.Colaboradores") && (
                <CustomTab label="Colaboradores" value={1} />
              )}
              {CheckPermission("Colaboradores.Declinados") && (
                <CustomTab label="Declinados" value={2} />
              )}
              {CheckPermission("Colaboradores.Inactivos") && (
                <CustomTab label="Inactivos" value={3} />
              )}
            </CustomTabs>
          </Grid>
          <Grid item md={6} xs={12}>
            {
              (tab === 1 && id && id !== "id") && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  style={{ width: "100%", }}
                >
                  <AddTable func={() => openHistory()} text="Ver l&iacute;nea de tiempo" noIcon={true}/>
                </Box>
              )
            }
          </Grid>
        </Grid>
      </Box> 
      <div className="collaborator__container">
        {(tab === 0 && CheckPermission("Colaboradores.NuevoIngreso")) && <CandidatesTab value={tab} index={0} />}

        {(tab === 1 && CheckPermission("Colaboradores.Colaboradores")) && <CollaboratorTab value={tab} index={1} />}

        {(tab === 2 && CheckPermission("Colaboradores.Declinados")) && <DeclinadoTab value={tab} index={2} />}

        <ModalState>
          {(tab === 3 && CheckPermission("Colaboradores.Inactivos")) && <InactiveTab value={tab} index={3} />}
        </ModalState>
      </div>
      {
        (tab === 1 && id && id !== "id") && (
          <CollaboratorHistoryModal showModal={showModal} setModal={setModal} id={id}></CollaboratorHistoryModal>
        )
      }
    </div>
  );
};

export default CollaboratorsScreenBase;
