
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { GET_TERMINAR_OUTSOURCERS_EVENTUAL } from "../../Querys/querys";
import styles from "../../components/Payroll/PayrollStyles.module.css";
import CustomTab from "../Collaborators/Tab/CustomTabMain";
import CustomTabs from "../Collaborators/Tab/CustomTabs";
import { Graphic } from "./Graphic";
import { server } from "../../global/server";
import { useState } from "react";
import axios from "axios";

const OutsorcersTerminarEventual = () => {
  const { id, tab } = useParams<any>();
  const history = useHistory();

  const {loading:loadingPrincipal, error:errorPrincipal, data:dataPrincipal} = useQuery(GET_TERMINAR_OUTSOURCERS_EVENTUAL,{
    variables:{
      getTerminarOutsourcersId: id,
      }
    })

    const PayrollData = dataPrincipal?.GET_TERMINAR_OUTSOURCERS_EVENTUAL
    const handleChange = () =>{
    }
    
    const porcentaje = PayrollData?.porcentajeOtrosMetodos;

    const [showZip, setShowZip] = useState('0')

    const showZIP = (url: any) =>{
      const link = document.createElement("a");
      link.href = url?.data?.urlPDF;
      link.setAttribute("download", "downloadtesting.PDF");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    const exportarPDF = async (id: any) => {
     setShowZip(id)
      //href={`${server}/report/zipAcceptedDispersionOursourcers/${id}`}
      //const doc: any = await axios.get(`${server}/report/zipAcceptedDispersionOursourcers/${id}`)   
      const url: any = await axios.get(`${server}/report/zipAcceptedDispersionOursourcersDownloadEventual/${id}`)
      setTimeout(() => {
        showZIP(url)
        setShowZip('0')
      }, 10000);
  
      
    }

    return (
      <>
      
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 1 para que se muestre la pestaña de pre-nómina
            value={1}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Outsorcers" value={0} />
            <CustomTab label="Historicas" value={1} />
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}></div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}></div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.to_contenedorTerminarOutsourtcers}>
        <div className={styles.to_contenedorInternoUno}>
          <div className={styles.to_contenedorTitulosInterno}>
            <h1 className={styles.to_alineacionTituloGraficas}>Pagos Autorizados</h1>
            <div className={styles.to_textoInterno}>
            {PayrollData?.Total}
            </div>
          </div>
          <div className={styles.to_contenedorGrafica}>
            <Graphic percentage={porcentaje}/>
          </div>
          <div className={styles.to_contenedorTextoPagos}>
            <div className={styles.to_textoPagos}>Pagos</div>
            <div className={styles.to_textoPagosOtrosMetodos}>Pagos con otro metodo</div>
          </div>
          <div className={styles.to_contenedorTextoPagos}>
            <div className={styles.to_textoPagos}>{PayrollData?.Pagos}</div>
            <div className={styles.to_textoPagosOtrosMetodos}>{PayrollData?.pagosOtrosMetodos}</div>
          </div>
        </div>
        <div className={styles.to_contenedorInternoUno}>
          <div className={styles.to_contenedorTitulosInterno}>
            <h1 className={styles.to_alineacionTituloGraficas}>Cancelados</h1>
            <div className={styles.to_contenedorTextoCancelados}>
              <div className={styles.to_contenedorInternoCancelados}>
                {PayrollData?.Canceladas}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.to_contenedorInternoUno}>
          <div className={styles.to_contenedorTitulosInterno}>
            <h1 className={styles.to_alineacionTituloGraficas}>Descargar</h1>
            <div className={styles.to_contenedorTextoCancelados}>
              <div className={styles.to_contenedorInternoCancelados}>
                {
                  showZip === '0'?
                    <div className={styles.to_botonDescargar}>
                      <div className={styles.to_iconoDescargaOutsourcers}></div>
                      <div>                              
                        <a
                          target="_blank"
                          //href={`${server}/report/zipAcceptedDispersionOursourcers/${id}`}
                          onClick={() => {
                            exportarPDF(id);
                          }}
                        >
                        Descargar
                      </a></div>
                    </div>
                  :
                    <div className={styles.to_botonDescargar}>
                      <div className={styles.to_iconoDescargaOutsourcers}></div>
                      <div>                              
                        <a
                          target="_blank"
                          style={{
                            cursor: 'wait',
                          }}
                        >
                        Descargar
                      </a></div>
                    </div>
                }
               
              </div>
            </div>
          </div>

        </div>
        
      </div>
      <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                onClick={() =>history.push(`/outsorcersdispersioneventual/${id}/${tab}`)}
              >
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Dispersar"</div>
              </div>
                <button className={styles.pd_botonSalir}
                  onClick={() => history.push(`/outsourcers`)}
                >
                  Salir
                </button>
            </div>
          </div>
      </>
    );
}

export default OutsorcersTerminarEventual  ;