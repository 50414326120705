import { useContext, useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent,Grid, FormControl, Select, TextField, Button } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Box from '@mui/material/Box';
import { NewsContext } from '../../../context/NewContext/NewContext';
import { AdminContext } from '../../../context/AdminContext/AdminContext';
import moment from 'moment';
import { ErrorAlert } from '../../../alerts/errorAlert';
import Chip from '@mui/material/Chip';
import IconoBorrarProyecto from '../../../assets/svg/icono_borrar_proyecto.svg';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_NOTICES, GET_ALL_NOTICES, GET_ALL_PROJECT, UPDATE_NOTICES } from '../../../Querys/querys';
import { postFileNoticeAWS } from '../../../services/candidateService';
import { SuccessfulAlert } from '../../../alerts/successAlerts';

const AddNewsModal = () => {
    const [projects, setProjects] = useState([]);
    const [projectsSelected, setProjectsSelected] = useState<Array<any>>([]);
    const { state, dispatch } = useContext(NewsContext);
    const { adminState } = useContext(AdminContext);

    const [getProjects, { data: resultProjects }] = useLazyQuery(GET_ALL_PROJECT,{
        fetchPolicy: 'no-cache'
    });

    const [createNotice] = useMutation(CREATE_NOTICES);

    const [updateNotice] = useMutation(UPDATE_NOTICES, {
        refetchQueries: [{ query: GET_ALL_NOTICES }],
    });

    const inputRef:any = useRef(null);

    useEffect(()=>{
        if(state.isOpen){
            getProjects();
        }
    }, [state.isOpen]);

    useEffect(()=> {
        if(resultProjects && resultProjects.GET_ALL_PROJECT){
            setProjects(resultProjects.GET_ALL_PROJECT);
        }
        if (state._id && state.projects) {
            setProjectsSelected(state.projects.split(','));
        }
    }, [resultProjects, state]);

    const onChange = (e: any) => {
        dispatch({ type: "UPDATE_NEW", payload: { key: e.target.name, value: e.target.value } });
    };

    const onSumbit = async () => {
        let myData = {
            ...state,
            projects: projectsSelected.join(','),
            AutorImg: adminState?.image,
            Fecha: state.Fecha ? state.Fecha : moment().format(),
        }

        if (myData.titulo === '') return ErrorAlert({ text: "Ingrese un título." });
        if (myData.BodyHTML.replace(/<(.|\n)*?>/g, '').trim().length === 0) return ErrorAlert({ text: "Ingrese descripción." });
        if (myData.projects === '') return ErrorAlert({ text: "Ingrese al menos un proyecto." });
        // if (!state.ImageUri && myData.File === '') return ErrorAlert({ text: "Ingrese imagen." });
        if (myData.scheduleDate !== '' && myData.scheduled && moment(myData.scheduleDate).isBefore(moment())) return ErrorAlert({ text: "Ingrese fecha mayor a la actual." });

        let variables = {
            autor: adminState?.Nombre,
            idUsuario: adminState?.id,
            tittle: myData.titulo,
            Descripcion: myData.BodyHTML,
            fecha: myData.scheduleDate,
            projects: myData.projects,
            Archivo: state.ImageUri ? state.ImageUri : myData.Archivo
        }

        if(state._id){
            try{
                if(myData.File){
                    const url = await postFileNoticeAWS(myData.File, state._id, "IMG_NOTICE", "IMG_NOTICE", false);
                    variables.Archivo = url.URL;
                }
                await updateNotice({
                    variables: {
                        updateNoticesId: state._id,
                        input: variables
                    }
                });
                await SuccessfulAlert({ text: "Noticia actualizada correctamente." });
                setProjectsSelected([]);
                dispatch({ type: "CLOSE_MODAL" });
            }catch(e){
                ErrorAlert({text:'Ocurrió un error al actualizar la noticia'});
            }
        }else{
            try{
                if (myData.File === '') {
                    let { data } = await createNotice({
                        variables: {
                            input: variables
                        }
                    });
                } else {
                    let { data } = await createNotice({
                        variables: {
                            input: variables
                        }
                    });
                    const url = await postFileNoticeAWS(myData.File, data.CREATE_NOTICES.id, "IMG_NOTICE", "IMG_NOTICE", false);
                    variables.Archivo = url.URL;
                    await updateNotice({
                        variables: {
                            updateNoticesId: data.CREATE_NOTICES.id,
                            input: variables
                        }
                    });
                }
                await SuccessfulAlert({ text: "Noticia creada correctamente." });
                setProjectsSelected([]);
                dispatch({ type: "CLOSE_MODAL" });
            }catch(e){
                ErrorAlert({text:'Ocurrió un error al crear la noticia'});
            }
        }
    }

    const onChangeProjects = (e: any) => {
        if (e.target.value === "All") {
            setProjectsSelected(projects.map(({ proyectName }: any) => proyectName))
        } else {
            if (!projectsSelected.includes(e.target.value)) {
                setProjectsSelected((oldList) => [...oldList, e.target.value]);
            }
        }
    }

    const onChangeImageClose = (e: any) => {
        let bikeImage = document.getElementById("imgNoticia") as HTMLImageElement;
        bikeImage.src = "../assets/svg/icono-cargar-imagen-noticia.svg";
        inputRef.current.value = null;
        dispatch({ type: "DELETE_FILE" });
    }

    const onDeleteProject = (project: any) => {
        let index = projectsSelected.indexOf(project);
        let temp = [...projectsSelected];
        temp.splice(index, 1);
        setProjectsSelected(temp);
    }

    const onChangeImage = (e: any) => {
        let bikeImage = document.getElementById("imgNoticia") as HTMLImageElement;
        bikeImage.src = "../assets/svg/icono-imagen-noticia-cargada.svg";
        dispatch({ type: "UPDATE_FILE", payload: { value: e.target.files[0] } });
    }

    const closeModal = () => {
        setProjects([]);
        setProjectsSelected([]);
        dispatch({ type: "CLOSE_MODAL" });
    }

    return (
        <Dialog aria-labelledby="customized-dialog-title" open={state.isOpen} fullWidth={true} maxWidth="md">

            <h2 id="form-dialog-title" className="text-center" style={{ marginTop: '20px' }}>{!state._id ? "Crear nueva" : "Editar"} noticia</h2>

            <DialogContent>
                <span className="text-closemodalx" onClick={closeModal}><img src="assets/svg/CloseM.svg" /></span>
                <Box display="flex" flexDirection="column">
                    <Grid direction="row" container justify="center" spacing={2}>
                        <Grid xs item>
                            <Grid direction="row" container justify="center" alignItems="center">
                                <Grid item xs>

                                    <div>
                                        <TextField
                                            name="titulo"
                                            type="text"
                                            fullWidth={true}
                                            size="small"
                                            onChange={(e) => onChange(e)}
                                            label="Título"
                                            value={state.titulo}
                                            variant="outlined"
                                        />
                                    </div>

                                </Grid>
                            </Grid>

                            <Box mt={2}>
                                <Grid direction="row" container justify="center" alignItems="center">
                                    <Grid item xs>
                                        <FormControl fullWidth={true}>
                                            <span className="sheduledDate__Question">Descripción</span>
                                            {/* <ReactQuill
                                                value={state.BodyHTML}
                                                placeholder="Ingresa tu noticia seeker!"
                                                onChange={(value) => dispatch({ type: "UPDATE_NEW", payload: { key: "BodyHTML", value } })}
                                            /> */}
                                            <TextField
                                                id="standard-multiline-static"
                                                multiline
                                                name="BodyHTML"
                                                rows={5}
                                                value={state.BodyHTML}
                                                placeholder='¡Envía una noticia a tu organización y conecta con tu equipo!'
                                                onChange={(e) => onChange(e)}
                                                variant="outlined"
                                            />

                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box mt={5} mb={5}>
                                <Grid direction="row" container justify="center" alignItems="center">
                                    <Grid xs container item justify="flex-start" style={{ marginLeft: '10px', fontWeight: 'bold' }} alignItems="flex-start">
                                        Subir imagen de la noticia
                                    </Grid>
                                    <Grid>
                                        <label className="custom-file-upload-image">
                                            <input ref={inputRef} type="file" name="Image" disabled={state.File ? true : false} onChange={(e) => onChangeImage(e)} accept=".png,.jpg,.jpeg,.svg" />
                                            <img id="imgNoticia" src="assets/svg/icono-cargar-imagen-noticia.svg" alt="Notica" height="100" />
                                            {
                                                state.File ? <span onClick={() => dispatch({ type: "DELETE_FILE" })}></span> : null
                                            }
                                        </label>
                                        {
                                            state.File ? <span className="text-cancel-file" onClick={(e) => onChangeImageClose(e)}>x</span> : null
                                        }
                                    </Grid>
                                </Grid>
                                {
                                    state.ImageUri && (
                                        <div className="current-image-news">
                                            <Grid direction="row" container justify="center" alignItems="center">
                                                <Grid xs container item justify="flex-start" style={{ marginLeft: '10px', fontWeight: 'bold' }} alignItems="flex-start">
                                                    Imagen actual
                                                </Grid>
                                                <Grid>
                                                    <img id="imgNoticia" src={state.ImageUri} alt="Notica" height="100" />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                }
                            </Box>
                        </Grid>

                        <Grid xs item>
                            <Box>
                                <Grid direction="row" container>
                                    <Grid xs item>
                                        <FormControl fullWidth={true}>
                                            <span className="sheduledDate__Question">¿Deseas programar la noticia?</span>
                                            <TextField
                                                id="datetime-local"
                                                name=""
                                                placeholder="Seleccione fecha y hora"
                                                type={state.scheduled ? 'datetime-local' : state.scheduleDate !== '' ? 'datetime-local' : 'text'}
                                                value={state.scheduled ? state.scheduleDate !== '' ? moment(state.scheduleDate).format('YYYY-MM-DDTHH:mm') : '' : ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onFocus={(e) => dispatch({ type: "UPDATE_SCHEDULEDATE", payload: { value: e.target.value } })}
                                                onChange={(e) => dispatch({ type: "UPDATE_SCHEDULEDATE", payload: { value: e.target.value } })}
                                                disabled={state._id ? true : false}
                                                variant="outlined"
                                                size="small"

                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* {
                                        state.scheduled ? (
                                            <Grid xs={1} item>
                                                <FormControl fullWidth={true}>
                                                    <Box pt={4}>
                                                        <EventBusyIcon fontSize="small"  color="error" onClick={() => dispatch({ type: "DELETE_SCHEDULEDATE" })} />
                                                    </Box>
                                                </FormControl>
                                            </Grid>
                                        ) : null
                                    } */}
                                </Grid>
                                <span className="warning__date">Si no eliges fecha, la noticia se envirá al instante*</span>
                            </Box>

                            <Box mt={5}>
                                <FormControl fullWidth={true} size="small">
                                    <span className="sheduledDate__Question">Áreas/Proyectos a compartir</span>
                                    <Select
                                        native
                                        value={""}
                                        onChange={(e) => onChangeProjects(e)}
                                        name="proyecto"
                                        variant="outlined"
                                    >
                                        <option value={""}></option>
                                        <option value={"All"}>Seleccionar todos</option>
                                        {
                                            projects.map(({ proyectName }: any, index: number) => (
                                                <option key={index} value={proyectName}>{proyectName}</option>
                                            ))
                                        }

                                    </Select>
                                    <ul className="listProjectsSelected">
                                        {
                                            projectsSelected.map((item: string, index:number) => (
                                                <Chip key={index} className="chip-proyecto" color="warning" label={item} deleteIcon={
                                                    <img src={IconoBorrarProyecto} alt="Delete icon" />
                                                } onDelete={() => onDeleteProject(item)} />
                                            ))
                                        }
                                    </ul>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <MuiDialogActions>
                <button className="btnCancel" onClick={closeModal}>
                    Cerrar
                </button>
                <button className="btnSave" onClick={onSumbit}>
                    <img src="/assets/svg/icono-noticia.svg" alt="Noticia" style={{ marginRight: "5px" }} />
                    {!state._id ? "Crear" : "Editar"} Noticia
                </button>
            </MuiDialogActions>
        </Dialog>
    )
}

export default AddNewsModal
