import * as React from 'react';
import { useMutation,useLazyQuery } from '@apollo/client';
import { GET_OBJECTIVE_EVIDENCE,
        DELETE_OBJECTIVE_EVIDENCE,
       } from '../../../Querys/querys';
import ObjectivesContext  from "../../../context/ObjectiveContext/ObjectiveContext";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import moment from 'moment';
import Minus from "../../../assets/svg/minus.svg";
import { formatter } from "../../../helpers/formatoMoneda";
import styles from '../Objectives.module.css';

function TablePaginationActions(props:any) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event:any) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event:any) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event:any) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event:any) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  TablePaginationActions.propTypes = {
    //count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    //rowsPerPage: PropTypes.number.isRequired,
  };

  const TableEvidences = ({data,setDisabledBtn,setViewLoad,
                           arrayEvidence,setArrayEvidence,
                           setSumPercentajeEvindence
                        }:any) => {
    const [page, setPage] = React.useState(0);
    const { state, dispatch } = React.useContext(ObjectivesContext);
    const [rowsPerPage, setRowsPerPage] = React.useState(2);
    const [objectivesEvidence] = useLazyQuery(GET_OBJECTIVE_EVIDENCE, {
		fetchPolicy: 'no-cache',
		onCompleted({ GET_OBJECTIVE_EVIDENCE }) {           
      if(GET_OBJECTIVE_EVIDENCE.length>0){
        setArrayEvidence(GET_OBJECTIVE_EVIDENCE)      
        let sumPercentaje =arrayEvidence.reduce((acum :any , result: any) => acum + result.result, 0);       
        setSumPercentajeEvindence(sumPercentaje)
        setViewLoad(false)
      }	else{
        setArrayEvidence([])      
        setSumPercentajeEvindence(0)
        setViewLoad(false)
      }		
		},
	});
    const [deleteObjective] = useMutation(DELETE_OBJECTIVE_EVIDENCE,{
        fetchPolicy: 'no-cache',
            onCompleted({ DELETE_OBJECTIVE_EVIDENCE }) {
          console.log(DELETE_OBJECTIVE_EVIDENCE)
          if(DELETE_OBJECTIVE_EVIDENCE ==="Campaign Objective Deleted"){
            objectivesEvidence({ 
              variables: {
                input: {
                  id: parseInt(state?.selectedObjective?.id as any, 10),
                }
              }})
          }      
        }
      }); 
      

  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  
    const handleChangePage = (event:any, newPage:any) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event:any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const eliminarEvidencia = async (id:any)=>{
        setDisabledBtn(false)
        setViewLoad(true) 
        await deleteObjective({
          variables: {
            input: {
              id: parseInt(id, 10)
            }
          }
        });       
      }
    

    const getFileType = (fileName: string) => {
        if (fileName.includes('.pdf')) return '/assets/icons/icon-pdf.svg'
        if (fileName.includes('.jpg')) return '/assets/icons/icon-jpg.svg'
        if (fileName.includes('.jpeg')) return '/assets/icons/icon-jpg.svg'
        if (fileName.includes('.png')) return '/assets/icons/icon-png.svg'
        if (fileName.includes('.mp4')) return '/assets/icons/icon-mp4.svg'
        if (fileName.includes('.zip')) return '/assets/icons/icon-zip.svg'
        if (fileName.includes('.docx')) return '/assets/icons/icon-doc.svg'
        if (fileName.includes('.xlsx')) return '/assets/icons/icon-xlsx.svg'
        return '/assets/icons/icon-pdf.svg'
      }
    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }}>
        <TableHead>
            <TableRow className='pd_contenedorTitulos'>
            <TableCell align="left">Documento</TableCell>
            <TableCell align="left">Fecha</TableCell>
            <TableCell align="left">Archivo</TableCell>
            <TableCell align="left">Progreso</TableCell>
            <TableCell align="left">Porcentaje</TableCell>
            <TableCell align="left"></TableCell>           
          </TableRow>
        </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((evidence:any) => (
                <TableRow
                key={evidence.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >              
                <TableCell  style={{ width: 160 }} className={styles.TableCellName} align="left">
                  {evidence.name}
                </TableCell>
                <TableCell style={{ width: 160 }} align="left">
                  {moment(parseInt(evidence.date, 10)).format("DD/MM/YY")}
                </TableCell>
                <TableCell align="center"  className={styles.fileDown} onClick={() => window.open(evidence.URL, "blank")}>
                <img src={getFileType(evidence.URL)} alt="Archivo" className={styles.evidenceTableImage} />
                </TableCell>
                {
                    evidence.metricIndicator === 'pesos'?
                    <TableCell align="left">{formatter(evidence.progress)} </TableCell>:
                    evidence.metricIndicator === 'porcentaje'?
                    <TableCell align="left">{evidence.progress} %</TableCell> :
                    <TableCell align="left">{evidence.progress} </TableCell>
                 }
                <TableCell align="left">{evidence.result   === null? 0 : evidence.result} %</TableCell>
                { state.campaign?.step === 'evaluar'?
                <TableCell align="center"></TableCell>: 
                <TableCell align="center">
                        <div 
                            className={styles.delEvidence}
                            onClick={()=>{
                            eliminarEvidencia(evidence.id);                                                        
                        }} >                                             
                       <img  src={Minus} alt="minus"  />
                       </div>
             </TableCell>
            } 
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
               <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'Todas', value: -1 }]}
               colSpan={6}               
               count={data.length}
               rowsPerPage={rowsPerPage}
               page={page}              
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
               ActionsComponent={TablePaginationActions}
               labelRowsPerPage=''
               sx={{
                 '& .MuiSelect-select':{
                   display: 'none !important'
                 },
                 '& > div.MuiToolbar-root > div.MuiInputBase-root > svg':{
                   display: 'none !important'
                 }
               }}
              /> 
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
  )
}

export default TableEvidences
