import {Types} from './MonthlyISRProcessContext'


const ModalReducer  = (state:any, action: any) =>{
  const {payload, type} = action;
  switch(type){
    case Types.CREATE_MONTHLYISR:
      return{
        ...state,
        createMonthlyISR: payload.createMonthlyISR
      }
    case Types.CLEAR_MONTHLYISR:
    return{
      ...state,
      _id: '',
      createMonthlyISR: false,
      deleteModal: false
    }
    case Types.DELETE_MONTHLYISR:
      return{
        ...state,
        _id: payload._id,
        deleteModal: true
      }
      case Types.ADD_IN_PROGRESS_PAYROLL:
        return {
          ...state,
          inProgressPayroll: [...payload, ...state.inProgressPayroll]
        }
        case Types.UPDATE_IN_PROGRESS_PAYROLL:
          return {
            ...state,
            inProgressPayroll: [...payload]
          }
    default:
      return state 
  }
}

export default ModalReducer;