
const Excel = require("exceljs");

export const CreateExcelFacturacionPagos = (data: any) => {
  console.log('Estos son los datos', data)
  const workbook = new Excel.Workbook();
  const ReporteDispersion= workbook.addWorksheet("Reporte Colaboradores Mensual");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };
  const headerFont = {
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteDispersion.columns = [
    { header: "Nombre", key: "Nombre", width },
    { header: "Apellido Paterno", key: "ApellidoP", width },
    { header: "Apellido Materno", key: "ApellidoM", width },
    { header: "Fecha", key: "Fecha", width },
  ];

  let letter = 65;
  ReporteDispersion.columns.forEach(() => {
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteDispersion.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {
    // let name = item?.Colaborador.toUpperCase() || "";

    ReporteDispersion.addRow({
      Nombre: item.name,
      ApellidoP: item.firstName,
      ApellidoM: item.lastName,
      Fecha: item.fechaIngreso    
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'DeslgoseMensualUsuarios.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};