import { useEffect, useContext, useReducer, useCallback, useState} from "react";
import '../Payroll/Payroll.css'
import { useToggle } from '../../hooks/useToggle';
import { TabMonthlyISRContext } from '../../context/PayrollContext/TabMonthlyISRContext';
import CrearMonthlyISRsModal from "./Modals/CrearMonthlyISRsModal";
import { tabMonthlyISRReducer } from "../../context/PayrollContext/TabMonthlyISRReducer"
import { clearMonthlyISRProcess, createMonthlyISRModal } from "../../context/PayrollProcess/MonthlyISRActions";
import MonthlyISRProcessContext from "../../context/PayrollProcess/MonthlyISRProcessContext";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
};

export const NuevaMonthlyISR = (props: TabPanelProps) => {
    const [MonthlyISRState, MonthlyISRDispatch,] = useReducer(tabMonthlyISRReducer, { loading: true, MonthlyISRs: [], MonthlyISRFilter: [] });
    const [inactiveOpen, setInactiveOpen] = useToggle(false);
    const [addMonthlyISROpen, setAddMonthlyISROpen] = useToggle(false);
    const [save, setSave] = useState(true);

    const obtenerDatos = useCallback(async () => {
        clearMonthlyISRProcess({}, payrollDispatch);
    },[]);

    const {dispatch: payrollDispatch} = useContext(MonthlyISRProcessContext) ;

    useEffect(()=>{
        obtenerDatos();
    },[obtenerDatos])


    const createModal = () =>{
        createMonthlyISRModal({createMonthlyISR: true}, payrollDispatch)
    }

    return (
        <div className="MsjNuevaPrenomina">
            <div className="Title">
            Comienza a calcular tu ISR mensual,
            <p className="continueparagraph">nosotros te ayudamos</p>
            </div>
            <p> Antes de iniciar, crea tus grupos de nómina en las
            <b><a href="https://google.com" className="MsjNuevaPrenominaLink"> Configuraciones</a></b>
            </p>
            <p>
            Si no sabes cómo hacer tu calculo de ISR <b><a href="https://google.com" className="MsjNuevaPrenominaLink"> Aprende fácil</a></b>
            </p>
                <TabMonthlyISRContext.Provider value={{
                    inactiveOpen,
                    setInactiveOpen,
                    addMonthlyISROpen,
                    setAddMonthlyISROpen,
                    MonthlyISRState,
                    MonthlyISRDispatch
                }}>
                        <button onClick={()=> createModal()} className="botonNuevaPrenomina">
                            <div className='alineacionBoton'>
                                <img src='/assets/icons/add_road_black_24dp.svg' alt="" />
                                <div className="nombrePuesto">
                                    <b><span>Nueva calculo ISR Mensual</span></b>
                                </div>
                            </div>
                        </button>
                    <CrearMonthlyISRsModal getDatos={obtenerDatos} save={save} setSave={setSave}/>
                </TabMonthlyISRContext.Provider>
        </div>
    );
    
    }
    




