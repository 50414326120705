import { NuevaMonthlyISR } from "./NuevaMonthlyISR";
import MonthlyISRTable from "./MonthlyISRTable";
import "../Payroll/Payroll.css";
import { useQuery } from "@apollo/client";
import { GET_ALL_MONTHLYISR } from "../../Querys/querys";
import { useEffect, useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const MonthlyISRTab = (props: TabPanelProps) => {
  const [isPayRoll, setIsPayRoll] = useState(false);
  const { data: resultPayroll } = useQuery(GET_ALL_MONTHLYISR);
  const allPayroll = resultPayroll?.GET_ALL_MONTHLYISR;

  useEffect(() => {
    if (allPayroll || allPayroll === 0) {
      setIsPayRoll(true);
    }
  }, [allPayroll]);

  return (
    <>
      {
        (!isPayRoll) ? (
          <NuevaMonthlyISR {...props} />
        ) : (
          <MonthlyISRTable {...props} />
        )
      }
    </>
  )
};

export default MonthlyISRTab;
