import { Dispatch } from "react";
import {Types} from "./MonthlyISRProcessContext"

export const createMonthlyISRModal = ({_id = "", createMonthlyISR = false}:any, dispatch: Dispatch<any>): void=>{
  dispatch({
    type: Types.CREATE_MONTHLYISR,
    payload:{
      createMonthlyISR
    }
  })
}

export const clearMonthlyISRProcess = ({_id = "", createMonthlyISR = false, deleteModal = false }:any, dispatch: Dispatch<any>): void=>{
  dispatch({
    type: Types.CLEAR_MONTHLYISR,
    payload:{
      _id,
      createMonthlyISR,
      deleteModal
    }
  })
}


export const deleteMonthlyISRProcess= ({_id = "", deleteModal = true}:any, dispatch: Dispatch<any>): void=>{
  console.log('Adios',_id)
  dispatch({
    type: Types.DELETE_MONTHLYISR,
    payload:{
      _id,
      deleteModal
    }
  })
}
