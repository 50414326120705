import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import {
    Grid
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { styleCollaboratorTab as style } from './styleSheet';
import { removeAccents } from '../../../helpers/RemoveAccents';


interface Props<T> {
    initState: Array<T>;
    setState: Dispatch<SetStateAction<T[]>> | ((a: T[]) => void);
    label?: string;
    fields?: Array<string>;
    width?: number;
    customSearch?: any;
}

const SearcherTable = <T extends Object>(
    { 
        initState,
        setState,
        label = "Buscar",
        fields = [],
        width = 420,
        customSearch
    }: Props<T>
) => {

    const [text, setText] = useState('');

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (customSearch) {
            const text = e.target.value;
            setText(text);
            customSearch(text);
            return;
        }
        
        const text = e.target.value;
        let dataFiltered;
        setText(text);
        if (text === "") {
            dataFiltered = initState;

        } else {
           
            dataFiltered = initState.filter((e) => {
                const value: { [unit: string]: any } = e;
                
                let itemData = ``;
                if (fields.length > 0) {
                    fields.forEach((field) => {
                        if(value[field] && typeof value[field] == 'string'){
                            itemData += `${removeAccents(value[field].toUpperCase())} `;
                        }
                    });
                } else {
                    const fieldsElement = Object.keys(e);
                    fieldsElement.forEach((field) => {
                        if(value[field] && typeof value[field] == 'string'){
                            itemData += `${removeAccents(value[field].toUpperCase())} `;
                        }
                    });
                }
                
                return itemData.indexOf(text.toUpperCase()) > -1;
            });
        }
        setState([...dataFiltered]);

    };

    return (
        <Grid item>
            <Box >

                <Grid
                    container
                    direction="row"
                    style={{...style.containerSearcher, width: width}}
                >

                    <Grid item >
                        <input
                            style={{...style.inputSearcher, width: (width - 100)}}
                            className="collaboratorTab__searcher"
                            placeholder={label}
                            value={text}
                            onChange={(e) => handleChange(e)}

                        />
                    </Grid>

                    <Grid item style={{
                        width: "20px",
                        padding: "0 20px 0 40px",
                        color: "#7f7f81",
                        fontStyle: "bold",
                    }}>
                        <img
                            src="/assets/icons/icono-buscar.svg"
                            alt="Buscar"
                        />
                    </Grid>

                </Grid>
            </Box>
        </Grid>

    )
};

export default SearcherTable
