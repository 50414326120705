/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import CustomTabs from '../components/Collaborators/Tab/CustomTabs';
import CustomTab from '../components/Collaborators/Tab/CustomTabMain';
import ObjectivesTable from '../components/Goals/ObjectivesTable/ObjectivesTable';
import AddTable from './Colaboradores/Colaboradores/CollaboratorTab/AddTable';
import CreateObjectiveModal from '../components/Goals/Modals/CreateObjectiveModal';
import ObjectivesContext, { Types } from '../context/ObjectiveContext/ObjectiveContext';
import AddEvidenceModal from '../components/Goals/Modals/AddEvidenceModal';
import QualifyEvidenceModal from '../components/Goals/Modals/QualifyEvidenceModal';
import InfoBondColaborator from '../components/Goals/Modals/InfoBondColaborator';
import SelectCollaborators from '../components/Goals/Modals/SelectCollaborators';
import CreateIncidentBondKpi from '../components/Goals/Modals/CreateIncidentBondKpi';
import { APPROVE_OBJECTIVES,
        UPDATE_OBJECTIVES, 
        GET_CAMPAIGN_BY_ID,
        GET_CAMPAIGN_BY_ID_LEADER, 
        NOTIFY_LEADER,
        VERIFY_APROVE_OBJECTIVES } 
        from '../Querys/campaigns/querys';
import { VERIFY_TOKEN_OBJECTIVES } from '../Querys/querys';
import { AdminContext } from '../context/AdminContext/AdminContext';
import CampaignUsersTable from '../components/Goals/CampaignUsersTable/CampaignUsersTable';
import FiltroEstado from '../components/Campaigns/components/FiltroEstado';
import { ExcelBondsKpis } from '../components/Goals/CampaignUsersTable/ExcelBondsKpis';
import Header from '../components/Header/Header';
import { SuccessfulAlert } from '../alerts/successAlerts';
import '../components/Team/Team.css';
import styles from '../components/Goals/Objectives.module.css';
import { errorAlert2 } from "../alerts/errorAlert2";
import { ErrorAlert } from "../alerts/errorAlert";
import { formatter } from "../helpers/formatoMoneda"


const ObjectivesScreen = () => {
  const { campaign: campaignIdFromUrl, id, user,token } =
    useParams<{ campaign: string; user: string; token: string; id: string }>();
  const history = useHistory();
  const { state, dispatch } = useContext(ObjectivesContext);
  const { adminState } = useContext(AdminContext);
  const [verifyTokenQuery] = useLazyQuery(VERIFY_TOKEN_OBJECTIVES, { fetchPolicy: 'no-cache' });
  const [getCampaignById] = useLazyQuery(GET_CAMPAIGN_BY_ID, { fetchPolicy: 'no-cache' });  
  const [getCampaignByIdLeader] = useLazyQuery(GET_CAMPAIGN_BY_ID_LEADER, { fetchPolicy: 'no-cache' });    
  const [approveObjective] = useMutation(APPROVE_OBJECTIVES);
  const [updateObjective] = useMutation(UPDATE_OBJECTIVES);
  const [verifyTotalAprove] = useMutation(VERIFY_APROVE_OBJECTIVES);
  const [notifyLeaderMutation] = useMutation(NOTIFY_LEADER);
  //nconst [leaderNotificationSent, setLeaderNotificationSent] = useState(false);
  const [weightObjectives, setWeightObjectives] = useState(0);
  const [maxObj, setMaxObj] = useState(0);
  const [maxObjInd, setMaxObjInd] = useState(0);
  const [addColObj, setAddColObj] = useState(0);
  const [isObjectivesApproved, setIsObjectivesApproved] = useState(false);
  const [estado, setEstado] = useState('definir')
  const [disButton,setDisButton] = useState(false)
  const [leaderNotified,setLeaderNotified] = useState(false)
  const [btnAproveObjectives , setBtnAproveObjectives] =  useState(false)
  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [objectivesListFilter, setObjectivesListFilter] = useState<any[]>([])
  const [infoReport, setInfoReport] = useState<any[]>([])
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [arrayIncidEnt, setArrayIncident] = useState([])
  const [countCalification,setCountCalification ] = useState(0);
  const [qualification,setQualification ] = useState(0);
  const verifyToken = async () => {
    try {
       const result = await verifyTokenQuery({
        variables: {
          campaignId: parseInt(campaignIdFromUrl, 10),
          userId: parseInt(user, 10),
          token
        },
       });       
       
       if(result.data?.VERIFY_TOKEN_OBJECTIVES === "false")       
      {
        setDisButton(true)
        return errorAlert2({ text: 'Lo sentimos, la campaña a la que intentas acceder ya no está activa. Por favor, comunúcate con el administrador o líder correspondiente para obtener más información o para verificar si hay nuevas campañas disponibles. Gracias por tu comprensión.' });  
      }      
    } catch (error: any) {
      return errorAlert2({ text: 'Lo sentimos, la campaña a la que intentas acceder ya no está activa. Por favor, comunúcate con el administrador o líder correspondiente para obtener más información o para verificar si hay nuevas campañas disponibles. Gracias por tu comprensión.' });
    }
  }
 
  useEffect(() => {
    if (campaignIdFromUrl && user && token && state.rol === "collaborator") {
      verifyToken()  
    }
  }, [campaignIdFromUrl, token, user])

  const getCampaignInfo = async () => {
    try {      
     const { data } = await getCampaignById({
      variables: {
        id: id,      
      },      
    })
    const campaignInfo = data?.GET_CAMPAIGN_BY_ID?.[0];    
    setEstado(campaignInfo?.step || 'definir');   
     if (campaignInfo?.id) {      
      dispatch({ type: Types.SET_CAMPAIGN_INFO, payload: campaignInfo })
      dispatch({ type: Types.SET_CAMPAIGN_INFO, payload: campaignInfo })      
      if (state.rol === 'collaborator') {
        dispatch({ type: Types.SET_ACTIVE_TABLE, payload: 'collaborator' })
      } else {
        dispatch({ type: Types.SET_ACTIVE_TABLE, payload: 'campaign' })
      }
    }
  } catch (error: any) {    
        if (error === 'Error at verifiying token') {     
        ErrorAlert({ text: 'Lo sentimos, la campaña a la que intentas acceder ya no está activa. Por favor, comunicate con el administrador o líder correspondiente para obtener más información o para verificar si hay nuevas campañas disponibles. Gracias por tu comprensión.' });
      }
  }
  }


  const getCampaignInfoLeader = async () => {
    console.log('ejecutar query del lider')
    try {      
     const { data } = await getCampaignByIdLeader({
      variables: {
        idLeader: user, 
        idCampaign:id     
      },      
    })
    const campaignInfo = data?.GET_CAMPAIGN_BY_ID_LEADER?.[0];    
     setEstado(campaignInfo?.step || 'definir');   
     if (campaignInfo?.id) {      
      dispatch({ type: Types.SET_CAMPAIGN_INFO, payload: campaignInfo })
      dispatch({ type: Types.SET_CAMPAIGN_INFO, payload: campaignInfo })      
      dispatch({ type: Types.SET_ACTIVE_TABLE, payload: 'campaign' })      
    }
  } catch (error: any) {    
        if (error === 'Error at verifiying token') {     
        ErrorAlert({ text: 'Lo sentimos, la campaña a la que intentas acceder ya no está activa. Por favor, comunicate con el administrador o líder correspondiente para obtener más información o para verificar si hay nuevas campañas disponibles. Gracias por tu comprensión.' });
      }
 }
  }

  useEffect(() => {  
    if (id) {
      dispatch({ type: Types.SET_CAMPAIGN_ID, payload: parseInt(id, 10) })
      dispatch({ type: Types.SET_COLLABORATOR_ID,  payload: parseInt(user, 10) })      
      if(state.rol !== 'leader'){
       getCampaignInfo()
      }
      else{
        getCampaignInfoLeader()
      }
    }
  }, [id]);

  useEffect(() => {     
   if (state?.campaign) {    
    if(state?.campaign?.collaborators){
      if(state?.campaign?.collaborators?.length>0){
       const op= (state?.campaign?.collaborators.length * state?.campaign?.maxObjectives)
       setMaxObj(op)
       setMaxObjInd(state?.campaign?.maxObjectives)  
      }
    }
   }
  }, [state]);
 

  useEffect(() => {
    if (adminState?.id && state.rol !== "collaborator") {
      dispatch({ type: Types.SET_COLLABORATOR_ID, payload: parseInt(adminState?.id, 10) })
    }
  }, [adminState]);

  const openCreateModal = () => {
    dispatch({ type: Types.CREATE_MODAL })
  }

  const setActiveTable = (table: string) => {
    dispatch({ type: Types.SET_ACTIVE_TABLE, payload: table })
  }

  useEffect(() => {
    return () => {
      dispatch({ type: Types.CLEAR_OBJECTIVE_STATE });
    }
  }, []);

  const approveObjectives = async () => {
     
    if(isCheck.length >0){
      isCheck.map(async(id:any)=>{
        await approveObjective({
            variables: {
            input: {
              campaignId: parseInt(state.campaign?.id as string, 10),
              collaboratorId: state.collaboratorId,
              objectiveId:parseInt(id)
            }
          }
        })
      })  
      await verifyTotalAprove({
         variables: {
            input: {
             campaignId: parseInt(state.campaign?.id as string, 10),
             collaboratorId: state.collaboratorId    
            }
           }
         })
    }
    SuccessfulAlert({ text: "Objetivos aprobados exitosamente" })
    setBtnAproveObjectives(false);
  }

  const notifyLeader = async () => {
    await notifyLeaderMutation({
      variables: {
        input: {
          campaignId: parseInt(state.campaign?.id as string, 10),
          collaboratorId: state.collaboratorId
        }
      }
    })
    SuccessfulAlert({ text: "Líder notificado exitosamente" })
   // setLeaderNotificationSent(true);
    
  }

  const goBack = () => {
    if (state.activeTable === "collaborator") {
      if (state.rol === 'collaborator') {
        history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}`)
      } else {
        setActiveTable("campaign")
      }
    } else if (state.activeTable === "campaign") {
      if (state?.userFromLink?.id) {
        history.push(`/campaign/${campaignIdFromUrl}/user/${user}/token/${token}`)
      } else {
        history.push('/campanias')
      }
      dispatch({ type: Types.RESET_OBJECTIVE_STATE })
    }
  }

  const createReportBond=()=>{
    ExcelBondsKpis(infoReport)
  }

  const openModalBono =()=>{
    dispatch({ type: Types.BOND_MODAL,payload:qualification })
    }

  const openModalIncidentBono =()=>{
    dispatch({ type: Types.CREATE_INCIDENT_BOND_MODAL })        
  }
  
  return (
    <Box style={{
      ...(campaignIdFromUrl && {
        backgroundColor: 'rgb(245, 246, 249)'
      })
    }}>
      {campaignIdFromUrl && (
        <Header fromLink />
      )}

      {
        state.rol === 'admin' && (
          <>
            <Box mt={3} ml={5} className="Title">
              KPI's y bonos
            </Box>
            <Box mt={3} ml={5}>
              <Grid
                container
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <CustomTabs
                    value={state.campaign?.status === 'finalizado' ? 1 : 0}
                    onChange={() => console.log("")}
                    aria-label="campaign tabs"
                  >
                    <CustomTab label="Campañas" value={0} />
                    <CustomTab label="Históricas" value={1} />
                  </CustomTabs>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3} ml={5} mr={5} className={styles.mainBox}>
              <section>
                <FiltroEstado
                  filtroEstado={estado}
                  setFiltroEstado={setEstado}
                />
              </section>
              <section className={styles.mainActionsContainer}>
               { 
               (state.campaign?.step === 'definir') ?
                 ((state.activeTable==="campaign") &&
                  (addColObj <  maxObj ) )?
                 <>
                  <AddTable
                    func={openCreateModal}
                    text="Crear Objetivo "
                    img="add-icon.svg"
                   />
                 </>:
                 ((state.activeTable==="collaborator") &&
                   weightObjectives < 100  && 
                   (addColObj <  maxObjInd)
                   )?
                 <>
                 <AddTable
                   func={openCreateModal}
                   text="Crear Objetivo "
                   img="add-icon.svg"
                  />
                </>                 
                 :              
                 <>
                    <button
                    className={styles.pd_botonInactive}
                    disabled             
                    >     
                    <div className={styles.pd_iconoCrearObjetivo}></div>                 
                    Crear Objetivo 
                  </button>
                 </>                 
                 :
                 <></>          
               }
               {
                 (objectivesListFilter.length > 0 && state?.campaign?.step ==='evaluar' && state.activeTable ==='campaign') &&
                  <Box className={styles.btnsActionsBonds}>
                     <Box>
                     <AddTable
                             func={createReportBond}
                             text="Reporte"
                             noIcon
                         />
                    </Box>   
                    {
                     state?.campaign?.campaignBond !== 'false' &&(
                     arrayIncidEnt.length > 0 ?
                      <Box>                       
                       <button
                       className={styles.pd_botonActive}     
                       onClick={openModalIncidentBono}                                    
                      >                 
                      Crear Incidencia Bono
                     </button>
                      </Box>
                      :
                      <Box>
                      <button
                       className={styles.pd_botonInactive}
                       disabled                  
                      >                 
                      Crear Incidencia Bono
                     </button>
                     </Box>
                     )
                    }          
                  </Box>                    
               }
               
               {(state.activeTable ==='collaborator' && state?.campaign?.step ==='evaluar' && state?.campaign?.typeBond !== '')?
                Number(countCalification) === 0 ?
                <Box>
                   <button
                   className={styles.pd_botonActive}
                   onClick={openModalBono}                                         
                   >                 
                   Bono
                 </button>          
               </Box>
               :
               <Box>
                  <button
                  className={styles.pd_botonInactive}
                  disabled                  
                  >                 
                  Bono
                </button>
               </Box>
               :<></>
              }                
                {
                  (state.campaign?.step === 'definir')?
                  ((state.activeTable === "collaborator")                 
                    && (isObjectivesApproved === false ))&& 
                  ( !btnAproveObjectives ?
                    (  
                    <button
                      className={styles.pd_botonInactive}
                      disabled                  
                    >                 
                      Aprobar objetivos
                    </button>):
                    (
                    <AddTable
                      func={approveObjectives}
                      text="Aprobar objetivos"
                      noIcon
                    />)                    
                  ):<></>
                }
              </section>
            </Box>
          </>
        )
      }
 
      {
        state.rol === 'collaborator' && (
          <Box mt={3} ml={5} mr={5} className={styles.mainBoxCollaborator}>
            <Box mt={3} ml={5} className="Title">
              KPI's y bonos
            </Box>
            <section className={styles.mainActionsCollaboratorContainer} style={{
              height: '36px'
            }}>    
            { (state.campaign?.step === 'definir')?
                state.activeTable === "campaign"                
                && weightObjectives === 100                                
                && (addColObj <=  maxObjInd)
                ?
                (
                 <button className={styles.pd_botonInactive} disabled >          
                    <div className={styles.pd_iconoCrearObjetivo}></div>       
                      Crear Objetivo
                  </button>
                ):
                (addColObj < maxObjInd && weightObjectives < 100  )?
                (
                  <AddTable
                    func={openCreateModal}
                    text="Crear Objetivo"
                    img="add-icon.svg"
                    disabled={disButton}
                  />
                )
                :
                (
                <button className={styles.pd_botonInactive}disabled  >          
                  <div className={styles.pd_iconoCrearObjetivo}></div>       
                   Crear Objetivo
               </button> 
               ):<></>
              }     
              {
                state.activeTable === "collaborator"                             
                && state.campaign?.step === 'definir'              
                &&
                (
                leaderNotified || weightObjectives !== 100?
                (  <button
                  className={styles.pd_botonInactive}
                  disabled                
                >                 
                   Notificar al líder
                </button>):
                  (<AddTable
                    func={notifyLeader}                   
                    text="Notificar al líder"
                    noIcon
                    />
                     )
                 )
              }          
            </section>
          </Box>
        )
      }
 
      {
        state.rol === 'leader' && (
          <>
           <Box mt={3} ml={5} mr={5} className={styles.mainBoxCollaborator}>
            <Box mt={3} ml={5} className="Title">
              KPI's y bonos -lider
            </Box>        
            <Box mt={3} ml={5} mr={5} className={styles.mainBox}>
             <section className={styles.mainActionsContainer}>
         
              {
                (state.campaign?.step === 'definir')?                    (
                   (state.activeTable === "campaign") &&                    
                   (addColObj <  maxObj )
                  ) ?
                  <>                 
                  <AddTable
                     func={openCreateModal}
                     text="Crear Objetivo"
                     img="add-icon.svg"
                    />
                    </>
                  :<>                       
                     <button
                      className={styles.pd_botonInactive}
                      disabled             
                      >
                      <div className={styles.pd_iconoCrearObjetivo}></div>              
                      Crear Objetivo
                      </button>                                     
                  </>:
                  <></>
                }
                 {
                  state.activeTable === "collaborator"
                  && ( state.campaign?.step === 'definir')
                  && isObjectivesApproved === false && 
                  ( !btnAproveObjectives  ?
                    (  
                    <button
                      className={styles.pd_botonInactive}
                      disabled                  
                    >                 
                      Aprobar objetivos
                    </button>):
                    (
                    <AddTable
                      func={approveObjectives}
                      text="Aprobar objetivos"
                      noIcon
                    />)                    
                  )
                }  
              </section>
            </Box>
            </Box>
          </>
        )
      }

 
      <section className="contenedor2">
        <Box p={1}>
          <Grid className={styles.paper}>
            {
              (state.activeTable === "campaign" || (state?.rol !== "admin")) && (
                <section className={styles.campaignInfo}>
                  {
                    state.campaign?.id && (
                      <strong className={styles.collaborator}>
                        {state.campaign?.nameCampaign}
                      </strong>
                    )
                  }
                </section>
              )
            }

            {
              state.activeTable === "collaborator" && state.collaboratorName && (
                <section className={styles.campaignInfo}>
                  <button className={styles.campaignTitle} onClick={() => setActiveTable("campaign")}>
                    {state.campaign?.nameCampaign}
                  </button>
                  <span className={styles.slash}>/</span>
                  <strong className={styles.collaborator}>
                    {state.collaboratorName}
                  </strong>
                </section>
              )
            }
            {console.log(state)}
          {
              state.campaign?.id && (
                <> 
                {/* <div > <span className={styles.period}>{state?.campaign?.nameCampaign}</span></div> */}
                <section className={styles.period}>
                  Periodo: {moment(state.campaign?.startCampaign).format("DD/MM/YYYY")} - {moment(state.campaign?.endCampaign).format("DD/MM/YYYY")}
                </section>
                <div className={styles.containerInfoCampaign}>
                  <div>
                  <span className={styles.period}>Número de Objetivos Mínimos: {state?.campaign?.minObjectives}</span>
                  </div>
                  <div>
                  <span className={styles.infoCampaign}>Número de Objetivos Máximos: {state?.campaign?.maxObjectives}</span>
                  </div>
                  <div>
                  <span className={styles.infoCampaign}>Calificación mínima: {state?.campaign?.minQualification} %</span>
                  </div>
                  <div>
                  <span className={styles.infoCampaign}>Descripción 1: {state?.campaign?.descriptionCampaign}</span>
                  </div>
                  <div>
                 {state?.campaign?.typeBond==='Porcentaje de ingreso'?
                  <span className={styles.infoCampaign}>Bono : {state?.campaign?.descriptionBond} %</span>
                  :
                  <span className={styles.infoCampaign}>Bono : {formatter(Number(state?.campaign?.descriptionBond))}</span>
                  }
                  </div>
              
                </div>
                </>
              )
            }
         
            <section className={styles.tableContainer}>
              {state.activeTable === "campaign" && state.campaignId && (
                (
                <CampaignUsersTable 
                 setAddColObj={setAddColObj}
                 objectivesListFilter={objectivesListFilter}
                 setObjectivesListFilter={setObjectivesListFilter}  
                 infoReport={infoReport}
                 setInfoReport={setInfoReport}   
                 arrayIncidEnt={arrayIncidEnt}
                 setArrayIncident={setArrayIncident}   
                 selected={selected}
                 setSelected={setSelected}                          
                />
                )
              )}
              {state.activeTable === "collaborator" && state.campaignId && (
                 <ObjectivesTable 
                       setWeightObjectives={setWeightObjectives}
                       weightObjectives={weightObjectives}
                       isCheck={isCheck}
                       setIsCheck={setIsCheck}  
                       setBtnAproveObjectives={setBtnAproveObjectives}
                       setLeaderNotified={setLeaderNotified}  
                       setAddColObj={setAddColObj}                        
                       addColObj={addColObj}
                       maxObjInd={maxObjInd}    
                       qualification={qualification}
                       setQualification={setQualification} 
                       countCalification={countCalification}
                       setCountCalification={setCountCalification}
                       />                             
              )}
            </section>
            {(state?.rol === "admin" || state?.rol === "leader") &&
                <section style={{
                  height: '24px',
                  marginBottom: '20px'
                }}>
                  <button style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    height: '24px'
                  }}
                    onClick={goBack}
                  >
                    <img src="/assets/svg/back-button.svg" alt="Regresar" />
                    <span style={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      // fontWeight: '400',
                      lineHeight: '19px',
                      color: '#222222',
                      marginLeft: '20px'
                    }}>Regresar</span>
                  </button>
                </section>
                 }
          </Grid>
        </Box>
      </section>
      <CreateObjectiveModal />      
      <AddEvidenceModal />
      <QualifyEvidenceModal />
      <SelectCollaborators/>
      <InfoBondColaborator/>
      <CreateIncidentBondKpi
         arrayIncidEnt   ={arrayIncidEnt} 
         setArrayIncident={setArrayIncident}
         selected={selected}
         setSelected={setSelected}   
         />
    </Box>
  )
};

export default ObjectivesScreen;

