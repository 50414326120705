import { useContext, useState } from "react";
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Grid,
} from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from "@material-ui/icons/MoreVert";

import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";

const CollaboratorRowOptions = (props: any) => {
  const { dispatch } = useContext(ObjectivesContext);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  const goToCollaboratorObjectives = () => {
    dispatch({ type: Types.SET_ACTIVE_TABLE, payload: "collaborator" });
    dispatch({ type: Types.SET_COLLABORATOR_ID, payload: props.id });
    dispatch({ type: Types.SET_COLLABORATOR_NAME, payload: props.name });
    setAnchorEl(null);
  };

  const deleteObjectiveAction = async (): Promise<void> => {
    setAnchorEl(null);
    // let response = await WarningAlert({ text: "¿Deseas eliminar el objetivo?", showDenyButton: true, confirmButtonText: "Eliminar" });
    // if (response.isConfirmed) {
    //   await deleteObjective({
    //     variables: {
    //       input: {
    //         id: parseInt(props.id, 10)
    //       }
    //     }
    //   });
    // }
  }

  return (
    <>
      <Tooltip title="Editar" placement="right">
        <Box className="IconButtonPoints" mr={2}>
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon style={{ color: "#fabb00" }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        className="MoreVerIcon"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          divider
          button
          onClick={goToCollaboratorObjectives}
        >
          Ver
          <Grid container item justify="flex-end">
            <img src={`/assets/svg/info-icon.svg`} alt="Información" />
          </Grid>
        </MenuItem>

        {/* <MenuItem
          button
          onClick={deleteObjectiveAction}
        >
          Eliminar&nbsp;
          <Grid container item justify="flex-end">
            <img src="/assets/svg/delete-icon.svg" alt="Eliminar" />
          </Grid>
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default CollaboratorRowOptions;
