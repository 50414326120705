import "../css/Campaigns.css"

interface props{
  filtroEstado: string,
  setFiltroEstado: any
}


const FiltroEstado = ({filtroEstado, setFiltroEstado}:props) =>{
  const cambioEstado = (estado:string) =>{
      setFiltroEstado(estado)
  }

  return(
    <>
      <div className="c_contenedorTitulosEstado">
        <div className={`c_tituloProceso ${filtroEstado ==='definir'? 'c_tituloProcesoSeleccionado': ''}`} onClick={() => cambioEstado('definir')}>Definir</div>
        <div className={`c_tituloProceso ${filtroEstado ==='actuar'? 'c_tituloProcesoSeleccionado': ''}`} onClick={() => cambioEstado('actuar')}>Actuar</div>
        <div className={`c_tituloProceso ${filtroEstado ==='evaluar'? 'c_tituloProcesoSeleccionado': ''}`} onClick={() => cambioEstado('evaluar')}>Evaluar</div>
      </div>
      <div className="c_contenedorIconoSeguimiento">
        {
  
        (filtroEstado === 'definir' ?
          <>
            <div className="c_circuloAmarillo" onClick={() => cambioEstado('definir')}>
              <div className="c_circuloBlanco"></div>
            </div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('actuar')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('evaluar')}></div>
           
        </>
        :(filtroEstado ==='actuar'?
          <>
            <div className="c_circuloGris" onClick={() => cambioEstado('definir')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloAmarillo" onClick={() => cambioEstado('actuar')}>
                  <div className="c_circuloBlanco"></div>
            </div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('evaluar')}></div>
          </>
        :(filtroEstado ==='evaluar'?
          <>
            <div className="c_circuloGris" onClick={() => cambioEstado('definir')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloGris" onClick={() => cambioEstado('actuar')}></div>
            <div className="c_lineaSeguimiento"></div>
            <div className="c_circuloAmarillo" onClick={() => cambioEstado('evaluar')}>
              <div className="c_circuloBlanco"></div>
            </div>
          </>
          :null
        )))}
      </div>
    </>
  )
}

export default FiltroEstado