import { useReducer } from "react";
import MonthlyISRPReducer from "./MonthlyISRPReducer";
import MonthlyISRProcessContext, { initialState } from "./MonthlyISRProcessContext";

const MonthlyISRState =(props:any) =>{
  const [state, dispatch] = useReducer(MonthlyISRPReducer, initialState)
  return(
    <MonthlyISRProcessContext.Provider value={{state, dispatch}}>
      {props.children}
    </MonthlyISRProcessContext.Provider>
  )
}

export default MonthlyISRState