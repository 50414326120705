import * as d3 from "d3";
import { useEffect, useRef } from "react";

type dataIt = {
  x: number;
  y: number;
};

type ScatterProps = {
  width: number;
  height: number;
  data: dataIt[];
};

const ScatterPlot = ({ data, width, height }: ScatterProps) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, bottom: 30, left: 60 },
      width2 = width - margin.left - margin.right,
      height2 = height - margin.top - margin.bottom;

    // Clear previous chart
    d3.select(ref.current).selectAll("*").remove();
    // append the svg object to the body of the page
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width2 + margin.left + margin.right)
      .attr("height", height2 + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Add X axis
    const x = d3.scaleLinear().domain([0, 10]).range([0, width2]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height2})`)
      .call(d3.axisBottom(x));

    // Add Y axis
    const y = d3.scaleLinear().domain([0, 10]).range([height2, 0]);
    svg.append("g").call(d3.axisLeft(y));

    // Add dots
    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .join("circle")
      .attr("cx", (d) => x(d.x))
      .attr("cy", (d) => y(d.y))
      .attr("r", 5)
      .style("fill", "#154565");
  }, [data, width, height]);

  return <svg ref={ref} width={width} height={height} />;
};

export default ScatterPlot;
