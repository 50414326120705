import ReactDOM from "react-dom";
import BitsApp from "./BitsApp";
import "./styles/Index.css";
import "./styles/swal.css";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: "https://bits-api-prd.azurewebsites.net/graphql",
    //uri: "http://3.144.118.244:3002/graphql",
  }),
});

if ("Notification" in window && Notification.permission !== "granted") {
  Notification.requestPermission().then(() => {
    console.log("Notification granted")
  })
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <BitsApp />
  </ApolloProvider>,
  document.getElementById("root")
);
