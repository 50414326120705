import * as d3 from "d3";
import * as d3hexbin from "d3-hexbin";
import { useEffect, useRef } from "react";

type dataIt = {
  x: number;
  y: number;
};

type HexbinPlotProps = {
  width: number;
  height: number;
  data: dataIt[];
};
const HexbinPlot = ({ data, width, height }: HexbinPlotProps) => {
  const ref = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, bottom: 30, left: 60 },
      width2 = width - margin.left - margin.right,
      height2 = height - margin.top - margin.bottom;

    // Clear previous chart
    d3.select(ref.current).selectAll("*").remove();

    // append the svg object to the body of the page
    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width2 + margin.left + margin.right)
      .attr("height", height2 + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const x = d3.scaleLinear().domain([0, 10]).range([0, width2]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height2})`)
      .call(d3.axisBottom(x));

    // Add Y axis
    const y = d3.scaleLinear().domain([0, 10]).range([height2, 0]);
    svg.append("g").call(d3.axisLeft(y));

    const hexbinGenerator = d3hexbin
      .hexbin()
      .radius(9)
      .extent([
        [0, 0],
        [width2, height2],
      ]);

    const hexbinData = hexbinGenerator(
      data.map((item: any) => [x(item.x), y(item.y)])
    );

    const maxItemPerBin = Math.max(...hexbinData.map((hex) => hex.length));

    const colorScale = d3
      .scaleSqrt<string>()
      .domain([0, maxItemPerBin])
      .range(["transparent", "#cb1dd1"]);

    const opacityScale = d3
      .scaleLinear<number>()
      .domain([0, maxItemPerBin])
      .range([0.2, 1]);

    // Plot the hexbins
    svg
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", width2)
      .attr("height", height2);

    svg
      .append("g")
      .attr("clip-path", "url(#clip)")
      .selectAll("path")
      .data(hexbinData)
      .join("path")
      .attr("d", hexbinGenerator.hexagon())
      .attr("transform", (d) => `translate(${d.x}, ${d.y})`)
      .attr("fill", (d) => colorScale(d.length))
      .attr("stroke", "black")
      .attr("stroke-width", "0.1")
      .attr("stroke-opacity", (d) => opacityScale(d.length));
  }, [data, width, height]);

  return <svg ref={ref} width={width} height={height} />;
};

export default HexbinPlot;
