/* eslint-disable array-callback-return */
import { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { Select } from "@material-ui/core";
import { useMutation,useLazyQuery } from "@apollo/client";
import { MenuCampaign } from './components/MenuCampaign';
import GreenSwitch from "../ConfigPayroll/GreenSwitch";
import { UPDATE_CAMPAIGN_ACTIVE, GET_ALL_CAMPAIGNS, UPDATE_CAMPAIGN_STEP,GET_INFO_COLABORATORS_CAMPAIGN } from "../../Querys/campaigns/querys";
import { WarningAlert } from '../../alerts/WarningAlert';
import { AttentionAlert2 } from "../../alerts/attentionAlert2";
import ObjectivesContext, { Types } from '../../context/ObjectiveContext/ObjectiveContext';


import "./css/CardCampaigns.css"

interface Campaign {
  id: number
  nameCampaign: string
  startCampaign: string
  endCampaign: string
  step: string
  campaignBond: string
  descriptionBond: string
  minObjectives: number
  maxObjectives: number
  minQualification: number
  descriptionCampaign: string
  active: string
  remandingDays: number
  expectedProgress: number
  createdObjectives: number
  finishedObjectives: number
  areas: {
    id: number
    area: string
  }[]
  sedes: {
    id: number
    sede: string
  }[]
  objetivesAvance: {
    id: number
    objetiveEvidence: string
  }[]
  totalCollaborators: number  
  pctAvance:number
}


const CampaignCard = ({ campaign }: { campaign: Campaign }) => {
  const {
    id,
    nameCampaign,
    startCampaign,
    endCampaign,
    step,
    campaignBond,
    // descriptionBond,
    // minObjectives,
    maxObjectives,
    // minQualification,
    // descriptionCampaign,
    active,
    // remandingDays,
    //expectedProgress,
    createdObjectives,
    //finishedObjectives,
    // areas,
    // sedes,
    totalCollaborators,
    objetivesAvance ,
    pctAvance 
  } = campaign
  const { dispatch } = useContext(ObjectivesContext);  
  const dateInitFormat = moment(startCampaign).utc().format('DD/MM/YYYY');
  const dateEndFormat = moment(endCampaign).utc().format('DD/MM/YYYY');
  const [stepValue, setStepValue] = useState("")
  const [disabledSelect, setDisabledSelect] = useState(false)
  const history = useHistory();  
  const bono = "true";
  const [countHundred, setCountHundred] = useState(0);
  const [countZero, setCountZero] = useState(0);
  const [updateStatus] = useMutation(UPDATE_CAMPAIGN_ACTIVE, {
    refetchQueries: [{ query: GET_ALL_CAMPAIGNS }]
  });
  const [updateStep] = useMutation(UPDATE_CAMPAIGN_STEP, {
    refetchQueries: [{ query: GET_ALL_CAMPAIGNS }]
  });

  const [viewObjectives] = useLazyQuery(GET_INFO_COLABORATORS_CAMPAIGN);

  const updateCampaignActive = async (id: any) => {
    await updateStatus({
      variables: {
        id: id
      }
    });
  }

  useEffect(() => {
    if (objetivesAvance.length >0) {
      const arrayHundred:any=[]
      const arrayZero:any=[]
      objetivesAvance.map((objAv:any)=>{
         if(objAv.objetiveEvidence === 100){
          arrayHundred.push(objAv)
          }
          if(objAv.objetiveEvidence === 0){
            arrayZero.push(objAv)
          }          
      })
      setCountHundred(arrayHundred.length)  
      setCountZero(arrayZero.length)  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countHundred,countZero]);

  
  useEffect(() => {
    if (stepValue === "") {
      setStepValue(step)
    }
  }, [step, stepValue]);


  useEffect(() => {
    if (active ==="Inactivo" ) {
      setDisabledSelect(true)
    }else{
      setDisabledSelect(false)
    }
  }, [active]);

  const handleChange = async (id: any, e: any) => {
    
    if(pctAvance >0 && e.target.value!=='terminar')  return AttentionAlert2({ text: "No puede cambiar de status de una campaña con objetivos calificados" }); 
    setStepValue(e.target.value)    
    let response = await WarningAlert({ text: "¿Deseas cambiar el status de la campaña?", showDenyButton: true, confirmButtonText: "Confirmar" });
    if (response.isConfirmed) {      
      const { data } = await viewObjectives({
            variables: {              
                id:parseInt(id)              
            }
          });
      const colaboratorsObjectives=data.GET_INFO_COLABORATORS_CAMPAIGN
      const arrayWith:any=[]
       colaboratorsObjectives.map((objectives:any)=>{
          if(objectives.objectivesCount === 0){
            arrayWith.push(objectives)
          }
       })    
       if(arrayWith.length > 0){
         let response1 = await WarningAlert({ text: "Tienes colaboradores que aun no han definido sus objetivos", showDenyButton: true, confirmButtonText: "Confirmar" });
         if (response1.isConfirmed) { 
          await updateStep({
              variables: {
                id: parseInt(id),
               stepValue: e.target.value,         
             }
           });
         }    
       }else{
        await updateStep({
          variables: {
            id: parseInt(id),
           stepValue: e.target.value,         
         }
       });
       }
    }
    else {
      setStepValue(step)
      return
    }
  }

  const goToObjectives =async  () => {
    dispatch({
      type: Types.SET_CAMPAIGN_INFO,
      payload: campaign
    })

    dispatch({
      type: Types.SET_ROL,
      payload: 'admin'
    })

    dispatch({
      type: Types.SET_CAMPAIGN_ID,
      payload: id
    })

    history.push(`/objetivos/${id}`)
  }

  return (
    <>
      <Card className='cardCampaign'>
        <CardContent className="contentCampaign" >
          <div className='topCampaign'>


                    <div>
                        <div className='selectCampaign'>
                          <Select
                            native
                            label={"step"}
                            name="step"
                            value={stepValue || ''}
                            disabled={disabledSelect}
                            onChange={(e) => { handleChange(id, e); }}
                            variant='outlined'
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              color: "#355F7A",
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              textAlign: "left",
                              backgroundColor: 'white',
                              height: "30px",
                              width: "120px",
                            }}
                          >
                            <option style={{ textAlign: "left" }} value="definir">Definir</option>
                            <option style={{ textAlign: "left" }} value="actuar"> Actuar</option>
                            <option style={{ textAlign: "left" }} value="evaluar">Evaluar</option>
                            <option style={{ textAlign: "left" }} value="terminar">Terminar</option>
                          </Select>
                        </div>
                        <div><span className='infoBono'>{campaignBond === bono ? 'Con bono' : "Sin bono"}</span></div>
                      </div>        
       

       
            <div className='btnCampaign'>
              <div className='switchCampaign'>
                <span className='actCampaign'>{active}</span>
                <GreenSwitch
                  updatePayrollStatus={updateCampaignActive}
                  id={id}
                  status={active} 
                  />
              </div>
              <div className='menCampaign'>
              {step === "definir" ? (
                <MenuCampaign id={id} active={active} campaign={campaign} /> 
               ):<></>
              }
              </div>
            </div>
          </div>
          <div className='infoCard'>
            <div><span className='titleCampaign'>{nameCampaign}</span></div>
            <div><span className='periodoCampaign'>{`Periodo: ${dateInitFormat} - ${dateEndFormat}`}</span></div>
          </div>
      
          {step === "definir" && (
            <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${createdObjectives} de ${maxObjectives * totalCollaborators}`}</span></div>                
                <div><span className='msjDefinir'>Objetivos</span> </div>
              </div>
              <div className="v-line"></div>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${totalCollaborators}`}</span></div>
                <div><span className='msjDefinir'>Colaboradores</span> </div>
              </div>
            </div>)
          }
        {step === "actuar" && (
             <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
             <div className='evaluarContain'>
               <div><span className='evaluarTxt'>{`${createdObjectives} `}</span>  </div>
               <div className='contMsjEvaluar'><span className='msjEvaluar'>Objetivos</span> </div>
               <div className='contMsjEvaluar'><span className='msjEvaluar'>Totales</span> </div>
             </div>
             <div className="ev-line"></div>
             <div className='evaluarContain'>
             {createdObjectives !==0 ?
               <div><span className='evaluarTxt'>{`${ ( (countHundred*100) / createdObjectives ).toFixed(2) } %`}</span></div>
               :
               <div><span className='evaluarTxt'>{`0%`}</span></div>
             }
               <div><span className='msjEvaluar'>Progreso</span></div>
               <div><span className='msjEvaluar'>Acumulado</span> </div>
             </div>
             <div className="ev-line"></div>
             <div className='evaluarContain'>
               <div><span className='evaluarTxt'>{countZero}</span></div>
               <div><span className='msjEvaluar'>Objetivos </span> </div>
               <div><span className='msjEvaluar'>Sin avance</span> </div>
             </div>
           </div>       
            )
          }
              {step === "evaluar" && (
                 <div className='definirCard' onClick={goToObjectives} style={{ cursor: 'pointer' }}>
              <div className='definirContain'>
                <div><span className='obtevosTxt'>{`${pctAvance.toFixed(2)} %`}</span>  </div> 
                <div><span className='msjDefinir'>Índice de éxito</span> </div>
              </div>
            </div>
            )
          }
        </CardContent>
      </Card>
    </>
  )
}

export default CampaignCard

