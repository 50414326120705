import { useState ,useEffect } from "react";
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import "../css/TableDynamic.css";

const TableDynamic = ({report}:any) => {
 const [nameColumns,setNameColumns] = useState<Array<any>>([]);
 const [valueReport,setValueReport] = useState<Array<any>>([]);   
  
 useEffect(() => {
  if (!report || report.length === 0) return;

  const firstRow = report[0];
  const columns = Object.keys(firstRow).map((key, index) => ({ id: `${index+1}ReportColumn`, name: key }));
  setNameColumns(columns);

  const valuesRep = report.map((data: any, rowIndex: number) => ({
    id: rowIndex+1,
    cells: Object.values(data).map((value, cellIndex) => ({ id: `${cellIndex+1}cell`, value:value })),
  }));

  setValueReport(valuesRep);
}, [report])
  
return (
<>
<Box className="containerTableDynamic">
<TableContainer component={Paper}>
<Table sx={{ minWidth: 650 }} aria-label="caption table">
  <TableHead>
    <TableRow className='pd_contenedorTitulos'>
     {nameColumns.length >0 ? 
      nameColumns.map((column:any) =>{
        return <TableCell key={column.id} className='cellSpanTitleReport' align="left">{column.name}</TableCell>
     })     
     :     
     <></>
    }     
    </TableRow>
  </TableHead>
  <TableBody>
     {
      valueReport.length >0 ?
       valueReport.map((row:any) => {       
        return(
         <TableRow key={row.id} >
          {
            row.cells.map((cell:any) => (
                <TableCell key={cell.id} align="left"> {cell.value}</TableCell>                
             ))
          }  
         </TableRow>  
        )            
       }):<></>
      } 
      </TableBody>
</Table>
</TableContainer>
</Box>  
</>
  )
}

export default TableDynamic

