import { useContext, useState } from "react";
import CampaignContext from "../../../context/CampaignsContext/CampaignContext"
import { createCampaignsModal, createDuplicateCampaignsModal } from "../../../context/CampaignsContext/Actions"
import { ErrorAlert } from '../../../alerts/errorAlert';
import { WarningAlert } from '../../../alerts/WarningAlert';
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import Box from '@mui/material/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DELETE_CAMPAIGN, GET_ALL_CAMPAIGNS, GET_HISTORICAL_CAMPAIGNS } from "../../../Querys/campaigns/querys";
import { useMutation } from "@apollo/client";
import CheckPermission from "../../../helpers/Administradores/Permissions";

export const MenuCampaign = (props: any) => {
    const { campaign } = props
    const { dispatch } = useContext(CampaignContext)

    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const openMenu = (e: any): void => {
        setAnchorEl(e.currentTarget);
    };
    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
        refetchQueries: [
            { query: GET_ALL_CAMPAIGNS },
            { query: GET_HISTORICAL_CAMPAIGNS },
        ],
    });

    const abreDuplicar = (_id: string, createDuplicateCampaigns: boolean) => {
        createDuplicateCampaignsModal({ _id, createDuplicateCampaigns, campaign }, dispatch)
    }

    const updateModal = (id: string, updateModal: boolean) => {
        setAnchorEl(null);
        createCampaignsModal(id, true, dispatch)
    }

    const deleteModal = async (id: any,active:any) => {
        setAnchorEl(null);
        console.log(active)
        if(active==="Activo")
        {
            return ErrorAlert({ text: 'No es posible eliminar una campaña que este activa' });
        }
       let response = await WarningAlert({ text: "¿Deseas eliminar la campaña?", showDenyButton: true, confirmButtonText: "Eliminar" });
       if (response.isConfirmed) {
             await deleteCampaign({
                 variables: {
                     id: parseInt(id)
                 }
             });
         }
    }

    const menuDelete = () => {
        return <MenuItem onClick={() => deleteModal(props.id,props.active)}>
            <div className="contenedorTextoMenuPrestacion" >
                <span className="textoMenuEliminarPrestacion">Eliminar</span>
            </div>
            <div className="imgDeletePres">
                <img
                    className="iconoMenuEliminarPrestacion"
                    src="/assets/svg/icono-eliminar.svg" alt="Eliminar" />
            </div>
        </MenuItem>
    }

    return (
        <>
            <Box mr={2}
                className="menuListNewPrestacion">
                <IconButton onClick={openMenu}
                    className="menuListOptionNewPrestacion"
                >
                    <MoreVertIcon className="moreOptionNewPrestacion" />
                </IconButton>
            </Box>
            <Menu
                className="MoreVerIcon"
                anchorEl={anchorEl}
                open={open}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={() => setAnchorEl(null)}>
                {CheckPermission("Calendario.AgregarDiaInhabil") && (
                    <MenuItem
                        onClick={() => abreDuplicar('', true)}
                    >
                        <div className="contenedorTextoMenuPrestacion" >
                            <span className="textoMenuEditarPrestacion" >Duplicar</span>
                        </div>
                        <div style={{ marginLeft: "24px" }}>
                            <ContentCopyIcon className="iconoMenuViewPrestacion" />
                        </div>
                    </MenuItem>
                )}
                {CheckPermission("Calendario.EditarCalendario") && props.campaign.status !== "finalizado" && (
                    <MenuItem onClick={() => updateModal(props.id, true)}>
                        <div className="contenedorTextoMenuPrestacion" >
                            <span className="textoMenuEditarPres" >Editar</span>
                        </div>
                        <div className="imgEditaPres" >
                            <img className="iconoMenuPres" src="/assets/svg/icono-editar.svg" alt="Editar" />
                        </div>
                    </MenuItem>
                )}
                {CheckPermission("Calendario.EliminarCalendario") && (
                    menuDelete()
                )}
            </Menu>

        </>
    )
}