import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import styles from "../../src/components/Payroll/PayrollStyles.module.css";
import CustomTabs from "../components/Collaborators/Tab/CustomTabs";
import CustomTab from "../components/Collaborators/Tab/CustomTabMain";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_EVENTUALPAYROLL,
  GET_EVENTUALPAYROLLCOLLABORATOR,
  UPDATE_EVENTUALPAYROLL_STATE,
  GET_ALL_COMPANY,
  GET_EVENTUALPAYROLLCOLLABORATOR_DESGLOCE,
  UPDATE_EVENTUAL_COLLABORATOR_DELETE
} from "..//Querys/querys";
import { formatter } from "../helpers/formatoMoneda";
import { getDateDay, getDateComplete } from "../helpers/Payroll/Payroll";
import CheckPermission from "../helpers/Administradores/Permissions";
import { 
  CreateExcelFilePayrollEv, 
  CreateExcelFilePayrollAguinaldo,
  CreateExcelFilePayrollPrimaVacacional, 
  CreateExcelFilePayrollFondoAhorro,
  CreateExcelFileDesgloce } from "../components/Payroll/Modals/ExcelPayrollE";
import LockedSystem from "../alerts/LockedSystem";
import { SuccessfulAlert } from '../alerts/successAlerts';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const InitPayroll = (props: TabPanelProps) => {
  const { id, tab } = useParams<any>();
  const [filtrado, setFiltrado] = useState([]);
  const history = useHistory();
  const [canRecalculate, setCanRecalculate] = useState(true);

  const [isCheck, setIsCheck] =  useState<any[]>([])
  const [isCheckInactivos, setIsCheckInactivos] =  useState<any[]>([])


  const resultPayrollCollaborator = useQuery(GET_EVENTUALPAYROLLCOLLABORATOR, {
    variables: { getEventualpayrollcollaboratorId: id },
  });

  const resultPayrollCollaboratorDesgloce = useQuery(GET_EVENTUALPAYROLLCOLLABORATOR_DESGLOCE, {
    variables: { getEventualpayrollcollaboratorId: id },
  });

  const { loading, data } = useQuery(GET_EVENTUALPAYROLL, {
    variables: { getEventualPayrollId: id },
  });

  const [updateCollaboratorStatus] = useMutation(UPDATE_EVENTUAL_COLLABORATOR_DELETE, {
    refetchQueries: [
      { query: GET_EVENTUALPAYROLLCOLLABORATOR, variables:{getEventualpayrollcollaboratorId: id}},
      { query: GET_EVENTUALPAYROLLCOLLABORATOR_DESGLOCE, variables:{getEventualpayrollcollaboratorId: id}},
      { query: GET_EVENTUALPAYROLL },
    ],
  });


  const allPayrollCollaborator = resultPayrollCollaborator.data?.GET_EVENTUALPAYROLLCOLLABORATOR;
  const PayrollData = data?.GET_EVENTUALPAYROLL;
  const allPayrollCollaboratorDesgloce = resultPayrollCollaboratorDesgloce.data?.GET_EVENTUALPAYROLLCOLLABORATOR_DESGLOCE;
  console.log(allPayrollCollaboratorDesgloce, 'Desgloce')
  /*--------------------------sistemaActivo--------------------*/
  const { data: resultCompany, startPolling: startPollingSistemaActivo, stopPolling: stopPollingSistemaActivo } = useQuery(GET_ALL_COMPANY);
  const myCompany = resultCompany?.GET_ALL_COMPANY;
  const [sistemaActivo, setSistemaActivo] = useState<any>(true);

  useEffect(()=>{
    startPollingSistemaActivo(1000);
    return () =>{
      stopPollingSistemaActivo()
    }
  },[startPollingSistemaActivo,stopPollingSistemaActivo])

  useEffect(() => {
    if(myCompany){
      setSistemaActivo(myCompany[0]?.SistemaActivo)
    }
  }, [myCompany]);
  /*--------------------------sistemaActivo--------------------*/

  useEffect(() => {
    setFiltrado(allPayrollCollaborator);
  }, [allPayrollCollaborator]);

  useEffect(() => {
    if(filtrado){
      let varRecalculate = 1
      filtrado.forEach((item:any) => {
        if((item.UUID && item.UUID !== "") || (item.dispersionStatus === "LIQUIDADO")){
          varRecalculate = 0
        }
      })
      if(varRecalculate === 0){
        setCanRecalculate(false)
      }
    }
  }, [filtrado]);

  const handleChange = () => {};

  const datosFiltrados = (e: any) => {
    if (e !== "") {
      let expresion = new RegExp(`${e}.*`, "i");
      const nuevoFiltrado = filtrado.filter((lis: any) =>
        expresion.test(lis.colaborator)
      );
      setFiltrado(nuevoFiltrado);
    } else {
      setFiltrado(allPayrollCollaborator);
    }
  };

  const [updatePayroll] = useMutation(UPDATE_EVENTUALPAYROLL_STATE);

  const updateState = (id: any) => {
    if(!PayrollData?.dateFinish){
      updatePayroll({
        variables: {
          input: {
            id: id,
            statusProgress: "Dispersar",
          },
        },
      });
    }   
  };

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }
  }

  const handleClickInactivos =  (e:any) => {
    const {id, checked} = e.target
    setIsCheckInactivos([...isCheckInactivos, id])
    if(!checked){
      setIsCheckInactivos(isCheckInactivos.filter((lis:any) => lis !== id))
    }
  }

  const handleOnExport = (tipo: any) => {
    if(tipo === "Aguinaldo"){

      CreateExcelFilePayrollAguinaldo(allPayrollCollaborator);

    } else  if (tipo === "Prima Vacacional") {

      CreateExcelFilePayrollPrimaVacacional(allPayrollCollaborator);
     
    } else  if (tipo === "Fondo Ahorro") {

      CreateExcelFilePayrollFondoAhorro(allPayrollCollaborator);
     
    } else {

      CreateExcelFilePayrollEv(allPayrollCollaborator);

    }

  };

  const handleOnExportDesgloce = () => {

      CreateExcelFileDesgloce(allPayrollCollaboratorDesgloce);

  };

  const eliminarColaborador = async () =>{
    if(isCheck.length > 0){
      for (const payrollDelete of isCheck){
        await updateCollaboratorStatus({
          variables: {
            id: String(payrollDelete)
          },
        })
      }
    }
    SuccessfulAlert({text: "Registro eliminado con éxito"})
    setIsCheck([])

  }  

  const handleSelectAll = (e:any) =>{

    if(!e.target.checked){
      setIsCheck([])
    }

    let filtradoSeleccionado = (allPayrollCollaborator.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (!lis.UUID || lis.UUID === "") && (lis.dispersionStatus !== "LIQUIDADO")
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleSelectAllInactivos = (e:any) =>{

    if(!e.target.checked){
      setIsCheckInactivos([])
    }

    let filtradoSeleccionado = (allPayrollCollaborator.filter((lis:any)=>{
      return e.target.checked && !isCheckInactivos.includes(lis.id) && (!lis.UUID || lis.UUID === "") && (lis.dispersionStatus !== "LIQUIDADO")
    }).map((lis:any) =>(lis.id)))
    setIsCheckInactivos(filtradoSeleccionado)

  }

  

  return (
    <>
      {!sistemaActivo ? (
        <LockedSystem/>
      ) : null}
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            value={3}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
      {loading ? null : PayrollData?.payroll_type === "Vales de Despensa" ? (
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>
            </div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
          </div>
        </div>
        ) :
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}></div>
            </div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
            <div className={styles.pc_lineaSeguimiento}></div>
            <div className={styles.pc_circuloGris}></div>
          </div>
        </div>
        }
        <>
        {loading ? null : PayrollData?.payroll_type === "Vales de Despensa" ? (
        <div className={styles.pc_contenedorBotones}>
          {CheckPermission("Nominas.Eventuales.Calcular.Calcular") && (
            canRecalculate ? 
              PayrollData?.dateFinish? null
              :
              <button className={styles.pc_botonCalcular}>
                <div className={styles.pc_iconoCalcular}></div>
                Calcular
              </button>
             : null
          )}
          
          <button id="payroll_10" className={styles.pd_botonDispersarDos}
            onClick={() => history.push(`/EventualPayrollTimbrar/${id}/${tab}`)}>
            <div
            >
            Ir a Timbrar
            </div>
            <div className={styles.pc_iconoDispersar}></div>
        </button>
        </div>
        ) : <div className={styles.pc_contenedorBotones}>
        {CheckPermission("Nominas.Eventuales.Calcular.Calcular") && (
          canRecalculate ?
            PayrollData?.dateFinish? null
            :
              <button className={styles.pc_botonCalcular}>
                <div className={styles.pc_iconoCalcular}></div>
                Calcular
              </button>
          : null
        )}
        {CheckPermission("Nominas.Eventuales.IrADispersar") && (
          <button
            className={styles.pc_botonDispersar}
            onClick={() => {
              updateState(id);
              history.push(`/EventualPayrollDispersar/${id}/${tab}`);
            }}
          >
            <div>Ir a Dispersar</div>
            <div className={styles.pc_iconoDispersar}></div>
          </button>
        )}
      </div> }
        </>
      </div>

      <div className={styles.pc_contenedorPrincipal}>
        <div className={styles.pc_tituloContenedorPrincipal}>
        {loading ? null : PayrollData?.payroll_type === "Vales de Despensa" ? (
             <div className={styles.pc_contenedorNombre}>
             <span className={`${styles.pc_tituloPrincipal} ${styles.e_titulo}`}>
               {tab}
             </span>
             &nbsp;
                  <span className={styles.pc_tituloPrincipal}>
                    {PayrollData?.pantryVouchersMonth}
                  </span>
           </div>
            ) :   <div className={styles.pc_contenedorNombre}>
            <span className={`${styles.pc_tituloPrincipal} ${styles.e_titulo}`}>
              {tab}
            </span>

          </div> }
          {loading ? null : PayrollData?.payroll_type === "PTU" ? (
            <div className={styles.e_titulo}>
              <span className={styles.pc_tituloPeriodo}>
                Monto:{formatter(PayrollData.total)}{" "}
              </span>
              &nbsp;-&nbsp;
              <span className={styles.pc_tituloPeriodo}>
                Factor Ingreso: {PayrollData.FactorIngresos}
              </span>
              &nbsp;
              <span className={styles.pc_tituloPeriodo}>
                Factor Días: {PayrollData.FactorDias}
              </span>
            </div>
          ) : null }
          {loading ? null : PayrollData?.payroll_type === "Fondo Ahorro" ? (
            <div className={styles.e_titulo}>
              <span className={styles.pc_tituloPeriodo}>Fondo de ahorro:</span>
              &nbsp;-&nbsp;
              <span className={styles.pc_tituloPeriodo}>
                {getDateDay(PayrollData?.init_date)}{" "}
                {getDateDay(PayrollData?.end_date)}
              </span>
              &nbsp;
            </div>
          ) : null }

          {loading ? null : PayrollData?.payroll_type === "Aguinaldo" ? (
            <div className={styles.e_titulo}>
              <span className={styles.pc_tituloPeriodo}>Aguinaldo</span>
              &nbsp;
              <span className={styles.pc_tituloPeriodo}>
                Periodo: {PayrollData?.AguinaldoPeriod}
              </span>
              &nbsp;
            </div>
          ) : null }

          {loading ? null : PayrollData?.payroll_type === "Prima Vacacional" ? (
            <div className={styles.e_titulo}>
              <span className={styles.pc_tituloPeriodo}>Prima Vacacional</span>
              &nbsp;
              &nbsp;
            </div>
          ) : null }

          {loading ? null : PayrollData?.payroll_type === "Vales de Despensa" ? (
            <div className={styles.e_titulo}>
              <span className={styles.pc_tituloPeriodo}>Vales de Despensa</span>
            </div>
          ) : null }


        </div>
        <div className={styles.pc_contenedorBuscadorDescargar}>
          <div className={styles.pc_contenedorBuscador}>
            <input
              type="text"
              placeholder="Buscar al colaborador por nombre"
              className={styles.pc_inputBuscador}
              onChange={(e) => datosFiltrados(e.target.value)}
            ></input>
            <div className={styles.pc_iconoBuscador}></div>
          </div>

          {PayrollData?.payroll_type === "Aguinaldo" ? (
          <button
            className={styles.pc_botonDescargar}
            onClick={() => handleOnExport("Aguinaldo")}
          >
            <div className={styles.pc_iconoDescargar}></div>
            <div>Descargar reporte</div>
          </button>

          ) : null }

          {PayrollData?.payroll_type === "Prima Vacacional" ? (
          <button
            className={styles.pc_botonDescargar}
            onClick={() => handleOnExport("Prima Vacacional")}
          >
            <div className={styles.pc_iconoDescargar}></div>
            <div>Descargar reporte</div>
          </button>

          ) : null }

        {
        PayrollData?.payroll_type === "Fondo Ahorro" ? (
          <div className={styles.contenedorBotonesDescargas}>
            <button
            className={styles.pc_botonDescargar}
            onClick={() => handleOnExportDesgloce()}
          >
            <div className={styles.pc_iconoDescargar}></div>
            <div>Descargar Desgloce</div>
          </button>

          <button
            className={styles.pc_botonDescargar}
            onClick={() => handleOnExport("Fondo Ahorro")}
          >
            <div className={styles.pc_iconoDescargar}></div>
            <div>Descargar reporte</div>
          </button>
          </div>
          ) : PayrollData?.payroll_type === "Prima Vacacional" ? (null
        ):  
          <></>  
        // <button
        // className={styles.pc_botonDescargar}
        // onClick={() => handleOnExport("Otro")}
        // >
        // <div className={styles.pc_iconoDescargar}></div>
        // <div>Descargar reporte </div>
        // </button>
        }

        </div>
 
                  <div
                  style={{
                    marginBottom: "20px",
                  }}
                  > {
                      (PayrollData?.dateFinish || !sistemaActivo) ? null
                      :  (isCheck.length > 0)?
                          <button className={styles.pc_botonEliminar}
                          onClick={() => eliminarColaborador()}
                          >
                              Eliminar
                            </button>
                        :
                          <button className={styles.pc_botonEliminarInactivo}>
                              Eliminar
                          </button>
                    }
                  </div>




        {PayrollData?.payroll_type === "PTU" ? (
          <>
           <div className={styles.pc_contenedorNombre}>
           <span className={`${styles.pc_tituloPrincipal} ${styles.e_titulo}`}>
             Activos
           </span>

         </div>
            <div className={styles.pc_contenedorTabla}>
        <table className={styles.pd_tablaPrincipal}>
          <thead className={styles.pd_contenedorTitulos}>
          <td className={styles.pc_columnaTitulos}>
            <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="check"
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e)}
                    ></input>
                    <label htmlFor="check"> Colaborador</label>
            </div>
          </td>
            <td className={styles.pd_columnaTitulos}>Fecha ingreso</td>
            <td className={styles.pd_columnaTitulos}>Salario diario actual</td>
            <td className={styles.pd_columnaTitulos}>Fecha inicial </td>
            <td className={styles.pd_columnaTitulos}>Fecha final </td>
            <td className={styles.pd_columnaTitulos}>Días laborados </td>
            <td className={styles.pd_columnaTitulos}>Ingresos anuales </td>
            <td className={styles.pd_columnaTitulos}>
              Importe por ingresos{" "}
            </td>
            <td className={styles.pd_columnaTitulos}>Importe por días </td>
            <td className={styles.pd_columnaTitulos}>Total a repartir </td>
            <td className={styles.pd_columnaTitulos}>Retención ISR </td>
            <td className={styles.pd_columnaTitulos}>
              Total dispersar PTU{" "}
            </td>
            <td className={styles.pd_columnaTitulos}>Estatus</td>
          </thead>

          
          <tbody>
          {filtrado?.filter((collaborator: any) => collaborator.collaborator === true).map((collaborator: any, index: any) => (
                  <tr>
                    <td className={styles.pc_columnaDatos}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={collaborator.id}
                          key={collaborator.id}
                          type="checkbox"
                          checked ={isCheck.includes(collaborator.id)}
                          onChange={(e) => handleClick(e)}
                          value={collaborator.id}
                          disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
                        ></input>
                        <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.FechaIngreso}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.SD)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {getDateComplete(collaborator?.init_datePTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {getDateComplete(collaborator?.end_datePTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.workingDaysPTU}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.AnnualIncomePTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.IncomeDaysPTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.IncomeAmountPTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.TotalDealPTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.deduction)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.total)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {(collaborator?.dispersionStatus === "ENPROCESO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                          <div className={styles.pd_textoBotonEstatusDos}>
                            Pendiente
                          </div>
                        </div>
                      : null
                      }
                      {(collaborator?.dispersionStatus === "LIQUIDADO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                          <div className={styles.pd_textoBotonEstatusCuatro}>
                            Pagado
                          </div>
                          <div className={styles.pd_iconoEstatusCuatro}></div>
                        </div>
                      : null
                      }
                    </td>
                  </tr>
                ))}
              </tbody>

          </table>
          </div>
          <div className={styles.pc_contenedorNombre}>
           <span className={`${styles.pc_tituloPrincipal} ${styles.e_titulo}`}>
             Inactivos
           </span>

         </div>
        <div className={styles.pc_contenedorTabla}>
        <table className={styles.pd_tablaPrincipal}>
          <thead className={styles.pd_contenedorTitulos}>
          <td className={styles.pc_columnaTitulos}>
            <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="checkInactivos"
                      type="checkbox"
                      onChange={(e) => handleSelectAllInactivos(e)}
                    ></input>
                    <label htmlFor="checkInactivos"> Colaborador</label>
            </div>
          </td>

            <td className={styles.pd_columnaTitulos}>Fecha ingreso</td>
            <td className={styles.pd_columnaTitulos}>Salario diario actual</td>
            <td className={styles.pd_columnaTitulos}>Fecha inicial </td>
            <td className={styles.pd_columnaTitulos}>Fecha final </td>
            <td className={styles.pd_columnaTitulos}>Días laborados </td>
            <td className={styles.pd_columnaTitulos}>Ingresos anuales </td>
            <td className={styles.pd_columnaTitulos}>
              Importe por ingresos{" "}
            </td>
            <td className={styles.pd_columnaTitulos}>Importe por días </td>
            <td className={styles.pd_columnaTitulos}>Total a repartir </td>
            <td className={styles.pd_columnaTitulos}>Retención ISR </td>
            <td className={styles.pd_columnaTitulos}>
              Total dispersar PTU{" "}
            </td>
            <td className={styles.pd_columnaTitulos}>Estatus</td>
          </thead>

          
          <tbody>
          {filtrado?.filter((collaborator: any) => collaborator.baja === true).map((collaborator: any, index: any) => (
                  <tr>
                    <td className={styles.pc_columnaDatos}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={collaborator.id}
                          key={collaborator.id}
                          type="checkbox"
                          checked ={isCheckInactivos.includes(collaborator.id)}
                          onChange={(e) => handleClickInactivos(e)}
                          value={collaborator.id}
                          disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
                        ></input>
                        <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.FechaIngreso}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.SD)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {getDateComplete(collaborator?.init_datePTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {getDateComplete(collaborator?.end_datePTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.workingDaysPTU}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.AnnualIncomePTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.IncomeAmountPTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.IncomeDaysPTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.TotalDealPTU)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.deduction)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.total)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {(collaborator?.dispersionStatus === "ENPROCESO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                          <div className={styles.pd_textoBotonEstatusDos}>
                            Pendiente
                          </div>
                        </div>
                      : null
                      }
                      {(collaborator?.dispersionStatus === "LIQUIDADO")?
                        <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                          <div className={styles.pd_textoBotonEstatusCuatro}>
                            Pagado
                          </div>
                          <div className={styles.pd_iconoEstatusCuatro}></div>
                        </div>
                      : null
                      }
                    </td>
                  </tr>
                ))}
              </tbody>

          </table>
          </div>
          </>
          ) : 
          <>
          <div className={styles.pc_contenedorTabla}>
          <table className={styles.pd_tablaPrincipal}> 

            {PayrollData?.payroll_type === "Fondo Ahorro" ? (
              <thead className={styles.pd_contenedorTitulos}>
              <td className={styles.pc_columnaTitulos}>
                <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id="check"
                          type="checkbox"
                          onChange={(e) => handleSelectAll(e)}
                        ></input>
                        <label htmlFor="check"> Colaborador </label>
                </div>
              </td>
                <td className={styles.pd_columnaTitulos}>Fecha ingreso</td>
                <td className={styles.pd_columnaTitulos}>Salario diario actual</td>
                <td className={styles.pd_columnaTitulos}>No. de aportaciones {" "}</td>
                <td className={styles.pd_columnaTitulos}>Aportación {" "}</td>
                <td className={styles.pd_columnaTitulos}>Aportación trabajador {" "}</td>
                <td className={styles.pd_columnaTitulos}>Aportación patrón {" "}</td>
                <td className={styles.pd_columnaTitulos}>Total</td>
              </thead>
            ) : null}
            {PayrollData?.payroll_type === "Prima Vacacional" ? (
              <thead className={styles.pd_contenedorTitulos}>
              <td className={styles.pc_columnaTitulos}>
                <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id="check"
                          type="checkbox"
                          onChange={(e) => handleSelectAll(e)}
                        ></input>
                        <label htmlFor="check"> Colaborador </label>
                </div>
              </td>
                <td className={styles.pd_columnaTitulos}>Fecha ingreso</td>
                <td className={styles.pd_columnaTitulos}>Salario diario Gravable</td>
                <td className={styles.pd_columnaTitulos}>Salario diario No Gravable</td>
                <td className={styles.pd_columnaTitulos}>Antiguedad</td>
                <td className={styles.pd_columnaTitulos}>Dia de Vacaciones</td>
                <td className={styles.pd_columnaTitulos}>
                  Tasa Prima Vacacional
                </td>
                <td className={styles.pd_columnaTitulos}>
                  Prima Vacacional
                </td>
                <td className={styles.pd_columnaTitulos}>
                  ISR
                </td>
                <td className={styles.pd_columnaTitulos}>Total Gravable</td>
                <td className={styles.pd_columnaTitulos}>Total No Gravable</td>
              </thead>
            ) : null}
            {PayrollData?.payroll_type === "Aguinaldo" ? (
              <thead className={styles.pd_contenedorTitulos}>
              <td className={styles.pc_columnaTitulos}>
                <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id="check"
                          type="checkbox"
                          onChange={(e) => handleSelectAll(e)}
                        ></input>
                        <label htmlFor="check"> Colaborador </label>
                </div>
              </td>
                <td className={styles.pd_columnaTitulos}>Fecha Alta</td>
                <td className={styles.pd_columnaTitulos}>Dias Laborados</td>
                <td className={styles.pd_columnaTitulos}>Dias Aguinaldo</td>
                <td className={styles.pd_columnaTitulos}>Factor Aguinaldo</td>
                <td className={styles.pd_columnaTitulos}>SD Completo</td>
                <td className={styles.pd_columnaTitulos}>Total a Recibir</td>
                <td className={styles.pd_columnaTitulos}>SDG</td>
                <td className={styles.pd_columnaTitulos}>Aguinaldo Gravable</td>
                <td className={styles.pd_columnaTitulos}>Excento</td>
                <td className={styles.pd_columnaTitulos}>Gravado</td>
                <td className={styles.pd_columnaTitulos}>Retencion Impuesto</td>
                <td className={styles.pd_columnaTitulos}>Neto Gravable</td>
                <td className={styles.pd_columnaTitulos}>Neto No Gravable</td>
              </thead>
            ) : null}
            {PayrollData?.payroll_type === "Vales de Despensa" ? (
              <thead className={styles.pd_contenedorTitulos}>
                <td className={styles.pc_columnaTitulos}>
                  <div id="colaboladores" className={styles.checkboxitem}>
                          <input
                            id="check"
                            type="checkbox"
                            onChange={(e) => handleSelectAll(e)}
                          ></input>
                          <label htmlFor="check"> Colaborador </label>
                  </div>
                </td>
                <td className={styles.pd_columnaTitulos}>Total Vales</td>
              </thead>
            ) : null}

            {PayrollData?.payroll_type === "Fondo Ahorro" ? (
              <tbody>
                {filtrado?.map((collaborator: any, index: any) => (
                  <tr>
                    <td className={styles.pc_columnaDatos}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={collaborator.id}
                          key={collaborator.id}
                          type="checkbox"
                          checked ={isCheck.includes(collaborator.id)}
                          onChange={(e) => handleClick(e)}
                          value={collaborator.id}
                          disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
                        ></input>
                        <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.FechaIngreso}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.SD)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.NoContributions}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                    {collaborator.TipoAportacion === "Fijo"
                      ? `${formatter(collaborator.PercentageContributions)}`
                      : `${collaborator.PercentageContributions}%`}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.WorkerContributions)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.EmployerContributions)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
            {PayrollData?.payroll_type === "Prima Vacacional" ? (
              <tbody>
                {filtrado?.map((collaborator: any, index: any) => (
                  <tr>
                   <td className={styles.pc_columnaDatos}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={collaborator.id}
                          key={collaborator.id}
                          type="checkbox"
                          checked ={isCheck.includes(collaborator.id)}
                          onChange={(e) => handleClick(e)}
                          value={collaborator.id}
                          disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
                        ></input>
                        <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.FechaIngreso}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.SD)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.SDNoGravablePrimaVacacional)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.AntiguedadPrimaVacacional}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.VacacionesPrimaVacacional}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.TasaPrimaVacacional}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.perception)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.deduction)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.total)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.TotalNoGravablePrimaVacacional)}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
            {PayrollData?.payroll_type === "Aguinaldo" ? (
              <tbody>

                {filtrado?.map((collaborator: any, index: any) => (
                  <tr>
                   <td className={styles.pc_columnaDatos}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={collaborator.id}
                          key={collaborator.id}
                          type="checkbox"
                          checked ={isCheck.includes(collaborator.id)}
                          onChange={(e) => handleClick(e)}
                          value={collaborator.id}
                          disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
                        ></input>
                        <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {getDateComplete(collaborator.fechaAlta)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.DiasLaborados}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.AguinaldoDays}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {collaborator.FactorAguinaldo}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.SD)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.netIncomeAguinaldo)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.SDG)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.perception)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.Excento)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.Gravado)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.deduction)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.AguinaldoGravable)}
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.AguinaldoNoGravable)}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
             {PayrollData?.payroll_type === "Vales de Despensa" ? (
              <tbody>
                {filtrado?.map((collaborator: any, index: any) => (
                  <tr>
                   <td className={styles.pc_columnaDatos}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={collaborator.id}
                          key={collaborator.id}
                          type="checkbox"
                          checked ={isCheck.includes(collaborator.id)}
                          onChange={(e) => handleClick(e)}
                          value={collaborator.id}
                          disabled={(!collaborator.UUID || collaborator.UUID === "") && collaborator.dispersionStatus !== "LIQUIDADO" ? false: true}
                        ></input>
                        <label htmlFor={collaborator.id}>  {collaborator.colaborator} </label>
                      </div>
                    </td>
                    <td className={styles.pc_columnaDatos}>
                      {formatter(collaborator.total)}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
          </table>
        </div>
        </>

      }
        <div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado}>
              <div
                className={styles.pd_contenedorRegreso}
                onClick={() => history.push(`/payroll`)}
              >
                <div className={styles.pd_botonRegreso}></div>
                <div>Regresar a "Eventual"</div>
              </div>
              <button
                className={styles.pd_botonSalir}
                onClick={() => history.push(`/payroll`)}
              >
                Salir
              </button>
            </div>
          </div>
        </div>



      </div>
      
    </>
  );
};

export default InitPayroll;
