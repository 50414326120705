/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import styles from "../../components/Payroll/PayrollStyles.module.css";
import CustomTabs from '../Collaborators/Tab/CustomTabs';
import CustomTab from '../Collaborators/Tab/CustomTabMain';
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import {GET_COLLABORATORS_OUTSORCERS_FINIQUITO, GET_PAYROLL_AUTORIZA_OUTSOURCERS_FINIQUITO} from "../../Querys/querys";
import { getDateDay, getDateYear } from "../../helpers/Payroll/Payroll";
import { formatter } from "../../helpers/formatoMoneda";
import AutorizaOutsorcers from "./modals/AutorizaOutsorcers";
import { openAutorizaOutsorcers, abreModalSinSeleccion, abreModalAceptarRechazado, abreModalRechazarRechazado } from "../../context/OutsorcersNomina/Actions";
import OutsorcersNominaContext from "../../context/OutsorcersNomina/OutsorcersNominaContext";
import DispersionOutsorcers from "./modals/DispersionOutsorcers";
import SinSeleccionOutsorcers from "./modals/SinSeleccionOutsorcers";
import AceptarRechazado from "./modals/AceptarRechazado";
import RechazarRechazado from "./modals/RechazarRechazado";
import CheckPermission from "../../helpers/Administradores/Permissions";


const OutsourcersAutorizarFiniquito = () =>{
  const {state, dispatch} = useContext(OutsorcersNominaContext)
  const { id, tab, tipo } = useParams<any>();
  const history = useHistory();
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true)
  const [inicio, setInicio] = useState(0)
  const [total, setTotal] = useState(0)
  const [disponibleDispersar, setDisponibleDispersar] = useState(true)
  const [diferencia, setDiferencia] = useState(0)
  const [filtrado, setFiltrado] = useState<any[]>([])
  const [datosTablas, setDatosTablas] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [isCheck, setIsCheck] = useState<any[]>([])
  const [procesoDispersion, setProcesoDispersion] = useState(false)
  const [barraPorcentaje, setBarraPorcentaje] = useState(0)
  const [registrosEnEspera, setRegistroEnEspera] = useState(0)
  const [dispersados,setDispersados] = useState(0)
  const [randomRequest, setRandomRequest] = useState(Math.random() * 10000);

  const handleChange = () =>{
  }

  const {loading:loadingPrincipal, data:dataPrincipal} = useQuery(GET_PAYROLL_AUTORIZA_OUTSOURCERS_FINIQUITO,{
    variables:{
      getPayrollAutorizaOutsourcersId: id
    }
  })

  const PayrollData = dataPrincipal?.GET_PAYROLL_AUTORIZA_OUTSOURCERS_FINIQUITO

  const {data:dataDispersion, startPolling, stopPolling} = useQuery(GET_COLLABORATORS_OUTSORCERS_FINIQUITO,{
    variables: { getCollaboratorsOutsorcersId: id },
  });
  

  const allPayrollDispersion = dataDispersion?.GET_COLLABORATORS_OUTSORCERS_FINIQUITO
  const datosIniciales = () =>{
    setCargandoPrincipal((current) => current = false)
  }
  const getDatosTabla = async() =>{
    setDatosTablas([...allPayrollDispersion])
    setRegistroEnEspera((current)=> current = allPayrollDispersion.filter((e:any) => e.idDispersion > 0 && e.dispersionStatus ==='ENPROCESO'))
    const tokenActual = PayrollData?.tokenActual

    let registrosConTockenEnEspera = (allPayrollDispersion.filter((e:any) =>
    e.tokenDispersar === tokenActual &&
    (e.dispersionStatus === 'LIQUIDADO' || e.dispersionStatus==='ERROR'))).length
    let porcentajeDispersados =(registrosConTockenEnEspera/ PayrollData?.numeroRegistrosToken)*100
    setBarraPorcentaje((current) => current = porcentajeDispersados)
    let registrosLiquidado = (allPayrollDispersion.filter((e:any) => e.dispersionStatus ==='LIQUIDADO')).length
    setDispersados((current) => current = registrosLiquidado)
  }

  const guardaPaginado = () =>{
    const results = !search ? datosTablas : datosTablas?.filter(
      (dato) => (dato.colaborator.toLowerCase().includes(search.toLocaleLowerCase())
      ))
      setFiltrado((current) => current = results)
  }

  const busqueda = (e:any)=>{
    setSearch((current) => current = e.target.value)
    guardaPaginado()
  }

  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([])
    }
    let filtradoSeleccionado = (allPayrollDispersion.filter((lis:any)=>{
      return e.target.checked && !isCheck.includes(lis.id) && (lis.statusOutsorcer === '')
    }).map((lis:any) =>(lis.id)))
    setIsCheck(filtradoSeleccionado)
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target
    setIsCheck([...isCheck, id])
    if(!checked){
      setIsCheck(isCheck.filter((lis:any) => lis !== id))
    }
  }

  const reinicioDatos = () =>{
    setIsCheck([])
  }

  const abreAutorizaOutsorcers = () => {
    openAutorizaOutsorcers({openAutoriza:true}, dispatch)
  }

  const abreSinSeleccion = () => {
    abreModalSinSeleccion({openSinSeleecion:true}, dispatch)
  }

  const abreAceptarRechazado = (id:any, nombreColaborador:any, conceptoDescartado:any) => {
    abreModalAceptarRechazado({_id:id,openAceptarRechazado:true, nombreColaborador, conceptoDescartado }, dispatch)
  }

  const abreRechazarRechazado = (id:any, nombreColaborador:any, conceptoDescartado:any) => {
    abreModalRechazarRechazado({_id:id,openRechazarRechazado:true, nombreColaborador, conceptoDescartado }, dispatch)
  }

  useEffect(()=>{
    if(dataPrincipal){
      datosIniciales()
  }
  },[dataPrincipal])

  useEffect(()=>{
    if(datosTablas){
      guardaPaginado()
    }
  },[datosTablas, search])

  
  useEffect(()=>{
    if(allPayrollDispersion){
      getDatosTabla();
    }
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[allPayrollDispersion, startPolling, stopPolling, isCheck])

  return(
    <>
      <Box mt={3} ml={5} className="Title">
        Nóminas
      </Box>
      <Box p={5} pb={3} pt={0}>
        <Grid container justify="flex-start">
          <CustomTabs
            //Tiene que ser 2 para que se muestre la pestaña de finiquitos
            value={2}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <CustomTab label="Incidencias" value={0} />
            {CheckPermission("Nominas.PreNomina") && (
              <CustomTab label="Pre-Nómina" value={1} />
            )}
            {CheckPermission("Nominas.FiniquitoLiquidacion") && (
            <CustomTab label="Finiquito / Liquidación" value={2} /> )}
            {CheckPermission("Nominas.Eventuales") && (
            <CustomTab label="Eventuales" value={3} />)}
             {CheckPermission("Nominas.Historicas") && (
            <CustomTab label="Históricas" value={4} />)}
             {CheckPermission("Nominas.Calculadora") && (
            <CustomTab label="Calculadora" value={5} />)}
            {CheckPermission("Nominas.Reportes") && (
            <CustomTab label="Reportes" value={6} />)}
          </CustomTabs>
        </Grid>
      </Box>
      <div className={styles.pc_contenedorTitulo}>
        <div className={styles.pc_contenedorProceso}>
          <div>
            <span className={styles.pc_tituloProceso}>Calcular</span>
            <span className={styles.pc_tituloProceso}>Dispersar</span>
            <span className={styles.pc_tituloProceso}>Timbrar</span>
            <span className={styles.pc_tituloProceso}>Terminar</span>
          </div>
          <div className={styles.pc_contenedorIconoSeguimiento}>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pd_iconoCompleto}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimientoCompleto}>
            </div>
            <div className={styles.pc_circuloAmarillo}>
              <div className={styles.pc_circuloBlanco}>
              </div>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
            <div className={styles.pc_lineaSeguimiento}>
            </div>
            <div className={styles.pc_circuloGris}>
            </div>
          </div>
        </div>
          <div className={styles.pc_contenedorBotones}>
            {isCheck.length >0?
              <button
                className={styles.pd_botonDispersarDos}
                onClick={()=>abreAutorizaOutsorcers()}
                >
                <div>Autorizar</div>
                <div className={styles.pc_iconoDispersar}></div>
              </button>
            :
              <button
                className={styles.pd_botonDispersarDos}
                onClick={()=>abreSinSeleccion()}
                >
                <div>Autorizar</div>
                <div className={styles.pc_iconoDispersar}></div>
              </button>
          }

          </div>
      </div>
      {(cargandoPrincipal === true || loadingPrincipal === true)?
          <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.contenedorLoading}>
              <div className={styles.loading}></div>
            </div>
          </div>
          :
      <div className={styles.pc_contenedorPrincipal}>
            <div className={styles.pc_tituloContenedorPrincipal}>
              <div className={styles.pc_contenedorNombre}>
                <div>
                  <span className={styles.pc_tituloPrincipal}>{tab}</span>
                </div>
                <div>
                  {/* <span className={styles.pc_tituloPeriodo}>Peri&oacute;do &nbsp;{getDateDay(PayrollData?.fechaInicio)}</span> &nbsp; - &nbsp;
                  <span className={styles.pc_tituloPeriodo}>{getDateDay(PayrollData?.fechaFin)}</span>&nbsp;
                  <span className={styles.pc_tituloPeriodo}>{getDateYear(PayrollData?.fechaFin)}</span> */}
                </div>
              </div>
              <div className={styles.pd_contenedorTituloSaldos}>
                <div className={styles.pd_cuentaBancaria}>
                  <div className={styles.pd_textoCuentaBancaria}>
                    Cuenta Bancaria
                  </div>
                  <div className={styles.po_nombreEmpresa}>
                    {PayrollData?.Empresa}
                  </div>
                  <div className={styles.pd_contenedorCuentaBancaria}>
                    {PayrollData?.cuentaBancaria}
                    
                  </div>
                </div>
            </div>
          </div>
          <div className={styles.pc_contenedorBuscadorDescargar}>
            <div className={styles.pc_contenedorBuscador}>
              <input
                value={search}
                className={styles.pc_inputBuscador}
                type="text"
                placeholder="Ingresa el nombre del colaborador"
                onChange={busqueda}
              />
              <div className={styles.pc_iconoBuscador}></div>
            </div>
          </div>
          <div className={styles.p_contenedorTablaDispersion}>
            <table className={styles.pd_tablaPrincipal}>
              <thead className={styles.pd_contenedorTitulos}>
                <td className={`${styles.pd_columnaTitulos} ${styles.pd_primerColumnaTitulo}`}>
                  <div id="colaboladores" className={styles.checkboxitem}>
                    <input
                      id="check"
                      type="checkbox"
                      onChange={(e) => handleSelectAll(e)}

                    ></input>
                      <label htmlFor="check"> Colaboradores </label>
                    </div>
                </td>
                <td className={styles.pd_columnaTitulos}>RFC</td>
                <td className={styles.pd_columnaTitulos}>Banco</td>
                <td className={styles.pd_columnaTitulos}>Cuenta bancaria</td>
                <td className={styles.pd_columnaTitulos}>CLABE</td>
                <td className={styles.pd_columnaTitulos}>Total a dispersar no gravable</td>
                <td id="payroll_9" className={styles.pd_columnaTitulos}>Estatus</td>
              </thead>
              <tbody className={styles.pc_contenedorTitulos}>
                {filtrado?.map((lis:any)=>(
                  <tr className={styles.pc_bordePrimeraColumna}>
                    <td className={`${styles.pd_primerColumnaTitulo}`}>
                      <div id="colaboladores" className={styles.checkboxitem}>
                        <input
                          id={lis?.id}
                          key={lis?.id}
                          type="checkbox"
                          checked ={isCheck.includes(lis?.id)}
                          value={lis?.id}
                          onChange={(e) => handleClick(e)}
                          disabled={(lis?.statusOutsorcer !== '')?true:false}
                          ></input>
                        <label htmlFor={lis?.id} className={styles.datosLabel}>  {lis?.colaborator} </label>
                      </div>
                    </td> 
                    <td className={styles.pc_columnaDatos}>{lis?.RFC}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.bank}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.accountNumber}</td>
                    <td className={styles.pc_columnaDatos}>{lis?.clabeNum}</td>
                    <td className={styles.pc_columnaDatos}>{formatter(lis?.TotalIncomeNotTaxable)}</td>
                    <td className={styles.pc_columnaDatos}>
                      {(lis?.statusOutsorcer === 'Autorizado')?
                        <div className={styles.po_contenedorBotonDescartado}>
                          <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                            <div className={styles.pd_textoBotonEstatusCuatro}>
                              Autorizado
                            </div>
                            <div className={styles.pd_iconoEstatusCuatro}></div>
                          </div>
                        </div>
                      : null
                      }
                      {(lis?.statusOutsorcer === 'Cancelado' || lis?.statusOutsorcer === 'Pago por otro metodo' || lis?.statusOutsorcer ==='Devolver a revision')?
                      <div className={styles.po_contenedorBotonDescartado}>
                        <div
                        className={styles.po_botonEstatusTres}>
                        <div className={styles.po_contenedorEstatusTres}>
                          <div className={styles.po_contEstatusTres}>
                            <div className={styles.po_iconoError}></div>
                              <div className={styles.po_textoBotonError}>
                                Descartado
                              </div>
                            </div>
                          <div className={styles.po_textoBotonErrorDos}>
                            {(lis?.statusOutsorcer ==='Devolver a revision')?
                              <span>Devolver a revis&iacute;on</span>
                            :
                              lis?.statusOutsorcer
                            }
                          </div>
                        </div>
                      </div>
                      <div className={styles.po_contenedorRespuestaDescartado}>
                        <div className={styles.po_aceptarRechazado}
                          onClick={()=> 
                            abreAceptarRechazado(lis.id, lis.colaborator, lis.statusOutsorcer)}
                        >
                          <div className={styles.po_iconoEstatusCuatro}></div>
                        </div>
                        <div className={styles.po_rechazarRechazado}
                          onClick={()=> 
                            abreRechazarRechazado(lis.id, lis.colaborator, lis.statusOutsorcer)}
                        >
                          <div className={styles.po_textoBotonRechazado}>X</div>
                        </div>    
                      </div>
                      </div>
                      : null
                      }
                      {(lis?.statusOutsorcer === 'Liquidado')?
                        <div className={styles.po_contenedorBotonDescartado}>
                          <div className={`${styles.po_botonEstatus} ${styles.po_botonEstatusCuatro}`}>
                            <div className={styles.po_textoBotonEstatusCuatro}>
                              Finalizado
                            </div>
                            <div className={styles.po_contenedorCuatro}>
                              <div className={styles.po_iconoEstatusCuatro}></div>
                              <div className={styles.po_iconoEstatusCuatro}></div>
                            </div>
                          </div>
                        </div>

                      : null
                      }
                    </td>
                  </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}
                onClick={() =>history.push(`/SettlementDispersar/${id}/${tab}/${tipo}`)}
              >
              <div className={styles.pd_botonRegreso}></div>
              <div>Regresar a "Dispersar"</div>
              </div>
                <button className={styles.pd_botonSalir}
                  onClick={() => history.push(`/payroll`)}
                >
                  Salir
                </button>
            </div>
          </div>
        </div>  
  }
        <AutorizaOutsorcers
          totalesDispersar={isCheck?.length}
          totales={allPayrollDispersion?.length}
          seleccionados={isCheck}
          reinicia={() => reinicioDatos()}
          idRegistro={id}
          tipo={"Finiquito"}
        />
        <SinSeleccionOutsorcers />
        <AceptarRechazado 
          tipo={"Finiquito"}
         />
        <RechazarRechazado 
         tipo={"Finiquito"}
        />
    </>
  )
}

export default OutsourcersAutorizarFiniquito
