import {useState, useEffect, useContext} from 'react'
import { useQuery } from '@apollo/client'
import { useHistory, useParams } from "react-router-dom";
import styles from '../../styles/Outsourcers/Outsourcers.module.css'
import { GET_ENTERPRISE_SELECT, GET_ENTERPRISE, GET_NOMINAS_OUTSORCERS, GET_ENTERPRISE_CENTRAL_PAGOS } from '../../Querys/querys'
import { getDateDay } from '../../helpers/Payroll/Payroll'
import { formatter } from '../../helpers/formatoMoneda'
import CentralPagosContext from '../../context/CentralPagos/CentralPagosContext';
import OutsorcersNominaContext from '../../context/OutsorcersNomina/OutsorcersNominaContext'
import { abreModalDescartarTodas } from '../../context/OutsorcersNomina/Actions';
import { ErrorAlert } from "../../alerts/errorAlert";
import DescartaTodas from './modals/DescartaTodas';

const OutsorcersTable = () =>{
  const { state, dispatch} = useContext(OutsorcersNominaContext);
  const history = useHistory();
  const { id, tab } = useParams<any>();
  const [empresa, setEmpresa] = useState("")
  const [cargandoPrincipal, setCargandoPrincipal] = useState(true)
  const [inicio, setInicio] = useState(0)
  const [filtro, setFiltro] =  useState("")
  const [datosTablas, setDatosTablas] = useState<any[]>([])
  
  const {loading:loadingListaEmpresa, error:errorListaEmpresa, data:dataListaEmpresa} = useQuery(GET_ENTERPRISE_SELECT,{})
  
  const dataEmpresaSelect = dataListaEmpresa?.GET_ENTERPRISE_SELECT.filter((enterprise:any)=>{return enterprise.Outsourcer})

  const {loading:loadingEmpresa, error:errorEmpresa, data: dataEmpresa,refetch} = useQuery(GET_ENTERPRISE_CENTRAL_PAGOS,{
    variables:{
      getEnterpriseCentralPagosId: empresa
    }
  })

  const empresaSeleccionada = dataEmpresa?.GET_ENTERPRISE_CENTRAL_PAGOS

  const {loading:loadingListaSolicitudes, error:errorListaSolicitudes, data:dataListaSolicitudes, startPolling, stopPolling} = useQuery(GET_NOMINAS_OUTSORCERS,{
    variables:{
      input:{
        filtro: filtro
      }
    }
  })

  const dataSolicitudes = dataListaSolicitudes?.GET_NOMINAS_OUTSORCERS
    
  const getDatosTabla = () =>{
    setDatosTablas([...dataSolicitudes])
  }

  const cambioFiltro = (e:any) =>{
    setFiltro((current) => current = e.target.value)
  }

  const cambioEstado = (estado:any) =>{
    setEmpresa((current) => current = estado)
  }

  const datosIniciales = () =>{
    setCargandoPrincipal((current) => current = false)
    setInicio((current) =>current = empresaSeleccionada?.saldo)
    if(empresa === "" && dataEmpresaSelect?.length > 0){
      setEmpresa((current) => current = dataEmpresaSelect?.["0"]["id"])
    }

  }
  
  const abreDescartar = (id:any,tipoNomina:any,totalLiquidadas:any) =>{
   if(totalLiquidadas>0){
    ErrorAlert({text:'No es posible eliminar la nomina'});  
   }else{
    abreModalDescartarTodas({openDescartaTodas: true, _id:id,tipo:tipoNomina}, dispatch)
   }
  }

  const dispercionOutsourcer= (id:any,nombreNomina:any,tipo:any) =>{
    // 
    console.log(tipo)

    switch (tipo) {
      case "eventual":
        history.push(`/outsorcersdispersionEventual/${id}/${nombreNomina}`)
        break;
     
     case "Finiquito":
        history.push(`/outsorcersdispersionFiniquito/${id}/${nombreNomina}`)
        break;
     
      case "Liquidaci�n":
          history.push(`/outsorcersdispersionLiquidacion/${id}/${nombreNomina}`)
       break;     
    
      default:
        history.push(`/outsorcersdispersion/${id}/${nombreNomina}`)
      break;
    }
    console.log("enviar ")
  }


  useEffect(()=>{
    refetch({
      getEnterpriseCentralPagosId: empresa
    })
  },[empresaSeleccionada])

  useEffect(()=>{
    if(dataListaSolicitudes){
      datosIniciales()
    }
  },[dataListaSolicitudes, empresaSeleccionada])


  useEffect(()=>{
    if(dataSolicitudes){
      getDatosTabla();
    }
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[dataSolicitudes, startPolling, stopPolling])


  return(
    <>
      {(loadingListaEmpresa)?
        <div className={styles.contenedorPrincipalCargando}>
          <div className={styles.loading}></div>
        </div>
      :
      <>
      <div className={styles.contenedorHeader}>
        <div className={styles.contenedorFiltro}>
          <input 
            placeholder='Buscar'
            className={styles.filtro}
            onChange={(e) => cambioFiltro(e)}
            />
          <div className={styles.iconoBuscador}></div>
        </div>
      <div className={styles.contenedorSaldos}>
        {
         (loadingEmpresa)?
         <div className={styles.saldosCargando}>
           <div className={styles.loadingSaldo}></div>
         </div>
         :
        <div className={styles.saldos}>
        <div className={styles.contenedorSaldoInterno}>
          <div className={styles.tituloSaldos}>Empresa</div>
          <div className={styles.contenedorUnoSaldo}>
              <select
              
                name="empresa"
                value={empresa}
                className={styles.nombreEmpresaSaldos}
                onChange={(e) => cambioEstado(e.target.value)}
              >
                {dataEmpresaSelect?.map((empresa:any)=>(
                  <option 
                    key={empresa?.id}
                    className={styles.optionEmpresa} 
                    value={empresa?.id}
                    >{empresa?.name}</option>
                ))}
              </select></div>
        </div>
        <div>
          <div className={styles.tituloSaldos}>Saldo total</div>
          <div className={styles.saldoInterno}>{ formatter(empresaSeleccionada?.saldo || 0) }</div>
        </div>
      </div>
      }
      </div>
      </div>

      <div className={styles.contenedorTitulo}>
      </div>
      <div className={styles.contenedorPrincipal}>
      {datosTablas?.map((solicitud:any)=>(
        <div className={styles.seleccion}>
          <div className={styles.seleccionInternoUno}>
            <div className={styles.tituloInternoUno}>
              {solicitud?.nombreNomina} 
            </div>      
           {solicitud?.tipoNomina === 'eventual'?
            <div className={styles.tituloInternoUno}>
            {solicitud?.payroll_type.split(" ")[0] === "PTU" ? (
              <span>
                {solicitud.payroll_type} {solicitud.AnioPTU}
              </span>
            ) : null }
            {solicitud?.payroll_type.split(" ")[0] === "Fondo Ahorro" ? (
              <span>
                {solicitud.payroll_type} {getDateDay(solicitud?.init_date)}{" "}
                {getDateDay(solicitud?.end_date)}
              </span>
            ) : null }
            {solicitud?.payroll_type.split(" ")[0] === "Aguinaldo" ? (
              <span>
                {solicitud.payroll_type}  
              </span>
            ) : null }
            {solicitud?.payroll_type.split(" ")[0] === "Prima Vacacional" ? (
              <span>
                {solicitud.payroll_type} 
              </span>
            ) : null }
            {solicitud?.payroll_type.split(" ")[0] === "Vales de Despensa" ? (
              <span>
                {solicitud.payroll_type} {solicitud.pantryVouchersMonth}
              </span>
            ) : null }
          </div>
           :<></>
           }
           <div className={`${styles.textoInternoUno} ${styles.margenTextoInternoUno}`}>
            <span>
              {getDateDay(solicitud?.fechaInicio)}{" "}
              {getDateDay(solicitud?.fechaFin)}
            </span>
            </div>
            <div className={styles.textoInternoUno}>
              {solicitud?.Empresa}
            </div>
            <div className={styles.textoInternoUno}>
              {solicitud?.tipoNomina}
            </div>
          </div>
          <div className={styles.seleccionInternoTres}>
          <div className={styles.contenedorInternoDatos}>
              <div className={styles.contenedorInternoDatosUno}>
                <div className={styles.tituloContenedorDatos}>Dispersiones</div>
                <div className={styles.textoContenedorDatos}>{solicitud?.Dispersiones}</div>
              </div>
              <div className={styles.contenedorInternoDatosUno}>
                <div className={styles.tituloContenedorDatos}>Total Dispersion</div>
                <div className={styles.textoContenedorDatos}>{formatter(solicitud?.totalDispersion)}</div>
              </div>
              <div className={styles.contenedorInternoDatosUno}>
                <div className={styles.tituloContenedorDatos}>Autorizadas</div>
                <div className={styles.textoContenedorDatos}>{solicitud?.Autorizadas}</div>
              </div>
              <div className={styles.contenedorInternoDatosUno}>
                {/* <div className={styles.tituloContenedorDatos}>Total Autorizadas</div> */}
                <div className={styles.tituloContenedorDatos}>Monto Autorizado</div>
                <div className={styles.textoContenedorDatos}>{formatter(solicitud?.totalAutorizadas)}</div>
              </div>
            </div>
          </div>
          <div className={styles.seleccionInternoCuatro}>
            <div className={styles.botonContinuaInterno}
              //onClick={() => history.push(`/outsorcersdispersion/${solicitud?.id}/${solicitud?.nombreNomina}`)}
              onClick={() => dispercionOutsourcer(solicitud?.id,solicitud?.nombreNomina,solicitud?.tipoNomina)}
            >Continuar</div>
            <div
              className={styles.pp_contenedorIconoEliminar}
              onClick={() => abreDescartar(solicitud?.id,solicitud?.tipoNomina,solicitud?.totalLiquidadas)}
            >
              <img
                className={styles.pp_iconoEliminar}
                src="/assets/svg/icono_eliminar-nomina.svg"
              ></img>
              <span className={styles.pp_textoEliminar}>Descartar</span>
            </div>
          </div>
        </div>
          ))
        }
      </div>
      <DescartaTodas  />
      </>
      }
    </>
  )
}

export default OutsorcersTable