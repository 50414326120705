import { createContext, Dispatch } from 'react';
import { ActionTabMonthlyISRReducer, StateTabMonthlyISRInterface } from '../../interfaces/TabMonthlyISR.interfaces';

interface InactiveModalInterface {
    inactiveOpen: any//boolean | (() => void);
    setInactiveOpen: any//boolean | (() => void);,
    addMonthlyISROpen: any,
    setAddMonthlyISROpen: any,
    MonthlyISRState: StateTabMonthlyISRInterface;
    MonthlyISRDispatch: Dispatch<ActionTabMonthlyISRReducer>
}

const initState:InactiveModalInterface  = {
    inactiveOpen: false,
    setInactiveOpen: () => { },
    addMonthlyISROpen: false,
    setAddMonthlyISROpen: () => { },
    MonthlyISRState: {
        MonthlyISRs: [],
        loading: false,
    },

    MonthlyISRDispatch: () => { },
}

export const TabMonthlyISRContext = createContext<InactiveModalInterface>(initState);