import styles from '../../../components/Payroll/PayrollStyles.module.css';
import { addDays, subMonths } from 'date-fns';
import { es } from 'date-fns/locale';
import { DateRange } from 'react-date-range';
import { useEffect, useState } from 'react';
import onClickOutside from "react-onclickoutside";
import moment from 'moment';

function DateRangeField({setDate, setFocusDate, containerClass, hideLabel, daySelected1, daySelected2}:any){
  
  
  //const firstDate: any = daySelected1 === undefined ? subMonths(new Date(), 0) : subMonths(new  Date(parseInt(moment(daySelected1).format('YYYY')), parseInt(moment(daySelected1).format('MM')), parseInt(moment(daySelected1).format('DD'))), 1)
  //const lastDate: any = daySelected2 === undefined ? addDays(new Date(), 0) : subMonths(new Date(parseInt(moment(daySelected2).format('YYYY')), parseInt(moment(daySelected2).format('MM')), parseInt(moment(daySelected2).format('DD'))), 1)

  const [showCalendario, setShowCalendario] = useState(false);
  const [firstDate, setFirstDate] = useState(subMonths(new Date(), 0));
  const [lastDate, setLastDate] = useState(addDays(new Date(), 0));
  const [stateCalendario, setStateCalendario] = useState([
    {
      startDate: firstDate,
      endDate: lastDate,
      key: "selection"
    }]
  );

   useEffect(() => {
     if(daySelected1 !== undefined){
      setFirstDate(subMonths(new  Date(parseInt(moment(daySelected1).format('YYYY')), parseInt(moment(daySelected1).format('MM')), parseInt(moment(daySelected1).format('DD'))), 1))
     }
   }, [daySelected1]);
   
   useEffect(() => {
    if(daySelected2 !== undefined){
      setLastDate(subMonths(new Date(parseInt(moment(daySelected2).format('YYYY')), parseInt(moment(daySelected2).format('MM')), parseInt(moment(daySelected2).format('DD'))), 1))
      // setLastDate(addDays(new Date(daySelected2), 1))
    }
  }, [daySelected2]);

  useEffect(() => {
     setStateCalendario([
      {
        startDate: firstDate,
        endDate: lastDate,
        key: "selection"
      }]
    );   
  }, [firstDate,lastDate]);


  DateRangeField.clickOutside = () => {
    setTimeout( () => {
      setShowCalendario(false);
      if(setFocusDate){
        setFocusDate(false);
      }
    }, 100);
  }

  const toggleCalendar = () => {
    setShowCalendario(!showCalendario);
    if(setFocusDate){
      setFocusDate(!showCalendario);
    }
  }

  const rangeSelection = (selection: any)=>{
    setStateCalendario(selection);
    //setShowCalendario(!showCalendario);
    setDate(selection);
  }

  return (
    <fieldset className={containerClass}>
      {
        !hideLabel && (
          <legend className={styles.tituloFieldset}>Fecha</legend>
        )
      }
        <div className='dateContainerSchedule'>
        
            <div onClick={() => toggleCalendar()} className={styles.dateField}>
                {stateCalendario.map(home => <div id="hora">
                <span className={styles.textDate}>{new Date(home.startDate).toLocaleDateString()} - {new Date(home.endDate).toLocaleDateString()}</span></div>)}

            </div>
            <div className={styles.contenedorCalendario}>
                <img
                className={styles.calendario}
                src="/assets/svg/icono-calendario.svg"
                onClick={() => toggleCalendar()}
                alt="Editar" />
                {
                (showCalendario ?
                    <DateRange className="dateRangeSchedule"
                    onChange={(item: any) => {
                        rangeSelection([item.selection])
                    }}
                    retainEndDateOnFirstSelection={true}
                    editableDateInputs={true}
                    dateDisplayFormat={"dd/MM/yyyy"}
                    moveRangeOnFirstSelection={false}
                    locale={es}
                    ranges={stateCalendario}
                    scroll={{ enabled: true }}
                    /> : null
                )
                }
            </div>
        </div>
    </fieldset>
  )
}

DateRangeField.clickOutside = () => {};

const clickOutsideConfig = {
  handleClickOutside: () => DateRangeField.clickOutside
};

export default onClickOutside(DateRangeField, clickOutsideConfig);