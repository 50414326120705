import {useState, useContext} from "react";
import {Dialog} from "@material-ui/core"
import styles from '../../Payroll/Modals/PayrollModals.module.css'
import { clearPayrollProcess } from "../../../context/PayrollProcess/Actions";
import PayrollProcessContext from "../../../context/PayrollProcess/PayrollProcessContext";
import { useMutation } from "@apollo/client";
import { CREACION_TOKEN_DISPERSION,
         GET_PAYROLL_DISPERSION_2, 
         SEND_DISPERSION_OUTSORCERS,
         GET_PAYROLL_DISPERSION_EVENTUAL,
         SEND_DISPERSION_OUTSORCERS_EVENTUAL,
         GET_PAYROLL_DISPERSION_FINIQUITO_OUT,
         SEND_DISPERSION_OUTSORCERS_FINIQUITO
         } from "../../../Querys/querys";
import { SuccessfulAlert } from "../../../alerts/successAlerts";
import { v4 as uuidv4} from 'uuid'

import OutsorcersNominaContext from "../../../context/OutsorcersNomina/OutsorcersNominaContext";
import { clearOutsorcers } from "../../../context/OutsorcersNomina/Actions";

const DispersionOutsorcers = (props:any) =>{
  const {totalesDispersar, totales, seleccionados, idRegistro} = props
  const {state, dispatch} = useContext(OutsorcersNominaContext)
  const [activo, setactivo] = useState(false)
  const [activoMensajeDos, setactivoMensajeDos] = useState(false)
  const [mensaje, setMensaje] = useState('Uno')
  const [token, setToken] = useState('')
  const [sendDispersion] = useMutation(SEND_DISPERSION_OUTSORCERS,{
    refetchQueries:[
      {query: GET_PAYROLL_DISPERSION_2, variables:{getPayrollDispersionId: idRegistro}}
    ]
  })

  const [sendDispersionEventual] = useMutation(SEND_DISPERSION_OUTSORCERS_EVENTUAL,{
    refetchQueries:[
      {query: GET_PAYROLL_DISPERSION_EVENTUAL, variables:{getPayrollDispersionId: idRegistro}}
    ]
  })

  const [sendDispersionFiniquito] = useMutation(SEND_DISPERSION_OUTSORCERS_FINIQUITO,{
    refetchQueries:[
      {query: GET_PAYROLL_DISPERSION_FINIQUITO_OUT, variables:{getPayrollDispersionId: idRegistro}}
    ]
  })

  const [creacionToken] = useMutation(CREACION_TOKEN_DISPERSION,{
  })

  const handleClose = () =>{
    clearOutsorcers({}, dispatch)
  }

  const handleChange = (e: any) =>{
    if(e.target.value === 'CONFIRMAR'){
        setactivo(true)
    }else{
        setactivo(false)
    }
  }

  const agregaSeleccionados = async() =>{
    const tokenCreado = uuidv4();
    let Array:any = []
    switch (props?.tipoNomina) {
      case "eventual":
        await creacionToken({
          variables:{
            input:{
              Desde: "NominaEventual",
              idRegistro: parseInt(idRegistro),
              token: tokenCreado,
              numeroRegistros: parseInt(totalesDispersar)
            }
          }
        })
        setMensaje((current) => current = 'Dos')
        props.setProcesoDispersion(true)
        seleccionados.map((lis:any)=>{
          Array.push({ id: parseInt(lis) })
        })
        await sendDispersionEventual({
          variables:{
            sendDispersionOutsorcersId: Array
          }
        })
        
      break;

      case "Finiquito":
        await creacionToken({
          variables:{
            input:{
              Desde: "Finiquito",
              idRegistro: parseInt(idRegistro),
              token: tokenCreado,
              numeroRegistros: parseInt(totalesDispersar)
            }
          }
        })
        setMensaje((current) => current = 'Dos')
        props.setProcesoDispersion(true)
        seleccionados.map((lis:any)=>{
          Array.push({ id: parseInt(lis) })
        })
        await sendDispersionFiniquito({
          variables:{
            sendDispersionOutsorcersId: Array
          }
        })
        
      break;
      case "liquidacion":
        await creacionToken({
          variables:{
            input:{
              Desde: "liquidacion",
              idRegistro: parseInt(idRegistro),
              token: tokenCreado,
              numeroRegistros: parseInt(totalesDispersar)
            }
          }
        })
        setMensaje((current) => current = 'Dos')
        props.setProcesoDispersion(true)
        seleccionados.map((lis:any)=>{
          Array.push({ id: parseInt(lis) })
        })
        await sendDispersionFiniquito({
          variables:{
            sendDispersionOutsorcersId: Array
          }
        })
        
      break;
    
      default:
        await creacionToken({
          variables:{
            input:{
              Desde: "Nomina",
              idRegistro: parseInt(idRegistro),
              token: tokenCreado,
              numeroRegistros: parseInt(totalesDispersar)
            }
          }
        })
        setMensaje((current) => current = 'Dos')
        props.setProcesoDispersion(true)        
        seleccionados.map((lis:any)=>{
          Array.push({ id: parseInt(lis) })
        })
        await sendDispersion({
          variables:{
            sendDispersionOutsorcersId: Array
          }
        })
        break;
    }
    props.reinicia()
    props.setProcesoDispersion(false)
    setactivo(false)
    setactivoMensajeDos((current) => current = false)
    SuccessfulAlert({text: "Registros enviados, por favor espera la respuesta"})
    setMensaje((current) => current ='Uno' )
    handleClose()
  }

  return(
    <Dialog aria-labelledby='costumized-dialog-title' open={state.openDispersionOutsorcer} fullWidth={false} maxWidth={"md"}>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorCerrarModal}>
          {mensaje === 'Tres'?
          null
          :
          <div
          className={styles.cerrarModal}
          onClick={()=> handleClose()}
        >
        </div>
          }

        </div>
        <div className={styles.contenedorPrincipal}>
          <div className={styles.fim_iconoPrincipal}>
            <div className={styles.fim_iconoComplemento}>
            </div>
          </div>
        </div>
        <div className={styles.tituloAtencion}>
          ¡Atenci&oacute;n!
        </div>
        {mensaje === 'Uno'?
          <>
            <div className={styles.em_mensajePrtincipal}>
              Est&aacute;s por dispersar n&oacute;mina a {totalesDispersar}/{totales} colaboradores
            </div>
          <div className={styles.em_mensajePrtincipal}>
            Escribe CONFIRMAR para continuar
          </div>
          <div>
              <input
                className={styles.input}
                type="text"
                onChange={(e) => handleChange(e)}
              />
          </div>
          </>
          :null
          }
          {mensaje === 'Dos'?
            <>
              <div className={styles.em_mensajePrtincipal}>
               Dispersi&oacute;n en proceso, no actualices ni cierres la pantalla
              </div>
              <div className={styles.em_mensajePrtincipal}>
              </div>
              <div className={styles.contenedorLoadingDispersion}>
                <div className={styles.loading}></div>
              </div>
            </>
          : null
          }
        <div className={styles.em_contenedorBotones}>
              {mensaje === 'Dos'?
              null
              :
              <button
                className={styles.em_botonCancelar}
                onClick={()=> handleClose()}
              >
                Cancelar
              </button>
              }
              {mensaje === 'Uno'?
                (activo === true ?
                  <button 
                    className={`${styles.botonesAtencion} ${styles.continuarAtencion}`}
                    onClick={()=> agregaSeleccionados()}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                  :
                  <button
                    className={`${styles.botonesAtencion} ${styles.continuarAtencionInactivo}`}
                  >
                    <div>
                      Confirmar
                    </div>
                  </button>
                )
                :null
              }
            </div>
      </div>
    </Dialog>
  )
}

export default DispersionOutsorcers