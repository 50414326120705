/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from 'react';
import { Dialog,
          DialogContent, 
         Grid, 
         TextField } from '@material-ui/core';
import { useMutation,useLazyQuery } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ObjectivesContext, { Types } from "../../../context/ObjectiveContext/ObjectiveContext";
import InputAdornment from '@mui/material/InputAdornment';
import { 
       GET_OBJECTIVE_EVIDENCE,
       QUALITY_CAMPAIGN_OBJECTIVE       
       } from '../../../Querys/querys';
import styles from '../Objectives.module.css';
import { formatter } from "../../../helpers/formatoMoneda";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ErrorAlert } from "../../../alerts/errorAlert";

const arrayAproved=(array:any)=>{
  let sumArray:any=[]
  array.map((evd:any)=>{
           if(evd.approved === 1){
            sumArray.push(evd)
           }
       })
  return sumArray
}  

const QualifyEvidenceModal = () => {
  const { state, dispatch } = useContext(ObjectivesContext);
  const [arrayEvidence, setArrayEvidence] =  useState<any[]>([])  
  const [sumPercentajeEvindence , setSumPercentajeEvindence] = useState(0)  ;
  const [coment,setComent] = useState('')  ;
  const [disabledCalif,setDisabledCalif] = useState(true);
  const [disabledCalifBtn,setDisabledCalifBtn] = useState(true)

  const [objectivesEvidence] = useLazyQuery(GET_OBJECTIVE_EVIDENCE, {
		fetchPolicy: 'no-cache',
		onCompleted({ GET_OBJECTIVE_EVIDENCE }) {           
      if(GET_OBJECTIVE_EVIDENCE.length>0){
        const calif=arrayAproved(GET_OBJECTIVE_EVIDENCE).reduce((acum :any , result: any) => acum + result.result, 0)
        if (state.selectedObjective) {   
          if(state?.selectedObjective.result >0 || state?.selectedObjective.result ===0){
            setSumPercentajeEvindence(state?.selectedObjective.result) 
            setDisabledCalifBtn(false)
           } else{
            setSumPercentajeEvindence(calif) 
            setDisabledCalifBtn(true)
           }    
        }       
        setArrayEvidence(GET_OBJECTIVE_EVIDENCE)         
      }	
		},
	});
  const [qualityObjectiveUpdate] = useMutation(QUALITY_CAMPAIGN_OBJECTIVE);
  useEffect(() => {  
    if(state?.selectedObjective?.id !== null){
     objectivesEvidence({ 
        variables: {
          input: {
            id: parseInt(state?.selectedObjective?.id as any, 10),
          }
        }})
    }  
   }, [objectivesEvidence, state?.selectedObjective]);

  const [form, setForm] = useState<any>({
    name: "",
    nameIndicator: "",
    metricObjective: "",
    metricIndicator: "",
    weight: "",
    description: "",
    progress: 0,
  });


  useEffect(() => {
    if (state.selectedObjective) {      
       setForm({
        name: state.selectedObjective.ObjectiveName,
        nameIndicator: state.selectedObjective.nameIndicator,
        metricObjective: state.selectedObjective.metricObjective,
        metricIndicator: state.selectedObjective.metricIndicator,
        weight: state.selectedObjective.weight?.toString() + "",
        description: state.selectedObjective.descriptionIndicator || "",    
        progress:  0,
      });          
      if(state.selectedObjective.coment !==null && state.selectedObjective.coment.length >0){
        setComent(state.selectedObjective.coment)     
      }     
    }
  }, [state.selectedObjective]);

  const calEvidence=(res:string,index:any )=>{
    const copyArray=[...arrayEvidence]
    setDisabledCalif(true)
    let resEvin:any
    let arrayNotNull:any=[]
    switch (res) {
      case 'ok':
         copyArray[index]['approved'] = 1;     
         resEvin =arrayAproved(copyArray).reduce((acum :any , result: any) => acum + result.result, 0);               
         setSumPercentajeEvindence(resEvin)         
         copyArray.map((obEv:any)=>{
           if(obEv.approved !== null){
            arrayNotNull.push(obEv)
           }
         })  
         if(copyArray.length ===arrayNotNull.length)
         {
          setDisabledCalif(false)
         }          
         setArrayEvidence(copyArray)
      break;
      case 'no':
        copyArray[index]['approved'] = 0; 
        resEvin =arrayAproved(copyArray).reduce((acum :any , result: any) => acum + result.result, 0); 
        setSumPercentajeEvindence(resEvin) 
        copyArray.map((obEv:any)=>{
          if(obEv.approved !== null){
           arrayNotNull.push(obEv)
          }
        })  
        if(copyArray.length ===arrayNotNull.length && resEvin>0)
        {
         setDisabledCalif(false)
        }        
        setArrayEvidence(copyArray)              
          
      break;      
    }
  }

  const onChangeQualify=(e:any)=>{
    const newQualify=e
    if(newQualify === ''){
      setSumPercentajeEvindence(0)  
      }else{
      setSumPercentajeEvindence(parseFloat(newQualify))
    }
  }

  const onChangeComment=(e:any)=>{
    setComent(e)
  }

 const qualityObjective=async () =>{
  const copyArray=[...arrayEvidence]
  const arrayNull:any=[]
  const arrayNo:any=[]
  copyArray.map((obEv:any)=>{
    if(obEv.approved === null){
      arrayNull.push(obEv)
    }
  })  
  if(arrayNull.length >0){
    return ErrorAlert({text:'Es necesario calificar todas las evidencias'});
  }
  copyArray.map((obEv:any)=>{
    if(obEv.approved === 0){
      arrayNo.push(obEv)
    }
  })  
  if(arrayNo.length >0){
    if(coment ==='')
    {
       return ErrorAlert({text:'Es necesario capturar un comentario'});
    }
  }

  for(const item of copyArray){
     delete item?.__typename;
  }  

  let arrayEvdFinal:any=[];
  let objEvdFinal:any={};
  copyArray.map((obEv:any)=>{
    objEvdFinal={...obEv,idObjective:parseInt(state?.selectedObjective?.id as any, 10)}
    arrayEvdFinal.push(objEvdFinal)
  })
  await qualityObjectiveUpdate({
    variables: {
      idCampaign: state.campaignId,
      comment:coment,
      qualify:sumPercentajeEvindence,
      input: {       
        arrayQualityEvidences:  arrayEvdFinal   
      }
    }
  });
  dispatch({ type: Types.SET_COUNT_NO_APROVE, payload:sumPercentajeEvindence });
  setSumPercentajeEvindence(0)
  setComent('')
  setDisabledCalif(true)
  dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });    
  dispatch({ type: Types.CANCEL_QUALITY_MODAL });
  setForm({
    name: "",
    nameIndicator: "",
    metricObjective: "",
    metricIndicator: "",
    weight: "",
    description: "",
  });
}

  const cancelButton =async () => {    
    setSumPercentajeEvindence(0)
    setDisabledCalif(true)
    setComent('')
    dispatch({ type: Types.CLEAR_SELECTED_OBJECTIVE });    
    dispatch({ type: Types.CANCEL_QUALITY_MODAL });
    setForm({
      name: "",
      nameIndicator: "",
      metricObjective: "",
      metricIndicator: "",
      weight: "",
      description: "",
    });
    
  }
  
  const getFileType = (fileName: string) => {
    if (fileName.includes('.pdf')) return '/assets/icons/icon-pdf.svg'
    if (fileName.includes('.jpg')) return '/assets/icons/icon-jpg.svg'
    if (fileName.includes('.jpeg')) return '/assets/icons/icon-jpg.svg'
    if (fileName.includes('.png')) return '/assets/icons/icon-png.svg'
    if (fileName.includes('.mp4')) return '/assets/icons/icon-mp4.svg'
    if (fileName.includes('.zip')) return '/assets/icons/icon-zip.svg'
    if (fileName.includes('.docx')) return '/assets/icons/icon-doc.svg'
    if (fileName.includes('.xlsx')) return '/assets/icons/icon-xlsx.svg'
    return '/assets/icons/icon-pdf.svg'
  }

 return (

    <Dialog
      aria-labelledby="customized-dialog-title"
      open={state.qualityObjectiveModal}
      fullWidth={true}  
      // maxWidth={"md"}  
    >
     <h2 id="form-dialog-title" className="text-center">
        {`Calificar "${form.name}" `} 
     </h2>
      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.nameIndicator}
              label="Nombre del indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricIndicator}
              label="Métrica objetivo"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>
        <Grid direction="row" container spacing={2}>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.metricObjective}
              label="Indicador"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                readOnly: true,
              }}
              disabled
            />
          </Grid>
          <Grid xs item>
            <TextField
              name="metricName"
              defaultValue={form.weight}
              label="Peso"
              variant="outlined"
              size="small"
              fullWidth={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogContent>       
        <Grid xs item>
          <TextField
            name="name"
            value={form.description}
            label="Descripción del objetivo"
            variant="outlined"
            size="small"
            fullWidth={true}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
        </Grid>
      </DialogContent>  
      {
        (state?.campaign?.step!=='definir' && arrayEvidence.length > 0 )&&
          <DialogContent>
            <h4 id="form-dialog-title" className="text-center">
              Evidencias
              {arrayEvidence.length > 0  && (
                <span>{` (${arrayEvidence.length})`}</span>
              )}
            </h4> 
      
            <TableContainer component={Paper}>
               <Table sx={{ minWidth: 300 }} aria-label="simple table">
                <TableHead>
                 <TableRow className='pd_contenedorTitulos'>
            <TableCell align="left">Documento</TableCell>
            {/* <TableCell align="left">Fecha</TableCell> */}
            <TableCell align="left">Archivo</TableCell>
            <TableCell align="left">Progreso</TableCell>
            <TableCell align="left">Porcentaje</TableCell>
            <TableCell align="left"></TableCell>           
          </TableRow>
        </TableHead>
        <TableBody>
         { arrayEvidence.map((evidence: any,ind:any) => (            
           <TableRow
           key={evidence.id}
           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
           >
            <TableCell align="left" width={190} className={styles.TableCellName} >{evidence.name}</TableCell>      
            {/* <TableCell align="left">{moment(parseInt(evidence.date, 10)).format("DD/MM/YY")}</TableCell> */}
            <TableCell align="center" width={10}  className={styles.fileDown} onClick={() => window.open(evidence.URL, "blank")}>
                             <img src={getFileType(evidence.URL)} alt="Archivo" className={styles.evidenceTableImage} />
             </TableCell>                         
             {
             evidence.metricIndicator === 'pesos'?
             <TableCell align="left">{formatter(evidence.progress)} </TableCell>:
             evidence.metricIndicator === 'porcentaje'?
             <TableCell align="left">{evidence.progress} %</TableCell> :
             <TableCell align="left">{evidence.progress} </TableCell>
            }      
             <TableCell align="left" >{evidence.result   === null? 0 : evidence.result} %</TableCell>
             <TableCell align="left" width={80}>
              <div className={styles.butonsQualify}>
                {evidence.approved=== null ?
                <> 
                <div className={styles.pointer}><CheckCircleIcon     onClick={()=>{ calEvidence('ok',ind)}}  fontSize="medium" color="action"/></div>
                <div className={styles.pointer}><CancelOutlinedIcon  onClick={()=>{ calEvidence('no',ind)}}  fontSize="medium" color="action"/></div>
                </>  :
                evidence.approved=== 1 ?
                <> 
                <div className={styles.pointer}><CheckCircleIcon     onClick={()=>{ calEvidence('ok',ind)}}  fontSize="medium" color="success"/></div>
                <div className={styles.pointer}><CancelOutlinedIcon  onClick={()=>{ calEvidence('no',ind)}}  fontSize="medium" color="action"/></div>
                </> :
                <> 
                <div className={styles.pointer}><CheckCircleIcon      onClick={()=>{ calEvidence('ok',ind)}}  fontSize="medium" color="action"/></div>
                <div className={styles.pointer}><CancelOutlinedIcon   onClick={()=>{ calEvidence('no',ind)}}  fontSize="medium" sx={{ color: 'red' }}/></div>
                </>
                }
              </div>
             </TableCell>
            
          </TableRow>
        ))}
         </TableBody>
      </Table>
      </TableContainer>
     </DialogContent>
      }      
     <DialogContent>
        <Grid direction="row" container  >
          <Grid xs item >
            <Box display="flex" justifyContent="flex-end">
                <TextField
                  name="califTotal"
                  value={sumPercentajeEvindence.toFixed(2)}
                  label="Calificación"
                  variant="outlined"
                  size="small"
                  type='text'  
                  disabled={disabledCalif}       
                  //  fullWidth={true}                         
                  onChange={e => onChangeQualify(e.target.value)}
                  onKeyPress={(event: any) => {
                    if (!/^[0-9.]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} 
                 />
              </Box>
          </Grid>         
      
        </Grid>
      </DialogContent>
      <DialogContent>
      <Grid xs item>
          <TextField
            name="coment"
            value={coment}
            onChange={e => onChangeComment(e.target.value)} 
            label="Comentario"
            variant="outlined"
            size="small"
            fullWidth={true}           
            multiline
            rows={4}              
          />       
          </Grid>
      </DialogContent>
      <DialogContent className={styles.containerBtnsQualify}>
         <div>
           <button
                onClick={cancelButton}
                className={styles.evidenceCancelButton}
              >Cancelar
          </button>                   
          </div>
        {disabledCalifBtn ? 
         <div className="containerAddPrestacion">
         <button
           onClick={qualityObjective}
           className={styles.pd_botonActive} 
         > Calificar                   
       </button>
        </div>       
         :
         <div className="containerAddPrestacion">
         <button
          // onClick={qualityObjective}
           className={styles.pd_botonInactive} 
         > Calificar                   
       </button>
        </div>       
         }
         
     </DialogContent>
       
        
    </Dialog>    
  );
};

export default QualifyEvidenceModal;
