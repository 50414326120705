import { formatterMxn } from "../../../helpers/formatoMoneda";
const Excel = require("exceljs");


export const ExcelBondsKpis = (data: any) =>{
 console.log(data)
  const workbook = new Excel.Workbook();
  const ReporteAdelantoNomina = workbook.addWorksheet("Reporte Bonos Kpi's");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };

  const headerFont = { 
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

 
  ReporteAdelantoNomina.columns = [
    { header: "Nombre (s)",                    key: "nombre", width },
    { header: "Apellidos",                     key: "apellidos", width },
    { header: "Email",                         key :"email", width },
    { header: "Área",                          key: "area", width },
    { header: "Sede",                          key: "sede", width },
    { header: "Fecha de ingreso",              key: "fechaIngreso", width },
    { header: "Ingreso Mensual",               key: "ingresoMensual", width },
    { header: "Calificación mínima requerida", key: "minQualification", width },
    { header: "Calificacion obtenida",         key: "qualFinal", width },    
    { header: "Tipo bono",                     key: "tipoBono", width },
    { header: "Bono",                          key: "descriptionBond", width },
    { header: "Bono Obtenido",                 key: "bono", width },
  ];

  let letter = 65;

  ReporteAdelantoNomina.columns.forEach(() => {
    ReporteAdelantoNomina.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteAdelantoNomina.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteAdelantoNomina.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {
    ReporteAdelantoNomina.addRow({
      nombre           : item.nombre,
      apellidos        : item.apellidos ,
      email            : item.email,
      area             : item.project,
      sede             : item.campus,
      fechaIngreso     : item.FechaIngreso,
      ingresoMensual   : formatterMxn(item.ingresoMensual),
      minQualification : item.minQualification,
      qualFinal        : item.qualFinal.toFixed(2) ,
      tipoBono         : item.tipoBono,
      descriptionBond  : item.tipoBono==='porcentaje'? `${item.descriptionBond} %`: `${formatterMxn((item.descriptionBond))}`,
      bono             : item.bono!== 0 ?formatterMxn((item.bono)):'$0.00',      
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteBonoKpis.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}
