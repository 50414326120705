import { useEffect, useState, useContext } from "react";
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import AddTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/AddTable";
import SearcherTable from "../../screens/Colaboradores/Colaboradores/CollaboratorTab/SearcherTable";
import TableBits from "../TableBits/TableBits";
import CommentField from "./Fields/CommentField";
import StateField from "./Fields/StateField";
import UserField from "./Fields/UserField";
import DateField from "./Fields/DateField";
import CheckPermission from "../../helpers/Administradores/Permissions";
import { MyMood } from '../../interfaces/MyMood';
import { GET_ALL_MYMOOD } from "../../Querys/querys";
import { useQuery } from "@apollo/client";

const columns: any = [
    { id: "Fecha", label: 'Fecha', align: 'left' },
    { id: "Autor", label: 'Autor', align: 'left' },
    { id: "estado", label: 'Estado', align: 'center' },
    { id: "Comentario", label: 'Comentario', align: 'left' },
];

const Body = ({ onHandleChange }: any) => {
    const { data: myMoodResult, startPolling, stopPolling } = useQuery(GET_ALL_MYMOOD);
    const [myMoodFilter, setMyMoodFilter] = useState<MyMood[]>([]);
    const [myMood, setMyMood] = useState<MyMood[]>([]);

    useEffect(()=>{
        startPolling(10000);
        return () =>{
          stopPolling()
        }
    },[startPolling,stopPolling]);

    useEffect(() => {
        if(myMoodResult && myMoodResult.GET_ALL_MYMOOD){
            setMyMoodFilter(myMoodResult.GET_ALL_MYMOOD);
            setMyMood(myMoodResult.GET_ALL_MYMOOD);
        }
    }, [myMoodResult]);

    return (
        <>
            <Box p={1} pb={3} display="flex" flexDirection="row" >
                <Grid container justify="flex-start">
                    <SearcherTable
                        initState={myMood}
                        setState={setMyMoodFilter}
                        label={"Buscar por autor / Estado / Comentario"}
                        fields={['fullName', 'Comentario', 'moodName']}
                        width={400}
                    />
                </Grid>
                {CheckPermission("MyMood.Estadisticas") && (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    style={{ width: "100%" }}
                >
                
                    <AddTable
                    func={onHandleChange}
                    img={"icono-agregar-event.svg"}
                    text={"Estadísticas"}
                    />
          
                </Box>
                )}
            </Box>
            

            <TableBits
                columns={columns}
                rows={myMoodFilter}
                components={[DateField, UserField, StateField, CommentField]}
                options={false}
                customInitialOrderBy="Fecha"
                customInitialOrder="desc"
            />

            
        </>
    )
}

export default Body;