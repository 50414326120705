import {
    Popper,
    Grow,
    Paper,
    MenuList,
    MenuItem,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import { AdminContext } from '../../context/AdminContext/AdminContext';
import { startLogout } from '../../actions/auth';
import { useHistory} from 'react-router-dom';
import CalendlyContext from '../../context/Calendly/CalendlyContext';


const PopperHeader = ({ open, anchorRef,setOpen}: any) => {

    const { adminDispatch } = useContext(AdminContext);
    const {state, dispatch}= useContext(CalendlyContext);
    const [calendly, setCalendly] = useState("");
    const history = useHistory();

    return (
        <Box zIndex="tooltip">
            <Popper data-testid="PopperMenu" open={open} anchorEl={anchorRef.current} transition disablePortal placement='bottom-end'>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <MenuList id="menu-list-grow">
                                
                            <MenuItem  onClick={() => {
                                        history.push("/miempresa");
                                        }}>
                                    Mi Compañía</MenuItem>
                                <MenuItem onClick={() => {
                                        history.push("/payment");
                                        }}>
                                    Facturación y Pagos</MenuItem>
                                
                                    <MenuItem
                                component="a"
                                href="https://calendar.app.google/tyqzSD1NE6mUhVMv9"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{color:'black',textDecorationColor:'none',textDecoration:'none'}}
                                    >
                                        <a>
                                            Ayuda
                                        </a>
                                </MenuItem>
                                <MenuItem onClick={()=>startLogout(adminDispatch!)}>Cerrar Sesión</MenuItem>
                                
                            </MenuList>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default PopperHeader;