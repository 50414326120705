import axios from 'axios';
import { useReducer } from 'react';
import { AppRouter } from './routers/AppRouter';
import { AdminContext } from './context/AdminContext/AdminContext';
import { adminReducer } from './context/AdminContext/adminReducer';
import { server, serverCFDI } from './global/server';
import AdminPermisosContex from './context/AdminContext/AdminPermisosContext/AdminPermisosState'
import IncidenciasContext from './context/IncidenciasContext/IncidenciasState'
import PayrollProcessContext from './context/PayrollProcess/PayrollProcessState'
import ObjectiveState from './context/ObjectiveContext/ObjectiveState'
import SettlementProcessContext from './context/PayrollProcess/SettlementProcessState'
import EmpresaContext from './context/Empresa/EmpresaState';
import EventualProcessState from './context/PayrollProcess/EventualProcessState'
import CalendlyState from './context/Calendly/CalendlyState';
import { TourProvider } from "@reactour/tour";
import MySteps from "./onboarding/StepsOnboard";
import OutsourcersState from './context/Outsourcers/OutsourcersState'
import CalendarsState from './context/NewCalendarContext/CalendarsState';
import CentralPagosState from './context/CentralPagos/CentralPagosState';
import OutsorcersNominaState from './context/OutsorcersNomina/OutsorcersNominaState';
import ReportState from './context/ReportsContext/ReportState';
import MonthlyISRState from './context/PayrollProcess/MonthlyISRState'

axios.defaults.baseURL = server;
axios.defaults.withCredentials = true;

function App() {
  const [adminState, adminDispatch] = useReducer(adminReducer, {
    loading: true,
  });

  const mystep = MySteps()

  return (
    <AdminContext.Provider value={{
      adminState,
      adminDispatch
    }}>
      <MonthlyISRState>
        <OutsorcersNominaState>
          <CentralPagosState>
            <CalendarsState>
              <CalendlyState>
                <EmpresaContext>
                  <OutsourcersState>
                    <EventualProcessState>
                      <SettlementProcessContext>
                        <IncidenciasContext>
                          <AdminPermisosContex>
                            <PayrollProcessContext>
                              <ObjectiveState>
                                <ReportState>
                                  <TourProvider
                                    steps={mystep}
                                    showBadge={false}
                                    onClickMask={() => { }}
                                    disableKeyboardNavigation={true}
                                    disableFocusLock={true}
                                    onClickClose={({ setIsOpen, setCurrentStep }) => {
                                      setIsOpen(false);
                                      setCurrentStep(0);
                                    }}
                                    showNavigation={false}
                                  >
                                    <AppRouter />
                                  </TourProvider>
                                </ReportState>
                              </ObjectiveState>
                            </PayrollProcessContext>
                          </AdminPermisosContex>
                        </IncidenciasContext>
                      </SettlementProcessContext>
                    </EventualProcessState>
                  </OutsourcersState>
                </EmpresaContext>
              </CalendlyState>
            </CalendarsState>
          </CentralPagosState>
        </OutsorcersNominaState>
      </MonthlyISRState>
    </AdminContext.Provider>
  );
}

export default App;
