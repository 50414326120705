import Accordion from '@mui/material/Accordion';
import { styled } from "@mui/material/styles";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "../css/TreeView.css";
import plusReport from "../../../assets/svg/plusReport.svg";
import iconCampo from "../../../assets/svg/icono_informesPreview_verCampo.svg";


const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(() => ({
  flexDirection: "row-reverse",
}));

const TreeView = ({columnas, filas, camposPivotes}:any) => {
  console.log("filas esta en treeview",filas)
  console.log("columnas hasta en tree",columnas)
  console.log("pivotes", camposPivotes)
  const filasID = filas.map((valor:any, index:any) => ({name: valor, id:`${index+1}FilasT`}))
  const columnasID = columnas.map((valor:any, index:any) => ({name: valor, id:`${index+1}ColumnasT`}))
  const agrupadorID = camposPivotes.map((valor:any, index:any) => ({name: valor, id:`${index+1}Agrupador` }))
  return (
    <div className='selectoresReport' >
       <Accordion>
        <AccordionSummary
          style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
          // expandIcon={<ExpandMoreIcon />}          
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className='containerInfo'> 
           <div className='txtInfo'> Campos de Filas  </div>
           <div><img src={plusReport} alt="add"/></div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{padding: 'unset'}}>
        {filas.length === 0 ?
          <div style={{padding: '8px 16px 16px'}}>
            <div className='txtInfo'>Este informe no contiene filas</div> 
          </div>
          :
          filasID.map((elemento:any) =>
          (
            <div key={elemento.id} className='conteinerCampo'>
              <div>{elemento.name}</div>
              <div><img src={iconCampo} alt="icono"/></div>
            </div>
          ))
        }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
         // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
         <div className='containerInfo'> 
           <div className='txtInfo'>  Campos de Columnas  </div>
           <div><img src={plusReport} alt="add"/></div>
          </div>
       </AccordionSummary>
        <AccordionDetails style={{padding: 'unset'}}>
        {columnas.length === 0 ?
          <div style={{padding: '8px 16px 16px'}}>
            <div className='txtInfo'>Este informe no contiene columnas</div>
          </div>
          :
          columnasID.map((elemento:any) =>
          (
                <div key={elemento.id} className='conteinerCampo'>
                  <div>{elemento.name}</div>
                  <div><img src={iconCampo} alt="icono"/></div>
                </div>
          ))
        }
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}
          //expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
        <div className='containerInfo'> 
           <div className='txtInfo'> Agrupador </div>
           <div><img src={plusReport} alt="add"/></div>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{padding: 'unset'}}>
        {camposPivotes?.length === 0 ?
          <div style={{padding: '8px 16px 16px'}}>
            <div className='txtInfo'>Este informe no contiene Agrupador</div>
          </div>
          :
          agrupadorID.map((elemento:any) =>
          (
            <div key={elemento.id} className='conteinerCampo'>
              <div>{elemento.name}</div>
              <div><img src={iconCampo} alt="icono"/></div>
            </div>
          ))
        }
        </AccordionDetails>
      </Accordion>
      
    </div>
  )
}

export default TreeView

