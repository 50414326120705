import {createContext, Dispatch} from 'react'

type InitialStateType ={
  _id: string 
  openAutoriza: boolean
  openDispersionOutsorcer: boolean
  openDescartarOutsorcer: boolean
  openSinSeleccion:boolean
  openAceptarRechazado: boolean
  openRechazarRechazado: boolean  
  nombreColaborador: string
  conceptoDescartado: string
  openDescartaTodas: boolean
  tipo: string
}

type ModalPartial = {
  state: InitialStateType,
  dispatch: Dispatch<any>,
}

export const initalState = {
  _id: '',
  openAutoriza: false,
  openDispersionOutsorcer: false,
  openDescartarOutsorcer: false,
  openSinSeleccion: false,
  openAceptarRechazado: false,
  openRechazarRechazado: false,
  nombreColaborador: '',
  conceptoDescartado: '',
  openDescartaTodas: false,
  tipo:''
}

export enum Types{
  OPEN_AUTORIZA = "OPEN_AUTORIZA",
  CLEAR_OUTSORCERS = "CLEAR_OUTSORCERS",
  OPEN_DISPERSION_OUTSORCERS = "OPEN_DISPERSION_OUTSORCERS",
  OPEN_DESCARTAR_OUTSORCERS = "OPEN_DESCARTAR_OUTSORCERS",
  OPEN_SIN_SELECCION = "OPEN_SIN_SELECCION",
  OPEN_ACEPTAR_RECHAZADO = "OPEN_ACEPTAR_RECHAZADO",
  OPEN_RECHAZAR_RECHAZADO = "OPEN_RECHAZAR_RECHAZADO",
  OPEN_DESCARTA_TODAS = "OPEN_DESCARTA_TODAS"
}

const ModalInitialState = {
  state: initalState,
  dispatch: () => null
}

const OutsorcersNominaContext = createContext<ModalPartial>(ModalInitialState)

export default OutsorcersNominaContext