/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import ObjectiveRowOptions from "../Modals/ObjectiveRowOptions";
import ObjectiveRowOptionsAdmin from "../Modals/ObjectiveRowOptionsAdmin";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatter } from "../../../helpers/formatoMoneda";
import ObjectivesContext from "../../../context/ObjectiveContext/ObjectiveContext";
import ProgressBar from "@ramonak/react-progress-bar";
import styles from "./ObjectivesTableApprove.module.css";


const TableObjectives = (props:any) => {  
  const { state } = useContext(ObjectivesContext); 
  const [calif, setCalif] = useState(0); 

  useEffect(() => {
   if (state?.campaign) {      
        setCalif(state?.campaign?.minQualification || 0);
   }
  }, [state.selectedObjective]);
  

  
  return (
    <Box display="flex" flexDirection="column" p={2}> 
     <div className={styles.pc_contenedorTabla}>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
        {
         state?.campaign?.step === 'definir'?
         <TableRow  key={state?.campaign?.step } className='pd_contenedorTitulos'> 
           <TableCell align="left">Nombre del Objetivo</TableCell>
           <TableCell align="left">Indicador</TableCell>
           <TableCell align="left">Tipo de Indicador</TableCell>
           <TableCell align="left">Metrica Objetivo</TableCell>
           <TableCell align="left">Peso</TableCell>          
           <TableCell align="left">Opciones</TableCell>                     
         </TableRow>        
          :state?.campaign?.step === 'actuar'?
          <TableRow   key={state?.campaign?.step } className='pd_contenedorTitulos'> 
            <TableCell align="left">Nombre del Objetivo</TableCell>
            <TableCell align="left">Indicador</TableCell>
            <TableCell align="left">Tipo de Indicador</TableCell>
            <TableCell align="left">Metrica Objetivo</TableCell>
            <TableCell align="left">Peso</TableCell>            
            <TableCell align="left">Evidencias</TableCell>
            <TableCell align="left">Progreso</TableCell>
            <TableCell align="left">Porcentaje</TableCell>                 
            <TableCell align="left">Opciones</TableCell>           
            </TableRow>       
          :state?.campaign?.step === 'evaluar'&&
          <TableRow   key={state?.campaign?.step } className='pd_contenedorTitulos'> 
            <TableCell align="left">Nombre del Objetivo</TableCell>
            <TableCell align="left">Indicador</TableCell>
            <TableCell align="left">Tipo de Indicador</TableCell>
            <TableCell align="left">Metrica Objetivo</TableCell>
            <TableCell align="left">Peso</TableCell>            
            <TableCell align="left">Evidencias</TableCell>
            <TableCell align="left">Calificación</TableCell>                           
            <TableCell align="left">Opciones</TableCell>           
          </TableRow>            
          }          
        </TableHead>
        <TableBody>
        {
        state?.campaign?.step === 'definir'?
        props.rows?.map((row: any, index: any) => (
          <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="left">{row.ObjectiveName}</TableCell>      
            <TableCell align="left">{row.nameIndicator}</TableCell>
            <TableCell align="left">{row.metricIndicator}</TableCell>                
            {row.metricIndicator === 'pesos'?
             <TableCell align="left">{row.metricObjective}</TableCell>:
              row.metricIndicator === 'porcentaje'?
             <TableCell align="left">{row.metricObjective} </TableCell>:
             <TableCell align="left">{row.metricObjective} </TableCell>
            }
            <TableCell align="left">{row.weight} % </TableCell>            
            
            { state?.rol === 'admin'?
                <TableCell align="left"><ObjectiveRowOptionsAdmin 
                                    {...row}
                                    addColObj={props.addColObj}
                                    maxObjInd={props.maxObjInd}  
                                    weightObjectives={props.weightObjectives}                                     
                                    /> </TableCell>
              :<TableCell align="left"><ObjectiveRowOptions 
              {...row}
              addColObj={props.addColObj}
              maxObjInd={props.maxObjInd}  
              weightObjectives={props.weightObjectives}                                     
              /> </TableCell>
             }
         </TableRow>
           ))      
         : state?.campaign?.step === 'actuar'?
         props.rows?.map((row: any, index: any) => (  
         <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="left">{row.ObjectiveName}</TableCell>      
            <TableCell align="left">{row.nameIndicator}</TableCell>
            <TableCell align="left">{row.metricIndicator}</TableCell>                
            {row.metricIndicator === 'pesos'?
             <TableCell align="left">{row.metricObjective}</TableCell>:
              row.metricIndicator === 'porcentaje'?
             <TableCell align="left">{row.metricObjective} </TableCell>:
             <TableCell align="left">{row.metricObjective} </TableCell>
            }
            <TableCell align="left">{row.weight} % </TableCell>
            <TableCell align="left">{row.evidenceCount} </TableCell>
             {
             row.metricIndicator === 'pesos'?
             <TableCell align="left">{formatter(row.progress.split("/")[0])} </TableCell>:
             row.metricIndicator === 'porcentaje'?
             <TableCell align="left">{row.progress.split("/")[0] } %</TableCell> :
             <TableCell align="left">{row.progress.split("/")[0]} </TableCell>
             }
                    
            {isNaN(parseInt(row.progress.split("/")[1]))?
              <TableCell align="left">
               <ProgressBar 
                 completed={0}                           
                 maxCompleted={100}
                 labelSize='12px'
                 bgColor='#4cbb17' 
                 width="100%"                          
                 />
             </TableCell>             
           :Number(row.progress.split("/")[1]) < 30 ?
            <TableCell align="left">
              <ProgressBar 
                completed={Number(row.progress.split("/")[1])}
                customLabel=" "                                           
                labelSize='12px'
                bgColor='#4cbb17' 
                width="100%"                          
                />
            </TableCell>
           : 
           <TableCell align="left">
             <ProgressBar 
               completed={Number(row.progress.split("/")[1])}                           
               maxCompleted={100}
               labelSize='12px'
               bgColor='#4cbb17' 
               width="100%"                          
               />
           </TableCell>
           }                      
           { state?.rol === 'admin'?
                 <TableCell align="left"><ObjectiveRowOptionsAdmin 
                                     {...row}
                                     addColObj={props.addColObj}
                                     maxObjInd={props.maxObjInd}  
                                     weightObjectives={props.weightObjectives}                                     
                                     /> </TableCell>
               :<TableCell align="left"><ObjectiveRowOptions 
               {...row}
               addColObj={props.addColObj}
               maxObjInd={props.maxObjInd}  
               weightObjectives={props.weightObjectives}                                     
               /> </TableCell>
              }
         
         </TableRow>
         ))
         : state?.campaign?.step === 'evaluar'?
         props.rows?.map((row: any, index: any) => (  
         <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell align="left">{row.ObjectiveName}</TableCell>      
            <TableCell align="left">{row.nameIndicator}</TableCell>
            <TableCell align="left">{row.metricIndicator}</TableCell>                
            {row.metricIndicator === 'pesos'?
             <TableCell align="left">{row.metricObjective}</TableCell>:
             row.metricIndicator === 'porcentaje'?
             <TableCell align="left">{row.metricObjective} </TableCell>:
             <TableCell align="left">{row.metricObjective} </TableCell>
            }
            <TableCell align="left">{row.weight} % </TableCell>
            <TableCell align="left">{row.evidenceCount} </TableCell>
            { (row.result === null  )?
              <TableCell align="left">
                <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusDos}`}>
                   <div className={styles.pd_textoBotonEstatusDos}>
                     Pendiente
                 </div>
                </div>
              </TableCell>
             :  row.result >= calif?
              <TableCell align="left">
                <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusCuatro}`}>
                  <div className={styles.pd_textoBotonEstatusCuatro}>
                     {`Aprobado `}
                  </div>
                  <div className={styles.pd_iconoEstatusCuatro}></div>
                </div> 
               </TableCell>:
                row.result < calif   ?
               <TableCell align="left">
               <div className={`${styles.pd_botonEstatus} ${styles.pd_botonEstatusError}`}>
                 <div className={styles.pd_textoBotonEstatusCuatro}>
                    {`No Aprobado `}
                 </div>                 
               </div> 
              </TableCell>:
               ""
              }
                     
            { state?.rol === 'admin'?
                 <TableCell align="left"><ObjectiveRowOptionsAdmin 
                                     {...row}
                                     addColObj={props.addColObj}
                                     maxObjInd={props.maxObjInd}  
                                     weightObjectives={props.weightObjectives}                                     
                                     /> </TableCell>
               :<TableCell align="left"><ObjectiveRowOptions 
               {...row}
               addColObj={props.addColObj}
               maxObjInd={props.maxObjInd}  
               weightObjectives={props.weightObjectives}                                     
               /> </TableCell>
              }
         
         </TableRow>
         )):""

        }
         </TableBody>
      </Table>
    </TableContainer>
   </div>      
    </Box>
  );
};

export default TableObjectives;