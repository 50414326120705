import { Route, Switch } from 'react-router';
import {
    useEffect,
    useState
} from 'react';
import { Grid } from '@material-ui/core';
import '../components/Header/Header.css';
import Header from '../components/Header/Header';
import SideBar from '../components/SideBar/SideBar';
import BitsScreen from '../screens/BitsScreen';
import TeamScreen from '../screens/TeamScreen';
import CollaboratorsScreen from '../screens/CollaboratorsScreen';
import NuevoIngresoScreen from '../screens/NuevoIngresoScreen';
import InactivosScreen from '../screens/InactivosScreen';

import CollaboratorsScreenBase from '../screens/CollaboratorsScreenBase';
import MyMoodScreen from '../screens/MyMoodScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import NewsScreen from '../screens/NewsScreen';
import FeedbackScreen from '../screens/FeedbackScreen';
import RigthSideBar from '../components/RigthSideBar/RigthSideBar';
import CalendarNewScreen from '../screens/CalendarNewScreen';
import OrganigramaScreen from '../screens/OrganigramaScreen'
import AdministratorScreen from '../screens/AdministratorScreen';
import MiEmpresaScreen from "../screens/MiEmpresaScreen";
import IdseScreen from '../screens/IdseScreen';
//PaymentsCentral
import PaymentsCentral from '../screens/PaymentsCentral';
//Payrolls
import payrollScreen from '../screens/PayrollScreenBase'
import configPayrollScreen from '../screens/ConfigPayrollScreenBase'
import ScheduleScreen from '../screens/ScheduleScreen';
import ScheduleScreen2 from '../screens/ScheduleScreen2';
import InitPayroll from '../screens/InitPayroll'
import InitMonthlyISR from '../screens/InitMonthlyISR'
import PayrollTimbrar from "../screens/PayrollTimbrar";
import PayrollTerminar from "../screens/PayrollTerminar";
import PayrollTerminarFiniquito from "../screens/PayrollTerminarFiniquito";
import PayrollEventualTerminar from "../screens/PayrollEventualTerminar";

import PayrollCancelTimbrar from "../screens/PayrollCancelTimbrar"
import PayrollCancelTimbrarEventual from "../screens/PayrollCancelTimbrarEventual"
import PayrollCancelTimbrarSettlement from "../screens/PayrollCancelTimbrarSettlement"

import InitSettlementL from '../screens/InitSettlementL';
import SettlementTimbrar from "../screens/SettlementTimbrar";

import InitEventualPayroll from '../screens/InitEventualPayroll'
import EventualPayrollTimbrar from "../screens/EventualPayrollTimbrar";
import EventualPayrollDispersion from "../screens/EventualPayrollDispersion";
import PayrollDispersion from '../screens/PayrollDispersion';
import SettlementDispersion from '../screens/SettlementDispersion';
import InitPayments from '../screens/InitPayments'

import CentralPagosScreen from '../screens/CentralPagosScreen';

import PrestacionesScreen from '../screens/PrestacionesScreen';

import OutsorcersScreen from '../screens/OutsourcersScreen';
import OutsorcersDispersion from '../components/Outsorcers/OutsourcersDispersion';
import OutsorcersDispersionEventual from '../components/Outsorcers/OutsourcersDispersionEventual';
import OutsorcersDispersionFiniquito from '../components/Outsorcers/OutsourcersDispersionFiniquito';
import OutsorcersDispersionLiquidacion from '../components/Outsorcers/OutsourcersDispersionLiquidacion';
import OutsorcersAutorizar from '../components/Outsorcers/OutsourcersAutorizar';
import OutsourcersAutorizarEventual from '../components/Outsorcers/OutsourcersAutorizarEventual';
import OutsourcersAutorizarFiniquito from '../components/Outsorcers/OutsourcersAutorizarFiniquito';
import OutsourcersAutorizarLiquidacion from '../components/Outsorcers/OutsourcersAutorizarLiquidacion';
import OutsorcersTerminar from '../components/Outsorcers/OutsorcersTerminar';
import OutsorcersTerminarEventual  from '../components/Outsorcers/OutsorcersTerminarEventual';
import OutsorcersTerminarFiniquito  from '../components/Outsorcers/OutsorcersTerminarFiniquito';
import ContractsScreen from '../screens/ContractsScreen';
import DispersionGraphs from '../components/DispersionGraphs/DispersionGraphs';
import ObjectivesScreen from '../screens/ObjectivesScreen';
import CampaignScreen from '../screens/CampaignScreen';
import ReportsScreen from '../screens/ReportsScreen';
import ReportInfoScreen from '../screens/ReportInfoScreen';
import ReportInfo from '../components/Reports/ReportInfo';
import ReportContent from '../components/Reports/ReportContent';
import FacturacionPagosScreen from '../screens/FacturacionPagosScreen';



const BitsRouter = () => {
    const [clas, setClas] = useState('BodyContainer');

    useEffect(() => {
        if (window.location.pathname !== '/') {
            setClas('BodyContainer BodyContainerWhite');
            document.documentElement.style.setProperty('--color', '#000');
            document.documentElement.style.setProperty('--backGroundRectangle', '#fff');
            document.documentElement.style.setProperty('--lineColor', '#e7e7e7');
        } else {
            document.documentElement.style.setProperty('--color', '#fff');
            document.documentElement.style.setProperty('--backGroundRectangle', '#154565');
            document.documentElement.style.setProperty('--lineColor', '#154565');
        }
    }, []);
    


    return (
        <div>
        <Header />

        <Grid direction="row" container item>
            <Grid xs="auto" item className="RectangleSideBar">
                <SideBar />
            </Grid>
            <Grid xs item className={clas} id="contentBody">
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={BitsScreen}
                    />
                    <Route
                        exact
                        path="/objetivos/:id"
                        component={ObjectivesScreen}
                    />

                    <Route
                        exact
                        path="/team"
                        component={TeamScreen}
                    />

                    <Route
                        exact
                        path="/collaborators/:id/:tab"
                        component={CollaboratorsScreenBase}
                    />
                    <Route
                        exact
                        path="/collaborators-view/:id/:tab"
                        component={CollaboratorsScreenBase}
                    />
                    <Route
                        exact
                        path="/nuevoingreso/:id/:tab"
                        component={NuevoIngresoScreen}
                    />
                    <Route
                        exact
                        path="/colaboradores/:id/:tab"
                        component={CollaboratorsScreen}
                    />
                    <Route
                        exact
                        path="/inactivos/:id/:tab"
                        component={InactivosScreen}
                    />

                    <Route 
                        exact
                        path="/Administrador/:id/:tab"
                        component={AdministratorScreen}
                    />

                    <Route
                        exact
                        path="/mymood"
                        component={MyMoodScreen}
                    />
                    <Route
                        exact
                        path="/mymood/stats"
                        component={MyMoodScreen}
                    />

                    <Route
                        exact
                        path="/notifications"
                        component={NotificationsScreen}
                    />

                    <Route
                        exact
                        path="/news"
                        component={NewsScreen}
                    />

                    <Route
                        exact
                        path="/feedback"
                        component={FeedbackScreen}
                    />

                    <Route
                        exact
                        path="/calendarNuevo"
                        component={CalendarNewScreen}
                    />
                    <Route
                        exact
                        path="/IDSE"
                        component={IdseScreen}
                    />
                    <Route
                        exact
                        path="/organigrama"
                        component={OrganigramaScreen}
                    />
                    <Route
                        exact
                        path="/payroll"
                        component={payrollScreen}
                    />
                    <Route
                        exact
                        path="/paymentscentral"
                        component={PaymentsCentral}
                    />
                    <Route
                        exact
                        path="/configPayroll"
                        component={configPayrollScreen}
                    />
                    <Route
                        exact
                        path="/InitPayroll/:id/:tab"
                        component={InitPayroll}
                    />
                    <Route
                        exact
                        path="/InitMonthlyISR/:id/:tab"
                        component={InitMonthlyISR}
                    />
                    <Route
                        exact
                        path="/InitSettlement/:id/:tab/:tipo"
                        component={InitSettlementL}
                    />
                    <Route
                        exact
                        path="/InitEventualPayroll/:id/:tab"
                        component={InitEventualPayroll}
                    />

                    <Route exact path="/miempresa" component={MiEmpresaScreen} />

                    <Route exact path="/PayrollTimbrar/:id/:tab" component={PayrollTimbrar} />
                    <Route exact path="/PayrollCancelTimbrar/:id/:all/:mail" component={PayrollCancelTimbrar} />
                    <Route exact path="/PayrollCancelTimbrarEventual/:id/:all/:mail" component={PayrollCancelTimbrarEventual} />
                    <Route exact path="/PayrollCancelTimbrarSettlement/:id/:all/:mail" component={PayrollCancelTimbrarSettlement} />
                    <Route exact path="/PayrollDispersar/:id/:tab" component={PayrollDispersion} />
                    <Route exact path="/SettlementTimbrar/:id/:tab" component={SettlementTimbrar} />
                    <Route exact path="/SettlementDispersar/:id/:tab/:tipo" component={SettlementDispersion} />
                    <Route exact path="/EventualPayrollTimbrar/:id/:tab" component={EventualPayrollTimbrar} />
                    <Route exact path="/EventualPayrollDispersar/:id/:tab" component={EventualPayrollDispersion} />

                    <Route
                        exact
                        path="/Schedule"
                        component={ScheduleScreen}
                    />
                    <Route
                        exact
                        path="/Schedule2/:id"
                        component={ScheduleScreen2}
                    />
                    <Route exact path="/PayrollTerminar/:id/:tab" component={PayrollTerminar} />
                    <Route exact path="/PayrollTerminarFiniquito/:id/:tab" component={PayrollTerminarFiniquito} />
                    <Route exact path="/PayrollEventualTerminar/:id/:tab" component={PayrollEventualTerminar} />
                    {/*<Route exact path="/payment" component={InitPayments} />  */}
                    <Route exact path="/payment" component={FacturacionPagosScreen} />
                    
                    <Route exact path="/prestaciones" component={PrestacionesScreen} />
                    <Route exact path="/campanias"     component={CampaignScreen} />
                    <Route exact path="/informes"     component={ReportContent} />
                    <Route exact path="/informes/:id" component={ReportInfo} />                    

                    <Route exact path="/outsourcers" component={OutsorcersScreen} />
                    <Route exact path="/outsorcersdispersion/:id/:tab" component={OutsorcersDispersion}/>
                    <Route exact path="/outsorcersdispersionEventual/:id/:tab" component={OutsorcersDispersionEventual}/>
                    <Route exact path="/OutsorcersdispersionFiniquito/:id/:tab" component={OutsorcersDispersionFiniquito}/> 
                    <Route exact path="/OutsorcersDispersionLiquidacion/:id/:tab" component={OutsorcersDispersionLiquidacion}/> 

                                       
                    <Route exact path="/outsorcersAutorizar/:id/:tab" component={OutsorcersAutorizar}/>
                    <Route exact path="/OutsourcersAutorizarEventual/:id/:tab" component={OutsourcersAutorizarEventual}/>
                    <Route exact path="/OutsourcersAutorizarFiniquito/:id/:tab/:tipo" component={OutsourcersAutorizarFiniquito}/>
                    <Route exact path="/OutsourcersAutorizarLiquidacion/:id/:tab/:tipo" component={OutsourcersAutorizarLiquidacion}/>
                    
                    <Route exact path="/outsorcersTerminar/:id/:tab" component={OutsorcersTerminar}/>
                    <Route exact path="/outsorcersTerminarEventual/:id/:tab" component={OutsorcersTerminarEventual}/> 
                    <Route exact path="/OutsorcersTerminarFiniquito/:id/:tab" component={OutsorcersTerminarFiniquito}/>                     
                    
                    <Route exact path="/centralpagos" component={CentralPagosScreen}/>
                    <Route exact path="/contracts" component={ContractsScreen}/>
                    <Route exact path="/pagos" component={DispersionGraphs}/>
                </Switch>
            </Grid>
            <Grid xs="auto" item className={clas} id="RigthSide">
                <RigthSideBar />
            </Grid>
        </Grid>
    </div>
    )
};

export default BitsRouter;
