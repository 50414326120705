const Excel = require("exceljs");

export const CreateExcelMovimientosSTP = (data: any) =>{
  console.log('Estos son los datos', data)
  
  const workbook = new Excel.Workbook();
  const ReporteMovimientosSTP = workbook.addWorksheet("Rerporte Movimientos STP");

  const width = 40;

  const header = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "002060" },
  };

  const headerFont = { 
    name: "Arial",
    color: { argb: "FFFFFF" },
    family: 2,
    size: 11,
    bold: true,
  };

  ReporteMovimientosSTP.columns = [
    { header: "Fecha de movimiento", key: "FechaMovimiento", width },
    { header: "Concepto", key: "Concepto", width },
    { header: "Clave Rastreo", key : "CveRastreo", width },
    { header: "Nombre Beneficiario", key: "NombreBeneficiario", width },
    { header: "Cargo (-) / Abono (+)", key: "CargoAbono", width },
    { header: "Tipo Operaci�n", key: "TipoOperacion", width },
    { header: "Saldo Final", key: "SaldoFinal", width },
  ];

  let letter = 65;

  ReporteMovimientosSTP.columns.forEach(() => {
    ReporteMovimientosSTP.getCell(`${String.fromCharCode(letter)}1`).fill = header;
    ReporteMovimientosSTP.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
    ReporteMovimientosSTP.getCell(`${String.fromCharCode(letter)}1`).alignment = {
      horizontal: "center",
    };
    letter++;
  });

  data.forEach((item: any) => {
    ReporteMovimientosSTP.addRow({
      FechaMovimiento: item.fechaOperacion,
      Concepto: item.conceptoPago,
      CveRastreo: item.claveRastreo,
      NombreBeneficiario: item.nombreBeneficiario,
      CargoAbono: item.cargoAbono,
      TipoOperacion: item.tipoOperacion,
      SaldoFinal: item.saldo
    });
  });

  workbook.xlsx.writeBuffer().then(function (data: Blob) {
    const blob = new Blob([data],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'ReporteMovimientosSTP.xlsx';
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}